import { useState, useEffect, useRef } from "react";
import moment from "moment";

/**
 * To track time changes.
 * @param {number} timeInterval - Time interval, in milliseconds, to update the current timestamp value
 * @param {boolean} shouldUpdate - Check to indicate if current timestamp should update at regular intervals, should be true only for live items
 * @returns {string} - Updated timestamp value of the current time as a state variable in milliseconds
 */
export default function useCurrentTime(timeInterval, shouldUpdate) {
  const [currentTime, setCurrentTime] = useState(moment().valueOf());
  const setIntervalTimeArray = useRef([]);

  useEffect(() => {
    let intervalId;
    if (shouldUpdate) {
      intervalId = setInterval(() => {
        setCurrentTime(moment().valueOf());
      }, timeInterval);
    }
    setIntervalTimeArray.current.push(intervalId);
    const setIntervalTimeArrayCopy = [...setIntervalTimeArray.current];
    return () => {
      setIntervalTimeArrayCopy.forEach((intervalId) => clearInterval(intervalId));
    };
  }, [timeInterval, shouldUpdate]);

  return currentTime;
}
