import middleware from "../../../shared/middleware";
import constants from "../../../shared/constants";

const { getProgramDetail, retrieveAvsItems, getSeasonData, getContentUserData, getRecordingProgramDetail } = middleware;
const { CONTAINER_LAYOUTS, PAGE_CONTENT_ITEM_TYPES } = constants;

// Action types
export const LOAD_ON_DEMAND_PLAY_OPTIONS = "LOAD_ON_DEMAND_PLAY_OPTIONS";
export const SHOW_PURCHASE_ACKNOWLEDGEMENT = "SHOW_PURCHASE_ACKNOWLEDGEMENT";
export const LOAD_CURRENT_PROGRAM_DETAILS = "LOAD_CURRENT_PROGRAM_DETAILS";
export const LOAD_CURRENT_SERIES_DETAILS = "LOAD_CURRENT_SERIES_DETAILS";
export const LOAD_PLAYBACK_SEASON_DATA = "LOAD_PLAYBACK_SEASON_DATA";
export const LOAD_CURRENT_SEASON_DETAILS = "LOAD_CURRENT_SEASON_DETAILS";
export const LOAD_CURRENT_SEASON_ENTITLEMENTS = "LOAD_CURRENT_SEASON_ENTITLEMENTS";

// Action methods

function loadProgramDetailsSuccess(content) {
  return { type: LOAD_CURRENT_PROGRAM_DETAILS, content };
}
function loadProgramDetailsFailure() {
  return { type: LOAD_CURRENT_PROGRAM_DETAILS, content: [] };
}
function loadCurrentSeriesDetailsSuccess(content) {
  return { type: LOAD_CURRENT_SERIES_DETAILS, content };
}
function loadCurrentSeriesDetailsFailure() {
  return { type: LOAD_CURRENT_SERIES_DETAILS, content: [] };
}
function loadSeasonDataSuccess(content) {
  return { type: LOAD_PLAYBACK_SEASON_DATA, content };
}
function loadSeasonDataFailure() {
  return { type: LOAD_PLAYBACK_SEASON_DATA, content: [] };
}
function loadCurrentSeasonDetailsSuccess(content) {
  return { type: LOAD_CURRENT_SEASON_DETAILS, content };
}
function loadCurrentSeasonDetailsFailure() {
  return { type: LOAD_CURRENT_SEASON_DETAILS, content: [] };
}
function loadCurrentSeasonEntitlementsSuccess(content) {
  return { type: LOAD_CURRENT_SEASON_ENTITLEMENTS, content };
}
function loadCurrentSeasonEntitlementsFailure() {
  return { type: LOAD_CURRENT_SEASON_ENTITLEMENTS, content: [] };
}

export const loadProgramDetail = (appProvider, itemId, itemType) => {
  return async (dispatch) => {
    try {
      const feedContent = await getProgramDetail(appProvider, itemId, itemType);
      if (feedContent && feedContent.containers) {
        dispatch(loadProgramDetailsSuccess(feedContent.containers));
      }
    } catch (error) {
      dispatch(loadProgramDetailsFailure());
    }
  };
};

export const loadRecordingProgramDetail = (appProvider, itemId, itemType) => {
  return async (dispatch) => {
    try {
      const feedContent = await getRecordingProgramDetail(appProvider, itemId);
      if (feedContent && feedContent.containers) {
        dispatch(loadProgramDetailsSuccess(feedContent.containers));
      }
    } catch (error) {
      dispatch(loadProgramDetailsFailure());
    }
  };
};

export const showPurchaseAcknowledgement = () => {
  return { type: SHOW_PURCHASE_ACKNOWLEDGEMENT, content: true };
};

export const loadCurrentSeriesDetails = (appProvider, uaType, seriesId) => {
  return async (dispatch) => {
    try {
      const feedContent = await retrieveAvsItems(appProvider, `/PAGE/DETAILS/${uaType}/${seriesId}`);
      if (feedContent) {
        const seriesContentContainer = feedContent.containers?.find(
          (container) => container?.layout?.toLowerCase() === CONTAINER_LAYOUTS.CONTENT_DETAIL
        );
        const seriesContentUri = seriesContentContainer?.retrieveItems?.uri;
        if (seriesContentUri) {
          const seriesContent = await retrieveAvsItems(appProvider, seriesContentUri);
          if (seriesContent) {
            dispatch(loadCurrentSeriesDetailsSuccess(seriesContent.containers[0]));
          }
        } else {
          dispatch(loadCurrentSeriesDetailsFailure());
        }
      }
    } catch (error) {
      dispatch(loadCurrentSeriesDetailsFailure());
    }
  };
};

export const loadSeasons = (appProvider, SeriesId, seasons) => {
  return async (dispatch) => {
    if (!seasons) {
      try {
        const feedContent = await getSeasonData(appProvider, SeriesId);
        if (feedContent) {
          const sortedFeedContent = [...feedContent.containers]?.sort(
            (previousSeason, nextSeason) => nextSeason.metadata.season - previousSeason.metadata.season
          );
          dispatch(loadSeasonDataSuccess(sortedFeedContent));
        }
      } catch (error) {
        dispatch(loadSeasonDataFailure());
      }
    } else if (seasons?.length > 0) {
      dispatch(loadSeasonDataSuccess(seasons));
    } else {
      dispatch(loadSeasonDataFailure());
    }
  };
};

export const loadCurrentSeasonDetails = (appProvider, currentSeason) => {
  const seasonUri = currentSeason?.retrieveItems?.uri;
  return async (dispatch) => {
    try {
      const feedContent = await retrieveAvsItems(appProvider, seasonUri);
      const seasonContainer = feedContent?.containers?.find(
        (container) => container.layout?.toLowerCase() === CONTAINER_LAYOUTS.BUNDLE_ITEM
      );
      if (seasonContainer) {
        dispatch(loadCurrentSeasonDetailsSuccess(seasonContainer.containers));
      } else {
        dispatch(loadCurrentSeasonDetailsFailure());
      }
    } catch (error) {
      dispatch(loadCurrentSeasonDetailsFailure());
    }
  };
};

export const loadCurrentSeasonEntitlements = (appProvider, seasonId) => {
  return async (dispatch) => {
    try {
      const feedContent = await getContentUserData(appProvider, seasonId, PAGE_CONTENT_ITEM_TYPES.season);
      if (feedContent) {
        if (feedContent.containers && feedContent.containers.length > 0) {
          dispatch(loadCurrentSeasonEntitlementsSuccess(feedContent.containers));
        }
      }
    } catch (error) {
      dispatch(loadCurrentSeasonEntitlementsFailure());
    }
  };
};
