import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import "./style.scss";
import { connect, useDispatch } from "react-redux";
import { useReducers } from "../../shared/hooks/useReducer";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  showModalPopup,
  showToastNotification,
  showRecordingSettingsPanel,
  loadUserSubscribedChannels,
  toggleSpinningLoaderAction,
  resetAction,
} from "../../App/state/actions";
import {
  getRecordingAction,
  toggleSettingsPanelAction,
  GET_RECORDINGS,
  EDIT_RECORDINGS,
  DELETE_RECORDINGS,
  MANIPULATE_RECORDING_ACTION_TRIGGERED,
  loadRecordingsBookmark,
} from "./state/actions";
import {
  getRecordingSystemType,
  checkRecordingStatus,
  getCPVRRecordingStatus,
  isCPVRRecordingRecorded,
  isCPVRRecordingInProgress,
  isCPVRRecordingScheduled,
  navigateToPVRManager,
  isRecordingPendingStateCheck,
  updateFilterSessionStorage,
  getFilterSessionStorage,
} from "../../shared/utils/recordingHelper";
import RecordingSidePanel from "../../components/RecordingSidePanel";
import constants from "../../shared/constants";
import recordingConstants from "../../shared/constants/recordingConstants";
import errors from "../../shared/constants/error";
import RecordingItem from "../../components/RecordingItem";
import ImageButton from "../../components/ImageButton";
import FilterDropdown from "../../components/FilterDropdown";
import { mapRecordingSeriesMediaContent, mapMRRecordingMediaContent } from "../../shared/analytics/helpers";
import { trackGenericAction, trackRecordingError } from "../../shared/analytics/dataLayer";
import { getAVSPosterArtImage } from "../../shared/utils/image";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import {
  LINK_INFO,
  ANALYTICS_STORAGE_KEYS,
  EXTRA_METADATA_TYPES,
  MAPPED_CONTENT_TYPES,
  ANALYTICS_EVENT_TYPES,
} from "../../shared/constants/analytics";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import { getFeatureProperties } from "../../shared/utils";
import { logNREvent } from "../../shared/analytics/newRelic";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";

const { REDUCER_TYPE } = constants;

/**
 * Recordings page component
 * Display the main recordings page for the user for MR and CPVR
 * All the scheduled, recorded and conflicted recordings will be listed here from where we can edit, delete and manage conflicts fo recordings.
 * @component
 * @param {Object} props
 */

const { MODAL_TYPES, IMAGES, PAGE_CONTENT_ITEM_TYPES } = constants;
const {
  RECORDING_PARAMS,
  RECORDING_REFINEMENTS,
  RECORDING_PACKAGES,
  MR_RECORDING_STATUS,
  MR_RECORDING_FILTER_OPTIONS,
} = recordingConstants;

const { AVS_ERROR_CODES } = errors;

const SORT_OPTIONS = RECORDING_REFINEMENTS.SORT_OPTIONS;
const FILTER_OPTIONS = RECORDING_REFINEMENTS.FILTER_OPTIONS;
const DEFAULT_FILTER_OPTION = FILTER_OPTIONS[0].values[0];
const DEFAULT_SORT_OPTION = SORT_OPTIONS[0].values[0];

const SORT_ICON = process.env.PUBLIC_URL + "/images/Sort_Icon.svg";
const SORT_ICON_ACTIVE = process.env.PUBLIC_URL + "/images/Sort_Icon_Active.svg";
const FILTER_ICON = process.env.PUBLIC_URL + "/images/Filter_Icon.svg";
const FILTER_ICON_ACTIVE = process.env.PUBLIC_URL + "/images/Filter_Icon_Active.svg";
const EDIT_ICON = process.env.PUBLIC_URL + "/images/Pen_Icon.svg";
const CANCEL_SERIES_ICON = process.env.PUBLIC_URL + "/images/Cancel_Series.svg";
const DELETE_ICON = process.env.PUBLIC_URL + "/images/Delete_Icon.svg";
const SERIES_VIEW_ALL_KEY = "recordingId";
const SORT_ORDER_KEY = "sortOrder";
const SORT_BY_KEY = "orderBy";
const FILTER_BY_KEY = "filterBy";

function RecordingsPage(props) {
  const {
    appProvider,
    userProfile,
    getRecordingAction,
    recordingsList,
    getRecordingError,
    editRecordingResponse,
    deleteRecordingResponse,
    manipulatedRecordingParams,
    showModalPopup,
    showToastNotification,
    showRecordingSettingsPanel,
    subscribedChannels,
    loadUserSubscribedChannels,
    toggleSettingsPanelAction,
    isRequestLoading,
    toggleSpinningLoaderAction,
    resetAction,
    featureToggles,
    recordingProfileData,
  } = props;
  const { isUserProfilesEnabled } = featureToggles;
  const { t: translate } = useTranslation();
  const { trackPageView, resetIsPageViewTracked } = useTrackPageView();
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const [refinementListType, setRefinementListType] = useState(null);
  const [showDropdownList, setShowDropdownList] = useState(false);
  const [recordingActionError, setRecordingActionError] = useState(null);
  const { bookmarks } = useReducers(REDUCER_TYPE.RECORDING);
  const isActionInProgress = useRef(false);
  const requiresInitialFetch = useRef(true);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const prevUrlQueryString = useRef(location.search);
  const urlQueryString = location.search;

  let seriesTitle = "",
    episodeCountString = "";
  // When navigating to the recordings page for the first time, we need to update the url to include info about which section
  // to display. We default to the recorded section.
  if (!urlQueryString.includes(RECORDING_PARAMS.SCHEDULED) && !urlQueryString.includes(RECORDING_PARAMS.RECORDED)) {
    history.replace(`${location.pathname}?${RECORDING_PARAMS.RECORDED}`);
  }

  const urlQueryParams = useMemo(() => new URLSearchParams(urlQueryString), [urlQueryString]);
  const selectedSeriesId = urlQueryParams.get(SERIES_VIEW_ALL_KEY);

  // If there are any supported sort query params in the URL, then we consider that as the currently selected sort option
  const selectedSortOption =
    (urlQueryParams.has(SORT_BY_KEY) &&
      urlQueryParams.has(SORT_ORDER_KEY) &&
      SORT_OPTIONS[0].values.find((option) => {
        return (
          option.value.includes(urlQueryParams.get(SORT_BY_KEY)) &&
          option.value.includes(urlQueryParams.get(SORT_ORDER_KEY))
        );
      })) ||
    DEFAULT_SORT_OPTION;

  // If there are any supported filter query params in the URL, then we consider that as the currently selected filter option
  const selectedFilterOption =
    (urlQueryParams.has(FILTER_BY_KEY) &&
      FILTER_OPTIONS[0].values.find((option) => {
        return option.value.includes(urlQueryParams.get(FILTER_BY_KEY));
      })) ||
    DEFAULT_FILTER_OPTION;

  const isSortApplied = selectedSortOption.label !== DEFAULT_SORT_OPTION.label;
  const isFilterApplied = selectedFilterOption.label !== DEFAULT_FILTER_OPTION.label;

  const isScheduleClicked = urlQueryString.includes(RECORDING_PARAMS.SCHEDULED);
  const recordingSystemType = useMemo(
    () => (userProfile?.isLoggedIn ? getRecordingSystemType(userProfile) : null),
    [userProfile]
  );
  const isMR = recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;
  const isCPVRPanicEnabled =
    !isMR &&
    (getRecordingError?.code === AVS_ERROR_CODES.PANIC_MODE ||
      getRecordingError?.code === AVS_ERROR_CODES.CPVR_AGL_PANIC_MODE ||
      getRecordingError?.code === AVS_ERROR_CODES.CPVR_SERIES_PANIC_MODE);
  // Represents the number of days recordings will be available on CPVR before being removed
  const recordingAvailabilityDays = (!isMR && recordingProfileData?.keepDays) || "";

  // recordingFetchFilters will only be used for the MR system and only when enabled in the feature toggle
  const recordingFetchFilters = useMemo(() => {
    if (isMR && getFeatureProperties(appProvider, "RemoteRecordings")?.useFilteredGet) {
      return MR_RECORDING_FILTER_OPTIONS[`${isScheduleClicked ? "SCHEDULED_PAGE" : "ALL"}_FILTERS`];
    }
    return undefined;
  }, [appProvider, isMR, isScheduleClicked]);

  // MR specific array of modified recording definitions
  const modifiedRecordingDefinitions = useMemo(() => {
    if (isMR && recordingsList?.length > 0) {
      const currentTime = moment().valueOf();
      // Making a modified array of recordings with the values needed for edit and UI of the page.
      return recordingsList
        .filter((recordingDefinition) => recordingDefinition.recordings?.length > 0)
        .map((recordingDefinition) => {
          const isRecordingRecorded = checkRecordingStatus(recordingDefinition, MR_RECORDING_STATUS.RECORDED); // check if all recordings within the definition are "Recorded"
          const isRecordingNow = checkRecordingStatus(recordingDefinition, MR_RECORDING_STATUS.RECORDING); // check if the any recording within the definition is "Recording"
          const channelName = recordingDefinition.recordings.find((recording) => recording.metadata?.channelName)
            ?.metadata?.channelName; // Using this because it's not a guarantee that we get `channelName` in `recordingDefinition.recordings[0].metadata`
          const isAnyRecordingPartiallyRecorded =
            !isRecordingRecorded &&
            recordingDefinition.recordings.some((recording) => {
              return recording.programStartTime <= currentTime;
            });

          let recordedEpisodes = [],
            scheduledEpisodes = [];
          // Manually splitting the recorded and scheduled episodes from a series recording into separate arrays
          recordingDefinition.recordings.forEach((recording) => {
            const recordingEndTime = recording.programStartTime + recording.duration * 1000;
            let recordingState;
            if (recording.programStartTime <= currentTime && currentTime <= recordingEndTime) {
              recordingState = MR_RECORDING_STATUS.RECORDING;
            } else if (currentTime < recordingEndTime) {
              recordingState = MR_RECORDING_STATUS.SCHEDULED;
            } else {
              recordingState = MR_RECORDING_STATUS.RECORDED;
            }

            // First we check the statuses which we have to trust from the MR STB
            if (recording.status === MR_RECORDING_STATUS.RECORDED) {
              recordedEpisodes.push(recording);
            } else if (recording.status === MR_RECORDING_STATUS.CANCELLED) {
              // Check the program start time to determine if the recording was cancelled while in-progress (partial recording created)
              if (recording.programStartTime <= currentTime) {
                recordedEpisodes.push(recording);
              }
            } else if (recording.status === MR_RECORDING_STATUS.CONFLICTED) {
              scheduledEpisodes.push(recording);
            } else {
              // If the definition has a status we cannot trust, we refer to the client-side-calculated recordingState for our best guess at the status
              if (
                recordingState === MR_RECORDING_STATUS.SCHEDULED &&
                moment(recording.programStartTime).add(recording.duration, "seconds").valueOf() > currentTime
              ) {
                scheduledEpisodes.push(recording);
              } else {
                recordedEpisodes.push(recording);
              }
            }
          });

          return {
            ...recordingDefinition,
            postPadding: recordingDefinition.recordings[0].postPadding,
            schedulingTime: recordingDefinition.recordings[0].schedulingTime,
            episodeScope: recordingDefinition.recordings[0].episodeScope,
            channelName,
            assetType: recordingDefinition.recordings[0].metadata?.uaGroupType,
            isRecordingRecorded,
            isRecordingNow,
            isAnyRecordingPartiallyRecorded,
            recordedItems: recordedEpisodes,
            scheduledItems: scheduledEpisodes,
            openRecordedEditPanel: !isScheduleClicked, // additional check to see which popup or text needs to be displayed when the user tries to edit the recordings(cancel or delete)
          };
        });
    }
    return null;
  }, [isMR, isScheduleClicked, recordingsList]);

  // CPVR specific array of recordings to show in the recorded section
  const recordedRecordingData = useMemo(() => {
    if (!isMR && recordingsList) {
      const recordedData = {
        recordings: [],
        seriesMap: null,
      };

      if (recordingsList.length > 0) {
        const eventRecordings =
          recordingsList.find((recordingResponse) => recordingResponse.recordingType === RECORDING_PARAMS.EVENT)
            ?.containers || [];
        const seriesRecordings =
          recordingsList.find((recordingResponse) => recordingResponse.recordingType === RECORDING_PARAMS.SERIES)
            ?.containers || [];
        let recordedRecordings = [];
        const recordedSeriesMap = new Map();
        for (let i = 0; i < eventRecordings.length; i++) {
          const recording = eventRecordings[i];
          // We need recordings to have metadata and extended metadata for functionality to work as expected
          if (recording.metadata?.extendedMetadata) {
            const recordingStatus = getCPVRRecordingStatus({ eventRecordingItem: recording });
            if (isCPVRRecordingRecorded(recordingStatus) || isCPVRRecordingInProgress(recordingStatus)) {
              if (recordingAvailabilityDays) {
                // User recording end time calculated as per the notes in https://telusvideoservices.atlassian.net/wiki/spaces/AD/pages/1680998507/Metadata+for+NPVR#:~:text=of%202%20minutes-,startDeltaTime,-Integer
                const userRecordingEndTime = recording.metadata.recordingStartTime + recording.metadata.stopDeltaTime;
                // Add a field for the recording expiry time since the backend doesn't figure this out for us
                recording.metadata.recordingExpiryTime =
                  userRecordingEndTime + parseInt(recordingAvailabilityDays) * 86400000;
              }
              const programSeriesId = recording.metadata.programSeriesId;
              if (programSeriesId) {
                updateCPVRSeriesGroup(
                  recording,
                  false,
                  programSeriesId,
                  seriesRecordings,
                  recordedSeriesMap,
                  recordedRecordings,
                  isScheduleClicked
                );
              } else {
                // Event recordings can simply be added to the list
                recordedRecordings.push(recording);
              }
            }
          }
        }
        recordedData.recordings = recordedRecordings;
        recordedData.seriesMap = recordedSeriesMap;
      }
      return recordedData;
    } else {
      return null;
    }
  }, [isMR, isScheduleClicked, recordingAvailabilityDays, recordingsList]);

  // CPVR specific array of recordings to show in the scheduled section
  const scheduledRecordingData = useMemo(() => {
    if (!isMR && recordingsList) {
      const scheduledData = {
        recordings: [],
        seriesMap: null,
      };

      if (recordingsList.length > 0) {
        const eventRecordings =
          recordingsList.find((recordingResponse) => recordingResponse.recordingType === RECORDING_PARAMS.EVENT)
            ?.containers || [];
        const seriesContainers =
          recordingsList.find((recordingResponse) => recordingResponse.recordingType === RECORDING_PARAMS.SERIES)
            ?.containers || [];
        // There were duplicate series getting returned from the BE. Hence filtering those out
        const mapSeriesRecording = new Map(seriesContainers?.map((c) => [c.id, c]));
        const seriesRecordings = [...mapSeriesRecording.values()];
        const scheduledRecordings = [];
        const scheduledSeriesMap = new Map();
        for (let i = 0; i < eventRecordings.length; i++) {
          const recording = eventRecordings[i];
          // We need recordings to have metadata and extended metadata for functionality to work as expected
          if (recording.metadata?.extendedMetadata) {
            const recordingStatus = getCPVRRecordingStatus({ eventRecordingItem: recording });
            if (isCPVRRecordingScheduled(recordingStatus)) {
              const recordingSeriesId = recording.metadata.recordingSeriesId;
              if (recordingSeriesId) {
                updateCPVRSeriesGroup(
                  recording,
                  true,
                  recordingSeriesId,
                  seriesRecordings,
                  scheduledSeriesMap,
                  scheduledRecordings,
                  isScheduleClicked
                );
              } else {
                // Event recordings can simply be added to the list
                scheduledRecordings.push(recording);
              }
            }
          }
        }
        const filteredSeriesRecordings = seriesRecordings.filter((seriesRec) => {
          return !scheduledRecordings?.some((scheduledRec) => {
            return seriesRec.id === scheduledRec.id;
          });
        });
        const filteredRecordingArray = [];
        const filteredSeriesRecordingsMap = new Map();
        for (let i = 0; i < filteredSeriesRecordings?.length; i++) {
          const recording = filteredSeriesRecordings[i];
          filteredRecordingArray.push(recording);
          filteredSeriesRecordingsMap.set(recording.metadata.recordingSeriesId, filteredRecordingArray.length - 1);
        }
        const result = new Map([...filteredSeriesRecordingsMap]);

        scheduledSeriesMap.forEach((value, key) => {
          result.set(key, value + filteredSeriesRecordings?.length);
        });

        scheduledData.recordings = filteredSeriesRecordings?.concat(scheduledRecordings);
        scheduledData.seriesMap = result;
      }
      return scheduledData;
    } else {
      return null;
    }
  }, [isMR, isScheduleClicked, recordingsList]);

  // Array of recordings/recording definitions used to create & display recording items
  const recordingsToDisplay = useMemo(() => {
    if (recordingsList) {
      let recordingsArray = [];
      if (recordingsList.length) {
        if (isMR) {
          const currentTime = moment().valueOf();
          // Making a modified array of recordings with the values needed for edit and UI of the page.
          let episodeArray = [];
          recordingsArray =
            modifiedRecordingDefinitions?.filter((recordingDefinition) => {
              let displayRecordingDefinition = false;
              /**
               * Displaying a recording only if it is
               * a) a series recording
               * b) an event recording that is associated with a program that has an airing end time in the future (includes programs that are airing now)
               * c) a recorded recording
               * We do not show recordings that are partially recorded(recording.status = CANCELLED) but if any recording has aired and
               * should ideally be in "Recording" or "Recorded" status then we show the recording with the "Recording now" badge or "Recorded" status on the UI respectively.
               * This logic is a bit complex due to the fact that we cannot rely on MR STBs being online always.
               * TODO: Need to simplify this method once we are sure STBs are working and are online.
               */
              if (
                recordingDefinition.recordingType === RECORDING_PARAMS.SERIES.toUpperCase() ||
                (recordingDefinition.recordingType === RECORDING_PARAMS.EVENT.toUpperCase() &&
                  moment(recordingDefinition.utcStartTime)
                    .add(recordingDefinition.recordings[0].duration, "seconds")
                    .valueOf() > currentTime) ||
                recordingDefinition.isRecordingRecorded ||
                recordingDefinition.isAnyRecordingPartiallyRecorded
              ) {
                // If a series is selected, we use the list of episode recordings associated with the series recording
                if (selectedSeriesId && selectedSeriesId === recordingDefinition.recordingDefinitionId) {
                  if (isScheduleClicked && recordingDefinition.scheduledItems) {
                    recordingDefinition.recordings = recordingDefinition.scheduledItems;
                  } else if (recordingDefinition.recordedItems) {
                    recordingDefinition.recordings = recordingDefinition.recordedItems;
                  }

                  for (let i = 0; i < recordingDefinition.recordings.length; i++) {
                    const recording = recordingDefinition.recordings[i];
                    episodeArray.push({
                      ...recording,
                      recordingType: RECORDING_PARAMS.EVENT,
                      isProgramOfSeries: true,
                      channelNumber: recordingDefinition.channelNumber,
                      areMultipleEpisodes: recordingDefinition.recordings.length > 1, // Does recording have multiple episodes
                      recordingDefinitionId: recordingDefinition.recordingDefinitionId,
                      isRecordingNow:
                        recording.programStartTime <= currentTime &&
                        currentTime <= recording.programStartTime + recording.duration * 1000,
                    });
                  }
                } else {
                  displayRecordingDefinition = !isScheduleClicked
                    ? recordingDefinition.isRecordingRecorded || recordingDefinition.isAnyRecordingPartiallyRecorded
                    : recordingDefinition.scheduledItems?.length > 0;
                }
              }
              return displayRecordingDefinition;
            }) || [];

          if (episodeArray.length) {
            recordingsArray = isScheduleClicked ? episodeArray.reverse() : episodeArray;
          }
        } else {
          const recordingData = isScheduleClicked ? scheduledRecordingData : recordedRecordingData;
          if (recordingData) {
            if (selectedSeriesId) {
              // If selectedSeriesId exists, then we want to set the recording list to the episode recording list for the selected series
              const selectedSeriesRecordingIndex = recordingData.seriesMap?.get(parseInt(selectedSeriesId));
              const filteredRecordings = recordingData.recordings;
              if (selectedSeriesRecordingIndex !== undefined) {
                const selectedSeriesRecording = filteredRecordings[selectedSeriesRecordingIndex];
                if (selectedSeriesRecording?.episodeRecordings?.length) {
                  recordingsArray = recordingsArray.concat(selectedSeriesRecording.episodeRecordings);
                }
              }
            } else {
              recordingsArray = recordingsArray.concat(recordingData.recordings);
            }
          }
        }

        if (!selectedSeriesId) {
          // Filter as appropriate
          const filterFunc = getFilterCompareFunction(isMR, selectedFilterOption.label);
          if (filterFunc) {
            recordingsArray = recordingsArray.filter(filterFunc);
          }
        }

        // Sort as appropriate
        const sortFunc = getSortCompareFunction(isMR, selectedSortOption.label, isScheduleClicked);
        if (sortFunc) {
          recordingsArray.sort(sortFunc);
        }
      }

      return recordingsArray;
    }
  }, [
    isMR,
    isScheduleClicked,
    modifiedRecordingDefinitions,
    recordedRecordingData,
    recordingsList,
    scheduledRecordingData,
    selectedFilterOption.label,
    selectedSeriesId,
    selectedSortOption.label,
  ]);

  // the series recording/recording definition that is used to display the series recording episode list page state
  const selectedSeriesRecordingItem = useMemo(() => {
    if (selectedSeriesId) {
      if (isMR) {
        if (modifiedRecordingDefinitions?.length > 0) {
          for (let i = 0; i < modifiedRecordingDefinitions.length; i++) {
            // we only want to return the definition if it has relevant recordings still
            if (
              modifiedRecordingDefinitions[i].recordingDefinitionId === selectedSeriesId &&
              ((isScheduleClicked && modifiedRecordingDefinitions[i].scheduledItems.length > 0) ||
                (!isScheduleClicked && modifiedRecordingDefinitions[i].recordedItems.length > 0))
            ) {
              return modifiedRecordingDefinitions[i];
            }
          }
        }
      } else {
        const recordingData = isScheduleClicked ? scheduledRecordingData : recordedRecordingData;
        const selectedSeriesRecordingIndex = recordingData?.seriesMap?.get(parseInt(selectedSeriesId));
        if (selectedSeriesRecordingIndex !== undefined) {
          return recordingData.recordings[selectedSeriesRecordingIndex];
        }
      }
    }
    return null;
  }, [
    isMR,
    isScheduleClicked,
    modifiedRecordingDefinitions,
    recordedRecordingData,
    scheduledRecordingData,
    selectedSeriesId,
  ]);

  /**
   * Fetch the user's recordings and save the response in redux
   *
   * @param {Object} recordingParamsMR - MR specific recording params used for edit/cancel/delete recording when initial attempt reports PENDING status
   * @param {Boolean} fetchingAfterAction - flag used to differentiate between initial fetch and post-action fetches
   */
  const fetchRecordings = useCallback(
    (recordingParamsMR = null, fetchingAfterAction = false) => {
      isActionInProgress.current = fetchingAfterAction;
      if (isMR) {
        getRecordingAction(appProvider, true, null, null, null, recordingFetchFilters, recordingParamsMR);
      } else {
        getRecordingAction(appProvider, false, true, null, [RECORDING_PARAMS.EVENT, RECORDING_PARAMS.SERIES]);
      }
    },
    [appProvider, getRecordingAction, isMR, recordingFetchFilters]
  );

  const fetchBookmarks = useCallback(() => {
    dispatch(loadRecordingsBookmark(appProvider));
  }, [appProvider, dispatch]);

  // Reset the recordings params associated with an outgoing recording manipulation action (set, edit, delete)
  const resetManipulatedRecordingParams = useCallback(() => {
    resetAction(MANIPULATE_RECORDING_ACTION_TRIGGERED, "recordingParams");
  }, [resetAction]);

  // Reset the response error from fetching recordings
  const resetGetRecordingError = useCallback(() => {
    resetAction(GET_RECORDINGS, "error");
  }, [resetAction]);

  // Reset the response data from editing a recording
  const resetEditRecordingResponse = useCallback(() => {
    resetAction(EDIT_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response data from deleting a recording
  const resetDeleteRecordingResponse = useCallback(() => {
    resetAction(DELETE_RECORDINGS, "content");
  }, [resetAction]);

  // Resets the values used to support recording CRUD action flows
  const resetRecordingActionValues = useCallback(() => {
    isActionInProgress.current = false;
    resetEditRecordingResponse();
    resetDeleteRecordingResponse();
    resetManipulatedRecordingParams();
    resetGetRecordingError();
    setRecordingActionError(null);
  }, [
    resetEditRecordingResponse,
    resetDeleteRecordingResponse,
    resetGetRecordingError,
    resetManipulatedRecordingParams,
  ]);

  /**
   * a call back function which will be triggered after a recording had been updated (edit/stop/delete)
   * @param {Object} recordingItem
   * @param {Boolean} isItemLive
   * @param {Boolean} isSeriesEvent
   * @param {String} analyticsEventType analytics event type
   * @param {String} toolSelections a toolSelection string with format: 'key:{selection},key:{selection}...'
   * @param {Object} error an error object
   */
  const trackRecordingUpdateEventCallback = useCallback(
    ({ recordingItem, isItemLive, isSeriesEvent, analyticsEventType, toolSelections, error }) => {
      if (error) {
        // Triggers a stop|delete recording error.
        // The error of start a recording comes from setRecordingResponse data which is handled via useEffect.
        trackRecordingError(analyticsEventType, error?.message, {
          errorCode: error?.code,
        });
      } else {
        // The metadata of MR recording is not sufficient currently, thus special handling is needed.
        const getRecordingMetaData = () => {
          if (isMR) {
            const mediaMetadata = {
              ...selectedSeriesRecordingItem,
              ...(isScheduleClicked
                ? { ...selectedSeriesRecordingItem?.scheduledItems?.[0] }
                : { ...recordingItem?.recordedItems?.[0] }),
            };
            return isSeriesEvent
              ? mapRecordingSeriesMediaContent(mediaMetadata)
              : mapMRRecordingMediaContent(mediaMetadata);
          } else {
            return isSeriesEvent
              ? mapRecordingSeriesMediaContent(selectedSeriesRecordingItem || recordingItem)
              : recordingItem?.eventRecordingItem || recordingItem;
          }
        };
        trackGenericAction(analyticsEventType, {
          isItemLive,
          isSeriesEvent,
          toolSelections,
          mappedContentType: isScheduleClicked
            ? MAPPED_CONTENT_TYPES.LIVE
            : isMR
            ? MAPPED_CONTENT_TYPES.LPVR
            : MAPPED_CONTENT_TYPES.CPVR,
          ...getRecordingMetaData(),
        });
      }
    },
    [isMR, isScheduleClicked, selectedSeriesRecordingItem]
  );

  /**
   * Determines the appropriate recording toast message to display
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {String} actionType - type of recording action that occurred (set/edit/delete)
   * @param {String} recordingType - type of the recording that is associated with the action
   * @param {Object} actionError - error response from any recording action (set/edit/delete/get)
   * @returns {String} recordings-specific toast message
   */
  const getRecordingToastMessage = useCallback(
    (recordingInfo, actionType, recordingType, actionError) => {
      let toastMsg;
      if (actionError?.code) {
        if (actionType === "edit") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_NOT_UPDATED" : "RECORDING_NOT_UPDATED"
            ]
          );
        } else if (actionType === "delete") {
          let msgKey;
          if (isMR) {
            if (actionError.code.toLowerCase().includes("cancel")) {
              msgKey = "COULD_NOT_CANCEL_RECORDING";
            } else {
              msgKey =
                recordingType === RECORDING_PARAMS.EVENT
                  ? "COULD_NOT_DELETE_RECORDING"
                  : "SERIES_RECORDING_NOT_DELETED";
            }
          } else {
            if (
              isScheduleClicked ||
              (recordingType === RECORDING_PARAMS.EVENT && isCPVRRecordingInProgress(recordingInfo.eventRecordingItem))
            ) {
              msgKey = "COULD_NOT_CANCEL_RECORDING";
            } else {
              msgKey =
                recordingType === RECORDING_PARAMS.EVENT
                  ? "COULD_NOT_DELETE_RECORDING"
                  : "SERIES_RECORDING_NOT_DELETED";
            }
          }
          toastMsg = translate(RECORDING_PARAMS[msgKey]);
        }
      } else {
        if (actionType === "edit") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_UPDATED" : "RECORDING_UPDATED"
            ]
          );
        } else if (actionType === "delete") {
          let seriesKey, eventKey;
          if (isMR) {
            if (isScheduleClicked || (recordingInfo.isRecordingNow && recordingType === RECORDING_PARAMS.EVENT)) {
              seriesKey = "SERIES_RECORDING_CANCELLED";
              eventKey = "RECORDING_CANCELLED";
            } else {
              seriesKey = "SERIES_RECORDINGS_DELETED";
              eventKey = "RECORDING_DELETED";
            }
          } else {
            seriesKey = isScheduleClicked ? "SERIES_RECORDING_CANCELLED" : "SERIES_RECORDINGS_DELETED"; // Cannot delete CPVR series recordings due to backend limitation [Jan 16, 2023]
            if (
              isScheduleClicked ||
              (recordingType === RECORDING_PARAMS.EVENT && isCPVRRecordingInProgress(recordingInfo.eventRecordingItem))
            ) {
              eventKey = "RECORDING_CANCELLED";
            } else {
              eventKey = "RECORDING_DELETED";
            }
          }
          toastMsg = translate(RECORDING_PARAMS[recordingType === RECORDING_PARAMS.SERIES ? seriesKey : eventKey]);
        }
      }

      return toastMsg;
    },
    [isMR, isScheduleClicked, translate]
  );

  /**
   * Displays the appropriate recording toast message (if any)
   *
   * @param {Object} manipulatedRecordingParams - refer to manipulateActionTriggered() in RecordingsPage/state/actions.js
   * @param {Object} actionError - error response from any recording action (set/edit/delete/get)
   */
  const displayRecordingToast = useCallback(
    (manipulatedRecordingParams, actionError) => {
      if (manipulatedRecordingParams) {
        const toastMsg = getRecordingToastMessage(
          manipulatedRecordingParams.targetAssetInfo,
          manipulatedRecordingParams.actionType,
          manipulatedRecordingParams.recordingType,
          actionError
        );
        if (toastMsg) {
          showToastNotification(toastMsg);
        }
      }
    },
    [getRecordingToastMessage, showToastNotification]
  );

  /**
   * Updates the recording page to display the series recording episode list state when a series recording item is selected
   *
   * @param {String} seriesRecordId
   * @param {Number} itemIndex
   */
  const openSeriesViewAll = useCallback(
    (seriesRecordId, itemIndex) => {
      if (seriesRecordId) {
        const queryParamString = urlQueryString.split(
          isScheduleClicked ? RECORDING_PARAMS.SCHEDULED : RECORDING_PARAMS.RECORDED
        )?.[1];
        navigateToPVRManager(
          isScheduleClicked,
          `${queryParamString ? "&" + queryParamString : ""}&${SERIES_VIEW_ALL_KEY}=${seriesRecordId}`
        );
        setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${itemIndex + 1};${LINK_INFO.RECORDING_ITEM_TITLE}`);
      }
    },
    [isScheduleClicked, urlQueryString]
  );

  /**
   * Click handler for series and individual recording edit buttons
   *
   * @param {Object} recordingInfo Custom recording info objects for the PVR manager, dynamic in structure
   * @param {String} typeOfRecording type of recording (event or series)
   */
  const editRecordingClickHandler = useCallback(
    (recordingInfo, typeOfRecording) => {
      if (recordingInfo) {
        toggleSettingsPanelAction(true);
        // We need to determine if we need to call the TRAY/SEARCH/PROGRAM call for fetching schedules.
        // We do not need to fetch the schedules for MR.
        const isScheduleNeeded = !isMR;
        // Spinning loader implementation on the recordingSettingsPanel.
        isScheduleNeeded && toggleSpinningLoaderAction(true, "loader-wrapper setting-spinner-loader");
        trackRecordingUpdateEventCallback({
          isItemLive: !isScheduleClicked,
          isSeriesEvent: typeOfRecording === RECORDING_PARAMS.SERIES,
          recordingItem: recordingInfo,
          analyticsEventType:
            typeOfRecording === RECORDING_PARAMS.SERIES
              ? ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_START
              : ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_START,
          mappedContentType: !isScheduleClicked
            ? MAPPED_CONTENT_TYPES.LIVE
            : isMR
            ? MAPPED_CONTENT_TYPES.LPVR
            : MAPPED_CONTENT_TYPES.CPVR,
        });
        showRecordingSettingsPanel(
          typeOfRecording,
          recordingInfo,
          null,
          true,
          trackRecordingUpdateEventCallback,
          isScheduleNeeded
        );
      }
    },
    [
      isMR,
      isScheduleClicked,
      showRecordingSettingsPanel,
      toggleSettingsPanelAction,
      toggleSpinningLoaderAction,
      trackRecordingUpdateEventCallback,
    ]
  );

  /**
   * Click handler for series and individual recording cancel/delete buttons
   *
   * @param {Object} recordingData Custom recording info objects for the PVR manager, dynamic in structure
   * @param {Boolean} isProgramOfSeries Flag indicating if selected item is an episode recording that is part of a series recording
   * @param {Boolean} isRecordingRecorded Flag indicating if the recording is recorded (not recording now)
   * @param {Boolean} isRecordingNow Flag indicating if the recording is recording now
   */
  const cancelRecordingClickHandler = useCallback(
    (recordingData, isProgramOfSeries, isRecordingRecorded, isRecordingNow) => {
      let recordingInfo, recordingType;
      if (isMR) {
        recordingInfo = recordingData;
        recordingType = recordingData?.recordingType.toLowerCase();
      } else {
        if (recordingData?.layout === "SERIES_NPVR_ITEM" || recordingData?.episodeRecordings) {
          recordingInfo = { seriesRecordingItem: recordingData };
          recordingType = RECORDING_PARAMS.SERIES;
        } else {
          recordingInfo = { eventRecordingItem: recordingData };
          recordingType = RECORDING_PARAMS.EVENT;
        }
      }

      const modalContent = {
        recordingInfo,
        programDetails: recordingData,
        isRecordingRecorded,
        isMR,
        isProgramOfSeries,
        recordingType,
        isRecordingNow,
      };
      showModalPopup(MODAL_TYPES.RECORDING, modalContent);
    },
    [isMR, showModalPopup]
  );
  const infoClickHandler = () => {
    if (isMR) {
      showModalPopup(MODAL_TYPES.ERROR, {
        title: "Recordings",
        message: translate("recordings_restriction_optik"),
        isCloseable: true,
        endButtonLabelOverride: translate("close"),
        endButtonClassOverride: "error-modal-btn-gray",
      });
    } else {
      showModalPopup(MODAL_TYPES.ERROR, {
        title: "Recordings",
        message: translate("cloud_pvr_availability").replace("%s", recordingAvailabilityDays),
        isCloseable: true,
        endButtonLabelOverride: translate("close"),
      });
    }
  };
  /**
   * Called when clicking on one of the tabs from the 'RecordingSidePanel' component
   * @param {string} type - Side panel tab type, "scheduled" or "recorded"
   */
  const sidePanelTabClickHandler = useCallback(
    (type) => {
      const isScheduledSelected = type === RECORDING_PARAMS.SCHEDULED;
      const isCurrentlyScheduled = isScheduleClicked;

      // Retrieve the stored data from sessionStorage based on the selected tab
      const storedData = getFilterSessionStorage(isScheduleClicked);
      const { filterBy, sortBy } = storedData[isScheduledSelected ? "SCHEDULED" : "RECORDED"] || {};

      // Don't do anything if clicking the tab we're already in
      if ((isCurrentlyScheduled && !isScheduledSelected) || (!isCurrentlyScheduled && isScheduledSelected)) {
        setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${type};${LINK_INFO.RECORDING_SIDEBAR}`);
        history.push(
          `${location.pathname}?${RECORDING_PARAMS[isScheduledSelected ? "SCHEDULED" : "RECORDED"]}${
            filterBy ? "&" + filterBy : ""
          }${sortBy ? "&" + sortBy : ""}`
        );
      }
    },
    [history, location, isScheduleClicked]
  );

  /**
   * Updates the URL with appropriate sort/filter query params when a sort/filter option is selected from the dropdown menu
   *
   * @param {String} type The refinement type, "sort" or "filter"
   * @param {Object[]} options Array of option categories (we only have one category for recordings page refinements as of June 1, 2022)
   * @param {Integer} categoryIndex Index of the selected category
   * @property {Object[]} options[categoryIndex].values Array of options for a specific category
   * @param {Integer} index Index of the selected option for a specific category
   */
  const refineOptionClickHandler = useCallback(
    (type, options, categoryIndex, index) => {
      const selectedOption = options[categoryIndex].values[index];
      let newLocation = `${location.pathname}?${RECORDING_PARAMS[isScheduleClicked ? "SCHEDULED" : "RECORDED"]}`;
      let filterByValue = "";
      let orderByValue = "";

      const storedData = getFilterSessionStorage(isScheduleClicked);
      const storedFilterBy = storedData[isScheduleClicked ? "SCHEDULED" : "RECORDED"]?.filterBy || "";
      const storedSortBy = storedData[isScheduleClicked ? "SCHEDULED" : "RECORDED"]?.sortBy || "";

      if (type === RECORDING_PARAMS.SORT) {
        filterByValue = storedFilterBy || urlQueryParams.get(FILTER_BY_KEY) || "";
        if (filterByValue) {
          newLocation += `&${FILTER_BY_KEY}=${filterByValue}`;
        }
        orderByValue = selectedOption.value ?? (storedSortBy || "");
        logNREvent(NR_PAGE_ACTIONS.RECORDING_SORT);
      } else {
        orderByValue = storedSortBy || urlQueryParams.get(SORT_BY_KEY) || "";
        const sortOrderValue = urlQueryParams.get(SORT_ORDER_KEY) || "";

        if (orderByValue && sortOrderValue) {
          newLocation += `&${SORT_BY_KEY}=${orderByValue}&${SORT_ORDER_KEY}=${sortOrderValue}`;
        }
        filterByValue = selectedOption.value ?? (storedFilterBy || "");
        logNREvent(NR_PAGE_ACTIONS.RECORDING_FILTER);
      }

      if (selectedOption.value) {
        newLocation += `&${selectedOption.value}`;
      }

      setRefinementListType(null);

      // Update sessionStorage with filterByValue and orderByValue
      updateFilterSessionStorage(isScheduleClicked ? "SCHEDULED" : "RECORDED", filterByValue, orderByValue);

      history.replace(newLocation);
    },
    [history, location, isScheduleClicked, urlQueryParams]
  );

  /**
   * Builds the refinement option lists for the provided refinement type
   *
   * @param {String} type The refinement type, "sort" or "filter"
   * @return {Object[]} Array of refinement option lists
   */
  const getRefineOptionList = useCallback(
    (type) => {
      let options, selectedOption;
      if (type === RECORDING_PARAMS.SORT) {
        options = SORT_OPTIONS;
        selectedOption = selectedSortOption;
      } else {
        options = FILTER_OPTIONS;
        selectedOption = selectedFilterOption;
      }

      return options.map((element) => {
        return {
          items: {
            label: translate(options[0].label),
            items: element.values.map((element) => {
              return { value: element.label };
            }),
            isLocalized: true,
          },
          linkClickHandler: (index, categoryIndex) => refineOptionClickHandler(type, options, categoryIndex, index),
          type: "radio",
          selectedItem: selectedOption.label || options[0].values[0].label,
        };
      });
    },
    [refineOptionClickHandler, selectedFilterOption, selectedSortOption, translate]
  );

  /**
   * Function to open the dropdownlist depending on the type that is sent
   * @param {string} type = filter or sort
   */
  const refineButtonClickHandler = (type) => {
    setRefinementListType(type);
  };
  const handleFilterButtonClick = (type) => {
    setShowDropdownList(true);
    setRefinementListType(type);
    refineButtonClickHandler(type);
  };
  /**
   * Hides the sort/filter dropdown pop up when clicked outside of the sort dropdown
   */
  const handleOutsideComponentClick = () => {
    setRefinementListType(null);
  };

  /**
   * Determines the appropriate page title to use based on the recordings count and if a filter is applied
   *
   * @param {Integer} recordingsCount Number of recordings available to display
   * @returns {String} page title string
   */
  const getPageTitle = (recordingsCount) => {
    if (recordingsCount > 0 || isFilterApplied) {
      let titleKey = "all_recorded";
      if (urlQueryString.includes("TVSHOW")) {
        titleKey = "series";
      } else if (urlQueryString.includes("MOVIE")) {
        titleKey = "parental_movies";
      } else if (isScheduleClicked) {
        titleKey = "all_scheduled";
      }

      return `${translate(titleKey)} (${recordingsCount})`;
    } else {
      return translate(isScheduleClicked ? "no_scheduled_content" : RECORDING_PARAMS.NO_RECORDED_CONTENT);
    }
  };

  /**
   * Used to build the list of components to render on the page, excluding the side panel
   *
   * @returns {JSX}
   */
  const getRecordingsSectionComponents = () => {
    const components = [];
    const headerComponents = [];
    if (!isRequestLoading) {
      if (recordingsToDisplay) {
        if (!selectedSeriesId) {
          headerComponents.push(<span key="pageTitle">{getPageTitle(recordingsToDisplay.length)}</span>);

          if (recordingsToDisplay.length || isFilterApplied) {
            headerComponents.push(
              <div key="refineComponents" className="recording-refine-components-container">
                <div key="refineButtons" className="refine-buttons-container">
                  <FilterDropdown
                    filterImage={isFilterApplied ? FILTER_ICON_ACTIVE : FILTER_ICON}
                    showDropdownList={showDropdownList && refinementListType === RECORDING_PARAMS.FILTER}
                    button={{
                      label: translate("filter"),
                      buttonClickHandler: () => handleFilterButtonClick(RECORDING_PARAMS.FILTER),
                      isFilterApplied: isFilterApplied,
                      buttonStyles: `${
                        refinementListType === RECORDING_PARAMS.FILTER ? "filter-icon clicked" : "filter-icon"
                      } ${isFilterApplied ? "filter-applied" : ""}`,
                    }}
                    lists={getRefineOptionList(refinementListType)}
                    popUp={{
                      popUpStyles: "recording-refinement-list",
                      handleOutsideComponentClick,
                    }}
                  />
                  <FilterDropdown
                    filterImage={isSortApplied ? SORT_ICON_ACTIVE : SORT_ICON}
                    showDropdownList={showDropdownList && refinementListType === RECORDING_PARAMS.SORT}
                    button={{
                      label: translate("sort"),
                      buttonClickHandler: () => handleFilterButtonClick(RECORDING_PARAMS.SORT),
                      isFilterApplied: isSortApplied,
                      buttonStyles: `${
                        refinementListType === RECORDING_PARAMS.SORT ? "sort-icon clicked" : "sort-icon"
                      } ${isSortApplied ? "filter-applied" : ""}`,
                    }}
                    lists={getRefineOptionList(refinementListType)}
                    popUp={{
                      popUpStyles: "sort-options recordings-page-header-sort-options",
                      handleOutsideComponentClick,
                    }}
                  />
                </div>
              </div>
            );
            components.push(
              <div key="pageHeader" className="recordings-page-header">
                {headerComponents}
              </div>
            );
          }
        } else {
          headerComponents.push(
            <ImageButton
              src={process.env.PUBLIC_URL + "/images/back.svg"}
              onClickHandler={history.goBack}
              alt={"recordingBack"}
              className="recordingBack"
            />
          );

          if (selectedSeriesRecordingItem) {
            seriesTitle = isMR ? selectedSeriesRecordingItem.title : selectedSeriesRecordingItem.metadata.title || "";
            const episodeCount = recordingsToDisplay?.length ?? 0;
            episodeCountString = `${episodeCount} ${translate(
              isScheduleClicked ? "scheduled_small" : "recorded_small"
            )}`;
            headerComponents.push(
              <div className="recording-series-metadata" key="seriesMetadata">
                <div className="series-recording-title">{seriesTitle}</div>
                <div className="recordings-total">{episodeCountString}</div>
              </div>
            );
          }
          if (
            recordingsToDisplay.length ||
            selectedSeriesRecordingItem?.metadata?.recordingSeriesId ||
            isFilterApplied ||
            isMR
          ) {
            headerComponents.push(
              <div key="refineComponents" className="recording-refine-components-container">
                {isScheduleClicked && (
                  <span
                    className="action-button"
                    onClick={() =>
                      editRecordingClickHandler(
                        isMR ? selectedSeriesRecordingItem : { seriesRecordingItem: selectedSeriesRecordingItem },
                        RECORDING_PARAMS.SERIES
                      )
                    }
                  >
                    <ImageButton
                      src={EDIT_ICON}
                      buttonContainerStyles="button-container"
                      alt=""
                      className="action-recording-icon"
                    />
                    {translate("edit_series")}
                  </span>
                )}
                <span
                  className="action-button"
                  onClick={() =>
                    cancelRecordingClickHandler(
                      selectedSeriesRecordingItem,
                      false,
                      isScheduleClicked ? false : true,
                      false
                    )
                  }
                >
                  <ImageButton
                    src={isScheduleClicked ? CANCEL_SERIES_ICON : DELETE_ICON}
                    buttonContainerStyles="button-container"
                    alt=""
                    className="action-recording-icon rec-delete"
                  />
                  {translate(isScheduleClicked ? "cancel_series" : "delete_series")}
                </span>
              </div>
            );
          }
          components.push(
            <div key="pageHeader" className="recordings-page-header">
              {headerComponents}
            </div>
          );
        }

        if (recordingsToDisplay.length) {
          components.push(
            <ul key="recordingItemsList" className={`recordings-list ${selectedSeriesId ? "no-title" : ""}`}>
              {recordingsToDisplay.map((recording, index) => {
                if (recording) {
                  let key,
                    recordingType,
                    isRecordingScheduled = isScheduleClicked,
                    recordingToDisplay = recording;
                  if (isMR) {
                    key = recording.recordGUID ?? recording.recordingDefinitionId;
                    recordingType = recording.recordingType?.toLowerCase();
                    isRecordingScheduled =
                      isRecordingScheduled &&
                      !(
                        recording.isRecordingRecorded ||
                        recording.status === MR_RECORDING_STATUS.RECORDED ||
                        recording.programStartTime <= moment().valueOf()
                      );
                  } else {
                    key = recording.id;
                    if (recording.episodeRecordings) {
                      // As per TCDWC-922, if there is only one recorded episode recording (either recorded as an EVENT recording OR recorded as part of a SERIES recording)
                      // then we need to surface that one episode instead of showing a series collection item. If there is only one scheduled episode recording,
                      // it will display according to how it was scheduled (if a SERIES recording was scheduled and only one episode is available, it will still appear as a
                      // series collection item with one episode in the list)
                      if (!isRecordingScheduled && recording.episodeRecordings.length === 1) {
                        recordingType = RECORDING_PARAMS.EVENT;
                        recordingToDisplay = recording.episodeRecordings[0];
                      } else {
                        recordingType = RECORDING_PARAMS.SERIES;
                      }
                    } else {
                      recordingType =
                        recording.metadata?.recordingSeriesId && !selectedSeriesId
                          ? RECORDING_PARAMS.SERIES
                          : RECORDING_PARAMS.EVENT;
                    }

                    if (recordingType === RECORDING_PARAMS.EVENT && recordingToDisplay.metadata) {
                      // For whatever reason, recording item IDs aren't unique in AVS so we need to use the start time too...
                      key =
                        key +
                        `-${
                          recordingToDisplay.metadata.recordingStartTime + recordingToDisplay.metadata.startDeltaTime
                        }`;
                    }
                  }

                  return (
                    <RecordingItem
                      key={key}
                      itemIndex={index}
                      recordingResponse={recordingToDisplay}
                      isRecordingScheduled={isRecordingScheduled}
                      isProgramOfSeries={recordingToDisplay.isProgramOfSeries || false}
                      openSeriesViewAll={(seriesRecordId) => openSeriesViewAll(seriesRecordId, index)}
                      selectedSeriesId={selectedSeriesId}
                      recordingType={recordingType}
                      editRecordingClickHandler={editRecordingClickHandler}
                      cancelRecordingClickHandler={cancelRecordingClickHandler}
                      showModalPopup={showModalPopup}
                      isMR={isMR}
                      bookmarks={bookmarks}
                    />
                  );
                }

                return null;
              })}
            </ul>
          );
        } else {
          let nullMsgKey;
          const isEmptySeriesCPVR = !isMR && selectedSeriesId;
          if (!isEmptySeriesCPVR) {
            if (isFilterApplied) {
              nullMsgKey = "recordings_no_content";
            } else {
              nullMsgKey = isScheduleClicked ? "no_scheduled_content_desc" : "no_recorded_content_desc";
            }
          }

          components.push(
            isEmptySeriesCPVR ? (
              <div className="recording-null-state-wrapper">
                <div key="seriesNullTitle" className="recordings-null-series-title">
                  {translate("recordings_available_null_state_title")}
                </div>
                <div key="nullMessage" className="recordings-null-series-message">
                  {translate("recordings_available_null_state_body")}
                </div>
              </div>
            ) : (
              <div key="nullMessage" className="recordings-null-message">
                {translate(nullMsgKey)}
              </div>
            )
          );
        }

        return (
          <div key="recordingsSectionContainer" className="recordings-section-container">
            {components}
          </div>
        );
      } else if (isCPVRPanicEnabled) {
        headerComponents.push(<span key="pageTitle">{translate("recordings_unavailable")}</span>);
        components.push(
          <div key="pageHeader" className="recordings-page-header">
            {headerComponents}
          </div>
        );
        components.push(
          <div key="nullMessage" className="recordings-null-message">
            {translate("recordings_unavailable_body")}
          </div>
        );
        return (
          <div key="recordingsSectionContainer" className="recordings-section-container">
            {components}
          </div>
        );
      }
    }
  };

  useEffect(() => {
    // Reset page view tracking if query string has changed
    if (urlQueryString !== prevUrlQueryString.current) {
      resetIsPageViewTracked();
    }
  }, [resetIsPageViewTracked, urlQueryString]);

  useEffect(() => {
    /**
     * handles pageLoaded event
     * 1. when user manually reloaded RecordingPage ('Recorded'|'Scheduled')
     * 2. when user clicked Recordings at the navigation bar
     * 3. when user clicked the 'Recorded' or 'Scheduled' button from sidebar
     */
    if (!selectedSeriesId && (urlQueryString !== prevUrlQueryString.current || requiresInitialFetch.current)) {
      trackPageView({
        pageName: isScheduleClicked ? RECORDING_PARAMS.SCHEDULED : RECORDING_PARAMS.RECORDED,
      });
    }
  }, [urlQueryString, selectedSeriesId, isScheduleClicked, trackPageView]);

  useEffect(() => {
    /**
     * handles pageLoaded event
     * 1. when user manually reloaded RecordingPage (series setting page)
     * 2. when user opened the series setting page
     */
    if (selectedSeriesRecordingItem && urlQueryString !== prevUrlQueryString.current) {
      const getRecordingMetaData = () => {
        const mediaMetadata = {
          ...selectedSeriesRecordingItem,
          ...(isScheduleClicked
            ? { ...selectedSeriesRecordingItem?.scheduledItems?.[0] }
            : { ...selectedSeriesRecordingItem?.recordedItems?.[0] }),
        };

        return mapRecordingSeriesMediaContent(mediaMetadata);
      };
      trackPageView({
        pageName: "series_settings",
        extraMetadataList: [
          {
            type: EXTRA_METADATA_TYPES.MEDIA_CONTENT,
            asset: {
              ...getRecordingMetaData(),
              isItemLive: isScheduleClicked,
              mappedContentType: isScheduleClicked
                ? MAPPED_CONTENT_TYPES.LIVE
                : isMR
                ? MAPPED_CONTENT_TYPES.LPVR
                : MAPPED_CONTENT_TYPES.CPVR,
            },
          },
        ],
      });
    }
  }, [isMR, isScheduleClicked, selectedSeriesRecordingItem, trackPageView, urlQueryString]);

  /**
   * Handles the page loading spinner
   */
  useEffect(() => {
    toggleSpinningLoaderAction(isRequestLoading);
    return () => {
      toggleSpinningLoaderAction(false);
    };
  }, [isRequestLoading, toggleSpinningLoaderAction]);

  /**
   * This useEffect handles fetching the subscribed channels if they haven't been fetched yet
   */
  useEffect(() => {
    if (userProfile?.isLoggedIn) {
      if (!subscribedChannels && appProvider) {
        loadUserSubscribedChannels(appProvider, userProfile, cancelTokenSource, isUserProfilesEnabled);
      }
    }
  }, [
    userProfile,
    appProvider,
    loadUserSubscribedChannels,
    subscribedChannels,
    cancelTokenSource,
    isUserProfilesEnabled,
  ]);

  /**
   * This useEffect handles the initial recordings fetch on page load
   */
  useEffect(() => {
    if (requiresInitialFetch.current) {
      requiresInitialFetch.current = false;
      fetchRecordings();
      fetchBookmarks();
    }
  }, [fetchRecordings, fetchBookmarks]);

  /**
   * This useEffect updates the prevUrlQueryString ref. If the recording system is MR and the filtered GET feature property is enabled,
   * this useEffect also handles fetching recordings when switching between Recorded and Scheduled sections
   */
  useEffect(() => {
    if (prevUrlQueryString.current !== urlQueryString && !urlQueryString.includes(SERIES_VIEW_ALL_KEY)) {
      if (recordingFetchFilters) {
        const wasScheduleClicked = prevUrlQueryString.current.includes(RECORDING_PARAMS.SCHEDULED);
        if ((isScheduleClicked && !wasScheduleClicked) || (!isScheduleClicked && wasScheduleClicked)) {
          fetchRecordings();
        }
      }
      prevUrlQueryString.current = urlQueryString;
    }
  }, [fetchRecordings, recordingFetchFilters, isScheduleClicked, urlQueryString]);

  /**
   * This useEffect handles navigating back to the main recording page from the series recording episode list view when the series recording
   * is updated and no longer has any episodes available
   */
  useEffect(() => {
    if (selectedSeriesId && !selectedSeriesRecordingItem && recordingsToDisplay !== undefined) {
      let newLocation = `${location.pathname}?${RECORDING_PARAMS[isScheduleClicked ? "SCHEDULED" : "RECORDED"]}`;
      const filterByValue = urlQueryParams.get(FILTER_BY_KEY);
      if (filterByValue) {
        newLocation += `&${FILTER_BY_KEY}=${filterByValue}`;
      }

      const orderByValue = urlQueryParams.get(SORT_BY_KEY);
      const sortOrderValue = urlQueryParams.get(SORT_ORDER_KEY);

      if (orderByValue && sortOrderValue) {
        newLocation += `&${SORT_BY_KEY}=${orderByValue}&${SORT_ORDER_KEY}=${sortOrderValue}`;
      }

      history.replace(newLocation);
    }
  }, [
    selectedSeriesRecordingItem,
    selectedSeriesId,
    recordingsToDisplay,
    history,
    location,
    isScheduleClicked,
    urlQueryParams,
  ]);

  /**
   * This useEffect is used to deal with errors that occur during recording CRUD actions
   */
  useEffect(() => {
    if ((getRecordingError || recordingActionError) && manipulatedRecordingParams) {
      displayRecordingToast(manipulatedRecordingParams, getRecordingError || recordingActionError);
      resetRecordingActionValues();
    }
  }, [
    displayRecordingToast,
    getRecordingError,
    manipulatedRecordingParams,
    recordingActionError,
    resetRecordingActionValues,
  ]);

  /**
   * This useEffect is used after we have re-fetched recordings following a edit/delete/cancel recording action.
   */
  useEffect(() => {
    if (recordingsToDisplay && manipulatedRecordingParams && isActionInProgress.current) {
      displayRecordingToast(manipulatedRecordingParams);
      resetRecordingActionValues();
    }
  }, [displayRecordingToast, manipulatedRecordingParams, resetRecordingActionValues, recordingsToDisplay]);

  /**
   * This useEffect is used after we have attempted to edit a recording
   */
  useEffect(() => {
    if (editRecordingResponse && isActionInProgress.current === false) {
      if (editRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(editRecordingResponse);
      } else {
        fetchRecordings(
          isMR && isRecordingPendingStateCheck(editRecordingResponse.content)
            ? editRecordingResponse.recordingParams
            : null,
          true
        );
      }
    }
  }, [editRecordingResponse, isMR, fetchRecordings]);

  /**
   * This useEffect is used after we have attempted to cancel/delete a recording
   */
  useEffect(() => {
    if (deleteRecordingResponse && isActionInProgress.current === false) {
      if (deleteRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(deleteRecordingResponse);
      } else {
        // Nothing useful returned in the MR delete response, so we assume its pending
        fetchRecordings(isMR ? deleteRecordingResponse.recordingParams : null, true);
      }
    }
  }, [deleteRecordingResponse, isMR, fetchRecordings]);

  return (
    <div key="recordingsPageContainer">
      {getRecordingsSectionComponents()}
      <RecordingSidePanel
        tabClickHandler={sidePanelTabClickHandler}
        isScheduleClicked={isScheduleClicked}
        seriesRecordingItem={selectedSeriesRecordingItem}
        selectedSeriesId={selectedSeriesId}
        recordingListArray={recordingsToDisplay}
        recordingAvailabilityDays={recordingAvailabilityDays}
        infoClickHandler={infoClickHandler}
        isMR={isMR}
        isRequestLoading={isRequestLoading}
      />
    </div>
  );
}

RecordingsPage.propTypes = {
  appProvider: PropTypes.object,
  userProfile: PropTypes.object,
  featureToggles: PropTypes.object,
  recordingProfileData: PropTypes.object,
};

function mapStateToProps({ app, recording }) {
  return {
    appProvider: app.provider,
    userProfile: app.userProfile,
    subscribedChannels: app.subscribedChannels,
    recordingsList: recording.recordingsList,
    getRecordingError: recording.getRecordingError,
    editRecordingResponse: recording.editRecordingResponse,
    deleteRecordingResponse: recording.deleteRecordingResponse,
    manipulatedRecordingParams: recording.manipulatedRecordingParams,
    isRequestLoading: app.isRequestLoading,
    featureToggles: app.featureToggles,
    recordingProfileData: app.recordingProfile?.recordingProfileData,
  };
}

const mapDispatchToProps = {
  resetAction,
  getRecordingAction,
  showModalPopup,
  showToastNotification,
  showRecordingSettingsPanel,
  loadUserSubscribedChannels,
  toggleSettingsPanelAction,
  toggleSpinningLoaderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordingsPage);

/**
 * Determines which series grouping to add the provided episode EVENT recording to.
 * If the episode recording matches a series group that already exists in recordingList, the seriesGroupingMap
 * is used to find the index and append the episode recording to the series group's episode recording list.
 * If the episode recording doesn't match any series group, a new group is created. If the episode recording was
 * created as part of a SERIES recording, the new group will use the SERIES recording metadata. Otherwise, the
 * new group metadata will be built using series-related metadata from the episode recording. The new group is
 * then appended to the recordingList and an entry is created in the seriesGroupingMap to track its position in
 * the list.
 *
 * @param {Object} recording The episode EVENT recording object we wish to group
 * @param {Boolean} isRecordingScheduled Flag indicating if the recording provided is scheduled
 * @param {Number} seriesGroupingId The series ID to use for grouping the episode recording
 * @param {Object[]} seriesRecordings Array of all SERIES recording objects
 * @param {Map} seriesGroupingMap Map reference used to keep track of unique series
 * @param {Object[]} recordingList Array of recording objects to append untracked SERIES recordings to
 * @param {Boolean} isScheduleClicked Flag that indicates if we are on the scheduled section.
 */
function updateCPVRSeriesGroup(
  recording,
  isRecordingScheduled,
  seriesGroupingId,
  seriesRecordings,
  seriesGroupingMap,
  recordingList,
  isScheduleClicked
) {
  if (recording?.metadata && seriesGroupingId && seriesRecordings && seriesGroupingMap && recordingList) {
    const modifiedRecording = {
      ...recording,
      isProgramOfSeries: true,
    };

    const recordingListIndex = seriesGroupingMap.get(seriesGroupingId);
    if (recordingListIndex !== undefined) {
      recordingList[recordingListIndex].episodeRecordings.push(modifiedRecording);

      // if we find an episode recording that is newer, we need to update the series recording item with the newer time
      if (
        !isScheduleClicked &&
        recordingList[recordingListIndex].metadata.newestRecordingProgramStartTime <
          modifiedRecording.metadata.programStartTime
      ) {
        recordingList[recordingListIndex].metadata.newestRecordingProgramStartTime =
          modifiedRecording.metadata.programStartTime;
      }
    } else {
      // Add new entry to map for this series and save its index w.r.t the recording list
      seriesGroupingMap.set(seriesGroupingId, recordingList.length);

      const seriesRecording = seriesRecordings.find((seriesRecording) => {
        if (isRecordingScheduled) {
          return seriesRecording.metadata?.recordingSeriesId === seriesGroupingId;
        } else {
          return seriesRecording.metadata?.programSeriesId === seriesGroupingId;
        }
      });

      const seriesImageUrl = getAVSPosterArtImage(recording.metadata, IMAGES.ASPECT_RATIOS.DIM_9x16);
      let seriesMetadata;
      // A series recording will only exist if the episode recording was created as part of setting a SERIES recording
      if (seriesRecording) {
        seriesMetadata = {
          ...seriesRecording.metadata,
          pictureUrl: seriesImageUrl,
          newestRecordingProgramStartTime: modifiedRecording.metadata.programStartTime,
        };
      } else {
        seriesMetadata = {
          pictureUrl: seriesImageUrl,
          programSeriesId: seriesGroupingId,
          title: recording.metadata.title,
          programStartTime: recording.metadata.programStartTime,
        };
      }

      const modifiedSeriesRecording = {
        ...seriesRecording,
        metadata: seriesMetadata,
        episodeRecordings: [modifiedRecording],
      };

      if (!modifiedSeriesRecording.id) modifiedSeriesRecording.id = seriesGroupingId;

      // Add the series recording item to the recordings list
      recordingList.push(modifiedSeriesRecording);
    }
  }
}

/**
 * Used to get a compare function for sorting based on the provided sort option label
 *
 * @param {Boolean} isMR Flag used to differentiate between MR and CPVR
 * @param {String} sortLabel Sort option label used to determine the appropriate compare function
 * @param {Boolean} isScheduleClicked Flag that indicates if we are on the scheduled section.
 * @returns {Function} compare function to be used for sorting
 */
function getSortCompareFunction(isMR, sortLabel, isScheduleClicked) {
  if (sortLabel === RECORDING_PARAMS.NEWEST_FIRST) {
    if (isMR) {
      return (recordingA, recordingB) => recordingB.utcStartTime - recordingA.utcStartTime;
    } else {
      return (recordingA, recordingB) => {
        let startTimeA;
        if (recordingA.metadata.newestRecordingProgramStartTime !== undefined) {
          // Series recordings
          startTimeA = recordingA.metadata.newestRecordingProgramStartTime;
        } else {
          // Single event recordings
          startTimeA = recordingA.metadata.programStartTime;
        }

        let startTimeB;
        if (recordingB.metadata.newestRecordingProgramStartTime !== undefined) {
          // Series recordings
          startTimeB = recordingB.metadata.newestRecordingProgramStartTime;
        } else {
          // Single event recordings
          startTimeB = recordingB.metadata.programStartTime;
        }

        if (startTimeA !== startTimeB) {
          return isScheduleClicked ? startTimeA - startTimeB : startTimeB - startTimeA;
        } else {
          // if start times are equal, sort alphabetically
          return recordingA.metadata.title > recordingB.metadata.title ? 1 : -1;
        }
      };
    }
  } else if (sortLabel === RECORDING_PARAMS.OLDEST_FIRST) {
    if (isMR) {
      return (recordingA, recordingB) => recordingA.utcStartTime - recordingB.utcStartTime;
    } else {
      return (recordingA, recordingB) => {
        let startTimeA;
        if (recordingA.metadata.newestRecordingProgramStartTime !== undefined) {
          // Series recordings
          startTimeA = recordingA.metadata.newestRecordingProgramStartTime;
        } else {
          // Single event recordings
          startTimeA = recordingA.metadata.programStartTime;
        }

        let startTimeB;
        if (recordingB.metadata.newestRecordingProgramStartTime !== undefined) {
          // Series recordings
          startTimeB = recordingB.metadata.newestRecordingProgramStartTime;
        } else {
          // Single event recordings
          startTimeB = recordingB.metadata.programStartTime;
        }

        if (startTimeA !== startTimeB) {
          return isScheduleClicked ? startTimeB - startTimeA : startTimeA - startTimeB;
        } else {
          // if start times are equal, sort alphabetically
          return recordingA.metadata.title > recordingB.metadata.title ? 1 : -1;
        }
      };
    }
  } else if (sortLabel === RECORDING_PARAMS.A_Z) {
    if (isMR) {
      return (recordingA, recordingB) => {
        const titleA = recordingA?.title?.toLowerCase();
        const titleB = recordingB?.title?.toLowerCase();

        if (titleA === titleB) {
          return 0;
        } else {
          return titleA > titleB ? 1 : -1;
        }
      };
    } else {
      return (recordingA, recordingB) => {
        const titleA = recordingA?.metadata?.title?.toLowerCase();
        const titleB = recordingB?.metadata?.title?.toLowerCase();

        if (titleA === titleB) {
          return 0;
        } else {
          return titleA > titleB ? 1 : -1;
        }
      };
    }
  } else if (sortLabel === RECORDING_PARAMS.Z_A) {
    if (isMR) {
      return (recordingA, recordingB) => {
        const titleA = recordingA?.title?.toLowerCase();
        const titleB = recordingB?.title?.toLowerCase();

        if (titleA === titleB) {
          return 0;
        } else {
          return titleA < titleB ? 1 : -1;
        }
      };
    } else {
      return (recordingA, recordingB) => {
        const titleA = recordingA?.metadata?.title?.toLowerCase();
        const titleB = recordingB?.metadata?.title?.toLowerCase();

        if (titleA === titleB) {
          return 0;
        } else {
          return titleA < titleB ? 1 : -1;
        }
      };
    }
  }
}

/**
 * Used to get a compare function for filtering based on the provided filter option label
 *
 * @param {Boolean} isMR Flag used to differentiate between MR and CPVR
 * @param {String} filterLabel Filter option label used to determine the appropriate compare function
 * @returns {Function} compare function to be used for filtering
 */
function getFilterCompareFunction(isMR, filterLabel) {
  if (filterLabel === RECORDING_PARAMS.SERIES) {
    if (isMR) {
      return (recording) => {
        return (
          recording.assetType?.toUpperCase() === PAGE_CONTENT_ITEM_TYPES.tvShow ||
          recording.recordingType?.toLowerCase() === RECORDING_PARAMS.SERIES
        );
      };
    } else {
      return (recording) => {
        return (
          recording.episodeRecordings ||
          recording.metadata.extendedMetadata?.dlum?.uaGroupType === PAGE_CONTENT_ITEM_TYPES.tvShow
        );
      };
    }
  } else if (filterLabel === RECORDING_PARAMS.MOVIES) {
    if (isMR) {
      return (recording) => {
        return recording.assetType?.toUpperCase() === PAGE_CONTENT_ITEM_TYPES.movie;
      };
    } else {
      return (recording) => {
        return recording.metadata.extendedMetadata?.dlum?.uaGroupType === PAGE_CONTENT_ITEM_TYPES.movie;
      };
    }
  } else {
    return null;
  }
}
