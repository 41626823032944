import constants from "../constants";

const { CONTENT_ITEM_TYPES } = constants;

/**
 * Check to see a title is of type movie.
 *
 * @param {Object} data - title data object
 * @param {String} ItemLayout - (Optional) Defines the Item Layout type as ContentItem or BundleItem or GroupOfBundle. AVS Specific types.
 * @returns {Boolean} - Returns true if movie or false if not a movie
 */
export const isItemTypeMovie = (data, itemLayout = null) => {
  if (
    itemLayout &&
    itemLayout.toLowerCase() === CONTENT_ITEM_TYPES.contentItem &&
    data.contentType &&
    data.contentType.toLowerCase() === CONTENT_ITEM_TYPES.vod &&
    data.contentSubtype &&
    data.contentSubtype.toLowerCase() === CONTENT_ITEM_TYPES.movie
  )
    return true;
  else if (
    data &&
    data.contentType &&
    data.contentType.toLowerCase() === CONTENT_ITEM_TYPES.program &&
    !data.seriesId &&
    !data.episodeNumber
  )
    return true;
  else if (data && data.uaType && data.uaType.toLowerCase() === CONTENT_ITEM_TYPES.movie) {
    return true;
  } else if (data?.extendedMetadata?.dlum?.uaGroupType?.toLowerCase() === CONTENT_ITEM_TYPES.movie) {
    return true;
  }

  return false;
};

/**
 * Check to see a title is of type series.
 *
 * @param {Object} data - title data object
 * @param {String} ItemLayout - (Optional) Defines the Item Layout type as ContentItem or BundleItem or GroupOfBundle. AVS Specific types.
 * @returns {Boolean} - Returns true if series or false if not a series
 */
export const isItemTypeSeries = (data, itemLayout = null) => {
  if (
    itemLayout &&
    (itemLayout.toLowerCase() === CONTENT_ITEM_TYPES.bundleItem ||
      itemLayout.toLowerCase() === CONTENT_ITEM_TYPES.contentItem) &&
    data.contentType &&
    data.contentType.toLowerCase() === CONTENT_ITEM_TYPES.groupOfBundles &&
    data.contentSubtype &&
    data.contentSubtype.toLowerCase() === CONTENT_ITEM_TYPES.series
  ) {
    return true;
  } else if (
    data &&
    data.contentType &&
    data.contentType.toLowerCase() === CONTENT_ITEM_TYPES.program &&
    data.seriesId &&
    !data.episodeNumber &&
    data.extendedMetadata &&
    data.extendedMetadata.dlum &&
    data.extendedMetadata.dlum.contentType?.toLowerCase() !== CONTENT_ITEM_TYPES.episode &&
    data.extendedMetadata.dlum.uaGroupType?.toLowerCase() !== CONTENT_ITEM_TYPES.tvshow
  )
    return true;
  else if (data && data.uaType && data.uaType.toLowerCase() === CONTENT_ITEM_TYPES.tvshow) {
    return true;
  } else if (data?.entityType?.toLowerCase() === CONTENT_ITEM_TYPES.team) {
    return true;
  }

  return false;
};

/**
 * Check to see a title is of type episode.
 *
 * @param {Object} data - title data object
 * @returns {Boolean} - Returns true if episode or false if not a episode
 */
export const isItemTypeEpisode = (data) => {
  if (
    data &&
    data.contentType &&
    data.contentType.toLowerCase() === CONTENT_ITEM_TYPES.vod &&
    data.contentSubtype &&
    data.contentSubtype.toLowerCase() === CONTENT_ITEM_TYPES.episode
  ) {
    return true;
  } else if (
    data &&
    data.contentType &&
    data.contentType.toLowerCase() === CONTENT_ITEM_TYPES.program &&
    data.seriesId &&
    (data.episodeNumber || data.episodeId)
  ) {
    return true;
  } else if (data && data.uaType && data.uaType.toLowerCase() === CONTENT_ITEM_TYPES.episode) {
    return true;
  } else if (
    data &&
    data.extendedMetadata &&
    data.extendedMetadata.dlum &&
    (data.extendedMetadata.dlum.contentType?.toLowerCase() === CONTENT_ITEM_TYPES.episode ||
      data.extendedMetadata.dlum.uaGroupType?.toLowerCase() === CONTENT_ITEM_TYPES.tvshow) &&
    !isItemTypeRecording(data)
  ) {
    return true;
  }
  return false;
};

/**
 * Check to see a title is of type recording.
 *
 * @param {Object} data - title data object
 * @returns {Boolean} - Returns true if episode or false if not a episode
 */
export const isItemTypeRecording = (data) => {
  if (data?.contentType?.toLowerCase() === CONTENT_ITEM_TYPES.recording) {
    return true;
  }

  return false;
};

/**
 * Check to see a title is of type live.
 *
 * @param {Object} data - title data object
 * @returns {Boolean} - Returns true if live or false if not a live
 */
export const isItemTypeLive = (data) => {
  if (
    data &&
    data.ItemType &&
    (data.ItemType.toLowerCase() === CONTENT_ITEM_TYPES.liveProgram ||
      data.ItemType.toLowerCase() === CONTENT_ITEM_TYPES.liveSeries)
  ) {
    return true;
  } else if (data && data.IsLive === true) {
    return true;
  } else if (data && data.contentType && data.contentType.toLowerCase() === CONTENT_ITEM_TYPES.program) {
    return true;
  } else {
    return data && data.StationId && data.StationId.length > 0;
  }
};

/**
 * Check to see a title is of type Network.
 *
 * @param {Object} data - title data object
 * @returns {Boolean} - Returns true if Network type or false if not
 */
export const isItemTypeNetwork = (data) => {
  const contentType = data?.contentType?.toLowerCase();
  const contentSubtype = data?.contentSubtype?.toLowerCase();
  if (
    (contentType === CONTENT_ITEM_TYPES.vod && contentSubtype === CONTENT_ITEM_TYPES.network) ||
    (contentType === CONTENT_ITEM_TYPES.launcher && contentSubtype === CONTENT_ITEM_TYPES.launcher)
  )
    return true;
  return false;
};

/**
 * Check to see a avsRole or role  is of type cast and crew.
 *
 * @param {Object} data - title data object
 * @returns {Boolean} - Returns true if crew type or false if not
 */
export const isItemTypeCrew = (data) => {
  return data?.avsRole?.length > 0 || data?.role?.length > 0;
};
