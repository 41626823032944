import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";

import PopUpOutline from "../PopUpOutline";
import OptikButton from "../../components/OptikButton";
import constants from "../../shared/constants";
import { getPurchaseTransactionType } from "../../shared/utils";
import { getFormattedPrice, getPackagePrice, getChannelInfoString } from "../../shared/utils/feedHelper";
import { handleImageError } from "../../shared/utils/image";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { logNREvent } from "../../shared/analytics/newRelic";
import "./style.scss";

const { TRANSACTION_TYPES, DEFAULT_RENTAL_DURATION, PACKAGE_TYPE } = constants;
const portraitL = process.env.PUBLIC_URL + "/images/swimlane-portrait-166px.jpg";
const PurchaseModal = ({
  channel,
  closeModal = () => {},
  itemImage,
  itemMetadata,
  purchaseItem = () => {},
  purchasePackage,
}) => {
  const [disablePurchaseCTA, setDisablePurchaseCTA] = useState(false);

  const { t: translate } = useTranslation();
  const { appLanguage } = useAppLanguage();

  const isPPVEvent = purchasePackage?.type?.toLowerCase() === PACKAGE_TYPE.PPV_EVENT;
  const transactionType = isPPVEvent ? TRANSACTION_TYPES.BUY : getPurchaseTransactionType(purchasePackage?.name);
  const isRentTransaction = transactionType === TRANSACTION_TYPES.RENT;
  const selectedPrice = getPackagePrice(purchasePackage) ?? 0;
  const language = itemMetadata?.language || itemMetadata?.extendedMetadata?.dlum?.defaultLanguage;

  const getHeaderString = () => {
    let headerKey;
    if (isPPVEvent) {
      headerKey = "ppv_purchase_prompt_title";
    } else {
      headerKey = transactionType;
    }

    return translate(headerKey);
  };

  const getAccessMessage = () => {
    if (isPPVEvent) {
      if (itemMetadata) {
        const startTimeMoment = moment(itemMetadata.airingStartTime);
        const startDate = startTimeMoment.format("LL");
        const startTime = startTimeMoment.format("LT");
        const endTime = moment(itemMetadata.airingEndTime).format("LT");

        return `${startDate} - ${translate("date_time_from_to").replace("%s", startTime).replace("%s", endTime)}`;
      }
    } else {
      return isRentTransaction
        ? translate("order_duration_title").replace("<duration>", DEFAULT_RENTAL_DURATION)
        : translate("order_title");
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const disallowPurchase = (value) => {
    setDisablePurchaseCTA(value);
  };

  return (
    <React.Fragment>
      <div className="purchase-modal-backdrop"></div>
      <div className="purchase-modal-container">
        <div className="purchase-modal-popup-container">
          <PopUpOutline className="purchase-modal-popup">
            <div className="purchase-modal-header">{getHeaderString()}</div>
            <div className="purchase-item">
              <div className="purchase-item-image">
                <img
                  src={itemImage ? `${itemImage}?w=166` : portraitL}
                  alt=""
                  data-testid="thumbnail"
                  className="thumbnail"
                  width="100%"
                  height="100%"
                  onError={(e) => handleImageError(e, portraitL)}
                />
              </div>
              <div className="purchase-item-details">
                <div className="purchase-item-title">{itemMetadata?.title}</div>
                <div className="purchase-information">
                  <div className="purchase-parameters">
                    <p>
                      {translate("purchase_price")}
                      {translate("punctuation:colon")}
                      <span className="price">{getFormattedPrice(selectedPrice, appLanguage)}</span>
                    </p>
                    <p>
                      {translate("order_access")}
                      {translate("punctuation:colon")}
                      <span>{getAccessMessage()}</span>
                    </p>
                    {channel && (
                      <p>
                        {translate("channel")}
                        {translate("punctuation:colon")}
                        <span>{getChannelInfoString(channel)}</span>
                      </p>
                    )}
                    {itemMetadata?.extendedMetadata?.dlum?.rating && (
                      <p>
                        {translate("order_rating")}
                        {translate("punctuation:colon")}
                        <span>{itemMetadata.extendedMetadata.dlum.rating}</span>
                      </p>
                    )}
                    {purchasePackage?.videoType && (
                      <p>
                        {translate("order_resolution")}
                        {translate("punctuation:colon")}
                        <span>{purchasePackage.videoType}</span>
                      </p>
                    )}
                    {language?.length > 0 && (
                      <p>
                        {translate("language")}
                        {translate("punctuation:colon")}
                        <span>
                          {language.toLowerCase().startsWith("en")
                            ? translate("canada_english")
                            : translate("canada_french")}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="purchase-cta">
              <button
                className="purchase-cancel-cta"
                onClick={() => {
                  logNREvent(NR_PAGE_ACTIONS.PURCHASE_CANCELLED);
                  closeModal();
                }}
              >
                {translate("cancel")}
              </button>
              <OptikButton
                label={translate(isRentTransaction ? "order_rent" : "order_buy")}
                onClickHandler={() => purchaseItem(purchasePackage, disallowPurchase)}
                disabled={disablePurchaseCTA}
              />
            </div>
          </PopUpOutline>
        </div>
      </div>
    </React.Fragment>
  );
};

PurchaseModal.propTypes = {
  channelMetadata: PropTypes.object,
  closeModal: PropTypes.func,
  itemImage: PropTypes.string,
  itemMetadata: PropTypes.object,
  purchaseItem: PropTypes.func,
  purchasePackage: PropTypes.object,
};

export default PurchaseModal;
