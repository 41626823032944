/**
 * load core modules
 */
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
/* load core modules end */

/**
 * load child components
 */
import Swimlane from "../../components/Swimlane";
import CastSwimlaneItem from "../../components/SwimlaneItem/CastSwimlaneItem";
/* load child components end */

/**
 * load custom hooks
 */
import { useReducers } from "../../shared/hooks/useReducer";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
/* load custom hooks end */

/**
 * load utilities
 */
import createSwimlaneModels from "../../shared/utils/dataFilters";
/* load utilities end */

/**
 * load analytics helper functions
 */
import { getSwimlaneRowNum } from "../../shared/analytics/helpers";
/* load analytics helper functions end */

/**
 * load constants
 */
import constants from "../../shared/constants/index";
import swimlaneConstants from "../../shared/constants/swimlane";
/* load constants end */

/** declare/destructure constants */
const { REDUCER_TYPE } = constants;
const { ITEM_TYPES, SWIMLANE_TITLES } = swimlaneConstants;

/**
 * Component to render cast and crew members tray
 * @component
 * @param {Object} props
 */
const CastAndCrew = ({ contentMetadata }) => {
  const { userProfile } = useReducers(REDUCER_TYPE.APP);
  const { similarItemsResponse } = useReducers(REDUCER_TYPE.MOVIE_DETAILS);

  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();

  const peopleList = useMemo(() => contentMetadata?.metadata?.extendedMetadata?.dlum?.people || [], [contentMetadata]);
  const hasPeople = peopleList.length > 0;

  const rolesFeed = useMemo(() => {
    if (hasPeople) {
      const title = translate(SWIMLANE_TITLES.CAST_AND_CREW);
      return createSwimlaneModels({
        data: peopleList.map((item) => ({ metadata: item })),
        avsComponent: { metadata: { label: title }, title },
        addParams: { DIMENSIONS: ITEM_TYPES.CHARACTER_ITEM.DIMENSIONS },
        itemsCount: peopleList.length,
        isAppLanguageFrench,
        userProfile,
      });
    }
    return null;
  }, [peopleList, hasPeople, translate, isAppLanguageFrench, userProfile]);

  if (!hasPeople) return null;

  return (
    <div className="detail-swimlane cast">
      <Swimlane
        getSwimlaneRowNum={() =>
          getSwimlaneRowNum(SWIMLANE_TITLES.CAST_AND_CREW, similarItemsResponse?.containers?.length > 0)
        }
        feed={rolesFeed}
        dimensionConfig={ITEM_TYPES.CHARACTER_ITEM.DIMENSIONS}
        sliderSettings={ITEM_TYPES.CHARACTER_ITEM.CONFIG}
        ThumbnailComponent={CastSwimlaneItem}
      />
    </div>
  );
};

CastAndCrew.propTypes = {
  contentMetadata: PropTypes.object.isRequired,
};

export default CastAndCrew;
