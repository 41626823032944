import request from "../../service/request";
import constants from "../constants";
import { getResponseObject } from "../utils";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { logNREvent } from "../../shared/analytics/newRelic";

const { APP_ENVIRONMENTS, API_BASE_URL, MVE2_AGL_VERSION } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;
const DEFAULT_PIN = "0000"; // AVS expects this as a default PIN
const PIN_STATES = {
  ENABLED: "Y",
  DISABLED: "N",
};

function makePurchase(appProvider, purchaseInfo, pin, cancelTokenSource = null) {
  let url = appProvider.config.avs_api_urls.purchase_url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", "R");
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }
  const data = {
    commercialPackageId: purchaseInfo.id,
    pin: pin || undefined,
    programId: purchaseInfo.programId?.toString() || undefined,
  };

  return request(
    {
      url,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
      withCredentials: true,
      returnResponseObject: true,
    },
    cancelTokenSource,
    appProvider
  )
    .then((response) => {
      if (response.data.resultCode === "KO") {
        logNREvent(NR_PAGE_ACTIONS.PURCHASE_ERROR);
        return Promise.reject(getResponseObject(response));
      } else {
        logNREvent(NR_PAGE_ACTIONS.PURCHASE);
        return response.data.resultObj;
      }
    })
    .catch((err) => Promise.reject(getResponseObject(err)));
}

export default makePurchase;

/**
 * Handles enabling or disabling the purchase PIN.
 * If shouldEnable = true, the function will enable the purchase pin and update the USER/PROFILE pin from the default to the provided userPIN
 * If shouldEnable = false, the function will disable the purchase pin and update the USER/PROFILE pin from the provided userPIN to the default
 *
 * @param {Object} appProvider App provider information
 * @param {Boolean} shouldEnable Flag to indicate if function should enable or disable purchase pin
 * @param {String} userPIN PIN input from the user
 * @returns {Promise} request promise
 */
export function setPurchasePINAvailability(appProvider, shouldEnable, userPIN) {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = AVS_API_LIST.user_profile;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const params = {
    newPurchasePin: shouldEnable ? userPIN : DEFAULT_PIN,
    purchasePin: shouldEnable ? DEFAULT_PIN : userPIN,
    purchasePinEnabled: shouldEnable ? PIN_STATES.ENABLED : PIN_STATES.DISABLED,
    rememberPurchasePin: shouldEnable ? PIN_STATES.DISABLED : PIN_STATES.ENABLED,
  };

  return request(
    {
      url,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: appProvider.AGL_Version === MVE2_AGL_VERSION ? { profileData: params } : params,
      withCredentials,
    },
    null,
    appProvider
  );
}

/**
 * Handles updating the purchase PIN.
 *
 * @param {Object} appProvider App provider information
 * @param {String} currentPIN User's current purchase PIN
 * @param {String} newPIN User's new purchase PIN
 * @returns {Promise} request promise
 */
export function updatePurchasePIN(appProvider, currentPIN, newPIN) {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = AVS_API_LIST.user_profile;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const params = {
    newPurchasePin: newPIN,
    purchasePin: currentPIN,
  };

  return request(
    {
      url,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: appProvider.AGL_Version === MVE2_AGL_VERSION ? { profileData: params } : params,
      withCredentials,
    },
    null,
    appProvider
  );
}

/**
 * Validate the provided purchase PIN by trying to update the PIN to itself.
 *
 * @param {Object} appProvider App provider information
 * @param {String} currentPIN User's current purchase PIN
 * @returns {Promise} request promise
 */
export function validatePurchasePIN(appProvider, currentPIN) {
  return updatePurchasePIN(appProvider, currentPIN, currentPIN);
}
