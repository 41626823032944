import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ForgotPinLink from "../ForgotPinLink";
import "./style.scss";

import PopUpOutline from "../PopUpOutline";
import { trackGenericAction } from "../../shared/analytics/dataLayer";
import { ANALYTICS_EVENT_TYPES } from "../../shared/constants/analytics";
import { isPinMaxLimitReach } from "../../shared/utils";
import telusConvivaAnalytics from "../../shared/utils/convivaAnalytics";

export const PIN_MODAL_MODES = {
  SET: "set",
  ACCESS: "access",
  CONFIRM: "confirm",
};

export const PIN_MODAL_TYPES = {
  PARENTAL: "parental",
  PURCHASE: "purchase",
};

const pinLength = 4;
const initialPinDigitArray = Array(pinLength).fill("");

/**
 * PIN entry modal component used for Parental and Purchase PINs
 * @param {Object} props
 * @returns PinModal component
 */
const PinModal = (props) => {
  const {
    initialPinModalMode,
    pinModalType,
    heading,
    subheading,
    closeModal,
    pinConfirmHandler,
    externalError,
    setExternalError,
    pinAnalyticsErrorEventHandler,
  } = props;
  const { t: translate } = useTranslation();
  const [pinModalMode, setPinModalMode] = useState(initialPinModalMode ?? PIN_MODAL_MODES.ACCESS);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalData, setModalData] = useState(null);
  const pinDigitArrayRef = useRef(initialPinDigitArray);
  const currentPinRef = useRef("");
  const newPinRef = useRef("");

  useEffect(() => {
    if (initialPinModalMode) {
      setPinModalMode(initialPinModalMode);
    }
  }, [initialPinModalMode]);

  /**
   * Update the modal heading depending on modal mode and type
   */
  useEffect(() => {
    const modalInfo = {
      modalHeading: heading ?? "",
      modalSubHeading: subheading,
      modalCancelCTA: translate("cancel"),
    };
    switch (pinModalMode) {
      case PIN_MODAL_MODES.SET:
        modalInfo.modalHeading =
          pinModalType === PIN_MODAL_TYPES.PARENTAL
            ? translate("pin_set_parental_pin")
            : translate("pin_set_purchase_pin");
        break;
      case PIN_MODAL_MODES.ACCESS:
        if (isPinMaxLimitReach(externalError)) {
          modalInfo.modalHeading =
            pinModalType === PIN_MODAL_TYPES.PARENTAL
              ? translate("parental_pin_locked")
              : translate("purchase_pin_locked");
          modalInfo.modalSubHeading =
            pinModalType === PIN_MODAL_TYPES.PARENTAL
              ? translate("parental_pin_locked_desc")
              : translate("purchase_pin_locked_reset");
          modalInfo.modalCancelCTA = translate("close");
        } else {
          modalInfo.modalHeading = heading;
        }
        break;
      case PIN_MODAL_MODES.CONFIRM:
        modalInfo.modalHeading =
          pinModalType === PIN_MODAL_TYPES.PARENTAL
            ? translate("pin_confirm_parental_pin")
            : translate("pin_confirm_purchase_pin");
        break;
      default:
        break;
    }
    setModalData(modalInfo);
  }, [externalError, heading, pinModalMode, pinModalType, subheading, translate]);

  /**
   * PIN validation happens server side, so we need to display appropriate messaging based on the external error checks
   */
  useEffect(() => {
    if (externalError && !isPinMaxLimitReach(externalError)) {
      if (externalError.params?.remainingAttempts) {
        setErrorMessage(translate("pin_incorrect_attempt").replace("%s", externalError.params.remainingAttempts));
      } else {
        setErrorMessage(translate("pin_error_message"));
      }
      clearPinEntries();
      setExternalError(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalError]);

  /**
   * Handle last digit input depending on modal mode.
   * If pinDigitArrayRef is missing digits, don't do anything.
   */
  const submitPin = () => {
    const pinInputString = pinDigitArrayRef.current.every((digit) => digit !== "")
      ? pinDigitArrayRef.current.join("")
      : null;

    if (pinInputString) {
      switch (pinModalMode) {
        case PIN_MODAL_MODES.SET:
          // Enforce numbers from 0-9
          if (/^\d+$/.test(pinInputString)) {
            // If all digits are valid, store the new PIN entry and change to CONFIRM mode
            newPinRef.current = pinInputString;
            setErrorMessage("");
            clearPinEntries();
            setPinModalMode(PIN_MODAL_MODES.CONFIRM);
          } else {
            setErrorMessage(translate("pin_error_alphabet"));
            clearPinEntries();
            pinAnalyticsErrorEventHandler && pinAnalyticsErrorEventHandler({ errorMessage: "pin_error_alphabet" });
          }
          break;
        case PIN_MODAL_MODES.ACCESS:
          // Pass the entered PIN to the provided confirm handler
          setErrorMessage("");
          pinConfirmHandler(pinInputString);
          break;
        case PIN_MODAL_MODES.CONFIRM:
          // Latest PIN entry needs to match the previous PIN entry
          if (pinInputString === newPinRef.current) {
            setErrorMessage("");
            // Pass the current PIN and new PIN to provided confirm handler
            pinConfirmHandler(newPinRef.current, currentPinRef.current);
          } else {
            pinAnalyticsErrorEventHandler && pinAnalyticsErrorEventHandler({ errorMessage: "pin_mismatch" });
            setErrorMessage(translate("pin_error_message"));
            clearPinEntries();
          }
          break;
        default:
          break;
      }
    }
  };

  const internalCloseModalButton = () => {
    if (pinModalType === PIN_MODAL_TYPES.PARENTAL) {
      telusConvivaAnalytics.reportParentalPinCancel();
    }

    closeModal();
  };

  /**
   * Update the appropriate entry in pinDigitArrayRef, and set the input element's value to • in order to hide the PIN digits.
   * This function is used by each digit input. It will also attempt to shift focus to the next input element if the input value is non-empty (i.e not on backspace)
   * @param {Object} event input element event object
   */
  const pinInputHandler = (event) => {
    const currentValue = event.currentTarget.value;
    const index = parseInt(event.currentTarget.id.replace("digit", "")) - 1;
    if (!isNaN(index)) {
      const pinArrayCopy = [...pinDigitArrayRef.current];
      pinArrayCopy[index] = currentValue;
      pinDigitArrayRef.current = pinArrayCopy;
      if (currentValue) {
        event.currentTarget.value = "•";
        if (index === pinLength - 1) {
          submitPin();
        } else {
          document.getElementById("digit" + (index + 2)).focus();
        }
      }
    }
  };

  /**
   * Reset the pinDigitArrayRef and the individual digit input elements
   */
  const clearPinEntries = useCallback(() => {
    pinDigitArrayRef.current = initialPinDigitArray;
    const pinContainer = document.getElementById("pinEntryContainer");
    for (let i = 0; i < pinContainer.children.length; i++) {
      pinContainer.children[i].value = "";
    }

    document.getElementById("digit1").focus();
  }, []);

  /**
   * Clear digit inputs when the modal type changes
   */
  useEffect(() => {
    clearPinEntries();
  }, [clearPinEntries, pinModalMode]);

  return (
    <React.Fragment>
      <div className="pin-modal-backdrop"></div>
      <div className="pin-modal-container">
        <div className="pin-modal-popup-container">
          <PopUpOutline className="pin-modal-popup">
            <p className="pin-modal-heading">{modalData?.modalHeading}</p>
            {modalData?.modalSubHeading && <p className="pin-modal-subheading">{modalData.modalSubHeading}</p>}
            {!isPinMaxLimitReach(externalError) && (
              <div id="pinEntryContainer" className={`pin-modal-pin-container ${errorMessage ? "has-error" : ""}`}>
                <input
                  id="digit1"
                  className="pin-modal-pin-input"
                  type="text"
                  maxLength="1"
                  onInput={pinInputHandler}
                  autoComplete="off"
                />
                <input
                  id="digit2"
                  className="pin-modal-pin-input"
                  type="text"
                  maxLength="1"
                  onInput={pinInputHandler}
                  autoComplete="off"
                />
                <input
                  id="digit3"
                  className="pin-modal-pin-input"
                  type="text"
                  maxLength="1"
                  onInput={pinInputHandler}
                  autoComplete="off"
                />
                <input
                  id="digit4"
                  className="pin-modal-pin-input"
                  type="text"
                  maxLength="1"
                  onInput={pinInputHandler}
                  autoComplete="off"
                />
              </div>
            )}
            {errorMessage && <p className="pin-modal-error-string">{errorMessage}</p>}
            <div className="pin-modal-cta">
              <button className="pin-modal-cancel-cta" onClick={internalCloseModalButton}>
                {modalData?.modalCancelCTA}
              </button>
              {pinModalMode === PIN_MODAL_MODES.ACCESS && (
                <ForgotPinLink
                  pinType={pinModalType}
                  onClick={(event) =>
                    trackGenericAction(ANALYTICS_EVENT_TYPES.EXIT_CLICK, {
                      name: pinModalType === PIN_MODAL_TYPES.PARENTAL ? "recover_parental_pin" : "recover_purchase_pin",
                      URL: event.currentTarget.href,
                    })
                  }
                />
              )}
            </div>
          </PopUpOutline>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PinModal;
