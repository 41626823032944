import React from "react";
import { useTranslation } from "react-i18next";
import ImageButton from "../ImageButton";
import { getSubTextLine1, getMovieDuration } from "../../shared/utils/feedHelper";
import Button from "../Button";

import {
  PlayerSidebarWrapper,
  PlayerSidebarHideButtonWrapper,
  PlayerSidebarRoute,
  PlayerSidebarAnchor,
} from "../../pages/PlayerPage/PlayerPage.styles";
import {
  MovieSidebarPanelDetails,
  MovieSidebarName,
  MovieSidebarDetail,
  MovieSidebarDescription,
} from "./MovieSideBar.styles";

import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { LINK_INFO, ANALYTICS_STORAGE_KEYS } from "../../shared/constants/analytics";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { checkUnifiedAsset } from "../../shared/utils";
import { showModalPopup } from "../../App/state/actions";
import constants from "../../shared/constants";
import { useTheme } from "styled-components";
const VIEW_ALL_ICON = process.env.PUBLIC_URL + "/images/white-chevron.svg";
const { MODAL_TYPES } = constants;
/**
 * Display side bar for movie
 * @param {Object} props
 */
function MovieSideBar(props) {
  const { handleHideButtonClick, content, dispatch } = props;
  const theme = useTheme();
  const screenWidth = window.screen.availWidth;
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const getDetailPageRoute = () => {
    const unifiedAssetId = content?.metadata?.extendedMetadata?.dlum?.uaId;
    if (unifiedAssetId) {
      return `/detail/movie/PAGE/DETAILS/MOVIE/${unifiedAssetId}`;
    } else {
      return `/detail/movie/PAGE/DETAILS/${content?.metadata?.contentType}/${content?.id}`;
    }
  };

  const trackDetailsNavigation = () => {
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.VIEW_DETAILS};${LINK_INFO.MOVIE_SIDEBAR}`);
  };

  return (
    <PlayerSidebarWrapper theme={theme} id="panel">
      <PlayerSidebarHideButtonWrapper>
        <Button
          buttonStyles="list-button"
          label={translate("hide")}
          onClickHandler={handleHideButtonClick}
          tabIndex="-1"
        />
      </PlayerSidebarHideButtonWrapper>
      {content?.metadata ? (
        <MovieSidebarPanelDetails width={screenWidth} className="panel-details">
          {content.metadata.title && <MovieSidebarName theme={theme}>{content.metadata.title}</MovieSidebarName>}
          <MovieSidebarDetail width={screenWidth} theme={theme}>
            {
              <span>
                {getSubTextLine1(content.metadata, false, isAppLanguageFrench) +
                  "\xa0\xa0" +
                  getMovieDuration(content.metadata)}
              </span>
            }
          </MovieSidebarDetail>
          {content.metadata.longDescription && (
            <MovieSidebarDescription theme={theme} width={screenWidth}>
              {content.metadata.longDescription}
            </MovieSidebarDescription>
          )}

          {checkUnifiedAsset(content.metadata.extendedMetadata?.dlum) ? (
            <PlayerSidebarRoute to={getDetailPageRoute()} tabIndex="-1" onClick={trackDetailsNavigation}>
              <span>{translate("details")}</span>
              <ImageButton src={VIEW_ALL_ICON} alt="viewAll" tabIndex="-1" />
            </PlayerSidebarRoute>
          ) : (
            <PlayerSidebarRoute>
              <PlayerSidebarAnchor
                href="#"
                tabIndex="-1"
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(showModalPopup(MODAL_TYPES.ERROR, { message: "error_non_unified", isCloseable: true })); // showing error for non-UA assets
                }}
              >
                <span>{translate("details")}</span>
                <ImageButton src={VIEW_ALL_ICON} alt="viewAll" tabIndex="-1" />
              </PlayerSidebarAnchor>
            </PlayerSidebarRoute>
          )}
        </MovieSidebarPanelDetails>
      ) : null}
    </PlayerSidebarWrapper>
  );
}

export default MovieSideBar;
