import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import packageJson from "../../../package.json";
import "./style.scss";
import SeoPageTags from "../../components/SeoPageTags";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import { getRecordingSystemType } from "../../shared/utils/recordingHelper";
import { getLocalStorage } from "../../shared/utils/localStorage";
import storageConstants from "../../shared/constants/storage";

const { DEVICE_ID } = storageConstants;
/**
 * Component that renders Account Information
 * @component
 * @param {object} props
 */

function AccountPage(props) {
  const { t: translate } = useTranslation();
  const { trackPageView } = useTrackPageView();
  const { appProvider, userProfile, isInHome, featureToggles } = props;
  const { isRemoteRecordingsEnabled, isCloudPVREnabled } = featureToggles;

  /**
   * Return account details
   */
  function getAccountInfo() {
    let listItems = [
      {
        name: translate("username"),
        value: userProfile.user.profile.profileData.accountUsername,
      },
      {
        name: translate("account_id"),
        value: userProfile.user.profile.profileData.userId,
      },
      { name: translate("app_version"), value: packageJson.version },
      { name: translate("language"), value: appProvider.lang },
      { name: translate("client_id"), value: getLocalStorage(DEVICE_ID) },
      {
        name: translate("in_home_status"),
        value: isInHome ? translate("connected_at_home") : translate("not_connected_at_home"),
      },
    ];

    // Any account info that is useful for developers/QA can be added here
    // Note: These items below should not be surfaced to real users.
    if (appProvider.ENV !== "avs-prod" && appProvider.ENV !== "avs-beta") {
      if (isCloudPVREnabled || isRemoteRecordingsEnabled) {
        listItems.push({
          name: "PVR Type",
          value: getRecordingSystemType(userProfile) || "Recordings not enabled for this account",
        });
      }
    }
    return listItems;
  }

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <React.Fragment>
      <SeoPageTags title={translate("settings_account_info")} keywords={["optik", "telus"]} />
      <div className="Account">
        <div className="account-heading">
          <span className="account-label">{translate("settings_account_info")}</span>
        </div>
        <div>
          {getAccountInfo() && (
            <div className="account-list">
              {getAccountInfo().map((item, index) => (
                <p key={index}>
                  {item.name}
                  <span>{item.value}</span>
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

function mapStateToProps({ app }) {
  return {
    userProfile: app.userProfile,
    appProvider: app.provider,
    isInHome: app.isInHome,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
