import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

/**
 * Create Image button
 * @component
 * @param {Object} props
 */

const ImageButton = React.forwardRef((props, ref) => {
  const {
    className,
    alt,
    src,
    onClickHandler,
    testID,
    buttonName,
    onKeyHandler,
    tabIndex,
    onKeyDownHandler,
    disabled,
    tooltipDirection,
    tooltipHorizontalDirection,
  } = props;
  const classStyleName = `imageButton ${className}`;
  return (
    <button
      className={classStyleName}
      data-testid={testID}
      type="button"
      onClick={onClickHandler}
      onKeyUp={onKeyHandler}
      ref={ref}
      tabIndex={tabIndex}
      onKeyDown={onKeyDownHandler}
      disabled={disabled}
    >
      <img alt={alt} src={src} />

      {/* <span className={tooltipDirection + "btn-tooltip"}>{tooltip}</span> */}
      {alt && tooltipDirection && (
        <span className={`${tooltipDirection}  ${tooltipHorizontalDirection}   btn-tooltip`}>{alt}</span>
      )}

      {buttonName && <span className="button-name">{buttonName}</span>}
    </button>
  );
});

ImageButton.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func,
  onKeyHandler: PropTypes.func,
  testID: PropTypes.string,
  buttonName: PropTypes.string,
  buttonContainerStyles: PropTypes.string,
  onKeyDownHandler: PropTypes.func,
  disabled: PropTypes.bool,
};

ImageButton.defaultProps = {
  className: "",
  onClickHandler: (e) => e,
  buttonName: null,
  buttonContainerStyles: "",
  onKeyDownHandler: (e) => e,
  disabled: false,
};

export default ImageButton;
