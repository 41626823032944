import request from "../../service/request";
import constants from "../constants";
import { addQueryParamsToString } from "../utils";

const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

export function getProgramSchedules(
  appProvider,
  searchTerm,
  uaId,
  isSeries = false,
  cancelTokenSource = null,
  channelIds,
  startTime,
  endTime,
  maxResults,
  sortOrder
) {
  let url = appProvider.config.avs_api_urls.search_live;

  const withCredentials = appProvider.userCluster === "R";
  const queryParams = {};

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (searchTerm) {
    queryParams.query = searchTerm;
  }

  if (uaId) {
    if (isSeries) {
      queryParams.filter_uaSeriesId = uaId;
    } else {
      queryParams.filter_uaId = uaId;
    }
  }

  if (channelIds) {
    queryParams.filter_channelIds = channelIds;
  }

  if (startTime) {
    queryParams.filter_startTime = startTime;
  }

  if (endTime) {
    queryParams.filter_endTime = endTime;
  }

  if (appProvider.channelMapID) {
    queryParams.filter_regionId = appProvider.channelMapID;
  }

  if (appProvider.panicMode && withCredentials) {
    queryParams.filter_pcLevel = appProvider.pcLevel;
  }

  if (maxResults) {
    queryParams.maxResults = maxResults;
  }

  if (sortOrder) {
    queryParams.sortOrder = sortOrder;
  }

  url = addQueryParamsToString(url, queryParams);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request(
    {
      url,
      method: "GET",
      withCredentials,
    },
    cancelTokenSource,
    appProvider
  );
}
