import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { showModalPopup } from "../../App/state/actions";
import OptikButton from "../../components/OptikButton";
import ParentalRatingList from "../../components/ParentalRatingList";
import ParentalRatingModal from "../../components/ParentalRatingModal";
import { PIN_MODAL_MODES, PIN_MODAL_TYPES } from "../../components/PinModal";
import middleware from "../../shared/middleware";
import constants from "../../shared/constants";
import parentalControlConstants from "../../shared/constants/parentalControls";
import "./style.scss";
import { trackWebAction } from "../../shared/analytics/dataLayer";
import { getGenericErrorEventHandler } from "../../shared/analytics/helpers";
import { ACTION_VALUES, ANALYTICS_EVENT_TYPES, WEB_ACTION_EVENT_NAMES } from "../../shared/constants/analytics";
import { useReducers } from "../../shared/hooks/useReducer";

const { MODAL_TYPES, REDUCER_TYPE } = constants;
const { PARENTAL_RATINGS } = parentalControlConstants;
const { updateProfile } = middleware;

/**
 * A screen on the Edit Profile page for the user to change their parental control rating
 * @component
 * @param {Object} props
 */
function ParentalControlScreen({
  // The parental control screen can be opened multiple times while editing
  // the profile; this prop keeps track of the last selected rating.
  currentSelectedRating = 0,
  isMasterProfile = false,
  isPcPinEnabled = false,
  onRatingSelect = () => {},
  showModalPopup,
}) {
  const { provider: appProvider } = useReducers(REDUCER_TYPE.APP);
  const [selectedRating, setSelectedRating] = useState(currentSelectedRating);
  const [showRatingsDefinitions, setShowRatingsDefinitions] = useState(false);
  const { t: translate } = useTranslation();

  const ratingClickHandler = (key) => {
    const newSelectedRating = PARENTAL_RATINGS[key];
    const analyticsRatingValue = (newSelectedRating.value || "zero").toString();

    if (newSelectedRating.value !== selectedRating) {
      trackWebAction(ANALYTICS_EVENT_TYPES.PARENTAL_FILTER_START, { rating: analyticsRatingValue });
      setSelectedRating(newSelectedRating.value);
      showModalPopup(MODAL_TYPES.PIN, {
        title: translate("enter_parental_pin"),
        pinModalMode: PIN_MODAL_MODES.ACCESS,
        pinModalType: PIN_MODAL_TYPES.PARENTAL,
        pinConfirmHandler: async (pin) => {
          // Validate the parental control PIN by trying to update the profile with a
          // user-submitted PIN. validateParentalPIN is not used here because it tries
          // to update the PIN but standard profiles are not able to do so.
          await updateProfile(appProvider, { parentalControlPin: pin });

          // Pass the validated PIN and selected rating back to edit profile
          onRatingSelect({
            ratingValue: newSelectedRating.value,
            validatedPin: pin,
          });
          trackWebAction(ANALYTICS_EVENT_TYPES.PARENTAL_FILTER_COMPLETE, { rating: analyticsRatingValue });
        },
        pinCancelHandler: () => setSelectedRating(currentSelectedRating),
        pinAnalyticsErrorEventHandler: getGenericErrorEventHandler(
          ANALYTICS_EVENT_TYPES.PARENTAL_FILTER_ERROR,
          ACTION_VALUES.PARENTAL_FILTER,
          WEB_ACTION_EVENT_NAMES.PARENTAL_FILTER_ERROR
        ),
      });
    }
  };

  return (
    <>
      <div className="parental-control-container">
        <div className="heading-row">
          <h1>{translate("pin_parental_control")}</h1>
          <OptikButton
            className="ghost-button cancel-button"
            label={translate("close")}
            onClickHandler={() => onRatingSelect(null)}
          />
        </div>
        <p className="description">
          {!isMasterProfile && !isPcPinEnabled ? (
            <>
              <span className="pin-info">{translate("profiles_parental_on")}</span>
              <br />
              <br />
              <span>{translate("parental_control_manage_standard")}</span>
            </>
          ) : (
            translate("pin_profile_restrict")
          )}
        </p>
        <button className="text-button" onClick={() => setShowRatingsDefinitions(true)}>
          {translate("rating_definition")}
          <img src={`${process.env.PUBLIC_URL}/images/white-chevron.svg`} alt="" className="ml-10" />
        </button>
        <ParentalRatingList
          selectedRating={selectedRating}
          isPcPinEnabled={isPcPinEnabled}
          onRatingSelect={ratingClickHandler}
        />
      </div>
      {showRatingsDefinitions && <ParentalRatingModal handleClose={() => setShowRatingsDefinitions(false)} />}
    </>
  );
}

ParentalControlScreen.propTypes = {
  currentSelectedRating: PropTypes.number.isRequired,
  isMasterProfile: PropTypes.bool,
  isPcPinEnabled: PropTypes.bool,
  onRatingSelect: PropTypes.func,
  showModalPopup: PropTypes.func,
};

const mapDispatchToProps = {
  showModalPopup,
};

export default connect(null, mapDispatchToProps)(ParentalControlScreen);
