import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import englishStrings from "./strings_en.json";
import frenchStrings from "./strings_fr.json";

const resources = {
  en: {
    translation: englishStrings,
    punctuation: {
      colon: ": ",
      open_quote: "\u0022",
      close_quote: "\u0022",
    },
  },
  fr: {
    translation: frenchStrings,
    punctuation: {
      colon: " : ",
      open_quote: "\u00ab ",
      close_quote: " \u00bb",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    resources,
    lowerCaseLng: true, // lowercase detected language for consistency
    returnNull: false, // use fallbackLng if translation is null
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: false, // prevents re-rendering on language change
    },
  });

// eslint-disable-next-line import/no-unused-modules
export default i18n;
