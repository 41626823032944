import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import SidebarListItem from "../SideBarListItem";
import ImageButton from "../ImageButton";
import routeConstants from "../../shared/constants/routes";
import { isItemTypeMovie, isItemTypeSeries, isItemTypeEpisode } from "../../shared/utils/content";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import Button from "../Button";
import constants from "../../shared/constants";
import { getAutoGeneratedObject, getAutogeneratedEpisodeString, checkUnifiedAsset } from "../../shared/utils";
import { showModalPopup } from "../../App/state/actions";
import {
  PlayerSidebarWrapper,
  PlayerSidebarHideButtonWrapper,
  PlayerSidebarRoute,
  PlayerSidebarAnchor,
} from "../../pages/PlayerPage/PlayerPage.styles";
import {
  LivePlayerSidebarDetails,
  LivePlayerSidebarProgramDesc,
  LivePlayerSidebarList,
  LivePlayerTab,
  LivePlayerTabButton,
} from "./VideoPlayerSideBar.styles";
const { MOVIE_DETAIL_PAGE, SERIES_DETAIL_PAGE, EPISODE_DETAIL_PAGE } = routeConstants;

const { ACTION_KEYS, MODAL_TYPES } = constants;
const VIEW_ALL_ICON = process.env.PUBLIC_URL + "/images/white-chevron.svg";

const VideoPlayerSideBar = ({
  channels,
  programs,
  currentChannelId,
  onLiveItemClick,
  isInFullScreen,
  handleHideButtonClick,
  onTabItemSelected,
  onProgressComplete,
  recordingButtonClickHandler,
  currentPlayingProgram,
  isMR,
  isPlayerControlsDisable,
  dispatch,
}) => {
  const theme = useTheme();
  const screenWidth = window.screen.availWidth;
  const { t: translate } = useTranslation();
  const [currentProgramInfo, setCurrentProgramInfo] = useState(null);
  const [hubIndex, setHubIndex] = useState(0);
  const [isLive, setIsLive] = useState(true);

  useEffect(() => {
    if (programs && Object.keys(programs).length > 0) {
      setCurrentProgramInfo(currentPlayingProgram);
      setTimeout(() => {
        const element = document.getElementById(`live-channel-${currentChannelId}`);
        if (isInFullScreen && element) {
          element.scrollIntoView({
            behavior: "auto",
            block: "start",
            inline: "nearest",
          });
        }
      }, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannelId, programs, currentPlayingProgram]);

  const goToDetailPage = () => {
    const programInfo = currentProgramInfo.metadata;
    const itemLayout = currentProgramInfo.metadata.contentType;
    const clickAction = currentProgramInfo?.actions?.find(
      (action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK
    );
    let routeTo = "#";
    if (isItemTypeMovie(programInfo, itemLayout)) {
      routeTo = MOVIE_DETAIL_PAGE.route + clickAction?.uri;
    } else if (isItemTypeEpisode(programInfo)) {
      routeTo = EPISODE_DETAIL_PAGE.route + clickAction?.uri;
    } else if (isItemTypeSeries(programInfo, itemLayout)) {
      routeTo = SERIES_DETAIL_PAGE.route + clickAction?.uri;
    }
    return routeTo;
  };

  const uaEpisodeObject = getAutoGeneratedObject(currentProgramInfo?.metadata);
  const getProgramDetail = () => {
    const episodeString = getAutogeneratedEpisodeString(uaEpisodeObject, currentProgramInfo.metadata, false);
    return episodeString;
  };

  const onHoverIn = (show) => {
    setIsLive(hubIndex === 0 && parseInt(show?.id) === parseInt(currentPlayingProgram?.id));
    setCurrentProgramInfo(show);
  };
  const onHoverOut = () => {
    setIsLive(true);
    const liveShow = currentPlayingProgram;
    setCurrentProgramInfo(liveShow);
  };
  const onTabClick = (index) => {
    onTabItemSelected(index);
    setHubIndex(index);
  };
  const trackDetailsNavigation = () => {
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, LINK_INFO.MINI_GUIDE);
  };

  return (
    <PlayerSidebarWrapper theme={theme} id="sidebar">
      <PlayerSidebarHideButtonWrapper>
        <Button
          buttonStyles="list-button"
          label={translate("hide")}
          onClickHandler={handleHideButtonClick}
          tabIndex="-1"
        />
      </PlayerSidebarHideButtonWrapper>
      {currentProgramInfo ? (
        <LivePlayerSidebarDetails
          data={"sidebar-details"}
          theme={theme}
          width={screenWidth}
          className="sidebar-details"
        >
          {currentProgramInfo.metadata?.title && (
            <div
              className={
                currentProgramInfo.metadata && isItemTypeMovie(currentProgramInfo.metadata)
                  ? "program-details"
                  : "program-name"
              }
            >
              {currentProgramInfo.metadata.title}
            </div>
          )}
          {!uaEpisodeObject.isEpisodeAutoGenerated && !uaEpisodeObject.isSeasonAutoGenerated && (
            <div className="program-details">
              <span>{getProgramDetail()}</span>
            </div>
          )}
          {currentProgramInfo.metadata.longDescription && (
            <LivePlayerSidebarProgramDesc data={"program-description"} theme={theme} width={screenWidth}>
              {currentProgramInfo.metadata.longDescription}
            </LivePlayerSidebarProgramDesc>
          )}
          {isLive &&
            (checkUnifiedAsset(currentProgramInfo?.metadata?.extendedMetadata?.dlum) ? (
              <PlayerSidebarRoute
                data={"detail-link"}
                to={goToDetailPage()}
                tabIndex="-1"
                onClick={trackDetailsNavigation}
              >
                <span>{translate("details")}</span>
                <ImageButton src={VIEW_ALL_ICON} alt="viewAll" tabIndex="-1" />
              </PlayerSidebarRoute>
            ) : (
              <PlayerSidebarRoute>
                <PlayerSidebarAnchor
                  href="#"
                  tabIndex="-1"
                  onClick={(event) => {
                    event.preventDefault();
                    dispatch(showModalPopup(MODAL_TYPES.ERROR, { message: "error_non_unified", isCloseable: true })); // showing error for non-UA assets
                  }}
                >
                  <span>{translate("details")}</span>
                  <ImageButton src={VIEW_ALL_ICON} alt="viewAll" tabIndex="-1" />
                </PlayerSidebarAnchor>
              </PlayerSidebarRoute>
            ))}
        </LivePlayerSidebarDetails>
      ) : null}
      <LivePlayerTab data={"tab"} theme={theme} className="tab">
        <LivePlayerTabButton
          data={"tabLinks active"}
          theme={theme}
          $active={hubIndex === 0}
          onClick={(e) => onTabClick(0)}
        >
          {translate("on_now")}
        </LivePlayerTabButton>
        <LivePlayerTabButton data={"tabLinks"} theme={theme} $active={hubIndex === 1} onClick={(e) => onTabClick(1)}>
          {translate("on_next")}
        </LivePlayerTabButton>
      </LivePlayerTab>
      <LivePlayerSidebarList data={"sidebar-list"} className="sidebar-list">
        {channels &&
          channels.length > 0 &&
          channels.map((channel, index) => {
            const program = programs[channel.id];
            return (
              program && (
                <SidebarListItem
                  key={program.id}
                  channel={channel}
                  program={program}
                  currentProgramId={currentPlayingProgram?.id}
                  onLiveItemClick={onLiveItemClick}
                  hubIndex={hubIndex}
                  onHoverIn={onHoverIn}
                  onHoverOut={onHoverOut}
                  onProgressComplete={onProgressComplete}
                  recordingButtonClickHandler={recordingButtonClickHandler}
                  isMR={isMR}
                  itemRowNumber={index}
                  isPlayerControlsDisable={isPlayerControlsDisable}
                />
              )
            );
          })}
      </LivePlayerSidebarList>
    </PlayerSidebarWrapper>
  );
};

export default VideoPlayerSideBar;
