import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import storage from "../../shared/constants/storage";
import {
  ToolTipWrapper,
  ToolTipHeading,
  ToolTipMessage,
  ToolTipArrow,
  ToolTipCloseButton,
  ToolTipHeaderWrapper,
  ToolTipStepIndicator,
  ToolTipNavigationButton,
  TooltipButtonContainer,
} from "./GuideToolTip.styles";
import { useTheme } from "styled-components";
import { setLocalStorage } from "../../shared/utils/localStorage";

const closeButton = process.env.PUBLIC_URL + "/images/tooltip_close.svg";
const { HAS_SEEN_FAV_GUIDE_TOOL_TIP, TOOL_TIP_SESSION_START } = storage;

const GuideToolTip = ({
  tooltipMessages,
  handleClose,
  favChannelToolTipMessage,
  isFavChannelToolTipActive,
  gridHeight,
}) => {
  const { t: translate } = useTranslation();
  const theme = useTheme();
  const guideToolTipMessage = isFavChannelToolTipActive ? favChannelToolTipMessage : tooltipMessages;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleClickOutside = () => {
      handleClose();
      const currentTime = moment().valueOf();
      setLocalStorage(HAS_SEEN_FAV_GUIDE_TOOL_TIP, true);
      setLocalStorage(TOOL_TIP_SESSION_START, currentTime);
    };
    isFavChannelToolTipActive && document.addEventListener("click", handleClickOutside, true);
    return () => {
      isFavChannelToolTipActive && document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClose, isFavChannelToolTipActive]);

  const handleNext = () => {
    if (currentIndex === guideToolTipMessage.length - 1) {
      handleClose(true);
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + guideToolTipMessage.length) % guideToolTipMessage.length);
  };

  if (!guideToolTipMessage || guideToolTipMessage.length === 0) {
    return null;
  }

  return (
    <ToolTipWrapper
      $showButton={guideToolTipMessage[currentIndex].showButton}
      theme={theme}
      position={guideToolTipMessage[currentIndex].position}
      $isFavChannelToolTipActive={isFavChannelToolTipActive}
      gridHeight={gridHeight ? gridHeight() : 800}
    >
      <ToolTipArrow
        $showButton={guideToolTipMessage[currentIndex].showButton}
        theme={theme}
        position={guideToolTipMessage[currentIndex].position}
      ></ToolTipArrow>
      <ToolTipHeaderWrapper>
        <ToolTipHeading>{guideToolTipMessage[currentIndex].tooltipHeader}</ToolTipHeading>
        <ToolTipCloseButton src={closeButton} onClick={handleClose} />
      </ToolTipHeaderWrapper>
      <ToolTipMessage $isFavChannelToolTipActive={isFavChannelToolTipActive}>
        {guideToolTipMessage[currentIndex].tooltipText}
      </ToolTipMessage>
      {guideToolTipMessage[currentIndex].showButton ? (
        <TooltipButtonContainer>
          <ToolTipStepIndicator>{`${currentIndex + 1}/${guideToolTipMessage.length}`}</ToolTipStepIndicator>
          <div>
            {currentIndex > 0 && (
              <ToolTipNavigationButton className="back-button" onClick={handleBack}>
                {translate("Back")}
              </ToolTipNavigationButton>
            )}
            <ToolTipNavigationButton theme={theme} onClick={handleNext}>
              {translate(currentIndex === guideToolTipMessage.length - 1 ? translate("done") : translate("next"))}
            </ToolTipNavigationButton>
          </div>
        </TooltipButtonContainer>
      ) : null}
    </ToolTipWrapper>
  );
};

export default GuideToolTip;
