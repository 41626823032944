import {
  SET_RECORDINGS,
  GET_RECORDINGS,
  DELETE_RECORDINGS,
  CONFLICT_RECORDINGS,
  EDIT_RECORDINGS,
  TOGGLE_SETTINGS_PANEL,
  MANIPULATE_RECORDING_ACTION_TRIGGERED,
  GET_RECORDINGS_BOOKMARKS,
} from "./actions";

const initialState = {
  setRecordingResponse: null,
  recordingsList: null,
  getRecordingError: null,
  editRecordingResponse: null,
  deleteRecordingResponse: null,
  displaySettingPanel: false,
  manipulatedRecordingParams: null,
  bookmarks: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RECORDINGS:
      return { ...state, setRecordingResponse: action.content };
    case GET_RECORDINGS:
      if (action.error !== undefined) {
        return { ...state, getRecordingError: action.error };
      } else {
        return { ...state, recordingsList: action.content };
      }
    case DELETE_RECORDINGS:
      return { ...state, deleteRecordingResponse: action.content };
    case CONFLICT_RECORDINGS:
      return { ...state, setRecordingResponse: action.content };
    case EDIT_RECORDINGS:
      return { ...state, editRecordingResponse: action.content };
    case TOGGLE_SETTINGS_PANEL:
      return { ...state, displaySettingPanel: action.toggle };
    case MANIPULATE_RECORDING_ACTION_TRIGGERED:
      return { ...state, manipulatedRecordingParams: action.recordingParams };
    case GET_RECORDINGS_BOOKMARKS:
      return { ...state, bookmarks: action.content };
    default:
      return state;
  }
};
