import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./style.scss";
import ImageButton from "../ImageButton";
import { getRegionalChannelNumber } from "../../shared/utils/epg";
import constants from "../../shared/constants";
import playerConstants from "../../shared/constants/player";
import { handleImageError } from "../../shared/utils/image";
import { useReducers } from "../../shared/hooks/useReducer";

import { addFavourites, deleteFavourites, retrieveFavourite } from "../../App/state/actions";

const { ASSET_TYPES } = playerConstants;
const { PAGE_CONTENT_ITEM_TYPES, REDUCER_TYPE } = constants;
const favouriteIcon = process.env.PUBLIC_URL + "/images/favourite.svg";
const favouriteActiveIcon = process.env.PUBLIC_URL + "/images/favourite_active_channel.svg";
const DefaultChannelImage = process.env.PUBLIC_URL + "/images/default-channel-logo.png";
/**
 * Display Favourite channel side panel
 * @param {Object} props
 */
const ChannelSidePanel = (props) => {
  const { toggleChannelSidePanelAction, favChannelIds } = props;
  const { provider: appProvider, subscribedChannels } = useReducers(REDUCER_TYPE.APP);
  const { t: translate } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [sortedChannels, setSortedChannels] = useState([]);
  const isAPIcallFired = useRef(false);

  /*Closing Channel side panel when navigated from one page to another*/
  useEffect(() => {
    const unListen = history.listen(() => {
      toggleChannelSidePanelAction(false);
    });
    return unListen;
  }, [history, toggleChannelSidePanelAction]);

  useEffect(() => {
    if (subscribedChannels?.containers?.length > 0) {
      dispatch(retrieveFavourite(appProvider, PAGE_CONTENT_ITEM_TYPES.live, subscribedChannels));
      const subscribedChannelObject = subscribedChannels.containers.map((channel) => {
        return { ...channel, channelNumber: getRegionalChannelNumber(channel, appProvider?.channelMapID) };
      });
      setSortedChannels(
        subscribedChannelObject.sort(
          (firstChannel, secondChannel) => firstChannel?.channelNumber - secondChannel?.channelNumber
        )
      );
    }
  }, [appProvider, subscribedChannels, dispatch]);

  useEffect(() => {
    isAPIcallFired.current = false;
  }, [favChannelIds]);

  const addFavourite = (channel) => {
    if (!isAPIcallFired.current) {
      isAPIcallFired.current = true;
      dispatch(addFavourites(appProvider, channel, PAGE_CONTENT_ITEM_TYPES.live));
    }
  };

  const removeFavourite = (channel) => {
    if (!isAPIcallFired.current) {
      isAPIcallFired.current = true;
      dispatch(deleteFavourites(appProvider, channel, PAGE_CONTENT_ITEM_TYPES.live));
    }
  };

  return (
    sortedChannels?.length > 0 && (
      <div className="channel-panel-backdrop-overlay">
        <div className="channel-favourite-panel">
          <div className="channel-favourite-panel-container">
            <div className="side-panel-top">
              <div className="close-panel" onClick={() => toggleChannelSidePanelAction(false)}>
                {translate("close")}
              </div>
              <div className="channel-favourite-panel-title">{translate("edit_favourite_channels")}</div>
            </div>
            <div className="panel-scrollable-channels">
              {sortedChannels.map((channel) => {
                const isChannelFavourite =
                  favChannelIds?.length > 0 && favChannelIds.some((id) => id === channel.metadata?.channelId);
                const channelLogo =
                  channel.assets?.length > 0 &&
                  channel.assets.find((asset) => asset.assetType === ASSET_TYPES.MASTER.toUpperCase())?.logoSmall;
                return (
                  <li key={channel.metadata?.channelId}>
                    <div
                      className="channel-fav-list"
                      onClick={isChannelFavourite ? () => removeFavourite(channel) : () => addFavourite(channel)}
                    >
                      <div className="panel-channel-info">
                        <div className="panel-channel-number">{channel.channelNumber}</div>
                        <div className="panel-channel-logo">
                          {channelLogo ? (
                            <img
                              src={`${channelLogo}?w=80`}
                              alt=""
                              onError={(e) => handleImageError(e, DefaultChannelImage)}
                            />
                          ) : (
                            <img src={DefaultChannelImage} alt="" />
                          )}
                        </div>
                      </div>
                      <div className="panel-channel-name">{channel.metadata?.channelName}</div>
                      <ImageButton
                        src={isChannelFavourite ? favouriteActiveIcon : favouriteIcon}
                        className="channel-favourite-icon"
                        testid="channelFavouriteIcon"
                        buttonContainerStyles="button-container"
                        alt=""
                      />
                    </div>
                  </li>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

ChannelSidePanel.propTypes = {
  toggleChannelSidePanelAction: PropTypes.func,
  favChannelIds: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ChannelSidePanel;
