/**
 * load core modules
 */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
/* load core modules end */

/**
 * load child components
 */
import ImageButton from "../../components/ImageButton";
/* load child components end */

/**
 * load assets
 */
import trailer_icon from "./assets/trailer.svg";
/* load assets end */

/**
 * load custom hooks
 */
import { useReducers } from "../../shared/hooks/useReducer";
import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";
/* load custom hooks end */

/**
 * load utilities
 */
import { setSessionStorage } from "../../shared/utils/sessionStorage";
/* load utilities end */

/**
 * load analytics helper functions
 */
import { getAAVideoPlayType } from "../../shared/analytics/helpers";
import { logNREvent } from "../../shared/analytics/newRelic";
/* load analytics helper functions end */

/**
 * load constants
 */
import constants from "../../shared/constants";
import { LINK_INFO, ANALYTICS_STORAGE_KEYS } from "../../shared/constants/analytics";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import playerConstants from "../../shared/constants/player";
import routeConstants from "../../shared/constants/routes";
/* load constants end */

/** declare/destructure constants */
const { REDUCER_TYPE, PAGE_CONTENT_ITEM_TYPES } = constants;
const { PLAYBACK_TYPES } = playerConstants;
const { ON_DEMAND_PLAYER } = routeConstants;

/**
 * Component to render trailer on movie detail pages
 * @component
 * @param {Object} props
 */
const Trailer = React.memo(({ watchOptions, trailer, getMediaNodeMetadata }) => {
  const { userProfile } = useReducers(REDUCER_TYPE.APP);
  const { t: translate } = useTranslation();

  const isUserLoggedIn = !!userProfile?.isLoggedIn;

  const { performPlaybackChecks } = usePlaybackChecks();

  const onTrailerClick = () => {
    setSessionStorage(
      ANALYTICS_STORAGE_KEYS.LINK,
      `${LINK_INFO.TRAILER_PLAY};${LINK_INFO.SUB_NAV};${getAAVideoPlayType()}`
    );
    logNREvent(NR_PAGE_ACTIONS.TRAILER_STARTED);
    let playableContent;
    if (watchOptions?.length) {
      playableContent = watchOptions.find((watchOption) => {
        return watchOption.contentId?.toString() === trailer.id?.toString();
      });
    }

    performPlaybackChecks(
      { ...playableContent, ...trailer.asset },
      PLAYBACK_TYPES.TRAILER,
      trailer.id,
      PAGE_CONTENT_ITEM_TYPES.vod,
      ON_DEMAND_PLAYER.route,
      getMediaNodeMetadata
    );
  };

  return isUserLoggedIn && trailer && Object.keys(trailer)?.length > 0 ? (
    <ImageButton
      src={trailer_icon}
      className="wall-content-icon"
      testid="wallTrailerIcon"
      buttonContainerStyles="button-container"
      onClickHandler={onTrailerClick}
      alt={translate("trailer")}
      tooltipDirection="top"
    />
  ) : null;
});

Trailer.propTypes = {
  watchOptions: PropTypes.array,
  trailer: PropTypes.object,
  getMediaNodeMetadata: PropTypes.func,
};

export default Trailer;
