import React from "react";
import { useDispatch } from "react-redux";

import MovieSideBar from "./MovieSideBar";

const Index = (props) => {
  const dispatch = useDispatch();

  return <MovieSideBar dispatch={dispatch} {...props} />;
};

export default Index;
