import moment from "moment";
import i18n from "../../i18n.js";
import {
  isItemTypeMovie,
  isItemTypeSeries,
  isItemTypeEpisode,
  isItemTypeLive,
  isItemTypeNetwork,
  isItemTypeCrew,
  isItemTypeRecording,
} from "./content";
import { getAVSKeyArtImage, getImageAspectRatioByDims } from "./image";
import constants from "../constants";
import styleVariables from "../../variables.scss";
import recordingConstants from "../constants/recordingConstants";
import { getAVSPosterArtImage } from "../utils/image";
import routeConstants from "../../shared/constants/routes";
import swimlaneConstants from "../constants/swimlane";
import { isOnNowFeedItem } from "../middleware/feeds";
import { setLocalStorage } from "../utils/localStorage";
import {
  getUpNextProgram,
  doesContentSupportStartOver,
  isGhostChannel,
  is4KChannel,
  isContentOOHBlocked,
  checkIsLookbackSupported,
} from "../utils/epg";
import {
  getMonthKeyByNum,
  updateQueryStringParameter,
  calculateLookbackHoursLeft,
  getNewestLookbackProgram,
  getAutoGeneratedObject,
  getAutogeneratedEpisodeString,
  checkUnifiedAsset,
} from "../utils";
import {
  getRecordingSystemType,
  getRouteToPVRManager,
  getCPVRExpiryString,
  getRecordingCTAs,
  isCPVRRecordingInProgress,
} from "./recordingHelper";
import { getChannelInfoString } from "../utils/feedHelper.js";
import storageConstants from "../../shared/constants/storage";

const {
  MOVIE_DETAIL_PAGE,
  SERIES_DETAIL_PAGE,
  CAST_DETAIL_PAGE,
  SVOD_ALL_PAGE,
  VIEW_ALL_PAGE,
  GUIDE,
  SUB_CATEGORIES_PAGE,
  EPISODE_DETAIL_PAGE,
  RECORDING_PLAYER,
  ON_DEMAND_PLAYER,
  LIVE_PLAYER,
} = routeConstants;
const { SWIMLANE_IDS, SWIMLANE_TITLES, ITEM_TYPES } = swimlaneConstants;
const {
  ACTION_KEYS,
  BUNDLE_LAYOUT_ITEM_TYPE,
  PAGE_CONTENT_ITEM_TYPES,
  FAVOURITE_CHANNELS,
  IMAGES,
  FAVOURITE_ASSETS,
  PURCHASE_ASSETS,
  RESUME_ASSETS,
  CONTENT_ITEM_TYPES,
} = constants;
const { RECORDING_PACKAGES } = recordingConstants;
const { FILTERS_SUFFIX, SORT_OPTIONS_SUFFIX } = storageConstants;

const THUMBNAIL_FALLBACK_16x9 = process.env.PUBLIC_URL + "/images/swimlane-landscape-324px.jpg";
const THUMBNAIL_FALLBACK_2x3 = process.env.PUBLIC_URL + "/images/swimlane-portrait-166px.jpg";
const THUMBNAIL_FALLBACK_CREW = process.env.PUBLIC_URL + "/images/Cast-166px.png";

/**
 * Creates and returns a swimlane item model object
 *
 * @returns {Object} - SwimlaneItem model object
 */
const getSwimlaneItemModel = () => {
  return {
    id: null,
    title: null,
    subtitle: {
      default: null,
      hover: null,
      styleOverride: null,
    },
    thumbnail: {
      src: null,
      alt: null,
      dimensions: null,
      fallbackSrc: null,
    },
    progressPercentage: null,
    channelLogo: null,
    entitlements: {
      isRestartable: false,
      isInHomeRequired: false,
      isFromGhostChannel: false,
    },
    route: null,
    recording: {
      iconSrc: null,
      isRecordingNow: false,
    },
    analyticsSrc: null,
  };
};

/**
 * Creates and returns an episode swimlane item model for season swimlanes
 *
 * @returns EpisodeSwimlaneItem model object
 */
const getEpisodeSwimlaneItemModel = () => {
  return {
    id: null,
    title: null,
    subtitle: {
      lineOne: null,
      lineTwo: null,
      lineOneStyleOverride: null,
    },
    thumbnail: {
      src: null,
      alt: null,
      dimensions: null,
      fallbackSrc: null,
    },
    description: null,
    entitlements: {
      isRestartable: false,
      isInHomeRequired: false,
      isFromGhostChannel: false,
      isUnsubscribed: false,
    },
    isCompletelyWatched: false,
    progressPercentage: null,
    routes: {
      details: null,
      playback: null,
    },
    analyticsSrc: null,
  };
};

/**
 * Determines the detail page path based on the item type.
 * @param {object[]} actions - Array of action objects
 * @param {object} itemMetadata - Item metadata object
 * @param {String} itemLayout - UI builder supported layout type (required for non-unified assets)
 * @returns {String} detail page path
 */
const getDetailPagePath = (actions, itemMetadata, itemLayout = null) => {
  let path = "";

  if (actions && itemMetadata) {
    const clickActionUri = actions.find((action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK)?.uri;
    if (clickActionUri) {
      if (isItemTypeRecording(itemMetadata)) {
        const unifiedAssetId = itemMetadata?.extendedMetadata?.dlum?.uaId;
        const uri = `/PAGE/DETAILS/${PAGE_CONTENT_ITEM_TYPES.movie}/${unifiedAssetId}`;
        path = isItemTypeMovie(itemMetadata, itemLayout)
          ? MOVIE_DETAIL_PAGE.route + uri
          : EPISODE_DETAIL_PAGE.route + uri;
      } else if (isItemTypeSeries(itemMetadata, itemLayout)) {
        path = SERIES_DETAIL_PAGE.route + clickActionUri;
      } else if (isItemTypeMovie(itemMetadata, itemLayout)) {
        path = MOVIE_DETAIL_PAGE.route + clickActionUri;
      } else if (isItemTypeEpisode(itemMetadata)) {
        const detailPage = isSeriesDetailPageUrl(clickActionUri) ? SERIES_DETAIL_PAGE : EPISODE_DETAIL_PAGE;
        path = detailPage.route + clickActionUri;
      }
    }
  }

  return path;
};

/**
 * Takes a content item of varying structures (depending on the API) and maps the data to a standard
 * swimlane item model. The original content item is also available via model.analyticsSrc
 *
 * @param {Object} item content item of varying types
 * @param {Object} dimensions thumbnail dimensions to use, can differ between swimlanes and content types
 * @param {Boolean} isAppLanguageFrench flag indicating if the app language is french
 * @param {Object} userProfile
 * @param {Object} currentPrograms Map where keys are timestamps and values are lists of EPG programs
 * @param {Object} avsComponent UI builder component item or equivalent
 * @param {Boolean} appendFrenchTag flag indicating if the FR tag should be appended
 * @param {Object} recordingProfile
 * @returns SwimlaneItem model object, see getSwimlaneItemModel() for structure
 */
const createSwimlaneItemModel = (
  item,
  dimensions,
  isAppLanguageFrench,
  userProfile,
  currentPrograms,
  avsComponent,
  appendFrenchTag = false,
  recordingProfile
) => {
  if (item?.metadata) {
    const model = getSwimlaneItemModel();
    const aspectRatio = getImageAspectRatioByDims(dimensions.width, dimensions.height);
    const clickAction = item.actions?.find((action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK);
    let playerRoute, defaultRoute;
    // Common data assignments (may get overwritten depending on scenario)
    model.id = item.metadata.contentId || item.metadata.updateId;
    model.analyticsSrc = item;
    model.thumbnail.dimensions = dimensions;
    model.thumbnail.fallbackSrc =
      aspectRatio === IMAGES.ASPECT_RATIOS.DIM_9x16 ? THUMBNAIL_FALLBACK_16x9 : THUMBNAIL_FALLBACK_2x3;

    if (isItemTypeNetwork(item.metadata)) {
      model.thumbnail.src = getAVSPosterArtImage(item.metadata, aspectRatio, true);
      model.thumbnail.alt = item.metadata.title;
      defaultRoute = updateQueryStringParameter(`${SVOD_ALL_PAGE.route}${clickAction?.uri}`, {
        image: encodeURIComponent(model.thumbnail.src || THUMBNAIL_FALLBACK_2x3),
      });
    } else if (isItemTypeCrew(item.metadata)) {
      model.title = item.metadata.name;
      if (isAppLanguageFrench && item.metadata.roleFra) {
        model.subtitle.default = item.metadata.roleFra;
      } else if (item.metadata.role) {
        model.subtitle.default = item.metadata.role;
      }

      model.thumbnail.src = item.metadata.image34;
      model.thumbnail.alt = item.metadata.name;
      model.thumbnail.fallbackSrc = THUMBNAIL_FALLBACK_CREW;
      defaultRoute =
        `${CAST_DETAIL_PAGE.route}?title=${encodeURIComponent(model.title)}` +
        `&image=${encodeURIComponent(item.metadata.image34 || THUMBNAIL_FALLBACK_CREW)}`;

      if (model.subtitle.default) {
        defaultRoute += `&subtitle=${encodeURIComponent(model.subtitle.default)}`;
      }
    } else if (isItemTypeRecording(item.metadata)) {
      // Need to calculate user-specific recording end time
      const userRecordingEndTime = item.metadata.recordingStartTime + item.metadata.stopDeltaTime;
      // Number of days recordings will be available on CPVR before being removed
      const recordingAvailabilityDays = recordingProfile?.recordingProfileData?.keepDays || "";

      let cpvrExpiryString;
      if (userRecordingEndTime && recordingAvailabilityDays) {
        const recordingExpiryTime = userRecordingEndTime + parseInt(recordingAvailabilityDays) * 86400000;
        cpvrExpiryString = getCPVRExpiryString({ ...item.metadata, recordingExpiryTime });
      }

      let remainingTime;
      let progressPercentage = 0;
      const progressSeconds = item.metadata.bookmarks?.[0]?.startDeltaTime;
      if (progressSeconds > 0) {
        // Calculating the user-specific recording duration ourself
        const durationSeconds = (item.metadata.stopDeltaTime - item.metadata.startDeltaTime) / 1000;
        if (durationSeconds > 0) {
          remainingTime = durationSeconds - progressSeconds;
          progressPercentage = (progressSeconds / durationSeconds) * 100;
          progressPercentage = Math.ceil(progressPercentage) < 100 ? progressPercentage : 0;
        }
      }

      model.progressPercentage = progressPercentage;
      const isRecordingNow = isCPVRRecordingInProgress(item);
      model.isUHD = is4KChannel(item.channel) || item.metadata.extendedMetadata?.isUHD ? true : false;
      model.hasRecordingAssets = item.assets?.length > 0 ? true : false;
      // episodic recordings should display episode info in the title
      if (item.metadata.episodeNumber) {
        model.title = getEpisodeDetail(item.metadata);

        // Replace the asset subtitle with the formatted recording date (MMM dd, yyyy)
        model.subtitle.default = getFormattedDate(item.metadata.recordingStartTime, false);

        if (avsComponent?.title?.toLowerCase() === SWIMLANE_TITLES.RESUME) {
          // subtitle should be remaining time if resume swimlane
          model.subtitle.default = getRemainingTimeString(remainingTime);
        } else {
          if (isRecordingNow) {
            // for non-resume swimlanes, the recording item subtitle is replaced by the "recording now" badge if the recording is in progress
            model.recording.isRecordingNow = true;
          } else {
            // subtitle should be expiry string if expiring soon, otherwise use the series title
            if (cpvrExpiryString) {
              model.subtitle.default = cpvrExpiryString;
              model.subtitle.styleOverride = { color: styleVariables.mangoTango };
            }
          }
        }
      } else {
        // if non-episodic, title should be program title
        model.title = item.metadata.title;

        if (avsComponent?.title?.toLowerCase() === SWIMLANE_TITLES.RESUME) {
          // subtitle should be remaining time if resume swimlane
          model.subtitle.default = getRemainingTimeString(remainingTime);
        } else {
          if (isRecordingNow) {
            // for non-resume swimlanes, the recording item subtitle is replaced by the "recording now" badge if the recording is in progress
            model.recording.isRecordingNow = true;
          } else if (cpvrExpiryString) {
            // if the recording is not in progress, the subtitle should be the expiry string if expiring soon
            model.subtitle.default = cpvrExpiryString;
            model.subtitle.styleOverride = { color: styleVariables.mangoTango };
          } else if (userRecordingEndTime) {
            // otherwise use the recording end date
            const dateStringArr = moment(userRecordingEndTime).format("YYYY-MM-DD").split("-");
            if (dateStringArr && dateStringArr.length === 3) {
              model.subtitle.default = `${i18n.t(getMonthKeyByNum(parseInt(dateStringArr[1])))} ${dateStringArr[2]}, ${
                dateStringArr[0]
              }`;
            }
          }
        }
      }

      if (item.channel?.metadata) {
        model.entitlements.isInHomeRequired = isContentOOHBlocked(item, item.channel);
        model.entitlements.isFromGhostChannel = isGhostChannel(item.channel);
      }

      model.thumbnail.src = getAVSPosterArtImage(item.metadata, aspectRatio);
      model.thumbnail.alt = item.metadata.title;

      defaultRoute = getDetailPagePath(item.actions, item.metadata);
      playerRoute =
        `${RECORDING_PLAYER.route}/episode/?playbackId=${item.metadata.contentId}` +
        `&externalId=${item.metadata.externalId}&contentType=${PAGE_CONTENT_ITEM_TYPES.recording}`;
    } else if (isItemTypeLive(item.metadata) && !(aspectRatio === IMAGES.ASPECT_RATIOS.DIM_2x3)) {
      model.title = item.metadata.title;
      const airingStartTimestamp = moment(item.metadata.airingStartTime);
      const airingTime = airingStartTimestamp.format("LT");
      const currentDate = moment().format("[date_format]");
      const airingDate = airingStartTimestamp.format("[date_format]");
      let dateString;
      if (currentDate === airingDate) {
        dateString = i18n.t("today_at");
      } else if (airingDate === moment().add(1, "d").format("[date_format]")) {
        dateString = i18n.t("tomorrow_at");
      }

      const currentTimeMs = moment().valueOf();
      const isProgramOnNow =
        item.metadata.airingStartTime <= currentTimeMs && currentTimeMs <= item.metadata.airingEndTime;
      if (item.channel) {
        const isSwimlaneTrending = avsComponent?.title?.toLowerCase() === SWIMLANE_TITLES.TRENDING;
        model.subtitle.default = isProgramOnNow
          ? isSwimlaneTrending
            ? i18n.t("on_now")
            : getChannelInfoString(item.channel, userProfile?.user?.profile?.profileData?.regionId)
          : isSwimlaneTrending && dateString
          ? `${dateString} ${airingTime}`
          : null;

        const upNextProgram =
          isProgramOnNow &&
          getUpNextProgram(currentPrograms, item.channel.metadata?.channelId, item.metadata.contentId);

        if (upNextProgram?.metadata?.title) {
          model.subtitle.hover = `${i18n.t("up_next")}${i18n.t("punctuation:colon")} ${upNextProgram.metadata.title}`;
        } else if (isSwimlaneTrending) {
          model.subtitle.hover = getChannelInfoString(item.channel, userProfile?.user?.profile?.profileData?.regionId);
        }

        if (item.channel.assets?.[0]) {
          model.channelLogo = item.channel.assets[0].logoSmall;
        }

        model.entitlements.isRestartable = doesContentSupportStartOver(item, item.channel);
        model.entitlements.isInHomeRequired = isContentOOHBlocked(item, item.channel);
        model.entitlements.isFromGhostChannel = isGhostChannel(item.channel);

        playerRoute = `${LIVE_PLAYER.route}/?stationId=${item.channel.metadata?.channelId}&contentType=LIVE`;
      }

      model.thumbnail.src = getAVSPosterArtImage(item.metadata, aspectRatio);
      model.thumbnail.alt = item.metadata.title;

      let progressPercentage = 0;
      if (item.metadata.airingStartTime && item.metadata.airingEndTime) {
        if (isProgramOnNow) {
          // Program is on now, calculate progress for progress bar
          const progressMs = currentTimeMs - item.metadata.airingStartTime;
          const durationMs = item.metadata.airingEndTime - item.metadata.airingStartTime;

          if (progressMs > 0 && durationMs > 0) {
            progressPercentage = (progressMs / durationMs) * 100;
            progressPercentage = Math.ceil(progressPercentage) < 100 ? progressPercentage : 0;
          }
        } else {
          // Program is not on now, direct users to detail page
          playerRoute = null;
        }
      }

      model.progressPercentage = progressPercentage;

      if (item.recordingData?.recordingInfo) {
        let eventRecordingSet, seriesRecordingSet, eventRecordingConflicted, seriesRecordingConflicted;
        if (item.recordingData.isMR) {
          eventRecordingSet = item.recordingData.recordingInfo.recordingEventScheduledCheck;
          seriesRecordingSet = item.recordingData.recordingInfo.recordingSeriesScheduledCheck;
          eventRecordingConflicted = item.recordingData.recordingInfo.recordingEventConflictCheck;
          seriesRecordingConflicted = item.recordingData.recordingInfo.recordingSeriesConflictCheck;
        }

        const recordingCTA = getRecordingCTAs(
          item.recordingData.isMR,
          item.recordingData.recordingInfo,
          item,
          eventRecordingConflicted,
          eventRecordingSet,
          seriesRecordingConflicted,
          seriesRecordingSet
        );

        if (recordingCTA.recordingExists) {
          model.recording.iconSrc = recordingCTA.recordingIcon;
        }
      }

      defaultRoute = getDetailPagePath(item.actions, item.metadata);
    } else {
      let remainingTime;
      let progressPercentage = 0;
      const progressSeconds = item.metadata.bookmarks?.[0]?.startDeltaTime;
      // Somehow its possible for items to exist in the Resume swimlane with 0 progress,
      // so we need to include these in order to display the remaining time string in subtitle
      if (progressSeconds >= 0) {
        const durationSeconds = item.metadata.duration;
        if (durationSeconds > 0) {
          remainingTime = durationSeconds - progressSeconds;
          progressPercentage = (progressSeconds / durationSeconds) * 100;
          progressPercentage = Math.ceil(progressPercentage) < 100 ? progressPercentage : 0;
        }
      }

      model.progressPercentage = progressPercentage;

      if (remainingTime >= 0) {
        model.title = isItemTypeEpisode(item.metadata) ? getEpisodeDetail(item.metadata) : item.metadata.title;
        model.subtitle.default = getRemainingTimeString(remainingTime);
      } else if (
        avsComponent &&
        item.metadata.year &&
        avsComponent.title !== FAVOURITE_ASSETS.TITLE &&
        avsComponent.title !== PURCHASE_ASSETS.TITLE
      ) {
        model.title = item.metadata.year.toString();
        model.subtitle.default = item.metadata.extendedMetadata?.dlum?.rating;
      } else if (avsComponent) {
        model.title =
          avsComponent.title !== FAVOURITE_ASSETS.TITLE && avsComponent.title !== PURCHASE_ASSETS.TITLE
            ? item.metadata.extendedMetadata?.dlum?.rating
            : "";
      }

      model.thumbnail.src = getAVSPosterArtImage(item.metadata, aspectRatio);
      model.thumbnail.alt = item.metadata.title;

      defaultRoute = getDetailPagePath(item.actions, item.metadata, item.layout);

      let playerContentType;
      if (isItemTypeEpisode(item.metadata)) {
        if (clickAction?.uri && !isSeriesDetailPageUrl(clickAction.uri)) {
          model.thumbnail.src = getAVSKeyArtImage(item.metadata, aspectRatio);
          model.thumbnail.alt = item.metadata.episodeTitle;
        }
        playerContentType = "episode";
      } else if (isItemTypeMovie(item.metadata, item.layout)) {
        playerContentType = "movie";
      }

      if (progressSeconds >= 0 || avsComponent?.title?.toLowerCase() === SWIMLANE_TITLES.PURCHASES) {
        playerRoute =
          `${ON_DEMAND_PLAYER.route}/${playerContentType}/?playbackId=${item.metadata.contentId}` +
          `&contentType=${item.metadata.contentType}`;
      }
    }

    // Only want to append the french tag if the content's default language is french
    if (appendFrenchTag && item.metadata.extendedMetadata?.dlum?.defaultLanguage?.toLowerCase().startsWith("fr")) {
      model.subtitle.default = `${model.subtitle.default}\u00a0\u00a0|\u00a0\u00a0${i18n.t("search_display_language")}`;
    }

    // Anything that should trigger a toast when attempting to trigger playback will be handled within the item component.
    // This way we don't send users to detail pages and we can still show the player href
    if (playerRoute && userProfile?.isLoggedIn && !model.entitlements.isFromGhostChannel) {
      model.route = playerRoute;
    } else {
      model.route = defaultRoute;
    }
    model.isUnifiedAsset = checkUnifiedAsset(
      item.metadata?.entityType?.toLowerCase() === CONTENT_ITEM_TYPES.team
        ? item.metadata
        : item.metadata?.extendedMetadata?.dlum
    );
    model.defaultRoute = defaultRoute;
    model.program = item;

    return model;
  }

  return null;
};

/**
 * Creates episode swimlane items for season swimlanes.
 *
 * @param {Object[]} episodeEntitlements Array of episode items with associated user data (items from the CONTENT/USERDATA/SEASON API)
 * @param {Object[]} programs Array of EPG episodic programs for the TV show the episodes belong to
 * @param {Object[]} subscribedChannels Array of subscribed channel items
 * @param {Boolean} isGeoBlocked
 * @returns {Object[]} Array of EpisodeSwimlaneItemModels, see getEpisodeSwimlaneItemModel() for object structure
 */
export const createEpisodeSwimlaneItems = (
  episodeEntitlements,
  programs,
  subscribedChannels,
  isGeoBlocked,
  isInHome,
  isLookbackEnabled = false,
  channelMapID = null
) => {
  if (episodeEntitlements.length > 0 && programs && subscribedChannels) {
    const currentTimeMs = moment().valueOf();
    const dimensions = ITEM_TYPES.TITLE_ITEM.EP_LIST_LANDSCAPE.DIMENSIONS.values;
    const aspectRatio = getImageAspectRatioByDims(dimensions.width, dimensions.height);

    return episodeEntitlements.map((episode) => {
      const model = getEpisodeSwimlaneItemModel();
      model.id = episode.id;
      model.analyticsSrc = episode;
      model.thumbnail.dimensions = dimensions;
      model.thumbnail.fallbackSrc =
        aspectRatio === IMAGES.ASPECT_RATIOS.DIM_9x16 ? THUMBNAIL_FALLBACK_16x9 : THUMBNAIL_FALLBACK_2x3;

      if (episode.metadata) {
        let progressPercentage;
        let isLiveEntitlement = false;

        // Prepare the episode item title
        const contentTitle = episode.metadata.episodeTitle || episode.metadata.title;
        if (!episode.metadata.episodeNumber || getAutoGeneratedObject(episode.metadata).isEpisodeAutoGenerated) {
          model.title = contentTitle;
        } else {
          model.title = `${episode.metadata.episodeNumber}${contentTitle ? `. ${contentTitle}` : ""}`;
        }

        // Get the episode image
        model.thumbnail.src = getAVSKeyArtImage(episode.metadata, aspectRatio);
        model.thumbnail.alt = contentTitle;

        // Set the description
        model.description = episode.metadata.longDescription;

        // Determine the detail page route
        const clickAction = episode.actions?.find((action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK);
        if (clickAction?.uri) {
          model.routes.details = `${EPISODE_DETAIL_PAGE.route}${clickAction.uri}`;
        } else {
          // Building the detail page route if no click action exists
          model.routes.details = `${EPISODE_DETAIL_PAGE.route}/PAGE/DETAILS/MOVIE/${episode.uaId}`;
        }

        // Default to unsubscribed, but update as appropriate depending on the logic below
        model.entitlements.isUnsubscribed = true;
        model.subtitle.lineOne = i18n.t("subscribe");

        // Find if the user is entitled to a program or vod and return episode.
        const matchingEntitlement = episode.entitlement?.hasTechPackage ? episode : null;
        if (matchingEntitlement) {
          isLiveEntitlement = isItemTypeLive(matchingEntitlement.metadata);
          model.entitlements.isGeoBlocked = isGeoBlocked;
          // If the user is entitled to a program, we need to find the relevant live content details
          if (isLiveEntitlement) {
            // Find the program item that matches the user's entitled content
            const matchingProgram = programs.find(
              (program) => program.metadata?.contentId === matchingEntitlement.metadata.contentId
            );
            if (matchingProgram?.channel) {
              // If we were able to find the program, we need to try to find the channel it is airing on
              const matchingChannel = subscribedChannels.containers.find(
                (channel) => channel.metadata.channelId === matchingProgram.channel.channelId
              );
              if (matchingChannel) {
                // If we find the channel, user is subscribed and we can determine live entitlements/statuses
                model.entitlements.isUnsubscribed = false;
                model.entitlements.isRestartable = doesContentSupportStartOver(matchingProgram, matchingChannel);
                model.entitlements.isLookbackSupported = checkIsLookbackSupported(
                  matchingProgram,
                  matchingChannel,
                  isInHome
                );
                model.entitlements.isInHomeRequired = isContentOOHBlocked(matchingProgram, matchingChannel);
                model.entitlements.isFromGhostChannel = isGhostChannel(matchingChannel);

                model.routes.playback = `${LIVE_PLAYER.route}/?stationId=${matchingChannel.metadata.channelId}&contentType=LIVE`;

                if (matchingProgram.metadata?.airingStartTime && matchingProgram.metadata.airingEndTime) {
                  if (
                    isLookbackEnabled &&
                    matchingProgram.metadata.airingEndTime < currentTimeMs &&
                    calculateLookbackHoursLeft(matchingProgram) > 0 &&
                    model.entitlements.isLookbackSupported
                  ) {
                    // Program is available as lookback
                    model.subtitle.lineOne = i18n.t("restart_live_tv");
                    model.subtitle.lineOneStyleOverride = { color: styleVariables.subscribeLink };
                    model.channelInfo = matchingProgram?.channel;
                    model.lookbackContent = getNewestLookbackProgram(
                      [matchingProgram],
                      subscribedChannels,
                      isInHome,
                      channelMapID
                    );
                  } else if (
                    matchingProgram.metadata.airingStartTime <= currentTimeMs &&
                    currentTimeMs <= matchingProgram.metadata.airingEndTime
                  ) {
                    // Program is on now
                    const progressMs = currentTimeMs - matchingProgram.metadata.airingStartTime;
                    const durationMs =
                      matchingProgram.metadata.airingEndTime - matchingProgram.metadata.airingStartTime;
                    const remainingTimeSeconds = (matchingProgram.metadata.airingEndTime - currentTimeMs) / 1000;

                    if (progressMs > 0 && durationMs > 0) {
                      progressPercentage = (progressMs / durationMs) * 100;
                      progressPercentage = Math.ceil(progressPercentage) < 100 ? progressPercentage : 0;
                    }

                    model.progressPercentage = progressPercentage;
                    model.subtitle.lineOne = i18n.t("on_now");
                    model.subtitle.lineOneStyleOverride = { color: styleVariables.subscribeLink };
                    model.subtitle.lineTwo =
                      remainingTimeSeconds >= 0 ? getRemainingTimeString(remainingTimeSeconds) : null;
                  } else {
                    // Program is upcoming
                    model.subtitle.lineOne =
                      matchingProgram.metadata.airingStartTime > currentTimeMs ? i18n.t("upcoming") : "";
                    model.subtitle.lineTwo = getFormattedDate(matchingProgram.metadata.airingStartTime);
                    model.entitlements.isRestartable = false; // Don't show restart icon for upcoming items
                    model.routes.playback = null;
                  }
                }
              }
            }
          } else {
            // User is entitled to VOD
            model.entitlements.isUnsubscribed = false;

            const bookmark = matchingEntitlement.metadata.bookmarks?.[0];
            const progressSeconds = bookmark?.startDeltaTime;
            if (progressSeconds >= 0) {
              const durationSeconds = matchingEntitlement.metadata.duration;
              if (durationSeconds > 0) {
                progressPercentage = (progressSeconds / durationSeconds) * 100;
                progressPercentage = Math.ceil(progressPercentage) < 100 ? progressPercentage : 0;
              }
            }

            model.progressPercentage = progressPercentage;

            if (progressPercentage > 98 || bookmark?.isComplete) {
              model.isCompletelyWatched = true;
            }

            model.subtitle.lineOne = getFormattedDate(
              matchingEntitlement.metadata.extendedMetadata.dlum.originalAirDate
            );
            model.subtitle.lineTwo = getItemDuration(matchingEntitlement.metadata.duration);

            model.routes.playback =
              `${ON_DEMAND_PLAYER.route}/episode/?playbackId=${matchingEntitlement.metadata.contentId}` +
              `&contentType=${PAGE_CONTENT_ITEM_TYPES.vod}`;
          }
        }
      }

      return model;
    });
  }

  return [];
};

/**
 * Returns true if the given URL is for a series detail page
 *
 * @param {String} url
 * @returns {Boolean}
 */
const isSeriesDetailPageUrl = (url) => {
  if (!url || typeof url !== "string") {
    return false;
  }
  return BUNDLE_LAYOUT_ITEM_TYPE.some((type) => url.includes(type));
};

/**
 * Converts list of title item object to list of swimlane model and returns the same
 *
 * @param {Array} items - title item objects array
 * @param {String} dimensions - width and height value of thumbnail for finding the right image for swimlane
 * @param {Boolean} isAppLanguageFrench
 * @param {Object} userProfile
 * @param {Object} currentPrograms Map where keys are timestamps and values are lists of EPG programs
 * @param {Object} avsComponent UI builder component item or equivalent
 * @param {Boolean} appendFrenchTag flag indicating if the FR tag should be appended
 * @param {Object} recordingProfile
 * @returns {Object[]} list of swimlane item models containing information needed for rendering
 */
export const convertTitleItemsListToSwimLaneModel = (
  items,
  dimensions,
  isAppLanguageFrench = false,
  userProfile,
  currentPrograms,
  avsComponent,
  appendFrenchTag = false,
  recordingProfile
) => {
  return items.map((item) => {
    return createSwimlaneItemModel(
      item,
      dimensions,
      isAppLanguageFrench,
      userProfile,
      currentPrograms,
      avsComponent,
      appendFrenchTag,
      recordingProfile
    );
  });
};

/**
 * Create and returns swimlane feed object model
 *
 * @param {String} title - Swimlane title
 * @param {Array} items - title items for a swimlane
 * @param {Array} feed - response feed object
 *
 * @returns {Object} - feed object for one swimlane.
 */
export const createSwimlaneFeedItem = (
  title,
  items,
  viewAllRoute = null,
  feed = null,
  subtitle = null,
  itemsCount = null,
  onClickTarget = ""
) => {
  let feedObj = {
    title: title,
    items: items,
    feed: feed,
    viewAllRoute: viewAllRoute,
    subtitle: subtitle,
    itemsCount: itemsCount || items.length,
    onClickTarget: onClickTarget,
  };

  if (!viewAllRoute && feed) {
    let encodedTitle = encodeURIComponent(feedObj.title);
    let viewAllUrl = null;
    if (isLiveFeed(feedObj)) {
      viewAllUrl = GUIDE.route + "?stationId=" + feedObj.items[0].item.channel.StationId;
    } else if (feed.Layout === constants.FEED_LAYOUT_TYPES.CATEGORY) {
      viewAllUrl = SUB_CATEGORIES_PAGE.route + "/" + feed.Id + "?title=" + encodedTitle;
    } else if (SWIMLANE_IDS.includes(feed.Id)) {
      viewAllUrl = null;
    } else if (feed.title?.toLowerCase() === SWIMLANE_TITLES.PVR) {
      viewAllUrl = getRouteToPVRManager();
    } else if (
      feed.actions &&
      feed.actions.length > 0 &&
      feed.actions[0].key === "onTitleClick" &&
      feed.actions[0].layout === "grid"
    ) {
      if (feed.actions[0].uri && feed.actions[0].uri.includes("?filter_airingTime=now")) {
        viewAllUrl = GUIDE.route;
      } else if (feed.actions[0].targetType && feed.actions[0].targetType === "PAGE") {
        viewAllUrl = `${SUB_CATEGORIES_PAGE.route}/${encodeURIComponent(feed.actions[0].uri)}?title=${encodedTitle}`;
      } else {
        let actionUri = feed.actions?.[0]?.uri;
        if (isFavouriteAssetsConfig(feed)) {
          actionUri = actionUri + "&sortOrder=DESC";
        }
        let titleKey = actionUri.includes("?") ? "&title=" : "?title=";
        viewAllUrl = VIEW_ALL_PAGE.route + actionUri + titleKey + encodedTitle;
        if (feed.metadata?.Filters) {
          setLocalStorage(title + FILTERS_SUFFIX, feed.metadata.Filters);
        }

        if (feed.metadata?.Sort) {
          setLocalStorage(title + SORT_OPTIONS_SUFFIX, feed.metadata.Sort);
        }
      }
    }
    feedObj.viewAllRoute = viewAllUrl;
  }
  return feedObj;
};

/**
 * Check for whether feed is live
 *
 * @param {String} URI - feed URI
 * @returns {Boolean} - true if live type
 */
const isLiveFeed = (feedObj) => {
  if (feedObj.items && feedObj.items.length > 0 && feedObj.items[0].item && feedObj.items[0].item.channel) {
    return isItemTypeLive(feedObj.items[0].item);
  } else if (feedObj && feedObj.feed && feedObj.feed.Uri) {
    return isOnNowFeedItem(feedObj.feed);
  }
  return false;
};

/**
 * Create and returns a swimlaneConfig object
 *
 * @param {Object} feedObj - swimlane feed object
 * @param {*} dimensionConfig - dimensionConfig for the swimlane
 * @param {*} sliderSettings - slider settings config
 * @param {*} ThumbnailComponent - thumbnail component to be used inside the swimlane
 *
 * @returns {Object} - One swimlane config object.
 */
export const createSwimlaneConfig = (feedObj, dimensionConfig, sliderSettings, ThumbnailComponent) => {
  return {
    feedObj: feedObj,
    dimensionConfig: dimensionConfig,
    sliderSettings: sliderSettings,
    ThumbnailComponent: ThumbnailComponent,
  };
};

/**
 * Returns formatted date like Sept 2, 2021
 * @param {Date} originalAirDate
 * @param {Boolean} airTime Whether to include the air time
 * @returns {String}
 */
export const getFormattedDate = (originalAirDate, airTime = false) => {
  return moment(originalAirDate).format(airTime ? "ll | LT" : "ll");
};

/* Get item runtime duration */
export const getItemDuration = (runTimeSec) => {
  let duration = "";
  const hours = Math.floor(runTimeSec / 3600);
  const minutes = Math.floor((runTimeSec - hours * 3600) / 60);

  if (hours !== 0) {
    duration = duration + hours + "h ";
  }
  if (minutes !== 0) {
    duration = duration + minutes + "min ";
  }
  return duration;
};

/**
 * Helper function used to match swimlanes with supported feature toggles.
 * If a swimlane cannot be matched with a feature toggle, we consider it
 * enabled.
 *
 * @param {Object} feed - UI builder swimlane feed object
 * @param {Object} [userProfile] - User profile object, not required for every feature check
 * @param {Boolean} isFavouritesEnabled
 * @param {Boolean} isCloudPVREnabled
 * @returns {Boolean} False if the swimlane is matched with a feature toggle that is disabled, true otherwise
 */
export const isSwimlaneFeatureEnabled = (feed, userProfile, isFavouritesEnabled, isCloudPVREnabled) => {
  let result = true;
  const feedUri = feed?.retrieveItems?.uri;

  if (feedUri) {
    if (feedUri.includes("FAVOURITE")) {
      result = isFavouritesEnabled;
    } else if (feedUri.includes("RECORDING")) {
      // There are no swimlanes associated with the MR system, so we only need to check the feature toggle for CPVR specifically
      result =
        getRecordingSystemType(userProfile) === RECORDING_PACKAGES.PACKAGE_NAME.CPVR_TP ? isCloudPVREnabled : false;
    }
  }

  return result;
};

export const isFavouriteChannelsConfig = (config) => {
  return config?.title === FAVOURITE_CHANNELS.TITLE && config.layout === FAVOURITE_CHANNELS.LAYOUT;
};

/**
 * Function to check if the config is for favourite assets(movies, tvshows).
 * @param {Object} config.
 * @returns Boolean.
 */
export const isFavouriteAssetsConfig = (config) => {
  return config?.title === FAVOURITE_ASSETS.TITLE && config.layout === FAVOURITE_ASSETS.LAYOUT;
};

export const isResumeSwimlaneConfig = (config) => {
  return config?.title === RESUME_ASSETS.TITLE && config.layout === RESUME_ASSETS.LAYOUT;
};

/**
 * @param {Number} remainingTime Remaining watch time of content in seconds
 * @returns Formatted remaining time string
 */
const getRemainingTimeString = (remainingTime) => {
  return remainingTime > 60 ? `${getItemDuration(remainingTime)} ${i18n.t("left")}` : i18n.t("reached_remaining_time");
};

/**
 * Helper function used to build channel data objects from EPG data from redux.
 *
 * @param {Object} epgData - TRAY/EPG data that's stored in redux
 * @param {Object} channel - channelInfo data used to populate the reminder of the data
 * @returns {Object} Return a object that is used to populate the swimlane
 */
export const createChannelItem = (epgData, channel) => {
  if (epgData?.actions && epgData.metadata && channel?.metadata && channel.regions) {
    return {
      actions: epgData.actions,
      assets: channel.assets,
      channel: {
        callLetter: channel.metadata.callLetter,
        channelId: channel.metadata.channelId,
        channelName: channel.metadata.channelName,
        regions: channel.regions,
      },
      id: epgData.id,
      layout: "CONTENT_ITEM",
      metadata: epgData.metadata,
    };
  }
  return null;
};

/**
 * Helper function used return the currently airing live show.
 *
 * @param {Array} shows - Array of shows from a live channel that's pulled from TRAY/EPG
 * @returns {Object} Returns the current show that's live
 */
export const getCurrentAiringShow = (shows) => {
  const currentTime = moment().valueOf();
  return shows.find((show) => {
    if (show) {
      return currentTime > show.metadata.airingStartTime && currentTime < show.metadata.airingEndTime;
    }
    return false;
  });
};

/**
 * Returns the season and episode number based on the specified conditions,
 * returning the title only when both episode and season autoGenerated flags are set to true
 * If includeTitle is true, the episode title is also returned
 *
 * @param {Object} itemContent
 * @param {Boolean} includeTitle
 * @returns {String}
 */
export const getEpisodeDetail = (itemContent, includeTitle = true) => {
  const title = itemContent.episodeTitle || itemContent.title || "";
  const autoGeneratedObject = getAutoGeneratedObject(itemContent);
  const isEpisodeAutoGenerated = autoGeneratedObject?.isEpisodeAutoGenerated;
  const isSeasonAutoGenerated = autoGeneratedObject?.isSeasonAutoGenerated;

  if (isEpisodeAutoGenerated && isSeasonAutoGenerated && includeTitle) {
    return title;
  }

  return getAutogeneratedEpisodeString(autoGeneratedObject, itemContent);
};
