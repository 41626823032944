import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';

    font-weight: 700;
    font-size: 32px;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';

    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Open Sans';

    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 32px;

  }

  body {
    margin: 0;
    background-color: black;
    color: white;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
  }

  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus {
    outline: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }
`;
export default GlobalStyle;
