import styled from "styled-components";

const LivePlayerSidebarDetails = styled.div(
  ({ theme, width }) => `
  background-color: ${theme.colours.focusedRecordingButtonBackground};
  min-height: 220px;
  padding-top: ${width <= 1366 ? "10px" : "30px"};
  padding-left: ${width <= 768 ? "25px" : "30px"};
  padding-bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  .program-name {
    font: ${theme.typography.regularFont};
    font-size: ${width <= 768 ? theme.typography.smallFontSwimlane : theme.typography.mediumFontSize};
    line-height: 1.35;
    text-align: left;
  }
  .program-details {
    margin: 8px 0 18px;
    margin-top: ${width <= 1366 ? "10px" : ""};
    font: ${theme.typography.primaryFont};
    font-size: ${width <= 768 ? theme.typography.mediumSwimlaneFontSize : theme.typography.largeFontSizeHd};
    line-height: 1.3;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  `
);

const LivePlayerSidebarProgramDesc = styled.div(
  ({ theme, width }) => `
  margin: 18px 0 8px;
  color: ${theme.colours.secondaryTableFontColor};
  font-size: ${width <= 768 ? theme.typography.smallFontSwimlane : theme.typography.mediumFontSize};
  padding-right: 10px;
  max-height: 120px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  `
);

const LivePlayerSidebarList = styled.div(
  () => `
  overflow: auto;
  `
);

const LivePlayerTab = styled.div(
  ({ theme }) => `
  border-bottom: 1px solid ${theme.colours.white};
  height: 60px;
  margin: 0 25px 10px;
  `
);

const LivePlayerTabButton = styled.button(
  ({ theme, $active }) =>
    `
  background-color: inherit;
  float: left;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  border: none;
  transition: 0.3s;
  font: ${theme.typography.primaryFont};
  font-size: ${theme.typography.mediumFontSize};
  color: ${theme.colours.white};
  border-bottom: 5px solid ${$active ? theme.colours.white : "transparent"};
  `
);

export {
  LivePlayerSidebarDetails,
  LivePlayerSidebarProgramDesc,
  LivePlayerSidebarList,
  LivePlayerTab,
  LivePlayerTabButton,
};
