import { getAVSAppChannel } from "../utils";
import constants from "../constants";
import { DEFAULT_CUSTOMER_TYPE } from "../constants/analytics";
import storageConstants from "../constants/storage";
import { getLocalStorage, setLocalStorage } from "../utils/localStorage";
const { PACKAGE_TYPE, PACKAGE_NAME, DEFAULT_REGION_ID } = constants;
const { USER_PROPERTY_TYPE } = storageConstants;

/**
 * Returns a provider object containing most basic parameters for the app
 * @param {Object} userProfile A user profile object
 * @param {Boolean} isAppLanguageFrench Language setting
 * @param {Boolean} inPanic Flag that determines ECE user experience in panic state
 * @param {Boolean} isPPVEnabled Feature flag from client side for PPV.
 */
function getAppProvider(
  userProfile,
  isAppLanguageFrench,
  config,
  ENV,
  inPanic = false,
  isPPVEnabled = false,
  useOAuth = true
) {
  let accountID = "";
  let channelMapID = "";
  const lang = isAppLanguageFrench ? "fr-CA" : "en-CA";
  let store = "HubsAndFeeds-Main";
  let AVS_PROPERTY = config.general.AVS_config.appPropertyName;
  let packageIds;
  let geoCityName;
  let geoProvinceCode;
  let geoStateCode;
  let paymentMethod;
  let svodPackageIds;
  let uuid;
  let option82;
  let customerType;
  let pcLevel;
  let pcLevelLabel;
  const hasGatedAccess = ENV === "avs-beta" ? "T3lus@B3ta" : process.env.REACT_APP_SECRET_PASSWORD;
  // AVS Specific Params
  const {
    appVersion,
    appHost,
    appHostOld,
    appLanguage = isAppLanguageFrench ? "FRA" : "ENG",
  } = config.general.AVS_config;

  const appChannel = getAVSAppChannel(config.general.AVS_config);

  let userCluster = "A";
  let panicMode = false;
  let userPropertyType = null;
  let userPropertyName = null;

  if (!userProfile?.isLoggedIn) {
    // 25 represents the default channel aka Calgary
    channelMapID = config?.general?.epg_channel_map_id ? config?.general?.epg_channel_map_id : DEFAULT_REGION_ID;
  } else if (userProfile?.isLoggedIn && userProfile.user?.profile?.profileData) {
    const userProfileObj = userProfile.user.profile.profileData;
    const customAttribute = userProfileObj.customAttributes?.find(
      (customAttribute) => customAttribute.attributeName === "USERACCOUNTTYPE"
    );
    customerType = customAttribute?.attributeValue ?? DEFAULT_CUSTOMER_TYPE;
    geoCityName = userProfileObj.geoCityName;
    geoProvinceCode = userProfileObj.geoProvinceCode;
    geoStateCode = userProfileObj.geoStateCode;
    paymentMethod = userProfileObj.paymentMethod;
    packageIds = userProfileObj.packageList
      ?.reduce((subscribedPackageIds, subscribedPackage) => {
        if (
          subscribedPackage.packageType.toLowerCase() === PACKAGE_TYPE.LIVE_SUB ||
          subscribedPackage.packageType.toLowerCase() === PACKAGE_TYPE.VOD_LIVE_SUB ||
          (subscribedPackage.packageName.toLowerCase() === PACKAGE_NAME.PPV && isPPVEnabled)
        ) {
          subscribedPackageIds.push(subscribedPackage.packageId);
        }
        return subscribedPackageIds;
      }, [])
      .join();
    svodPackageIds = userProfileObj.packageList?.map((subscribedPackage) => subscribedPackage.packageId).join();
    channelMapID = userProfileObj.regionId;
    accountID = userProfileObj.username;
    AVS_PROPERTY = userProfileObj.properties?.[0]?.propertyName;
    uuid = userProfileObj.uuidExternal;
    option82 = userProfileObj.option82?.[0];
    pcLevel = userProfileObj.userPcLevelVod;
    pcLevelLabel = userProfileObj.userPcLevelVodLabel;
    userCluster = "R";
    userPropertyType = userProfileObj.userPropertyType ?? getLocalStorage(USER_PROPERTY_TYPE);
    userPropertyName = userProfileObj?.properties?.[0]?.propertyName;
    if (userPropertyType) {
      // Store userPropertyType for profile switching because it is only returned for the master profile
      setLocalStorage(USER_PROPERTY_TYPE, userPropertyType);
    }
  }
  panicMode = inPanic;

  // General Params
  const isOptik = AVS_PROPERTY === "OPTIK" ? true : false;

  return {
    channelMapID,
    lang,
    store,
    config,
    isOptik,
    userPropertyType,
    userPropertyName,
    accountID,
    hasGatedAccess,
    AGL_Version: appVersion,
    AGL_TENANT_URL: appHost,
    AVS_PLATFORM: appChannel,
    AVS_LANG: appLanguage,
    AVS_PROPERTY,
    userCluster,
    packageIds,
    geoCityName,
    geoStateCode,
    geoProvinceCode,
    paymentMethod,
    svodPackageIds,
    uuid,
    option82,
    customerType,
    pcLevel,
    pcLevelLabel,
    panicMode,
    ENV,
    useOAuth,
  };
}

export default getAppProvider;
