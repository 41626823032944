import React from "react";
import { useTranslation } from "react-i18next";

import BackButton from "../../components/ImageButton";

import { convertToPercentageString, isEllipsisActive, getAutogeneratedEpisodeString } from "../../shared/utils";
import { getAVSKeyArtImage, handleImageError } from "../../shared/utils/image";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { getEpisodeInfoLine1 } from "../../shared/utils/feedHelper";

import useAppLanguage from "../../shared/hooks/useAppLanguage";

import constants from "../../shared/constants";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";

const { IMAGES, UA_STANDALONE } = constants;

const VIEW_ALL_ICON = process.env.PUBLIC_URL + "/images/white-chevron.svg";
const icon = process.env.PUBLIC_URL + "/images/swimlane-landscape-324px.jpg";

const EpisodeWallContent = ({
  playbackId,
  progressTime,
  contentMetadata,
  isRecordingNow,
  uaEpisodeObject,
  hasCC,
  hasDV,
  seriesInfo,
  children,
}) => {
  const { t: translate } = useTranslation();

  const { isAppLanguageFrench } = useAppLanguage();

  const isStandAloneEpisode = contentMetadata?.metadata?.extendedMetadata?.dlum?.layoutHint === UA_STANDALONE;

  const getProgressPercentage = () => {
    if (progressTime > 0 && contentMetadata?.metadata?.duration) {
      return (progressTime / contentMetadata.metadata.duration) * 100;
    }
  };

  // Re-direct to series page
  const linkClickHandler = () => {
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.SERIES_LINK};${LINK_INFO.SUB_NAV}`);
    window.location.href = seriesInfo?.url;
  };

  const toggleText = (e) => {
    let descElement = document.getElementById("desc");
    if (descElement.style.display === "") {
      descElement.style.display = "inline-block";
      e.target.innerText = translate("show_less");
    } else {
      descElement.style.display = "";
      e.target.innerText = translate("details_read_more");
    }
  };

  return (
    <>
      <div className="episode-content">
        <div className="episode-poster">
          <img
            src={`${getAVSKeyArtImage(contentMetadata?.metadata, IMAGES.ASPECT_RATIOS.DIM_9x16)}?w=485`}
            alt=""
            onError={(e) => handleImageError(e, icon)}
          ></img>
          {progressTime && playbackId ? (
            <div className="ep-progress-bar-container">
              <div
                className="ep-progress-bar"
                style={{
                  width: convertToPercentageString(getProgressPercentage()),
                }}
              ></div>
            </div>
          ) : null}
        </div>

        <div className="episode-desc">
          <div className="episode-header">
            {isStandAloneEpisode ? null : (
              <div
                className={seriesInfo?.url ? "episode-back-btn btn-cursor" : "episode-back-btn"}
                onClick={seriesInfo?.url ? linkClickHandler : () => {}}
              >
                <span>{seriesInfo?.name}</span>
                {seriesInfo?.url ? <BackButton src={VIEW_ALL_ICON} alt="viewAll" /> : null}
              </div>
            )}
            <h1 className={isStandAloneEpisode ? "no-series-header" : null}>
              {getAutogeneratedEpisodeString(uaEpisodeObject, contentMetadata?.metadata)}
            </h1>
          </div>

          <h2>{getEpisodeInfoLine1(contentMetadata?.metadata, isAppLanguageFrench)} </h2>
          <h2 className="eps-badge-wrap">
            {isRecordingNow ? (
              <div className="recording-badge">
                <img
                  src={
                    isAppLanguageFrench
                      ? process.env.PUBLIC_URL + "/images/Recording_Now_FR.svg"
                      : process.env.PUBLIC_URL + "/images/Recording_Now_EN.svg"
                  }
                  alt=""
                />
              </div>
            ) : null}
            {hasCC ? (
              <span className="cc">
                <img src={process.env.PUBLIC_URL + "/images/CC.svg"} alt="cc-indicator" />
              </span>
            ) : null}
            {hasDV ? (
              <span className="dv">
                <img src={process.env.PUBLIC_URL + "/images/DV.svg"} alt="dv-indicator" />
              </span>
            ) : null}
          </h2>
          <p id="desc">{contentMetadata?.metadata?.longDescription}</p>
          {contentMetadata?.metadata?.longDescription && isEllipsisActive("desc") && (
            <h3 className="show-more" onClick={(e) => toggleText(e)} id="textButton">
              {translate("details_read_more")}
            </h3>
          )}
        </div>
      </div>
      {children != null && children}
    </>
  );
};

export default EpisodeWallContent;
