import moment from "moment";
import epgConstants from "../constants/epg";

const { TIMESLOT_WIDTH, TIMESLOT_MINUTES, PROGRAM_ROW_HEIGHT, CHANNEL_TYPES } = epgConstants;

export const getCurrentDate = () => {
  return getDate();
};

export const getDate = (timeStamp = null) => {
  const date = timeStamp ? moment(timeStamp) : moment();
  return date.format("[date_format]");
};

export const getHour = (nowString, timeStamp = null, returnTS = false) => {
  const date = timeStamp ? moment(timeStamp) : moment();
  const nextTime = moment(timeStamp).add(1, "hours");
  return isTimeNow(date, nextTime, nowString, returnTS);
};

export const isTimeNow = (date, nextTimeSlot, nowString, returnTS = false) => {
  return date <= moment().valueOf() && moment().valueOf() <= nextTimeSlot && returnTS === false
    ? nowString
    : date.format("[hour_format]");
};

/**
 * Check channel or programs is playable on device by checking entitlement array of any of the program.
 *
 * @param {Object} channel
 * @param {Array} programs
 */
export const isPlaybackNotSupportedOutOfHome = (channel, programs) => {
  return channel?.metadata?.isNotAvailableOutOfHome || programs?.[0]?.metadata?.isNotAvailableOutOfHome;
};

/**
 * Helper function to determine if a channel is considered a ghost channel.
 * 4K channels are currently considered ghost channels.
 *
 * @param {Object} channel - See createEpgChannelObject() for channel object structure
 * @returns {Boolean} true if the channel is considered a ghost channel
 */
export const isGhostChannel = (channel) => {
  return channel?.metadata?.extendedMetadata?.isGhost?.toLowerCase() === "y" || is4KChannel(channel);
};

/**
 * function to check if a channel support restartable
 * @param {Object} channelObj - channel object
 * @returns {Boolean} true if the channel is restartable
 */
export const isChannelRestartable = (channelObj, isInHome) => {
  const isChannelOOHRestricted = channelObj?.metadata?.isNotAvailableOutOfHome && !isInHome;
  return !isGhostChannel(channelObj) &&
    !isChannelOOHRestricted &&
    (channelObj?.metadata?.isStartOver || channelObj?.metadata?.isCatchUp)
    ? true
    : false;
};

/**
 * Function checks if channel contains 4k streams
 *
 * @param {Object} channelItem - channel object returned from the backend
 * @returns {Boolean} true if the channel has a 4K video asset
 */
export const is4KChannel = (channelItem) => {
  if (channelItem?.assets) {
    for (let i = 0; i < channelItem.assets.length; i++) {
      if (channelItem.assets[i].videoType?.toLowerCase() === "4k") {
        return true;
      }
    }
  }

  return false;
};

/**
 * Determine the regional channel number by searching through channel region objects and attempting
 * to match one with the provided regionId. If no regionId is provided OR no match is found, we try
 * to find a region object that is flagged as the default. If no default is found, we try to return
 * the first region item's regional channel number.
 *
 * @param {Object} channel - One channel object
 * @param {Number} regionId - user's region id
 * @return {Number} returns the regional channel number or undefined if none can be found
 */

export const getRegionalChannelNumber = (channel, regionId) => {
  let regionalChannelNumber;
  if (channel?.regions?.length > 0) {
    let regionalChannelNumberObj;
    if (regionId) {
      regionalChannelNumberObj = channel.regions.find((item) => item.regionId === regionId);
    }

    if (!regionalChannelNumberObj) {
      regionalChannelNumberObj = channel.regions.find((item) => item.isDefault === true);
    }

    return regionalChannelNumberObj?.regionalChannelNumber || channel.regions[0].regionalChannelNumber;
  }

  return regionalChannelNumber;
};

/**
 * Check channel is in the user subscribed channel list
 *
 * @param {Object} channel - channel object
 * @param {Object} subscribedChannels - list of user subscribed channels
 */
export function isUserSubscribedChannel(channel, subscribedChannels) {
  if (subscribedChannels && subscribedChannels.containers && subscribedChannels.containers.length) {
    return subscribedChannels.containers.some((subscribedChannel) => subscribedChannel.id === channel.id);
  }
  return false; // changing default return value to false to avoid marking all channels as subscribed
}

/**
 * Checks channel in the user's favourite channels list.
 *
 * @param {Object} channel - channel object.
 * @param {Object} favouriteChannels - list of favourited channels.
 * @returns true/false
 */
export function isUserFavouritedChannel(channel, favouriteChannels) {
  if (favouriteChannels?.length) {
    return favouriteChannels.some((favouriteChannel) => {
      return favouriteChannel.id === channel.id;
    });
  }
  return false; // changing default return value to false to avoid marking all channels as favourited
}

/**
 * Comparison function for sorting channels by channel number
 * @param {Object} firstChannel
 * @param {Object} secondChannel
 * @returns {Number}
 */
export const channelCompareFunction = (firstChannel, secondChannel) => {
  return firstChannel?.number - secondChannel?.number;
};

/**
 * Returns a standardized EPG channel object for use across components
 * The returned object has the following structure:
 * {
 *   "number": Number,
 *   "assets": Array,
 *   "id": String,
 *   "metadata": Object
 * }
 *
 * @param {Object} channel
 * @param {Number} regionId
 * @returns {Object}
 */
export const createEpgChannelObject = (channel, regionId) => {
  const { actions, layout, properties, regions, ...rest } = channel;
  const channelObject = {
    number: regions?.[0]?.regionalChannelNumber ?? getRegionalChannelNumber(channel, regionId),
    ...rest,
  };
  return channelObject;
};

/**
 * Fetch the program that is airing after a specified program.
 *
 * @param {Object} currentPrograms Object map where key is the timestamp and value is a cached array of EPG data
 * @param {Number} channelId ID of desired channel to get program from
 * @param {Number} programId ID of desired program
 * @returns program object or null
 */
export const getUpNextProgram = (currentPrograms, channelId, programId) => {
  for (const segmentTimestamp in currentPrograms) {
    const currentChannelPrograms = currentPrograms[segmentTimestamp]?.[channelId];
    if (currentChannelPrograms) {
      const programIndex = currentChannelPrograms.findIndex((program) => program.id === String(programId));
      if (programIndex > -1 && currentChannelPrograms[programIndex + 1]) {
        return currentChannelPrograms[programIndex + 1];
      }
    }
  }

  return null;
};

/**
 * Determines if start-over functionality is available for a specific program.
 * This function considers the content's isStartOver flags, at the program and channel level as appropriate,
 * the user's in-home status, and if content is airing on a ghost channel.
 *
 * Refer to doesContentSupportStartOver() for start-over flag logic
 * @param {Object} programMetadata
 * @param {Object} channel
 * @param {boolean} isInHome
 * @returns {boolean} if start over is supported for program and channel are not OOH restricted and channel is not ghost
 */
export const checkIsStartOverSupported = (programMetadata, channel, isInHome) => {
  const isProgramOOHRestricted = programMetadata?.isNotAvailableOutOfHome && !isInHome;
  const isChannelOOHRestricted = channel?.metadata?.isNotAvailableOutOfHome && !isInHome;
  // NOTE: to modify logic for isGhostChannel utility, if in future 4K channels are playable
  if (isProgramOOHRestricted || isChannelOOHRestricted || isGhostChannel(channel)) {
    return false;
  } else {
    return doesContentSupportStartOver({ metadata: programMetadata }, channel);
  }
};

/**
 * Determines if start-over functionality is available for a specific program.
 * This function only checks the content's isStartOver flags, at the program and channel level as appropriate,
 * and does not consider any other entitlements or statuses (such as in-home or ghost).
 *
 * Logic is as per {@link https://telusvideoservices.atlassian.net/wiki/spaces/AD/pages/1708065142/Client+-+StartOver#Client-Logic}
 * @param {Object} program
 * @param {Object} channel the channel that the provided program is airing on
 * @returns {Boolean} true if the program or channel supports restarting
 */
export const doesContentSupportStartOver = (program, channel) => {
  if (program?.metadata && channel?.metadata) {
    if (program.metadata.isStartOver !== undefined && program.metadata.isStartOver !== null) {
      return program.metadata.isStartOver;
    } else if (channel.metadata.isStartOver !== undefined && channel.metadata.isStartOver !== null) {
      return channel.metadata.isStartOver;
    }
  }

  return true;
};

/**
 * Determines if a specific program requires a home network for playback by checking both the program and channel
 * @param {Object} program
 * @param {Object} channel the channel that the provided program is airing on
 * @returns {Boolean} true if the program or channel requires a home network
 */
export const isContentOOHBlocked = (program, channel) => {
  if (program?.metadata && channel?.metadata) {
    return program.metadata.isNotAvailableOutOfHome || channel.metadata.isNotAvailableOutOfHome || false;
  }

  return false;
};

/*
 * Determines if look-back functionality is available for a specific program.
 * This function considers the content's isCatchUp flags, at the program and channel level as appropriate,
 * the user's in-home status, and if content is airing on a ghost channel.
 *
 * @param {Object} programMetadata Program metadata object
 * @param {Object} channel Channel object
 * @param {boolean} isInHome
 * @returns {boolean} True if look-back is supported for the program
 */
export const checkIsLookbackSupported = (programMetadata, channel, isInHome) => {
  if (programMetadata && channel?.metadata) {
    const isProgramOOHRestricted = programMetadata.isNotAvailableOutOfHome && !isInHome;
    const isChannelOOHRestricted = channel.metadata.isNotAvailableOutOfHome && !isInHome;

    if (isProgramOOHRestricted || isChannelOOHRestricted || isGhostChannel(channel)) {
      return false;
    } else {
      const programIsLookBackFlag = programMetadata.isCatchUp;
      const channelIsLookBackFlag = channel.metadata.isCatchUp;
      if (programIsLookBackFlag === false) {
        return false;
      } else if (!programIsLookBackFlag) {
        // either null, undefined, or an empty string
        if (channelIsLookBackFlag === false) return false;
      }

      return true;
    }
  }
  return false;
};

/**
 * Returns the timestamp of the timeslot to which the given timestamp belongs.
 * Caveat: this logic assumes 30-minute timeslots.
 * @param {Number} timestamp
 * @returns {Number}
 */
export const getTimeslotTimestamp = (timestamp = null) => {
  const date = timestamp ? moment(timestamp) : moment();

  date.set({
    minute: date.minute() >= 30 ? 30 : 0,
    second: 0,
    millisecond: 0,
  });

  return date.valueOf();
};

/**
 * Convert program duration to program cell width in pixels
 * @param {Number} duration
 * @returns {Number}
 */
export const convertDurationToWidth = (duration) => {
  if (duration <= 0) {
    return 0;
  }
  return (duration * TIMESLOT_WIDTH) / (TIMESLOT_MINUTES * 60 * 1000);
};

/**
 * Convert horizontal offset to timestamp
 * @param {Number} offset Horizontal offset in pixels
 * @param {Number} epgRangeStart Starting timestamp of the EPG range
 * @returns {Number}
 */
export const convertOffsetToTimestamp = (offset, epgRangeStart) => {
  return moment(epgRangeStart)
    .add((offset * TIMESLOT_MINUTES) / TIMESLOT_WIDTH, "minute")
    .valueOf();
};

/**
 * Returns vertical offset of the channel row
 * @param {Array} channels
 * @param {Number} regionalChannelNumber
 * @returns {Number|Boolean} Returns false if the channel is not found
 */
export const getChannelOffset = (channels, regionalChannelNumber) => {
  const rowIndex = channels?.findIndex((channel) => channel?.number === regionalChannelNumber);
  if (rowIndex >= 0) {
    return rowIndex * PROGRAM_ROW_HEIGHT;
  } else {
    return false;
  }
};

/**
 * Returns closest available channel to the selected regional channel number
 * @param {Object} channels
 * @param {Number} selectedRegionalChannelNumber
 * @return {Object} channel object
 */
export const getClosestChannelByRegionalChannelNumber = (channels, selectedRegionalChannelNumber) => {
  return channels.reduce((previousChannel, currentChannel) => {
    return Math.abs(currentChannel.number - selectedRegionalChannelNumber) <
      Math.abs(previousChannel.number - selectedRegionalChannelNumber)
      ? currentChannel
      : previousChannel;
  });
};

/**
 * Checks if a channel is a PPV channel
 * @param {Object} channel
 * @returns {Boolean} True if channel's channelType matches expected PPV channel type
 */
export const isPPVChannel = (channel) => {
  if (channel?.metadata) {
    return channel.metadata.channelType === CHANNEL_TYPES.PPV;
  }

  return false;
};
