import request from "../../service/request";
import constants from "../constants";
import storageConstants from "../constants/storage";
import playerConstants from "../constants/player";
import { removeLocalStorage } from "../utils/localStorage";
import { isPanicMode } from "../utils";
import { resetScrollPosition } from "../utils";
import { getLocalStorage } from "../utils/localStorage";

const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;
const {
  ACTIVE_PROFILE_USER_ID,
  USER_PROPERTY_TYPE,
  AVS_REFRESH_TOKEN,
  SELECTED_CHANNEL,
  SELECTED_TIME,
  SORT_OPTIONS_SUFFIX,
  CACHED_ACCOUNT_BRAND,
  USER_PROFILE,
  AUTH_USER_CONTENT,
  USER_HAS_LOGGED_IN,
} = storageConstants;
const { PLAYER_CONTROLS } = playerConstants;
const { SETTINGS_CONFIG, LOGIN_BRANDS } = constants;

export const logout = (appProvider) => {
  let url = appProvider.config.avs_api_urls.logout;
  let headers = { "Content-Type": "application/json" };

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const avsRefreshToken = getLocalStorage(AVS_REFRESH_TOKEN);
  if (avsRefreshToken !== null) {
    headers[AVS_REFRESH_TOKEN] = avsRefreshToken;
  }

  return request({
    url: url,
    method: "DELETE",
    headers: headers,
    data: {
      logoutDevice: {},
    },
    withCredentials: true,
  }).then((response) => {
    if (!isPanicMode(response)) {
      // Panic mode related error being thrown
      removeLocalStorage(AVS_REFRESH_TOKEN);
      removeLocalStorage(ACTIVE_PROFILE_USER_ID);
      removeLocalStorage(USER_PROPERTY_TYPE);
      removeLocalStorage(SELECTED_CHANNEL);
      removeLocalStorage(SELECTED_TIME);
      removeLocalStorage(PLAYER_CONTROLS.MUTE);
      removeLocalStorage(PLAYER_CONTROLS.VOLUME);
      removeLocalStorage(SETTINGS_CONFIG.CLOSED_CAPTION);
      removeLocalStorage(SETTINGS_CONFIG.DESCRIBED_VIDEO);
      removeLocalStorage(SORT_OPTIONS_SUFFIX);
      resetScrollPosition();
      // We should only remove cache profile data since we're actually logging the user out
      removeLocalStorage(USER_PROFILE);
      removeLocalStorage(AUTH_USER_CONTENT);
      removeLocalStorage(USER_HAS_LOGGED_IN);

      // Log out from TELUS Identity
      const redirectUri =
        ENV === APP_ENVIRONMENTS.DEV ? "http://localhost" : appProvider.config.general.AVS_config.redirectUri;

      const accountBrand = getLocalStorage(CACHED_ACCOUNT_BRAND) ?? LOGIN_BRANDS.TELUS;
      const logoutUrl =
        accountBrand === LOGIN_BRANDS.KOODO
          ? appProvider.config.general.koodo_logout_url
          : appProvider.config.general.telus_logout_url;

      const url = logoutUrl.replace("$redirectURI", encodeURIComponent(redirectUri));
      removeLocalStorage(CACHED_ACCOUNT_BRAND); // Remove cached account brand on user logout
      window.location.href = url;
    }
  });
};
