import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import useWindowSize from "../../shared/hooks/useWindowSize";
import { handleImageError } from "../../shared/utils/image";
import wallPlaceholderLarge from "./assets/wall_placeholder.jpg";
import wallPlaceholderMedium from "./assets/wall_placeholder-1366px.jpg";
import wallPlaceholderSmall from "./assets/wall_placeholder-768px.jpg";
/**
 * Display information about a specific
 * title( Live, Movie, Series or Episode )
 * along with a catchy artwork to the user
 * @component
 * @param {Object} props
 */

function Wall({ cover }) {
  const [windowWidth] = useWindowSize();

  let placeholder = wallPlaceholderLarge;
  if (windowWidth <= 1366 && windowWidth > 768) {
    placeholder = wallPlaceholderMedium;
  } else if (windowWidth <= 768) {
    placeholder = wallPlaceholderSmall;
  }

  return (
    <div className="wall">
      <img
        src={cover ? `${cover}?w=${windowWidth}` : placeholder}
        alt=""
        onError={(e) => handleImageError(e, placeholder)}
      />
    </div>
  );
}

Wall.propTypes = {
  cover: PropTypes.string,
};

Wall.defaultProps = {};

export default Wall;
