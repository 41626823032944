import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";

import "./style.scss";

const Breadcrumb = ({ breadCrumbSegments }) => {
  const history = useHistory();

  return (
    <div className="breadcrumb">
      {breadCrumbSegments.map((segment, index) => (
        <span key={index} className="breadcrumb-segment">
          {index < breadCrumbSegments.length - 1 ? (
            <Link to="#" onClick={() => history.go(index - breadCrumbSegments.length + 1)} className="breadcrumb-link">
              {segment}
            </Link>
          ) : (
            segment
          )}
          {index < breadCrumbSegments.length - 1 && <span className="breadcrumb-separator">/</span>}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;

Breadcrumb.propTypes = {
  breadCrumbSegments: PropTypes.arrayOf(PropTypes.string).isRequired,
};
