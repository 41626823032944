// TODO: Remove this file after removing its usage from SubCategoryPage
import componentRegistry from "./avsComponentRegistry";
import { isSwimlaneFeatureEnabled } from "../utils/swimlane";

/**
 * Used to determine if an AVS-configured component can and should be created
 *
 * @param {Object} appProvider
 * @param {Object} componentConfig - AVS component object
 * @param {Object} userProfile
 * @param {Boolean} isFavouritesEnabled
 * @param {Boolean} isCloudPVREnabled
 * @return {Boolean} True if the component can and should be created
 */
const canCreateComponent = (appProvider, componentConfig, userProfile, isFavouritesEnabled, isCloudPVREnabled) => {
  return (
    componentConfig &&
    componentConfig.layout &&
    componentConfig.metadata &&
    isSwimlaneFeatureEnabled(componentConfig, userProfile, isFavouritesEnabled, isCloudPVREnabled) &&
    (appProvider.userCluster === "R" || (appProvider.userCluster === "A" && !componentConfig.metadata.requiresAuth))
  );
};

/**
 * Creates UI components as per defined within AVS Layouts
 * @param object AVS Component object
 * @return object React UI component
 */
function createComponent(
  avsComponent,
  appProvider,
  subscribedChannelIds,
  userProfile,
  updateAvailableSwimlanes,
  trackPageViewWithProductImpressions,
  favChannelIds,
  isFavouritesEnabled,
  isCloudPVREnabled,
  breadCrumbTitle
) {
  if (
    canCreateComponent(appProvider, avsComponent, userProfile, isFavouritesEnabled, isCloudPVREnabled) &&
    componentRegistry[avsComponent.layout]
  )
    return componentRegistry[avsComponent.layout](
      appProvider,
      avsComponent,
      subscribedChannelIds,
      userProfile,
      updateAvailableSwimlanes,
      trackPageViewWithProductImpressions,
      favChannelIds,
      breadCrumbTitle
    );
  else return null;
}

export default createComponent;
