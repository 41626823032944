import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/promise/all-settled";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import "./i18n";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <App />
  </ErrorBoundary>
);
