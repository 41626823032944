import styled from "styled-components";

const MovieSidebarPanelDetails = styled.div(
  ({ width }) => `
  padding-top: 15px;
  padding-left: 33px;
  width: 100%;
  box-sizing: border-box;
  margin-top: ${width <= 1366 ? "10px" : ""};
  `
);

const MovieSidebarName = styled.div(
  ({ theme }) => `
  line-height: inherit;
  padding-right: 10px;
  font: ${theme.typography.primaryFont};
  `
);

const MovieSidebarDetail = styled.div(
  ({ theme, width }) => `
    display: flex;
    margin-top: ${width <= 1366 ? "10px" : "17px"};
    font-size: ${theme.typography.mediumFontSize};
  `
);

const MovieSidebarDescription = styled.div(
  ({ theme, width }) => `
  margin: 18px 0 8px;
  color: ${theme.colours.secondaryTableFontColor};
  font-size: ${width <= 1366 ? theme.typography.rentButtonFontSize : theme.typography.mediumFontSize};
  padding-right: 10px;
  `
);

export { MovieSidebarPanelDetails, MovieSidebarName, MovieSidebarDetail, MovieSidebarDescription };
