import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./FilterDropdownButton.styles.js";
import {
  ButtonContainer,
  DropdownButtonContainer,
  DropdownIconContainer,
  FilterCount,
  StyledDropdownIcon,
  ArrowIconStyle,
  FilterIcon,
} from "./FilterDropdownButton.styles";
import { useTheme } from "styled-components";

const ArrowIcon = `${process.env.PUBLIC_URL}/images/ico_arrow_up_small.svg`;

/**
 * Create Dropdown button
 * @component
 * @param {Object} props
 */
const FilterDropdownButton = React.forwardRef(
  (
    {
      className,
      label,
      filterCount,
      isFilterApplied,
      onClickHandler,
      filterImage,
      showDropdownList,
      isGuidePage,
      screenWidth,
    },
    ref
  ) => {
    const classStyleName = `dropdown-button filterDropdownButton ${className}`;
    const theme = useTheme();

    return (
      <ButtonContainer
        theme={theme}
        width={screenWidth}
        className={classNames(
          "button-container",
          { "guide-filter-button": isGuidePage },
          { filter: filterImage },
          { "hover-state": filterImage && showDropdownList },
          { "gray-background": isGuidePage && showDropdownList }
        )}
        onClick={onClickHandler}
        ref={ref}
      >
        <DropdownButtonContainer
          data={"guide-filter-icon"}
          className={classStyleName}
          theme={theme}
          width={screenWidth}
        >
          {!isGuidePage && filterImage && <FilterIcon src={filterImage} alt="" className="filter-icon" />}
          {label}
          {filterCount && (
            <FilterCount data={"filter-count"} theme={theme}>
              {filterCount}
            </FilterCount>
          )}
        </DropdownButtonContainer>
        {!filterImage && (
          <DropdownIconContainer data={"dropdown-icon-container"}>
            <StyledDropdownIcon $isfilterapplied={isFilterApplied} className="dropdown-icon" />
          </DropdownIconContainer>
        )}
        {isGuidePage && (
          <ArrowIconStyle
            className={showDropdownList ? "up-arrow-icon" : "down-arrow-icon"}
            alt="arrowIcon"
            src={ArrowIcon}
          />
        )}
      </ButtonContainer>
    );
  }
);

FilterDropdownButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func,
  filterCount: PropTypes.number,
  isFilterApplied: PropTypes.bool,
};

FilterDropdownButton.defaultProps = {
  className: "",
  onClickHandler: (e) => e,
  filterCount: null,
  isFilterApplied: false,
};

export default FilterDropdownButton;
