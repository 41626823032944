import middleware from "../../../shared/middleware";

const { getSimilarItems } = middleware;
// Action types
export const LOAD_ALL_SIMILAR_ITEMS = "LOAD_ALL_SIMILAR_ITEMS";

// Action methods
function loadSimilarItemsSuccess(content) {
  return { type: LOAD_ALL_SIMILAR_ITEMS, content: content.containers, total: content.total };
}
function loadSimilarItemsFailure() {
  return { type: LOAD_ALL_SIMILAR_ITEMS, content: [] };
}
export const loadSimilarItems = (
  appProvider,
  contentType,
  genres,
  itemSubType,
  userProfile = null,
  pcLevel,
  cancelTokenSource = null
) => {
  return async (dispatch) => {
    try {
      const feedContent = await getSimilarItems(
        appProvider,
        contentType,
        genres,
        itemSubType,
        userProfile,
        pcLevel,
        cancelTokenSource
      );
      if (feedContent) {
        dispatch(loadSimilarItemsSuccess(feedContent));
      }
    } catch (error) {
      dispatch(loadSimilarItemsFailure());
    }
  };
};
