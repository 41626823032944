import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./style.scss";

const tickIcon = process.env.PUBLIC_URL + "/images/tick-icon.svg";
/**
 * Create Dropdown List
 * @component
 * @param {Object} props
 */
function DropdownList({ className, list, onClickHandler, selectedItem }) {
  const { t: translate } = useTranslation();
  const classStyleName = `dropdownList ${className}`;

  return (
    <div className={classStyleName}>
      {list.label && <div className="list-label">{list.label}</div>}
      <div className="list-items">
        {list.items &&
          list.items.map((item, index) => {
            let classValue = item.isDisabled ? "disabled" : "";
            classValue += item?.label === selectedItem || item.value === selectedItem ? " selected-item" : "";

            return (
              <div key={index} className={classValue} onClick={() => onClickHandler(item)}>
                {item?.label === selectedItem || item.value === selectedItem ? (
                  <img src={tickIcon} alt="selected" />
                ) : undefined}
                {list.isLocalized ? translate(item.label ?? item.value) : item.label ?? item.value}
                {item.desc ? <span className="alt-text">{item.desc}</span> : <></>}
              </div>
            );
          })}
      </div>
    </div>
  );
}

DropdownList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.object.isRequired,
  onClickHandler: PropTypes.func,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]).isRequired,
};

DropdownList.defaultProps = { className: "", onClickHandler: (e) => e };

export default DropdownList;
