import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";

import {
  loadSVODItems,
  LOAD_SVOD_PKG,
  loadSVODContainerItems,
  LOAD_SVOD_PAGE_CONTAINERS,
  LOAD_SVOD_PAGE_DETAILS,
} from "./state/actions";
import swimlaneConstants from "../../shared/constants/swimlane";
import constants from "../../shared/constants/index";
import { resetAction } from "../../App/state/actions";
import PropTypes from "prop-types";
import ViewAll from "../../components/ViewAll";
import { convertTitleItemsListToSwimLaneModel } from "../../shared/utils/swimlane";
import CategoryFilter from "../../components/CategoryFilter";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { getPcLevelRestriction } from "../../shared/utils";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import "./style.scss";

const { CONTAINER_TYPES, CONTAINER_LAYOUTS, CONTAINER_ACTION_TYPE } = constants;

const { ITEM_TYPES } = swimlaneConstants;
const dimension = ITEM_TYPES.TITLE_ITEM.PORTRAIT.DIMENSIONS.values;

/**
 * VIEW ALL page component
 * @param {Object} props - component properties
 */

function SvodViewAllPage(props) {
  const {
    appProvider,
    loadSVODItems,
    content,
    resetAction,
    loadSVODContainerItems,
    containers,
    pageDetails,
    userProfile,
    totalContent,
    featureToggles,
  } = props;

  const { isUserProfilesEnabled, isParentalPINEnabled } = featureToggles;
  const match = useRouteMatch();
  const location = useLocation();
  const [isFilterLoaded, setFilterLoaded] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const query = new URLSearchParams(location.search);
  const [lastSelectedFilterIndex, setLastSelectedFilterIndex] = useState(0);
  const contentProvider = query.get("filter_contentProvider");
  const contentSubType = query.get("filter_contentSubtype");
  const imgSrc = query.get("image");
  const [channelLogo, setChannelLogo] = useState("");
  const [filters, setFilters] = useState(null);
  const [getSVODItemsUri, setSVODItemsUri] = useState(null);
  const { isAppLanguageFrench } = useAppLanguage();
  const [filterCategoryName, setFilterCategoryName] = useState(null);
  const [isChangingFilter, setIsChangingFilter] = useState(false);

  const selectedFilter = useRef(null);
  const defaultSVODPkgUri = useRef(null);
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const { trackPageView } = useTrackPageView();

  const {
    params: { uriType, uriSubType, itemType, contentId },
  } = match;

  const detailUri =
    uriType && uriSubType && itemType && contentId ? `/${uriType}/${uriSubType}/${itemType}/${contentId}` : null;
  const uriRegionId = appProvider?.channelMapID ? `&filter_regionId=${appProvider.channelMapID}` : null;
  const pcLevel = getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled);

  useEffect(() => {
    if (content?.length === 0) setIsChangingFilter(false);
    if (content?.length > 0) {
      setItemsList((itemsList) => [
        ...itemsList,
        ...convertTitleItemsListToSwimLaneModel(content, dimension, isAppLanguageFrench, userProfile),
      ]);
    }
    setIsLoading((isLoading) => {
      if (isLoading) return false;
    });
  }, [content, isAppLanguageFrench, userProfile]);

  useEffect(() => {
    if (detailUri) {
      loadSVODContainerItems(appProvider, detailUri, CONTAINER_TYPES.CONTAINERS);
    } else {
      loadSVODItems(appProvider, contentProvider, contentSubType, userProfile, pcLevel, cancelTokenSource);
      setChannelLogo(imgSrc);
    }

    return () => {
      resetAction(LOAD_SVOD_PKG, "content");
      resetAction(LOAD_SVOD_PAGE_CONTAINERS, "content");
      resetAction(LOAD_SVOD_PAGE_DETAILS, "content");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  const itemObjToLowercase = (item) => {
    return Object.keys(item).reduce((a, b) => {
      a[b.toLowerCase()] = item[b];
      return a;
    }, {});
  };

  useEffect(() => {
    if (pageDetails) {
      setChannelLogo(pageDetails[0]?.metadata?.extendedMetadata?.source?.image43);
      if (pageDetails[0]?.metadata?.extendedMetadata?.categoryFilter) {
        let contentList = pageDetails[0]?.metadata?.extendedMetadata?.categoryFilter;
        let contentDataList = [];
        let filterDataList = [];
        for (var j in contentList.values) filterDataList.push(itemObjToLowercase(contentList.values[j]));
        for (var i in filterDataList)
          contentDataList.push({
            Name: isAppLanguageFrench
              ? filterDataList[i]?.label_fra ?? filterDataList[i].label
              : filterDataList[i].label,
            uri: filterDataList[i].value,
          });
        setFilters(contentDataList);
        contentList = itemObjToLowercase(contentList);
        setFilterCategoryName(isAppLanguageFrench ? contentList?.label_fra ?? contentList?.label : contentList?.label);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDetails]);

  useEffect(() => {
    if (containers) {
      const sVODDetailsContainer = containers.find(
        (container) => container?.layout?.toLowerCase() === CONTAINER_LAYOUTS.CONTENT_DETAIL
      );
      if (sVODDetailsContainer) {
        let sVODDetailsUri = sVODDetailsContainer.retrieveItems?.uri;
        loadSVODContainerItems(appProvider, sVODDetailsUri, CONTAINER_LAYOUTS.CONTENT_DETAIL);
      }
      const sVODItemsContainer = containers.find(
        (container) => container?.layout?.toLowerCase() === CONTAINER_LAYOUTS.GRID
      );
      if (sVODItemsContainer) {
        let sVODItemsUri = sVODItemsContainer.retrieveItems?.uri;
        if (appProvider && appProvider.channelMapID) {
          sVODItemsUri = sVODItemsUri + uriRegionId;
        }
        setSVODItemsUri(sVODItemsUri);
        defaultSVODPkgUri.current = sVODItemsUri;
        const currentHash = window.location.hash;
        if (!currentHash.includes("filter") || currentHash.includes("filter=All")) {
          loadSVODContainerItems(appProvider, sVODItemsUri, CONTAINER_ACTION_TYPE.WALL_PAGE, userProfile, pcLevel);
        } else {
          let data = currentHash.substring(currentHash.indexOf("?filter=") + 8);
          setFilterLoaded(data.replace(/%20/g, " "));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containers]);

  function onFilterSelected(item) {
    setIsChangingFilter(true);
    itemsList.length > 0 && setItemsList([]); // re-setting items in case of filter update
    if (item) {
      selectedFilter.current = item;
      loadSVODContainerItems(
        appProvider,
        item.uri + uriRegionId,
        CONTAINER_ACTION_TYPE.WALL_PAGE,
        userProfile,
        pcLevel
      );
    } else {
      loadSVODContainerItems(appProvider, getSVODItemsUri, CONTAINER_ACTION_TYPE.WALL_PAGE, pcLevel);
    }
  }

  useEffect(() => {
    if (filters && isFilterLoaded) {
      filters.forEach((element, index) => {
        if (element.Name === decodeURIComponent(isFilterLoaded)) {
          loadSVODContainerItems(
            appProvider,
            element.uri + uriRegionId,
            CONTAINER_ACTION_TYPE.WALL_PAGE,
            userProfile,
            pcLevel
          );
          setLastSelectedFilterIndex(index);
          selectedFilter.current = element;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const loadNextSetOfItems = (startIndex, endIndex) => {
    setIsLoading(true);

    if (detailUri) {
      const nextSetUri = selectedFilter?.current
        ? selectedFilter?.current.uri + uriRegionId
        : defaultSVODPkgUri?.current;
      loadSVODContainerItems(
        appProvider,
        nextSetUri,
        CONTAINER_ACTION_TYPE.WALL_PAGE,
        userProfile,
        pcLevel,
        startIndex,
        endIndex
      );
    } else {
      loadSVODItems(
        appProvider,
        contentProvider,
        contentSubType,
        userProfile,
        pcLevel,
        cancelTokenSource,
        startIndex,
        endIndex
      );
    }
  };

  return (
    <div>
      {filters && filters.length > 0 && (
        <div className="sort-filters">
          <CategoryFilter
            contentList={filters}
            filterSelected={onFilterSelected}
            lastSelectedFilterIndex={lastSelectedFilterIndex}
            filterCategoryName={filterCategoryName}
          />
        </div>
      )}

      <ViewAll
        title={pageDetails ? pageDetails[0]?.metadata?.title : ""}
        imgSrc={channelLogo}
        items={itemsList}
        isIconRect={true}
        isFromChannelSwimlane={true}
        noResultFound={!isChangingFilter && content?.length === 0 && itemsList?.length === 0}
        isLoading={isLoading}
        loadNextSetOfItems={loadNextSetOfItems}
        totalContent={totalContent}
        filterParams={selectedFilter.current}
      />
    </div>
  );
}

function mapStateToProps({ app, svodPackage }) {
  return {
    appProvider: app.provider,
    content: svodPackage.content,
    containers: svodPackage.containers,
    pageDetails: svodPackage.pageDetails,
    totalContent: svodPackage.totalContent,
    userProfile: app.userProfile,
    featureToggles: app.featureToggles,
  };
}
SvodViewAllPage.propTypes = {
  appProvider: PropTypes.object.isRequired,
  content: PropTypes.array,
  resetAction: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  featureToggles: PropTypes.object,
};

SvodViewAllPage.defaultProps = {
  content: [],
};

const mapDispatchToProps = {
  loadSVODItems,
  resetAction,
  loadSVODContainerItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(SvodViewAllPage);
