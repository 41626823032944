import moment from "moment";
import { trackPlayerFullscreen } from "../analytics/media";
import { getFeatureProperties } from "../utils";
import PlayerConstants from "../../shared/constants/player";
import constants from "../../shared/constants";
import epgConstants from "../constants/epg";

const { ASSET_TYPES } = PlayerConstants;
const { WATCH_OPTIONS, PACKAGE_TYPE } = constants;
const { PPV_STATES } = epgConstants;

/**
 * Returns progress percentage
 * @param {float} progress in seconds
 * @param {float} total duration in seconds
 * @return {int} progress in percentage
 */
export function getProgress(progress, duration) {
  return (progress / duration) * 100;
}

/**
 * Returns a second duration formatted as HH:MM:SS
 * @param {int} duration
 * @return {string} formatted time
 */
export function formatSecondsToTimeDisplay(duration) {
  let formattedTime = "";
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration - hours * 3600) / 60);
  let seconds = Math.floor(duration - (hours * 3600 + minutes * 60));

  if (hours !== 0) {
    formattedTime = formattedTime + hours + ":";
  }

  if (minutes !== 0) {
    formattedTime = minutes < 10 ? formattedTime + "0" + minutes + ":" : formattedTime + minutes + ":";
  } else {
    formattedTime = formattedTime + "00:";
  }

  if (seconds !== 0) {
    formattedTime = seconds < 10 ? formattedTime + "0" + seconds : formattedTime + seconds;
  } else {
    formattedTime = formattedTime + "00";
  }

  return formattedTime;
}

/**
 * Checks if the current view is in full screen
 * @param object browser document model
 */
export function isFullScreen(document) {
  return !!(
    document.fullscreen ||
    document.webkitIsFullScreen ||
    document.mozFullScreen ||
    document.msFullscreenElement ||
    document.fullscreenElement
  );
}

/**
 * Enters or exits fullscreen mode
 * @param {Boolean} isInFullScreenMode
 * @param {object} document object model
 * @param {object} videoRef video container
 */
export function showOrHideFullScreen(isInFullScreenMode, document, videoRef) {
  if (isInFullScreenMode) {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();
    trackPlayerFullscreen(false);
  } else {
    if (videoRef.requestFullscreen) videoRef.requestFullscreen();
    else if (videoRef.mozRequestFullScreen) videoRef.mozRequestFullScreen();
    else if (videoRef.webkitRequestFullScreen) videoRef.webkitRequestFullScreen();
    else if (videoRef.msRequestFullscreen) videoRef.msRequestFullscreen();
    trackPlayerFullscreen(true);
  }
}

/**
 * Determines the PPV event state, the appropriate asset to stream, and provides the PPV purchase package details
 * if the event is purchaseable.
 *
 * @param {Object} entitlement entitlement object from CONTENT/USERDATA/LIVE response
 * @returns {Object} object containing data to support the PPV feature
 */
export const getPPVTuneInfo = (entitlement) => {
  if (entitlement?.assets) {
    const masterAsset = entitlement.assets.find((asset) => asset.assetType?.toLowerCase() === ASSET_TYPES.MASTER);
    if (masterAsset?.rights) {
      const info = {
        eventState: PPV_STATES.BARKER,
        assetToPlay: null,
        purchasePackage: null,
      };

      if (masterAsset.rights.toLowerCase() === WATCH_OPTIONS.watch) {
        // Purchased PPV event flow, use the main event stream
        // TCDWC-1522 TODO: the backend was buggy when working on this so tuning to a purchased PPV event from guide AND
        // retuning after a successful purchase still needs to be tested (does it work, are some functionalities hidden as appropriate, etc.)
        info.assetToPlay = masterAsset;
        info.eventState = PPV_STATES.PURCHASED;
      } else if (masterAsset.rights.toLowerCase() === WATCH_OPTIONS.buy) {
        // use the barker stream
        info.assetToPlay = entitlement.assets.find((asset) => asset.assetType?.toLowerCase() === ASSET_TYPES.TRAILER);

        // check if the PPV event is available for purchase
        const ppvEventCommPackage = masterAsset.commercialPackages?.find(
          (commPackage) => commPackage.type?.toLowerCase() === PACKAGE_TYPE.PPV_EVENT
        );

        if (ppvEventCommPackage) {
          info.eventState = PPV_STATES.PURCHASABLE;
          info.purchasePackage = { ...ppvEventCommPackage, videoType: masterAsset.videoType };
        }
      }

      return info;
    }
  }
  return null;
};

/**
 * A PPV event is only purchasable if the purchase package is available and if there is more than `ppvPurchaseThreshold`
 * seconds remaining to the event end time.
 *
 * @param {Object} appProvider
 * @param {Object} ppvPurchasePackage Purchase package for a PPV Event
 * @param {Number} ppvEventEndTime Program end time of the PPV event, unix timestamp in milliseconds
 * @returns {Boolean} True if the event is purchasable
 */
export const isPPVEventPurchasable = (appProvider, ppvPurchasePackage, ppvEventEndTime) => {
  if (appProvider && ppvPurchasePackage && ppvEventEndTime) {
    const ppvPurchaseThreshold = getFeatureProperties(appProvider, "PPV")?.purchaseThresholdMs;
    return ppvEventEndTime - moment().valueOf() > ppvPurchaseThreshold;
  }

  return false;
};
