import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import SeoPageTags from "../../components/SeoPageTags";
import { PIN_MODAL_MODES, PIN_MODAL_TYPES } from "../../components/PinModal";
import middleware from "../../shared/middleware";
import parentalControlConstants from "../../shared/constants/parentalControls";
import constants from "../../shared/constants";
import errorConstants from "../../shared/constants/error";
import routeConstants from "../../shared/constants/routes";
import { loadUserProfile, loadUserProfiles, showToastNotification, showModalPopup } from "../../App/state/actions";
import { trackWebAction, trackGenericAction } from "../../shared/analytics/dataLayer";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import { getGenericErrorEventHandler } from "../../shared/analytics/helpers";
import { ACTION_VALUES, ANALYTICS_EVENT_TYPES, WEB_ACTION_EVENT_NAMES } from "../../shared/constants/analytics";
import ToggleSwitch from "../../components/ToggleSwitch";
import ParentalRatingModal from "../../components/ParentalRatingModal";
import ForgotPinLink from "../../components/ForgotPinLink";
import Button from "../../components/Button";
import ParentalRatingList from "../../components/ParentalRatingList";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import "./style.scss";

const { setParentalPINAvailability, updateParentalPIN, validateParentalPIN, updateParentalControlRatingLevel } =
  middleware;
const { PARENTAL_RATINGS } = parentalControlConstants;
const { MODAL_TYPES } = constants;
const { AVS_ERROR_CODES } = errorConstants;
const { SETTINGS } = routeConstants;
const VIEW_ALL_ICON = process.env.PUBLIC_URL + "/images/white-chevron.svg";

/**
 * Parental Control settings page component
 * @component
 * @param {object} props
 */

function ParentalPinPage(props) {
  const { t: translate } = useTranslation();
  const {
    appProvider,
    userProfile,
    loadUserProfile,
    loadUserProfiles,
    showToastNotification,
    showModalPopup,
    featureToggles,
  } = props;
  const [currentPCRating, setCurrentPCRating] = useState(null);
  const currentPinModalMode = useRef(PIN_MODAL_MODES.ACCESS);
  const [isPinEnabled, setIsPinEnabled] = useState(false);
  const [isToggleEnabled, setIsToggleEnabled] = useState(false);
  const pinConfirmHandler = useRef(null);
  const [popUpDialog, setPopUpDialog] = useState(false);
  const currentPinRef = useRef(null);
  const newPCRating = useRef(null);
  const analyticsErrorEventHandlerRef = useRef(null);
  const analyticsPCRatingCompletedRef = useRef({
    isUpdated: false,
    eventType: "",
    rating: null,
  });
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const { trackPageView } = useTrackPageView();
  const { isUserProfilesEnabled } = featureToggles;

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  useEffect(() => {
    const userProfileData = userProfile?.user?.profile?.profileData;
    let isEnabled = false;
    let userPCLevel = 0;

    if (userProfileData) {
      userPCLevel = parseInt(userProfileData.userPcLevelVod);
      isEnabled = userProfileData.parentalControlPinEnabled === "Y";
    }

    setCurrentPCRating(userPCLevel);
    setIsPinEnabled(isEnabled);
    setIsToggleEnabled(isEnabled);
  }, [userProfile]);

  useEffect(() => {
    const { isUpdated, eventType, rating } = analyticsPCRatingCompletedRef.current;
    if (isUpdated) {
      trackWebAction(eventType, { rating });
      analyticsPCRatingCompletedRef.current.isUpdated = false;
    }
  }, [currentPCRating]);

  /**
   * Sets up the PIN modal and displays it
   * @param {String} pinModalMode Can be set or access
   * @param {String} errorMessage The debug message to display in the console if an error occurs
   * @param {Boolean} closeOnConfirm Flag indicating if modal should close after confirm is pressed
   */
  const displayPINModal = (pinModalMode, errorMessage, closeOnConfirm = true) => {
    const modalContent = {
      pinModalMode,
      pinModalType: PIN_MODAL_TYPES.PARENTAL,
      pinConfirmHandler: pinConfirmHandler.current,
      pinAnalyticsErrorEventHandler: analyticsErrorEventHandlerRef.current,
      pinErrorMessage: errorMessage,
      closeOnConfirm,
    };

    if (pinModalMode === PIN_MODAL_MODES.ACCESS) {
      modalContent["title"] = translate("enter_parental_pin");
    }

    currentPinModalMode.current = pinModalMode;

    showModalPopup(MODAL_TYPES.PIN, modalContent);
  };

  /**
   * Set up the PIN modal for verifying the PIN
   * @param {Boolean} disablePin flag indicating if user is attempting to disable PIN
   * @param {String} errorEventType analytics error event type
   */
  const verifyPin = (disablePin, errorEventType) => {
    let errorMessage;

    if (disablePin) {
      pinConfirmHandler.current = disablePinConfirmHandler;
      analyticsErrorEventHandlerRef.current = getGenericErrorEventHandler(
        errorEventType,
        ACTION_VALUES.PARENTAL_PIN_DISABLE,
        WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_DISABLE_ERROR
      );
      errorMessage = "Error disabling PIN";
    } else {
      pinConfirmHandler.current = verifyPinConfirmHandler;
      analyticsErrorEventHandlerRef.current = getGenericErrorEventHandler(
        errorEventType,
        ACTION_VALUES.PARENTAL_PIN_RESET,
        WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_RESET_ERROR
      );
      errorMessage = "Error validating PIN";
    }

    displayPINModal(PIN_MODAL_MODES.ACCESS, errorMessage, disablePin);
  };

  /**
   * Set up the PIN modal for updating the PIN
   */
  const updatePin = () => {
    pinConfirmHandler.current = updatePinConfirmHandler;
    const errorMessage = "Error updating PIN";
    displayPINModal(PIN_MODAL_MODES.SET, errorMessage);
  };

  const enablePin = () => {
    pinConfirmHandler.current = enablePinConfirmHandler;
    analyticsErrorEventHandlerRef.current = getGenericErrorEventHandler(
      ANALYTICS_EVENT_TYPES.PARENTAL_PIN_ENABLE_ERROR,
      ACTION_VALUES.PARENTAL_PIN_ENABLE,
      WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_ENABLE_ERROR
    );
    const errorMessage = "Error enabling PIN";
    displayPINModal(PIN_MODAL_MODES.SET, errorMessage, true);
  };

  /**
   * Set up the PIN modal for updating the account rating restrictions
   * @param {Object} analyticsInfo
   */
  const updateAccountPCRating = ({
    analyticsEventStartType,
    analyticsEventCompleteType,
    rating,
    errorEventType,
    toolName,
    errorName,
  }) => {
    trackWebAction(analyticsEventStartType, { rating });
    analyticsPCRatingCompletedRef.current = {
      isUpdated: false,
      rating,
      eventType: analyticsEventCompleteType,
    };
    pinConfirmHandler.current = updateRatingPinConfirmHandler;
    analyticsErrorEventHandlerRef.current = getGenericErrorEventHandler(errorEventType, toolName, errorName);
    const errorMessage = "Error updating current parental control rating restriction";
    displayPINModal(PIN_MODAL_MODES.ACCESS, errorMessage);
  };

  /**
   * Checks if the provided PIN matches the user's currently set PIN
   * @param {String} pin
   */
  const verifyPinConfirmHandler = (pin) => {
    let promise = validateParentalPIN(appProvider, pin).then(() => {
      currentPinRef.current = pin;
      updatePin();
    });

    return promise;
  };

  /**
   * Update the user's PIN with the provided newPin
   * @param {String} newPin
   */
  const updatePinConfirmHandler = (newPin) => {
    let promise = updateParentalPIN(appProvider, currentPinRef.current, newPin).then(() => {
      trackWebAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_RESET_COMPLETE);
      currentPinRef.current = null;
      loadUserProfile(appProvider);
      showToastNotification(translate("pin_parental_updated"));
    });

    return promise;
  };

  /**
   * Handler for the PIN modal OK press during the update rating restriction flow.
   * Attempts to update the VOD and LIVE content rating levels to the value stored in newPCRating
   * using the provided PIN.
   * @param {String} pin
   */
  const updateRatingPinConfirmHandler = (pin) => {
    let promise = updateParentalControlRatingLevel(appProvider, pin, newPCRating.current.toString())
      .then(() => {
        newPCRating.current = null;
        analyticsPCRatingCompletedRef.current.isUpdated = true;
        loadUserProfile(appProvider);
      })
      .catch((e) => {
        if (!AVS_ERROR_CODES.INCORRECT_PIN_CODES.includes(e?.code)) {
          newPCRating.current = null;
        }

        throw e;
      });

    return promise;
  };

  /**
   * Handler for PIN modal OK press during the disable PIN flow.
   * Attempts to disable the parental PIN using the provided PIN.
   * @param {String} pin
   */
  const disablePinConfirmHandler = (pin) => {
    let promise = setParentalPINAvailability(appProvider, false, pin).then(() => {
      trackWebAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_DISABLE_COMPLETE);
      loadUserProfile(appProvider);
      showToastNotification(translate("parental_control_applied"));
      loadUserProfiles(appProvider, cancelTokenSource);
    });

    return promise;
  };

  /**
   * Handler for PIN modal OK press during the disable PIN flow.
   * Attempts to disable the parental control PIN using the provided PIN.
   * @param {String} pin
   */
  const enablePinConfirmHandler = (pin) => {
    let promise = setParentalPINAvailability(appProvider, true, pin).then(() => {
      trackWebAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_ENABLE_COMPLETE, { isWithToggle: true });
      loadUserProfile(appProvider);
      loadUserProfiles(appProvider, cancelTokenSource);
      showToastNotification(translate("parental_set_pin"));
    });

    return promise;
  };

  /**
   * Click handler for the parental control rating options.
   * Sets the value for newPCRating and begins the update rating restriction flow.
   * @param {String} key The parental control rating key used to determine what restriction value to reference
   */
  const ratingClickHandler = (key) => {
    const analyticsInfo = {
      toolName: ACTION_VALUES.PARENTAL_FILTER,
      rating: (PARENTAL_RATINGS[key].value || "zero").toString(),
      errorName: WEB_ACTION_EVENT_NAMES.PARENTAL_FILTER_ERROR,
      errorEventType: ANALYTICS_EVENT_TYPES.PARENTAL_FILTER_ERROR,
      analyticsEventStartType: ANALYTICS_EVENT_TYPES.PARENTAL_FILTER_START,
      analyticsEventCompleteType: ANALYTICS_EVENT_TYPES.PARENTAL_FILTER_COMPLETE,
    };

    if (PARENTAL_RATINGS[key].value !== currentPCRating) {
      newPCRating.current = PARENTAL_RATINGS[key].value;
      updateAccountPCRating(analyticsInfo);
    }
  };

  return userProfile?.user?.profile?.profileData?.isMasterAccount === "N" ? (
    <Redirect to={SETTINGS.route} />
  ) : (
    <React.Fragment>
      <SeoPageTags title={translate("parental_pin")} keywords={["optik", "telus"]} />
      <div className="parental-pin-page">
        <h1 className="parental-pin-heading">{translate("parental_pin")}</h1>
        <p className="parental-pin-desc">
          {translate(
            isUserProfilesEnabled
              ? "parental_control_rating_lock_admin_&_standard"
              : "parental_control_rating_lock_no_profiles"
          ).replace(/\n\n/g, "\n")}
        </p>

        {!isUserProfilesEnabled && (
          <button className="rating-definition-button" onClick={() => setPopUpDialog(true)}>
            {translate("rating_definition")}
            <img src={VIEW_ALL_ICON} alt="" className="ml-10" />
          </button>
        )}

        <div className="parental-pin-toggle-row">
          <span className="toggle-label">{translate("parental_pin")}</span>
          <div className="toggle-pin-switch">
            <ToggleSwitch
              isChecked={isToggleEnabled}
              onToggleButtonChange={(toggleOn) => {
                if (toggleOn) {
                  trackWebAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_ENABLE_START);
                  enablePin();
                } else {
                  trackWebAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_DISABLE_START);
                  verifyPin(true, ANALYTICS_EVENT_TYPES.PARENTAL_PIN_DISABLE_ERROR);
                }
              }}
            />
          </div>
        </div>

        {isPinEnabled && (
          <>
            {!isUserProfilesEnabled && (
              <ParentalRatingList
                isPcPinEnabled={isPinEnabled}
                selectedRating={currentPCRating}
                onRatingSelect={ratingClickHandler}
              />
            )}

            <div className="parental-pin-action-container">
              <Button
                buttonStyles="clear-button"
                label={translate("change_pin_stb")}
                onClickHandler={() => {
                  verifyPin(false, ANALYTICS_EVENT_TYPES.PARENTAL_PIN_RESET_ERROR);
                  trackWebAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_RESET_START);
                }}
              />

              <ForgotPinLink
                pinType={PIN_MODAL_TYPES.PARENTAL}
                onClick={(event) =>
                  trackGenericAction(ANALYTICS_EVENT_TYPES.EXIT_CLICK, {
                    name: "recover_parental_pin",
                    URL: event.currentTarget.href,
                  })
                }
              />
            </div>
          </>
        )}
      </div>

      {popUpDialog && <ParentalRatingModal handleClose={() => setPopUpDialog(false)} />}
    </React.Fragment>
  );
}

function mapStateToProps({ app }) {
  return {
    userProfile: app.userProfile,
    appProvider: app.provider,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  loadUserProfile,
  loadUserProfiles,
  showToastNotification,
  showModalPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentalPinPage);
