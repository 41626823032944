import React, { useState, useEffect } from "react";
import styles from "./style.scss";

/**
 * Component that render Volume Slide Bar component
 * @component
 */
const VolumeSlideBar = React.forwardRef(({ audioLevel, onChange, isMuted }, ref) => {
  const [volumeValue, setVolumeValue] = useState(audioLevel);
  const [rangeStyles, setRangeStyle] = useState({
    background: `linear-gradient(to right, ${styles.colorGreen}, ${styles.colorGreen} ${volumeValue}%, ${styles.colorWhite} ${volumeValue}%)`,
  });

  useEffect(() => {
    if (isMuted) {
      setVolumeValue(0);
      setRangeStyle({
        background: `linear-gradient(to right, ${styles.colorGreen}, ${styles.colorGreen} ${0}%, ${
          styles.colorWhite
        } ${0}%)`,
      });
    } else {
      let currentVolume = audioLevel;
      setVolumeValue(currentVolume);
      setRangeStyle({
        background: `linear-gradient(to right, ${styles.colorGreen}, ${styles.colorGreen} ${currentVolume}%, ${styles.colorWhite} ${currentVolume}%)`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMuted]);

  const onRangeChange = (e) => {
    let rangeVal = e.target.value;

    onChange(rangeVal);
    setVolumeValue(rangeVal);

    setRangeStyle({
      background: `linear-gradient(to right, ${styles.colorGreen}, ${styles.colorGreen} ${rangeVal}%, ${styles.colorWhite} ${rangeVal}%)`,
    });
  };

  return (
    <div className="range-container">
      <input
        className="input"
        type="range"
        value={volumeValue}
        onChange={onRangeChange}
        style={rangeStyles}
        onClick={(e) => e.stopPropagation()}
        step={5}
        ref={ref}
      />
    </div>
  );
});

export default VolumeSlideBar;
