import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  loadUserProfile,
  loadUserProfiles,
  LOAD_SUBSCRIBED_CHANNELS,
  resetAction,
  showModalPopup,
  showToastNotification,
} from "../../App/state/actions";
import ProfileScreen from "../../components/ProfileScreen";
import OptikButton from "../../components/OptikButton";
import TextInput from "../../components/TextInput";
import AvatarGrid from "../../components/AvatarGrid";
import AvatarButton from "../../components/AvatarButton";
// TODO: Add back in TCDWC-1208 (MVE4)
// import ToggleSwitch from "../../components/ToggleSwitch";
import { PIN_MODAL_MODES, PIN_MODAL_TYPES } from "../../components/PinModal";
import ParentalControlScreen from "../../components/ParentalControlScreen";
import ParentalRatingModal from "../../components/ParentalRatingModal";
import constants from "../../shared/constants";
import errorConstants from "../../shared/constants/error";
import parentalControlConstants from "../../shared/constants/parentalControls";
import routeConstants from "../../shared/constants/routes";
import middleware from "../../shared/middleware";
import { sortAndFilterAvatars } from "../../shared/utils/userProfile";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { trackGenericAction, trackWebAction } from "../../shared/analytics/dataLayer";
import {
  ACTION_VALUES,
  ANALYTICS_ERROR_INFO,
  ANALYTICS_ERROR_NAMES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_STORAGE_KEYS,
  LINK_INFO,
  PROFILE_PAGE_NAMES,
  WEB_ACTION_EVENT_NAMES,
} from "../../shared/constants/analytics";
import "./style.scss";
import { getGenericErrorEventHandler } from "../../shared/analytics/helpers";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import useTrackPageView from "../../shared/hooks/useTrackPageView";

const { MODAL_TYPES } = constants;
const { AVS_ERROR_CODES } = errorConstants;
const { PARENTAL_RATINGS } = parentalControlConstants;
const { PROFILE_EDIT, PROFILE_SELECT } = routeConstants;

const { updateProfile, deleteProfile, logoutProfile, getAvatarList, setParentalPINAvailability } = middleware;

/**
 * Page component for editing an individual profile
 * @component
 */
function EditProfilePage({
  appProvider,
  profiles,
  userProfile,
  loadUserProfile,
  loadUserProfiles,
  showToastNotification,
  showModalPopup,
  resetAction,
}) {
  const match = useRouteMatch();
  const userId = parseInt(match.params.id, 10);

  const profile = profiles?.find((profile) => profile.userId === userId);
  const { isMaster: isMasterProfile, kidsProfile, username } = profile ?? {};

  const isPcPinEnabled = userProfile?.user?.profile?.profileData?.parentalControlPinEnabled === "Y";
  const isMasterAccountLoggedIn = userProfile?.user?.profile?.profileData?.isMasterAccount === "Y";
  const isPageLoadAllowed = isMasterAccountLoggedIn || userProfile?.user?.profile?.profileData?.userId === userId;

  const [showSelectAvatarScreen, setShowSelectAvatarScreen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(profile?.avatar);
  const [avatarList, setAvatarList] = useState(null);
  const [avatarError, setAvatarError] = useState(null);
  // TODO: Add back in TCDWC-1208 (MVE4)
  // const [isExitProofEnabled, setIsExitProofEnabled] = useState(profile?.exitProofEnabled);
  const [profileName, setProfileName] = useState(profile?.profileName);
  const [profileNameInputError, setProfileNameInputError] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showParentalControlScreen, setShowParentalControlScreen] = useState(false);
  const [showRatingsDefinitions, setShowRatingsDefinitions] = useState(false);
  const [pcLevel, setPcLevel] = useState(parseInt(profile?.userPcLevelVod, 10));
  const profileNameInput = useRef(null);
  const parentalControlPin = useRef(null);
  const isProfileDeleted = useRef(false);
  const { t: translate } = useTranslation();
  const history = useHistory();
  const isToolUsageStartedRef = useRef(false);
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const { trackPageView, resetIsPageViewTracked } = useTrackPageView();

  useEffect(() => {
    if (isPageLoadAllowed && showSelectAvatarScreen && !avatarList) {
      getAvatarList(appProvider, cancelTokenSource).then((result) => {
        if (result?.length > 0) {
          // Exclude avatars used by other profiles
          const exclusions = profiles
            ?.filter((profile) => profile.userId !== userId)
            ?.map((profile) => profile.avatar?.id);
          const sortedActiveAvatars = sortAndFilterAvatars(result, exclusions);
          setAvatarList(sortedActiveAvatars);
        }
      });
    }
  }, [appProvider, avatarList, cancelTokenSource, isPageLoadAllowed, profiles, showSelectAvatarScreen, userId]);

  useEffect(() => {
    if (!isPageLoadAllowed) return;
    if (showSelectAvatarScreen) {
      trackPageView({
        profileEvent: {},
        pageName: PROFILE_PAGE_NAMES.CHOOSE_IMAGE,
      });
    } else {
      trackPageView({
        pageName: PROFILE_PAGE_NAMES.EDIT_PROFILE,
        profileEvent: isToolUsageStartedRef.current
          ? {}
          : {
              toolUsageStart: 1,
              toolName: ACTION_VALUES.EDIT_PROFILE,
            },
      });
      isToolUsageStartedRef.current = true;
    }
  }, [isPageLoadAllowed, showSelectAvatarScreen, trackPageView]);

  const onAvatarSelect = (avatarInfo, index = 0) => {
    resetIsPageViewTracked();
    setSelectedAvatar(avatarInfo);
    setShowSelectAvatarScreen(false);
    setAvatarError(null);
    setSessionStorage(
      ANALYTICS_STORAGE_KEYS.LINK,
      `${LINK_INFO.PROFILE_AVATAR}_${index + 1};${LINK_INFO.CHOOSE_AN_IMAGE}`
    );
  };

  const onPcRatingSelect = (pcParams) => {
    if (pcParams) {
      setPcLevel(pcParams.ratingValue);
      parentalControlPin.current = pcParams.validatedPin;
    }
    setShowParentalControlScreen(false);
  };

  const saveProfile = (e) => {
    e.preventDefault();

    // Prevent form resubmission
    if (isFormSubmitted) {
      return;
    }

    const profileNameInputValue = profileNameInput.current?.value?.trim();
    if (!profileNameInputValue || profileNameInputValue?.length === 0) {
      setProfileNameInputError(translate("please_enter_profile_name"));
      trackGenericAction(ANALYTICS_EVENT_TYPES.PROFILE_EDIT_ERROR, {
        toolName: ACTION_VALUES.EDIT_PROFILE,
        name: ANALYTICS_ERROR_NAMES.USER_ERROR,
        errorDetails: ANALYTICS_ERROR_INFO.PROFILE_EDIT_FAILURE,
        errorMessage: "please_enter_profile_name",
        errorCode: null,
      });
      return;
    }
    setProfileNameInputError(null);
    setIsFormSubmitted(true);

    const toolSelections = [];
    const profileParams = { username };

    // TODO: Add back in TCDWC-1208 (MVE4)
    // if (kidsProfile) {
    //   profileParams.exitProofEnabled = isExitProofEnabled;
    // }

    // Send params that have to be unique across profiles only if they have changed,
    // otherwise we get "[parameter] already in use" errors
    if (profileNameInputValue !== profile?.profileName) {
      profileParams.profileName = profileNameInputValue;
      toolSelections.push("name_change");
    }
    if (selectedAvatar?.id !== profile?.avatar?.id) {
      profileParams.avatarId = selectedAvatar.id;
      toolSelections.push("profile_image_change");
    }

    // Pass in parental control level and pin if the user has changed the rating level and validated the pin
    const pcLevelParam = pcLevel.toString();
    if (parentalControlPin.current && pcLevelParam !== profile?.userPcLevelVod) {
      profileParams.parentalControlPin = parentalControlPin.current;
      profileParams.pcLevelVod = pcLevelParam;
      profileParams.pcLevelEpg = pcLevelParam;
      toolSelections.push("parental_control_change");
    }

    updateProfile(appProvider, profileParams)
      .then(() => {
        if (userProfile?.user?.profile?.profileData?.userId === userId) {
          return Promise.allSettled([loadUserProfile(appProvider), loadUserProfiles(appProvider, cancelTokenSource)]);
        } else {
          return loadUserProfiles(appProvider, cancelTokenSource);
        }
      })
      .then(() => {
        trackGenericAction(WEB_ACTION_EVENT_NAMES.PROFILE_EDIT_COMPLETE, { toolSelections });
        setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.SAVE};${LINK_INFO.EDIT_PROFILE}`);
        showToastNotification(translate("profiles_updated"));
        history.push(isMasterAccountLoggedIn ? PROFILE_EDIT.route : "/");
      })
      .catch((err) => {
        let errMessage = "";
        let errorName = ANALYTICS_ERROR_NAMES.USER_ERROR;
        if (err?.code?.includes(AVS_ERROR_CODES.AVATAR_IN_USE)) {
          if (selectedAvatar) {
            loadUserProfiles(appProvider, cancelTokenSource).then(() => {
              setAvatarList(null);
            });
          }
          errMessage = "profile_avatar_used";
          setAvatarError(translate("profile_avatar_used"));
        } else if (err?.code?.includes(AVS_ERROR_CODES.PROFILE_NAME_IN_USE)) {
          errMessage = "profile_name_already_used";
          setProfileNameInputError(translate("profile_name_already_used"));
        } else if (err?.code?.includes(AVS_ERROR_CODES.INVALID_PROFILE_NAME)) {
          errMessage = "please_use_letters_and_numbers";
          setProfileNameInputError(translate("please_use_letters_and_numbers"));
        } else {
          errMessage = "profiles_failed_update";
          errorName = ANALYTICS_ERROR_NAMES.SERVER_ERROR;
          showToastNotification(translate("profiles_failed_update"));
        }

        trackGenericAction(ANALYTICS_EVENT_TYPES.PROFILE_EDIT_ERROR, {
          toolName: ACTION_VALUES.EDIT_PROFILE,
          name: errorName,
          errorDetails: ANALYTICS_ERROR_INFO.PROFILE_EDIT_FAILURE,
          errorMessage: errMessage,
          errorCode: err?.code,
        });
        setIsFormSubmitted(false);
      });
  };

  const showDeleteProfileModal = () => {
    const modalTitle = translate("profiles_delete_name")
      .split("%s")
      .map((token, index) => {
        if (index === 0) {
          return token;
        }
        return (
          <Fragment key="profile-name">
            <span className={`profile-name ${profile.profileName.length > 16 ? "overflow" : ""}`}>
              {profile.profileName}
            </span>
            {token}
          </Fragment>
        );
      });
    const modalContent = {
      title: modalTitle,
      className: "delete-profile-modal",
      isCloseable: true,
      message: "profiles_delete_permanent",
      buttons: [
        {
          label: translate("delete"),
          classOverride: "delete-button",
          onClickHandler: async () => {
            try {
              // If user is deleting their own profile, log out from subaccount first
              if (userProfile?.user?.profile?.profileData?.userId === userId) {
                await logoutProfile(appProvider);
                await loadUserProfile(appProvider);
                resetAction(LOAD_SUBSCRIBED_CHANNELS, "subscribedChannels");
              }
              await deleteProfile(appProvider, userId);
              isProfileDeleted.current = true;
              await loadUserProfiles(appProvider, cancelTokenSource);
              trackGenericAction(WEB_ACTION_EVENT_NAMES.PROFILE_EDIT_COMPLETE, { toolSelections: ["deleted_profile"] });
              setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.DELETE};${LINK_INFO.EDIT_PROFILE}`);
              history.push({
                pathname: isMasterAccountLoggedIn ? PROFILE_EDIT.route : PROFILE_SELECT.route,
                state: {
                  deletedProfile: {
                    ...profile,
                    originalIndex: profiles.findIndex((profile) => profile.userId === userId),
                  },
                },
              });
            } catch (err) {
              showToastNotification(translate("profiles_failed_delete"));
              trackGenericAction(ANALYTICS_EVENT_TYPES.PROFILE_EDIT_ERROR, {
                toolName: ACTION_VALUES.EDIT_PROFILE,
                name: ANALYTICS_ERROR_NAMES.SERVER_ERROR,
                errorDetails: ANALYTICS_ERROR_INFO.PROFILE_EDIT_FAILURE,
                errorMessage: "profiles_failed_delete",
                errorCode: err?.code,
              });
            }
          },
        },
      ],
      endButtonLabelOverride: translate("cancel"),
      endButtonClassOverride: "ghost-button",
    };
    showModalPopup(MODAL_TYPES.ERROR, modalContent);
  };

  if (!isPageLoadAllowed) {
    return <Redirect to="/" />;
  } else if (!profile && !isProfileDeleted.current) {
    return <Redirect to={PROFILE_EDIT.route} />;
  } else if (showSelectAvatarScreen) {
    return (
      <ProfileScreen>
        <div className="select-avatar-container">
          <h1>{translate("profiles_choose_image")}</h1>
          <OptikButton
            className="cancel-button"
            label={translate("cancel")}
            onClickHandler={() => {
              setShowSelectAvatarScreen(false);
              setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.CANCEL};${LINK_INFO.CHOOSE_AN_IMAGE}`);
            }}
          />
          <AvatarGrid avatarList={avatarList} onAvatarSelect={onAvatarSelect} />
        </div>
      </ProfileScreen>
    );
  } else if (showParentalControlScreen) {
    return (
      <ProfileScreen>
        <ParentalControlScreen
          currentSelectedRating={pcLevel}
          isMasterProfile={isMasterProfile}
          isPcPinEnabled={isPcPinEnabled}
          onRatingSelect={onPcRatingSelect}
        />
      </ProfileScreen>
    );
  } else {
    return (
      <ProfileScreen isKidsBackground={kidsProfile}>
        <form className="edit-profile-form" onSubmit={saveProfile}>
          <h1>{translate("edit_profile")}</h1>
          <div className="form-row">
            <div className="avatar-container">
              <AvatarButton
                avatarInfo={selectedAvatar}
                onClick={() => {
                  resetIsPageViewTracked();
                  setShowSelectAvatarScreen(true);
                  setSessionStorage(
                    ANALYTICS_STORAGE_KEYS.LINK,
                    `${LINK_INFO.PROFILE_AVATAR};${LINK_INFO.EDIT_PROFILE}`
                  );
                }}
                isKids={kidsProfile}
                isEditMode={true}
              />
              {avatarError && <span className="avatar-error">{avatarError}</span>}
            </div>
            <div className="input-container">
              <TextInput
                ref={profileNameInput}
                value={profileName}
                placeholder={translate("profile_name")}
                onChange={(event) => setProfileName(event.target.value)}
                errorMessage={profileNameInputError}
              />

              <button
                type="button"
                className="button-input"
                onClick={() => {
                  if (kidsProfile) {
                    setShowRatingsDefinitions(true);
                  } else if (isMasterAccountLoggedIn && !isPcPinEnabled) {
                    trackWebAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_ENABLE_START);

                    // Set up a PIN to access the parental control screen
                    showModalPopup(MODAL_TYPES.PIN, {
                      pinModalMode: PIN_MODAL_MODES.SET,
                      pinModalType: PIN_MODAL_TYPES.PARENTAL,
                      pinConfirmHandler: async (pin) => {
                        await setParentalPINAvailability(appProvider, true, pin);
                        await Promise.allSettled([
                          loadUserProfile(appProvider),
                          loadUserProfiles(appProvider, cancelTokenSource),
                        ]);
                        setShowParentalControlScreen(true);
                        showToastNotification(translate("parental_set_pin"));
                        trackWebAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_ENABLE_COMPLETE, { isWithToggle: false });
                      },
                      pinAnalyticsErrorEventHandler: getGenericErrorEventHandler(
                        ANALYTICS_EVENT_TYPES.PARENTAL_PIN_ENABLE_ERROR,
                        ACTION_VALUES.PARENTAL_PIN_ENABLE,
                        WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_ENABLE_ERROR
                      ),
                    });
                  } else {
                    setShowParentalControlScreen(true);
                  }
                }}
              >
                <span className="label">{translate("pin_parental_control")}</span>
                <span className="selected-value">
                  {kidsProfile
                    ? translate("content_rating_description")
                    : isPcPinEnabled
                    ? translate(getPcRatingStringKey(pcLevel))
                    : translate("profiles_access_all")}
                </span>
                <div className="icon-container">
                  {kidsProfile ? (
                    <img className="info-icon" src={`${process.env.PUBLIC_URL}/images/info-icon.svg`} alt="" />
                  ) : (
                    <img className="chevron-icon" src={`${process.env.PUBLIC_URL}/images/chevron-right.svg`} alt="" />
                  )}
                </div>
              </button>

              {/* TODO: Add back in TCDWC-1208 (MVE4) */}
              {/* {kidsProfile && (
                <div className={"toggle-setting-container"}>
                  <div>
                    <span>{translate("kid_proof_exit")}</span>
                    <p>{translate("kid_proof_exit_description")}</p>
                  </div>
                  <ToggleSwitch
                    isChecked={isExitProofEnabled}
                    onToggleButtonChange={setIsExitProofEnabled}
                  />
                </div>
              )} */}
            </div>
          </div>
          <div className="form-row cta-container">
            <OptikButton
              className="ghost-button"
              label={translate("cancel")}
              onClickHandler={() => {
                setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.CANCEL};${LINK_INFO.EDIT_PROFILE}`);
                if (history.length > 1) {
                  history.goBack();
                } else {
                  history.push(PROFILE_EDIT.route);
                }
              }}
            />
            <OptikButton
              label={translate("save")}
              onClickHandler={saveProfile}
              disabled={isFormSubmitted}
              type="submit"
            />
          </div>
          {isMasterProfile ? (
            <div className="form-row delete-text-container">{translate("profiles_admin_cannot_delete")}</div>
          ) : (
            <div className="form-row delete-button-container">
              <OptikButton label={translate("delete_profile")} onClickHandler={showDeleteProfileModal} />
            </div>
          )}
        </form>

        {showRatingsDefinitions && (
          <ParentalRatingModal handleClose={() => setShowRatingsDefinitions(false)} isKidsProfile={kidsProfile} />
        )}
      </ProfileScreen>
    );
  }
}

/**
 * Return the highest content rating level that is not parental control restricted
 * @param {Number} ratingValue
 * @returns {String}
 */
const getPcRatingStringKey = (ratingValue) => {
  if (ratingValue === PARENTAL_RATINGS.UNRATED.value) {
    return "profiles_access_all";
  }
  if (ratingValue === PARENTAL_RATINGS.EXEMPT.value) {
    return "all_content_restricted";
  }

  const targetKey = Object.keys(PARENTAL_RATINGS).find((key) => PARENTAL_RATINGS[key]?.value < ratingValue);
  return PARENTAL_RATINGS?.[targetKey]?.rating;
};

EditProfilePage.propTypes = {
  appProvider: PropTypes.object,
  profiles: PropTypes.array,
  userProfile: PropTypes.object,
  loadUserProfile: PropTypes.func,
  loadUserProfiles: PropTypes.func,
  showToastNotification: PropTypes.func,
  showModalPopup: PropTypes.func,
  resetAction: PropTypes.func,
};

const mapStateToProps = ({ app }) => ({
  appProvider: app.provider,
  profiles: app.profileList,
  userProfile: app.userProfile,
});

const mapDispatchToProps = {
  loadUserProfile,
  loadUserProfiles,
  showToastNotification,
  showModalPopup,
  resetAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfilePage);
