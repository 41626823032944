import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import "./style.scss";
import SeoPageTags from "../../components/SeoPageTags";
import useTrackPageView from "../../shared/hooks/useTrackPageView";

/**
 * Page that opens from the basics option in settings
 * @component
 */
function BasicsPage() {
  const { t: translate } = useTranslation();
  const { trackPageView } = useTrackPageView();
  const { isAppLanguageFrench } = useAppLanguage();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <React.Fragment>
      <SeoPageTags title={translate("settings_basics")} keywords={["optik", "telus"]} />
      <div className="basics">
        <div className="basics-heading">
          <span className="basics-label">{translate("settings_basics")}</span>
        </div>
        <div className="basics-row">
          <div className="basics-thumb">
            <img src={`${process.env.PUBLIC_URL}/images/Welcome_Popup_Carousel_2.png`} alt="" />
          </div>
          <div className="basics-thumb-description">
            <h3>{translate("Welcome_Popup_Carousel_2_title")}</h3>
            <p>
              {translate("Welcome_Popup_Carousel_2_subTitle_1")} {translate("Welcome_Popup_Carousel_2_subTitle_2")}
            </p>
          </div>
        </div>
        <div className="basics-row">
          <div className="basics-thumb">
            {isAppLanguageFrench ? (
              <img src={`${process.env.PUBLIC_URL}/images/Welcome_Popup_Carousel_3_FR.gif`} alt="" />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/images/Welcome_Popup_Carousel_3.gif`} alt="" />
            )}
          </div>
          <div className="basics-thumb-description">
            <h3>{translate("Welcome_Popup_Carousel_3_title")}</h3>
            <p>
              {translate("Welcome_Popup_Carousel_3_subTitle_1")} {translate("Welcome_Popup_Carousel_3_subTitle_2")}
            </p>
          </div>
        </div>
        <div className="basics-row">
          <div className="basics-thumb">
            {isAppLanguageFrench ? (
              <img src={`${process.env.PUBLIC_URL}/images/Welcome_Popup_Carousel_4_FR.gif`} alt="" />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/images/Welcome_Popup_Carousel_4.gif`} alt="" />
            )}
          </div>
          <div className="basics-thumb-description">
            <h3>{translate("Welcome_Popup_Carousel_4_title")}</h3>
            <p>
              {translate("Welcome_Popup_Carousel_4_subTitle_1")} {translate("Welcome_Popup_Carousel_4_subTitle_2")}
            </p>
          </div>
        </div>
        <div className="basics-row">
          <div className="basics-thumb">
            {isAppLanguageFrench ? (
              <img src={`${process.env.PUBLIC_URL}/images/Lookback_FR.gif`} alt="" />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/images/Lookback_EN.gif`} alt="" />
            )}
          </div>
          <div className="basics-thumb-description">
            <h3>{translate("Feature_Onboarding_Lookback_Title")}</h3>
            <p>{translate("Feature_Onboarding_Lookback_Body")}</p>
          </div>
        </div>
        <div className="basics-row">
          <div className="basics-thumb">
            {isAppLanguageFrench ? (
              <img src={`${process.env.PUBLIC_URL}/images/Contextual_menu_FR.gif`} alt="" />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/images/Contextual_menu_EN.gif`} alt="" />
            )}
          </div>
          <div className="basics-thumb-description">
            <h3>{translate("Feature_Onboarding_Contextual_Title")}</h3>
            <p>{translate("Feature_Onboarding_Contextual_Body")}</p>
          </div>
        </div>
        <div className="basics-row">
          <div className="basics-thumb">
            {isAppLanguageFrench ? (
              <img src={`${process.env.PUBLIC_URL}/images/Favourite_FR.gif`} alt="" />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/images/Favourite_EN.gif`} alt="" />
            )}
          </div>
          <div className="basics-thumb-description">
            <h3>{translate("Feature_Onboarding_Favourite_Title")}</h3>
            <p>{translate("Feature_Onboarding_Favourite_Body")}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BasicsPage;
