/**
 * load core modules
 */
import React from "react";
import PropTypes from "prop-types";
/* load core modules end */

/**
 * load child components
 */
import ImageButton from "../../components/ImageButton";
/* load child components end */

/**
 * Component to render primary recording CTA for details page
 * @component
 * @param {Object} props
 */
const RecordingCTA = ({ handleRecordingAction, showRecordingCTALoading, primaryRecordingCTA }) => {
  return primaryRecordingCTA ? (
    <div className="recording-button-wrapper">
      <ImageButton
        src={primaryRecordingCTA.recordingIcon}
        className="wall-content-icon wall-recording-icon"
        onClickHandler={(event) => handleRecordingAction(event)}
        testID="wallRecordIcon"
        buttonContainerStyles="button-container"
        alt={primaryRecordingCTA.altHead}
        tooltipDirection="top"
      />
      {showRecordingCTALoading && (
        <img className="record-page-loading" src={process.env.PUBLIC_URL + "/images/Rec_Progress.svg"} alt="" />
      )}
    </div>
  ) : null;
};

RecordingCTA.propTypes = {
  handleRecordingAction: PropTypes.func,
  showRecordingCTALoading: PropTypes.bool,
  primaryRecordingCTA: PropTypes.object,
};

export default RecordingCTA;
