import Conviva from "@convivainc/conviva-js-coresdk";
import PlayerConstants from "../constants/player";

/**
 * String "true" or "false" should be used when a field is Boolean type in Conviva (only happens in custom javascript implementation).
 * If passing boolean true or false as a value, "True" & "False" will be displayed on Conviva dashboard.
 * */
export const TRUE = "true";
export const FALSE = "false";
export const NOT_AVAILABLE = null;
export const DEFAULT_RESOURCE = "INHOUSE";
export const STALL_TRACKING_DELAY_MS = 100;
export const UNKNOWN = "UNKNOWN";

export const LINE_TYPES = Object.freeze({
  COPPER: "copper",
  GPON: "gpon",
  FIBRE: "fibre",
});

export const PLAYBACK_TYPES = Object.freeze({
  MOVIE: "movie",
  EPISODE: "episode",
  LIVE: "live",
  TRAILER: "trailer",
});

export const CONTENT_METADATA = Object.freeze({
  TYPE: "c3.cm.contentType",
  CHANNEL: "c3.cm.channel",
  CATEGORY_TYPE: "c3.cm.categoryType",
  ASSET_ID: "c3.cm.id",
  SERIES_NAME: "c3.cm.seriesName",
  SEASON_NUM: "c3.cm.seasonNumber",
  SHOW_TITLE: "c3.cm.showTitle",
  EPISODE_NUM: "c3.cm.episodeNumber",
  MAIN_GENRE: "c3.cm.genre",
  GENRES: "c3.cm.genreList",
});

export const DEVICE_METADATA = Object.freeze({
  OS_FAMILY: "Device Operating System Family",
  NAME: "Device Name",
  MARKETING_NAME: "Device Marketing Name",
  BROWSER_NAME: "Browser Name",
  BROWSER_VERSION: "Browser Version",
});

export const CUSTOM_METADATA = Object.freeze({
  SESSION_ID: "sessionId",
  CHANNEL_ID: "channelId",
  CHANNEL_MAP: "channelMap",
  DRM: "drm",
  UNIVERSAL_METADATA_INFO: "universalMetadataId",
  TMS_ID: "tmsId",
  LOGGED_IN: "loggedIn",
  PLAY_BACK_MODE: "playBackMode",
  IN_HOME: "inHome",
  WIFI_SIGNAL_STRENGTH: "wifiSignalStrength",
  CONTENT_SUBTYPE: "contentSubtype",
  STB_SERIAL_NUMBER: "stbSerialNumber",
  TAMS_ID: "tamsId",
  CUSTOMER_TYPE: "customerType",
  UUID: "UUID",
  LINE_TYPE: "lineType",
  NETWORK_ACCESS_DEVICE: "networkAccessDevice",
  STB_FIRMWARE: "stbFirmware",
  PANIC_MODE: "panicMode",
  SHOW_TYPE: "showType",
  STREAM_TYPE: "streamType",
  PROFILE_ID: "profileId",
  PROFILE_TYPE: "profileType",
  SUBSCRIPTION_TYPE: "subscriptionType",
  CDN_HOST_NAME: "cdnHostName",
  CDN_URL: "cdnURL",
  STREAM_LOCATION: "streamLocation",
  VIDEO_RESOLUTION: "videoResolution",
});

export const CUSTOM_EVENTS = Object.freeze({
  VIDEO_QUALITY_CHANGE: "VideoQualityChange",
  PARENTAL_PIN_START: "ParentalPinStart",
  PARENTAL_PIN_CANCEL: "ParentalPinCancel",
  PARENTAL_PIN_SUCCESS: "ParentalPinSuccess",
  PARENTAL_PIN_FAILED: "ParentalPinFailed",
});

export const CUSTOM_EVENTS_ATTRIBUTES = Object.freeze({
  OLD_QUALITY: "OldQuality",
  NEW_QUALITY: "NewQuality",
  PLAYER: "Player",
});

export const STREAM_TYPES = Object.freeze({
  VIDEO: "video",
  AUDIO: "audio",
});

export const PLAY_BACK_MODE = Object.freeze({
  LOCAL: "local",
});

export class ContentMetadataBuilder {
  // internal metadata fields
  #metadata = {};
  #contentMetadata = {};

  build() {
    this.#contentMetadata[Conviva.Constants.ASSET_NAME] = this.#metadata[Conviva.Constants.ASSET_NAME];
    this.#contentMetadata[Conviva.Constants.STREAM_URL] = this.#metadata[Conviva.Constants.STREAM_URL];
    this.#contentMetadata[Conviva.Constants.IS_LIVE] = this.#metadata[Conviva.Constants.IS_LIVE];
    this.#contentMetadata[Conviva.Constants.DURATION] = this.#metadata[Conviva.Constants.DURATION];
    this.#contentMetadata[Conviva.Constants.DEFAULT_RESOURCE] = this.#metadata[Conviva.Constants.DEFAULT_RESOURCE];
    this.#contentMetadata[Conviva.Constants.PLAYER_NAME] = this.#metadata[Conviva.Constants.PLAYER_NAME];
    this.#contentMetadata[Conviva.Constants.APPLICATION_VERSION] =
      this.#metadata[Conviva.Constants.APPLICATION_VERSION];
    this.#contentMetadata[Conviva.Constants.VIEWER_ID] = this.#metadata[Conviva.Constants.VIEWER_ID];
    this.#contentMetadata[Conviva.Constants.FRAMEWORK_NAME] = PlayerConstants.PLAYER_NAME;
    this.#contentMetadata[Conviva.Constants.FRAMEWORK_VERSION] = PlayerConstants.PLAYER_SDK_VERSION;

    this.#contentMetadata[CONTENT_METADATA.TYPE] = this.#metadata[CONTENT_METADATA.TYPE];
    this.#contentMetadata[CONTENT_METADATA.CHANNEL] = this.#metadata[CONTENT_METADATA.CHANNEL];
    this.#contentMetadata[CONTENT_METADATA.CATEGORY_TYPE] = this.#metadata[CONTENT_METADATA.CATEGORY_TYPE];
    this.#contentMetadata[CONTENT_METADATA.ASSET_ID] = this.#metadata[CONTENT_METADATA.ASSET_ID];
    this.#contentMetadata[CONTENT_METADATA.SERIES_NAME] = this.#metadata[CONTENT_METADATA.SERIES_NAME];
    this.#contentMetadata[CONTENT_METADATA.SEASON_NUM] = this.#metadata[CONTENT_METADATA.SEASON_NUM];
    this.#contentMetadata[CONTENT_METADATA.SHOW_TITLE] = this.#metadata[CONTENT_METADATA.SHOW_TITLE];
    this.#contentMetadata[CONTENT_METADATA.EPISODE_NUM] = this.#metadata[CONTENT_METADATA.EPISODE_NUM];
    this.#contentMetadata[CONTENT_METADATA.MAIN_GENRE] = this.#metadata[CONTENT_METADATA.MAIN_GENRE];
    this.#contentMetadata[CONTENT_METADATA.GENRES] = this.#metadata[CONTENT_METADATA.GENRES];

    this.#contentMetadata[CUSTOM_METADATA.SESSION_ID] = this.#metadata[CUSTOM_METADATA.SESSION_ID];
    this.#contentMetadata[CUSTOM_METADATA.CHANNEL_ID] = this.#metadata[CUSTOM_METADATA.CHANNEL_ID];
    this.#contentMetadata[CUSTOM_METADATA.CHANNEL_MAP] = this.#metadata[CUSTOM_METADATA.CHANNEL_MAP];
    this.#contentMetadata[CUSTOM_METADATA.DRM] = this.#metadata[CUSTOM_METADATA.DRM];
    this.#contentMetadata[CUSTOM_METADATA.UNIVERSAL_METADATA_INFO] =
      this.#metadata[CUSTOM_METADATA.UNIVERSAL_METADATA_INFO];
    this.#contentMetadata[CUSTOM_METADATA.TMS_ID] = this.#metadata[CUSTOM_METADATA.TMS_ID];
    this.#contentMetadata[CUSTOM_METADATA.LOGGED_IN] = this.#metadata[CUSTOM_METADATA.LOGGED_IN];
    this.#contentMetadata[CUSTOM_METADATA.PLAY_BACK_MODE] = this.#metadata[CUSTOM_METADATA.PLAY_BACK_MODE];
    this.#contentMetadata[CUSTOM_METADATA.IN_HOME] = this.#metadata[CUSTOM_METADATA.IN_HOME];
    this.#contentMetadata[CUSTOM_METADATA.WIFI_SIGNAL_STRENGTH] = NOT_AVAILABLE;
    this.#contentMetadata[CUSTOM_METADATA.CONTENT_SUBTYPE] = this.#metadata[CUSTOM_METADATA.CONTENT_SUBTYPE];
    this.#contentMetadata[CUSTOM_METADATA.TAMS_ID] = this.#metadata[CUSTOM_METADATA.TAMS_ID];
    this.#contentMetadata[CUSTOM_METADATA.STB_SERIAL_NUMBER] = NOT_AVAILABLE;
    this.#contentMetadata[CUSTOM_METADATA.UUID] = this.#metadata[CUSTOM_METADATA.UUID];
    this.#contentMetadata[CUSTOM_METADATA.LINE_TYPE] = this.#metadata[CUSTOM_METADATA.LINE_TYPE];
    this.#contentMetadata[CUSTOM_METADATA.CUSTOMER_TYPE] = this.#metadata[CUSTOM_METADATA.CUSTOMER_TYPE];
    this.#contentMetadata[CUSTOM_METADATA.NETWORK_ACCESS_DEVICE] =
      this.#metadata[CUSTOM_METADATA.NETWORK_ACCESS_DEVICE];
    this.#contentMetadata[CUSTOM_METADATA.STB_FIRMWARE] = NOT_AVAILABLE;
    this.#contentMetadata[CUSTOM_METADATA.PANIC_MODE] = this.#metadata[CUSTOM_METADATA.PANIC_MODE];
    this.#contentMetadata[CUSTOM_METADATA.SHOW_TYPE] = this.#metadata[CUSTOM_METADATA.SHOW_TYPE];
    this.#contentMetadata[CUSTOM_METADATA.STREAM_TYPE] = this.#metadata[CUSTOM_METADATA.STREAM_TYPE];
    this.#contentMetadata[CUSTOM_METADATA.PROFILE_ID] = this.#metadata[CUSTOM_METADATA.PROFILE_ID];
    this.#contentMetadata[CUSTOM_METADATA.PROFILE_TYPE] = this.#metadata[CUSTOM_METADATA.PROFILE_TYPE];
    this.#contentMetadata[CUSTOM_METADATA.SUBSCRIPTION_TYPE] = this.#metadata[CUSTOM_METADATA.SUBSCRIPTION_TYPE];
    this.#contentMetadata[CUSTOM_METADATA.CDN_HOST_NAME] = this.#metadata[CUSTOM_METADATA.CDN_HOST_NAME];
    this.#contentMetadata[CUSTOM_METADATA.CDN_URL] = this.#metadata[CUSTOM_METADATA.CDN_URL];
    this.#contentMetadata[CUSTOM_METADATA.STREAM_LOCATION] = this.#metadata[CUSTOM_METADATA.STREAM_LOCATION];

    return this.#contentMetadata;
  }

  setMetadata(key, newValue) {
    this.#metadata[key] = newValue;
  }

  getMetadata(key) {
    return this.#metadata?.[key];
  }

  reset() {
    this.#metadata = {};
    this.#contentMetadata = {};
  }
}

export class Timeout {
  #delay;
  #callback;
  #repeat;
  #timeoutOrIntervalId;
  #active;

  /**
   * Creates a new timeout callback handler.
   * @param {number} delay the delay in milliseconds after which the callback should be executed
   * @param {function} callback the callback to execute after the delay time
   * @param {boolean} repeat if true, call the callback repeatedly in delay intervals
   */
  constructor(delay, callback, repeat = false) {
    this.#delay = delay;
    this.#callback = callback;
    this.#repeat = repeat;
    this.#timeoutOrIntervalId = 0;
    this.#active = false;
  }

  /**
   * Starts the timeout and calls the callback when the timeout delay has passed.
   * @returns {Timeout} the current timeout (so the start call can be chained to the constructor)
   */
  start() {
    this.reset();
    return this;
  }

  /**
   * Clears the timeout. The callback will not be called if clear is called during the timeout.
   */
  clear() {
    this.clearInternal();
  }

  /**
   * Resets the passed timeout delay to zero. Can be used to defer the calling of the callback.
   */
  reset() {
    this.clearInternal();

    if (this.#repeat) {
      this.#timeoutOrIntervalId = setInterval(this.#callback, this.#delay);
    } else {
      this.#timeoutOrIntervalId = setTimeout(() => {
        this.#active = false;
        this.#callback();
      }, this.#delay);
    }
    this.#active = true;
  }

  isActive() {
    return this.#active;
  }

  clearInternal() {
    if (this.#repeat) {
      clearInterval(this.#timeoutOrIntervalId);
    } else {
      clearTimeout(this.#timeoutOrIntervalId);
    }
    this.#active = false;
  }
}
