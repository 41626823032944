import { combineReducers } from "redux";
import feedPageReducer from "../../pages/FeedPage/state/reducer";
import topNavReducer from "../../components/TopNav/state/reducer";
import moviesDetailsReducer from "../../pages/MovieDetailPage/state/reducer";
import seriesDetailsReducer from "../../pages/SeriesDetailPage/state/reducer";
import epgReducer from "../../pages/GuidePage/state/reducer";
import castReducer from "../../pages/CastDetailPage/state/reducer";
import viewAllReducer from "../../pages/ViewAllPage/state/reducer";
import svodPackageReducer from "../../pages/SvodViewAllPage/state/reducer";
import searchReducer from "../../pages/SearchPage/state/reducer";
import similarItemsReducer from "../../pages/SimilarItemsPage/state/reducer";
import subCategoryReducer from "../../pages/SubCategoryPage/state/reducer";
import episodeDetailsReducer from "../../pages/EpisodeDetailPage/state/reducer";
import onDemandPlayerReducer from "../../pages/PlayerPage/state/onDemandPlayerReducer";
import recordingPlayerReducer from "../../pages/PlayerPage/state/recordingPlayerReducer";
import recordingReducer from "../../pages/RecordingsPage/state/reducer";
import contentItemReducer from "../../pages/ContentItemDetailsPage/state/reducer";

import constants from "../../shared/constants";

const { CONTAINER_LAYOUTS, CONTENT_ITEM_TYPES, PAGE_CONTENT_ITEM_TYPES } = constants;

import {
  LOAD_CHANNELS,
  DISPLAY_TOP_NAV,
  LOAD_SUBSCRIBED_CHANNELS,
  DISPLAY_MODAL_POPUP,
  DISPLAY_TOAST_NOTIFICATION,
  LOAD_SUBSCRIBED_NETWORK,
  DISPLAY_PLAYBACK_ERROR,
  LOAD_ON_NOW_PROGRAMS,
  LOAD_USER_PROFILE,
  LOAD_USER_PROFILES,
  DISPLAY_RECORDING_SETTING,
  SET_CONVIVA_CONTENT_SUBTYPE,
  SET_API_REQUEST_LOADING,
  TOGGLE_SPINNING_LOADER,
  VIDEO_PLAYING,
  RELOAD_APP,
  TOGGLE_CHANNEL_SIDE_PANEL,
  FAVOURITE_CHANNELS,
  FAVOURITE_ASSETS,
  ADD_FAVOURITE,
  DELETE_FAVOURITE,
  RECORDING_USER_PROFILE,
  SEARCH_COLLECTION,
} from "./actions";

/**
 * A Reducer function for app level reductions
 * @param {object} state defines the state value
 * @param {*} action defines the action
 */
function appReducer(state = null, action) {
  switch (action.type) {
    case LOAD_CHANNELS:
      return { ...state, channelMapInfo: action.channelMapInfo };
    case LOAD_SUBSCRIBED_CHANNELS:
      return { ...state, subscribedChannels: action.subscribedChannelInfo };
    case DISPLAY_TOP_NAV:
      return { ...state, hideTopNav: action.status };
    case DISPLAY_MODAL_POPUP:
      return {
        ...state,
        modalPopup: {
          modalType: action.modalType,
          modalContent: action.modalContent,
        },
      };
    case DISPLAY_TOAST_NOTIFICATION:
      return {
        ...state,
        toastData: {
          message: action.message,
          icon: action.icon,
          analyticsInfo: action.analyticsInfo,
        },
      };
    case LOAD_SUBSCRIBED_NETWORK:
      return { ...state, subscribedNetworks: action.subscribedNetworks };
    case DISPLAY_PLAYBACK_ERROR:
      return {
        ...state,
        playbackError: {
          code: action.code,
          message: action.message,
        },
      };
    case LOAD_ON_NOW_PROGRAMS:
      return { ...state, onNowPrograms: action.programs };
    case LOAD_USER_PROFILE:
      return {
        ...state,
        userProfile: {
          ...action.userProfile,
          masterAccountHouseholdID: state?.userProfile?.masterAccountHouseholdID,
        },
      };
    case LOAD_USER_PROFILES:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          masterAccountHouseholdID: action.profiles.find((profile) => profile?.isMaster)?.username,
        },
        profileList: action.profiles,
      };
    case DISPLAY_RECORDING_SETTING:
      return { ...state, recordingSettingsPanelData: action.recordingSettingsPanelData };
    case SET_CONVIVA_CONTENT_SUBTYPE:
      return { ...state, convivaContentSubType: action.convivaContentSubType };
    case SET_API_REQUEST_LOADING:
      return { ...state, isRequestLoading: action.state };
    case TOGGLE_SPINNING_LOADER:
      return { ...state, spinningLoaderParams: action.spinningLoaderParams };
    case VIDEO_PLAYING:
      return { ...state, videoPlaying: action.status };
    case RELOAD_APP:
      return { ...state, reloadApp: action.status };
    case TOGGLE_CHANNEL_SIDE_PANEL:
      return { ...state, displayChannelSidePanel: action.displayChannelSidePanel };
    case FAVOURITE_CHANNELS:
      return { ...state, favouriteChannels: action.favouriteChannels };
    case FAVOURITE_ASSETS:
      return { ...state, favouriteAssets: action.favouriteAssets };
    case ADD_FAVOURITE:
      const { content: isAdded, newFavouriteItem } = action || {};
      const { metadata, layout } = newFavouriteItem || {};

      const isChannelToAdd = metadata?.contentType === PAGE_CONTENT_ITEM_TYPES.live;
      const isAssetToAdd =
        layout?.toLowerCase() === CONTAINER_LAYOUTS.CONTENT_ITEM ||
        layout?.toLowerCase() === CONTAINER_LAYOUTS.BUNDLE_ITEM;

      return {
        ...state,
        isAddedToFavourites: isAdded,
        favouriteChannels: isChannelToAdd ? [newFavouriteItem, ...state.favouriteChannels] : state.favouriteChannels, // add channel to favourite channels cache
        favouriteAssets: isAssetToAdd ? [newFavouriteItem, ...state.favouriteAssets] : state.favouriteAssets, // add asset to favourite assets cache
      };
    case DELETE_FAVOURITE:
      const { content: isDeleted, itemLayout, itemId } = action || {};

      const isChannelToDelete = itemLayout === CONTAINER_LAYOUTS.CHANNEL_ITEM;
      const isAssetToDelete =
        itemLayout === CONTAINER_LAYOUTS.CONTENT_ITEM || itemLayout === CONTAINER_LAYOUTS.BUNDLE_ITEM;

      // method to filter assets based on ua group type
      const filterFavouriteAssets = (item) => {
        const extendedMetadata = item.metadata?.extendedMetadata?.dlum;
        const groupType = extendedMetadata?.uaGroupType?.toLowerCase();

        return groupType === CONTENT_ITEM_TYPES.tvshow
          ? extendedMetadata?.uaGroupId !== itemId
          : extendedMetadata?.uaId !== itemId;
      };

      return {
        ...state,
        isAddedToFavourites: isDeleted,
        favouriteChannels: isChannelToDelete
          ? state.favouriteChannels.filter((item) => item.id !== itemId) // remove channel from favourite channels cache
          : state.favouriteChannels,
        favouriteAssets: isAssetToDelete ? state.favouriteAssets.filter(filterFavouriteAssets) : state.favouriteAssets, // remove asset from favourite assets cache
      };
    case RECORDING_USER_PROFILE:
      return { ...state, recordingProfile: action.recordingProfile };
    case SEARCH_COLLECTION:
      return { ...state, searchCollection: action.searchCollection };
    default:
      return state;
  }
}

export default combineReducers({
  feed: feedPageReducer,
  app: appReducer,
  topNav: topNavReducer,
  movieDetails: moviesDetailsReducer,
  seriesDetails: seriesDetailsReducer,
  epg: epgReducer,
  castDetails: castReducer,
  viewAll: viewAllReducer,
  svodPackage: svodPackageReducer,
  search: searchReducer,
  similarItems: similarItemsReducer,
  subCategory: subCategoryReducer,
  episodeDetail: episodeDetailsReducer,
  onDemandPlayer: onDemandPlayerReducer,
  recordingPlayer: recordingPlayerReducer,
  recording: recordingReducer,
  contentItem: contentItemReducer,
});
