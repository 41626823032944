import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ViewAll from "../../components/ViewAll";
import { convertTitleItemsListToSwimLaneModel } from "../../shared/utils/swimlane";
import swimlaneConstants from "../../shared/constants/swimlane";
import {
  getPcLevelRestriction,
  capitalize,
  createDropdownItemLabelObj,
  getDefaultFilterItems,
} from "../../shared/utils";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import middleware from "../../shared/middleware";
import paginationConstants from "../../shared/constants/pagination";
import searchConstants from "../../shared/constants/search";
import SortDropdown from "../../components/SortDropdown";
import ViewAllFilterDropdown from "../../components/ViewAllFilterDropdown";
import useTrackPageView from "../../shared/hooks/useTrackPageView";

const { ITEM_TYPES } = swimlaneConstants;
const dimension = ITEM_TYPES.TITLE_ITEM.PORTRAIT.DIMENSIONS.values;
const { getSearchContent } = middleware;
const { INITIAL_START_INDEX, INITIAL_END_INDEX_VIEW_ALL } = paginationConstants;
const { SEARCH_ORDER_PARAM, SEARCH_ORDER_OPTIONS, SEARCH_FILTER_OPTIONS } = searchConstants;

/**
 * Search view all page component
 *
 * @component
 * @param {Object} props
 */
const SearchViewAllPage = (props) => {
  const { appProvider, content, userProfile, featureToggles } = props;
  const { isUserProfilesEnabled, isParentalPINEnabled } = featureToggles;
  const [showViewAll, setShowViewAll] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // boolean to show spinning loader

  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const feedName = query.get("title");
  const searchTerm = capitalize(query.get("searchTerm"));
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const total = useRef(null);
  const { trackPageView } = useTrackPageView();

  const pcLevel = getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled);

  const filterData = useMemo(() => {
    if (userProfile?.isLoggedIn) {
      return SEARCH_FILTER_OPTIONS;
    } else {
      // Remove subscribed content from filters if user is not logged in
      return SEARCH_FILTER_OPTIONS.map((filterOption) => {
        if (filterOption.key === "content_filters") {
          return {
            ...filterOption,
            values: filterOption.values.filter((contentOption) => contentOption.value !== "subscribed"),
          };
        } else {
          return filterOption;
        }
      });
    }
  }, [userProfile]);
  const initialFilterItems = getFilterItemsFromUrl(query);
  const initialFilterDropdownState = useRef(initialFilterItems);
  const [filterParams, setFilterParams] = useState(convertFilterItemsToString(initialFilterItems));
  const prevFilterParams = useRef("");

  const initialSortItem = getSortItemFromUrl(query.get(SEARCH_ORDER_PARAM)) ?? SEARCH_ORDER_OPTIONS[0];
  const [sortParams, setSortParams] = useState(initialSortItem.value);
  const prevSortParams = useRef("");

  useEffect(() => {
    trackPageView({ feedName });
  }, [trackPageView, feedName]);

  useEffect(() => {
    const fetchSearchItems = async () => {
      try {
        const feedContent = await getSearchContent(
          appProvider,
          searchTerm,
          userProfile,
          pcLevel,
          filterParams,
          sortParams,
          INITIAL_START_INDEX,
          INITIAL_END_INDEX_VIEW_ALL,
          feedName
        );
        if (feedContent) {
          total.current = feedContent.total;
          setItemsList(
            convertTitleItemsListToSwimLaneModel(
              feedContent.containers?.filter((item) => item?.layout && item?.metadata),
              dimension,
              isAppLanguageFrench,
              userProfile
            )
          );
          setShowViewAll(true);
        }
      } catch (error) {
        console.error("Failed to retrieve search items", error);
      }
    };
    if (!content || filterParams !== prevFilterParams.current || sortParams !== prevSortParams.current) {
      fetchSearchItems();
      prevFilterParams.current = filterParams;
      prevSortParams.current = sortParams;
    }
    content !== null && setShowViewAll(true);
    if (content) {
      for (const feed of content) {
        const { feedTitleKey } = feed;
        if (translate(feedTitleKey) === feedName) {
          const { feedData, totalItems } = feed;
          setItemsList(convertTitleItemsListToSwimLaneModel(feedData, dimension, isAppLanguageFrench, userProfile));
          total.current = totalItems;
        }
      }
    }
  }, [
    appProvider,
    content,
    feedName,
    isAppLanguageFrench,
    searchTerm,
    userProfile,
    translate,
    pcLevel,
    filterParams,
    sortParams,
  ]);

  const loadNextSetOfItems = async (startIndex, endIndex, filterParams, sortParams) => {
    setIsLoading(true);
    try {
      const feedContent = await getSearchContent(
        appProvider,
        searchTerm,
        userProfile,
        pcLevel,
        filterParams,
        sortParams,
        startIndex,
        endIndex,
        feedName
      );
      if (feedContent) {
        setItemsList((itemsList) => [
          ...itemsList,
          ...convertTitleItemsListToSwimLaneModel(
            feedContent.containers?.filter((item) => item?.layout && item?.metadata),
            dimension,
            isAppLanguageFrench,
            userProfile
          ),
        ]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to retrieve search items", error);
    }
  };

  return showViewAll ? (
    <div className="view-all-wrapper">
      <div className="sort-filters">
        <ViewAllFilterDropdown
          filterList={filterData}
          applyButtonHandler={setFilterParams}
          resetButtonHandler={setFilterParams}
          defaultFilterParams={createDropdownItemLabelObj(getDefaultFilterItems(filterData), isAppLanguageFrench)}
          initialParamsState={initialFilterDropdownState.current}
        />
        <SortDropdown
          sortOptionList={[
            {
              label: translate("sort_by"),
              values: SEARCH_ORDER_OPTIONS,
            },
          ]}
          applyButtonHandler={setSortParams}
          defaultSortParams={{ 0: SEARCH_ORDER_OPTIONS[0].label }}
          initialParamsState={{ 0: initialSortItem.label }}
        />
      </div>
      <ViewAll
        title={feedName}
        items={itemsList}
        searchTerm={query.get("searchTerm")}
        totalContent={total.current}
        loadNextSetOfItems={loadNextSetOfItems}
        filterParams={filterParams}
        sortParams={sortParams}
        isLoading={isLoading}
      />
    </div>
  ) : (
    <></>
  );
};

/**
 * Returns the selected search order option based on the searchOrder param in the URL
 * @param {String} searchOrderValue
 * @returns {Object}
 */
const getSortItemFromUrl = (searchOrderValue) => {
  return SEARCH_ORDER_OPTIONS.find((item) => item.value === `${SEARCH_ORDER_PARAM}=${searchOrderValue}`) ?? null;
};

/**
 * Creates an object using the filter option values from the given query parameter values
 * @param {URLSearchParams} queryParams
 * @returns {Object}
 */
const getFilterItemsFromUrl = (queryParams) => {
  return Object.assign(
    {},
    SEARCH_FILTER_OPTIONS.map((filterOption) => {
      const urlParamKey = filterOption.key === "language_filters" ? "filter_language" : filterOption.key;
      const urlParamValue = queryParams.get(urlParamKey)?.toLowerCase();

      return (
        filterOption.values.find((option) => option.value.toLowerCase().includes(urlParamValue))?.label ??
        filterOption.values[0].label
      );
    })
  );
};

/**
 * Converts a filter items object into query string parameters
 * @param {Object} filterParams
 * @returns {String}
 */
const convertFilterItemsToString = (filterItems) => {
  const filterParams = SEARCH_FILTER_OPTIONS.map((filterOption, index) => {
    if (filterItems[index] === filterOption.values[0].label) {
      return "";
    } else {
      const selectedOption = filterOption.values.find((option) => filterItems[index] === option.label);
      if (filterOption.key === "language_filters") {
        return selectedOption.value;
      } else {
        return `${filterOption.key}=${selectedOption.value}`;
      }
    }
  }).filter((item) => item); // Remove blank params

  return filterParams.join("&");
};

SearchViewAllPage.propTypes = {
  appProvider: PropTypes.object.isRequired,
  content: PropTypes.array,
  userProfile: PropTypes.object,
  featureToggles: PropTypes.object,
};

SearchViewAllPage.defaultProps = {
  content: [],
};

function mapStateToProps({ app, search }) {
  return {
    appProvider: app.provider,
    content: search.content,
    userProfile: app.userProfile,
    featureToggles: app.featureToggles,
  };
}

export default connect(mapStateToProps)(SearchViewAllPage);
