import constants from "../constants";
import storageConstants from "../constants/storage";
import request from "../../service/request";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../utils/localStorage";
import error from "../../shared/constants/error";

const {
  APP_ENVIRONMENTS,
  API_BASE_URL,
  MAX_ACCOUNT_PROFILES,
  CASSANDRA_KEEPALIVE_INTERVAL,
  USER_SESSION_RENEWAL_INTERVAL,
} = constants;

const { AVS_REFRESH_TOKEN, USER_PROFILE } = storageConstants;

const { AVS_ERROR_CODES } = error;

const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

/* eslint-disable no-unused-vars */

const checkProfileResponse = (res, appProvider, avsRefreshToken) => {
  // Pull store user profile json
  let userProfile = getLocalStorage(USER_PROFILE);

  // If cassandra is down based on the error codes from
  // devicesessions, we should user the cache user profile
  if (
    res.data?.resultCode === "KO" &&
    (res.data?.errorDescription === AVS_ERROR_CODES.INVALID_STREAM_SESSION ||
      res.data?.errorDescription === AVS_ERROR_CODES.INTERNAL_SERVER_ERROR ||
      res.data?.errorDescription === AVS_ERROR_CODES.CASSANDRA_OUTAGE ||
      res.data?.errorDescription === AVS_ERROR_CODES.MS_OUTAGE ||
      res.data?.errorDescription === AVS_ERROR_CODES.SERVICE_OUTAGE_CODES[0])
  ) {
    if (userProfile?.length) {
      userProfile = JSON.parse(userProfile);
    }

    return {
      keepAlive: CASSANDRA_KEEPALIVE_INTERVAL,
      profile: createUserProfile(userProfile?.length ? true : false, userProfile, null),
    };
  } else if (
    res.data?.errorDescription === AVS_ERROR_CODES.TOKEN_NOT_VALID ||
    res.data?.errorDescription === AVS_ERROR_CODES.TOKEN_NOT_VALID_2 ||
    res.data?.errorDescription === AVS_ERROR_CODES.USER_NOT_LOGIN
  ) {
    removeLocalStorage(AVS_REFRESH_TOKEN);
    return {
      keepAlive: CASSANDRA_KEEPALIVE_INTERVAL,
      profile: createUserProfile(false, userProfile, null),
    };
  } else {
    return returnAVSUserProfile(appProvider, avsRefreshToken)
      .then((profile) => {
        // Storing userprofile to local storage for future usage
        setLocalStorage(USER_PROFILE, JSON.stringify(profile));

        return {
          keepAlive: res.data.resultObj.keepAlive,
          profile: createUserProfile(true, profile, null),
        };
      })
      .catch(() => {
        return {
          keepAlive: res.data.resultObj.keepAlive,
          profile: createUserProfile(false, null, null),
        };
      });
  }
};

/* Process User Profile **/
export function getUserProfile(appProvider, authUserContent) {
  let avsRefreshToken = getLocalStorage(AVS_REFRESH_TOKEN);
  if (avsRefreshToken !== null) {
    return renewUserSession(appProvider, avsRefreshToken)
      .then((res) => {
        return checkProfileResponse(res, appProvider, avsRefreshToken);
      })
      .catch((res) => {
        return checkProfileResponse(res, appProvider, avsRefreshToken);
      });
  } else {
    avsRefreshToken = authUserContent?.headers[AVS_REFRESH_TOKEN];
    return returnAVSUserProfile(appProvider, avsRefreshToken)
      .then((profile) =>
        renewUserSession(appProvider, avsRefreshToken)
          .then((res) => {
            return {
              keepAlive: res.data.resultObj.keepAlive,
              profile: createUserProfile(true, profile, null),
            };
          })
          .catch((res) => {
            return checkProfileResponse(res, appProvider, avsRefreshToken);
          })
      )
      .catch((error) => {
        handleLoginFailure(error);
      });
  }
}

// /* Extend User Session **/
export function extendUserSession(appProvider, avsRefreshToken = null) {
  return renewUserSession(appProvider, avsRefreshToken)
    .then((res) => res)
    .catch((err) => err);
}

// HELPERS //

/**
 * Creates a User profile object
 * @param Boolean login status
 * @param User user object
 * @param String Access token for the user
 */
function createUserProfile(isLoggedIn, user, accessToken, errorInfo = null) {
  return { isLoggedIn, user, accessToken, errorInfo, masterAccountHouseholdID: null };
}

function handleLoginFailure(error) {
  if (error && error.type && error.type === "browser") {
    return {
      keepAlive: USER_SESSION_RENEWAL_INTERVAL,
      profile: createUserProfile(false, null, null),
    };
  }
}

/**
 * Promise Handler - Return AVS user profile
 */
function returnAVSUserProfile(appProvider, avsRefreshToken = null) {
  let url = appProvider.config.avs_api_urls.user_profile;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url: url,
    method: "GET",
    withCredentials: true,
  };

  if (avsRefreshToken !== null) {
    options.headers = {};
    options.headers[AVS_REFRESH_TOKEN] = avsRefreshToken;
  }

  return request(options, null, appProvider);
}

/**
 * Update profile details
 * @param {Object} appProvider
 * @param {Object} profileParams
 * @returns {Promise}
 */
export function updateProfile(appProvider, profileParams) {
  let url = appProvider.config.avs_api_urls.user_profile;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
    data: profileParams,
  };

  return request(options, null, appProvider);
}

/**
 * Create a new profile (can only be used to create sub-account profiles)
 * @param {Object} appProvider
 * @param {Object} profileParams
 * @param {Object} cancelTokenSource CancelToken - deprecated in v0.22.0, will have to update to AbortController if we update Axios in the future
 * @returns {Promise}
 */
export function createProfile(appProvider, profileParams, cancelTokenSource = null) {
  let url = appProvider.config.avs_api_urls.subaccount_profile;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
    data: profileParams,
  };

  return request(options, cancelTokenSource, appProvider);
}

/**
 * Get list of profiles
 * @param {Object} appProvider
 * @param {Object} cancelTokenSource CancelToken - deprecated in v0.22.0, will have to update to AbortController if we update Axios in the future
 * @returns {Promise}
 */
export function getUserProfileList(appProvider, cancelTokenSource = null) {
  let url = appProvider.config.avs_api_urls.list_profiles;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url,
    method: "GET",
    withCredentials: true,
  };

  return request(options, cancelTokenSource, appProvider).then((result) => {
    return result?.familyAccountList?.filter((profile) => profile.status === "Active")?.slice(0, MAX_ACCOUNT_PROFILES);
  });
}

/**
 * Get list of available avatars
 * @param {Object} appProvider
 * @param {Object} cancelTokenSource CancelToken - deprecated in v0.22.0, will have to update to AbortController if we update Axios in the future
 * @returns {Promise}
 */
export function getAvatarList(appProvider, cancelTokenSource = null) {
  let url = appProvider.config.avs_api_urls.list_avatars;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url,
    method: "GET",
    withCredentials: true,
  };

  return request(options, cancelTokenSource, appProvider);
}

/**
 * Delete a profile
 * @param {Object} appProvider
 * @param {Number} userId
 * @returns {Promise}
 */
export function deleteProfile(appProvider, userId) {
  let url = appProvider.config.avs_api_urls.user_profile;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = `${url}/${userId}`;

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url,
    method: "DELETE",
    withCredentials: true,
  };

  return request(options, null, appProvider);
}

/**
 * Log in to subaccount profile
 * @param {Object} appProvider
 * @param {Number} userId
 * @param {String} entryPin
 * @returns {Promise}
 */
export function loginProfile(appProvider, userId, entryPin = null) {
  let url = appProvider.config.avs_api_urls.subaccount_login;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const data = { userId };
  if (entryPin) {
    data.entryPin = entryPin;
  }

  const options = {
    url,
    method: "POST",
    withCredentials: true,
    returnResponseObject: true,
    data,
  };

  const avsRefreshToken = getLocalStorage(AVS_REFRESH_TOKEN);
  if (avsRefreshToken !== null) {
    options.headers = {
      [AVS_REFRESH_TOKEN]: avsRefreshToken,
    };
  }

  return request(options, null, appProvider).then((response) => {
    return response.data.resultObj;
  });
}

/**
 * Log out from subaccount profile
 * @param {Object} appProvider
 * @returns {Promise}
 */
export function logoutProfile(appProvider) {
  let url = appProvider.config.avs_api_urls.subaccount_logout;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url,
    method: "DELETE",
    withCredentials: true,
    returnResponseObject: true,
  };

  const avsRefreshToken = getLocalStorage(AVS_REFRESH_TOKEN);
  if (avsRefreshToken !== null) {
    options.headers = {
      [AVS_REFRESH_TOKEN]: avsRefreshToken,
    };
  }

  return request(options, null, appProvider).then((response) => {
    return response.data.resultObj;
  });
}

/**
 * Promise Handler - Returns user session cookies are valid or not
 */
function renewUserSession(appProvider, avsRefreshToken = null) {
  let url = appProvider.config.avs_api_urls.user_session_renewal;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
    withCredentials: true,
    returnResponseObject: true,
  };

  if (avsRefreshToken !== null) {
    options.headers[AVS_REFRESH_TOKEN] = avsRefreshToken;
  }

  return request(options, null, appProvider);
}

/**
 * Video stream heartbeat request that also fetches content entitlements. The pcPin parameter can be
 * used to unlock Parental Control blocked content.
 * @param {Object} appProvider
 * @param {String} contentType
 * @param {String|Number} contentId
 * @param {String|Number} assetId
 * @param {String} pcPin Optional Parental Control PIN
 * @param {Object} cancelTokenSource CancelToken - deprecated in v0.22.0, will have to update to AbortController if we update Axios in the fugure
 * @param {String} avsRefreshToken
 * @returns {Promise}
 */
export function videoStreamHeartBeat(
  appProvider,
  contentType,
  contentId,
  assetId,
  pcPin = null,
  cancelTokenSource = null,
  avsRefreshToken = null
) {
  let url = appProvider.config.avs_api_urls.video_heart_beat;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$CONTENT_TYPE", contentType);
  url = url.replace("$CONTENT_ID", contentId);
  url = url.replace("$ASSET_ID", assetId);
  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const headers = { "Content-Type": "application/json" };
  if (pcPin) {
    headers.pcpin = pcPin;
  }

  const options = {
    url,
    method: "POST",
    headers,
    data: {},
    withCredentials: true,
    returnResponseObject: true,
  };

  if (avsRefreshToken !== null) {
    options.headers = {
      [AVS_REFRESH_TOKEN]: avsRefreshToken,
    };
  }

  return request(options, cancelTokenSource, appProvider);
}
