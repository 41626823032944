const DATA_ENV = process.env.REACT_APP_DATA_ENV;

export default Object.freeze({
  PLAYER_TYPE: {
    LINEAR: "linear",
    LIVE: "live",
    VOD: "vod",
    RECORDING: "recording",
  },
  STREAM_MODE: {
    LIVE: "IMC_MODE_LIVE",
    VOD: "IMC_MODE_ADAPTIVE",
  },
  PLAYER_LOG_LEVELS: {
    ERROR: "IMC_LOG_ERROR",
    INFO: "IMC_LOG_INFO",
    DEBUG: "IMC_LOG_DEBUG",
    WARN: "IMC_LOG_WARN",
    NONE: "IMC_LOG_NONE",
  },
  PLAYBACK_TYPES: {
    MOVIE: "movie",
    TRAILER: "trailer",
    EPISODE: "episode",
  },
  SUPPORTED_PLAYBACK_ENCODING: ["Jitp", "hls"],
  PLAYER_CONTROLS: {
    STOP: "stop",
    PLAY: "play",
    RESUME: "resume",
    PAUSE: "pause",
    GET_NEXT_LIVE_PROGRAM: "GET_NEXT_LIVE_PROGRAM",
    PLAY_COMPLETED: "PLAY_COMPLETED",
    VOLUME: "volume",
    MUTE: "mute",
    CLOSE: "close",
  },
  NON_FATAL_ERROR_CODES: [
    219,
    222,
    224,
    225,
    226,
    227,
    228,
    229,
    230,
    231,
    50014,
    50018,
    50070,
    60001,
    99999, // Catch all error code
  ],
  PLAYER_ERROR_CODES: [1203, 1201, 2003],
  PLAYER_CONCURRENT_STREAM_ERROR: "concurrent stream limit reached",
  USER_HAS_NO_RIGHTS: "user has no rights",
  CONNECTION_ERROR_CODES: [100, 102, 103, 104, 105],
  STREAM_LIMIT_EXCEEDED_ERROR_CODES: [2413],
  NO_BROWSER_SUPPORT_ERROR_CODES: [2006],
  AVS_ERROR_CODES: [100001],
  PLAYER_STREAM_CONFIG: {
    live: {
      streamURL: {
        hls: "https://vxoalottdevopus.cdn.avp.teluslabs.net/$CONTENTID/vxfmt=dp/playlist.m3u8?device_profile=hlsvmxfps",
        dash: "https://vxoalottdevopus.cdn.avp.teluslabs.net/$CONTENTID/vxfmt=dp/manifest.mpd?device_profile=dashvmx",
      },
      licenseURL: {
        hls: "https://multidrm.core.verimatrixcloud.net/fairplay",
        dash: "https://multidrm.core.verimatrixcloud.net/widevine",
      },
      licenseCertUrl: {
        hls: "/libs/fairplay.cer",
      },
    },
    vod: {
      streamURL: {
        hls: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/shls/$CONTENTID/index.m3u8?device=VMX_CORE_HLS",
        dash: "https://vod-browser-tlx-b.cdn.avp.teluslabs.net/sdash/$CONTENTID/index.mpd/Manifest?device=VMX_CORE_DASH",
      },
      licenseURL: {
        hls: "https://multidrm.core.verimatrixcloud.net/fairplay",
        dash: "https://multidrm.core.verimatrixcloud.net/widevine",
      },
      licenseCertUrl: {
        hls: "/libs/fairplay.cer",
      },
    },
  },
  STREAM_FORMAT_TYPES: {
    HLS: "hls",
    DASH: "dash",
  },
  DRM_SYSTEM_TYPES: {
    WV: "widevine",
    PR: "playready",
    FP: "fairplay",
  },
  ASSET_TYPES: {
    TRAILER: "trailer",
    MASTER: "master",
  },
  BINGE_WATCH_TIMER: 20,
  BINGE_WATCH_CARD_THRESHOLD: 98,
  STILL_WATCHING_COUNTDOWN_TIMER: 120, // Automatically close the player after 2 minutes
  PLAYER_NAME: "Bitmovin",
  PLAYER_SDK_VERSION: "8.90.0",
  DRM_LICENSE_FAIRPLAY: "drm/license/fairplay",
  BOOKMARK_UPDATE_INTERVAL_TIMER: 120000, // Call update bookmark call in every 2 minutes
  BOOKMARK_TITLE: "LastViewPoint", // static bookmark title used to create bookmark
  DRM_LICENSE_WIDEVINE: "drm/license/widevine",
  PLAYBACK_FORWARD_BUFFER: 40,
  CAST_FRAMEWORK_URL: "//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1",
});
