import styled from "styled-components";
import { Link } from "react-router-dom";

const PlayerSidebarWrapper = styled.div(
  ({ theme }) => `
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: ${theme.colours.focusedRecordingButtonBackground};
  display: flex;
  flex-flow: column;
  `
);

const PlayerSidebarHideButtonWrapper = styled.div(
  () => `
  margin-top: 40px;
  text-align: right;
  margin-right: 40px;
  `
);

const PlayerSidebarRoute = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colours.white};
  font-size: ${({ theme }) => theme.typography.mediumFontSize};
  line-height: 1.4;
  text-decoration: none;
  margin: 0 8px 0 0;
  & button {
    margin-left: 5px;
    margin-top: 5px;
    & img {
      height: 12px;
      width: 8px;
    }
  }
`;
const PlayerSidebarAnchor = styled.a(
  ({ theme }) => `
  color: ${theme.colours.white};
  text-decoration: none;
  `
);

export { PlayerSidebarWrapper, PlayerSidebarHideButtonWrapper, PlayerSidebarRoute, PlayerSidebarAnchor };
