import getAppProvider from "./appProvider";
import {
  getUserProfile,
  extendUserSession,
  videoStreamHeartBeat,
  getUserProfileList,
  getAvatarList,
  updateProfile,
  createProfile,
  deleteProfile,
  loginProfile,
  logoutProfile,
} from "./userProfile";
import getHubs from "./hubs";
import { getChannels, getChannelPrograms, getChannelDetails, getChannelById, getSubscribedChannels } from "./epg";
import { getProgramDetail, getRecordingProgramDetail } from "./program";
import { getUserProfileRecording } from "./recordings";
import { getSeriesDetail } from "./series";
import getCastDetail from "./cast";
import getSvodItems from "./svodItems";
import { getSearchContent } from "./search";
import { getSeasonDetails, getSeasonData } from "./seasonDetail";
import { getSimilarItems } from "./similarItems";
import getSubCategoryPageItems from "./subCategory";
import { addToFavourites, removeFromFavourites } from "./favourites";
import { getProgramSchedules } from "./schedules";
import { bookmarkAVSVideo, createBookmark, updateBookmark } from "./bookmark";
import getConfig from "./config";
import retrieveAvsItems from "./retrieveAvsItems";
import { authenticateUser } from "./login";
import { logout } from "./logout";
import getContentUserData from "./contentUserData";
import { fetchVideoUrl, fetchVideoToken } from "./videoContent";
import {
  setParentalPINAvailability,
  updateParentalPIN,
  validateParentalPIN,
  updateParentalControlRatingLevel,
} from "./parentalControl";
import makePurchase, { setPurchasePINAvailability, updatePurchasePIN, validatePurchasePIN } from "./purchase";
import { getNextProgram } from "./getNextProgram";
import getFavourites from "./fetchFavourites";

const middleware = {
  getAppProvider,
  getUserProfile,
  getUserProfileList,
  getAvatarList,
  updateProfile,
  createProfile,
  deleteProfile,
  loginProfile,
  logoutProfile,
  getHubs,
  getChannels,
  getChannelPrograms,
  getChannelDetails,
  getProgramDetail,
  getRecordingProgramDetail,
  getSeriesDetail,
  getCastDetail,
  getSvodItems,
  getSearchContent,
  getSeasonDetails,
  getSimilarItems,
  getSubCategoryPageItems,
  addToFavourites,
  removeFromFavourites,
  makePurchase,
  getProgramSchedules,
  getChannelById,
  getSubscribedChannels,
  getConfig,
  retrieveAvsItems,
  authenticateUser,
  logout,
  getContentUserData,
  fetchVideoUrl,
  fetchVideoToken,
  extendUserSession,
  getSeasonData,
  videoStreamHeartBeat,
  bookmarkAVSVideo,
  createBookmark,
  updateBookmark,
  setParentalPINAvailability,
  updateParentalPIN,
  validateParentalPIN,
  setPurchasePINAvailability,
  updatePurchasePIN,
  validatePurchasePIN,
  updateParentalControlRatingLevel,
  getNextProgram,
  getFavourites,
  getUserProfileRecording,
};

export default middleware;
