import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { trackGenericAction } from "../../shared/analytics/dataLayer";
import { ANALYTICS_EVENT_TYPES } from "../../shared/constants/analytics";
import constants from "../../shared/constants/index";
import "./style.scss";

const RedirectPIK = () => {
  const { t: translate } = useTranslation();
  const desc = translate("migration_browser_telustvplus").replace(/\n\n\n/g, "\n\n");

  const trackPikMigration = () => {
    trackGenericAction(ANALYTICS_EVENT_TYPES.EXIT_CLICK, {
      name: "pik_migration",
      URL: constants.TELUS_TV_PLUS,
    });
  };

  useEffect(() => {
    trackGenericAction(ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS, { message: translate("pik_migration") });
  }, [translate]);

  return (
    <div className="container">
      <div className="form-wrapper">
        <div className="heading">{translate("pik_migration")}</div>
        <div className="description">
          {desc.split("<link>").map((item, index) => {
            if (index === 0) {
              return item;
            }
            return (
              <>
                <a href={constants.TELUS_TV_PLUS} className="link" onClick={trackPikMigration}>
                  {translate("telustvplus_url")}
                </a>
                {item}
              </>
            );
          })}
        </div>
        <button
          onClick={() => {
            trackPikMigration();
            window.location.replace(constants.TELUS_TV_PLUS);
          }}
        >
          {translate("migration_browser_goto_telustvplus")}
        </button>
      </div>
    </div>
  );
};
export default RedirectPIK;
