import React from "react";
import { useDispatch } from "react-redux";

import VideoPlayerSideBar from "./VideoPlayerSideBar";

const Index = (props) => {
  const dispatch = useDispatch();

  return <VideoPlayerSideBar dispatch={dispatch} {...props} />;
};

export default Index;
