import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import "./style.scss";

/**
 * Text input component
 * @component
 * @param {Object} props
 */
const TextInput = forwardRef(function TextInput({ className = "", errorMessage, ...rest }, ref) {
  return (
    <div className="text-input-container">
      <input type="text" className={`text-input ${errorMessage ? "invalid" : ""} ${className}`} ref={ref} {...rest} />
      {errorMessage ? <span className="text-input-error">{errorMessage}</span> : null}
    </div>
  );
});

TextInput.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default TextInput;
