import React from "react";
import "./style.scss";

const Button = React.forwardRef(({ buttonStyles, label, onClickHandler, tabIndex }, ref) => {
  return (
    <button ref={ref} className={buttonStyles} onClick={onClickHandler} tabIndex={tabIndex}>
      {label}
    </button>
  );
});

export default Button;
