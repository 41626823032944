import middleware from "../../../shared/middleware";
import constants from "../../../shared/constants";
import paginationConstants from "../../../shared/constants/pagination";

import { processDemandOptions, getUpdatedOnDemandOptions, processLiveOptions } from "../../../shared/utils/detailsPage";

const { getSimilarItems, getContentUserData, retrieveAvsItems } = middleware;

const { CONTAINER_TYPES, PAGE_CONTENT_ITEM_TYPES } = constants;
const { INITIAL_START_INDEX, INITIAL_END_INDEX } = paginationConstants;

// Action types
export const LOAD_PROGRAM_DETAIL = "LOAD_PROGRAM_DETAIL";
export const LOAD_SIMILAR_CONTENT_ITEMS = "LOAD_SIMILAR_CONTENT_ITEMS";
export const LOAD_SCHEDULES = "LOAD_SCHEDULES";
export const LOAD_CONTENT_USERDATA = "LOAD_CONTENT_USERDATA";
export const LOAD_CONTENT_ITEM_PAGE_CONTAINERS = "LOAD_CONTENT_ITEM_PAGE_CONTAINERS";
export const LOAD_ON_DEMAND_OPTIONS = "LOAD_ON_DEMAND_OPTIONS";
export const UPDATE_ON_DEMAND_OPTIONS = "UPDATE_ON_DEMAND_OPTIONS";

// Action methods
function loadPageContainersSuccess(content) {
  return { type: LOAD_CONTENT_ITEM_PAGE_CONTAINERS, content };
}
function loadPageContainersFailure() {
  return { type: LOAD_CONTENT_ITEM_PAGE_CONTAINERS, content: [] };
}
function loadProgramSuccess(content) {
  return { type: LOAD_PROGRAM_DETAIL, content };
}
function loadProgramFailure() {
  return { type: LOAD_PROGRAM_DETAIL, content: {} };
}
function loadSimilarItemsSuccess(content) {
  return { type: LOAD_SIMILAR_CONTENT_ITEMS, content: content };
}
function loadSimilarItemsFailure(error) {
  return { type: LOAD_SIMILAR_CONTENT_ITEMS, content: error };
}
function loadSchedulesSuccess(content, regionId) {
  return { type: LOAD_SCHEDULES, content, regionId };
}
function loadSchedulesFailure() {
  return { type: LOAD_SCHEDULES, content: [] };
}
function loadOnDemandOptionsSuccess(content) {
  return { type: LOAD_ON_DEMAND_OPTIONS, content };
}
function loadOnDemandOptionsFailure() {
  return { type: LOAD_ON_DEMAND_OPTIONS, content: [] };
}

function loadContentUserDataSuccess(content) {
  return { type: LOAD_CONTENT_USERDATA, content };
}

function loadContentUserDataFailure() {
  return { type: LOAD_CONTENT_USERDATA, content: {} };
}

export const updateOnDemandOptions = (purchasedContentItemUserData) => {
  return (dispatch, getState) => {
    const {
      onDemandOptions: { onDemandContent },
      contentUserData,
    } = getState().contentItem;
    // updating on demand options on purchase of a movie
    const updatedWatchOptions = getUpdatedOnDemandOptions(
      onDemandContent,
      contentUserData,
      purchasedContentItemUserData
    );
    dispatch({ type: UPDATE_ON_DEMAND_OPTIONS, content: { ...updatedWatchOptions, progressTime: null } });
  };
};

export const loadSimilarItems = (
  appProvider,
  itemType,
  itemGenre,
  itemSubType = null,
  userProfile = null,
  pcLevel = null,
  cancelTokenSource = null
) => {
  return async (dispatch) => {
    try {
      const feedContent = await getSimilarItems(
        appProvider,
        itemType,
        itemGenre,
        itemSubType,
        userProfile,
        pcLevel,
        cancelTokenSource,
        INITIAL_START_INDEX,
        INITIAL_END_INDEX
      );
      if (feedContent) {
        dispatch(loadSimilarItemsSuccess(feedContent));
      }
    } catch (error) {
      dispatch(loadSimilarItemsFailure(error));
    }
  };
};

export const loadContentUserData = (appProvider, itemId, itemType = null) => {
  return async (dispatch) => {
    try {
      const feedContent = await getContentUserData(appProvider, itemId, itemType);
      if (feedContent) {
        if (feedContent.containers && feedContent.containers.length > 0) {
          dispatch(loadContentUserDataSuccess(feedContent.containers[0]));
        }
      }
    } catch (error) {
      dispatch(loadContentUserDataFailure());
    }
  };
};

export const loadContentItemDetailPageItems = (appProvider, actionUri, contentItem, cancelTokenSource) => {
  let successAction;
  let failureAction;
  switch (contentItem) {
    case CONTAINER_TYPES.CONTAINERS:
      successAction = loadPageContainersSuccess;
      failureAction = loadPageContainersFailure;
      break;
    case CONTAINER_TYPES.CONTENT:
      successAction = loadProgramSuccess;
      failureAction = loadProgramFailure;
      break;
    case CONTAINER_TYPES.SCHEDULES:
      successAction = (containers) => loadSchedulesSuccess(containers, appProvider.channelMapID);
      failureAction = loadSchedulesFailure;
      break;
    case CONTAINER_TYPES.ON_DEMAND:
      successAction = loadOnDemandOptionsSuccess;
      failureAction = loadOnDemandOptionsFailure;
      break;
    case CONTAINER_TYPES.MORE_LIKE_THIS:
      successAction = loadSimilarItemsSuccess;
      failureAction = loadSimilarItemsFailure;
      break;
    default:
      break;
  }
  return async (dispatch, getState) => {
    try {
      const feedContent = await retrieveAvsItems(appProvider, actionUri);
      if (feedContent) {
        if (contentItem === CONTAINER_TYPES.MORE_LIKE_THIS) {
          dispatch(successAction(feedContent));
        } else if (feedContent.containers) {
          if (contentItem === CONTAINER_TYPES.SCHEDULES) {
            const { subscribedChannels, isInHome, featureToggles, channelMapInfo } = getState().app;

            // processing live options before triggering a re-render of movie detail page
            const processedLiveOptions = processLiveOptions(
              feedContent.containers,
              subscribedChannels,
              isInHome,
              appProvider,
              featureToggles.isLookbackEnabled,
              channelMapInfo
            );
            dispatch(successAction({ ...processedLiveOptions, schedules: feedContent.containers }));
          } else if (contentItem === CONTAINER_TYPES.ON_DEMAND) {
            const onDemandOptions = await Promise.allSettled(
              feedContent.containers.map((option) =>
                option.id
                  ? getContentUserData(appProvider, option.id, PAGE_CONTENT_ITEM_TYPES.vod, cancelTokenSource) // fetching entitlements for on demand options
                  : Promise.resolve()
              )
            );
            const { contentUserData } = getState().contentItem;

            // processing on demand options before triggering a re-render of movie detail page
            const processedDemandOptions = processDemandOptions(
              feedContent.containers,
              onDemandOptions,
              contentUserData
            );
            dispatch(successAction({ ...processedDemandOptions, onDemandContent: feedContent.containers }));
          } else {
            dispatch(successAction(feedContent.containers));
          }
        }
      }
    } catch (error) {
      if (contentItem === CONTAINER_TYPES.MORE_LIKE_THIS) {
        dispatch(failureAction(error));
      } else {
        dispatch(failureAction());
      }
    }
  };
};
