/**
 * load core modules
 */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
/* load core modules end */

/**
 * load child components
 */
import Swimlane from "../../components/Swimlane";
import SwimlaneItem from "../../components/SwimlaneItem";
/* load child components end */

/**
 * load custom hooks
 */
import { useReducers } from "../../shared/hooks/useReducer";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
/* load custom hooks end */

/**
 * load actions
 */
import { loadSimilarItems, LOAD_SIMILAR_MOVIES } from "./state/actions";
import { resetAction } from "../../App/state/actions";
/* load actions end */

/**
 * load utilities
 */
import { getPcLevelRestriction } from "../../shared/utils";
import createSwimlaneModels from "../../shared/utils/dataFilters";
/* load utilities end */

/**
 * load analytics helper functions
 */
import { getSwimlaneRowNum } from "../../shared/analytics/helpers";
/* load analytics helper functions end */

/**
 * load constants
 */
import constants from "../../shared/constants/index";
import swimlaneConstants from "../../shared/constants/swimlane";
import routeConstants from "../../shared/constants/routes";
/* load constants end */

/** declare/destructure constants */
const { CONTENT_ITEM_TYPES, REDUCER_TYPE, ACTION_KEYS } = constants;
const { ITEM_TYPES, SWIMLANE_TITLES } = swimlaneConstants;
const { SIMILAR_ITEMS } = routeConstants;

/**
 * Component to render more like this tray
 * @component
 * @param {Object} props
 */
const SimilarItems = ({ detailUri, contentMetadata }) => {
  const viewAllUrl = useRef(""); // ref to store view all url for "more like this" tray on movie details page
  const areGenreBasedSimilarItemsFetched = useRef(false); // ref to check if call was made to fetch similar items using client(genre-based) logic

  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const dispatch = useDispatch();
  const cancelTokenSource = useCancelTokenSource();
  const { provider: appProvider, featureToggles, userProfile } = useReducers(REDUCER_TYPE.APP);
  const { similarItemsResponse } = useReducers(REDUCER_TYPE.MOVIE_DETAILS);

  const { isRecommendationMLTEnabled, isParentalPINEnabled, isUserProfilesEnabled } = featureToggles;

  const pcLevel = useMemo(
    () => getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled),
    [isParentalPINEnabled, isUserProfilesEnabled, userProfile]
  );

  useEffect(() => {
    return () => {
      dispatch(resetAction(LOAD_SIMILAR_MOVIES, "content"));
      viewAllUrl.current = "";
      areGenreBasedSimilarItemsFetched.current = false; // re-setting on change of content
    };
  }, [dispatch]);

  /**
   * Uses the list of genres in the movie metadata to fetch similar items for populating the MLT swimlane
   * @param movieMetadata - metadata for the movie
   */
  const fetchGenreBasedSimilarItems = useCallback(
    (movieMetadata) => {
      if (movieMetadata?.extendedMetadata?.dlum?.displayGenres?.length > 0) {
        dispatch(
          loadSimilarItems(
            appProvider,
            movieMetadata.contentType?.toLowerCase(),
            movieMetadata.extendedMetadata.dlum.displayGenres[0],
            movieMetadata.contentSubtype || movieMetadata.uaType,
            userProfile,
            pcLevel,
            cancelTokenSource
          )
        );
      }
    },
    [appProvider, cancelTokenSource, pcLevel, userProfile, dispatch]
  );

  useEffect(() => {
    /**
     * Handles fetching the similar items when RecommendationMLT feature toggle is disabled
     */
    if (!isRecommendationMLTEnabled && contentMetadata && !similarItemsResponse) {
      fetchGenreBasedSimilarItems(contentMetadata.metadata);
      return;
    }
    /**
     * After we have fetched the movie details content and fetched similar items:
     * If the fetch was successful and we have results, we set the View All URL to be used by the MLT swimlane.
     * If the fetch was unsuccessful or returned no results, and the recommendation feature is enabled, we know the
     * recommendation engine had an issue and so we try to fetch the similar items using the genre-based strategy.
     */
    if (similarItemsResponse && contentMetadata?.metadata?.extendedMetadata?.dlum?.displayGenres?.length > 0) {
      if (similarItemsResponse.containers?.length) {
        viewAllUrl.current =
          `${SIMILAR_ITEMS.route}/${contentMetadata.id}` +
          `?itemType=${CONTENT_ITEM_TYPES.programs}` +
          `&genres=${contentMetadata.metadata.extendedMetadata.dlum.displayGenres[0]}` +
          `&itemSubType=${contentMetadata.metadata.contentSubtype ?? contentMetadata.metadata.uaType ?? ""}`;
      } else {
        // Fallback solution to try getting similar items using the genre-based TRAY/SEARCH/VOD or TRAY/SEARCH/LIVE call, depending on content type passed in
        if (!areGenreBasedSimilarItemsFetched.current && isRecommendationMLTEnabled) {
          // avoid loop in case of genre-based api failure
          areGenreBasedSimilarItemsFetched.current = true;
          fetchGenreBasedSimilarItems(contentMetadata.metadata);
        }
      }
    }
  }, [fetchGenreBasedSimilarItems, isRecommendationMLTEnabled, contentMetadata, similarItemsResponse]);

  const similarItemsFeed = useMemo(() => {
    if (similarItemsResponse?.containers) {
      // Filtering out any items that point to the detail page we are currently on
      const filteredSimilarItems = similarItemsResponse.containers.filter(
        (item) =>
          item?.actions?.find((action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK)?.uri !== detailUri
      );

      if (filteredSimilarItems?.length > 0) {
        const title = translate(SWIMLANE_TITLES.SIMILAR_ITEMS);
        return createSwimlaneModels({
          data: filteredSimilarItems,
          avsComponent: { metadata: { label: title }, title },
          addParams: { DIMENSIONS: ITEM_TYPES.TITLE_ITEM.PORTRAIT.DIMENSIONS, viewAllUrl: viewAllUrl.current },
          itemsCount: similarItemsResponse.total || filteredSimilarItems.length,
          isAppLanguageFrench,
          userProfile,
          currentPrograms: undefined,
        });
      }
    }

    return null;
  }, [detailUri, isAppLanguageFrench, similarItemsResponse, translate, userProfile]);

  return similarItemsResponse?.containers?.length > 0 ? (
    <div className="detail-swimlane">
      <Swimlane
        getSwimlaneRowNum={() => getSwimlaneRowNum(SWIMLANE_TITLES.SIMILAR_ITEMS)}
        feed={similarItemsFeed}
        dimensionConfig={ITEM_TYPES.TITLE_ITEM.PORTRAIT.DIMENSIONS}
        sliderSettings={ITEM_TYPES.CHARACTER_ITEM.CONFIG}
        ThumbnailComponent={SwimlaneItem}
      />
    </div>
  ) : null;
};

SimilarItems.propTypes = {
  detailUri: PropTypes.string,
  contentMetadata: PropTypes.object.isRequired,
};

export default SimilarItems;
