import searchConstants from "../../../shared/constants/search";

const {
  FEED_TITLES: { MOVIE, TV_SHOW },
} = searchConstants;

// Action types
export const LOAD_SEARCH_RESULTS = "LOAD_SEARCH_RESULTS";

// Action methods
export const loadSearchContent = (data) => {
  if (data) {
    return {
      type: LOAD_SEARCH_RESULTS,
      content: [
        {
          feedTitleKey: TV_SHOW,
          feedData:
            data[1]?.status === "fulfilled"
              ? data[1]?.value?.containers?.filter((item) => item?.layout && item?.metadata) ?? []
              : [],
          totalItems: data[1]?.value?.total,
        },
        {
          feedTitleKey: MOVIE,
          feedData:
            data[0]?.status === "fulfilled"
              ? data[0]?.value?.containers?.filter((item) => item?.layout && item?.metadata) ?? []
              : [],
          totalItems: data[0]?.value?.total,
        },
      ],
    };
  }
};
