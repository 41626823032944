import React from "react";
import moment from "moment";
import i18n from "../../i18n.js";

import constants from "../constants";
import { getFormattedDate } from "./swimlane";
import middleware from "../middleware";
import RatingIcon from "../../components/RatingIcon";
import ratingConstants from "../../shared/constants/rating";
import routeConstants from "../../shared/constants/routes";
import playerConstants from "../../shared/constants/player";
import {
  getRegionalChannelNumber,
  isGhostChannel,
  isContentOOHBlocked,
  isUserSubscribedChannel,
} from "../../shared/utils/epg";
import { getAutoGeneratedObject, getPurchaseTransactionType } from ".";
import { seasonCompareFunction, getFeatureProperties } from "../utils";

const {
  ON_DEMAND_OPTIONS,
  TRANSACTION_TYPES,
  PACKAGE_TYPE,
  PAGE_CONTENT_ITEM_TYPES,
  WATCH_OPTIONS,
  CONTAINER_LAYOUTS,
  DEFAULT_RENTAL_DURATION,
} = constants;
const { critics, fan } = ratingConstants;
const { ASSET_TYPES } = playerConstants;
const { retrieveAvsItems } = middleware;
const { SERIES_DETAIL_PAGE } = routeConstants;

/**
 * Method to display rating info.
 * @param {Object} scoreDetails
 * @param {Array} ratingArray
 * @param {Boolean} isCinocheRatings
 */
const displayRatingInfo = (scoreDetails, ratingArray, isCinocheRatings = false) => {
  const ratingInfo = [
    {
      score: scoreDetails.criticScore,
      imageType: scoreDetails.criticImage,
      type: critics,
      isCinocheRatings,
      isRatingFromDetailsHeader: true,
    },
    {
      score:
        isCinocheRatings && !scoreDetails.criticScore
          ? scoreDetails.fanScore
          : !isCinocheRatings
          ? scoreDetails.fanScore
          : null,
      imageType:
        isCinocheRatings && !scoreDetails.criticImage
          ? scoreDetails.fanImage
          : !isCinocheRatings
          ? scoreDetails.fanImage
          : null,
      type: fan,
      isCinocheRatings,
      isRatingFromDetailsHeader: true,
    },
  ];

  for (let ratingObj of ratingInfo) {
    ratingArray.push(<RatingIcon key={ratingObj.type} ratingObj={ratingObj} />);
  }
};

/**
 * Returns rating object depending on the app language selected and the object present.
 * @param {Boolean} isAppLanguageFrench
 * @param {Object} cinocheRatings
 * @param {Object} rtRatings
 * @returns {Object}
 */
const displayRatingDetails = (isAppLanguageFrench, cinocheRatings, rtRatings) => {
  let scoreDetails = null,
    isCinocheRatings = false;
  if (isAppLanguageFrench) {
    if (cinocheRatings) {
      scoreDetails = cinocheRatings;
      isCinocheRatings = true;
    } else {
      scoreDetails = rtRatings;
      isCinocheRatings = false;
    }
  } else {
    if (rtRatings) {
      scoreDetails = rtRatings;
      isCinocheRatings = false;
    } else {
      scoreDetails = cinocheRatings;
      isCinocheRatings = true;
    }
  }
  return { scoreDetails, isCinocheRatings };
};

/**
 * Return season and episode number, genre, release year for given content
 * @param {Object} contentDetails
 * @param {Boolean} isAppLanguageFrench
 * @param {Boolean} isSeriesPage
 * @returns {String|JSX}
 */
export const getSubTextLine1 = (contentDetails, isAppLanguageFrench, isSeriesPage) => {
  let lineMetaData = "";
  if (contentDetails) {
    if (isSeriesPage && contentDetails?.containers && contentDetails.containers.length > 0) {
      const seasonLabel = contentDetails.containers.length === 1 ? i18n.t("season") : i18n.t("seasons");
      const seasonInfo = `${contentDetails.containers.length} ${seasonLabel}`;
      const episodeCount = contentDetails.containers.reduce((numEpisodes, nextEpisode) => {
        return numEpisodes + nextEpisode.metadata.episodeCount;
      }, 0);
      const episodeLabel = episodeCount === 1 ? i18n.t("episode") : i18n.t("episodes");
      const episodeInfo = `${episodeCount} ${episodeLabel}`;
      lineMetaData = `${seasonInfo} | ${episodeInfo}`;
    }

    if (contentDetails?.metadata?.extendedMetadata?.dlum?.rating) {
      const currRating = contentDetails?.metadata?.extendedMetadata.dlum.rating;
      if (currRating && currRating !== "")
        lineMetaData = lineMetaData.toString().length > 0 ? `${lineMetaData} | ${currRating}` : currRating;
    }

    if (contentDetails?.metadata?.year) {
      lineMetaData =
        lineMetaData.toString().length > 0
          ? `${lineMetaData} | ${contentDetails?.metadata.year}`
          : contentDetails?.metadata.year;
    } else if (contentDetails?.metadata?.StartYear && contentDetails?.metadata?.StartYear?.toString().length > 0) {
      lineMetaData =
        lineMetaData.toString().length > 0
          ? `${lineMetaData} | ${contentDetails?.metadata.StartYear}`
          : contentDetails?.metadata.StartYear;
    }

    if (contentDetails?.metadata?.extendedMetadata?.dlum?.displayGenres?.length > 0) {
      const item = isAppLanguageFrench
        ? contentDetails?.metadata?.extendedMetadata.dlum.displayGenresFra?.[0]
        : contentDetails?.metadata?.extendedMetadata.dlum.displayGenres[0];
      if (item) lineMetaData = lineMetaData.length > 0 ? `${lineMetaData} | ${item}` : lineMetaData;
    }

    const movieDuration =
      contentDetails?.metadata?.uaType === PAGE_CONTENT_ITEM_TYPES.movie && getMovieDuration(contentDetails?.metadata);
    if (movieDuration) lineMetaData = lineMetaData.length > 0 ? `${lineMetaData} ${movieDuration}` : lineMetaData;
  }

  return lineMetaData;
};

/**
 * Return ratings display text for the given content
 * @param {Object} contentDetails
 * @param {Boolean} isAppLanguageFrench
 * @param {Object} firstLiveProgram
 * @param {Object} unifiedAssetExtendedMetadata
 * @param {Boolean} showRecordingNowBadge
 * @returns {String|JSX}
 */
export const getSubTextLine2 = (
  contentDetails,
  isAppLanguageFrench,
  firstLiveProgram,
  unifiedAssetExtendedMetadata,
  showRecordingNowBadge
) => {
  const extendedMetadata = contentDetails?.extendedMetadata;
  if (extendedMetadata) {
    const rtRatings =
      extendedMetadata.dlum?.rt ||
      (unifiedAssetExtendedMetadata?.rt &&
        Object.keys(unifiedAssetExtendedMetadata.rt).length > 0 &&
        unifiedAssetExtendedMetadata.rt);
    const cinocheRatings =
      extendedMetadata.dlum?.cinoche ||
      (unifiedAssetExtendedMetadata?.cinoche &&
        Object.keys(unifiedAssetExtendedMetadata.cinoche).length > 0 &&
        unifiedAssetExtendedMetadata.cinoche);
    const ratingDetails = displayRatingDetails(isAppLanguageFrench, cinocheRatings, rtRatings);
    const { scoreDetails, isCinocheRatings } = ratingDetails;

    const ratingArray = [];
    if (scoreDetails) {
      displayRatingInfo(scoreDetails, ratingArray, isCinocheRatings);
    }
    return showRecordingNowBadge ||
      extendedMetadata.hasCC ||
      firstLiveProgram?.extendedMetadata?.hasCC ||
      unifiedAssetExtendedMetadata?.hasCC ||
      extendedMetadata?.hasDV ||
      firstLiveProgram?.extendedMetadata?.hasDV ||
      unifiedAssetExtendedMetadata?.hasDV ? (
      <>
        {rtRatings ? <span> {ratingArray}</span> : null}
        {showRecordingNowBadge ? (
          <img
            src={
              isAppLanguageFrench
                ? process.env.PUBLIC_URL + "/images/Recording_Now_FR.svg"
                : process.env.PUBLIC_URL + "/images/Recording_Now_EN.svg"
            }
            alt=""
            className="recording-now-badge"
          />
        ) : null}
        {extendedMetadata.hasCC || firstLiveProgram?.extendedMetadata?.hasCC || unifiedAssetExtendedMetadata?.hasCC ? (
          <span>
            <img src={process.env.PUBLIC_URL + "/images/CC.svg"} alt="cc-indicator" />
          </span>
        ) : null}
        {extendedMetadata.hasDV ? (
          <span className="dv">
            <img src={process.env.PUBLIC_URL + "/images/DV.svg"} alt="dv-indicator" />
          </span>
        ) : null}
      </>
    ) : (
      <div>{ratingArray}</div>
    );
  }
};

/**
 * Return live item channel and timings value
 * @param {Object} contentDetails
 * @param {Array} channelInfo
 */
export const getLiveProgramInfo = (contentDetails, channelInfo, channelMapID) => {
  let lineMetaData;

  if (contentDetails && channelInfo) {
    let regionalChannelNumber =
      channelInfo.regionalChannelNumber ?? getRegionalChannelNumber(channelInfo, channelMapID);
    channelInfo.regionalChannelNumber = regionalChannelNumber;
    lineMetaData =
      channelInfo.regionalChannelNumber +
      " - " +
      channelInfo.channelName +
      " | " +
      moment(contentDetails.airingStartTime).format("LT") +
      " - " +
      moment(contentDetails.airingEndTime).format("LT");
  }
  return lineMetaData;
};

/**
 * Return live item channel and timings value
 * @param {Object} contentDetails
 */
export const getLiveEpisodesInfo = (contentDetails) => {
  let lineMetaData = "";
  if (contentDetails.title) {
    lineMetaData = contentDetails.title;
  }
  if (contentDetails.EpisodeNumber) {
    lineMetaData =
      lineMetaData.length > 0
        ? `E${contentDetails.EpisodeNumber}${i18n.t("punctuation:colon")}${lineMetaData}`
        : `E${contentDetails.EpisodeNumber}`;
  }
  if (contentDetails.SeasonNumber) {
    lineMetaData = `S${contentDetails.SeasonNumber} ` + lineMetaData;
  }
  return lineMetaData;
};

/**
 * Return Episodes Count and Season Count
 * @param {Object} contentDetails
 */
export const getEpisodeCount = (contentDetails) => {
  let lineMetaData = "";
  let episodeLabel;
  let episodeCount;

  if (contentDetails.containers && contentDetails.containers.length > 0) {
    let seasonLabel = contentDetails.containers.length === 1 ? i18n.t("season") : i18n.t("seasons");
    lineMetaData = contentDetails.containers.length + " " + seasonLabel;
    episodeCount = contentDetails.containers.reduce((numEpisodes, nextEpisode) => {
      return numEpisodes + nextEpisode.metadata.episodeCount;
    }, 0);
  }

  if (episodeCount) {
    episodeLabel = episodeCount === 1 ? i18n.t("episode") : i18n.t("episodes");
    lineMetaData =
      lineMetaData.length > 0 ? `${lineMetaData} | ${episodeCount} ${episodeLabel}` : `${episodeCount} ${episodeLabel}`;
  }
  return lineMetaData;
};

/**
 * Return movie duration
 * @param {Object} contentDetails
 */
export const getMovieDuration = (movieContent) => {
  if (movieContent && movieContent.duration && movieContent.duration > 60) {
    //let content = movieContent.CatalogInfo;
    return "| " + getDurationInMinHour(movieContent);
  } else {
    return "";
  }
};
const getDurationInMinHour = (content) => {
  let lineMetaData = "";
  let runTimeSec = content.duration;

  let duration = "";
  let hours = Math.floor(runTimeSec / 3600);
  let minutes = Math.floor((runTimeSec - hours * 3600) / 60);

  if (hours !== 0) {
    duration = duration + hours + "h ";
  }
  if (minutes !== 0) {
    if (minutes < 10) {
      duration = duration + "0" + minutes + "m ";
    } else {
      duration = duration + minutes + "m ";
    }
  }
  lineMetaData = duration;
  return lineMetaData;
};

/**
 * Return EpisodeInfo for line 1
 * @param {Object} contentDetails
 * @param {Boolean} isAppLanguageFrench
 */
export const getEpisodeInfoLine1 = (contentDetails, isAppLanguageFrench, isSportsEventPage) => {
  let lineMetaData = "";
  if (contentDetails.extendedMetadata && contentDetails.extendedMetadata.dlum) {
    const currRating = contentDetails.extendedMetadata.dlum?.rating;
    if (currRating) lineMetaData = currRating;
  }

  if (isSportsEventPage && contentDetails.extendedMetadata?.dlum?.episode?.displaySeason) {
    const seasonDetail = isAppLanguageFrench
      ? contentDetails?.extendedMetadata?.dlum?.episode?.displaySeasonFra
      : contentDetails?.extendedMetadata?.dlum?.episode?.displaySeason;
    if (seasonDetail) lineMetaData = lineMetaData.length > 0 ? `${lineMetaData} | ${seasonDetail}` : lineMetaData;
  }

  if (
    contentDetails.extendedMetadata?.dlum?.displayGenres &&
    contentDetails.extendedMetadata?.dlum?.displayGenres.length > 0
  ) {
    const item = isAppLanguageFrench
      ? contentDetails?.extendedMetadata?.dlum?.displayGenresFra
        ? contentDetails?.extendedMetadata?.dlum?.displayGenresFra[0]
        : contentDetails?.extendedMetadata?.dlum?.displayGenres[0]
      : contentDetails?.extendedMetadata?.dlum?.displayGenres[0];
    if (item) lineMetaData = lineMetaData.length > 0 ? `${lineMetaData} | ${item}` : lineMetaData;
  }

  if (contentDetails.duration) {
    lineMetaData = lineMetaData + " | " + getDurationInMinHour(contentDetails);
  }
  if (!isSportsEventPage && contentDetails.extendedMetadata && contentDetails.extendedMetadata?.dlum?.originalAirDate) {
    lineMetaData = lineMetaData + " | " + getFormattedDate(contentDetails.extendedMetadata.dlum.originalAirDate);
  } else if (!isSportsEventPage && contentDetails.year && parseInt(contentDetails.year) > 0) {
    lineMetaData = lineMetaData + " | " + contentDetails.year;
  }
  return lineMetaData;
};

/**
 * Returns the trailer asset
 * @param {Array} assets
 * @param {Object} content types
 */
export const getTrailerAsset = (assets) => {
  return assets?.find(
    (item) =>
      item?.assetType?.toLowerCase() === ASSET_TYPES.TRAILER && item?.rights?.toLowerCase() === WATCH_OPTIONS.watch
  );
};

/**
 * Returns boolean for the item opened can be viewed live or not
 * @param {Array} schedules
 * @param {String|Number} channelId
 * @param {Object} channelMapInfo
 */
export const getItemLiveAvailability = (schedules, channelId, channelMapInfo, currentTime) => {
  const channelIdStr = typeof channelId === "string" ? channelId : channelId.toString();
  return schedules.find((schedule) => {
    let scheduleIdStr = typeof schedule.id === "string" ? schedule.id : schedule.id.toString();
    const channelObj = getLiveChannelAsset(channelMapInfo?.containers, schedule.channel.channelId);
    if (channelObj) {
      schedule.isNotAvailableOutOfHome = isContentOOHBlocked(schedule, channelObj);
      return (
        scheduleIdStr === channelIdStr &&
        currentTime > schedule.metadata.airingStartTime &&
        currentTime < schedule.metadata.airingEndTime &&
        !isGhostChannel(channelObj)
      );
    }
    return false;
  });
};

/**
 * get Episode duration and is new info
 * @param {Object} program
 * @returns episode metadata
 */
export const getEpisodeInfo = (program) => {
  let lineMetaData = "";
  let catalogInfo = program?.metadata?.extendedMetadata?.dlum;
  if (catalogInfo?.IsNew) {
    lineMetaData = i18n.t("new").toUpperCase() + "\xa0\xa0\xa0";
  }
  if (catalogInfo?.originalAirDate) {
    lineMetaData = getFormattedDate(catalogInfo.originalAirDate);
  } else if (catalogInfo?.ReleaseYear) {
    lineMetaData = lineMetaData + catalogInfo.ReleaseYear;
  }

  return lineMetaData;
};

/**
 * Return ratings display text for the given content
 * @param {Object} dlum
 * @param {Boolean} isAppLanguageFrench
 */
export const getBottomRatingDetails = (dlum, isAppLanguageFrench) => {
  let iconDiv = [];

  const cinocheRatings = dlum?.cinoche && Object.keys(dlum.cinoche).length > 0 ? dlum.cinoche : null;
  const rtRatings = dlum?.rt && Object.keys(dlum.rt).length > 0 ? dlum.rt : null;

  const ratingDetails = displayRatingDetails(isAppLanguageFrench, cinocheRatings, rtRatings);
  const { scoreDetails, isCinocheRatings } = ratingDetails;

  const ratingInfo = [
    { score: scoreDetails.criticScore, imageType: scoreDetails.criticImage, type: critics, isCinocheRatings },
    { score: scoreDetails.fanScore, imageType: scoreDetails.fanImage, type: fan, isCinocheRatings },
  ];

  for (let ratingObj of ratingInfo) {
    if (ratingObj.type === critics && ratingObj.score) {
      iconDiv.push(getText(isCinocheRatings ? "details_critic_cinoche" : "details_critic", isCinocheRatings));
      iconDiv.push(<RatingIcon key={ratingObj.type} ratingObj={ratingObj} />);
    }
    if (ratingObj.type === fan && ratingObj.score) {
      iconDiv.push(getText(isCinocheRatings ? "details_members_cinoche" : "details_audience", isCinocheRatings));
      iconDiv.push(<RatingIcon key={ratingObj.type} ratingObj={ratingObj} />);
    }
  }
  return <div>{iconDiv}</div>;
};

const getText = (text, isCinocheRatings = false) => {
  return (
    <span className={`rating-text${isCinocheRatings ? " cinoche" : " rt"}`} key={text}>
      {i18n.t(text)}
    </span>
  );
};
export const getGenreInOneLine = (contentDetails, isAppLanguageFrench) => {
  const genres = isAppLanguageFrench
    ? contentDetails?.extendedMetadata?.dlum?.displayGenresFra ??
      contentDetails?.extendedMetadata?.dlum?.displayGenres ??
      []
    : contentDetails?.extendedMetadata?.dlum?.displayGenres ?? [];

  return genres.join(", ");
};

/**
 * Returns the list of available purchase options
 * @param {Array} assets
 * @returns {Array}
 */
export const getPurchaseOptions = (assets) => {
  if (assets?.length > 0) {
    return assets?.reduce((purchaseOptions, asset) => {
      if (
        asset?.assetType?.toLowerCase() === ASSET_TYPES.MASTER &&
        asset.rights === WATCH_OPTIONS.buy &&
        asset.commercialPackages?.length > 0
      ) {
        const { contentId, videoType } = asset;
        asset.commercialPackages.forEach((commercialPackage) => {
          if (commercialPackage?.type?.toLowerCase() === PACKAGE_TYPE.TVOD) {
            purchaseOptions.push({
              contentId,
              videoType,
              ...commercialPackage,
            });
          }
        });
      }
      return purchaseOptions;
    }, []);
  }
  return null;
};

/**
 * Returns commercial package price or null if not available
 * @param {Object} commercialPackage
 * @returns {Number}
 */
export const getPackagePrice = (commercialPackage) => {
  return commercialPackage?.notRecurring?.discountedPrice ?? commercialPackage?.notRecurring?.price ?? null;
};

/**
 * Returns locale-sensitive formatted price string. The currency is assumed to be CAD.
 * @param {Number} price
 * @param {String} appLanguage
 * @returns {String}
 */
export const getFormattedPrice = (price, appLanguage = "en") => {
  if (typeof price === "number") {
    return price.toLocaleString(`${appLanguage}-CA`, {
      style: "currency",
      currency: "CAD",
    });
  }

  return null;
};

/**
 * Returns expiry message for purchased assets
 * @param {String|Number} expiryDate
 * @returns {String}
 */
export const getAssetExpiryString = (expiryDate) => {
  if (expiryDate) {
    const endDateTime = moment.unix(expiryDate);
    const currentDateTime = moment();

    if (endDateTime.diff(currentDateTime, "hours") > DEFAULT_RENTAL_DURATION) {
      // If the rental duration is over 48 hours, the user owns the asset
      return i18n.t("order_own_title");
    } else {
      return i18n
        .t("expire_date_and_time")
        .replace("%s", endDateTime.format("ll"))
        .replace("%s", endDateTime.format("LT"));
    }
  }
  return null;
};

/**
 * Returns lookback available string message
 * @param {Number} lookbackHoursLeft
 * @returns {String}
 */
export const getLookbackAvailabilityString = (lookbackHoursLeft) => {
  if (lookbackHoursLeft) {
    if (lookbackHoursLeft > 1) {
      return i18n.t("lookback_availability").replace("%s", lookbackHoursLeft);
    } else {
      return i18n.t("lookback_availability_limit_reached");
    }
  }
  return "";
};

/**
 * Sort function to place TVOD assets in front of other assets
 * @param {Object} assetA
 * @param {Object} assetB
 * @returns {Number}
 */
export const prioritizeTvodCompareFunction = (assetA, assetB) => {
  // Prioritize TVOD by checking for a rental end date
  if (assetA.rentalEndDate && !assetB.rentalEndDate) {
    return -1;
  }
  if (!assetA.rentalEndDate && assetB.rentalEndDate) {
    return 1;
  }
  return 0;
};

/**
 * Returns the first available master asset
 */
export const retrieveMasterAsset = (assets, right) =>
  assets.find((asset) => asset?.assetType?.toLowerCase() === ASSET_TYPES.MASTER && asset?.rights === right);

/**
 * Return series url & content  for an episode
 */
export const getSeriesInfo = async (programInfo, appProvider, isSportsEventPage = false) => {
  const seriesId =
    programInfo?.metadata?.extendedMetadata?.dlum?.series?.uaId ||
    programInfo?.metadata?.extendedMetadata?.dlum?.uaGroupId;
  let seasonIndex;
  let seriesName;
  let seriesDetailContent;
  const seriesIdentifier = isSportsEventPage ? PAGE_CONTENT_ITEM_TYPES.sportsSeries : PAGE_CONTENT_ITEM_TYPES.tvShow;
  const actionUri = seriesId ? `/PAGE/DETAILS/${seriesIdentifier}/${seriesId}` : null;
  if (actionUri) {
    try {
      const seriesContainers = await retrieveAvsItems(appProvider, actionUri);
      const seriesContentContainer = seriesContainers?.containers?.find(
        (container) => container?.layout?.toLowerCase() === CONTAINER_LAYOUTS.CONTENT_DETAIL
      );
      const contentUri = seriesContentContainer?.retrieveItems?.uri;
      const seriesInfo = await retrieveAvsItems(appProvider, contentUri);
      seriesDetailContent = seriesInfo?.containers[0]?.metadata;
      const seriesBundleContainer = seriesInfo?.containers?.find(
        (container) => container?.layout?.toLowerCase() === CONTAINER_LAYOUTS.BUNDLE_ITEM
      );
      seriesName = seriesBundleContainer?.title;
      const sortedData = seriesBundleContainer?.containers.sort(seasonCompareFunction);
      const sortedSeasonData = getSortedSeasons(programInfo, sortedData);
      seasonIndex = sortedSeasonData.findIndex(
        (seasonItem) => seasonItem?.metadata?.season === programInfo?.metadata?.season
      );
    } catch {
      seriesName = programInfo?.metadata?.extendedMetadata?.dlum?.sortTitle || "";
      seasonIndex = null;
    }
  }
  const seriesInfo = {};
  if (seriesName) {
    const url = actionUri
      ? (seasonIndex !== undefined || seasonIndex !== null) && Number.isInteger(seasonIndex) && seasonIndex >= 0
        ? `#${SERIES_DETAIL_PAGE.route}${actionUri}?index=${seasonIndex}`
        : `#${SERIES_DETAIL_PAGE.route}${actionUri}`
      : null;
    seriesInfo.name = seriesName;
    seriesInfo.url = url;
  }
  if (seriesDetailContent) {
    seriesInfo.seriesDetailContent = seriesDetailContent;
  }
  return seriesInfo;
};

/**
 * Return watch options available for a movie or episode
 */
export const getWatchOptions = (assets) => {
  if (assets?.length > 0) {
    const watchOptions = assets.reduce((allAssets, asset) => {
      if (asset?.rights?.toLowerCase() === WATCH_OPTIONS.watch) {
        allAssets.push({
          ...asset,
          btnType: ON_DEMAND_OPTIONS.PLAY,
        });
      } else if (asset?.rights?.toLowerCase() === WATCH_OPTIONS.buy && asset?.commercialPackages?.length > 0) {
        if (asset.channel) {
          allAssets.push({
            ...asset,
            btnType: ON_DEMAND_OPTIONS.SUBSCRIBE,
          });
        } else {
          const { contentId, videoType } = asset;
          asset.commercialPackages.forEach((commercialPackage) => {
            allAssets.push({
              ...commercialPackage,
              contentId,
              videoType,
              btnType:
                getPurchaseTransactionType(commercialPackage.name) === TRANSACTION_TYPES.RENT
                  ? ON_DEMAND_OPTIONS.RENT
                  : ON_DEMAND_OPTIONS.BUY,
            });
          });
        }
      }
      return allAssets;
    }, []);
    return watchOptions;
  }
  return null;
};

export const getLiveChannelAsset = (liveOptions, channelID) => {
  if (!channelID) return null;

  return liveOptions?.find((liveOption) => parseInt(liveOption?.id) === parseInt(channelID));
};

/**
 * Returns first available in-home schedule that is airing now
 * @param {Object[]} schedules List of program schedules from TRAY/SEARCH/PROGRAM
 * @param {Number} currentTime Current Unix timestamp in milliseconds
 * @param {Object} channelMapInfo Response object from TRAY/LIVECHANNELS containing all channel containers
 * @param {Object} subscribedChannels List of user subscribed channels
 * @returns {Object} first schedule that is in-home and airing now
 */
export const getFirstInHomeLiveSchedule = (schedules, currentTime, channelMapInfo, subscribedChannels) => {
  return schedules.find((schedule) => {
    if (schedule?.metadata && schedule.channel) {
      const channelObj = getLiveChannelAsset(channelMapInfo?.containers, schedule.channel.channelId);
      if (channelObj) {
        return (
          currentTime >= schedule.metadata.airingStartTime &&
          currentTime <= schedule.metadata.airingEndTime &&
          !isContentOOHBlocked(schedule, channelObj) &&
          !isGhostChannel(channelObj) &&
          isUserSubscribedChannel(channelObj, subscribedChannels)
        );
      }
    }
    return false;
  });
};

/**
 * Returns first available out-of-home schedule that is airing now
 * @param {Object[]} schedules List of program schedules from TRAY/SEARCH/PROGRAM
 * @param {Number} currentTime Current Unix timestamp in milliseconds
 * @param {Object} channelMapInfo Response object from TRAY/LIVECHANNELS containing all channel containers
 * @param {Object} subscribedChannels List of user subscribed channels
 * @returns {Object} first schedule that is out-of-home and airing now
 */
export const getFirstOutOfHomeLiveSchedule = (schedules, currentTime, channelMapInfo, subscribedChannels) => {
  return schedules.find((schedule) => {
    if (schedule?.metadata && schedule.channel) {
      const channelObj = getLiveChannelAsset(channelMapInfo?.containers, schedule.channel.channelId);
      if (channelObj) {
        schedule.isNotAvailableOutOfHome = isContentOOHBlocked(schedule, channelObj);
        return (
          currentTime >= schedule.metadata.airingStartTime &&
          currentTime <= schedule.metadata.airingEndTime &&
          isContentOOHBlocked(schedule, channelObj) &&
          !isGhostChannel(channelObj) &&
          isUserSubscribedChannel(channelObj, subscribedChannels)
        );
      }
    }
    return false;
  });
};

/**
 * Retrieves the pause live properties by looking at the feature flags
 * @param {Object} appProvider
 * @returns {object} "PauseLiveTV" properties
 */
export const getPauseLiveProperties = (appProvider) => {
  return getFeatureProperties(appProvider, "PauseLiveTV");
};

/**
 * Returns a formatted string to present the channel number and/or name to users.
 *
 * Format: "<channelNumber> - <channelName>"
 * Ex: "106 - Citytv Edmonton HD"
 *
 * @param {Object} channel
 * @param {Number} regionId user specific region ID
 * @returns {String} formatted string
 */
export const getChannelInfoString = (channel, regionId) => {
  if (channel?.metadata) {
    let channelNumber = "";
    if (channel.number) {
      channelNumber = channel.number.toString();
    } else if (channel.regions && regionId) {
      channelNumber = getRegionalChannelNumber(channel, regionId);
    }

    let formattedString = channelNumber;
    if (channel.metadata.channelName) {
      formattedString += `${channelNumber ? " - " : ""}${channel.metadata.channelName}`;
    }

    return formattedString;
  }
  return "";
};

/**
 * Method to check if the genre of the said asset is "News" or "Sports" and return an array of seasons.
 * @param {Object} content
 * @param {Array} seasons
 * @returns Array
 */
export const getSortedSeasons = (content, seasons) => {
  const autoGeneratedObject = getAutoGeneratedObject(content?.metadata);
  if (
    autoGeneratedObject?.isSeasonAutoGenerated &&
    autoGeneratedObject.isEpisodeAutoGenerated &&
    content?.metadata?.extendedMetadata?.dlum?.displayGenres?.length > 0 &&
    content.metadata.extendedMetadata.dlum.displayGenres.some((genre) => genre === "Sports" || genre === "News")
  ) {
    return seasons;
  } else {
    return seasons && [...seasons].reverse();
  }
};
