import { createSwimlaneFeedItem, convertTitleItemsListToSwimLaneModel } from "./swimlane";
import i18n from "../../i18n";

/**
 * Swimlane Data Parse Helper
 * @param {Object} config Swimlane config
 * @param {Array} config.data
 * @param {Object} config.avsComponent
 * @param {Object} config.addParams
 * @param {Number} config.itemsCount
 * @param {Boolean} config.isAppLanguageFrench
 * @param {Object} config.userProfile
 * @param {Object} config.currentPrograms
 * @param {Boolean} config.appendFrenchTag
 * @param {Object} config.recordingProfile
 * @returns {Object}
 */
export default function createSwimlaneModels({
  data,
  avsComponent,
  addParams,
  itemsCount,
  isAppLanguageFrench = false,
  userProfile,
  currentPrograms,
  appendFrenchTag,
  recordingProfile,
}) {
  const items = convertTitleItemsListToSwimLaneModel(
    data,
    avsComponent?.title === i18n.t("teams") ? addParams.DIMENSIONS.teamViewAllValues : addParams.DIMENSIONS.values,
    isAppLanguageFrench,
    userProfile,
    currentPrograms,
    avsComponent,
    appendFrenchTag,
    recordingProfile
  );
  return createSwimlaneFeedItem(
    avsComponent.metadata.label,
    items,
    addParams.viewAllUrl || null,
    avsComponent,
    addParams.subtitle || null,
    itemsCount,
    avsComponent.metadata.onclicktarget
  );
}
