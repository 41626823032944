import styled from "styled-components";
import "../../variables.scss";

export const StyledButton = styled.button(
  ({ $backgroundColor, theme, width }) => `
  background-color: ${$backgroundColor || theme.colours.buttonBackground};
  font: ${theme.typography.primaryFont};
  border: none;
  width: 199px;
  height: 51px;
  margin-top: 15px;
  border-radius: 8px;
  font-weight: ${theme.typography.fontWeightSemiBold};
  font-size: ${
    width <= 768
      ? theme.typography.smallFontSize
      : width <= 1366
      ? theme.typography.mediumSwimlaneFontSize
      : theme.typography.largeFontSize
  };
  color: ${theme.colours.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: transparent;
  }
  &:hover {
    background-color: ${theme.colours.subscribeLink};
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.wallButton {
    padding: 9px 127.4px 12px 128px;
    margin: 24px 348px 0 0;
    width: 324px;
    height: 51px;

  }
  &.gray-button {
    background-color: ${theme.colours.buttonColor};
    &:hover {
      background-color: ${theme.colours.doveGray};
    }
  }
  &.ghost-button {
    background-color: transparent;
    border: solid 2px ${theme.colours.doveGray};
    &:hover {
      background-color: ${theme.colours.doveGray};
    }
  }
  &.PVR-wall-button {
    height: 66px;
    justify-content: flex-start;
    margin: 0;
    padding-left: ${width <= 1600 ? "10px" : "20px"};
    width: ${width <= 1600 ? "100%" : "66px"};
    &:hover {
      background-color: ${theme.colours.focusedRecordingButtonBackground};
    }
    &.selected {
      background-color: ${theme.colours.recordingButtonBackground};
      border-radius: 10px;
      gap: 16px;
      width: ${width <= 1024 ? "66px" : "100%"};
    }
    &.scheduled {
      background-color: ${theme.colours.recordingButtonBackground};
      border-radius: 10px;
      gap: 16px;
      width: ${width <= 1024 ? "66px" : "100%"};
    }

  }

  }
`
);

export const StyledIcon = styled.img`
  height: 22px;
  width: 22px;
  margin-right: 5px;

  &.restricted {
    opacity: 0.5;
    padding-right: 2.8px;
  }

  ${({ width }) =>
    width <= 1366 &&
    `
    height: 20px;
    width: 20px;
  `}

  ${({ width }) =>
    width <= 768 &&
    `
    height: 15px;
    width: 15px;
  `}
`;

export const StyledSpan = styled.span`
  line-height: 26px;

  ${({ width }) =>
    width <= 1366 &&
    `
    line-height: 20px;
  `}

  ${({ width }) =>
    width <= 768 &&
    `
    line-height: 16px;
  `}
`;
