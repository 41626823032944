import React from "react";

const ContextMenuItemText = ({ iconClass, iconSrc, iconKey, label }) => {
  return (
    <div className="button-wrap">
      <span className="icon-container">
        <img key={iconKey} src={iconSrc} alt="" className={iconClass} />
      </span>
      <span className="label-container">{label}</span>
    </div>
  );
};

export default ContextMenuItemText;
