import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

import FilterDropdown from "../FilterDropdown";

/**
 * Season Dropdown component
 *
 * @component
 * @param {Object} props
 */
const CategoryFilter = ({ contentList, filterSelected, lastSelectedFilterIndex, filterCategoryName }) => {
  const { t: translate } = useTranslation();
  const [showDropdownList, setShowDropdownList] = useState(false);

  let titleArray = getFiltersHeadings(contentList);
  const [item, setItem] = useState(titleArray[lastSelectedFilterIndex].value);
  const createList = () => {
    const list = {
      items: titleArray,
      label: filterCategoryName,
    };
    return list;
  };

  const buttonClickHandler = () => {
    setShowDropdownList(true);
  };
  useEffect(() => {
    setItem(titleArray[lastSelectedFilterIndex].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSelectedFilterIndex]);

  const linkClickHandler = (index) => {
    const item = titleArray[index];

    const currentHash = window.location.hash;

    let updatedHash;
    if (!currentHash.includes("filter")) {
      updatedHash = currentHash + "?filter=" + item.value;
    } else {
      updatedHash = currentHash.replace(
        currentHash.substring(currentHash.indexOf("?filter="), currentHash.length),
        `?filter=${item.value}`
      );
    }

    window.history.replaceState({}, currentHash, updatedHash);

    setItem(item.value);
    filterSelected(contentList[index]);
    setShowDropdownList(false);
  };

  /**
   * Hides the date dropdown pop up when clicked outside of the date dropdown
   */
  const handleOutsideComponentClick = () => {
    setShowDropdownList(false);
  };

  return (
    <FilterDropdown
      showDropdownList={showDropdownList}
      filterImage={
        item !== titleArray[0].value
          ? `${process.env.PUBLIC_URL}/images/Filter_Icon_Active.svg`
          : `${process.env.PUBLIC_URL}/images/Filter_Icon.svg`
      }
      button={{
        label: translate("filter"),
        buttonClickHandler,
        buttonStyles: item !== titleArray[0].value ? "filter-applied" : "",
      }}
      lists={[
        {
          items: createList(),
          linkClickHandler: linkClickHandler,
          type: "radio",
          selectedItem: item,
        },
      ]}
      popUp={{
        popUpStyles: "categoryListContainer",
        style: { height: createList().length > 3 ? "300px" : "100px" },
        handleOutsideComponentClick,
      }}
    />
  );
};

export default CategoryFilter;

/**
 * Create list of string array for dorp down component
 * @param {Object} contentList
 * @returns {Array} array of string
 */
const getFiltersHeadings = (contentList) => {
  let titleArray = contentList.map((element) => {
    return {
      value: element.Name,
    };
  });

  return titleArray;
};
