import React from "react";
import { useTranslation } from "react-i18next";
import PopUpOutline from "../PopUpOutline";
import "./style.scss";
import ToggleSwitch from "../ToggleSwitch";

/**
 * Component that renders a mini video settings
 * @component
 * @param {Object} props
 */
function VideoSettings({
  closeCaption,
  audioDescribeVideo,
  onCloseCaptionChange,
  onAudioDescVideoChange,
  isADEnabled,
  isCCAvailable,
}) {
  const { t: translate } = useTranslation();

  const onToggleButtonChange = (isChange) => {
    onCloseCaptionChange(isChange);
  };

  const onAudioDescChange = (isSelected) => {
    onAudioDescVideoChange(isSelected);
  };
  return (
    <PopUpOutline className="video-settings-popup">
      {isCCAvailable && (
        <div className="title-content">
          <h1>
            <img src={process.env.PUBLIC_URL + "/images/CC-indicator.svg"} alt="CC-Indicator" />
            {translate("closed_captioning")}
          </h1>
          <ToggleSwitch isChecked={closeCaption} onToggleButtonChange={onToggleButtonChange} />
        </div>
      )}
      {isADEnabled && (
        <div className="title-content">
          <h1>
            <img src={process.env.PUBLIC_URL + "/images/AD-indicator.svg"} alt="AD-Indicator" />
            {translate("audio_description")}
          </h1>
          <ToggleSwitch isChecked={audioDescribeVideo} onToggleButtonChange={onAudioDescChange} />
        </div>
      )}
    </PopUpOutline>
  );
}
export default VideoSettings;
