import React, { useRef, useState, useEffect } from "react";
import "./style.scss";
import PropTypes from "prop-types";

import FilterDropdownButton from "../FilterDropdownButton";
import FilterDropdownList from "../FilterDropdownList";
import PopUpOutline from "../PopUpOutline";
import { useTheme } from "styled-components";

import { Scrollable } from "./FilterDropdown.styles";

/**
 * Create Dropdown
 *
 * @component
 * @param {Object} props
 */
const FilterDropdown = (props) => {
  const { button, lists, popUp, showDropdownList, filterImage, isGuidePage } = props;
  const { popUpStyles, handleOutsideComponentClick, childItems } = popUp;
  const dropdownButtonRef = useRef(null);
  const theme = useTheme();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {button && (
        <FilterDropdownButton
          label={button.label}
          onClickHandler={button.buttonClickHandler}
          className={button.buttonStyles}
          filterCount={button.filterCount}
          isFilterApplied={button.isFilterApplied}
          filterImage={filterImage}
          showDropdownList={showDropdownList}
          ref={dropdownButtonRef}
          isGuidePage={isGuidePage ? isGuidePage : ""}
          screenWidth={screenWidth}
        />
      )}
      {showDropdownList ? (
        <PopUpOutline
          className={popUpStyles}
          handleOutsideComponentClick={handleOutsideComponentClick}
          optionalRef={dropdownButtonRef}
        >
          <Scrollable theme={theme}>
            {lists.map((list, index) => {
              if (typeof list.isFeaturedEnabled === "undefined" || list.isFeaturedEnabled) {
                return (
                  <FilterDropdownList
                    key={index}
                    categoryIndex={index}
                    list={list.items}
                    type={list.type}
                    onClickHandler={list.linkClickHandler}
                    selectedItem={list.selectedItem}
                    className={list.listStyles}
                    screenWidth={screenWidth}
                  />
                );
              }
              return null;
            })}
          </Scrollable>
          {childItems}
        </PopUpOutline>
      ) : undefined}
    </div>
  );
};

export default FilterDropdown;

FilterDropdown.propTypes = {
  button: PropTypes.object,
  lists: PropTypes.array.isRequired,
  popUp: PropTypes.object.isRequired,
  showDropdownList: PropTypes.bool.isRequired,
  filterImage: PropTypes.string,
};
