import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

import DropdownButton from "../DropdownButton";
import DropdownList from "../DropdownList";
import PopUpOutline from "../PopUpOutline";

/**
 * Create Dropdown
 *
 * @component
 * @param {Object} props
 */
const Dropdown = (props) => {
  const { button, lists, popUp, showDropdownList } = props;
  const { label, tag, buttonClickHandler, buttonStyles, contentSize } = button;
  const { popUpStyles, handleOutsideComponentClick } = popUp;

  return (
    <React.Fragment>
      <DropdownButton
        label={label}
        tag={tag}
        listSize={contentSize}
        onClickHandler={buttonClickHandler}
        className={buttonStyles}
        showDropdownList={showDropdownList}
      />
      {showDropdownList ? (
        <PopUpOutline className={popUpStyles} handleOutsideComponentClick={handleOutsideComponentClick}>
          <div className="scrollable">
            {lists.map((list, index) => {
              return (
                <DropdownList
                  key={index}
                  list={list.items}
                  onClickHandler={list.linkClickHandler}
                  selectedItem={list.selectedItem}
                  className={list.listStyles}
                />
              );
            })}
          </div>
        </PopUpOutline>
      ) : undefined}
    </React.Fragment>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  button: PropTypes.object.isRequired,
  lists: PropTypes.array.isRequired,
  popUp: PropTypes.object.isRequired,
  showDropdownList: PropTypes.bool.isRequired,
};
