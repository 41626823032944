import React from "react";
import { useDispatch } from "react-redux";
import { useReducers } from "../../shared/hooks/useReducer";

import SidebarListItem from "./SideBarListItem";
import constants from "../../shared/constants";
const { REDUCER_TYPE } = constants;

const Index = (props) => {
  const dispatch = useDispatch();
  const { featureToggles, provider: appProvider, isInHome } = useReducers(REDUCER_TYPE.APP);
  const { recordingsList, manipulatedRecordingParams } = useReducers(REDUCER_TYPE.RECORDING);

  const { isRestartLiveTVEnabled: isRestartLiveFeatureEnabled, isRecordingEnabled } = featureToggles;

  return (
    <SidebarListItem
      dispatch={dispatch}
      isInHome={isInHome}
      appProvider={appProvider}
      recordingsList={recordingsList}
      manipulatedRecordingParams={manipulatedRecordingParams}
      isRestartLiveFeatureEnabled={isRestartLiveFeatureEnabled}
      isRecordingEnabled={isRecordingEnabled}
      {...props}
    />
  );
};

export default Index;
