export default Object.freeze({
  FEED_TITLES: {
    TV_SHOW: "search_result_tv_shows",
    MOVIE: "search_result_movies",
  },
  SEARCH_ORDER_PARAM: "searchOrder",
  ENTER_KEY: "Enter",
  SEARCH_ORDER_OPTIONS: [
    {
      label: "popularity",
      value: "searchOrder=",
    },
    {
      label: "alphabetical",
      value: "searchOrder=sortTitle:asc",
    },
    {
      label: "alphabetical_reverse",
      value: "searchOrder=sortTitle:desc",
    },
  ],
  SEARCH_FILTER_OPTIONS: [
    {
      key: "content_filters",
      label: "content",
      values: [
        {
          label: "all_content",
          value: "all",
        },
        {
          label: "my_content",
          value: "subscribed",
        },
      ],
    },
    {
      key: "language_filters",
      label: "language",
      values: [
        {
          label: "all",
          value: "",
        },
        {
          label: "english",
          value: "filter_language=ENG",
        },
        {
          label: "french",
          value: "filter_language=FRA",
        },
      ],
    },
  ],
});
