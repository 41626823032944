import { useEffect } from "react";
import Document from "../document";

/**
 * Custom hook for identifying clicks happening outside of an element or component
 *
 * @param {Object} ref - Element dom reference object
 * @param {Function} clickHandler - Callback method to be invoked on click happening outside of an element
 */
export default function useOutsideClickIndicator(ref, clickHandler, optionalRef) {
  useEffect(() => {
    /**
     * Invoke clickHandler callback if clicked outside of an element
     * @param {Object} event - event object
     */
    function handleClickOutside(event) {
      if (optionalRef) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          optionalRef.current &&
          !optionalRef.current.contains(event.target)
        ) {
          clickHandler(event);
        }
      } else {
        if (ref.current && !ref.current.contains(event.target)) {
          clickHandler(event);
        }
      }
    }

    // Bind the event listener
    Document.addEventListener("mousedown", handleClickOutside);
  }, [ref, clickHandler, optionalRef]);
}
