import React from "react";
import { useReducers } from "../../shared/hooks/useReducer";
import { useHistory } from "react-router-dom";

import constants from "../../shared/constants";

const { REDUCER_TYPE } = constants;

function SearchCollection(props) {
  const { searchCollection } = useReducers(REDUCER_TYPE.APP);
  const history = useHistory();
  return (
    <div className="search-swimlanes">
      <div className="swimlane-row">
        <div className="header">
          <div className="title">
            <span>{searchCollection?.label}</span>
          </div>
        </div>
        <div className="grid">
          {searchCollection?.items?.map((item, index) => {
            const redirectUri = item.actions?.[0]?.uri?.includes("TRAY/SEARCH")
              ? `/viewall${item.actions?.[0]?.uri}`
              : item.actions?.[0]?.uri;
            return (
              <div key={index} className="grid-item">
                <img
                  src={item.editorialMetadata?.pictureUrlUnfocus}
                  alt={item.metadata?.title}
                  onClick={() => {
                    history.push(redirectUri);
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.src = item.editorialMetadata?.pictureUrlFocus;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = item.editorialMetadata?.pictureUrlUnfocus;
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SearchCollection;
