import request from "../../service/request";
import constants from "../constants";
import storageConstants from "../constants/storage";
import { getLocalStorage } from "../../shared/utils/localStorage";
const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

/* Get Config **/
export default function getConfig(config) {
  let configURL = config.avs_api_urls.config_properties_url.replace("$TENANT_URL", config.general.AVS_config.appHost);

  configURL = configURL.replace("$AGL_VERSION", config.general.AVS_config.appVersion);
  configURL = configURL.replace("$LANG", "ENG");
  configURL = configURL.replace(/\$PLATFORM/g, config.general.AVS_config.appChannelTypes.default);
  if (ENV === APP_ENVIRONMENTS.DEV) {
    configURL = configURL.replace("https://" + config.general.AVS_config.appHost, API_BASE_URL);
  }

  return request({
    url: configURL,
    method: "GET",
    returnResponseObject: true,
  });
}
