import constants from "../constants";

const { CONTAINER_LAYOUTS, CONTENT_ITEM_TYPES } = constants;

/**
 * Method to get item id for adding/removing a favourite
 * @param {Object} item favourite item to added or removed
 * @returns {String} id of the item to be added or removed
 */
export const getFavouriteItemId = (item) => {
  const { layout, metadata } = item;
  if (layout?.toLowerCase() === CONTAINER_LAYOUTS.CONTENT_ITEM) {
    const { uaGroupId, uaId } = metadata?.extendedMetadata?.dlum || {};
    return metadata?.extendedMetadata?.dlum?.uaGroupType?.toLowerCase() === CONTENT_ITEM_TYPES.tvshow
      ? uaGroupId
      : uaId;
  }
  return metadata?.type === CONTENT_ITEM_TYPES.sportsCard ? metadata.seriesUaId : item.id;
};
