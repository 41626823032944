import {
  LOAD_SIMILAR_SERIES,
  LOAD_PROGRAM_DETAILS,
  LOAD_PLAY_OPTIONS,
  LOAD_SERIES_CONTENT,
  LOAD_EPISODE_SCHEDULES,
  LOAD_EPISODE_PAGE_CONTAINERS,
  LOAD_ON_DEMAND_OPTIONS,
  LOAD_LIST_OF_TEAMS,
} from "./actions";

const initialState = {
  containers: null,
  content: null,
  seriesContent: null,
  similarItems: null,
  schedules: null,
  onDemandOptions: null,
  contentUserData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EPISODE_PAGE_CONTAINERS:
      return { ...state, containers: action.content };
    case LOAD_SIMILAR_SERIES:
      return { ...state, similarItems: action.content, totalSimilarItems: action.total };
    case LOAD_PROGRAM_DETAILS:
      return { ...state, content: action.content };
    case LOAD_PLAY_OPTIONS:
      return { ...state, contentUserData: action.content };
    case LOAD_SERIES_CONTENT:
      return { ...state, seriesContent: action.content };
    case LOAD_EPISODE_SCHEDULES:
      return { ...state, schedules: action.content };
    case LOAD_ON_DEMAND_OPTIONS:
      return { ...state, onDemandOptions: action.content };
    case LOAD_LIST_OF_TEAMS:
      return { ...state, listOfTeams: action.content };
    default:
      return state;
  }
};
