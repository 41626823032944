import request from "../../service/request";
import constants from "../constants";
import paginationConstants from "../constants/pagination";
import { addQueryParamsToString, getKidExclusionRating } from "../utils";

const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const { INITIAL_START_INDEX, INITIAL_END_INDEX_VIEW_ALL } = paginationConstants;

const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

function getCastDetail(
  appProvider,
  title,
  userProfile = null,
  pcLevel = null,
  startIndex = INITIAL_START_INDEX,
  endIndex = INITIAL_END_INDEX_VIEW_ALL,
  cancelTokenSource = null
) {
  const withCredentials = appProvider.userCluster === "R";
  const queryParams = {
    filter_uaGroupType: "MOVIE,TVSHOW",
    from: startIndex,
    to: endIndex,
    maxResults: endIndex + 1,
  };

  let url = appProvider.config.avs_api_urls.search_unified_assets;
  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$QUERY", "filter_crew");
  url = url.replace("$query", title);

  if (appProvider.channelMapID) {
    queryParams.filter_regionId = appProvider.channelMapID;
  }

  if (appProvider.panicMode && withCredentials) {
    queryParams.filter_technicalPackages = appProvider.svodPackageIds;
    queryParams.filter_pcLevel = appProvider.pcLevel;
  } else if (pcLevel) {
    queryParams.filter_pcLevel = pcLevel;
  }

  if (queryParams.filter_pcLevel) {
    getKidExclusionRating(userProfile, queryParams);
  }

  url = addQueryParamsToString(url, queryParams, true);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request({ url: url, method: "GET", withCredentials }, cancelTokenSource, appProvider);
}

export default getCastDetail;
