import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import styles from "./style.scss";

import {
  showToastNotification,
  showModalPopup,
  toggleSpinningLoaderAction,
  addFavourites,
  deleteFavourites,
  hideBookmark,
} from "../../App/state/actions";
import { setRecordingAction } from "../../pages/RecordingsPage/state/actions";
import {
  convertFromPixelString,
  convertToPercentageString,
  getQueryParamsByName,
  isPastProgram,
  getAutoGeneratedObject,
  getAutogeneratedEpisodeString,
} from "../../shared/utils";
import {
  getRecordingCTAs,
  getRecordingInfo,
  setRecording,
  getRecordingSystemType,
} from "../../shared/utils/recordingHelper";
import { isItemTypeEpisode, isItemTypeMovie } from "../../shared/utils/content";
import { handleImageError } from "../../shared/utils/image";
import useAppLanguage from "../../shared/hooks/useAppLanguage";

import routeConstants from "../../shared/constants/routes";
import playerConstants from "../../shared/constants/player";
import constants from "../../shared/constants";
import recordingConstants from "../../shared/constants/recordingConstants";
import error from "../../shared/constants/error";

import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";
import { useReducers } from "../../shared/hooks/useReducer";

import ContextualMenu from "../ContextualMenu";

const PLAY_ICON = process.env.PUBLIC_URL + "/images/CTA-PlayCircle.svg";
const RESTART_ICON = process.env.PUBLIC_URL + "/images/Restart.svg";
const IN_HOME_ICON = process.env.PUBLIC_URL + "/images/In_Home.svg";
const OUT_OF_HOME_ICON = process.env.PUBLIC_URL + "/images/Out_of_home.svg";
const RECORDING_NOW_BADGE_EN = process.env.PUBLIC_URL + "/images/Recording_Now_EN.svg";
const RECORDING_NOW_BADGE_FR = process.env.PUBLIC_URL + "/images/Recording_Now_FR.svg";
const infoIcon = `${process.env.PUBLIC_URL}/images/info-icon.svg`;
const favouriteIcon = `${process.env.PUBLIC_URL}/images/favourite_white_update.svg`;
const favouriteActiveIcon = `${process.env.PUBLIC_URL}/images/favourite_active_channel.svg`;
const recordEpisodeIcon = `${process.env.PUBLIC_URL}/images/set_recording.svg`;
const recordSeriesIcon = `${process.env.PUBLIC_URL}/images/record_series.svg`;
const removeIcon = `${process.env.PUBLIC_URL}/images/remove.svg`;

const { LIVE_PLAYER, ON_DEMAND_PLAYER, RECORDING_PLAYER } = routeConstants;
const { PLAYBACK_TYPES } = playerConstants;
const { REDUCER_TYPE, MODAL_TYPES, CONTENT_ITEM_TYPES } = constants;
const { RECORDING_PARAMS, RECORDING_PACKAGES } = recordingConstants;
const { API_STATUS_CODES } = error;

function SwimlaneItem(props) {
  const {
    channelLogo,
    entitlements,
    isUHD,
    hasRecordingAssets,
    id,
    itemClickAnalyticsCallback = () => null,
    progressPercentage,
    recording,
    route,
    subtitle,
    thumbnail,
    title,
    isUnifiedAsset,
    parentId,
    showContextMenu,
    defaultRoute,
    program,
    setSelectedAssetRecordingInfo,
    updateRecordingHandler,
    setRecordingAction,
    showFavouriteContextMenuOption,
    isResumeSwimlane,
    refreshFeedRef,
    config,
    analyticsTitle,
    swimlaneTitle,
  } = props;
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const history = useHistory();

  const { performPlaybackChecks } = usePlaybackChecks();
  const { isRestartLiveTVEnabled } = useReducers(REDUCER_TYPE.APP).featureToggles;
  const [overImageText, setOverImageText] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [isAddedToFavourites, setIsAddedToFavourites] = useState(false);
  const isInHome = useSelector(({ app }) => app.isInHome);
  const userProfile = useSelector(({ app }) => app.userProfile);
  const featureToggles = useSelector(({ app }) => app.featureToggles);
  const favouriteAssets = useSelector(({ app }) => app.favouriteAssets);
  const appProvider = useSelector(({ app }) => app.provider);
  const recordingsList = useSelector(({ recording }) => recording.recordingsList);
  const { isAppLanguageFrench } = useAppLanguage();
  const { isGuideContextMenuEnabled: isContextMenuEnabled, isFavouritesEnabled, isRecordingEnabled } = featureToggles;
  const isTeamsSwimlane = swimlaneTitle === translate("teams");

  const isPlayerRoute = route.includes("player") || false;
  const isBlockedByOOH = entitlements.isInHomeRequired && !isInHome;
  const isPlayable = isPlayerRoute && !isUHD && !isBlockedByOOH;

  const getStatusIcons = () => {
    const statusIcons = [];
    if (recording.iconSrc) {
      statusIcons.push(
        <img key={"recordingIcon-" + id} className="recording-icon" src={recording.iconSrc} alt={"Recording"} />
      );
    }

    if (
      entitlements &&
      (entitlements.isInHomeRequired || entitlements.isFromGhostChannel || entitlements.isRestartable)
    ) {
      if (entitlements.isInHomeRequired || entitlements.isFromGhostChannel) {
        statusIcons.push(
          <img
            key={"statusIcon-" + id}
            className="status-icon"
            src={isInHome && !entitlements.isFromGhostChannel ? IN_HOME_ICON : OUT_OF_HOME_ICON}
            alt={isInHome && !entitlements.isFromGhostChannel ? "In home" : "Out of home"}
          />
        );
      }

      if (entitlements.isRestartable && isRestartLiveTVEnabled && !isBlockedByOOH && !entitlements.isFromGhostChannel) {
        statusIcons.push(<img key={"restartIcon-" + id} className="status-icon" src={RESTART_ICON} alt={"Restart"} />);
      }
    }

    // Only want to see a maximum of two icons
    return statusIcons.slice(0, 2);
  };

  useEffect(() => {
    if (favouriteAssets?.length > 0) {
      const isAssetAdded = favouriteAssets.some((fav) => {
        return program?.metadata?.extendedMetadata?.dlum?.uaId?.toLowerCase() === CONTENT_ITEM_TYPES.movie
          ? fav.metadata?.extendedMetadata?.dlum?.uaId === program?.metadata?.extendedMetadata?.dlum?.uaId
          : fav.metadata?.extendedMetadata?.dlum?.uaGroupId === program?.metadata?.extendedMetadata?.dlum?.uaGroupId;
      });
      setIsAddedToFavourites(isAssetAdded);
    }
  }, [favouriteAssets, program]);

  const favouriteAction = useCallback(() => {
    toggleSpinningLoaderAction(true, "spinner");
    isAddedToFavourites
      ? dispatch(deleteFavourites(appProvider, program, program?.metadata?.extendedMetadata?.dlum?.uaGroupType))
          .then(() => {
            dispatch(
              showToastNotification(
                translate("favourite_program_removed").replace("{Program name}", program.metadata.title)
              )
            );
          })
          .catch(() => console.error("Failed to remove", program.metadata.title, "from favourites"))
          .finally(() => toggleSpinningLoaderAction(false))
      : dispatch(addFavourites(appProvider, program, program?.metadata?.extendedMetadata?.dlum?.uaGroupType))
          .then((res) => {
            if (res === API_STATUS_CODES.OK) {
              dispatch(
                showToastNotification(
                  translate("favourite_program_added").replace("{Program name}", program.metadata.title)
                )
              );
            }
          })
          .catch(() => console.error("Failed to add", program.metadata.title, "to favourites"))
          .finally(() => toggleSpinningLoaderAction(false));
  }, [isAddedToFavourites, appProvider, program, translate, dispatch]);

  const getMetadataComponents = () => {
    const metadataChildren = [];
    // Determine what elements to render for the metadata
    let maxTitleContainerWidth = thumbnail.dimensions.width;
    if (channelLogo) {
      metadataChildren.push(
        <img key={"channelLogo-" + id} className="channel-logo" src={channelLogo} alt="Channel logo" />
      );

      // Available space for title, subtitle, and status icons decreases when we have a channel logo
      maxTitleContainerWidth =
        maxTitleContainerWidth -
        convertFromPixelString(styles.channelLogoWidth) -
        convertFromPixelString(styles.channelLogoRightPadding);
    }

    const textChildren = [];
    if (title) {
      const statusIcons = getStatusIcons();
      textChildren.push(
        <div key={"titleContainer-" + id} className="title-container">
          <p key={"title-" + id} className="title">
            {title}
          </p>
          {statusIcons}
        </div>
      );
    }

    if (recording.isRecordingNow) {
      textChildren.push(
        <img
          key={"recording-badge-" + id}
          src={isAppLanguageFrench ? RECORDING_NOW_BADGE_FR : RECORDING_NOW_BADGE_EN}
          alt="Recording now"
        />
      );
    } else if (subtitle.default) {
      let subtitleText = subtitle.default;
      // support alternative subtitle on hover
      if (subtitle.hover && isHovering) {
        subtitleText = subtitle.hover;
      }

      textChildren.push(
        <p key={"subtitle-" + id} className="subtitle" style={subtitle.styleOverride}>
          {subtitleText}
        </p>
      );
    }

    if (textChildren.length) {
      metadataChildren.push(
        <div
          key={"textContainer-" + id}
          className="text-container"
          style={{
            maxWidth: maxTitleContainerWidth,
          }}
        >
          {textChildren}
        </div>
      );
    }

    return metadataChildren;
  };

  /**
   * Handles thumbnail fallback when the thumbnail fails to load
   * @param {Error} e Error from thumbnail img failing to load
   * @param {Object} thumbnailInfo The item's thumbnail properties
   */
  const handleThumbnailError = (e, thumbnailInfo) => {
    handleImageError(e, thumbnailInfo.fallbackSrc);
    setOverImageText(thumbnailInfo.alt);
  };

  const onHoverIn = () => {
    setIsHovering(true);
  };

  const onHoverOut = () => {
    setIsHovering(false);
  };

  /**
   * Swimlane item click handler used to prevent navigation when necessary or to trigger other navigational side effects
   * such as analytics
   * @param {SyntheticEvent} event onClick event
   */
  const onItemClick = (event) => {
    if (isUHD) {
      event.preventDefault();
      dispatch(showToastNotification(translate("recordings_restriction_telustvplus")));
    } else if (!isUnifiedAsset) {
      event.preventDefault();
      dispatch(showModalPopup(MODAL_TYPES.ERROR, { message: "error_non_unified", isCloseable: true })); // showing error for non-UA assets
    } else if (isPlayerRoute) {
      event.preventDefault();
      if (isPlayable) {
        const isLiveRoute = route?.includes(LIVE_PLAYER.route);
        const isVodRoute = route?.includes(ON_DEMAND_PLAYER.route);
        const isRecordingsRoute = route?.includes(RECORDING_PLAYER.route);

        let contentId, playerRoute;

        const vodPlaybackType = Object.values(PLAYBACK_TYPES).find((playbackType) => route?.includes(playbackType));
        const contentType = getQueryParamsByName("contentType", route);
        const externalId = getQueryParamsByName("externalId", route);

        if (isLiveRoute) {
          contentId = getQueryParamsByName("stationId", route);
          playerRoute = LIVE_PLAYER.route;
        } else {
          contentId = getQueryParamsByName("playbackId", route);
          if (isVodRoute) {
            playerRoute = ON_DEMAND_PLAYER.route;
          }
          if (isRecordingsRoute) {
            playerRoute = RECORDING_PLAYER.route;
          }
        }
        if (isRecordingsRoute && !hasRecordingAssets) {
          dispatch(showToastNotification(translate("recordings_restriction_notplayable")));
        } else {
          performPlaybackChecks(
            null,
            vodPlaybackType,
            parseInt(contentId),
            contentType,
            playerRoute,
            null,
            externalId,
            null,
            (userData) => itemClickAnalyticsCallback(userData),
            null,
            analyticsTitle
          );
        }
      } else {
        let errorMsgKey, errorIconSrc;
        if (entitlements.isInHomeRequired && !isInHome) {
          errorMsgKey = "message_in_home";
          errorIconSrc = OUT_OF_HOME_ICON;
        } else {
          console.error("Item has player route but isn't playable and the reason was not identified - props = ", props);
        }

        if (errorMsgKey) {
          dispatch(showToastNotification(translate(errorMsgKey), errorIconSrc));
        }
      }
    }
  };

  const contextMenuItems = [];
  const regionId = appProvider?.channelMapID;
  const recordingSystemType = useMemo(
    () => (userProfile?.isLoggedIn ? getRecordingSystemType(userProfile) : null),
    [userProfile]
  );

  const isMR = recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;

  const programRecordingInfo = useMemo(() => {
    if (program?.metadata?.isRecordable && recordingsList && isRecordingEnabled) {
      return getRecordingInfo(isMR, program, recordingsList, regionId);
    }
    return null;
  }, [program, recordingsList, isRecordingEnabled, isMR, regionId]);

  const recordingCTA = useMemo(() => {
    if (programRecordingInfo) {
      const eventRecordingSet = programRecordingInfo.recordingEventScheduledCheck;
      const seriesRecordingSet = programRecordingInfo.recordingSeriesScheduledCheck;
      const seriesRecordingConflicted = programRecordingInfo.recordingSeriesConflictCheck;
      const eventRecordingConflicted = programRecordingInfo.recordingEventConflictCheck;
      const cPVRRecordingItem = programRecordingInfo.eventRecordingItem;
      const isRecordingIconAvailable =
        seriesRecordingSet ||
        seriesRecordingConflicted ||
        eventRecordingSet ||
        eventRecordingConflicted ||
        cPVRRecordingItem
          ? true
          : false;
      /**
       * Using the recording status (set or conflict) to check if the icon needs to be provide or not.
       * Currently we are not showing hollow recording icon.
       */
      if (isRecordingIconAvailable) {
        return getRecordingCTAs(
          isMR,
          programRecordingInfo,
          program,
          eventRecordingConflicted,
          eventRecordingSet,
          seriesRecordingConflicted,
          seriesRecordingSet
        );
      }
    }
    return null;
  }, [programRecordingInfo, isMR, program]);

  const programCellType = useMemo(() => {
    const programInfo = program?.metadata;
    if (isItemTypeMovie(programInfo)) {
      return CONTENT_ITEM_TYPES.movie;
    } else if (isItemTypeEpisode(programInfo)) {
      return CONTENT_ITEM_TYPES.episode;
    }
    return null;
  }, [program]);

  /**
   * Schedule a recording and save the response in redux
   *
   * @param {String} typeOfRecording - the recording type (event)
   * @param {Object} recordingInfo - recording information used to set the recording
   */
  const scheduleRecording = useCallback(
    (typeOfRecording, recordingInfo) => {
      if (recordingInfo) {
        setRecording(typeOfRecording, appProvider, recordingInfo.assetToRecord, setRecordingAction, isMR);
      }
    },
    [appProvider, isMR, setRecordingAction]
  );

  // Recording buttons logic
  if (programRecordingInfo && !isPastProgram(program?.airingEndTime)) {
    if (program.channel && program.metadata) {
      // Set/Edit single recording button
      const autoGeneratedObject = getAutoGeneratedObject(programRecordingInfo.assetToRecord?.metadata);
      const episodeInfo = getAutogeneratedEpisodeString(
        autoGeneratedObject,
        programRecordingInfo.assetToRecord?.metadata ?? programRecordingInfo,
        false
      );
      if (
        !recordingCTA &&
        (programCellType === CONTENT_ITEM_TYPES.movie || programCellType === CONTENT_ITEM_TYPES.episode)
      ) {
        const labelText = episodeInfo
          ? `${translate(RECORDING_PARAMS.RECORD)} ${episodeInfo}`
          : translate(RECORDING_PARAMS.RECORD);
        contextMenuItems.push({
          iconKey: "set-single-recording-icon",
          iconSrc: recordEpisodeIcon,
          label: labelText,
          onClickHandler: (event) => {
            event.stopPropagation();
            setSelectedAssetRecordingInfo(programRecordingInfo);
            scheduleRecording(RECORDING_PARAMS.EVENT, programRecordingInfo);
          },
        });
      } else if (
        recordingCTA?.actionType === RECORDING_PARAMS.EDIT_REC ||
        recordingCTA?.actionType === RECORDING_PARAMS.CANCEL_REC
      ) {
        const labelText =
          recordingCTA.actionType === RECORDING_PARAMS.EDIT_REC
            ? isMR
              ? translate(RECORDING_PARAMS.EDIT)
              : `${translate(RECORDING_PARAMS.EDIT)} ${episodeInfo}`
            : translate(recordingCTA.actionType);
        contextMenuItems.push({
          iconKey: "edit-single-recording-icon",
          iconSrc: recordingCTA.recordingIcon,
          label: labelText,
          onClickHandler: (event) => {
            event.stopPropagation();
            updateRecordingHandler(programRecordingInfo);
          },
        });
      }
      // Set/Edit series recording button
      // Note: for MR recording, recordingCTA is returned as RECORDING_PARAMS.EDIT_REC no matter the recording is series or event, so we need to check the actionType for RECORDING_PARAMS.EDIT_REC, too
      if (
        recordingCTA?.actionType !== RECORDING_PARAMS.EDIT_SERIES &&
        recordingCTA?.actionType !== RECORDING_PARAMS.EDIT_REC &&
        recordingCTA?.actionType !== RECORDING_PARAMS.CANCEL_REC &&
        programCellType === CONTENT_ITEM_TYPES.episode
      ) {
        contextMenuItems.push({
          iconKey: "set-series-recording-icon",
          iconSrc: recordSeriesIcon,
          label: translate(RECORDING_PARAMS.RECORD_SERIES),
          onClickHandler: (event) => {
            event.stopPropagation();
            setSelectedAssetRecordingInfo(programRecordingInfo);
            scheduleRecording(RECORDING_PARAMS.SERIES, programRecordingInfo);
          },
        });
      } else if (recordingCTA?.actionType === RECORDING_PARAMS.EDIT_SERIES) {
        contextMenuItems.push({
          iconKey: "edit-series-recording-icon",
          iconSrc: recordingCTA.recordingIcon,
          label: translate(RECORDING_PARAMS.EDIT),
          onClickHandler: (event) => {
            event.stopPropagation();
            updateRecordingHandler(programRecordingInfo);
          },
        });
      }
    }
  }

  contextMenuItems.push({
    iconKey: "details-icon",
    iconSrc: infoIcon,
    label: translate("details"),
    onClickHandler: () => {
      if (!isUnifiedAsset) {
        showModalPopup(MODAL_TYPES.ERROR, { message: "error_non_unified", isCloseable: true }); // showing error for non-UA assets
      } else {
        history.push(defaultRoute);
      }
    },
  });

  const shouldHaveFavOption =
    showFavouriteContextMenuOption &&
    (program?.metadata?.extendedMetadata?.dlum?.uaGroupType?.toLowerCase() === CONTENT_ITEM_TYPES.movie ||
      (program?.metadata?.extendedMetadata?.dlum?.uaGroupType?.toLowerCase() === CONTENT_ITEM_TYPES.tvshow &&
        program?.metadata?.extendedMetadata?.dlum?.uaGroupId));

  if (userProfile?.isLoggedIn && isFavouritesEnabled && shouldHaveFavOption) {
    contextMenuItems.push({
      iconKey: "favourite-icon",
      iconSrc: isAddedToFavourites ? favouriteActiveIcon : favouriteIcon,
      label: translate(isAddedToFavourites ? "action_unfavourite" : "action_favourite"),
      onClickHandler: (event) => {
        event.stopPropagation();
        favouriteAction();
      },
    });
  }

  if (isResumeSwimlane) {
    contextMenuItems.push({
      iconKey: "remove-icon",
      iconSrc: removeIcon,
      label: translate("remove_from_resume"),
      onClickHandler: (event) => {
        event.stopPropagation();
        const bookmark = program?.metadata?.bookmarks[0];
        const { bookmarkId, bookmarkSet, bookmarkTitle, startDeltaTime } = bookmark || {};
        const { contentType, contentId, bookmarkSetId } = bookmarkSet || {};
        const requestBody = {
          bookmark: {
            bookmarkId,
            bookmarkTitle,
            startDeltaTime,
            hidden: true,
          },
        };
        dispatch(
          hideBookmark(
            appProvider,
            contentType.toLowerCase().includes(RECORDING_PARAMS.RECORDING) ? RECORDING_PARAMS.RECORDING : contentType,
            contentId,
            bookmarkSetId,
            requestBody
          )
        )
          .then(() => {
            refreshFeedRef[config.id]();
          })
          .catch((err) => console.error("Failed to remove item from resume tray.", err));
      },
    });
  }

  const itemShowcard = (
    <a href={`#${route}`} onClick={onItemClick}>
      <div
        className={classNames("thumbnail-container", {
          "team-thumbnail": isTeamsSwimlane,
        })}
        style={{
          borderRadius: thumbnail.dimensions.borderRadius,
          height: isTeamsSwimlane
            ? thumbnail.dimensions.height
            : thumbnail.dimensions.height + 2 * convertFromPixelString(styles.thumbnailBorderThickness),
        }}
      >
        <img
          className="thumbnail"
          src={thumbnail.src ? `${thumbnail.src}?w=${isTeamsSwimlane ? "107.5" : thumbnail.dimensions.width}` : ""}
          alt={thumbnail.alt ?? ""}
          onError={(e) => handleThumbnailError(e, thumbnail)}
          style={{
            height: !isTeamsSwimlane && thumbnail.dimensions.height,
            width: !isTeamsSwimlane && thumbnail.dimensions.width,
            opacity: !!overImageText ? 0.5 : undefined,
            backgroundColor: !!overImageText ? "#000" : isTeamsSwimlane ? "#343434" : undefined,
          }}
        />
        {!!progressPercentage && (
          <div
            className="progress-bar-container"
            style={{
              width: thumbnail.dimensions.width,
            }}
          >
            <div className="progress-bar-background">
              <div
                className="progress-bar"
                style={{
                  width: convertToPercentageString(progressPercentage),
                }}
              ></div>
            </div>
          </div>
        )}
        {isPlayable && isHovering && <img className="thumbnail-icon" src={PLAY_ICON} alt="Play icon" />}
        {!!overImageText && <span className="text-over-image">{overImageText}</span>}
      </div>
      {swimlaneTitle !== translate("more_like_this") && (
        <div className="metadata-container">{getMetadataComponents()}</div>
      )}
    </a>
  );

  return (
    <div
      id={id}
      className="item-container"
      style={{
        width: thumbnail.dimensions.width + 2 * convertFromPixelString(styles.thumbnailBorderThickness),
        marginRight: thumbnail.dimensions.itemSpacing - 2 * convertFromPixelString(styles.thumbnailBorderThickness),
      }}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
    >
      {isContextMenuEnabled && showContextMenu && userProfile?.isLoggedIn ? (
        <ContextualMenu
          id={`context-menu-${config?.id}-${id}`}
          contextMenuItems={contextMenuItems}
          menuAppendTo={parentId}
        >
          {itemShowcard}
        </ContextualMenu>
      ) : (
        itemShowcard
      )}
    </div>
  );
}

SwimlaneItem.propTypes = {
  channelLogo: PropTypes.string,
  entitlements: PropTypes.shape({
    isRestartable: PropTypes.bool,
    isInHomeRequired: PropTypes.bool,
    isFromGhostChannel: PropTypes.bool,
  }),
  id: PropTypes.number,
  itemClickAnalyticsCallback: PropTypes.func,
  progressPercentage: PropTypes.number,
  recording: PropTypes.shape({
    iconSrc: PropTypes.string,
    isRecordingNow: PropTypes.bool,
  }),
  route: PropTypes.string,
  subtitle: PropTypes.shape({
    default: PropTypes.string,
    hover: PropTypes.string,
    styleOverride: PropTypes.object,
  }),
  thumbnail: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    dimensions: PropTypes.object,
    fallbackSrc: PropTypes.string,
  }),
  title: PropTypes.string,
  showFavouriteContextMenuOption: PropTypes.bool,
};
const mapDispatchToProps = {
  setRecordingAction,
};

export default connect(null, mapDispatchToProps)(SwimlaneItem);
