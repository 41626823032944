import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import middleware from "../shared/middleware";
import { reloadApp } from "../shared/utils";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../shared/utils/localStorage";
import errorConstants from "../shared/constants/error";
import storageConstants from "../shared/constants/storage";
import { setReloadApp, showToastNotification } from "../App/state/actions.js";
import PropTypes from "prop-types";
import { Event } from "@harnessio/ff-javascript-client-sdk";

const { AVS_ERROR_CODES } = errorConstants;
const { PREV_IN_PANIC_STATE, USER_HAS_LOGGED_IN } = storageConstants;
const { getConfig } = middleware;

const ECEManager = (props) => {
  const { appProvider, videoPlaying, setReloadApp, showToastNotification, panicState, harnessOnFunc, userProfile } =
    props;
  const intervalId = useRef(null);
  const panicModeState = useRef(appProvider.panicMode);
  const videoState = useRef(false);
  const appWillReload = useRef(false);
  const hasUserSeenEnabledToast = useRef(false);
  const { t: translate } = useTranslation();
  const panicModeConstants = appProvider.config.PANIC_MODE;

  /**
   * Displays a contextual service toast notification if appropriate
   *
   * @param {Boolean} isPanicMode - Flag indicating if panic mode is enabled
   */
  const handleToastNotification = useCallback(
    (isPanicMode) => {
      const wasPrevInPanic = getLocalStorage(PREV_IN_PANIC_STATE);
      if (isPanicMode && !hasUserSeenEnabledToast.current) {
        showToastNotification(translate("panic_mode_enabled"));
        setLocalStorage(PREV_IN_PANIC_STATE, true, panicModeConstants.PREV_PANIC_MODE_STORAGE_TTL_MS);
        hasUserSeenEnabledToast.current = true;
      } else if (!isPanicMode && wasPrevInPanic) {
        setLocalStorage(USER_HAS_LOGGED_IN, false);
        showToastNotification(translate("panic_mode_restored"));
        removeLocalStorage(PREV_IN_PANIC_STATE);
        removeLocalStorage(USER_HAS_LOGGED_IN);
      }
    },
    [panicModeConstants.PREV_PANIC_MODE_STORAGE_TTL_MS, showToastNotification, translate]
  );

  /**
   * Purpose of this method is to handle refreshing the app from a panic mode and leaving panic mode
   */
  const handleReloadApp = useCallback(async () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    if (videoState.current) {
      appWillReload.current = true;
      setReloadApp(true);
    } else {
      reloadApp(appProvider);
    }
  }, [appProvider, setReloadApp]);

  /**
   * Purpose of this method is poll CONFIG/PROPERTIES for panic state
   */
  const createTimer = useCallback(() => {
    videoState.current = videoPlaying.playing;
    if (intervalId.current === null) {
      intervalId.current = setInterval(async () => {
        let opusConfig;
        try {
          opusConfig = await getConfig(appProvider.config);
        } catch (err) {
          opusConfig = err;
        }
        if (
          (opusConfig?.data?.resultCode === "OK" && panicModeState.current) ||
          (opusConfig?.data?.resultCode === "KO" &&
            opusConfig.data.errorDescription === AVS_ERROR_CODES.PANIC_MODE &&
            !panicModeState.current)
        ) {
          handleReloadApp();
        }
      }, panicModeConstants[panicModeState.current ? "PANIC_INTERVAL" : "NORMAL_INTERVAL"]);
    }
  }, [handleReloadApp, panicModeConstants, videoPlaying, appProvider]);

  useEffect(() => {
    // If harness returns the panic value of legacy we will poll config/properties
    if (panicState === "legacy" && !panicModeState.current) {
      createTimer();
    } else if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    if (userProfile.isLoggedIn && !videoPlaying.playing && !appWillReload.current)
      handleToastNotification(panicModeState.current);
  }, [createTimer, panicState, handleReloadApp, handleToastNotification, videoPlaying.playing, userProfile]);

  // Used to detect flag changes in harness
  useEffect(() => {
    harnessOnFunc(Event.CHANGED, (flagInfo) => {
      if (flagInfo.flag === "panic") {
        const panicObj = JSON.parse(flagInfo.value);
        const panicState = panicObj.state;
        if (
          ((panicState === "full" || panicState === "partial") && !panicModeState.current) ||
          ((panicState === "none" || panicState === "legacy") && panicModeState.current)
        ) {
          handleReloadApp();
        }
      }
    });
  }, [harnessOnFunc, handleReloadApp]);

  return <></>;
};

function mapStateToProps({ app }) {
  return {
    videoPlaying: app.videoPlaying,
    appProvider: app.provider,
    userProfile: app.userProfile,
  };
}

const mapDispatchToProps = {
  setReloadApp,
  showToastNotification,
};

ECEManager.propTypes = {
  userProfile: PropTypes.object,
  appProvider: PropTypes.object,
  videoPlaying: PropTypes.object,
  setReloadApp: PropTypes.func,
  panicState: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ECEManager);
