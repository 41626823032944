import React from "react";
import moment from "moment";
import { useRef } from "react";
import PropTypes from "prop-types";
import { convertDurationToWidth, getTimeslotTimestamp } from "../../shared/utils/epg";
import epgConstants from "../../shared/constants/epg";
import styled from "styled-components";
import classNames from "classnames";

const CalenderIcon = `${process.env.PUBLIC_URL}/images/Calendar.svg`;

const { TIMESLOT_MINUTES } = epgConstants;
const CurrentTimeIndicator = styled.div`
  background-color: ${(props) => props.theme.colours.focusedButtonBackground};
`;
/**
 * EPG time cell component
 *
 * @component
 */
export function TimeCell({ index, style, data, onCellClick, onHoverChanged }) {
  const cellRef = useRef(null);

  const handleCellClick = () => {
    onCellClick(index);
  };

  const handleMouseEnter = () => {
    onHoverChanged(index, true);
  };

  const handleMouseLeave = () => {
    onHoverChanged(index, false);
  };

  const { currentTime, epgRange } = data;
  const cellStartTime = moment(epgRange.start)
    .add(TIMESLOT_MINUTES * (index - 1), "minute")
    .valueOf();
  const isOnNow = getTimeslotTimestamp(currentTime) === cellStartTime;
  if (index === 0) {
    return null;
  }
  return (
    <div
      style={style}
      ref={cellRef}
      className={classNames("cell time-cell", { "on-now": isOnNow })}
      onClick={handleCellClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isOnNow && (
        <CurrentTimeIndicator
          className="current-time-indicator"
          style={{ width: convertDurationToWidth(currentTime - cellStartTime) }}
        />
      )}
      <span>{moment(cellStartTime).format("LT")}</span>
    </div>
  );
}

TimeCell.propTypes = {
  index: PropTypes.number,
  style: PropTypes.object,
  data: PropTypes.object,
};

/**
 * EPG time cell component that is sticky-positioned on the left side of the screen.
 * When clicking on this component, it will open a calendar where we can select the date and time.
 * Upon selection, the EPG will scroll to the chosen date and time.
 * @component
 */
export function CornerTimeCell({ style, handleCalenderClick, dateTimeSelected, translate, resetClicked }) {
  const getSelectedDate = (date) => {
    if (!date) return translate("today");
    const selectedDate = moment(date, "ddd DD MMM YYYY hh:mm A");
    const currentDate = moment();
    if (selectedDate.isSame(currentDate, "day")) {
      return translate("today");
    } else if (selectedDate.isSame(currentDate.clone().subtract(1, "day"), "day")) {
      return translate("yesterday");
    } else if (selectedDate.isSame(currentDate.clone().add(1, "day"), "day")) {
      return translate("tomorrow");
    } else {
      return selectedDate.format("ddd DD MMM");
    }
  };

  return (
    <div style={style} className="cell time-cell corner" onClick={handleCalenderClick}>
      <div className="date-text-container">
        <img src={CalenderIcon} alt="Calendar Icon" className="calendar-icon" />
        <span className="selected-date-text">{getSelectedDate(dateTimeSelected?.value)}</span>
      </div>
    </div>
  );
}

CornerTimeCell.propTypes = {
  style: PropTypes.object,
  targetIconClickHandler: PropTypes.func,
  timelineScrollOffset: PropTypes.number,
  onNowTimeCellScrollOffset: PropTypes.number,
};
