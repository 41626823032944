import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

/**
 * Radio button component
 * @component
 */
const RadioButton = (props) => {
  const { title, checked, onChangeHandler, className, name, radioClassValue } = props;
  let containerClass = "radio-container";
  containerClass += className ? " " + className : "";

  return (
    <label className={containerClass}>
      <input type="radio" name={name} checked={checked} onChange={onChangeHandler} />
      <span className={`radio-checkmark ${radioClassValue}`}></span>
      <span className="label">{title}</span>
    </label>
  );
};

RadioButton.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};

RadioButton.defaultProps = {
  className: null,
  checked: false,
};

export default RadioButton;
