import styled from "styled-components";

export const VideoPlayerButton = styled.button(
  ({
    $backgroundColor = "transparent", // Default value for background color
    theme,
    width = "auto",
    height = "auto",
    disabled,
    $hoverBackgroundColor,
    $disableHoverEffect,
  }) => `
  background-color: ${
    $backgroundColor === "transparent" ? "transparent" : theme.colours[$backgroundColor] || $backgroundColor
  };
  border-width: 0;
  padding: 0;
  cursor: ${disabled ? "not-allowed" : "pointer"};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${width};
  height: ${height};
  opacity: ${disabled ? 0.5 : 1};

  img {
    display: block;
    display: inline-flex;
    margin: 14px 14px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &.live-player-recording-icon {
    background-color: ${theme.colours.translucentWhite || "rgba(255, 255, 255, 0.6)"};
    border-radius: 50%;
    bottom: 0;

    &:hover {
      background-color: ${
        !$disableHoverEffect && $hoverBackgroundColor
          ? $hoverBackgroundColor
          : theme.colours.translucentWhite || "rgba(255, 255, 255, 0.6)"
      };
    }
  }

  .btn-tooltip {
    position: absolute;
    width: auto;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: ${theme.colours.mineShaftGray};
    color: ${theme.colours.white};
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-in-out;
    white-space: nowrap;
    font: ${theme.typography.primaryFontHd};
    pointer-events: none;

    &.top {
      top: 0;
      transform: translateX(-50%) translateY(-130%);
      left: 50%;
    }

    &.right {
      left: 0%;
    }

    &.bottom {
      bottom: 0;
      transform: translateX(-50%) translateY(130%);
      left: 50%;
    }

    &.left {
      right: 0;
    }
  }

  &:hover {
    background-color: ${!$disableHoverEffect && $hoverBackgroundColor ? $hoverBackgroundColor : "transparent"};
    border-radius: 50%;
  }

  &:hover .btn-tooltip {
    visibility: visible;
    opacity: 1;
  }
`
);
