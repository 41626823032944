import request from "../../service/request";
import constants from "../constants";
import { addQueryParamsToString } from "../utils";

const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

export const getSeasonData = (appProvider, itemId) => {
  const withCredentials = appProvider.userCluster === "R";
  const queryParams = {};

  let url = appProvider.config.avs_api_urls.season_list;
  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$id", itemId);

  if (appProvider.channelMapID) {
    queryParams.filter_regionId = appProvider.channelMapID;
  }

  if (appProvider.panicMode && withCredentials) {
    queryParams.filter_technicalPackages = appProvider.svodPackageIds;
    queryParams.filter_pcLevel = appProvider.pcLevel;
  }

  url = addQueryParamsToString(url, queryParams, true);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request({ url: url, method: "GET", withCredentials }, null, appProvider);
};

export function getSeasonDetails(appProvider, seasonId) {
  const withCredentials = appProvider.userCluster === "R";
  const queryParams = {};

  let url = appProvider.config.avs_api_urls.season_details;
  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$id", seasonId);

  if (appProvider.channelMapID) {
    queryParams.filter_regionId = appProvider.channelMapID;
  }

  if (appProvider.panicMode && withCredentials) {
    queryParams.filter_technicalPackages = appProvider.svodPackageIds;
    queryParams.filter_pcLevel = appProvider.pcLevel;
  }

  url = addQueryParamsToString(url, queryParams, true);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request({ url: url, method: "GET", withCredentials }, null, appProvider);
}
