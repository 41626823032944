/**
 * Axios Request Wrapper
 */

import axios from "axios";
import constants from "../shared/constants";
import { setCookie } from "../shared/utils/cookie";
import storageConstants from "../shared/constants/storage";
import { setLocalStorage, getLocalStorage } from "../shared/utils/localStorage";
import { getSessionStorage } from "../shared/utils/sessionStorage";

const { AVS_REFRESH_TOKEN, CANARY } = storageConstants;

/**
 * Create an Axios Client
 */
const client = axios.create({
  baseURL: constants.API_BASE_URL,
});

/**
 * Request Wrapper with actions
 * NOTE: current Cancellation solution is based on CancelToken approach which is deprecated since axios v0.22.0, our axio is on v0.19.0 so AbortController may not be supported.
 * If we update axios in the future to greater than 0.22.0, we'll have to update to AbortController cancellation due to deprecation
 */
const request = function (options, cancelTokenSource = null, appProvider = null, usePromise = true) {
  options.headers = options.headers ? { ...options.headers, restful: "yes" } : { restful: "yes" };
  if (getSessionStorage(CANARY)) {
    options.headers["x-deployment-target"] = "canary";
  }
  const onSuccess = function (response) {
    // Check header for AVS-refresh-token and store it
    if (response?.headers[AVS_REFRESH_TOKEN]) {
      setCookie(AVS_REFRESH_TOKEN, response.headers[AVS_REFRESH_TOKEN], 365);
      setLocalStorage(AVS_REFRESH_TOKEN, response.headers[AVS_REFRESH_TOKEN]);
    }
    if (options.returnResponseObject) {
      return response;
    } else {
      if (response.data.resultCode === "KO") {
        return usePromise
          ? Promise.reject({
              url: response.config.url,
              message: response.data.message,
              type: "Server",
              code: response.data.errorDescription,
            })
          : {
              url: response.config.url,
              message: response.data.message,
              type: "Server",
              code: response.data.errorDescription,
            };
      } else {
        return response.data.resultObj;
      }
    }
  };

  const onError = function (error) {
    if (axios.isCancel(error)) {
      // Silence is golden
      return;
    } else if (error) {
      console.error("Request Failed:", error);
      let errorResponse = null;
      const errorDetails = error.response;
      if (errorDetails) {
        console.error("Status:", errorDetails.status);
        console.error("Data:", errorDetails.data);
        console.error("Headers:", errorDetails.headers);
        if (options.returnResponseObject) {
          errorResponse = errorDetails;
        } else {
          errorResponse = {
            url: errorDetails.config.url,
            message: errorDetails.data.message,
            type: "Server",
            code: errorDetails.data.errorDescription,
          };
        }
      } else {
        console.error("Error Message:", error.message);
        errorResponse = error.message
          ? { url: error.url, message: error.message, type: "browser", code: error.code }
          : { message: error.request, type: "server" };
      }
      return Promise.reject(errorResponse);
    }
  };

  if (cancelTokenSource) options["cancelToken"] = cancelTokenSource.token;

  return client(options).then(onSuccess).catch(onError);
};

export default request;
