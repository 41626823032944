import { LOAD_CURRENT_PROGRAMS } from "./actions";

const initialState = {
  currentPrograms: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CURRENT_PROGRAMS:
      return {
        ...state,
        currentPrograms: {
          ...state.currentPrograms,
          [action.segmentTimestamp]: action.programs,
        },
        isEpgFetched: action.isEpgFetched,
      };
    default:
      return state;
  }
};
