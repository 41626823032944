import { LOAD_ALL_SIMILAR_ITEMS } from "./actions";

const initialState = {
  content: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALL_SIMILAR_ITEMS:
      return { ...state, content: action.content, total: action.total };
    default:
      return state;
  }
};
