import styled from "styled-components";

const VodPlayerSidebarDetails = styled.div(
  ({ width }) => `
  min-height: 250px;
  margin-top: ${width <= 1366 ? "10px" : ""};
  margin-left: ${width <= 768 ? "25px" : ""};
  box-sizing: border-box;
  padding-bottom: 0;
  padding-left: 30px;
  padding-top: 30px;
  width: 100%;
  `
);

const VodPlayerSidebarEpisodeName = styled.div(
  ({ theme, width }) => `
  font-size: ${
    width <= 768
      ? theme.typography.smallFontSwimlane
      : width <= 1366
      ? theme.typography.mediumSwimlaneFontSize
      : theme.typography.mediumFontSize
  };
  line-height: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 10px;
  `
);

const VodPlayerSidebarEpisodeDetails = styled.div(
  ({ theme, width }) => `
  display: flex;
  margin-top: ${width <= 1366 ? "10px" : "17px"};
  margin-right: ${width <= 1366 ? "5px" : ""};
  font: ${theme.typography.primaryFont};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: inherit;
  font-size: ${width <= 768 ? theme.typography.mediumSwimlaneFontSize : ""};
  `
);

const VodPlayerSidebarEpisodeDesc = styled.div(
  ({ theme, width }) => `
  margin: 18px 0 8px;
  height: 54px;
  color: ${theme.colours.secondaryTableFontColor};
  font-size: ${
    width <= 768
      ? theme.typography.smallFontSwimlane
      : width <= 1366
      ? theme.typography.mediumFontSize
      : theme.typography.rentButtonFontSize
  };
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;;
  `
);

const VodPlayerSidebarList = styled.div(
  () => `
  overflow: auto;
  `
);

const VodPlayerSortFilters = styled.div(
  ({ theme, width }) => `
  min-height: 42px;
  margin: 13px 10px 7px 0;
  padding: 9px 15px 10px 24px;
  border-radius: 12px;
  position: relative;
  .button-container {
    width: fit-content;
    background-color: ${theme.colours.sharkGrayBackground};
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 15px;
    box-sizing: border-box;
    .dropdown-button {
      font-size: ${theme.typography.mediumSwimlaneFontSize};
      max-width: ${width <= 768 ? "243px" : width <= 1024 ? "239px" : width <= 1366 ? " 344px" : ""};
    }
    .dropdown-button div:first-child {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      max-width: 426px;
    }
  }
  `
);

export {
  VodPlayerSidebarDetails,
  VodPlayerSidebarEpisodeName,
  VodPlayerSidebarEpisodeDetails,
  VodPlayerSidebarEpisodeDesc,
  VodPlayerSidebarList,
  VodPlayerSortFilters,
};
