import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ViewAllButton from "../ImageButton";
import ImageButton from "../ImageButton";
import routeConstants from "../../shared/constants/routes";
import storageConstants from "../../shared/constants/storage";
import { getQueryParamsByName } from "../../shared/utils";
import { useTranslation } from "react-i18next";
import { removeSessionStorage, setSessionStorage } from "../../shared/utils/sessionStorage";
import { buildLinkInSwimlaneTitle } from "../../shared/analytics/helpers";
import constants from "../../shared/constants";
import { ANALYTICS_STORAGE_KEYS } from "../../shared/constants/analytics";
import "./style.scss";
import { useReducers } from "../../shared/hooks/useReducer";

const EDIT_ICON = process.env.PUBLIC_URL + "/images/Pen_Icon.svg";
const VIEW_ALL_ICON = process.env.PUBLIC_URL + "/images/white-chevron.svg";

const { REDUCER_TYPE } = constants;
const {
  LAST_SELECTED_SWIMLANE_ID,
  LAST_SCROLL_POSITION,
  LAST_VIEW_ALL_SCROLL_POSITION,
  LAST_VIEW_ALL_SCROLL_SWIMLANE_ID,
} = storageConstants;
/**
 * Swimlane title component
 *
 * @component
 * @param {Object} props
 */
function SwimlaneHeader(props) {
  const {
    feed,
    getSwimlaneRowNum = () => null,
    isFavouriteChannelsFeed = false,
    toggleChannelSidePanelAction,
    breadCrumbTitle,
  } = props;
  const { t: translate } = useTranslation();
  const { userProfile, featureToggles } = useReducers(REDUCER_TYPE.APP);
  const isKidsProfile = featureToggles.isUserProfilesEnabled && userProfile?.user?.profile?.profileData?.kidsProfile;
  const routeData = feed?.feed?.metadata?.EPGTarget;
  let isGuideRoute, isSearchRoute, isRecordingRoute, maxResultsParam;
  if (feed?.viewAllRoute) {
    isGuideRoute = feed.viewAllRoute.includes(routeConstants.GUIDE.route) && !isKidsProfile;
    isSearchRoute = feed.viewAllRoute.includes(routeConstants.SEARCH.route);
    isRecordingRoute = feed.viewAllRoute.includes(routeConstants.RECORDINGS.route) && !isKidsProfile;
    maxResultsParam = getQueryParamsByName("maxResults", feed.viewAllRoute);
  }
  const channelNumber = getQueryParamsByName("ChannelNumber", routeData);

  const getSwimlaneTooltip = () => {
    if (isGuideRoute) {
      return translate("view_guide");
    } else if (isRecordingRoute) {
      return translate("recordings");
    } else {
      return translate("view_all");
    }
  };

  const getRoute = () => {
    if (channelNumber) {
      return `${feed.viewAllRoute}?channelNumber=${channelNumber}`;
    }
    if (maxResultsParam) {
      return feed.viewAllRoute.replace(`&maxResults=${maxResultsParam}`, "");
    }
    if (breadCrumbTitle) {
      return `${feed.viewAllRoute}/${breadCrumbTitle}`;
    }
    return feed.viewAllRoute;
  };

  return (
    <div className={`header${isFavouriteChannelsFeed && feed?.items?.length > 0 ? " fav-channel-head" : ""}`}>
      <div className="title">
        {feed.viewAllRoute &&
        !isFavouriteChannelsFeed && // Currently we do not have viewAll page for fav channels
        (isGuideRoute ||
          isRecordingRoute ||
          feed.title === routeConstants.SUB_CATEGORIES_PAGE.title ||
          feed.itemsCount > 20) ? (
          <Link
            to={getRoute()}
            data-testid={feed.feed !== null ? feed.feed.Id : ""}
            onClick={() => {
              setSessionStorage(LAST_SELECTED_SWIMLANE_ID, feed?.feed?.id);
              setSessionStorage(LAST_SCROLL_POSITION, window.scrollY);
              setSessionStorage(
                ANALYTICS_STORAGE_KEYS.LINK,
                buildLinkInSwimlaneTitle(feed?.title, getSwimlaneRowNum(feed?.title))
              );
              removeSessionStorage(LAST_VIEW_ALL_SCROLL_POSITION, LAST_VIEW_ALL_SCROLL_SWIMLANE_ID);
            }}
          >
            <span>
              {feed.title}
              {!isSearchRoute && <span className="title-tooltip">{getSwimlaneTooltip()}</span>}
            </span>
            <ViewAllButton src={VIEW_ALL_ICON} alt="viewAll" buttonContainerStyles="button-container" />
          </Link>
        ) : (
          <span> {feed.title} </span>
        )}
      </div>
      {isFavouriteChannelsFeed && feed?.items?.length > 0 && (
        <span className="edit-favourite-channel-button" onClick={() => toggleChannelSidePanelAction(true)}>
          <ImageButton
            src={EDIT_ICON}
            buttonContainerStyles="button-container"
            alt=""
            className="edit-fav-channel-icon"
          />
          {translate("edit")}
        </span>
      )}
      {feed.subtitle && <div className="subtitle">{feed.subtitle}</div>}
    </div>
  );
}

SwimlaneHeader.propTypes = {
  feed: PropTypes.object.isRequired,
  getSwimlaneRowNum: PropTypes.func,
  userProfile: PropTypes.object,
  isFavouriteChannelsFeed: PropTypes.bool,
  toggleChannelSidePanelAction: PropTypes.func,
};

export default SwimlaneHeader;
