import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { addQueryParamsToString } from "../../shared/utils";
import { PIN_MODAL_TYPES } from "../PinModal";
import { useReducers } from "../../shared/hooks/useReducer";
import constants from "../../shared/constants";
import "./style.scss";

const { REDUCER_TYPE } = constants;

/**
 * Link to the PIN Management portal to reset purchase and parental PINs
 * @component
 * @param {Object} props
 */
const ForgotPinLink = forwardRef(function ForgotPinLink({ pinType, className = "", onClick }, ref) {
  const { t: translate } = useTranslation();
  const { provider: appProvider } = useReducers(REDUCER_TYPE.APP);

  return (
    <a
      ref={ref}
      target="_blank"
      rel="noopener noreferrer"
      href={addQueryParamsToString(appProvider.config.general.recover_pin_url, {
        pinType: pinType === PIN_MODAL_TYPES.PARENTAL ? "Parental" : "Rental",
      })}
      className={`pin-recover-link ${className ?? ""}`}
      onClick={onClick}
    >
      {translate("forgot_pin")}
      <img src={`${process.env.PUBLIC_URL}/images/external-icon.svg`} alt="" className="external-icon" />
    </a>
  );
});

export default ForgotPinLink;

ForgotPinLink.propTypes = {
  pinType: PropTypes.string.isRequired,
  className: PropTypes.string,
};
