// TODO: Refactor this component.
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./style.scss";
import PopUpOutline from "../PopUpOutline";
import { isItemTypeEpisode } from "../../shared/utils/content";
import constants from "../../shared/constants";
import recordingConstants from "../../shared/constants/recordingConstants";
import { deleteRecording, stopCPVRRecording, isCPVRRecordingInProgress } from "../../shared/utils/recordingHelper";
import { editRecordingAction, deleteRecordingAction } from "../../pages/RecordingsPage/state/actions";
import { useReducers } from "../../shared/hooks/useReducer";
import { trackGenericAction } from "../../shared/analytics/dataLayer";
import { ANALYTICS_EVENT_TYPES, MAPPED_CONTENT_TYPES } from "../../shared/constants/analytics";
import { mapRecordingSeriesMediaContent } from "../../shared/analytics/helpers";
import { getCustomContextMetadata, trackConvivaCustomEvent } from "../../shared/analytics/media";
import { getAutoGeneratedObject, getAutogeneratedEpisodeString } from "../../shared/utils";
import { logNREvent } from "../../shared/analytics/newRelic";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";

const { PAGE_CONTENT_ITEM_TYPES, REDUCER_TYPE } = constants;
const { RECORDING_PARAMS } = recordingConstants;

const CancelPopup = ({
  deleteRecordingAction,
  editRecordingAction,
  recordingInfo,
  programDetails,
  isMR,
  closeModal,
  isRecordingRecorded,
  recordingType,
  closeRecordingSettings,
  isProgramOfSeries,
  recordGUID,
  isRecordingNow,
  isInHome,
  userProfile,
}) => {
  const { t: translate } = useTranslation();
  const { provider: appProvider, featureToggles } = useReducers(REDUCER_TYPE.APP);
  const [recordingInfoDetails, setRecordingInfoDetails] = useState();
  const [headingText, setHeadingText] = useState("");
  const [messageText, setMessageText] = useState("");
  const [cancelCTAText, setCancelCTAText] = useState("");

  const defaultKeepCTAText = translate(RECORDING_PARAMS.KEEP_RECORDING);
  const [keepCTAText, setKeepCTAText] = useState(defaultKeepCTAText);
  const { isConvivaAppTrackerEnabled } = featureToggles;

  useEffect(() => {
    if (recordingInfo) {
      let recordingInfoDetails;
      if (isMR) {
        if (recordingInfo.recordingSeriesCheck) {
          const getSpecificRecordingAsset =
            isProgramOfSeries && recordGUID
              ? recordingInfo.recordingSeriesCheck.recordings?.length > 0 &&
                recordingInfo.recordingSeriesCheck.recordings.find((recording) => recording.recordGUID === recordGUID)
              : recordingInfo.recordingSeriesCheck;
          recordingInfoDetails = {
            ...getSpecificRecordingAsset,
            isProgramOfSeries,
            recordingDefinitionId: recordingInfo.recordingSeriesCheck.recordingDefinitionId,
          };
        } else if (recordingInfo.recordingEventScheduledCheck) {
          recordingInfoDetails = recordingInfo.recordingEventScheduledCheck;
        } else {
          recordingInfoDetails = recordingInfo;
        }
      } else {
        if (recordingType === RECORDING_PARAMS.SERIES) {
          if (recordingInfo.seriesRecordingItem?.metadata) {
            recordingInfoDetails = {
              recordId: recordingInfo.seriesRecordingItem.metadata.recordingSeriesId,
              keepRecordedEpisodes: !isRecordingRecorded,
            };
          }
        } else {
          if (recordingInfo.eventRecordingItem?.metadata) {
            recordingInfoDetails = {
              recordingsToDelete: [
                {
                  recordId: recordingInfo.eventRecordingItem.metadata.contentId,
                  startDeltaTime: recordingInfo.eventRecordingItem.metadata.startDeltaTime,
                },
              ],
            };
          }
        }
      }

      if (recordingInfoDetails) recordingInfoDetails.recordingInfo = recordingInfo;
      setRecordingInfoDetails(recordingInfoDetails);
    }
  }, [recordingInfo, isMR, isProgramOfSeries, recordGUID, recordingType, isRecordingRecorded]);

  useEffect(() => {
    let recordingMessage,
      headerText,
      primaryCTAKeyPrefix,
      cancelCTAText,
      keepCTAText,
      eventKeepCTAText,
      eventCancelCTAText;
    if (
      (!programDetails?.openRecordedEditPanel &&
        (!isRecordingRecorded || programDetails?.isRecordingRecorded === false)) ||
      (isRecordingNow && recordingType === RECORDING_PARAMS.EVENT)
    ) {
      recordingMessage =
        recordingType === RECORDING_PARAMS.EVENT
          ? translate("cancel_recording_single")
          : translate(RECORDING_PARAMS.SERIES_CANCEL_MESSAGE);
      eventCancelCTAText = translate(RECORDING_PARAMS.CANCEL_RECORDING);
      eventKeepCTAText = translate(RECORDING_PARAMS.KEEP_RECORDING);
      if (
        recordingInfoDetails?.recordings?.length === 1 &&
        recordingInfoDetails.recordingType === RECORDING_PARAMS.EVENT.toUpperCase()
      ) {
        if (recordingInfoDetails.recordings[0].metadata?.uaGroupType === PAGE_CONTENT_ITEM_TYPES.tvShow) {
          eventCancelCTAText = translate("cancel_episode");
          eventKeepCTAText = translate("keep_episode");
        }
      }
      headerText = translate(RECORDING_PARAMS.CANCEL_RECORDING);
      primaryCTAKeyPrefix = RECORDING_PARAMS.CANCEL;
      if (recordingType === RECORDING_PARAMS.SERIES) {
        cancelCTAText = translate(RECORDING_PARAMS.CANCEL_SERIES);
        keepCTAText = translate(RECORDING_PARAMS.KEEP_SERIES);
      } else {
        cancelCTAText = eventCancelCTAText;
        keepCTAText = eventKeepCTAText;
      }
    } else {
      recordingMessage = translate(RECORDING_PARAMS.RECORDING_DELETE_MESSAGE);
      headerText = translate(RECORDING_PARAMS.DELETE_RECORDING);
      primaryCTAKeyPrefix = RECORDING_PARAMS.DELETE;
      if (recordingType === RECORDING_PARAMS.SERIES) {
        const totalRecordings =
          recordingInfo?.recordedItems?.length || recordingInfo?.seriesRecordingItem?.episodeRecordings?.length;
        cancelCTAText = translate("delete_recordings_bulk_button").replace("%s", totalRecordings);
        keepCTAText = translate(RECORDING_PARAMS.KEEP_EPISODES);
        recordingMessage = translate("delete_recordings_bulk_body").replace("%s", totalRecordings);
      } else {
        cancelCTAText = headerText;
        keepCTAText = translate(RECORDING_PARAMS.KEEP_RECORDING);
      }
    }

    setHeadingText(headerText);

    let recordingCancelMessage = recordingMessage;
    if (recordingType === RECORDING_PARAMS.EVENT && programDetails) {
      const programMetadata = programDetails.metadata || programDetails.recordings?.[0]?.metadata;
      if (programMetadata) {
        const isContentEpisodic =
          isItemTypeEpisode(programMetadata) || programMetadata.uaGroupType === PAGE_CONTENT_ITEM_TYPES.tvShow;
        if (isContentEpisodic && recordingType === RECORDING_PARAMS.EVENT) {
          // Base on the test result on CPVR episode detail page, we need to make sure the following cancelCTAText and keepCTAText update only happens on event cancel popup
          const seasonNumber = programMetadata.seasonNumber || programMetadata.season;
          const episodeNumber = programMetadata.episodeNumber || programMetadata.episodeId;
          const autoGeneratedObject = getAutoGeneratedObject(programMetadata);
          const programName = getAutogeneratedEpisodeString(autoGeneratedObject, programMetadata, false);

          if (seasonNumber && episodeNumber) {
            recordingCancelMessage = translate(
              (!programDetails?.openRecordedEditPanel || isRecordingNow) &&
                (!isRecordingRecorded || programDetails?.isRecordingRecorded === false)
                ? RECORDING_PARAMS.RECORDING_CANCEL_MESSAGE
                : "delete_recording_program"
            ).replace("<program_name>", programName);
          }
          cancelCTAText = translate(`${primaryCTAKeyPrefix}_episode`);
          keepCTAText = translate("keep_episode");
        }
      }
    }

    setMessageText(recordingCancelMessage);
    setCancelCTAText(cancelCTAText);
    setKeepCTAText(keepCTAText);
  }, [
    isRecordingRecorded,
    programDetails,
    isProgramOfSeries,
    translate,
    recordingType,
    recordingInfo,
    recordingInfoDetails,
    isRecordingNow,
    isMR,
  ]);

  const cancelRecording = () => {
    const isSeriesEvent = recordingType === RECORDING_PARAMS.SERIES;
    if (
      !isMR &&
      isCPVRRecordingInProgress(recordingInfo.eventRecordingItem) &&
      recordingType === RECORDING_PARAMS.EVENT
    ) {
      trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_STOP, {
        isItemLive: true,
        isSeriesEvent,
        ...(isSeriesEvent
          ? mapRecordingSeriesMediaContent(recordingInfoDetails?.recordingInfo?.assetToRecord)
          : recordingInfoDetails?.recordingInfo?.assetToRecord),
        mappedContentType: MAPPED_CONTENT_TYPES.CPVR,
      });
      if (isConvivaAppTrackerEnabled) {
        trackConvivaCustomEvent(
          ANALYTICS_EVENT_TYPES.VIDEO_RECORD_STOP,
          getCustomContextMetadata(recordingInfoDetails, userProfile, appProvider, isInHome)
        );
      }
      logNREvent(NR_PAGE_ACTIONS.RECORDING_STOP);
      stopCPVRRecording(appProvider, recordingInfo, editRecordingAction);
    } else {
      if (recordingInfoDetails) {
        trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_DELETE, {
          isItemLive: true,
          isSeriesEvent,
          ...(isSeriesEvent
            ? mapRecordingSeriesMediaContent(recordingInfoDetails?.recordingInfo?.assetToRecord)
            : recordingInfoDetails?.recordingInfo?.assetToRecord),
          mappedContentType: isMR ? MAPPED_CONTENT_TYPES.LPVR : MAPPED_CONTENT_TYPES.CPVR,
        });
        if (isConvivaAppTrackerEnabled) {
          trackConvivaCustomEvent(
            ANALYTICS_EVENT_TYPES.VIDEO_RECORD_DELETE,
            getCustomContextMetadata(recordingInfoDetails, userProfile, appProvider, isInHome)
          );
        }
        // We need to send "cancel" in action as payload in deleteRecording() method when we try to cancel an ongoing recording(event/episode part of series/series).
        deleteRecording(
          appProvider,
          isMR,
          recordingInfoDetails,
          recordingType,
          deleteRecordingAction,
          !isRecordingRecorded || (isRecordingNow && isProgramOfSeries)
        );
      }
    }
    closeRecordingSettings();
    closeModal();
  };

  return (
    <React.Fragment>
      <div className="cancel-modal-backdrop"></div>
      <div className="cancel-modal-container">
        <div className="cancel-modal-popup-container">
          <PopUpOutline className="cancel-modal-popup">
            <div className="cancel-type">{headingText}</div>
            <div className="cancel-item">
              <div className="cancel-item-details">
                <div className="cancel-subtitle">
                  <p>{messageText}</p>
                </div>
                <button
                  className="cancel-item-title"
                  onClick={(event) => {
                    event.stopPropagation();
                    cancelRecording();
                  }}
                >
                  <p>{cancelCTAText}</p>
                </button>
                <button className="cancel-item-title shaded" onClick={() => closeModal()}>
                  <p>{keepCTAText}</p>
                </button>
              </div>
            </div>
          </PopUpOutline>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  editRecordingAction,
  deleteRecordingAction,
};

CancelPopup.propTypes = {
  recordingInfo: PropTypes.object.isRequired,
  programDetails: PropTypes.object,
  isMR: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isRecordingRecorded: PropTypes.bool,
  recordingType: PropTypes.string.isRequired,
  closeRecordingSettings: PropTypes.func,
  isProgramOfSeries: PropTypes.bool,
  recordGUID: PropTypes.string,
};

CancelPopup.defaultProps = {
  programDetails: null,
  isRecordingRecorded: false,
  closeRecordingSettings: () => null,
  isProgramOfSeries: false,
  recordGUID: "",
};

export default connect(null, mapDispatchToProps)(CancelPopup);
