import { useEffect, useRef } from "react";
import axios from "axios";

/**
 * Custom Hook for API requests cleanup on component unmount
 * @returns {object} - a CancelToken ref used to cancel an axios request
 */
export default function useCancelTokenSource() {
  const cancelTokenSource = useRef(axios.CancelToken.source()); // cancelTokenSource ref for requests unmount clean up

  useEffect(() => {
    const unmountTokenSource = cancelTokenSource.current;

    return () => {
      unmountTokenSource.cancel(); // silent cancellation of middleware requests
    };
  }, []);

  return cancelTokenSource.current;
}
