import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import SeoPageTags from "../../components/SeoPageTags";
import { PIN_MODAL_MODES, PIN_MODAL_TYPES } from "../../components/PinModal";
import middleware from "../../shared/middleware";
import { loadUserProfile, showToastNotification, showModalPopup } from "../../App/state/actions";
import ToggleSwitch from "../../components/ToggleSwitch";
import Button from "../../components/Button";
import ForgotPinLink from "../../components/ForgotPinLink";
import constants from "../../shared/constants";
import routeConstants from "../../shared/constants/routes";
import { trackWebAction, trackGenericAction } from "../../shared/analytics/dataLayer";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import { ACTION_VALUES, ANALYTICS_EVENT_TYPES, WEB_ACTION_EVENT_NAMES } from "../../shared/constants/analytics";
import { getGenericErrorEventHandler } from "../../shared/analytics/helpers";
import "./style.scss";

const { setPurchasePINAvailability, updatePurchasePIN, validatePurchasePIN } = middleware;
const { MODAL_TYPES } = constants;
const { SETTINGS } = routeConstants;

/**
 * Purchase PIN settings page component
 * @component
 * @param {object} props
 */

function PurchasePinPage(props) {
  const { t: translate } = useTranslation();
  const { appProvider, userProfile, loadUserProfile, showToastNotification, showModalPopup, featureToggles } = props;
  const { isUserProfilesEnabled } = featureToggles;
  const [isPurchasePinEnabled, setIsPurchasePinEnabled] = useState(false);
  const [isToggleEnabled, setIsToggleEnabled] = useState(false);
  const currentPinModalMode = useRef(PIN_MODAL_MODES.ACCESS);
  const pinConfirmHandler = useRef(null);
  const currentPinRef = useRef(null);
  const analyticsErrorEventHandlerRef = useRef(null);
  const { trackPageView } = useTrackPageView();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  useEffect(() => {
    const isEnabled = userProfile?.user?.profile?.profileData?.purchasePinEnabled === "Y";
    setIsPurchasePinEnabled(isEnabled);
    setIsToggleEnabled(isEnabled);
  }, [userProfile]);

  /**
   * Sets up the PIN modal and displays it
   * @param {String} pinModalMode Can be set or access
   * @param {String} errorMessage The debug message to display in the console if an error occurs
   * @param {Boolean} closeOnConfirm Flag indicating if modal should close after confirm is pressed
   */
  const displayPINModal = (pinModalMode, errorMessage, closeOnConfirm = true) => {
    const modalContent = {
      pinModalMode,
      pinModalType: PIN_MODAL_TYPES.PURCHASE,
      pinConfirmHandler: pinConfirmHandler.current,
      pinAnalyticsErrorEventHandler: analyticsErrorEventHandlerRef.current,
      pinErrorMessage: errorMessage,
      closeOnConfirm,
    };

    if (pinModalMode === PIN_MODAL_MODES.ACCESS) {
      modalContent["title"] = translate("purchase_pin_enter");
    }

    currentPinModalMode.current = pinModalMode;

    showModalPopup(MODAL_TYPES.PIN, modalContent);
  };

  const enablePin = () => {
    pinConfirmHandler.current = enablePinConfirmHandler;
    analyticsErrorEventHandlerRef.current = getGenericErrorEventHandler(
      ANALYTICS_EVENT_TYPES.PURCHASE_PIN_ENABLE_ERROR,
      ACTION_VALUES.PURCHASE_PIN_ENABLE,
      WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_ENABLE_ERROR
    );
    const errorMessage = "Error enabling PIN";
    displayPINModal(PIN_MODAL_MODES.SET, errorMessage, true);
  };

  /**
   * Set up the PIN modal for verifying the PIN
   * @param {Boolean} disablePin flag indicating if user is attempting to disable PIN
   * @param {String} errorEventType analytics error event type
   */
  const verifyPin = (disablePin, errorEventType) => {
    let errorMessage;

    if (disablePin) {
      pinConfirmHandler.current = disablePinConfirmHandler;
      analyticsErrorEventHandlerRef.current = getGenericErrorEventHandler(
        errorEventType,
        ACTION_VALUES.PURCHASE_PIN_DISABLE,
        WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_DISABLE_ERROR
      );
      errorMessage = "Error disabling PIN";
    } else {
      pinConfirmHandler.current = verifyPinConfirmHandler;
      analyticsErrorEventHandlerRef.current = getGenericErrorEventHandler(
        errorEventType,
        ACTION_VALUES.PURCHASE_PIN_RESET,
        WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_RESET_ERROR
      );
      errorMessage = "Error validating PIN";
    }

    displayPINModal(PIN_MODAL_MODES.ACCESS, errorMessage, disablePin);
  };

  /**
   * Set up the PIN modal for updating the PIN
   */
  const updatePin = () => {
    pinConfirmHandler.current = updatePinConfirmHandler;
    const errorMessage = "Error updating PIN";
    displayPINModal(PIN_MODAL_MODES.SET, errorMessage);
  };

  /**
   * Handler for PIN modal OK press during the disable PIN flow.
   * Attempts to disable the purchase PIN using the provided PIN.
   * @param {String} pin
   */
  const enablePinConfirmHandler = (pin) => {
    let promise = setPurchasePINAvailability(appProvider, true, pin).then(() => {
      trackWebAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_ENABLE_COMPLETE);
      loadUserProfile(appProvider);
      showToastNotification(translate("purchase_set_pin"));
    });

    return promise;
  };

  /**
   * Checks if the provided PIN matches the user's currently set PIN
   * @param {String} pin
   */
  const verifyPinConfirmHandler = (pin) => {
    let promise = validatePurchasePIN(appProvider, pin).then(() => {
      currentPinRef.current = pin;
      updatePin();
    });

    return promise;
  };

  /**
   * Update the user's PIN with the provided newPin
   * @param {String} newPin
   */
  const updatePinConfirmHandler = (newPin) => {
    let promise = updatePurchasePIN(appProvider, currentPinRef.current, newPin).then(() => {
      trackWebAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_RESET_COMPLETE);
      currentPinRef.current = null;
      loadUserProfile(appProvider);
      showToastNotification(translate("pin_purchase_updated"));
    });

    return promise;
  };

  /**
   * Handler for PIN modal OK press during the disable PIN flow.
   * Attempts to disable the purchase PIN using the provided PIN.
   * @param {String} pin
   */
  const disablePinConfirmHandler = (pin) => {
    let promise = setPurchasePINAvailability(appProvider, false, pin).then(() => {
      trackWebAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_DISABLE_COMPLETE);
      loadUserProfile(appProvider);
      showToastNotification(translate("purchase_control_applied"));
    });

    return promise;
  };

  return userProfile?.user?.profile?.profileData?.isMasterAccount === "N" ? (
    <Redirect to={SETTINGS.route} />
  ) : (
    <React.Fragment>
      <SeoPageTags title={translate("purchase_pin")} keywords={["optik", "telus"]} />
      <div className="purchase-pin-page">
        <h1 className="purchase-pin-heading">{translate("purchase_pin")}</h1>
        <p className="purchase-pin-desc">
          {translate(
            isUserProfilesEnabled ? "purchase_control_rating_lock" : "purchase_control_rating_lock_no_profiles"
          )}
        </p>

        <div className="purchase-pin-toggle-row">
          <span className="toggle-label">{translate("purchase_pin")}</span>
          <div className="toggle-pin-switch">
            <ToggleSwitch
              isChecked={isToggleEnabled}
              onToggleButtonChange={(toggleOn) => {
                if (toggleOn) {
                  trackWebAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_ENABLE_START);
                  enablePin();
                } else {
                  trackWebAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_DISABLE_START);
                  verifyPin(true, ANALYTICS_EVENT_TYPES.PURCHASE_PIN_DISABLE_ERROR);
                }
              }}
            />
          </div>
        </div>

        {isPurchasePinEnabled && (
          <div className="purchase-pin-action-container">
            <Button
              buttonStyles="clear-button"
              label={translate("change_pin_stb")}
              onClickHandler={() => {
                verifyPin(false, ANALYTICS_EVENT_TYPES.PURCHASE_PIN_RESET_ERROR);
                trackWebAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_RESET_START);
              }}
            />

            <ForgotPinLink
              pinType={PIN_MODAL_TYPES.PURCHASE}
              onClick={(event) =>
                trackGenericAction(ANALYTICS_EVENT_TYPES.EXIT_CLICK, {
                  name: "recover_purchase_pin",
                  URL: event.currentTarget.href,
                })
              }
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

function mapStateToProps({ app }) {
  return {
    userProfile: app.userProfile,
    appProvider: app.provider,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  loadUserProfile,
  showToastNotification,
  showModalPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchasePinPage);
