import React from "react";
import Logo from "../../components/Logo";
import { useTranslation } from "react-i18next";
import "./style.scss";

/**
 * Component that renders fallback error page when app crashes
 * @component
 */
function ErrorFallback() {
  const { t: translate } = useTranslation();

  return (
    <div className="fallback-error">
      <div className="app-logo">
        <Logo isClickable={false} />
      </div>
      <span className="error-text">
        <img className="warning" src={process.env.PUBLIC_URL + "/images/warning-icon.svg"} alt="warning" />
        {translate("sorry_error_occurred")}
      </span>
    </div>
  );
}

export default ErrorFallback;
