import middleware from "../../../shared/middleware";

const { getHubs } = middleware;

// Action types
export const LOAD_HUBS = "LOAD_HUBS";
export const UPDATE_ACTIVE_INDEX = "UPDATE_ACTIVE_INDEX";

// Action methods
function loadHubsSuccess(hubs) {
  return { type: LOAD_HUBS, hubs };
}

function loadHubsFailure() {
  return { type: LOAD_HUBS, hubs: [] };
}

export const loadHubs = (appProvider, userProfile) => {
  return async (dispatch) => {
    try {
      const hubs = await getHubs(appProvider);
      if (hubs) {
        if (appProvider.panicMode) hubs.containers = hubs.containers.filter((hub) => hub.metadata.MenuKey !== "Home");
        const { containers } = hubs;
        if (userProfile?.user?.profile?.profileData?.kidsProfile) {
          const kidsProfileHubs = containers?.filter((hub) => hub.metadata.keyControl === "kids_profile");
          dispatch(loadHubsSuccess(kidsProfileHubs?.length > 0 ? kidsProfileHubs : containers));
        } else {
          dispatch(loadHubsSuccess(containers?.filter((hub) => hub.metadata.Visibility !== "hidden")));
        }
      }
    } catch (error) {
      dispatch(loadHubsFailure());
    }
  };
};
