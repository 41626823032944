import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import constants from "../shared/constants";
import errorConstants from "../shared/constants/error";
import middleware from "../shared/middleware";
import { removeLocalStorage, getLocalStorage } from "../shared/utils/localStorage";
import { shouldRetryRefresh, reloadApp } from "../shared/utils";
import storageConstants from "../shared/constants/storage";
import moment from "moment";

const { extendUserSession, videoStreamHeartBeat, logout } = middleware;
const {
  SELECTED_CHANNEL,
  ACTIVE_PROFILE_USER_ID,
  USER_PROPERTY_TYPE,
  AVS_REFRESH_TOKEN,
  SELECTED_TIME,
  USER_HAS_LOGGED_IN,
} = storageConstants;

const KeepAliveManager = (props) => {
  const { appProvider, videoPlaying, keepAliveDelay, userProfile } = props;

  const renewUserSessionTimerId = useRef();
  const videoPlayerPayload = useRef();
  const quickPollEnabled = useRef(false);
  const { APP_ENVIRONMENTS, CASSANDRA_KEEPALIVE_INTERVAL, USER_SESSION_RENEWAL_INTERVAL } = constants;
  const { AVS_ERROR_CODES } = errorConstants;
  let performKeepAlive;
  const apiTimestamp = useRef();

  const removeUserSession = useCallback(() => {
    removeLocalStorage(AVS_REFRESH_TOKEN);
    removeLocalStorage(ACTIVE_PROFILE_USER_ID);
    removeLocalStorage(USER_PROPERTY_TYPE);
    removeLocalStorage(SELECTED_CHANNEL);
    removeLocalStorage(SELECTED_TIME);
    removeLocalStorage(USER_HAS_LOGGED_IN);
  }, []);

  const resetAppSession = useCallback(() => {
    if (renewUserSessionTimerId.current) {
      removeUserSession();
      const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;
      clearInterval(renewUserSessionTimerId.current);
      // We only want to force appside logout situation in Prod when the session becomes too stale to renew.

      removeLocalStorage(SELECTED_CHANNEL);
      removeLocalStorage("selectedCategories");
      window.location.href = appProvider.config.general.telus_logout_url.replace(
        "$redirectURI",
        encodeURIComponent(appProvider.config.general.AVS_config.redirectUri)
      );
    }
  }, [
    appProvider.config.general.AVS_config.redirectUri,
    appProvider.config.general.telus_logout_url,
    removeUserSession,
    APP_ENVIRONMENTS.PROD,
  ]);

  const setupKeepAlive = useCallback(
    (delayOverride = null) => {
      if (userProfile?.isLoggedIn) {
        clearInterval(renewUserSessionTimerId.current);
        if (delayOverride) {
          quickPollEnabled.current = true;
        } else {
          quickPollEnabled.current = false;
        }
        renewUserSessionTimerId.current = setInterval(() => {
          performKeepAlive();
        }, (delayOverride ? delayOverride : keepAliveDelay) * 1000);
      }
    },
    [performKeepAlive, userProfile, keepAliveDelay]
  );

  const retryTokenFetch = useCallback(
    (result, avsRefreshToken) => {
      if (shouldRetryRefresh(result?.data?.errorDescription) && avsRefreshToken === null) {
        // Attempt to renew and fetch a new token
        performKeepAlive(getLocalStorage(AVS_REFRESH_TOKEN));
      } else if (avsRefreshToken) {
        // If we fail to renew throw user into guest mode
        resetAppSession();
      } else if (result?.data?.errorDescription === AVS_ERROR_CODES.INVALID_STREAM_SESSION) {
        reloadApp(appProvider);
      } else if (result?.data?.resultCode !== "OK" && quickPollEnabled.current === false) {
        // Assume the service is down and poll ever 1 min
        setupKeepAlive(CASSANDRA_KEEPALIVE_INTERVAL);
      } else if (result?.data?.resultCode === "OK" && quickPollEnabled.current === true) {
        // Revert back to normal polling
        setupKeepAlive();
      }
    },
    [
      performKeepAlive,
      resetAppSession,
      setupKeepAlive,
      CASSANDRA_KEEPALIVE_INTERVAL,
      AVS_ERROR_CODES.INVALID_STREAM_SESSION,
      appProvider,
    ]
  );

  const callHeartbeat = useCallback(
    (avsRefreshToken = null) => {
      extendUserSession(appProvider, avsRefreshToken)
        .then((res) => {
          retryTokenFetch(res, avsRefreshToken);
        })
        .catch((err) => {
          retryTokenFetch(err, avsRefreshToken);
          console.error(err);
        });
    },
    [appProvider, retryTokenFetch]
  );

  performKeepAlive = useCallback(
    (avsRefreshToken = null) => {
      if (
        avsRefreshToken ||
        apiTimestamp.current === undefined ||
        moment().unix() - apiTimestamp.current >= USER_SESSION_RENEWAL_INTERVAL - 30
      ) {
        apiTimestamp.current = moment().unix();
        if (
          videoPlayerPayload.current.playing &&
          videoPlayerPayload.current.currentContentId &&
          videoPlayerPayload.current.assetId
        ) {
          videoStreamHeartBeat(
            appProvider,
            videoPlayerPayload.current.type,
            videoPlayerPayload.current.currentContentId,
            videoPlayerPayload.current.assetId,
            null,
            null,
            avsRefreshToken
          )
            .then((res) => {
              retryTokenFetch(res, avsRefreshToken);
            })
            .catch((err) => {
              retryTokenFetch(err, avsRefreshToken);
              console.error(err);
            });
        } else {
          callHeartbeat(avsRefreshToken);
        }
      }
    },
    [appProvider, callHeartbeat, retryTokenFetch, USER_SESSION_RENEWAL_INTERVAL]
  );

  const renewTokens = useCallback(
    (avsRefreshToken) => {
      callHeartbeat(avsRefreshToken);
    },
    [callHeartbeat]
  );

  appProvider.refreshToken = renewTokens;

  useEffect(() => {
    videoPlayerPayload.current = videoPlaying;
  }, [videoPlaying]);

  useEffect(() => {
    setupKeepAlive();
  }, [setupKeepAlive]);

  return <></>;
};

function mapStateToProps({ app }) {
  return {
    videoPlaying: app.videoPlaying,
    appProvider: app.provider,
    userProfile: app.userProfile,
  };
}

KeepAliveManager.propTypes = {
  appProvider: PropTypes.object,
  videoPlaying: PropTypes.object,
  keepAliveDelay: PropTypes.number,
  userProfile: PropTypes.object,
};

export default connect(mapStateToProps)(KeepAliveManager);
