import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RadioButton from "../RadioButton";
import CheckBox from "../CheckBox";
import Toggle from "../ToggleSwitch";
import "./FilterDropdownList.styles.js";
import { capitalize } from "../../shared/utils";
import {
  DropdownListContainer,
  ListLabel,
  FilterListItems,
  ToggleSwitchWrapper,
  ToggleSwitchTitle,
  ToggleImage,
  DropdownItemsContainer,
} from "./FilterDropdownList.styles";
import { useTheme } from "styled-components";

/**
 * Create Filter Dropdown List
 * @component
 * @param {Object} props
 */
function FilterDropdownList({
  className,
  list,
  onClickHandler,
  type,
  categoryIndex,
  selectedItem,
  screenWidth,
  isHoverEnabled,
  toggleChecked,
}) {
  const { t: translate } = useTranslation();
  const classStyleName = `dropdownList ${className}`;
  const selectedItemsArr = selectedItem ? selectedItem.split(",") : [];
  const theme = useTheme();

  const isAlreadySelected = (value) => {
    const isFound = selectedItemsArr.indexOf(value);
    if (isFound !== -1) return true;
    return false;
  };

  const filterChecked = (index, categoryIndex, event) => {
    onClickHandler(index, categoryIndex, event.target.checked, event);
  };

  return (
    <DropdownListContainer className={classStyleName}>
      {list.label && (
        <ListLabel data={"list-label"} theme={theme} width={screenWidth}>
          {capitalize(list.label)}
        </ListLabel>
      )}
      <FilterListItems
        data={"filter-list-items"}
        theme={theme}
        width={screenWidth}
        isHoverEnabled={isHoverEnabled}
        isToggleEnabled={list.isToggleEnabled}
      >
        {type === "radio" &&
          list.items &&
          list.items.map((item, index) => {
            let classValue,
              radioClassValue = "";
            if (item.isDisabled) {
              classValue = "filter-disabled";
              radioClassValue = "disabled";
            } else {
              classValue = isAlreadySelected(item.value) ? "filter-selected" : "";
            }
            return (
              <DropdownItemsContainer key={index} className={classValue} theme={theme} width={screenWidth}>
                <RadioButton
                  title={list.isLocalized ? translate(item.value) : item.value}
                  checked={isAlreadySelected(item.value)}
                  onChangeHandler={filterChecked.bind(this, index, categoryIndex)}
                  className={`dropdown-items-container ${classValue}`}
                  radioClassValue={radioClassValue}
                />
              </DropdownItemsContainer>
            );
          })}

        {type === "checkbox" &&
          list.items &&
          list.items.map((item, index) => {
            const classValue = isAlreadySelected(item.value) ? "filter-selected" : "";
            return (
              <DropdownItemsContainer key={index} className={classValue}>
                <CheckBox
                  title={list.isLocalized ? translate(item.value) : item.value}
                  checked={isAlreadySelected(item.value)}
                  onChangeHandler={filterChecked.bind(this, index, categoryIndex)}
                  className="dropdown-items-container"
                />
              </DropdownItemsContainer>
            );
          })}

        {type === "toggle" &&
          list.items &&
          list.items.map((item, index) => {
            const toggleClass = item.isDisabled ? "filter-disabled" : "";
            return (
              <ToggleSwitchWrapper data={"toggle-switch-wrapper"} key={index}>
                <ToggleImage data={"toggle-image"} src={item.image.src} alt={item.image.alt} />
                <ToggleSwitchTitle data={"toggle-switch-title"}>
                  {list.isLocalized ? translate(item.value) : item.value}
                </ToggleSwitchTitle>
                <Toggle
                  isChecked={toggleChecked}
                  onToggleButtonChange={onClickHandler}
                  data={"dropdown-items-container"}
                  disabled={item.isDisabled}
                  className={`dropdown-items-container ${toggleClass}`}
                />
              </ToggleSwitchWrapper>
            );
          })}
        {/* Rendering plain text with image if no checkbox, radio, or toggle is needed */}
        {type === "textWithImage" &&
          list.items &&
          list.items.map((item, index) => (
            <DropdownItemsContainer key={index} className={`${item.isDisabled ? "filter-disabled" : ""}`} theme={theme}>
              <span>{list.isLocalized ? translate(item.value) : item.value}</span>
              {item.image && <img src={item.image.src} alt={item.image.alt} className="watched-icon" />}
            </DropdownItemsContainer>
          ))}
      </FilterListItems>
    </DropdownListContainer>
  );
}

FilterDropdownList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.object.isRequired,
  onClickHandler: PropTypes.func,
  selectedItem: PropTypes.string,
  type: PropTypes.string.isRequired,
  toggleChecked: PropTypes.bool,
};

FilterDropdownList.defaultProps = {
  className: "",
  onClickHandler: (e) => e,
  type: "checkbox",
};

export default FilterDropdownList;
