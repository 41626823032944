import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import FilterDropdown from "../FilterDropdown";
import epgConstants from "../../shared/constants/epg";
import { updateURLQueryParam } from "../../shared/utils";
import "./style.scss";

const { CATEGORY_FILTER_BUTTONS } = epgConstants;

/**
 *  View All filter dropdown component
 *
 * @component
 * @param {Object} props
 */
const ViewAllFilterDropdown = ({
  filterList,
  applyButtonHandler,
  resetButtonHandler,
  defaultFilterParams,
  initialParamsState,
}) => {
  const [showDropdownList, setShowDropdownList] = useState(false);
  const [selectedItem, setSelectedItem] = useState(initialParamsState ?? defaultFilterParams);
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  let lastSelectedItemRef = useRef(initialParamsState ?? defaultFilterParams);

  const getFilterOptionList = () => {
    return filterList.map((element, index) => {
      return {
        items: createList(element),
        linkClickHandler: filterLinkClickHandler,
        type: "radio",
        selectedItem: selectedItem[index] ? selectedItem[index] : null,
      };
    });
  };

  /**
   * Returns a dropdown list template for filter items
   * @return {Object}
   */

  const createList = (element) => {
    const contentList = element.values;
    let titleArray = contentList.map((element) => {
      return {
        value: isAppLanguageFrench && element.label_FRA ? element.label_FRA : element.label,
      };
    });

    const list = {
      label: isAppLanguageFrench && element.label_FRA ? element.label_FRA : element.label,
      items: titleArray,
      isLocalized: true,
    };
    return list;
  };

  /**
   *
   * @returns true if a new filter option has been selected from the dropdown
   */
  const isNewFilterOptionSelected = () => {
    let result = false;
    if (selectedItem && lastSelectedItemRef.current) {
      const selectedItemKeys = Object.keys(selectedItem);
      const lastSelectedItemKeys = Object.keys(lastSelectedItemRef.current);
      for (let i = 0; i < selectedItemKeys.length; i++) {
        result = result || selectedItem[selectedItemKeys[i]] !== lastSelectedItemRef.current[lastSelectedItemKeys[i]];
        if (result) break;
      }
    }
    return result;
  };

  /**
   * Determines the appropriate filter query param values using the provided itemData
   * @param {Object} itemData Object where keys represent groupings and values are the label of the selected item for that group
   * @returns {String} Query params required to achieve desired filtered state
   */
  const selectFilterItems = (itemData) => {
    const queryParams = Object.values(filterList).map((item, index) => {
      return getFilterQueryParam(item.key, itemData[index]);
    });

    // Update URL filter params
    filterList.forEach((filterOption, index) => {
      let modifiedQueryParam = queryParams[index];
      let removeParamFromUrl = false;
      if (queryParams[index] === "") {
        modifiedQueryParam =
          filterOption.key === "language_filters" ? "filter_language=All" : `${filterOption.key}=All`;
        removeParamFromUrl = true;
      }
      updateURLQueryParam(modifiedQueryParam, removeParamFromUrl);
    });

    lastSelectedItemRef.current = itemData;
    return queryParams.filter((param) => param).join("&");
  };

  /**
   * Shows filter dropdown pop up when clicked on the filter dropdown button
   */
  const buttonClickHandler = () => {
    setShowDropdownList(true);
  };

  /**
   * Runs when a filter item is selected from the dropdown
   * @param {Integer} index
   * @param {Integer} categoryIndex
   */
  const filterLinkClickHandler = (index, categoryIndex) => {
    setSelectedItem({
      ...selectedItem,
      [categoryIndex]:
        isAppLanguageFrench && filterList[categoryIndex].values[index].label_FRA
          ? filterList[categoryIndex].values[index].label_FRA
          : filterList[categoryIndex].values[index].label,
    });
  };

  /**
   * Hides the filter dropdown pop up when clicked outside of the filter dropdown
   */
  const handleOutsideComponentClick = () => {
    setShowDropdownList(false);
    cancelButtonClickHandler();
  };

  const resetButtonClickHandler = () => {
    setSelectedItem(defaultFilterParams);
    const filterValues = selectFilterItems(defaultFilterParams);
    resetButtonHandler(filterValues);
    setShowDropdownList(false);
  };

  const cancelButtonClickHandler = () => {
    setShowDropdownList(false);
    if (lastSelectedItemRef.current) {
      setSelectedItem(lastSelectedItemRef.current);
    } else {
      setSelectedItem(defaultFilterParams);
    }
  };

  const applyButtonClickHandler = () => {
    const filterValues = selectFilterItems(selectedItem);
    applyButtonHandler(filterValues);
    setShowDropdownList(false);
  };

  /**
   * Attempts to find a filter query param from the filterList for given a filter category and filter label
   * @param {String} filterCategoryKey Key used to find the specific filter category
   * @param {String} filterItemLabel Label used to find the specific filter in a filter category
   * @returns {String} A specific filter's respective query param string or empty string if not found
   */
  function getFilterQueryParam(filterCategoryKey, filterItemLabel) {
    let filterParamString = "";
    if (filterList) {
      for (let filterCategory of filterList) {
        // Find the matching filter category in the filter list
        if (filterCategory.key === filterCategoryKey) {
          for (let filterItem of filterCategory.values) {
            // Find the matching filter item in the filter category
            if (
              (isAppLanguageFrench && filterItem.label_FRA ? filterItem.label_FRA : filterItem.label) ===
              filterItemLabel
            ) {
              let filterValue = filterItem.value;
              // Genre filter data is formatted a specific way
              if (filterCategory.key === "filter_genres") {
                // Get the query param from the filter value
                const queryParam = filterValue.split("&")[1];
                if (queryParam) {
                  // Split the query param key-value pair
                  const queryParamKV = queryParam.split("=");
                  let queryParamKey = queryParamKV[0];
                  // Remove the query param prefix if it exists
                  if (queryParamKey.startsWith("&") || queryParamKey.startsWith("?")) {
                    queryParamKey = queryParamKey.substring(1);
                  }
                  // Update the param string with encoded query param value
                  filterParamString += queryParamKey + "=" + encodeURIComponent(queryParamKV[1]);
                  break;
                }
                // Language filter data is formatted a specific way
              } else if (filterCategory.key === "language_filters") {
                // Remove the query param prefix if it exists
                if (filterValue && (filterValue.startsWith("&") || filterValue.startsWith("?"))) {
                  filterValue = filterValue.substring(1);
                }
                // Update the param string with encoded query param value
                filterParamString += filterValue ?? "";
                break;
              } else if (filterCategory.key === "content_filters") {
                // Get the query param from the filter value
                if (filterValue !== "all") {
                  // Update the param string with encoded query param value
                  filterParamString += filterCategory.key + "=" + encodeURIComponent(filterValue);
                  break;
                }
              }
            }
          }
          break;
        }
      }
    }

    return filterParamString;
  }

  const getFilterButtons = () => {
    let applyButtonClasses = "filter-button filter-apply-button";
    applyButtonClasses += isNewFilterOptionSelected() ? " filter-button-enabled" : "";
    return (
      <div className="filter-buttons">
        <div className="filter-button filter-reset-button" onClick={resetButtonClickHandler}>
          {translate(CATEGORY_FILTER_BUTTONS.RESET)}
        </div>
        <div className="filter-button filter-cancel-button" onClick={cancelButtonClickHandler}>
          {translate(CATEGORY_FILTER_BUTTONS.CANCEL)}
        </div>
        <div className={applyButtonClasses} onClick={applyButtonClickHandler}>
          {translate(CATEGORY_FILTER_BUTTONS.APPLY)}
        </div>
      </div>
    );
  };

  return (
    <FilterDropdown
      showDropdownList={showDropdownList}
      filterImage={
        JSON.stringify(selectedItem) !== JSON.stringify(defaultFilterParams)
          ? `${process.env.PUBLIC_URL}/images/Filter_Icon_Active.svg`
          : `${process.env.PUBLIC_URL}/images/Filter_Icon.svg`
      }
      button={{
        label: translate("filter"),
        buttonClickHandler,
        buttonStyles: JSON.stringify(selectedItem) !== JSON.stringify(defaultFilterParams) ? "filter-applied" : "",
      }}
      lists={getFilterOptionList()}
      popUp={{
        popUpStyles: "viewAllFilter",
        handleOutsideComponentClick,
        childItems: getFilterButtons(),
      }}
    />
  );
};

export default ViewAllFilterDropdown;

ViewAllFilterDropdown.propTypes = {
  filterList: PropTypes.array.isRequired,
  applyButtonHandler: PropTypes.func.isRequired,
  resetButtonHandler: PropTypes.func.isRequired,
  defaultFilterParams: PropTypes.object.isRequired,
  initialParamsState: PropTypes.object,
};
