import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import routeConstants from "../../shared/constants/routes";
import constants from "../../shared/constants";
import storageConstants from "../../shared/constants/storage";
import { setSessionStorage, removeSessionStorage } from "../../shared/utils/sessionStorage";
import { removeLocalStorage } from "../../shared/utils/localStorage";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import { resetScrollPosition } from "../../shared/utils";
import "./styles.scss";

const { FEED } = routeConstants;
const { TOP_NAV_ACTIVITY_TYPES } = constants;
const { RECORDINGS_FILTER_VALUES, EPG_SELECTED_DATE, EPG_SELECTED_TIME, EPG_SELECTED_TIME_FORMAT } = storageConstants;

/**
 * Returns selection index according to browser route.
 * @param {Array} hubs - Hubs configuration
 * @param {Object} location - Route location object
 * @returns {Number} - Selection index. Returns -1, if no matching route found.
 */
const getSelectionIndex = (hubs, location) => {
  if ((!hubs && hubs.length === 0) || (!location && !location.pathname)) {
    return 0;
  }

  let index = hubs.findIndex((hubItem) => hubItem.route.includes(location.pathname));
  if (index === -1 && (location.pathname === "/" || location.pathname.includes(FEED.route))) {
    index = 0;
  }
  return index;
};

/**
 * Component for navigation activity.
 *
 * @component
 * @param {Object} props
 */
function ContentActivities(props) {
  const { hubs } = props;
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(getSelectionIndex(hubs, location));
  const { t: translate } = useTranslation();

  const selectedHub = useMemo(() => {
    const matchedHub = hubs[activeIndex];
    return matchedHub?.type === TOP_NAV_ACTIVITY_TYPES.page ? translate(matchedHub?.name) : matchedHub?.name;
  }, [hubs, activeIndex, translate]);

  useEffect(() => {
    // Listening to browser route change for updating active index.
    setActiveIndex(getSelectionIndex(hubs, location));
  }, [location, hubs]);

  const clickHandler = (hubName, index) => {
    setActiveIndex(index);
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${hubName};${LINK_INFO.MEGA_NAV}`);
    resetScrollPosition();
    removeSessionStorage(RECORDINGS_FILTER_VALUES);
    removeLocalStorage(EPG_SELECTED_DATE);
    removeLocalStorage(EPG_SELECTED_TIME);
    removeLocalStorage(EPG_SELECTED_TIME_FORMAT);
  };

  if ((!hubs && hubs.length === 0) || (!location && !location.pathname)) {
    return null;
  }

  return (
    <header className="hubs">
      <button className="hubs-dropdown">
        <span className="selected-hub">{selectedHub || translate("menu")}</span>
        <span>
          <img src={process.env.PUBLIC_URL + "/images/white-chevron.svg"} alt="back-navigation" />
        </span>
      </button>
      <ul className="hubs-list">
        {hubs.map((hubItem, index) => {
          const selectedClass = activeIndex === index ? "selected" : "";
          return (
            <li key={index} className="hubs-list-item">
              <Link
                to={hubItem.route}
                key={hubItem.name + hubItem.type + hubItem.route}
                className={selectedClass}
                onClick={() => clickHandler(hubItem.name, index)}
              >
                {hubItem.type === TOP_NAV_ACTIVITY_TYPES.page ? translate(hubItem.name) : hubItem.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </header>
  );
}

ContentActivities.propTypes = {
  hubs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ContentActivities;
