import { LOAD_FEED, PAGE_COMPONENTS, SWIMLANE_ITEMS } from "./actions";

const initialState = {
  content: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FEED:
      return { ...state, content: action.content };
    case PAGE_COMPONENTS:
      return { ...state, pageComponents: action.components };
    case SWIMLANE_ITEMS:
      return { ...state, swimlaneItems: action.items };
    default:
      return state;
  }
};
