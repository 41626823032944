/**
 * Check for whether feed is of type onNow
 *
 * @param {Object} feed - feed object
 * @returns {Boolean} - true if discovery type
 */
export const isOnNowFeedItem = (feed) => {
  const BASE_URL = "udl://live/feeds/playablechannels/";
  const feedURI = feed.Uri.toLowerCase();

  return feedURI.includes(BASE_URL);
};
