import styled from "styled-components";
import "../../variables.scss";

export const ProgressWrap = styled.div`
  width: 100%;
  position: relative;
  align-items: center;
  min-height: 26px;
  display: flex;

  &:hover .hover-styles {
    height: 7px;
    top: 9px;
    cursor: pointer;
  }
`;
export const TimeLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font: ${({ theme }) => theme.typography.regularFont};
  font-size: 20px;
  min-height: 30px;

  .current {
    color: ${({ theme }) => theme.colours.primaryFontColor};
  }

  .end {
    color: ${({ theme }) => theme.colours.secondaryFontColor};
  }
`;

export const ProgressBarStyled = styled.div`
  height: 4px;
  background: ${({ theme }) => theme.colours.playerProgressBg};
  border-radius: 0 3px 3px 0;
`;

export const ProgressWrapActive = styled.div`
  height: 26px;
  cursor: pointer;
`;

export const ProgressIndicator = styled.div`
  height: 4px;
  background: ${({ theme }) => theme.colours.playerProgressIndicator};
  border-radius: 3px 0 0 3px;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 11px;
`;

export const ProgressBufferIndicator = styled.div`
  height: 4px;
  background: ${({ theme }) => theme.colours.playerBufferIndicator};
  border-radius: 3px 0 0 3px;
  pointer-events: none;
  position: absolute;
  top: 11px;
`;

export const KnobTooltip = styled.div`
  position: absolute;
  width: auto;
  padding: 3px 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colours.knobTooltip};
  color: ${({ theme }) => theme.colours.knob};
  white-space: nowrap;
  font: ${({ theme }) => theme.typography.btnTooltip};
  pointer-events: none;
  top: -15px;
  transform: translateX(-50%) translateY(-50%);
`;

export const HoverIndicator = styled.div`
  position: absolute;
  height: 20px;
  border: solid 2px ${({ theme }) => theme.colours.hoverIndicator};
  border-radius: 2px;
  top: 1.5px;
  z-index: 2;
`;

export const ProgressMarker = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  top: 11px;
`;

export const Knob = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colours.knob};
  cursor: pointer;
  top: -6px;
  left: -8px;
  transition: all ease-in-out 0.1s;
  z-index: 3;
`;
