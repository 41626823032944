import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Logo from "../../components/Logo";
import appStore_en from "./assets/apple-en.svg";
import appStore_fr from "./assets/apple-fr.svg";
import playStore_en from "./assets/google-en.svg";
import playStore_fr from "./assets/google-fr.svg";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import "./style.scss";

const MobileView = (props) => {
  const { appProvider } = props;
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const { trackPageView } = useTrackPageView();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <div className="mobile-view">
      <div className="logo-container">
        <Logo isClickable={false} />
      </div>
      <div>{translate("download_optik")}</div>

      <div className="apple-store-btn">
        <a href={appProvider.config.web.app_store_url}>
          <img src={isAppLanguageFrench ? appStore_fr : appStore_en} alt="apple-logo" />
        </a>
      </div>

      <div className="google-store-btn">
        <a href={appProvider.config.web.play_store_url}>
          <img src={isAppLanguageFrench ? playStore_fr : playStore_en} alt="google-play-logo" />
        </a>
      </div>
    </div>
  );
};

function mapStateToProps({ app }) {
  return { appProvider: app.provider };
}

export default connect(mapStateToProps)(MobileView);
