import styled from "styled-components";

const DropdownListContainer = styled.div``;

const DropdownItemsContainer = styled.div`
  margin: 0 auto;
  height: 48px;
`;

const ListLabel = styled.div(
  ({ theme, width }) => `
  color: ${theme.colours.paleSky};
  font: ${
    width <= 768
      ? theme.typography.regularFontHd
      : width <= 1366
      ? theme.typography.primaryFontHd
      : theme.typography.regularFontSize
  };
  font-size:${width <= 768 ? theme.typography.smallFontSizeHd : ""}
  line-height: 22px;
  cursor: auto;
  padding-left: 24px;
  margin-bottom: 8px;
`
);

const FilterListItems = styled.div(
  ({ theme, width, isHoverEnabled, isDisabled }) => `
  margin-bottom: 10px;

  .filter-disabled {
    color: ${theme.colours.paleSky};
    &:hover {
      background-color: ${isHoverEnabled && !isDisabled ? theme.colours.mineShaftGray : "transparent"};
    }
  }
    .watched-icon {
    margin-left: 50px;
  }

  div {
    color: ${isDisabled ? theme.colours.disabledGray : theme.colours.white};
    font:${width <= 1366 ? theme.typography.regularFontHd : theme.typography.regularFontSize};
    font-size: ${width <= 768 ? theme.typography.smallFontSizeHd : ""};
    &:hover {
      background-color: ${theme.colours.scheduleBg};
    }
  }

  .filter-selected {
    font: ${width <= 768 || width <= 1366 ? theme.typography.primaryFontHd : theme.typography.dropdownPrimaryFont};
    font-size: ${width <= 768 ? theme.typography.smallFontSizeHd : ""}
  }

  label {
    margin-left: 28px;
  }
`
);

const ToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
`;

const ToggleSwitchTitle = styled.span`
  flex: 1;
  margin-left: 14px;
`;

const ToggleImage = styled.img`
  margin-left: 28px;
  height: 18px;
`;

export {
  DropdownListContainer,
  ListLabel,
  FilterListItems,
  ToggleSwitchWrapper,
  ToggleSwitchTitle,
  ToggleImage,
  DropdownItemsContainer,
};
