import { LOAD_SVOD_PKG, LOAD_SVOD_PAGE_CONTAINERS, LOAD_SVOD_PAGE_DETAILS } from "./actions";

const initialState = {
  content: null,
  filters: null,
  containers: null,
  pageDetails: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SVOD_PAGE_CONTAINERS:
      return { ...state, containers: action.content };
    case LOAD_SVOD_PAGE_DETAILS:
      return { ...state, pageDetails: action.content };
    case LOAD_SVOD_PKG:
      return { ...state, content: action.content, totalContent: action.total };
    default:
      return state;
  }
};
