import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { VariableSizeGrid } from "react-window";
import moment from "moment";
import PropTypes from "prop-types";
import useWindowSize from "../../shared/hooks/useWindowSize";
import useCurrentTime from "../../shared/hooks/useCurrentTime";
import { IsSafari, numberStringCompareFunction } from "../../shared/utils";
import DateTimePicker from "../DateTimePicker";
import {
  convertDurationToWidth,
  convertOffsetToTimestamp,
  getChannelOffset,
  getTimeslotTimestamp,
  isChannelRestartable,
  isGhostChannel,
  isUserFavouritedChannel,
  isUserSubscribedChannel,
  isPlaybackNotSupportedOutOfHome,
  channelCompareFunction,
  getClosestChannelByRegionalChannelNumber,
  createEpgChannelObject,
  isPPVChannel,
} from "../../shared/utils/epg";
import { getRecordingSystemType } from "../../shared/utils/recordingHelper";
import epgConstants from "../../shared/constants/epg";
import constants from "../../shared/constants";
import { useTranslation } from "react-i18next";
import ImageButton from "../ImageButton";
import ChannelCell from "./ChannelCell";
import ProgramCell from "./ProgramCell";
import ProgramSlot from "./ProgramSlot";
import { TimeCell, CornerTimeCell } from "./TimeCell";
import "./style.scss";
import { useReducers } from "../../shared/hooks/useReducer";
import { removeLocalStorage } from "../../shared/utils/localStorage";
import storageConstants from "../../shared/constants/storage";

const {
  PLAYABLE_ON_THIS_DEVICE,
  MY_SUBSCRIBED_CHANNELS,
  MY_FAVOURITE_CHANNELS,
  TIMESLOT_WIDTH,
  TIMESLOT_MINUTES,
  TIMESLOT_HEIGHT,
  PROGRAM_ROW_HEIGHT,
  CHANNEL_CELL_WIDTH,
  DEFAULT_RANGE_DAYS,
  EPG_END_BUFFER_SLOTS,
} = epgConstants;
const { REDUCER_TYPE } = constants;

// TODO: Smooth scrolling in Safari causes too much jank; maybe revisit in future
const EPG_SCROLL_BEHAVIOR = IsSafari() ? "auto" : "smooth";
const EPG_TOP_MARGIN = 161;
const CHEVRON_SCROLL_WIDTH = 2 * TIMESLOT_WIDTH;

const leftChevronIcon = `${process.env.PUBLIC_URL}/images/Left_Chevron.svg`;
const rightChevronIcon = `${process.env.PUBLIC_URL}/images/Right_Chevron.svg`;

/**
 * EPG component that uses react-window to only render enough data to fill the viewport
 *
 * @component
 */
const Epg = forwardRef(function EPG(
  {
    appProvider,
    userProfile,
    rangeChangeHandler,
    timeChangeHandler,
    selectedChannelNumber,
    selectedTime,
    filters,
    onApplyFilters,
    isInHome,
    channelMapInfo,
    favouriteChannels,
    programs,
    subscribedChannels,
    setSelectedAssetRecordingInfo,
    updateRecordingHandler,
    isLookbackSegmentFetched,
    setIsLookbackSegmentFetched,
    lookbackOffsetHours,
    selectedDateAndTimeHandler,
    showToastNotification,
    onNoSearchResults,
    noChannelsFound,
  },
  ref
) {
  const { featureToggles } = useReducers(REDUCER_TYPE.APP);
  const { isLookbackEnabled, isRecordingEnabled, isRestartLiveTVEnabled } = featureToggles;
  const [windowWidth, windowHeight] = useWindowSize();
  const [dateTimeSelected, setDateTimeSelected] = useState();
  const currentTime = useCurrentTime(60000, true);
  const { t: translate } = useTranslation();
  const gridRef = useRef(null);
  const isMouseDown = useRef(false);
  const [showCalendarPicker, setShowCalendarPicker] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);
  const [hoveredCell, setHoveredCell] = useState(null);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const rangeDays = appProvider?.config?.web.program_load_control?.limitOfDays ?? DEFAULT_RANGE_DAYS;
  const epgRange = useMemo(() => {
    const epgStartTimestamp = getTimeslotTimestamp(
      isLookbackEnabled ? moment(currentTime).subtract(lookbackOffsetHours, "hours").startOf("hour") : currentTime
    );
    return {
      start: epgStartTimestamp,
      end: moment(epgStartTimestamp)
        .add(rangeDays, "day")
        .add(EPG_END_BUFFER_SLOTS * TIMESLOT_MINUTES, "minute")
        .valueOf(),
    };
  }, [currentTime, rangeDays, lookbackOffsetHours, isLookbackEnabled]);
  // to track if the component is in initial loading state. This is useful when lookback feature is enabled. So we can scroll to current programs column
  const [isInitial, setIsInitial] = useState(true);
  const { EPG_SELECTED_DATE, EPG_SELECTED_TIME, EPG_SELECTED_TIME_FORMAT } = storageConstants;

  const initialScrollLeft = selectedTime
    ? convertDurationToWidth(getTimeslotTimestamp(selectedTime) - epgRange.start)
    : isLookbackEnabled
    ? convertDurationToWidth(getTimeslotTimestamp(currentTime) - epgRange.start)
    : 0;
  const horizontalScrollRef = useRef(initialScrollLeft);
  const leftEdgeTimeRef = useRef(
    selectedTime ? selectedTime : isLookbackEnabled ? getTimeslotTimestamp(currentTime) : epgRange.start
  );
  const recordingSystemType = useMemo(
    () => (userProfile?.isLoggedIn ? getRecordingSystemType(userProfile) : null),
    [userProfile]
  );

  const epgPrograms = useMemo(() => {
    const segmentTimestamps = Object.keys(programs).sort(numberStringCompareFunction);
    return segmentTimestamps.reduce((result, timestamp) => {
      const segmentPrograms = programs[timestamp] ?? {};
      for (const channelId in segmentPrograms) {
        if (!result[channelId]) {
          result[channelId] = [];
        }
        const channelPrograms = segmentPrograms[channelId];
        let lastProgram = null;
        channelPrograms.forEach((program) => {
          // Remove duplicates
          if (result[channelId].some((previouslyAddedProgram) => previouslyAddedProgram.id === program.id)) {
            return;
          }
          // Remove overlapping programs
          if (lastProgram) {
            if (
              lastProgram.metadata?.airingStartTime <= program.metadata?.airingStartTime &&
              lastProgram.metadata?.airingEndTime > program.metadata?.airingStartTime
            ) {
              return;
            }
            if (lastProgram.metadata?.airingEndTime >= program.metadata?.airingEndTime) {
              return;
            }
          }
          result[channelId].push(program);
          lastProgram = program;
        });
      }
      return result;
    }, {});
  }, [programs]);

  /**
   * Returns false if the given channel does not match the selected EPG filters
   * @param {Object} channel
   * @returns {Boolean}
   */
  const epgChannelFilterFunction = useCallback(
    (channel) => {
      let filterInHomeChannels = false;
      let filterUnsubscribedChannels = false;
      let filterGhostChannels = false;
      let filterNonFavouriteChannels = false;

      switch (filters?.channels) {
        case PLAYABLE_ON_THIS_DEVICE:
          filterUnsubscribedChannels = true;
          filterGhostChannels = true;
          if (!isInHome) {
            filterInHomeChannels = true;
          }
          break;
        case MY_SUBSCRIBED_CHANNELS:
          filterUnsubscribedChannels = true;
          break;
        case MY_FAVOURITE_CHANNELS:
          filterNonFavouriteChannels = true;
          break;
        default:
          break;
      }

      const channelPrograms = epgPrograms?.[channel.id];

      // Filter out items that are missing channel and program data
      if (!channel || !channelPrograms?.length) return false;
      // Filter out unsubscribed channels if appropriate
      if (filterUnsubscribedChannels && !isUserSubscribedChannel(channel, subscribedChannels)) return false;
      // Filter out In Home channels if appropriate
      if (filterInHomeChannels && isPlaybackNotSupportedOutOfHome(channel, channelPrograms)) return false;
      // Filter out ghost channels if appropriate
      if (filterGhostChannels && isGhostChannel(channel)) return false;
      // Filter only restartable channel
      if (filters?.restart && !isChannelRestartable(channel, isInHome)) return false;

      if (filters?.customFilter) {
        // Filter out channels that don't match the custom filter - channel number, channel name, or program title
        if (
          channel.number.toString().indexOf(filters.customFilter) === -1 &&
          channel.metadata?.channelName.toLowerCase().indexOf(filters.customFilter.toLowerCase()) === -1 &&
          channelPrograms?.every(
            (program) => program.metadata?.title.toLowerCase().indexOf(filters.customFilter.toLowerCase()) === -1
          )
        ) {
          return false;
        }
      }
      // Filter out un-favourite channels if appropriate
      if (filterNonFavouriteChannels && !isUserFavouritedChannel(channel, favouriteChannels)) return false;
      // Filter out PPV channels if panic mode is enabled
      if (appProvider.panicMode && isPPVChannel(channel)) return false;

      return true;
    },
    [favouriteChannels, filters, isInHome, epgPrograms, subscribedChannels, appProvider]
  );

  const epgChannels = useMemo(() => {
    const filteredChannels = channelMapInfo?.containers
      ?.map((channel) => createEpgChannelObject(channel, appProvider.channelMapID))
      .filter(epgChannelFilterFunction)
      .sort(channelCompareFunction);

    if (filteredChannels?.length == 0 && filters?.customFilter !== "") {
      onNoSearchResults();
    }
    return filteredChannels;
  }, [channelMapInfo, appProvider.channelMapID, epgChannelFilterFunction, onNoSearchResults, filters?.customFilter]);

  const initialChannelNumber = useMemo(() => {
    if (!selectedChannelNumber || !epgChannels?.length || !appProvider) {
      return null;
    }
    const closestChannel = getClosestChannelByRegionalChannelNumber(epgChannels, selectedChannelNumber);
    return closestChannel ? closestChannel.number : null;
  }, [epgChannels, selectedChannelNumber, appProvider]);

  // Update time offset after scroll
  const scrollHandler = useCallback(() => {
    if (!gridRef.current) {
      return;
    }

    const scrollOffset = gridRef.current.scrollLeft;
    if (
      scrollOffset !== horizontalScrollRef.current ||
      convertOffsetToTimestamp(horizontalScrollRef.current, epgRange.start) !== leftEdgeTimeRef.current
    ) {
      const leftEdgeTime = convertOffsetToTimestamp(scrollOffset, epgRange.start);
      if (timeChangeHandler) {
        timeChangeHandler(leftEdgeTime);
      }

      horizontalScrollRef.current = scrollOffset;
      leftEdgeTimeRef.current = leftEdgeTime;
    }
  }, [epgRange.start, timeChangeHandler]);

  // Debounce consecutive scroll events fired in a short period of time
  const debouncedScrollHandler = useMemo(() => debounce(scrollHandler, 400), [scrollHandler]);
  useEffect(() => {
    if (onApplyFilters && epgChannels && epgPrograms && Object.keys(epgPrograms).length > 0) {
      onApplyFilters(epgChannels.length > 0);
    }
  }, [epgChannels, epgPrograms, onApplyFilters]);

  useEffect(() => {
    // When lookback is enabled, VariableSizeGrid needs to scroll to initialScrollLeft because there are buffered lookback programs hidden in the left. This should only happen once on the page load so setIsInitial to false afterwards.
    if (initialScrollLeft > 0 && isLookbackEnabled) {
      if (gridRef.current && isInitial) {
        gridRef.current.scrollTo({ left: initialScrollLeft });
        setIsInitial(false);
      }
    }
  }, [isLookbackEnabled, initialScrollLeft, isInitial]);

  useEffect(() => {
    if (isInitial && isLookbackEnabled) return;
    if (rangeChangeHandler) {
      rangeChangeHandler(epgRange.start);
    }
    if (gridRef.current) {
      // Update the left edge time when the EPG range changes
      if (gridRef.current.scrollLeft === 0 && !isLookbackSegmentFetched) {
        scrollHandler();
      } else {
        // Scroll back to the user's last position before the range change
        const adjustedOffset = convertDurationToWidth(leftEdgeTimeRef.current - epgRange.start);
        gridRef.current.scrollTo({ left: adjustedOffset });
        if (isLookbackSegmentFetched) setIsLookbackSegmentFetched(false); // Mark the finish of one lookback segment fetching cycle
      }
    }
  }, [
    epgRange.start,
    rangeChangeHandler,
    scrollHandler,
    isLookbackSegmentFetched,
    setIsLookbackSegmentFetched,
    initialScrollLeft,
    isLookbackEnabled,
    isInitial,
  ]);

  const onLeftChevronClick = () => {
    if (gridRef.current) {
      gridRef.current.scrollBy({ left: -CHEVRON_SCROLL_WIDTH, behavior: EPG_SCROLL_BEHAVIOR });
    }
  };

  const onRightChevronClick = () => {
    if (gridRef.current) {
      gridRef.current.scrollBy({ left: CHEVRON_SCROLL_WIDTH, behavior: EPG_SCROLL_BEHAVIOR });
    }
  };

  const onNowTimeCellScrollOffset = useMemo(
    () => convertDurationToWidth(getTimeslotTimestamp(currentTime) - epgRange.start),
    [currentTime, epgRange]
  );

  const onResetClick = () => {
    setShowCalendarPicker(false);
    if (gridRef.current) {
      gridRef.current.scrollTo({
        left: onNowTimeCellScrollOffset,
        behavior: EPG_SCROLL_BEHAVIOR,
      });
    }
    setResetClicked(true);
    setDateTimeSelected("");
    removeLocalStorage(EPG_SELECTED_DATE);
    removeLocalStorage(EPG_SELECTED_TIME);
    removeLocalStorage(EPG_SELECTED_TIME_FORMAT);
  };

  const handleCalenderClick = () => {
    setShowCalendarPicker(true);
  };

  const onCloseCalendar = () => {
    setShowCalendarPicker(false);
  };

  // Expose scroll methods to parent component
  useImperativeHandle(
    ref,
    () => ({
      scrollToTime: (timestamp) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            left: convertDurationToWidth(timestamp - epgRange.start),
            behavior: EPG_SCROLL_BEHAVIOR,
          });
        }
      },
      scrollToChannel: (regionalChannelNumber) => {
        const offset = getChannelOffset(epgChannels, regionalChannelNumber);
        if (offset >= 0 && gridRef.current) {
          gridRef.current.scrollTo({ top: offset, behavior: EPG_SCROLL_BEHAVIOR });
        }
      },
      gridHeight: () => gridRef.current?.firstChild?.offsetHeight,
    }),
    [epgChannels, epgRange]
  );

  const timeCellCount = rangeDays * 24 * (60 / TIMESLOT_MINUTES) + EPG_END_BUFFER_SLOTS;
  const containerWidth = timeCellCount * TIMESLOT_WIDTH + CHANNEL_CELL_WIDTH;

  const onApplyDateTime = (selectedDateTime) => {
    selectedDateAndTimeHandler(selectedDateTime);
    setDateTimeSelected(selectedDateTime);
    setShowCalendarPicker(false);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isMouseDown.current) {
        handleDrag(e);
      }
    };
    const handleMouseUp = handleDragEnd;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const handleDragStart = (e) => {
    // Check if the corner cell clicked
    const TimeCell = e.target.closest(".time-cell");
    if (TimeCell) return;
    // Check if the right mouse button was clicked
    if (e.button === 2) return;
    if (!gridRef.current) return;
    const slider = gridRef.current;
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    isMouseDown.current = true;
    document.body.style.cursor = "grabbing";
  };

  const handleDragEnd = () => {
    isMouseDown.current = false;
    if (gridRef.current) {
      document.body.style.cursor = "default";
    }
  };

  const handleDrag = (e) => {
    if (!isMouseDown.current || !gridRef.current) return;
    const slider = gridRef.current;
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
  };

  const handleCellClick = (cellIndex) => {
    if (gridRef.current) {
      const scrollOffset = (cellIndex - 1) * TIMESLOT_WIDTH;
      gridRef.current.scrollTo({ left: scrollOffset, behavior: EPG_SCROLL_BEHAVIOR });
    }
  };
  const handleHoverChange = (index, isHovered) => {
    setHoveredCell(isHovered ? index : null);
  };

  return epgChannels?.length || noChannelsFound ? (
    <>
      <div
        tabIndex="-1"
        className={showCalendarPicker ? "curtain-background" : ""}
        onFocus={() => {
          if (gridRef.current) {
            // Make grid focusable to handle keyboard scroll events
            gridRef.current.tabIndex = -1;
            gridRef.current.focus();
          }
        }}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
      >
        <VariableSizeGrid
          className="epg-grid"
          outerRef={gridRef}
          width={windowWidth}
          height={windowHeight - EPG_TOP_MARGIN}
          onScroll={debouncedScrollHandler}
          itemData={{
            currentTime,
            epgRange,
            isRecordingEnabled,
            isRestartLiveTVEnabled,
            recordingSystemType,
            epgChannels,
            epgPrograms,
            setSelectedAssetRecordingInfo,
            updateRecordingHandler,
          }}
          itemKey={({ rowIndex, columnIndex, data }) => {
            // Use channelId in key instead of rowIndex because the list can be altered via filters
            if (data?.epgChannels?.[rowIndex]?.id) {
              return `${data.epgChannels[rowIndex].id}:${columnIndex}`;
            }
            return `${rowIndex}:${columnIndex}`;
          }}
          columnCount={timeCellCount + 1}
          columnWidth={(columnIndex) => (columnIndex === 0 ? CHANNEL_CELL_WIDTH : TIMESLOT_WIDTH)}
          rowCount={epgChannels.length + 1}
          rowHeight={(rowIndex) => (rowIndex === 0 ? TIMESLOT_HEIGHT : PROGRAM_ROW_HEIGHT)}
          overscanColumnCount={5}
          overscanRowCount={5}
          initialScrollLeft={initialScrollLeft}
          initialScrollTop={initialChannelNumber ? getChannelOffset(epgChannels, initialChannelNumber) : undefined}
          innerElementType={forwardRef(({ children, style, ...rest }, ref) => {
            const renderedRows = Array.of(...new Set(children.map((child) => child.props.rowIndex)));
            const renderedColumns = Array.of(...new Set(children.map((child) => child.props.columnIndex)));

            // Create sticky timeline row
            const stickyTimeCells = renderedColumns.map((column, index) => {
              const { data } = children[0].props;
              const { currentTime, epgRange } = data;
              return index === 0 ? (
                <CornerTimeCell
                  key="corner"
                  handleCalenderClick={handleCalenderClick}
                  dateTimeSelected={dateTimeSelected}
                  translate={translate}
                  resetClicked={resetClicked}
                />
              ) : (
                <Fragment key={`time-0:${column}`}>
                  {index === 1 ? (
                    <div className="timeline-placeholder" style={{ width: (column - 1) * TIMESLOT_WIDTH }} />
                  ) : null}
                  <TimeCell
                    index={column}
                    style={{
                      width: TIMESLOT_WIDTH,
                      height: TIMESLOT_HEIGHT,
                    }}
                    data={{ currentTime, epgRange }}
                    onCellClick={handleCellClick}
                    onHoverChanged={handleHoverChange}
                  />
                </Fragment>
              );
            });

            // Add a sticky channel cell for each rendered program row
            const stickyChannelCells = renderedRows.map((row, index) => {
              if (index === 0) {
                return null;
              }
              const { data } = children[0].props;
              const { epgChannels, epgPrograms } = data || {};
              const channel = epgChannels[row - 1];
              const programs = epgPrograms[channel.id];

              return channel ? (
                <Fragment key={`sticky-${channel?.id ?? row}:0`}>
                  {index === 1 ? (
                    // This empty div provides spacing to position the channel cells properly
                    <div style={{ height: (row - 1) * PROGRAM_ROW_HEIGHT }} />
                  ) : null}
                  <ChannelCell channel={channel} firstProgram={programs?.[0]} rowIndex={row} columnIndex={0} />
                </Fragment>
              ) : null;
            });

            // Render programs that start before EPG range
            const programsBeforeEpgRange = renderedRows.map((row, index) => {
              if (index === 0) {
                return null;
              }
              const { data } = children[0].props;
              const { epgRange, epgChannels, epgPrograms } = data || {};
              const channel = epgChannels[row - 1];
              const programs = epgPrograms[channel.id];

              const renderedRangeStart = moment(epgRange.start)
                .add(TIMESLOT_MINUTES * Math.max(0, renderedColumns[0] - 1), "minute")
                .valueOf();
              const program =
                programs?.find((program) => {
                  return (
                    program.metadata?.airingStartTime < renderedRangeStart &&
                    program.metadata?.airingEndTime > renderedRangeStart
                  );
                }) ?? null;
              // Copy the styling for this cell from a cell in the same row
              const style = program ? children.find((child) => child.props.rowIndex === row)?.props?.style : {};

              return program ? (
                <ProgramCell
                  key={program.id}
                  rowNumber={row}
                  columnNumber={programs.indexOf(program)}
                  program={program}
                  currentTime={currentTime}
                  epgRange={epgRange}
                  channel={channel}
                  style={style}
                  setSelectedAssetRecordingInfo={setSelectedAssetRecordingInfo}
                  updateRecordingHandler={updateRecordingHandler}
                />
              ) : null;
            });

            // Remove slots reserved for timeline and channel cells
            const gridCells = children.map((child) => {
              const { rowIndex, columnIndex } = child.props;
              if (rowIndex === 0 || columnIndex === 0) {
                return null;
              }
              return child;
            });

            return (
              <div
                ref={ref}
                // Use a fixed width to scroll accurately to a time far in the future
                style={{ ...style, width: containerWidth }}
                {...rest}
              >
                {epgChannels.length || noChannelsFound ? stickyTimeCells : ""}
                {stickyChannelCells}
                {programsBeforeEpgRange}
                {gridCells}
              </div>
            );
          })}
        >
          {ProgramSlot}
        </VariableSizeGrid>
      </div>
      {isLookbackEnabled && hoveredCell && !showCalendarPicker && (
        <div className="epg-chevron left">
          <ImageButton src={leftChevronIcon} onClickHandler={onLeftChevronClick} alt={translate("back")} />
        </div>
      )}
      {hoveredCell && !showCalendarPicker && (
        <div className={hoveredCell && !showCalendarPicker ? "epg-chevron right" : ""}>
          <ImageButton src={rightChevronIcon} onClickHandler={onRightChevronClick} alt={translate("forward")} />
        </div>
      )}
      {showCalendarPicker && (
        <div className="datetime-picker-overlay">
          <div className="datetime-picker-container">
            <DateTimePicker
              showCalendarPicker={showCalendarPicker}
              onCloseCalendar={onCloseCalendar}
              onApply={onApplyDateTime}
              onReset={onResetClick}
              epgRange={epgRange}
              showToastNotification={showToastNotification}
            />
          </div>
        </div>
      )}
    </>
  ) : null;
});

function mapStateToProps({ app, epg }) {
  return {
    appProvider: app.provider,
    userProfile: app.userProfile,
    isInHome: app.isInHome,
    channelMapInfo: app.channelMapInfo,
    subscribedChannels: app.subscribedChannels,
    favouriteChannels: app.favouriteChannels,
    programs: epg.currentPrograms,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(Epg);

Epg.propTypes = {
  rangeChangeHandler: PropTypes.func,
  timeChangeHandler: PropTypes.func,
  selectedChannelNumber: PropTypes.number,
  selectedTime: PropTypes.number,
  filters: PropTypes.object,
  onApplyFilters: PropTypes.func,
  onNoSearchResults: PropTypes.func,
};

/**
 * Debounces a callback function by the given delay
 *
 * @param {Function} callback
 * @param {Number} delay
 * @returns {Function}
 */
const debounce = (callback, delay) => {
  let debounceTimeout;

  return () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = setTimeout(() => {
      debounceTimeout = null;
      callback();
    }, delay);
  };
};
