/**
 * Save information in local storage with optional expiration support
 *
 * @param {String} key - Reference key for the item to be saved
 * @param {String} value - The value to save
 * @param {Number} [ttl] - The amount of time before the item should expire, in milliseconds
 */
export function setLocalStorage(key, value, ttl = null) {
  let item = value;
  if (ttl) {
    const today = new Date();
    item = {
      value,
      expiry: today.getTime() + ttl,
    };
  }

  localStorage.setItem(key, JSON.stringify(item));
}

/**
 * Fetch information from local storage and delete it if it has expired
 *
 * @param {String} key - Reference key for the item to be fetched
 * @returns the value in local storage that is associated with the provided key, or null if it has expired
 */
export function getLocalStorage(key) {
  const itemString = localStorage.getItem(key);

  if (!itemString) {
    return null;
  }

  try {
    const item = JSON.parse(itemString);
    if (item?.expiry) {
      const today = new Date();
      if (today.getTime() > item.expiry) {
        // If the item we're trying to fetch has expired, we shouldn't return its value
        removeLocalStorage(key);
        return null;
      }

      return item.value;
    }

    return item;
  } catch (e) {
    return itemString;
  }
}

/**
 * Deletes information from local storage
 *
 * @param {String} key - Reference key for the item to be deleted
 */
export function removeLocalStorage(key) {
  localStorage.removeItem(key);
}
