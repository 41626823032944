import styled from "styled-components";

const FilterBoxContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 6px;
`;

const ChannelSearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 6px;
  width: 100%;
  height: 80px;
`;

const FilterInput = styled.input(
  ({ theme }) => `
  outline: none;
  color: ${theme.colours.white};
  background: ${theme.colours.translucentWhite};
  border-color: transparent;
  white-space: normal;
  text-overflow: ellipsis;
  padding-left: 48px;
  width: 440px;
  height: 41px;
  border-radius: 5px;
  font: ${theme.typography.regularFontSize};
  @media (prefers-reduced-motion: no-preference) {
    transition: all var(--transition-duration) ease-out, background 0ms;
  }
`
);

const SearchButton = styled.img`
  position: absolute;
  margin-left: 16px;
  width: 24px;
  height: 24px;
`;

const CrossIcon = styled.img`
  position: absolute;
  right: 5%;
  cursor: pointer;
  height: auto;
  vertical-align: top;
`;

export { FilterBoxContainer, ChannelSearchInputContainer, FilterInput, SearchButton, CrossIcon };
