import React from "react";
import { useDispatch } from "react-redux";
import { useReducers } from "../../shared/hooks/useReducer";

import VodPlayerSideBar from "./VodPlayerSideBar";
import constants from "../../shared/constants";
const { REDUCER_TYPE } = constants;

const Index = (props) => {
  const dispatch = useDispatch();
  const {
    seasonContent: episodeContent,
    seasonDetails: feedContent,
    seriesDetails,
  } = useReducers(REDUCER_TYPE.ON_DEMAND_PLAYER);

  return (
    <VodPlayerSideBar
      feedContent={feedContent}
      seriesDetails={seriesDetails}
      episodeContent={episodeContent}
      dispatch={dispatch}
      {...props}
    />
  );
};

export default Index;
