import React, { memo, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { showToastNotification, addFavourites, deleteFavourites } from "../../App/state/actions";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import constants from "../../shared/constants";
import routeConstants from "../../shared/constants/routes";
import storageConstants from "../../shared/constants/storage";
import { isGhostChannel, isUserSubscribedChannel } from "../../shared/utils/epg";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { handleImageError } from "../../shared/utils/image";
import { areEqual } from "react-window";
import ImageButton from "../ImageButton";

import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";
import { useReducers } from "../../shared/hooks/useReducer";

const { PAGE_CONTENT_ITEM_TYPES, REDUCER_TYPE } = constants;
const { LIVE_PLAYER } = routeConstants;
const { EPG_SELECTED_CHANNEL_NUMBER } = storageConstants;

const outOfHomeIcon = `${process.env.PUBLIC_URL}/images/Out_of_home.svg`;
const inHomeIcon = `${process.env.PUBLIC_URL}/images/In_Home.svg`;
const unsubscribedIcon = `${process.env.PUBLIC_URL}/images/key-icon.svg`;
const channel4kIcon = `${process.env.PUBLIC_URL}/images/4K_icon.svg`;
const defaultChannelImage = `${process.env.PUBLIC_URL}/images/default-channel-logo.png`;
const favouriteIcon = `${process.env.PUBLIC_URL}/images/favourite_white_update.svg`;
const favouriteActiveIcon = `${process.env.PUBLIC_URL}/images/favourite_active_channel.svg`;

/**
 * EPG channel cell component that is sticky-positioned on the left side of the screen
 *
 * @component
 */
const ChannelCell = memo(function ChannelCell({ style, channel, firstProgram, rowIndex }) {
  const {
    provider: appProvider,
    userProfile,
    subscribedChannels,
    isInHome,
    featureToggles: { isFavouritesEnabled },
    favouriteChannels,
  } = useReducers(REDUCER_TYPE.APP);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const isAPIcallFired = useRef(false);

  const { performPlaybackChecks } = usePlaybackChecks();

  const favouriteChannelIds = useMemo(
    () => favouriteChannels?.length > 0 && favouriteChannels.map((channel) => parseInt(channel?.id)),
    [favouriteChannels]
  );
  const isChannelNotSubscribed = userProfile?.isLoggedIn && !isUserSubscribedChannel(channel, subscribedChannels);
  const isChannelNotAvailableOutOfHome = channel.metadata?.isNotAvailableOutOfHome ?? false;
  const isProgramNotAvailableOutOfHome = firstProgram?.metadata?.isNotAvailableOutOfHome ?? false;
  const isProgramGeoBlocked = firstProgram?.metadata?.isGeoBlocked ?? false;
  const isChannelGhost = isGhostChannel(channel);
  const [isChannelFavourite, setIsChannelFavourite] = useState(
    favouriteChannelIds?.length > 0 && favouriteChannelIds.some((id) => id === channel.metadata?.channelId)
  );
  const isFavouriteChannelsUpdated = useRef(false);

  const onChannelCellClick = (event) => {
    event.preventDefault();

    if (userProfile.isLoggedIn) {
      if (isChannelNotSubscribed) {
        event.preventDefault();
        dispatch(showToastNotification(translate("message_not_subscribed"), unsubscribedIcon));
      } else if ((isChannelNotAvailableOutOfHome || isProgramNotAvailableOutOfHome) && !isInHome) {
        event.preventDefault();
        dispatch(showToastNotification(translate("message_in_home"), outOfHomeIcon));
      } else if (isChannelGhost) {
        event.preventDefault();
        dispatch(showToastNotification(translate("recordings_restriction_telustvplus"), channel4kIcon));
      } else if (isProgramGeoBlocked) {
        event.preventDefault();
        dispatch(showToastNotification(translate("error_program_not_available_region")));
      } else {
        performPlaybackChecks(null, null, channel?.id, PAGE_CONTENT_ITEM_TYPES.live, LIVE_PLAYER.route);
        setSessionStorage(EPG_SELECTED_CHANNEL_NUMBER, channel.number);
        setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.GUIDE};${rowIndex}:1`);
      }
    }
  };

  const addFavourite = () => {
    if (isAPIcallFired.current === false) {
      isAPIcallFired.current = true;
      dispatch(addFavourites(appProvider, channel, PAGE_CONTENT_ITEM_TYPES.live)).then(() => {
        isAPIcallFired.current = false;
        isFavouriteChannelsUpdated.current = true;
        setIsChannelFavourite(true);
      });
    }
  };

  const removeFavourite = () => {
    if (isAPIcallFired.current === false) {
      isAPIcallFired.current = true;
      dispatch(deleteFavourites(appProvider, channel, PAGE_CONTENT_ITEM_TYPES.live)).then(() => {
        isAPIcallFired.current = false;
        isFavouriteChannelsUpdated.current = true;
        setIsChannelFavourite(false);
      });
    }
  };

  return (
    <a
      href={`#${LIVE_PLAYER.route}/?stationId=${channel.id}&contentType=LIVE`}
      style={style}
      className="cell channel-cell"
      onClick={onChannelCellClick}
    >
      {userProfile?.isLoggedIn && isFavouritesEnabled && !isChannelNotSubscribed && (
        <span className="fav-button-container">
          <ImageButton
            src={isChannelFavourite ? favouriteActiveIcon : favouriteIcon}
            alt=""
            onClickHandler={(e) => {
              e.stopPropagation(); // prevents onChannelCellClick
              e.preventDefault(); // prevents link navigation
              if (isChannelFavourite) {
                removeFavourite();
              } else {
                addFavourite();
              }
            }}
          />
        </span>
      )}

      <div className="info">
        {isChannelNotSubscribed ? (
          <img src={unsubscribedIcon} alt="" />
        ) : isChannelNotAvailableOutOfHome || isProgramNotAvailableOutOfHome || isChannelGhost ? (
          <img src={isInHome && !isChannelGhost ? inHomeIcon : outOfHomeIcon} alt="" />
        ) : null}
        <span className="number">{channel.number}</span>
      </div>

      <div className="logo">
        {channel.assets?.[0]?.logoSmall ? (
          <img
            src={`${channel.assets[0].logoSmall}?w=80`}
            alt=""
            onError={(e) => handleImageError(e, defaultChannelImage)}
          />
        ) : (
          <img src={defaultChannelImage} alt="" />
        )}
      </div>
    </a>
  );
}, areEqual);

export default ChannelCell;

ChannelCell.propTypes = {
  style: PropTypes.object,
  channel: PropTypes.object,
  firstProgram: PropTypes.object,
  rowIndex: PropTypes.number,
};
