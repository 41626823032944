import moment from "moment";

import PlayerConstants from "../constants/player";
import middleware from "../middleware";
import constants from "../constants";
import { seasonCompareFunction } from "../utils";

const {
  ASSET_TYPES,
  USER_HAS_NO_RIGHTS,
  CONNECTION_ERROR_CODES,
  PLAYER_CONCURRENT_STREAM_ERROR,
  NO_BROWSER_SUPPORT_ERROR_CODES,
  STREAM_LIMIT_EXCEEDED_ERROR_CODES,
  STREAM_FORMAT_TYPES,
  AVS_ERROR_CODES: PLAYER_AVS_ERROR_CODES,
} = PlayerConstants;

const { WATCH_OPTIONS, CONTAINER_LAYOUTS, PAGE_CONTENT_ITEM_TYPES } = constants;

const { getContentUserData, retrieveAvsItems, getProgramSchedules } = middleware;
const TRAY_SEARCH_VOD = "/TRAY/SEARCH/VOD";

// Helper methods that provide stream information and metadata information during video playback

/**
 * Creates a video stream information object for the video player for player page
 * @param {String} streamMode
 * @param {Object} manifestURL
 * @param {String} playbackType  Stream format type (HLS or DASH)
 * @param {String} drmType
 * @param {String} LA_URL  Stream license URL
 * @param {String} LA_CERT_URL  Stream license certificate URL
 * @param {String} authToken  JWT Token returned with manifest URL from AVS
 * @param {Integer} assetId  ID of the stream asset to be used for playback
 * @param {String} contentId  ID of the content that the asset belongs to
 * @param {Array} bookmarks Array of bookmark objects
 * @param {String} externalContentId External content id that's specific for recordings
 * @param {String} bookmarkId
 * @param {String} tags
 * @return {Object} streamInfo object
 */

export const createVideoStream = (
  streamMode,
  manifestURL,
  playbackType,
  drmType,
  LA_URL,
  LA_CERT_URL,
  authToken,
  assetId,
  contentId,
  bookmarks = [],
  externalContentId = "",
  bookmarkId = "",
  tags = ""
) => {
  return {
    streamMode,
    manifestURL,
    playbackType,
    drmType,
    LA_URL,
    LA_CERT_URL,
    authToken,
    assetId,
    contentId,
    bookmarks,
    bookmarkId,
    tags,
    externalContentId,
  };
};

/**
 * Creates a metadata info object for the video player
 * @param {String} title
 * @param {String} subTitle
 * @param {String} image uri
 * @param {Integer} duration of the program in seconds
 * @param {Object} startTime of the program ( Moment object )
 * @param {String} channelNumber
 * @param {String} bgImage uri
 * @param {Object|null} extendedMetadata
 * @param {Object|null} recordingDeltaTime
 * @return {Object} metadata info object
 */
export const createMetadataInfo = (
  title = null,
  subTitle = null,
  image = null,
  duration = 0,
  startTime = {},
  channelNumber = null,
  bgImage = null,
  extendedMetadata = null,
  recordingDeltaTime = null
) => {
  return {
    title,
    subTitle,
    image,
    duration,
    startTime,
    channelNumber,
    bgImage,
    extendedMetadata,
    recordingDeltaTime,
  };
};

/**
 * Returns an error object based on the AVS errors from the server
 * @param {String} message
 * @return {Object} error code and the message
 */
export const getAVSPlaybackError = (errorObj) => {
  return { code: 100001, message: errorObj?.message };
};

/**
 * Method to check if the episode has any playable assets
 * @param {Object} data
 * @returns {Boolean} true if any master asset is playable else false
 */
const isCurrentEpisodePlayable = (data) =>
  data?.entitlement?.assets?.some(
    (asset) =>
      asset?.assetType?.toLowerCase() === ASSET_TYPES.MASTER && asset?.rights?.toLowerCase() === WATCH_OPTIONS.watch
  );

/**
 * Method to return the first episode of a TV show if playable
 * This method checks for all the vod options for an unified episode
 * @param {Object} appProvider
 * @param {Object} firstSeason
 * @param {Array} seasonEntitlements
 * @param {Boolean} isEntitlementCallNeeded
 * @returns {Array} If more than one playable svod options are present
 * @returns {Object} If any playable svod or tvod option is present
 */
export const getFirstPlayableEpisode = async (
  appProvider,
  firstSeason,
  seasonEntitlements,
  isEntitlementCallNeeded
) => {
  let firstPlayableEpisode;
  const subscribedEpisodeWatchOptions = [];

  try {
    let firstSeasonEpisodes;
    if (isEntitlementCallNeeded) {
      const firstSeasonFeed = await getContentUserData(
        appProvider,
        `${firstSeason?.id}?season=${firstSeason?.metadata?.season}`,
        PAGE_CONTENT_ITEM_TYPES.season
      );
      firstSeasonEpisodes = firstSeasonFeed?.containers;
    } else {
      firstSeasonEpisodes = seasonEntitlements;
    }

    const unifiedFirstEpisode = firstSeasonEpisodes?.sort(
      (previousEpisode, nextEpisode) => previousEpisode.metadata.episodeNumber - nextEpisode.metadata.episodeNumber
    )?.[0];

    let onDemandUri = `${TRAY_SEARCH_VOD}?filter_uaId=${unifiedFirstEpisode?.uaId}`;
    if (appProvider && appProvider.channelMapID) {
      onDemandUri = `${onDemandUri}&filter_regionId=${appProvider.channelMapID}`;
    }

    const allOnDemandOptions = await retrieveAvsItems(appProvider, onDemandUri);

    if (allOnDemandOptions?.containers?.length > 1) {
      for (
        let onDemandOptionsCount = 0;
        onDemandOptionsCount < allOnDemandOptions.containers.length;
        onDemandOptionsCount++
      ) {
        const currentEpisode = allOnDemandOptions.containers[onDemandOptionsCount];

        const userContent = await getContentUserData(appProvider, currentEpisode?.id, PAGE_CONTENT_ITEM_TYPES.vod);

        const userData = userContent?.containers?.find(
          (container) => container?.layout?.toLowerCase() === CONTAINER_LAYOUTS.CONTENT_ITEM
        );

        const currentEpisodeSVODInfo = currentEpisode?.metadata?.extendedMetadata?.source;

        if (
          currentEpisodeSVODInfo &&
          Object.keys(currentEpisodeSVODInfo).length > 0 &&
          isCurrentEpisodePlayable(userData)
        ) {
          currentEpisode.userData = userData;
          subscribedEpisodeWatchOptions.push(currentEpisode); // in case more than one vod option present, storing all playable svod options
        } else if (!firstPlayableEpisode && isCurrentEpisodePlayable(userData)) {
          firstPlayableEpisode = currentEpisode;
          firstPlayableEpisode.userData = userData;
        }
      }
    } else if (allOnDemandOptions?.containers?.length === 1) {
      const firstEpisodeWatchOption = allOnDemandOptions?.containers[0];
      const userContent = await getContentUserData(
        appProvider,
        firstEpisodeWatchOption?.id,
        PAGE_CONTENT_ITEM_TYPES.vod
      );
      const userData = userContent?.containers?.find(
        (container) => container?.layout?.toLowerCase() === CONTAINER_LAYOUTS.CONTENT_ITEM
      );
      if (isCurrentEpisodePlayable(userData)) {
        firstPlayableEpisode = firstEpisodeWatchOption;
        firstPlayableEpisode.userData = userData;
      }
    }
  } catch {
    firstPlayableEpisode = null;
  }
  if (subscribedEpisodeWatchOptions.length > 0) {
    return subscribedEpisodeWatchOptions;
  } else {
    return firstPlayableEpisode;
  }
};

/**
 * Method to return the playable episode of a TV show
 * @param {Object} appProvider
 * @param {Array} bookmark
 * @param {Object} season
 * @param {Object} seasonEntitlements
 * @param {Boolean} isEntitlementCallNeeded
 * @returns {Object} playable episode of a TV show
 */
export const getPlayableEpisode = async (
  appProvider,
  bookmark,
  season,
  seasonEntitlements,
  isEntitlementCallNeeded
) => {
  let playableEpisode;
  try {
    let seasonEpisodes;
    if (isEntitlementCallNeeded) {
      const seasonFeed = await getContentUserData(
        appProvider,
        `${season.id}?season=${season.metadata?.season}`,
        PAGE_CONTENT_ITEM_TYPES.season
      );
      seasonEpisodes = seasonFeed?.containers;
    } else {
      seasonEpisodes = seasonEntitlements;
    }
    const bookmarkedEpisode =
      seasonEpisodes?.length > 0 &&
      seasonEpisodes.find(
        (episode) =>
          episode?.metadata &&
          bookmark &&
          parseInt(episode.metadata.season) === parseInt(bookmark.season) &&
          parseInt(episode.metadata.episodeNumber) === parseInt(bookmark.episodeNumber) &&
          parseInt(episode.metadata.contentId) === parseInt(bookmark.bookmarkSet?.contentId)
      );
    let onDemandUri = `${TRAY_SEARCH_VOD}?filter_uaId=${bookmarkedEpisode?.uaId}`;
    if (appProvider?.channelMapID) {
      onDemandUri = `${onDemandUri}&filter_regionId=${appProvider.channelMapID}`;
    }

    const allOnDemandOptions = await retrieveAvsItems(appProvider, onDemandUri);
    const bookmarkedOption = allOnDemandOptions?.containers?.find((onDemandOption) => {
      return parseInt(onDemandOption.id) === parseInt(bookmark?.bookmarkSet?.contentId);
    });
    if (bookmarkedOption) {
      const userContent = await getContentUserData(appProvider, bookmarkedOption.id, PAGE_CONTENT_ITEM_TYPES.vod);
      const data = userContent?.containers?.find(
        (container) => container?.layout?.toLowerCase() === CONTAINER_LAYOUTS.CONTENT_ITEM
      );
      bookmarkedOption.userData = data;
      playableEpisode = bookmarkedOption;
    }
  } catch {
    playableEpisode = null;
  }
  return playableEpisode;
};

/**
 * Method to return converted playback error title & error message
 * @param {Object} playbackError
 * @returns {Object}
 */
export const getPlaybackErrorMessageInfo = (playbackError) => {
  let errorTitle = "";
  let errorMessage = "";

  if (playbackError.code) {
    const playbackErrorData = getErrorData(parseInt(playbackError.code));
    if (playbackErrorData && Object.values(playbackErrorData).length) {
      errorTitle = playbackErrorData.title;
      errorMessage = playbackErrorData.message;

      if (playbackErrorData.title && playbackErrorData.title === "avs_error_message") {
        errorTitle = "error";
        if (playbackError.message?.toLowerCase() === PLAYER_CONCURRENT_STREAM_ERROR) {
          errorMessage = "error_pbr_streams_limit";
        } else if (playbackError.message?.toLowerCase() === USER_HAS_NO_RIGHTS) {
          errorTitle = "asset_expired_title";
          errorMessage = "asset_expired_message";
        } else {
          errorMessage = playbackError.message;
        }
      }
    }
  } else if (playbackError.message) {
    errorTitle = "error_playback_title";
    errorMessage = playbackError.message;
  }

  return { errorTitle, errorMessage };
};

/**
 * Returns the modal title for the given type
 * @param {string} type
 * @return {string} error value
 */
function getErrorData(code) {
  if (CONNECTION_ERROR_CODES.includes(code)) {
    return {
      title: "error_playback_title",
      message: "connectivity_error_message",
    };
  } else if (STREAM_LIMIT_EXCEEDED_ERROR_CODES.includes(code)) {
    return {
      title: "no_bandwidth_error_title",
      message: "error_pbr_streams_limit",
    };
  } else if (NO_BROWSER_SUPPORT_ERROR_CODES.includes(code)) {
    return {
      title: "browser_not_supported",
      message: "error_playback_browser",
    };
  } else if (PLAYER_AVS_ERROR_CODES.includes(code)) {
    return { title: "avs_error_message" };
  } else {
    return {
      title: "error_playback_title",
      message: "sorry_error_occurred",
    };
  }
}

// In some scenarios specially in case of Safari browser,
// bitmovin player returns a random seven digit timestamp value which leads to incorrect progress bar treatment for such cases.
// Therefore, creating below function to determine a valid timestamp

/**
 * Determines whether the given timestamp is from past day or not
 * @param {number} timestamp
 * @return {boolean} timestamp is from past day or not
 */
export function isTimestampFromPastDay(timestamp) {
  return moment.unix(timestamp).isBefore(moment(), "day");
}

/**
 * Determine adaptive bitrate streaming type
 * @param {string} streamURL
 * @return {string} stream type
 */
export function getStreamType(streamURL) {
  const hlsRegPattern = new RegExp(/([a-zA-Z0-9\s_\\.\-():])+(.m3u8|.m3u)/, "i");
  const streamType = streamURL?.match(hlsRegPattern) ? STREAM_FORMAT_TYPES.HLS : STREAM_FORMAT_TYPES.DASH;
  return streamType; // HLS or DASH
}

/**
 * Retrieves next stream in queue for a restarted stream
 * @param {object} appProvider
 * @param {string} cancelTokenSource
 * @param {string} channelId
 * @param {number} startTime
 * @return {object} next stream in queue for a restarted stream
 */
export const getNextLiveStream = async (appProvider, cancelTokenSource, channelId, startTime) => {
  const nextLiveProgram = await getProgramSchedules(
    appProvider,
    null,
    null,
    false,
    cancelTokenSource,
    channelId,
    startTime,
    null,
    1,
    "asc"
  );
  return nextLiveProgram?.containers[0];
};

/**
 * Determined if the provided value is a valid timestamp or not
 * @param {number} value current playback time returned by the bitmovin player
 * @returns {boolean}
 */
export const isTimestamp = (value) => {
  // Check if the value is a number
  if (typeof value === "number") {
    // If the value is greater than a reasonable threshold, consider it a timestamp
    // Unix timestamps in seconds are currently greater than 10^9 (approximately 31.7 years from the epoch)
    if (value > 10 ** 9) {
      return true;
    } else {
      return false;
    }
  }

  // If the value is not a number, return false
  return false;
};
