import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import FilterDropdown from "../FilterDropdown";
import { updateURLQueryParam } from "../../shared/utils";
import "./style.scss";

/**
 *  Sort By Dropdown component
 *
 * @component
 * @param {Object} props
 */
const SortDropdown = ({ sortOptionList, applyButtonHandler, defaultSortParams, initialParamsState }) => {
  const [showDropdownList, setShowDropdownList] = useState(false);
  const [selectedItem, setSelectedItem] = useState(initialParamsState ?? defaultSortParams);
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();

  const getSortOptionList = () => {
    return sortOptionList.map((element, index) => {
      return {
        items: createList(element),
        linkClickHandler: sortLinkClickHandler,
        type: "radio",
        selectedItem: selectedItem[index] ? selectedItem[index] : null,
      };
    });
  };

  /**
   * Returns a dropdown list template for sort items
   * @return {Object}
   */
  const createList = (element) => {
    const contentList = element.values;
    let titleArray = contentList.map((element) => {
      return {
        value: isAppLanguageFrench && element.label_FRA ? element.label_FRA : element.label,
      };
    });

    const list = {
      label: isAppLanguageFrench && element.label_FRA ? element.label_FRA : element.label,
      items: titleArray,
      isLocalized: true,
    };
    return list;
  };

  /**
   * Determines the appropriate sort query param values using the provided itemData
   * @param {Object} itemData Object where keys represent groupings and values are the label of the selected item for that group
   * @returns Query params to achieve desired sort method
   */
  const selectSortItem = (itemData) => {
    let sortValue = "";
    for (let i = 0; i < sortOptionList[0].values.length; i++) {
      if (sortOptionList[0].values[i].label === itemData[0]) {
        const sortOption = sortOptionList[0].values[i];
        sortValue += sortOption.value;
        if (sortValue.startsWith("&") || sortValue.startsWith("?")) {
          sortValue = sortValue.substring(1);
        }
        break;
      }
    }

    updateUrl(sortValue);
    return sortValue;
  };

  /**
   * Shows sort dropdown pop up when clicked on the sort dropdown button
   */
  const buttonClickHandler = () => {
    setShowDropdownList(true);
  };

  const sortLinkClickHandler = (index, categoryIndex) => {
    const updatedSelectedItem = {
      ...selectedItem,
      [categoryIndex]:
        isAppLanguageFrench && sortOptionList[categoryIndex].values[index].label_FRA
          ? sortOptionList[categoryIndex].values[index].label_FRA
          : sortOptionList[categoryIndex].values[index].label,
    };
    setSelectedItem(updatedSelectedItem);
    const sortValue = selectSortItem(updatedSelectedItem);
    applyButtonHandler(sortValue);
    setShowDropdownList(false);
  };

  /**
   * Hides the sort dropdown pop up when clicked outside of the sort dropdown
   */
  const handleOutsideComponentClick = () => {
    setShowDropdownList(false);
  };

  /**
   * Updates the URL to match the current sort method
   * @param {String} urlParam Combined orderBy and sortOrder query params
   */
  const updateUrl = (urlParam) => {
    const queryParams = urlParam.split("&");

    updateURLQueryParam(queryParams[0]);
    updateURLQueryParam(queryParams[1]);
  };

  return (
    <FilterDropdown
      showDropdownList={showDropdownList}
      filterImage={
        selectedItem[0] !== defaultSortParams[0]
          ? `${process.env.PUBLIC_URL}/images/Sort_Icon_Active.svg`
          : `${process.env.PUBLIC_URL}/images/Sort_Icon.svg`
      }
      button={{
        label: translate("sort"),
        buttonClickHandler,
        filterCount: null,
        isFilterApplied: selectedItem[0] !== defaultSortParams[0],
        buttonStyles: selectedItem[0] !== defaultSortParams[0] ? "filter-applied" : "",
      }}
      lists={getSortOptionList()}
      popUp={{
        popUpStyles: "sort-options",
        handleOutsideComponentClick,
      }}
    />
  );
};

export default SortDropdown;

SortDropdown.propTypes = {
  sortOptionList: PropTypes.array.isRequired,
  applyButtonHandler: PropTypes.func.isRequired,
  defaultSortParams: PropTypes.object.isRequired,
  initialParamsState: PropTypes.object,
};
