import React from "react";
import "./style.scss";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { getBottomRatingDetails } from "../../shared/utils/feedHelper";
import rtLogo from "./assets/rt_logo.svg";
const CINOCHE_LOGO = process.env.PUBLIC_URL + "/images/Cinoche_Critics.svg";

/**
 * Display Rating UI component
 * @component
 */
const RatingUI = (props) => {
  const { isAppLanguageFrench } = useAppLanguage();

  const dlum = props.content?.extendedMetadata?.dlum;

  const getRatingImage = () => {
    if (isAppLanguageFrench) {
      return dlum?.cinoche ? CINOCHE_LOGO : rtLogo;
    } else {
      return dlum?.rt ? rtLogo : CINOCHE_LOGO;
    }
  };

  return (
    <div className="bottom-rating">
      <img className="rating-logo" src={getRatingImage()} alt=" " />
      <div className="score">{getBottomRatingDetails(dlum, isAppLanguageFrench)}</div>
    </div>
  );
};

export default RatingUI;
