import React, { memo } from "react";
import { areEqual } from "react-window";
import moment from "moment";
import PropTypes from "prop-types";
import epgConstants from "../../shared/constants/epg";
import ProgramCell from "./ProgramCell";
import "./style.scss";

const { TIMESLOT_MINUTES } = epgConstants;

/**
 * EPG program slot component that renders an empty placeholder slot as well as any programs
 * that start within the timeslot
 *
 * @component
 */
const ProgramSlot = memo(function ProgramSlot({ rowIndex, columnIndex, style, data }) {
  const {
    epgChannels,
    epgPrograms,
    currentTime,
    epgRange,
    recordingSystemType,
    setSelectedAssetRecordingInfo,
    updateRecordingHandler,
  } = data || {};

  const channel = epgChannels[rowIndex - 1];
  const programs = epgPrograms?.[channel.id];

  const cellStartTime = moment(epgRange.start)
    .add(TIMESLOT_MINUTES * (columnIndex - 1), "minute")
    .valueOf();
  const cellEndTime = moment(cellStartTime).add(TIMESLOT_MINUTES, "minute").valueOf();

  const displayPrograms = [];
  for (let i = 0; i < programs?.length; i++) {
    const program = programs[i];

    if (program.metadata?.airingStartTime >= cellStartTime && program.metadata?.airingStartTime < cellEndTime) {
      // Render programs that start in this cell
      displayPrograms.push(program);
    } else if (program.metadata?.airingStartTime > cellEndTime) {
      // Programs are sorted by start time so we can break out of the loop when we encounter a future program
      break;
    }
  }

  return (
    <>
      <div style={style} className="cell placeholder-cell" />
      {displayPrograms?.map((program) => {
        return (
          <ProgramCell
            key={program.id}
            rowNumber={rowIndex}
            columnNumber={programs.indexOf(program)}
            program={program}
            currentTime={currentTime}
            epgRange={epgRange}
            channel={channel}
            recordingSystemType={recordingSystemType}
            setSelectedAssetRecordingInfo={setSelectedAssetRecordingInfo}
            updateRecordingHandler={updateRecordingHandler}
            style={style}
          />
        );
      })}
    </>
  );
}, areEqual);

export default ProgramSlot;

ProgramSlot.propTypes = {
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  style: PropTypes.object,
  data: PropTypes.object,
};
