import request from "../../service/request";
import constants from "../constants";
import { getResponseObject } from "../utils";

const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

export function fetchVideoUrl(
  appProvider,
  contentType,
  contentId,
  assetId,
  pin,
  tokeOnly = false,
  cancelTokenSource = null
) {
  // CancelToken is deprecated in v0.22.0, will have to update to AbortController if we update Axios in the future
  let url = appProvider.config.avs_api_urls.video_url;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", "R");
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$CONTENT_TYPE", contentType);
  url = url.replace("$CONTENT_ID", contentId);
  url = url.replace("$ASSET_ID", assetId);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  if (tokeOnly) {
    url += "?onlyToken=true";
  }

  let headers;
  if (pin) {
    headers = {
      pcpin: pin,
    };
  }

  return request(
    { url, method: "GET", headers, withCredentials: true, returnResponseObject: true },
    cancelTokenSource,
    appProvider
  )
    .then((response) => {
      if (response?.data?.resultCode === "KO") {
        return Promise.reject(getResponseObject(response));
      } else return response?.data?.resultObj;
    })
    .catch((err) => {
      return Promise.reject(getResponseObject(err));
    });
}

/**
 * Retrieve Video JWT Token. (Currently only applies to live channel content)
 * @param {*} appProvider - App provider to provision calls based on session
 * @param {String} contentType Content Type ( Live only at the moment)
 * @param {String} contentId Content ID of the given video asset
 * @param {Object} cancelTokenSource CancelToken - deprecated in v0.22.0, will have to update to AbortController if we update Axios in the future
 * @return {Promise}
 */
export function fetchVideoToken(appProvider, contentType, contentId, cancelTokenSource = null) {
  let url = appProvider.config.avs_api_urls.video_auth_token;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", "R");
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$CONTENT_TYPE", contentType);
  url = url.replace("$CONTENT_ID", contentId);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request({ url, method: "GET", withCredentials: true }, cancelTokenSource, appProvider);
}
