import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { loadUserProfile, LOAD_SUBSCRIBED_CHANNELS, resetAction } from "../../App/state/actions";
import constants from "../../shared/constants";
import routeConstants from "../../shared/constants/routes";
import storageConstants from "../../shared/constants/storage";
import middleware from "../../shared/middleware";
import { setLocalStorage } from "../../shared/utils/localStorage";
import AvatarButton from "../AvatarButton";
import "./style.scss";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";

const { MAX_ACCOUNT_PROFILES } = constants;
const { PROFILE_ADD, PROFILE_EDIT } = routeConstants;
const { ACTIVE_PROFILE_USER_ID } = storageConstants;
const { loginProfile, logoutProfile } = middleware;

/**
 * Displays a list of profile avatars and names in a row. If isEditMode
 * is true, each avatar links to its profile's edit page.
 * @component
 * @param {Object} props
 */
function ProfileList({
  appProvider,
  profiles,
  isEditMode = false,
  userProfile,
  loadUserProfile,
  newProfileId,
  deletedProfile = null,
  resetAction,
}) {
  const [profileList, setProfileList] = useState(null);
  const { t: translate } = useTranslation();
  const history = useHistory();

  const activeProfile = userProfile?.user?.profile?.profileData;

  useEffect(() => {
    // Capture the original indices of each profile for link tracking in case we need to splice in a deleted profile
    const profilesCopy = profiles.map((profile, index) => ({
      ...profile,
      trueIndex: index,
    }));
    if (deletedProfile?.originalIndex) {
      profilesCopy.splice(deletedProfile.originalIndex, 0, deletedProfile);
    }
    setProfileList(profilesCopy);
  }, [deletedProfile, profiles]);

  return profileList?.length > 0 ? (
    <div className="profile-list">
      {profileList.map(({ avatar, userId, profileName, isMaster, kidsProfile, trueIndex }) => {
        const isNewProfile = newProfileId && newProfileId === userId;
        const isDeletedProfile = deletedProfile && deletedProfile.userId === userId;

        return (
          <AvatarButton
            key={userId}
            avatarInfo={avatar}
            to={isEditMode && !isDeletedProfile ? `${PROFILE_EDIT.route}/${userId}` : undefined}
            onClick={
              isDeletedProfile
                ? undefined
                : async () => {
                    if (isEditMode) {
                      setSessionStorage(
                        ANALYTICS_STORAGE_KEYS.LINK,
                        `${LINK_INFO.PROFILE_AVATAR}_${trueIndex + 1};${LINK_INFO.PROFILE}`
                      );
                    } else {
                      try {
                        if (activeProfile?.userId !== userId) {
                          if (activeProfile?.isMasterAccount === "N") {
                            await logoutProfile(appProvider);
                          }
                          if (!isMaster) {
                            await loginProfile(appProvider, userId);
                          }
                          await loadUserProfile(appProvider);
                          resetAction(LOAD_SUBSCRIBED_CHANNELS, "subscribedChannels");
                        }
                        setLocalStorage(ACTIVE_PROFILE_USER_ID, userId);
                        setSessionStorage(
                          ANALYTICS_STORAGE_KEYS.LINK,
                          `${LINK_INFO.PROFILE_AVATAR}_${trueIndex + 1};${LINK_INFO.PROFILE}`
                        );
                        history.push("/");
                      } catch (err) {
                        // TODO: How to handle switch profile errors?
                        console.error(err);
                      }
                    }
                  }
            }
            title={profileName}
            subtitle={isMaster ? translate("admin") : null}
            className={isNewProfile ? "newly-added" : isDeletedProfile ? "newly-deleted" : undefined}
            isKids={kidsProfile}
            isEditMode={isEditMode}
          />
        );
      })}
      {isEditMode && profiles.length < MAX_ACCOUNT_PROFILES && (
        <Link
          to={PROFILE_ADD.route}
          className="add-profile-link"
          onClick={() => {
            setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.ADD_PROFILE};${LINK_INFO.PROFILE}`);
          }}
        >
          <div className="icon-container">
            <img src={`${process.env.PUBLIC_URL}/images/plus-icon.svg`} alt={translate("add_profile")} />
          </div>
          <span>{translate("add_profile")}</span>
        </Link>
      )}
    </div>
  ) : null;
}

ProfileList.propTypes = {
  appProvider: PropTypes.object,
  profiles: PropTypes.array,
  isEditMode: PropTypes.bool,
  userProfile: PropTypes.object,
  loadUserProfile: PropTypes.func,
  newProfileId: PropTypes.number,
  deletedProfile: PropTypes.object,
  resetAction: PropTypes.func,
};

const mapStateToProps = ({ app }) => ({
  appProvider: app.provider,
  userProfile: app.userProfile,
  profiles: app.profileList,
});

const mapDispatchToProps = {
  loadUserProfile,
  resetAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileList);
