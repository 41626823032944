import {
  LOAD_PROGRAM_DETAIL,
  LOAD_SIMILAR_CONTENT_ITEMS,
  LOAD_SCHEDULES,
  LOAD_CONTENT_USERDATA,
  LOAD_CONTENT_ITEM_PAGE_CONTAINERS,
  LOAD_ON_DEMAND_OPTIONS,
  UPDATE_ON_DEMAND_OPTIONS,
} from "./actions";

const initialState = {
  containers: null,
  content: null,
  similarItemsResponse: null,
  liveOptions: null,
  contentUserData: null,
  onDemandOptions: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONTENT_ITEM_PAGE_CONTAINERS:
      return { ...state, containers: action.content };
    case LOAD_PROGRAM_DETAIL:
      return { ...state, content: action.content };
    case LOAD_SIMILAR_CONTENT_ITEMS:
      return { ...state, similarItemsResponse: action.content };
    case LOAD_SCHEDULES:
      return { ...state, liveOptions: action.content };
    case LOAD_CONTENT_USERDATA:
      return { ...state, contentUserData: action.content };
    case LOAD_ON_DEMAND_OPTIONS:
      return { ...state, onDemandOptions: action.content };
    case UPDATE_ON_DEMAND_OPTIONS:
      return { ...state, onDemandOptions: action.content };
    default:
      return state;
  }
};
