import request from "../../service/request";
import constants from "../constants";
const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

const { CONTENT_ITEM_TYPES } = constants;

export function getProgramDetail(appProvider, itemId, itemType) {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = itemType && itemType === CONTENT_ITEM_TYPES.vod ? AVS_API_LIST.movie_detail : AVS_API_LIST.episode_detail;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$ID", itemId);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }
  return request({ url, method: "GET", withCredentials }, null, appProvider);
}

export function getRecordingProgramDetail(appProvider, itemId) {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = AVS_API_LIST.CPVR.get_recordings_with_metadata;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$RECORDING_TYPE", "EVENT");

  url += "?filter_recordId=" + itemId;

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }
  return request({ url, method: "GET", withCredentials }, null, appProvider);
}
