import { useEffect, useRef, useReducer } from "react";
import { getSearchContent } from "../middleware/search";
import { capitalize } from "../utils";

export const useCachedResults = (appProvider, searchTerm, userProfile, pcLevel, manualSearchTriggered) => {
  const cache = useRef({});
  const modifiedSearchTerm = capitalize(searchTerm);

  const initialState = {
    status: "idle",
    error: null,
    data: [],
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;

    const fetchData = async () => {
      const pcLevelKey = pcLevel ?? "unrestricted";
      dispatch({ type: "FETCHING" });
      if (cache.current[modifiedSearchTerm]?.[pcLevelKey]) {
        const data = cache.current[modifiedSearchTerm][pcLevelKey];
        dispatch({ type: "FETCHED", payload: data });
      } else {
        try {
          const data = await getSearchContent(appProvider, modifiedSearchTerm, userProfile, pcLevel);
          if (data.every((promise) => promise.status === "fulfilled")) {
            if (!cache.current[modifiedSearchTerm]) {
              cache.current[modifiedSearchTerm] = {};
            }
            cache.current[modifiedSearchTerm][pcLevelKey] = data;
          }
          if (cancelRequest) return;
          if (data.every((promise) => promise.status === "rejected")) {
            dispatch({ type: "FETCH_ERROR", payload: data[0]?.reason?.message });
          } else {
            dispatch({ type: "FETCHED", payload: data });
          }
        } catch (error) {
          if (cancelRequest) return;
          dispatch({ type: "FETCH_ERROR", payload: error.message });
        }
      }
    };

    if (searchTerm.length >= 3 || searchTerm.length === 0) {
      fetchData();
    } else if (manualSearchTriggered) {
      fetchData();
    }

    return function cleanup() {
      cancelRequest = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appProvider, searchTerm, pcLevel, manualSearchTriggered]);

  return state;
};
