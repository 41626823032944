import middleware from "../../../shared/middleware";
import paginationConstants from "../../../shared/constants/pagination";
import { updateQueryStringParameter } from "../../../shared/utils";

const { retrieveAvsItems } = middleware;
const { INITIAL_START_INDEX, INITIAL_END_INDEX_VIEW_ALL } = paginationConstants;

// Action types
export const LOAD_VIEW_ALL = "LOAD_VIEW_ALL";

// Action methods
function loadViewAllItemsSuccess(content) {
  return { type: LOAD_VIEW_ALL, content };
}
function loadViewAllItemsFailure() {
  return { type: LOAD_VIEW_ALL, content: {} };
}
export const loadViewAllItems = (
  appProvider,
  actionURI,
  filterParams,
  sortParams,
  cancelTokenSource = null,
  startIndex = INITIAL_START_INDEX,
  endIndex = INITIAL_END_INDEX_VIEW_ALL,
  cachedContent
) => {
  return async (dispatch) => {
    try {
      if (filterParams) {
        actionURI += (actionURI.includes("?") ? "&" : "?") + filterParams;
      }
      if (sortParams) {
        actionURI += (actionURI.includes("?") ? "&" : "?") + sortParams;
      }
      actionURI = updateQueryStringParameter(actionURI, {
        from: startIndex,
        to: endIndex,
        maxResults: endIndex + 1,
      });
      let feedContent;
      if (cachedContent) {
        feedContent = { total: cachedContent.length, containers: cachedContent.slice(startIndex, endIndex + 1) };
      } else {
        feedContent = await retrieveAvsItems(appProvider, actionURI, false, cancelTokenSource);
      }
      if (feedContent) {
        dispatch(loadViewAllItemsSuccess(feedContent));
      }
    } catch (error) {
      dispatch(loadViewAllItemsFailure());
    }
  };
};
