import request from "../../service/request";
import constants from "../constants";
const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

export const addToFavourites = (appProvider, feedId, itemType, title, cancelTokenSource = null) => {
  const withCredentials = appProvider.userCluster === "R";

  let url = appProvider.config.avs_api_urls.manage_favourites_url;
  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", "R");
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$content_type", itemType);
  url = url.replace("$contentId", feedId);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request(
    {
      url,
      method: "POST",
      data: { title: title },
      returnResponseObject: true,
      withCredentials,
    },
    cancelTokenSource,
    appProvider
  );
};

export const removeFromFavourites = (appProvider, feedId, itemType) => {
  const withCredentials = appProvider.userCluster === "R";

  let url = appProvider.config.avs_api_urls.manage_favourites_url;
  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", "R");
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$content_type", itemType);
  url = url.replace("$contentId", feedId);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request(
    {
      url,
      method: "DELETE",
      returnResponseObject: true,
      withCredentials,
    },
    null,
    appProvider
  );
};
