import middleware from "../../../shared/middleware";
import constants from "../../../shared/constants";
import paginationConstants from "../../../shared/constants/pagination";

const { getSimilarItems, getProgramSchedules, getContentUserData, retrieveAvsItems } = middleware;

const { CONTAINER_TYPES, PAGE_CONTENT_ITEM_TYPES } = constants;
const { INITIAL_START_INDEX, INITIAL_END_INDEX } = paginationConstants;

// Action types
export const LOAD_SERIES_PAGE_CONTAINERS = "LOAD_SERIES_PAGE_CONTAINERS";
export const LOAD_SERIES_DETAIL = "LOAD_SERIES_DETAIL";
export const LOAD_SIMILAR_SERIES = "LOAD_SIMILAR_SERIES";
export const LOAD_PROGRAM_TITLES = "LOAD_PROGRAM_TITLES";
export const LOAD_SERIES_SCHEDULES = "LOAD_SERIES_SCHEDULES";
export const LOAD_SEASON_DATA = "LOAD_SEASON_DATA";
export const LOAD_FIRST_EPISODE_DATA = "LOAD_FIRST_EPISODE_DATA";
export const LOAD_SEASON_ENTITLEMENTS = "LOAD_SEASON_ENTITLEMENTS";
export const LOAD_CONTENT_USERDATA = "LOAD_CONTENT_USERDATA";
export const LOAD_LIST_OF_TEAMS = "LOAD_LIST_OF_TEAMS";

// Action methods
function loadPageContainersSuccess(content) {
  return { type: LOAD_SERIES_PAGE_CONTAINERS, content };
}
function loadPageContainersFailure() {
  return { type: LOAD_SERIES_PAGE_CONTAINERS, content: [] };
}
function loadSeriesSuccess(content) {
  return { type: LOAD_SERIES_DETAIL, content };
}
function loadSeriesFailure() {
  return { type: LOAD_SERIES_DETAIL, content: [] };
}

function loadSimilarItemsSuccess(content) {
  return { type: LOAD_SIMILAR_SERIES, content: content.containers, total: content.total };
}
function loadSimilarItemsFailure() {
  return { type: LOAD_SIMILAR_SERIES, content: [] };
}

function loadSchedulesSuccess(content, regionId) {
  return { type: LOAD_SERIES_SCHEDULES, content, regionId };
}
function loadSchedulesFailure() {
  return { type: LOAD_SERIES_SCHEDULES, content: [] };
}

function loadSeasonDataSuccess(content) {
  return { type: LOAD_SEASON_DATA, content };
}
function loadSeasonDataFailure() {
  return { type: LOAD_SEASON_DATA, content: {} };
}
function loadSeasonEntitlementsSuccess(content) {
  return { type: LOAD_SEASON_ENTITLEMENTS, content };
}

function loadSeasonEntitlementsFailure() {
  return { type: LOAD_SEASON_ENTITLEMENTS, content: [] };
}

function loadContentUserDataSuccess(content) {
  return { type: LOAD_CONTENT_USERDATA, content };
}

function loadContentUserDataFailure() {
  return { type: LOAD_CONTENT_USERDATA, content: {} };
}

function loadListOfTeamsSuccess(content) {
  return { type: LOAD_LIST_OF_TEAMS, content: content };
}
function loadListOfTeamsFailure(error) {
  return { type: LOAD_LIST_OF_TEAMS, content: error };
}

export const loadSeasons = (seasons) => {
  if (seasons?.length > 0) {
    return loadSeasonDataSuccess(seasons);
  } else {
    return loadSeasonDataFailure();
  }
};
export const loadSimilarItems = (
  appProvider,
  itemType,
  itemGenre,
  itemSubType = null,
  userProfile = null,
  pcLevel = null,
  cancelTokenSource = null
) => {
  return async (dispatch) => {
    try {
      const feedContent = await getSimilarItems(
        appProvider,
        itemType,
        itemGenre,
        itemSubType,
        userProfile,
        pcLevel,
        cancelTokenSource,
        INITIAL_START_INDEX,
        INITIAL_END_INDEX
      );
      if (feedContent) {
        dispatch(loadSimilarItemsSuccess(feedContent));
      }
    } catch (error) {
      dispatch(loadSimilarItemsFailure());
    }
  };
};

export const loadTVShowDetailItems = (appProvider, actionUri, tvShowDetailItem) => {
  let successAction;
  let failureAction;
  switch (tvShowDetailItem) {
    case CONTAINER_TYPES.CONTAINERS:
      successAction = loadPageContainersSuccess;
      failureAction = loadPageContainersFailure;
      break;
    case CONTAINER_TYPES.CONTENT:
      successAction = loadSeriesSuccess;
      failureAction = loadSeriesFailure;
      break;
    case CONTAINER_TYPES.SCHEDULES:
      successAction = (containers) => loadSchedulesSuccess(containers, appProvider.channelMapID);
      failureAction = loadSchedulesFailure;
      break;
    case CONTAINER_TYPES.MORE_LIKE_THIS:
      successAction = loadSimilarItemsSuccess;
      failureAction = loadSimilarItemsFailure;
      break;
    case CONTAINER_TYPES.LIST_OF_TEAMS:
      successAction = loadListOfTeamsSuccess;
      failureAction = loadListOfTeamsFailure;
      break;
    default:
      break;
  }
  return async (dispatch) => {
    try {
      const feedContent = await retrieveAvsItems(appProvider, actionUri);
      if (feedContent) {
        if (tvShowDetailItem === CONTAINER_TYPES.MORE_LIKE_THIS) {
          dispatch(successAction(feedContent));
        } else if (feedContent.containers) {
          dispatch(successAction(feedContent.containers));
        }
      }
    } catch (error) {
      dispatch(failureAction());
    }
  };
};

export const loadSeasonEntitlements = (appProvider, seasonId) => {
  return async (dispatch) => {
    try {
      const feedContent = await getContentUserData(appProvider, seasonId, PAGE_CONTENT_ITEM_TYPES.season);
      if (feedContent) {
        if (feedContent.containers && feedContent.containers.length > 0) {
          dispatch(loadSeasonEntitlementsSuccess(feedContent.containers));
        }
      }
    } catch (error) {
      dispatch(loadSeasonEntitlementsFailure());
    }
  };
};

export const loadSchedules = (appProvider, title, cancelTokenSource) => {
  return async (dispatch) => {
    try {
      const feedContent = await getProgramSchedules(appProvider, title, null, false, cancelTokenSource);
      if (feedContent) {
        dispatch(loadSchedulesSuccess(feedContent.containers, appProvider.channelMapID));
      } else {
        throw new Error("SeriesDetailPage - program schedules response object is invalid");
      }
    } catch (error) {
      dispatch(loadSchedulesFailure());
    }
  };
};

export const loadContentUserData = (appProvider, itemId, itemType = null) => {
  return async (dispatch) => {
    try {
      const feedContent = await getContentUserData(appProvider, itemId, itemType);
      if (feedContent) {
        if (feedContent.containers && feedContent.containers.length > 0) {
          dispatch(loadContentUserDataSuccess(feedContent.containers[0]));
        }
      }
    } catch (error) {
      dispatch(loadContentUserDataFailure());
    }
  };
};
