import { PlayerEvent } from "bitmovin-player";
import Conviva from "@convivainc/conviva-js-coresdk";

import {
  isConsole,
  isDesktop,
  isMobileOnly,
  isSmartTV,
  isTablet,
  osVersion,
  osName,
  browserName,
  browserVersion,
} from "react-device-detect";

import {
  PLAYBACK_TYPES,
  DEVICE_METADATA,
  CONTENT_METADATA,
  CUSTOM_METADATA,
  CUSTOM_EVENTS,
  CUSTOM_EVENTS_ATTRIBUTES,
  STREAM_TYPES,
  ContentMetadataBuilder,
  DEFAULT_RESOURCE,
  NOT_AVAILABLE,
  UNKNOWN,
  Timeout,
  STALL_TRACKING_DELAY_MS,
  LINE_TYPES,
  TRUE,
  FALSE,
  PLAY_BACK_MODE,
} from "../constants/conviva";
import { getSessionStorage } from "./sessionStorage";
import { getMappedContentType, getMappedShowType, getMappedUserProfileType, getCustomerType } from "./analytics";
import { getMediaContentDuration } from "../analytics/helpers";
import {
  ANALYTICS_ERROR_NAMES,
  ANALYTICS_STORAGE_KEYS,
  MAPPED_CONTENT_TYPES,
  SHOW_TYPES,
} from "../constants/analytics";
import constants from "../constants";
import PlayerConstants from "../constants/player";
import packageJson from "../../../package.json";
import { getPlaybackErrorMessageInfo } from "./playbackHelpers";
import { capitalize } from ".";
import { getRegionalChannelNumber } from "./epg";

let instance;

let maxVideoResolutionWidth = null;
let maxVideoResolutionHeight = null;
let convivaContentSubType = null;
let videoResolutionWidth = null;
let videoResolutionHeight = null;
let lastSeenBitrate = null;
let serverEdgeIp = null;
let isAfterFirstTimePlaying = false;
let needsParentalPin = false;

let convivaAnalytics = null;

let appProvider = null;
let isEnableDebugLog = false;
let userProfile = null;
let isSessionActive = false;
const contentMetadataBuilder = new ContentMetadataBuilder();
let stallTrackingTimeout = null;

class TelusConvivaAnalytics {
  constructor() {
    if (instance) {
      throw new Error("You can only create one instance!");
    }
    instance = this;
  }

  getInstance = () => {
    return this;
  };

  isConvivaAnalyticsInitialized = () => {
    return convivaAnalytics !== null;
  };

  initConvivaAnalytics = (initAppProvider, initUserProfile) => {
    appProvider = initAppProvider;
    userProfile = initUserProfile;

    if (!this.isConvivaAnalyticsInitialized() && initAppProvider.config?.conviva?.customer_key) {
      const settings = {};

      // No need to set GATEWAY_URL and LOG_LEVEL settings for production release.
      // The Conviva SDK provides the default values for production.
      if (!!initAppProvider.config?.conviva?.gateway_url) {
        settings[Conviva.Constants.GATEWAY_URL] = initAppProvider.config?.conviva?.gateway_url;
        settings[Conviva.Constants.LOG_LEVEL] = isEnableDebugLog ? Conviva.Constants.LogLevel.DEBUG : null;
      }

      Conviva.Analytics.init(initAppProvider.config?.conviva?.customer_key, {}, settings);

      // set device metadata
      const devMetadata = {
        [Conviva.Constants.Network.CONNECTION_TYPE]: window?.navigator?.connection?.type || NOT_AVAILABLE,
        [Conviva.Constants.DeviceMetadata.MODEL]: NOT_AVAILABLE,
        [Conviva.Constants.DeviceMetadata.MANUFACTURER]: NOT_AVAILABLE,
        [Conviva.Constants.DeviceMetadata.OS_NAME]: osName,
        [Conviva.Constants.DeviceMetadata.OS_VERSION]: osVersion,
        [Conviva.Constants.DeviceMetadata.TYPE]: getDeviceType(),
        [Conviva.Constants.DeviceMetadata.CATEGORY]: Conviva.Constants.DeviceCategory.WEB,
        [DEVICE_METADATA.NAME]: NOT_AVAILABLE,
        [DEVICE_METADATA.MARKETING_NAME]: NOT_AVAILABLE,
        [DEVICE_METADATA.OS_FAMILY]: getOSFamily(),
        [DEVICE_METADATA.BROWSER_NAME]: browserName,
        [DEVICE_METADATA.BROWSER_VERSION]: browserVersion,
      };

      Conviva.Analytics.setDeviceMetadata(devMetadata);

      // Create a Conviva monitoring object, this object will be used throughout the entire VideoPlayer lifecycle to report video related events.
      convivaAnalytics = Conviva.Analytics.buildVideoAnalytics();
    }
  };

  initVideoSession = (contentInfo, newConvivaContentSubType) => {
    convivaContentSubType = newConvivaContentSubType;
    if (isSessionActive) {
      this.updateSessionFromExternal(contentInfo);
    } else {
      this.initializeSessionFromExternal(contentInfo);
    }
  };

  /**
   * Initializes a new conviva tracking session.
   * If no source was loaded and no title in contentInfo this method will throw an error,
   * due to conviva SDK throws an error when initializing session without assetName
   *
   * @param {object} contentInfo
   * @param {player} player bitmovin player
   */
  initializeSession = (contentInfo, player) => {
    if (!player?.getSource() && contentInfo?.metadataInfo?.title) {
      throw Error("AssetName is missing.");
    }

    this.internalInitializeSession(contentInfo, player);
  };

  /**
   * A Conviva Session should only be initialized when there is a source provided in the player because
   * Conviva only allows to update different `contentMetadata` at different times.
   * @param {object} contentInfo
   * @param {object} player bitmovin player
   */
  internalInitializeSession = (contentInfo, player) => {
    if (!this.isConvivaAnalyticsInitialized()) {
      this.initConvivaAnalytics(appProvider, userProfile);
    }

    this.buildContentMetadata(contentInfo, player);

    if (isSessionActive) {
      convivaAnalytics.setContentInfo(contentMetadataBuilder.build());
      convivaAnalytics.setCallback(() => {
        const playheadTimeMs = player.getCurrentTime("relativetime") * 1000;
        convivaAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.PLAY_HEAD_TIME, playheadTimeMs);
      });
    } else {
      isSessionActive = true;
      convivaAnalytics.reportPlaybackRequested(contentMetadataBuilder.build());
    }

    if (lastSeenBitrate) {
      convivaAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.BITRATE, lastSeenBitrate);
    }

    this.reportServerIP();

    if (videoResolutionWidth && videoResolutionHeight) {
      this.reportInitVideoResolutionChangeEvent(videoResolutionWidth, videoResolutionHeight);
    }

    if (maxVideoResolutionWidth && maxVideoResolutionHeight) {
      this.reportMaxVideoResolution();
    }
  };

  initializeSessionFromExternal = (contentInfo) => {
    if (isSessionActive && this.isConvivaAnalyticsInitialized()) return;

    this.buildContentMetadata(contentInfo, null);
    convivaAnalytics.reportPlaybackRequested(contentMetadataBuilder.build());

    this.reportServerIP();
  };

  updateSessionFromExternal(contentInfo) {
    if (!isSessionActive || this.isConvivaAnalyticsInitialized()) return;
    this.buildContentMetadata(contentInfo, null);
    convivaAnalytics.setContentInfo(contentMetadataBuilder.build());
    this.reportServerIP();
  }

  reportServerIP = () => {
    if (serverEdgeIp) {
      convivaAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.CDN_IP, serverEdgeIp);
    }
  };

  internalEndSession = () => {
    if (isSessionActive && this.isConvivaAnalyticsInitialized()) {
      convivaAnalytics.reportPlaybackEnded();
    }

    maxVideoResolutionHeight = null;
    maxVideoResolutionWidth = null;
    videoResolutionWidth = null;
    videoResolutionHeight = null;
    lastSeenBitrate = null;
    convivaContentSubType = null;
    isAfterFirstTimePlaying = false;
    serverEdgeIp = null;
    needsParentalPin = false;
    isSessionActive = false;
    this.resetContentMetadata();
    convivaAnalytics?.release();
    Conviva.Analytics.release();
    convivaAnalytics = null;
  };

  onPlayNextLive = (contentInfo, player) => {
    if (isSessionActive && this.isConvivaAnalyticsInitialized()) {
      this.buildContentMetadata(contentInfo, player);
      convivaAnalytics.setContentInfo(contentMetadataBuilder.build());
      this.reportServerIP();
    }
  };

  onPlayNextProgram = () => {
    this.internalEndSession();
  };

  onSwitchProgram = () => {
    this.internalEndSession();
  };

  onPlayerClosed = () => {
    this.internalEndSession();
  };

  resetContentMetadata = () => {
    contentMetadataBuilder?.reset();
  };

  // Since there are no stall events during play / playing; seek / seeked; timeShift / timeShifted,
  // needed to track stalling state between those events. To prevent tracking eg. when seeking in buffer we delay it.
  startStallTrackingTimeout = () => {
    stallTrackingTimeout = new Timeout(STALL_TRACKING_DELAY_MS, () => {
      convivaAnalytics.current &&
        convivaAnalytics.current.reportPlaybackMetric(
          Conviva.Constants.Playback.PLAYER_STATE,
          Conviva.Constants.PlayerState.BUFFERING
        );
    });

    stallTrackingTimeout.start();
  };

  clearStallTrackingTimeout = () => {
    if (stallTrackingTimeout === null) return;

    stallTrackingTimeout.clear();
  };

  getPlayerState = (player) => {
    return player.isPlaying()
      ? Conviva.Constants.PlayerState.PLAYING
      : isAfterFirstTimePlaying
      ? Conviva.Constants.PlayerState.PAUSED
      : null;
  };

  trackSeekStart = () => {
    convivaAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.SEEK_STARTED);
  };

  trackSeekEnd = () => {
    convivaAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.SEEK_ENDED);
  };

  onPlaybackStateChanged = (propsRef, player, event, playbackError, resetPlayerPage) => {
    if (!this.isConvivaAnalyticsInitialized()) return;

    let playerState;
    const eventType = event.type;
    const bitrate = event?.targetQuality?.bitrate ? Math.round(event?.targetQuality?.bitrate / 1000) : null;
    const width = event?.targetQuality?.width ? event?.targetQuality?.width : null;
    const height = event?.targetQuality?.height ? event?.targetQuality?.height : null;

    if ((eventType === PlayerEvent.Play || eventType === PlayerEvent.Error) && !isSessionActive) {
      this.internalInitializeSession(propsRef.current, player);
    } else if (eventType === PlayerEvent.VideoPlaybackQualityChanged && !isSessionActive) {
      // Since the first videoPlaybackQualityChanged event sometimes comes before playback ever started,
      // we need to store the value and use it for tracking when initializing the session.
      videoResolutionWidth = width;
      videoResolutionHeight = height;

      lastSeenBitrate = bitrate;

      let maxResolution = player
        .getAvailableVideoQualities()
        .reduce((max, videoQuality) => (max.height > videoQuality.height ? max : videoQuality));
      if (maxResolution) {
        maxVideoResolutionWidth = maxResolution.width;
        maxVideoResolutionHeight = maxResolution.height;
      }
      return;
    }

    // Todo: comment out following code once we decide to manually track `bitmovin keep stalling` as en error when source host is shot down
    // currently, we don't report this error to user, screen only displays loading state
    //
    // Handle tracking stall timeout event, Conviva analytics would like to track it as an error if stalling more than 10 seconds
    // if (!intervalIdRef.current && event.type === PlayerEvent.StallStarted) {
    //   intervalIdRef.current = setInterval(() => {
    //     reportPlaybackDeficiency(contentInfo, player, 'Stalling time out', Conviva.Constants.ErrorSeverity.FATAL, false);
    //   }, 10000);
    // } else {
    //   intervalIdRef.current && clearInterval(intervalIdRef.current);
    //   intervalIdRef.current = null;
    // }

    switch (event.type) {
      case PlayerEvent.Play:
        this.startStallTrackingTimeout();
        break;
      case PlayerEvent.SourceLoaded:
        this.internalInitializeSession(propsRef.current, player);
        break;
      case PlayerEvent.Seek:
        this.trackSeekStart();
        this.startStallTrackingTimeout();
        break;
      case PlayerEvent.TimeShift:
        this.trackSeekStart();
        this.startStallTrackingTimeout();
        break;
      case PlayerEvent.StallStarted:
        this.clearStallTrackingTimeout();
        playerState = Conviva.Constants.PlayerState.BUFFERING;
        break;
      case PlayerEvent.Playing:
        if (!isAfterFirstTimePlaying) isAfterFirstTimePlaying = true;
        this.clearStallTrackingTimeout();
        playerState = Conviva.Constants.PlayerState.PLAYING;
        break;
      case PlayerEvent.Paused:
        this.clearStallTrackingTimeout();
        playerState = Conviva.Constants.PlayerState.PAUSED;
        break;
      case PlayerEvent.Seeked:
        this.trackSeekEnd();
        this.clearStallTrackingTimeout();
        playerState = this.getPlayerState(player);
        break;
      case PlayerEvent.TimeShifted:
        this.trackSeekEnd();
        playerState = this.getPlayerState(player);
        this.clearStallTrackingTimeout();
        break;
      case PlayerEvent.StallEnded:
        this.clearStallTrackingTimeout();
        playerState = this.getPlayerState(player);
        break;
      case PlayerEvent.PlaybackFinished:
        this.clearStallTrackingTimeout();
        this.internalEndSession();
        break;
      case PlayerEvent.VideoPlaybackQualityChanged:
        lastSeenBitrate = null;
        convivaAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.BITRATE, bitrate);
        this.reportVideoResolutionChangeEvent(width, height);
        this.checkMaxVideoResolution(player);
        break;
      case PlayerEvent.Error:
        resetPlayerPage();
        const { errorMessage } = getPlaybackErrorMessageInfo(playbackError?.message ? playbackError : event);
        const message = errorMessage ? errorMessage : NOT_AVAILABLE;

        this.reportPlaybackDeficiency(
          propsRef.current,
          player,
          `${ANALYTICS_ERROR_NAMES.SERVER_ERROR};Playback Error;${message};[${event?.code || NOT_AVAILABLE}]`,
          Conviva.Constants.ErrorSeverity.FATAL,
          true
        );
        break;
      case PlayerEvent.SourceUnloaded:
        this.internalEndSession();
        break;
      default:
        break;
    }

    if (playerState) {
      convivaAnalytics.reportPlaybackMetric(Conviva.Constants.Playback.PLAYER_STATE, playerState);
    }
  };

  /**
   * Sends a custom deficiency event during playback to Conviva's Player Insight.
   * If no session is active it will NOT create one.
   *
   * @param {string} message message which will be send to conviva
   * @param {Conviva.Constants.ErrorSeverity} severity one of FATAL or WARNING
   * @param {boolean} endSession a flag if session should be closed after reporting the deficiency
   */
  reportPlaybackDeficiency = (message, severity = Conviva.Constants.ErrorSeverity.FATAL, endSession = false) => {
    if (endSession) {
      convivaAnalytics.reportPlaybackFailed(message);
      this.internalEndSession();
    } else {
      convivaAnalytics.reportPlaybackError(message, severity);
    }
  };

  onLoadPlayerSuccessful = (contentInfo, player) => {
    this.initializeSession(contentInfo, player);
  };

  onLoadPlaybackFailed = (contentInfo, player) => {
    this.internalInitializeSession(contentInfo, player);
  };

  reportInitVideoResolutionChangeEvent = (newWidth, newHeight) => {
    var attr = {};
    attr[CUSTOM_EVENTS_ATTRIBUTES.OLD_QUALITY] = UNKNOWN;
    attr[CUSTOM_EVENTS_ATTRIBUTES.NEW_QUALITY] = getVideoResolutionString(newWidth, newHeight);
    attr[CUSTOM_EVENTS_ATTRIBUTES.PLAYER] = getAppName(appProvider?.config?.general?.environment);
    this.internalReportCustomEvent(CUSTOM_EVENTS.VIDEO_QUALITY_CHANGE, attr);
  };

  reportVideoResolutionChangeEvent = (newWidth, newHeight) => {
    var attr = {};
    attr[CUSTOM_EVENTS_ATTRIBUTES.OLD_QUALITY] = getVideoResolutionString(videoResolutionWidth, videoResolutionHeight);
    attr[CUSTOM_EVENTS_ATTRIBUTES.NEW_QUALITY] = getVideoResolutionString(newWidth, newHeight);
    attr[CUSTOM_EVENTS_ATTRIBUTES.PLAYER] = getAppName(appProvider?.config?.general?.environment);
    this.internalReportCustomEvent(CUSTOM_EVENTS.VIDEO_QUALITY_CHANGE, attr);

    videoResolutionWidth = newWidth;
    videoResolutionHeight = newHeight;
  };

  checkMaxVideoResolution = (player) => {
    let maxResolution = player
      .getAvailableVideoQualities()
      .reduce((max, videoQuality) => (max.height > videoQuality.height ? max : videoQuality));
    if (maxResolution) {
      if (maxResolution.width > maxVideoResolutionWidth && maxResolution.height > maxVideoResolutionHeight) {
        maxVideoResolutionWidth = maxResolution.width;
        maxVideoResolutionHeight = maxResolution.height;

        this.reportMaxVideoResolution();
      }
    }
  };

  reportMaxVideoResolution = () => {
    if (!isSessionActive || !this.isConvivaAnalyticsInitialized()) return;

    var contentInfo = {};
    contentInfo[CUSTOM_METADATA.VIDEO_RESOLUTION] = getVideoResolutionString(
      maxVideoResolutionWidth,
      maxVideoResolutionHeight
    );

    convivaAnalytics.setContentInfo(contentInfo);
    this.reportServerIP();
  };

  internalReportCustomEvent = (eventName, attributes) => {
    if (!isSessionActive || !this.isConvivaAnalyticsInitialized()) return;

    convivaAnalytics.reportPlaybackEvent(eventName, attributes);
  };

  reportParentalPinStart = (title, contentType) => {
    if (!this.isConvivaAnalyticsInitialized()) {
      this.initConvivaAnalytics(appProvider, userProfile);
    }

    if (!needsParentalPin) {
      if (!isSessionActive) {
        var contentInfo = {};
        contentInfo[Conviva.Constants.ASSET_NAME] = title || NOT_AVAILABLE;
        contentInfo[Conviva.Constants.IS_LIVE] = contentType;

        convivaAnalytics.reportPlaybackRequested(contentInfo);
        isSessionActive = true;
      }

      this.reportUserWait(true);
      this.internalReportCustomEvent(CUSTOM_EVENTS.PARENTAL_PIN_START, {});
      needsParentalPin = true;
    }
  };

  reportParentalPinCancel = () => {
    if (!needsParentalPin || !isSessionActive || !this.isConvivaAnalyticsInitialized()) return;

    this.internalReportCustomEvent(CUSTOM_EVENTS.PARENTAL_PIN_CANCEL, {});
    this.reportUserWait(false);
    this.internalEndSession();
  };

  reportParentalPinSuccess = () => {
    if (!needsParentalPin || !isSessionActive || !this.isConvivaAnalyticsInitialized()) return;

    needsParentalPin = false;
    this.internalReportCustomEvent(CUSTOM_EVENTS.PARENTAL_PIN_SUCCESS, {});
    this.reportUserWait(false);
  };

  reportParentalPinFailed = (maxRetry, err) => {
    if (!needsParentalPin || !isSessionActive || !this.isConvivaAnalyticsInitialized()) return;

    this.internalReportCustomEvent(CUSTOM_EVENTS.PARENTAL_PIN_FAILED, {});

    if (maxRetry) {
      this.reportUserWait(false);
      this.reportPlaybackError(err);
    }
  };

  reportUserWait = (started) => {
    convivaAnalytics.reportPlaybackEvent(
      started ? Conviva.Constants.Events.USER_WAIT_STARTED : Conviva.Constants.Events.USER_WAIT_ENDED
    );
  };

  reportPlaybackError = (err) => {
    const message = err.message ? err.message : NOT_AVAILABLE;

    this.reportPlaybackDeficiency(
      null,
      null,
      `${ANALYTICS_ERROR_NAMES.SERVER_ERROR};Playback Error;${message};[${err.code || NOT_AVAILABLE}]`,
      Conviva.Constants.ErrorSeverity.FATAL,
      true
    );
  };

  /**
   * Update contentMetadata
   * @param {object} contentInfo
   * @param {object} player bitmovin player
   */
  buildContentMetadata = (contentInfo, player) => {
    const regExp = /[a-zA-Z]/g;
    let title = contentInfo?.metadataInfo?.title || contentInfo?.seriesDetails?.title || NOT_AVAILABLE;
    const isRecording = contentInfo?.type === PlayerConstants.PLAYER_TYPE.RECORDING;
    const isVod = contentInfo?.type === PlayerConstants.PLAYER_TYPE.VOD;
    const isLive = contentInfo?.type === PlayerConstants.PLAYER_TYPE.LIVE;
    const playbackType = contentInfo?.playbackType || contentInfo?.type;
    const isTrailer = playbackType === PLAYBACK_TYPES.TRAILER;
    const isRestart = contentInfo?.isRestartedStream;
    const isLookback = contentInfo?.isLookbackStream;
    const username = userProfile?.user?.profile?.profileData?.username;
    const firstTwoLettersString = username?.length >= 2 ? username.substring(0, 2) : "";
    const streamType = isLive ? Conviva.Constants.StreamType.LIVE : Conviva.Constants.StreamType.VOD;
    const { USER_PROPERTY_NAMES } = constants;
    const isSPLUS =
      userProfile?.user?.profile?.profileData?.properties?.[0]?.propertyName === USER_PROPERTY_NAMES.SPLUS;
    const isTechTrial = regExp.test(firstTwoLettersString) && firstTwoLettersString !== "tq";
    const { lineType, networkAccessDevice } = parseOption82(appProvider?.option82);
    const isFast = contentInfo?.liveProgramDetails?.channel?.metadata?.extendedMetadata?.isFast === "Y" || false;

    contentMetadataBuilder.setMetadata(Conviva.Constants.IS_LIVE, streamType);
    contentMetadataBuilder.setMetadata(Conviva.Constants.DEFAULT_RESOURCE, DEFAULT_RESOURCE);
    contentMetadataBuilder.setMetadata(
      Conviva.Constants.PLAYER_NAME,
      getAppName(appProvider?.config?.general?.environment)
    );
    contentMetadataBuilder.setMetadata(
      Conviva.Constants.APPLICATION_VERSION,
      `${appProvider?.config?.version} (${packageJson.version})`
    );
    contentMetadataBuilder.setMetadata(
      Conviva.Constants.VIEWER_ID,
      userProfile?.masterAccountHouseholdID ?? appProvider?.accountID
    );
    contentMetadataBuilder.setMetadata(
      CONTENT_METADATA.TYPE,
      isLive || isRecording ? PlayerConstants.PLAYER_TYPE.LINEAR : PlayerConstants.PLAYER_TYPE.VOD
    );
    if (isLive && contentInfo?.liveProgramDetails) {
      title = contentInfo?.liveProgramDetails?.metadata?.title || NOT_AVAILABLE;
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.CHANNEL,
        `Channel ${contentInfo?.liveProgramDetails?.channel?.number || NOT_AVAILABLE} ${
          contentInfo?.liveProgramDetails?.channel?.metadata?.channelName || NOT_AVAILABLE
        }`
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.ASSET_ID,
        String(contentInfo?.liveProgramDetails?.metadata?.extendedMetadata?.dlum?.uaId || NOT_AVAILABLE)
      );

      contentMetadataBuilder.setMetadata(
        CUSTOM_METADATA.CHANNEL_ID,
        String(contentInfo?.liveProgramDetails?.channel?.id || NOT_AVAILABLE)
      );

      contentMetadataBuilder.setMetadata(
        Conviva.Constants.DURATION,
        getMediaContentDuration(!!contentInfo?.liveProgramDetails?.metadata?.programDuration, {
          duration: contentInfo?.liveProgramDetails?.metadata?.programDuration,
          airingStartTime: contentInfo?.liveProgramDetails?.metadata?.airingStartTime,
          airingEndTime: contentInfo?.liveProgramDetails?.metadata?.airingEndTime,
        })
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.MAIN_GENRE,
        contentInfo?.liveProgramDetails?.metadata?.genres?.[0]
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.GENRES,
        getGenres(contentInfo?.liveProgramDetails?.metadata?.genres)
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.CATEGORY_TYPE,
        contentInfo?.liveProgramDetails?.metadata?.extendedMetadata?.dlum?.uaGroupType || NOT_AVAILABLE
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.SEASON_NUM,
        String(contentInfo?.liveProgramDetails?.metadata?.season || 0)
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.EPISODE_NUM,
        String(contentInfo?.liveProgramDetails?.metadata?.episodeId || 0)
      );
      contentMetadataBuilder.setMetadata(
        CUSTOM_METADATA.TMS_ID,
        contentInfo?.liveProgramDetails?.metadata?.extendedMetadata?.dlum?.gracenote?.tmsId || NOT_AVAILABLE
      );
      contentMetadataBuilder.setMetadata(
        CUSTOM_METADATA.UNIVERSAL_METADATA_INFO,
        contentInfo?.liveProgramDetails?.metadata?.extendedMetadata?.dlum?.umId || NOT_AVAILABLE
      );
      contentMetadataBuilder.setMetadata(
        CUSTOM_METADATA.SHOW_TYPE,
        getMappedShowType(contentInfo.liveProgramDetails?.metadata?.extendedMetadata?.dlum?.uaGroupType, null)
      );

      if (contentInfo?.liveProgramDetails?.metadata?.contentType === "PROGRAM") {
        contentMetadataBuilder.setMetadata(CONTENT_METADATA.SERIES_NAME, title);
      }
    } else if (contentInfo?.seriesDetails) {
      if (!isVod) {
        contentMetadataBuilder.setMetadata(
          CONTENT_METADATA.CHANNEL,
          `Channel ${
            getRegionalChannelNumber(
              contentInfo?.programDetailsRef?.current?.[0]?.channel,
              appProvider?.channelMapID
            ) || NOT_AVAILABLE
          } ${contentInfo?.programDetailsRef?.current?.[0]?.channel?.channelName || NOT_AVAILABLE}`
        );
        contentMetadataBuilder.setMetadata(
          CUSTOM_METADATA.CHANNEL_ID,
          String(contentInfo?.programDetailsRef?.current?.[0]?.channel?.channelId || NOT_AVAILABLE)
        );
      }
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.ASSET_ID,
        contentInfo.seriesDetails.metadata?.extendedMetadata?.dlum?.uaId || NOT_AVAILABLE
      );
      contentMetadataBuilder.setMetadata(
        Conviva.Constants.DURATION,
        getMediaContentDuration(true, {
          duration:
            contentInfo.seriesDetails.metadata?.duration || contentInfo?.seriesDetails?.metadata?.programDuration,
        })
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.MAIN_GENRE,
        contentInfo.seriesDetails.metadata?.extendedMetadata?.dlum?.displayGenres?.[0]
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.GENRES,
        getGenres(contentInfo.seriesDetails.metadata?.extendedMetadata?.dlum?.displayGenres)
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.SEASON_NUM,
        String(contentInfo?.metadataInfo?.extendedMetadata?.season || 0)
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.EPISODE_NUM,
        String(contentInfo?.metadataInfo?.extendedMetadata?.episodeNumber || 0)
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.CATEGORY_TYPE,
        contentInfo.seriesDetails.metadata?.extendedMetadata?.dlum?.uaGroupType || NOT_AVAILABLE
      );
      contentMetadataBuilder.setMetadata(
        CUSTOM_METADATA.TMS_ID,
        contentInfo.seriesDetails.metadata?.extendedMetadata?.dlum?.gracenote?.tmsId || NOT_AVAILABLE
      );
      contentMetadataBuilder.setMetadata(
        CUSTOM_METADATA.UNIVERSAL_METADATA_INFO,
        contentInfo.seriesDetails.metadata?.extendedMetadata?.dlum?.umId || NOT_AVAILABLE
      );
      contentMetadataBuilder.setMetadata(
        CUSTOM_METADATA.SHOW_TYPE,
        getMappedShowType(contentInfo.seriesDetails?.metadata?.extendedMetadata?.dlum?.uaGroupType, null)
      );
    } else {
      if (isRecording) {
        contentMetadataBuilder.setMetadata(
          CONTENT_METADATA.CHANNEL,
          `Channel ${
            getRegionalChannelNumber(
              contentInfo?.programDetailsRef?.current?.[0]?.channel,
              appProvider?.channelMapID
            ) || NOT_AVAILABLE
          } ${contentInfo?.programDetailsRef?.current?.[0]?.channel?.channelName || NOT_AVAILABLE}`
        );
        contentMetadataBuilder.setMetadata(
          CUSTOM_METADATA.CHANNEL_ID,
          String(contentInfo?.programDetailsRef?.current?.[0]?.channel?.channelId || NOT_AVAILABLE)
        );
      }
      contentMetadataBuilder.setMetadata(
        CUSTOM_METADATA.SHOW_TYPE,
        getMappedShowType(contentInfo?.metadataInfo?.extendedMetadata?.dlum?.uaGroupType, null)
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.ASSET_ID,
        contentInfo?.metadataInfo?.extendedMetadata?.dlum?.uaId || NOT_AVAILABLE
      );
      contentMetadataBuilder.setMetadata(
        Conviva.Constants.DURATION,
        getMediaContentDuration(true, {
          duration: contentInfo?.metadataInfo?.duration || contentInfo?.metadataInfo?.programDuration,
        })
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.MAIN_GENRE,
        contentInfo?.metadataInfo?.extendedMetadata?.dlum?.displayGenres?.[0]
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.GENRES,
        getGenres(contentInfo?.metadataInfo?.extendedMetadata?.dlum?.displayGenres)
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.SEASON_NUM,
        String(contentInfo?.metadataInfo?.extendedMetadata?.season || 0)
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.EPISODE_NUM,
        String(contentInfo?.metadataInfo?.extendedMetadata?.episodeNumber || 0)
      );
      contentMetadataBuilder.setMetadata(
        CONTENT_METADATA.CATEGORY_TYPE,
        contentInfo?.metadataInfo?.extendedMetadata?.dlum?.uaGroupType || NOT_AVAILABLE
      );
      contentMetadataBuilder.setMetadata(
        CUSTOM_METADATA.TMS_ID,
        contentInfo?.metadataInfo?.extendedMetadata?.dlum?.gracenote?.tmsId || NOT_AVAILABLE
      );
      contentMetadataBuilder.setMetadata(
        CUSTOM_METADATA.UNIVERSAL_METADATA_INFO,
        contentInfo?.metadataInfo?.extendedMetadata?.dlum?.umId || NOT_AVAILABLE
      );
    }
    contentMetadataBuilder.setMetadata(
      CUSTOM_METADATA.CONTENT_SUBTYPE,
      getMappedContentType(
        isLive,
        playbackType,
        isLookback ? MAPPED_CONTENT_TYPES.LOOKBACK : isRestart ? MAPPED_CONTENT_TYPES.RESTART : convivaContentSubType,
        isRecording,
        isFast
      )
    );

    title =
      contentInfo?.metadataInfo?.extendedMetadata?.dlum?.sortTitle ||
      contentInfo?.seriesDetails?.metadata?.extendedMetadata?.dlum?.sortTitle ||
      title ||
      NOT_AVAILABLE;
    contentMetadataBuilder.setMetadata(CONTENT_METADATA.SERIES_NAME, title || NOT_AVAILABLE);
    contentMetadataBuilder.setMetadata(
      CONTENT_METADATA.SHOW_TITLE,
      isLookback
        ? contentInfo?.programDetailsRef?.current?.metadata?.episodeTitle || NOT_AVAILABLE
        : contentInfo?.metadataInfo?.subTitle ||
            contentInfo?.seriesDetails?.metadata?.subTitle ||
            contentInfo?.liveProgramDetails?.metadata?.episodeTitle ||
            NOT_AVAILABLE
    );

    if (isTrailer) {
      contentMetadataBuilder.setMetadata(CUSTOM_METADATA.SHOW_TYPE, SHOW_TYPES.TRAILER);
    }

    contentMetadataBuilder.setMetadata(
      CUSTOM_METADATA.SESSION_ID,
      getSessionStorage(ANALYTICS_STORAGE_KEYS.SESSION_ID) || NOT_AVAILABLE
    );
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.STREAM_LOCATION, PLAY_BACK_MODE.LOCAL || NOT_AVAILABLE);
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.DRM, getDRM(contentInfo?.streamInfo?.drmType || NOT_AVAILABLE));
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.CHANNEL_MAP, getChannelMap(appProvider?.geoCityName));
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.LOGGED_IN, contentInfo?.isLoggedIn ? TRUE : FALSE);
    contentMetadataBuilder.setMetadata(
      CUSTOM_METADATA.PLAY_BACK_MODE,
      getPlaybackMode(isLive, isRestart, isTrailer, isRecording, isLookback)
    );
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.IN_HOME, !!contentInfo?.isInHome ? TRUE : FALSE);

    contentMetadataBuilder.setMetadata(
      CUSTOM_METADATA.TAMS_ID,
      isLive ? NOT_AVAILABLE : contentInfo?.metadataInfo?.externalId || NOT_AVAILABLE
    );
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.UUID, appProvider?.uuid);
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.LINE_TYPE, lineType);
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.NETWORK_ACCESS_DEVICE, networkAccessDevice);
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.CUSTOMER_TYPE, getCustomerType(userProfile, isTechTrial));
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.PANIC_MODE, appProvider?.panicMode ? TRUE : FALSE);
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.STREAM_TYPE, STREAM_TYPES.VIDEO);
    contentMetadataBuilder.setMetadata(
      CUSTOM_METADATA.PROFILE_ID,
      String(userProfile?.user?.profile?.profileData?.userId || NOT_AVAILABLE)
    );
    contentMetadataBuilder.setMetadata(
      CUSTOM_METADATA.PROFILE_TYPE,
      getMappedUserProfileType(userProfile?.user?.profile?.profileData) || NOT_AVAILABLE
    );
    contentMetadataBuilder.setMetadata(
      CUSTOM_METADATA.SUBSCRIPTION_TYPE,
      userProfile?.isLoggedIn
        ? isSPLUS
          ? USER_PROPERTY_NAMES.SPLUS.toLowerCase()
          : appProvider?.isOptik
          ? USER_PROPERTY_NAMES.OPTIK.toLowerCase()
          : USER_PROPERTY_NAMES.PIK.toLowerCase()
        : null
    );
    contentMetadataBuilder.setMetadata(CUSTOM_METADATA.CDN_URL, contentInfo?.cdnUrl);

    if (contentInfo.cdnUrl) {
      try {
        const url = new URL(contentInfo?.cdnUrl);
        const hostname = url.hostname;

        contentMetadataBuilder.setMetadata(CUSTOM_METADATA.CDN_HOST_NAME, hostname);

        const groupID = isLive ? "LIVE" : "VOD";
        serverEdgeIp = `C-XServerSignature:cdn=INHOUSE;groupID=${groupID};server-signature=${url};status=200;url=${
          contentInfo?.streamInfo?.manifestURL || NOT_AVAILABLE
        }`;
      } catch (e) {
        //err handling
      }
    }

    contentMetadataBuilder.setMetadata(
      Conviva.Constants.STREAM_URL,
      contentInfo?.streamInfo?.manifestURL || NOT_AVAILABLE
    );

    contentMetadataBuilder.setMetadata(
      CUSTOM_METADATA.VIDEO_RESOLUTION,
      getVideoResolutionString(maxVideoResolutionWidth, maxVideoResolutionHeight)
    );

    contentMetadataBuilder.setMetadata(Conviva.Constants.ASSET_NAME, title || NOT_AVAILABLE);

    convivaAnalytics.reportPlaybackMetric(
      Conviva.Constants.Playback.AUDIO_LANGUAGE,
      !isVod
        ? contentInfo?.liveProgramDetails?.channel?.metadata?.channelLanguage?.toLowerCase() ||
            contentInfo?.metadataInfo?.extendedMetadata?.dlum?.defaultLanguage?.toLowerCase()
        : contentInfo?.metadataInfo?.extendedMetadata?.dlum?.defaultLanguage?.toLowerCase()
    );
    // player might not be ready when a conviva monitoring session starts
    if (!!player?.getSource()) {
      this.buildSourceRelatedMetadata(player, !isLive);
    }
  };

  /**
   * Build assetName and streamUrl from player source
   * @param {object} player bitmovin player
   * @param {boolean} isVod
   * */
  buildSourceRelatedMetadata = (player, isVod) => {
    const source = player?.getSource();

    contentMetadataBuilder.setMetadata(Conviva.Constants.STREAM_URL, getUrlFromSource(player.getStreamType(), source));

    if (isVod) {
      contentMetadataBuilder.setMetadata(Conviva.Constants.DURATION, player?.getDuration());
    }
  };
}

const telusConvivaAnalytics = Object.freeze(new TelusConvivaAnalytics());
export default telusConvivaAnalytics;

const getUrlFromSource = (streamType, source) => {
  switch (streamType) {
    case "dash":
      return source.dash;
    case "hls":
      return source.hls;
    case "progressive":
      if (Array.isArray(source.progressive)) {
        return source.progressive[0].url;
      } else {
        return source.progressive;
      }
    default:
      break;
  }
};

const getDRM = (drm) => {
  if (!drm) return NOT_AVAILABLE;
  const drmType = capitalize(drm);

  if (drmType === "Fairplay") return "FairPlay";
  return drmType;
};
const getGenres = (genres) => (Array.isArray(genres) ? genres.join(", ") : String(genres || NOT_AVAILABLE));
const getChannelMap = (regionName) => (regionName ? `geo-${regionName.toLowerCase()}` : NOT_AVAILABLE);

const getPlaybackMode = (isLive, isRestart, isTrailer, isRecording, isLookback) => {
  if (isRestart) return "REPLAY_PLAYER";
  if (isLookback) return "CATCHUP_PLAYER";
  if (isLive) return "LIVE_PLAYER";
  if (isTrailer) return "TRAILER_PLAYER";
  if (isRecording) return "RECORDING_PLAYER";
  return "VOD_PLAYER";
};

const getDeviceType = () => {
  if (isConsole) return Conviva.Constants.DeviceType.CONSOLE;
  if (isDesktop) return Conviva.Constants.DeviceType.DESKTOP;
  if (isMobileOnly) return Conviva.Constants.DeviceType.MOBILE;
  if (isSmartTV) return Conviva.Constants.DeviceType.SMARTTV;
  if (isTablet) return Conviva.Constants.DeviceType.TABLET;
  return NOT_AVAILABLE;
};

const getOSFamily = () => {
  const userAgent = window?.navigator?.userAgent || "";
  if (userAgent.indexOf("Windows") !== -1) return "Windows OS";
  if (userAgent.indexOf("Mac") !== -1) return "Mac OS";
  if (userAgent.indexOf("X11") !== -1) return "UNIX";
  if (userAgent.indexOf("Linux") !== -1) return "Linux";
  return NOT_AVAILABLE;
};

/**
 * Parse option82
 * @param {string} option82
 * @returns {Object} {lineType, networkAccessDevice}
 * */
const parseOption82 = (option82 = "") => {
  // eslint-disable-next-line no-unused-vars
  const [networkAccessDevice, _, lineTypeIndicator] = option82.split(" ");
  let lineType = NOT_AVAILABLE;

  if (networkAccessDevice) {
    if (/DS..$/.test(networkAccessDevice)) lineType = LINE_TYPES.COPPER;
    else if (/OT..$/.test(networkAccessDevice)) lineType = LINE_TYPES.FIBRE;
    else if (!lineTypeIndicator) lineType = LINE_TYPES.COPPER;
    else if (!!lineTypeIndicator) lineType = LINE_TYPES.GPON;
  }

  return {
    lineType,
    networkAccessDevice: networkAccessDevice ?? NOT_AVAILABLE,
  };
};

const getAppName = (env) => {
  if (!env) return NOT_AVAILABLE;
  return `TELUSTV-${env.replace("AVS-", "")}-WEB`;
};

const getVideoResolutionString = (width, height) => {
  if (width && height) {
    if (width == 1280 && height == 720) return "HD";
    if (width == 1920 && height == 1080) return "FHD";
    if (width == 2560 && height == 1440) return "2K";
    if (width == 3840 && height == 2160) return "UHD";
    if (width == 4096 && height == 2160) return "4K";
    if (width == 7680 && height == 4320) return "8K";
    if (width == 0 && height == 0) return UNKNOWN;
    if (height == 480) return "SD";
    else return "OTHER_" + width + "x" + height;
  }
  return UNKNOWN;
};
