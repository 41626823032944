import React, { useEffect, useState } from "react";
import "./style.scss";
import constants from "../../shared/constants";
import { useReducers } from "../../shared/hooks/useReducer";

const { SPINNING_LOADER_TIMER, REDUCER_TYPE } = constants;
const spinningLoader = process.env.PUBLIC_URL + "/images/spinner.png";

const SpinningLoader = () => {
  const { spinningLoaderParams } = useReducers(REDUCER_TYPE.APP);
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    if (spinningLoaderParams?.toggle) {
      const timeout = setInterval(() => {
        setIsDisplayed(true);
        // Loader should appear after 2.5 seconds have elapsed after the API call is made.
      }, SPINNING_LOADER_TIMER);
      return () => clearInterval(timeout);
    } else {
      setIsDisplayed(false);
    }
  }, [spinningLoaderParams]);

  return isDisplayed && spinningLoaderParams?.toggle ? (
    <div className={spinningLoaderParams.className ?? "spinner-backdrop"}>
      <img className="spinning-loader" src={spinningLoader} alt="" />
    </div>
  ) : (
    <></>
  );
};

export default SpinningLoader;
