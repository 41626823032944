import axios from "axios";
import request from "../../service/request";
import constants from "../constants";
import searchConstants from "../constants/search";
import paginationConstants from "../constants/pagination";
import { addQueryParamsToString, getKidExclusionRating } from "../utils";
import i18n from "../../i18n.js";

const {
  FEED_TITLES: { MOVIE, TV_SHOW },
} = searchConstants;
const { INITIAL_START_INDEX, INITIAL_END_INDEX_VIEW_ALL } = paginationConstants;

const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

let cancelToken;

export const getSearchContent = (
  appProvider,
  searchTerm,
  userProfile = null,
  pcLevel = null,
  filterParamString = null,
  searchOrder = null,
  startIndex = INITIAL_START_INDEX,
  endIndex = INITIAL_END_INDEX_VIEW_ALL,
  feedName = null
) => {
  let url = appProvider.config.avs_api_urls.search_unified_assets.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  const withCredentials = appProvider.userCluster === "R";
  const queryParams = {
    from: startIndex,
    to: endIndex,
    maxResults: endIndex + 1,
  };

  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$QUERY", "query");
  url = url.replace("$query", encodeURIComponent(searchTerm));

  if (appProvider.channelMapID) {
    queryParams.filter_regionId = appProvider.channelMapID;
  }

  if (appProvider.panicMode && withCredentials) {
    queryParams.filter_technicalPackages = appProvider.svodPackageIds;
    queryParams.filter_pcLevel = appProvider.pcLevel;
  } else if (pcLevel) {
    queryParams.filter_pcLevel = pcLevel;
  }

  if (queryParams.filter_pcLevel) {
    getKidExclusionRating(userProfile, queryParams);
  }

  if (filterParamString) {
    const filterParams = filterParamString.split("&").map((param) => param.split("="));
    filterParams.forEach(([key, value]) => {
      if (key === "content_filters" && value === "subscribed" && !queryParams.filter_technicalPackages) {
        queryParams.filter_technicalPackages = appProvider.packageIds;
      } else {
        queryParams[key] = value;
      }
    });
  }

  if (searchOrder) {
    queryParams.searchOrder = searchOrder.split("=")[1];
  }

  url = addQueryParamsToString(url, queryParams, true);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const movieUrl = url + "&filter_uaGroupType=MOVIE";
  const seriesUrl = url + "&filter_uaGroupType=TVSHOW";

  // Cancel previous request, but not if we are loading subsequent paginated results
  if (typeof cancelToken != typeof undefined && !startIndex) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();

  if (feedName === i18n.t(MOVIE)) {
    return request(
      {
        url: movieUrl,
        method: "GET",
        withCredentials,
        cancelToken: cancelToken.token,
      },
      null,
      appProvider
    );
  } else if (feedName === i18n.t(TV_SHOW)) {
    return request(
      {
        url: seriesUrl,
        method: "GET",
        withCredentials,
        cancelToken: cancelToken.token,
      },
      null,
      appProvider
    );
  } else {
    const movieRes = request(
      {
        url: movieUrl,
        method: "GET",
        withCredentials,
        cancelToken: cancelToken.token,
      },
      null,
      appProvider
    );

    const seriesRes = request(
      {
        url: seriesUrl,
        method: "GET",
        withCredentials,
        cancelToken: cancelToken.token,
      },
      null,
      appProvider
    );
    return Promise.allSettled([movieRes, seriesRes]);
  }
};
