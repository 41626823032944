import React, { useRef } from "react";
import PropTypes from "prop-types";
import "./style.scss";

import useOutsideClickIndicator from "../../shared/hooks/useOutsideClickIndicator";

/**
 * Create Popup Outline
 * @component
 * @param {Object} props
 */
function PopUpOutline({ className, width, height, children, handleOutsideComponentClick, optionalRef }) {
  const popUpRef = useRef(null);
  const classStyleName = `popUpContainer ${className}`;
  useOutsideClickIndicator(popUpRef, handleOutsideComponentClick, optionalRef);

  return (
    <div ref={popUpRef} className={classStyleName} style={{ width, height }}>
      {children}
    </div>
  );
}

export default PopUpOutline;

PopUpOutline.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.node.isRequired,
  handleOutsideComponentClick: PropTypes.func,
  optionalRef: PropTypes.object,
};

PopUpOutline.defaultProps = {
  className: "",
  width: "",
  height: "",
  handleOutsideComponentClick: () => {},
};
