import request from "../../service/request";
import constants from "../constants";
const { APP_ENVIRONMENTS, API_BASE_URL, PAGE_CONTENT_ITEM_TYPES } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

/**
 * Returns a list of favourites.
 * @param {object} appProvider App provider object
 * @param {String} contentType
 * @return {Promise}
 */
function getFavourites(appProvider, contentType) {
  const withCredentials = appProvider.userCluster === "R";
  let url = appProvider.config.avs_api_urls.favourites_url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$CONTENT_TYPE", contentType);
  if (contentType?.includes(PAGE_CONTENT_ITEM_TYPES.movie)) {
    url = `${url}&sortOrder=DESC`;
  }

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request({ url: url, method: "GET", withCredentials }, null, appProvider);
}

export default getFavourites;
