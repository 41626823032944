/**
 * load core modules
 */
import { useEffect, useCallback, useRef, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
/* load core modules end */

/**
 * load custom hooks
 */
import { useReducers } from "../hooks/useReducer";
/* load custom hooks end */

/**
 * load actions
 */
import {
  resetAction,
  showToastNotification,
  showModalPopup,
  showRecordingSettingsPanel,
} from "../../App/state/actions";
import {
  setRecordingAction,
  getRecordingAction,
  toggleSettingsPanelAction,
  SET_RECORDINGS,
  GET_RECORDINGS,
  EDIT_RECORDINGS,
  DELETE_RECORDINGS,
  MANIPULATE_RECORDING_ACTION_TRIGGERED,
} from "../../pages/RecordingsPage/state/actions";
/* load actions end */

import { logNREvent } from "../../shared/analytics/newRelic";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";

/**
 * load utilities
 */
import {
  getRecordingCTAs,
  getRecordingInfo,
  setRecording,
  getRecordingSystemType,
  getCPVRRecordingStatus,
  doesCPVREventRecordingExist,
  isCPVRRecordingInProgress,
  getRecordablePrograms,
  navigateToPVRManager,
  showCPVRConflictModal,
  checkCPVRConflicts,
  isRecordingPendingStateCheck,
} from "../utils/recordingHelper";
import { setSessionStorage } from "../utils/sessionStorage";
/* load utilities end */

/**
 * load analytics helper functions
 */
import { trackGenericAction, trackRecordingError } from "../analytics/dataLayer";
import { getCustomContextMetadata, trackConvivaCustomEvent } from "../analytics/media";
/* load analytics helper functions end */

/**
 * load constants
 */
import constants from "../constants";
import { LINK_INFO, ANALYTICS_STORAGE_KEYS, ANALYTICS_EVENT_TYPES } from "../constants/analytics";
import recordingConstants from "../constants/recordingConstants";
/* load constants end */

/** declare/destructure constants */
const { MODAL_TYPES, REDUCER_TYPE } = constants;
const { RECORDING_PARAMS, RECORDING_PACKAGES, MR_RECORDING_FILTER_OPTIONS } = recordingConstants;

/**
 * Custom hook for handling recordings on detail pages
 */
export const useRecordings = (
  currentAndUpcomingSchedules,
  preferredScheduleId,
  contentMetadata,
  setShowRecordingNowBadge
) => {
  const [recordingSystemType, setRecordingSystemType] = useState(null);
  const [selectedAssetRecordingInfo, setSelectedAssetRecordingInfo] = useState(null);
  const [recordingActionError, setRecordingActionError] = useState(null);
  const [showRecordingModal, setShowRecordingModal] = useState(false);

  const recordingEventTypeRef = useRef(null);
  const recordingEventCallbackFuncRef = useRef(null);
  const isRecordingActionInProgress = useRef(false);
  const prevRecordingOnNowResult = useRef();

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const {
    provider: appProvider,
    userProfile,
    isInHome,
    subscribedChannels,
    featureToggles,
  } = useReducers(REDUCER_TYPE.APP);
  const { isRecordingEnabled, isConvivaAppTrackerEnabled } = featureToggles;
  const {
    recordingsList,
    getRecordingError,
    setRecordingResponse,
    editRecordingResponse,
    deleteRecordingResponse,
    manipulatedRecordingParams,
  } = useReducers(REDUCER_TYPE.RECORDING);

  const isUserLoggedIn = userProfile?.isLoggedIn ? true : false;

  /** isMR - type: boolean - check if a login user has Media Room recording package, this constant is crucial for recording functions*/
  const isMR = useMemo(
    () => recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP,
    [recordingSystemType]
  );

  /** we need to create a recordable program list from schedules, this list can be used on recording functions */
  const recordablePrograms = useMemo(() => {
    if (isRecordingEnabled && currentAndUpcomingSchedules && subscribedChannels) {
      return getRecordablePrograms(currentAndUpcomingSchedules, subscribedChannels);
    }
  }, [currentAndUpcomingSchedules, isRecordingEnabled, subscribedChannels]);

  // list of recording info objects for every program schedule that the user is subscribed to and is airing on a recordable channel
  const programsRecordingInfoList = useMemo(() => {
    if (recordingsList && recordablePrograms) {
      return recordablePrograms.map((schedule) => {
        return getRecordingInfo(isMR, schedule, recordingsList, appProvider?.channelMapID);
      });
    }

    return null;
  }, [recordingsList, isMR, recordablePrograms, appProvider]);

  // Recording info that is associated with the primary recording button
  const primaryRecordingInfo = useMemo(() => {
    if (programsRecordingInfoList) {
      let preferredScheduleRecordingInfo;
      if (preferredScheduleId) {
        preferredScheduleRecordingInfo = programsRecordingInfoList.find(
          (recordingInfo) => recordingInfo.assetToRecord.id === preferredScheduleId
        );
      }

      // Try to find the first schedule that has a recording associated with it already
      let firstExistingRecordingAsset;
      if (isMR) {
        firstExistingRecordingAsset = programsRecordingInfoList.find((recordingInfo) => {
          return recordingInfo.recordingEventScheduledCheck || recordingInfo.recordingEventConflictCheck;
        });
      } else {
        firstExistingRecordingAsset = programsRecordingInfoList.find((recordingInfo) => {
          const assetRecordingStatus = getCPVRRecordingStatus(recordingInfo);
          return doesCPVREventRecordingExist(assetRecordingStatus);
        });
      }

      return firstExistingRecordingAsset || preferredScheduleRecordingInfo || programsRecordingInfoList[0];
    }

    return null;
  }, [isMR, preferredScheduleId, programsRecordingInfoList]);

  // Primary recording button CTA details
  const primaryRecordingCTA = useMemo(() => {
    if (primaryRecordingInfo) {
      if (isMR) {
        let isEventRecordingSet = false;
        if (primaryRecordingInfo.recordingEventScheduledCheck) {
          isEventRecordingSet = true;
        }

        return getRecordingCTAs(
          true,
          primaryRecordingInfo,
          recordablePrograms,
          primaryRecordingInfo.recordingEventConflictCheck,
          isEventRecordingSet,
          false,
          false
        );
      } else {
        return getRecordingCTAs(false, primaryRecordingInfo, recordablePrograms);
      }
    }
    return null;
  }, [isMR, primaryRecordingInfo, recordablePrograms]);

  // Used to display the loading spinner on the primary recording button, as appropriate
  const showRecordingCTALoading =
    manipulatedRecordingParams &&
    primaryRecordingInfo &&
    manipulatedRecordingParams.targetAssetInfo.id === primaryRecordingInfo.assetToRecord.id;

  /**
   * Determines the appropriate recording toast message to display
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {String} actionType - type of recording action that occurred (set/edit/delete)
   * @returns {String} recordings-specific toast message
   */
  const getRecordingToastMessage = useCallback(
    (recordingInfo, actionType) => {
      let toastMsg;
      if (recordingInfo.code) {
        if (actionType === "set") {
          toastMsg = translate(RECORDING_PARAMS.RECORDING_NOT_SET);
        } else if (actionType === "edit") {
          toastMsg = translate(RECORDING_PARAMS["RECORDING_NOT_UPDATED"]);
        } else if (actionType === "delete") {
          toastMsg = translate(RECORDING_PARAMS.COULD_NOT_CANCEL_RECORDING);
        }
      } else {
        if (actionType === "set") {
          toastMsg = translate(RECORDING_PARAMS["RECORDING_SET"]);
        } else if (actionType === "edit") {
          toastMsg = translate(RECORDING_PARAMS["RECORDING_UPDATED"]);
        } else if (actionType === "delete") {
          toastMsg = translate(RECORDING_PARAMS["RECORDING_CANCELLED"]);
        }
      }

      return toastMsg;
    },
    [translate]
  );

  /**
   * Displays the appropriate recording toast message (if any)
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {Object} manipulatedRecordingParams - refer to manipulateActionTriggered() in RecordingsPage/state/actions.js
   */
  const displayRecordingToast = useCallback(
    (recordingInfo, manipulatedRecordingParams) => {
      if (recordingInfo && manipulatedRecordingParams) {
        const toastMsg = getRecordingToastMessage(recordingInfo, manipulatedRecordingParams.actionType);
        if (toastMsg) {
          dispatch(showToastNotification(toastMsg));
          recordingEventTypeRef.current = null;
        }
      }
    },
    [getRecordingToastMessage, dispatch]
  );

  // Reset the recordings params associated with an outgoing recording manipulation action (set, edit, delete)
  const resetManipulatedRecordingParams = useCallback(() => {
    dispatch(resetAction(MANIPULATE_RECORDING_ACTION_TRIGGERED, "recordingParams"));
  }, [dispatch]);

  // Reset the response data from setting a recording
  const resetSetRecordingResponse = useCallback(() => {
    dispatch(resetAction(SET_RECORDINGS, "content"));
  }, [dispatch]);

  // Reset the response error from fetching recordings
  const resetGetRecordingError = useCallback(() => {
    dispatch(resetAction(GET_RECORDINGS, "error"));
  }, [dispatch]);

  // Reset the response data from editing a recording
  const resetEditRecordingResponse = useCallback(() => {
    dispatch(resetAction(EDIT_RECORDINGS, "content"));
  }, [dispatch]);

  // Reset the response data from deleting a recording
  const resetDeleteRecordingResponse = useCallback(() => {
    dispatch(resetAction(DELETE_RECORDINGS, "content"));
  }, [dispatch]);

  /**
   * Resets the values used to support recording CRUD action flows
   */
  const resetRecordingActionValues = useCallback(() => {
    isRecordingActionInProgress.current = false;
    resetSetRecordingResponse();
    resetEditRecordingResponse();
    resetDeleteRecordingResponse();
    resetManipulatedRecordingParams();
    resetGetRecordingError();
    setRecordingActionError(null);
  }, [
    resetDeleteRecordingResponse,
    resetEditRecordingResponse,
    resetGetRecordingError,
    resetManipulatedRecordingParams,
    resetSetRecordingResponse,
  ]);

  /**
   * Fetch the user's recordings and save the response in redux
   * @param {Object} recordingParamsMR - MR specific recording params used for set/edit/delete recording when initial attempt reports PENDING status
   * @param {Boolean} fetchingAfterAction - flag used to differentiate between initial fetch and post-action fetches
   */
  const fetchRecordings = useCallback(
    (recordingParamsMR = null, fetchingAfterAction = false) => {
      isRecordingActionInProgress.current = fetchingAfterAction;
      if (isMR) {
        dispatch(
          getRecordingAction(
            appProvider,
            true,
            null,
            null,
            null,
            MR_RECORDING_FILTER_OPTIONS.SCHEDULED_PAGE_FILTERS,
            recordingParamsMR
          )
        );
      } else {
        dispatch(getRecordingAction(appProvider, false, true, null, [RECORDING_PARAMS.EVENT, RECORDING_PARAMS.SERIES]));
      }
    },
    [appProvider, dispatch, isMR]
  );

  /**
   * A callback function which will be triggered after a recording has been updated
   * @param {Object} recordingItem
   * @param {Boolean} isItemLive
   * @param {Boolean} isSeriesEvent
   * @param {String} mappedContentType live|svod|tvod|cpvr|lpvr
   * @param {String} analyticsEventType analytics event type
   * @param {String} toolSelections a toolSelection string with format: 'key:{selection},key:{selection}...'
   * @param {Object} error an error object
   */
  const trackRecordingUpdateEventCallback = useCallback(
    ({ recordingItem, isItemLive, isSeriesEvent, mappedContentType, analyticsEventType, toolSelections, error }) => {
      if (error) {
        // Triggers a stop|delete recording error.
        // The error of start a recording comes from setRecordingResponse data which is handled via useEffect.
        trackRecordingError(analyticsEventType, error?.message, {
          errorCode: error?.code,
        });
      } else {
        recordingEventCallbackFuncRef.current = () => {
          trackGenericAction(analyticsEventType, {
            isItemLive,
            isSeriesEvent,
            toolSelections,
            mappedContentType,
            ...(isMR
              ? {
                  ...contentMetadata,
                  contentId: recordingItem?.metadata?.contentId,
                  channel: {
                    callLetter: selectedAssetRecordingInfo?.callLetter,
                    channelName: selectedAssetRecordingInfo?.channelName,
                    channelId: selectedAssetRecordingInfo?.mediaRoom?.channelId,
                    programId: selectedAssetRecordingInfo?.mediaRoom?.programId,
                  },
                }
              : primaryRecordingInfo.assetToRecord || recordingItem),
          });
        };

        if (
          analyticsEventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE ||
          analyticsEventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_COMPLETE
        ) {
          // At this moment, the result of editing a recording is unknown, but a toastMessage will show up on the screen according to the result of editing a recording.
          // Thus set the recordingEventType here, and then trigger the event based on the toastMessage later.
          recordingEventTypeRef.current = analyticsEventType;
        } else {
          // The result of cancel|delete a recording is ready at this moment
          recordingEventCallbackFuncRef.current();
        }
      }
    },
    [isMR, contentMetadata, selectedAssetRecordingInfo, primaryRecordingInfo]
  );

  /** Memoized showRecordingSettingHandler, function object will only change when showRecordingSettingsPanel changes */
  const showRecordingSettingHandler = useCallback(
    (recordingInfo, typeOfRecording) => {
      trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_START, {
        isItemLive: true,
        isSeriesEvent: false,
        ...(isMR
          ? {
              ...contentMetadata,
              channel: {
                callLetter: selectedAssetRecordingInfo?.callLetter,
                channelName: selectedAssetRecordingInfo?.channelName,
                channelId: selectedAssetRecordingInfo?.mediaRoom?.channelId,
                programId: selectedAssetRecordingInfo?.mediaRoom?.programId,
              },
            }
          : recordingInfo?.assetToRecord),
      });
      logNREvent(NR_PAGE_ACTIONS.RECORDING_EDIT);
      if (isConvivaAppTrackerEnabled) {
        trackConvivaCustomEvent(
          ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_START,
          getCustomContextMetadata(contentMetadata, userProfile, appProvider, isInHome)
        );
      }
      dispatch(
        showRecordingSettingsPanel(
          typeOfRecording,
          recordingInfo,
          !isMR ? recordablePrograms : null,
          false,
          trackRecordingUpdateEventCallback
        )
      );
    },
    [
      contentMetadata,
      selectedAssetRecordingInfo,
      isMR,
      dispatch,
      recordablePrograms,
      trackRecordingUpdateEventCallback,
      appProvider,
      isInHome,
      userProfile,
      isConvivaAppTrackerEnabled,
    ]
  );

  /**
   * Schedule a recording and save the response in redux
   *
   * @param {String} typeOfRecording - the recording type (event)
   * @param {Object} recordingInfo - recording information used to set the recording
   */
  const scheduleRecording = useCallback(
    (typeOfRecording, recordingInfo) => {
      if (recordingInfo) {
        recordingEventTypeRef.current = ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START;
        setRecording(typeOfRecording, appProvider, recordingInfo.assetToRecord, setRecordingAction, isMR, dispatch);
      }
      logNREvent(NR_PAGE_ACTIONS.RECORDING_START);
    },
    [appProvider, isMR, dispatch]
  );

  /**
   * Opens the modal used for deleting recorded recordings or cancelling scheduled/in progress recordings.
   *
   * @param {Object} recordingInfo Recording information
   * @param {Object} programDetails Program information
   */
  const openCancelRecordingModal = useCallback(
    (recordingInfo, programDetails) => {
      const modalContent = {
        recordingInfo,
        programDetails,
        isRecordingRecorded: false,
        recordingType: RECORDING_PARAMS.EVENT,
        isMR: false,
      };
      dispatch(showModalPopup(MODAL_TYPES.RECORDING, modalContent));
    },
    [dispatch]
  );

  const closeRecordingModal = () => {
    setShowRecordingModal(false);
  };

  /**
   * @param {Object} recordingInfo - Object containing response of particular asset from recordingsList object to compare and edit the recordings.
   * @param {String} typeOfRecording - EVENT
   */
  const editRecordingHandler = (recordingInfo, typeOfRecording) => {
    showRecordingSettingHandler(recordingInfo, typeOfRecording);
  };

  /** Click event handler replaces the recording modal popup*/
  const handleRecordingAction = useCallback(
    (event, recordingInfo = primaryRecordingInfo) => {
      event.stopPropagation();
      if (recordingInfo) {
        setSelectedAssetRecordingInfo(recordingInfo);
        if (isMR) {
          const hasEventRecording = recordingInfo.recordingEventScheduledCheck;
          if (hasEventRecording) {
            showRecordingSettingHandler(recordingInfo, RECORDING_PARAMS.EVENT);
            dispatch(toggleSettingsPanelAction(true));
          } else if (!recordingInfo.recordingEventConflictCheck && !recordingInfo.recordingEventScheduledCheck) {
            scheduleRecording(RECORDING_PARAMS.EVENT, recordingInfo);
          } else {
            setShowRecordingModal(true);
          }
        } else {
          const cpvrRecordingStatus = getCPVRRecordingStatus(recordingInfo);
          const isRecordingInProgress = isCPVRRecordingInProgress(cpvrRecordingStatus);
          if (doesCPVREventRecordingExist(cpvrRecordingStatus)) {
            if (isRecordingInProgress) {
              // Show cancel modal
              openCancelRecordingModal(recordingInfo, recordingInfo.assetToRecord);
            } else {
              // Show edit settings panel
              showRecordingSettingHandler(recordingInfo, RECORDING_PARAMS.EVENT);
              dispatch(toggleSettingsPanelAction(true));
            }
          } else {
            scheduleRecording(RECORDING_PARAMS.EVENT, recordingInfo);
          }
        }
      }
    },
    [isMR, openCancelRecordingModal, showRecordingSettingHandler, dispatch, scheduleRecording, primaryRecordingInfo]
  );

  useEffect(() => {
    if (isUserLoggedIn) {
      setRecordingSystemType(getRecordingSystemType(userProfile));
    }
  }, [isUserLoggedIn, userProfile]);

  /**
   * This useEffect is used to deal with errors that occur during recording CRUD actions
   */
  useEffect(() => {
    if ((getRecordingError || recordingActionError) && manipulatedRecordingParams) {
      displayRecordingToast(getRecordingError || recordingActionError, manipulatedRecordingParams);
      resetRecordingActionValues();
    }
  }, [
    displayRecordingToast,
    getRecordingError,
    manipulatedRecordingParams,
    recordingActionError,
    resetRecordingActionValues,
  ]);

  /**
   * This useEffect is used after we have re-fetched recordings following a set/edit/delete recording action.
   */
  useEffect(() => {
    if (programsRecordingInfoList && manipulatedRecordingParams && isRecordingActionInProgress.current) {
      // Can't use the selectedAssetRecordingInfo as-is, need to update it with the latest recording state checks/values
      const selectedRecordingInfo = programsRecordingInfoList.find((recordingInfo) => {
        return recordingInfo?.assetToRecord?.id === selectedAssetRecordingInfo?.assetToRecord?.id;
      });
      setSelectedAssetRecordingInfo(selectedRecordingInfo);

      if (isMR && selectedRecordingInfo?.recordingEventConflictCheck) {
        setShowRecordingModal(true);
      } else {
        displayRecordingToast(selectedRecordingInfo, manipulatedRecordingParams);
      }

      resetRecordingActionValues();
    }
  }, [
    displayRecordingToast,
    programsRecordingInfoList,
    isMR,
    manipulatedRecordingParams,
    resetRecordingActionValues,
    selectedAssetRecordingInfo,
  ]);

  /**
   * This useEffect is used after we have attempted to schedule a recording
   */
  useEffect(() => {
    if (setRecordingResponse && isRecordingActionInProgress.current === false) {
      if (isMR) {
        if (setRecordingResponse.code) {
          // Error occurred
          setRecordingActionError(setRecordingResponse);
        } else {
          fetchRecordings(
            isRecordingPendingStateCheck(setRecordingResponse.content) ? setRecordingResponse.recordingParams : null,
            true
          );
        }
      } else {
        if (setRecordingResponse.code) {
          // Error occurred
          if (checkCPVRConflicts(setRecordingResponse)) {
            // show popup modal for recording conflict error
            showCPVRConflictModal(
              () => {
                navigateToPVRManager(true); // Redirect user to PVR manager scheduled page to manage the conflict
                setSessionStorage(
                  ANALYTICS_STORAGE_KEYS.LINK,
                  `${LINK_INFO.MANAGE_RECORDINGS};${LINK_INFO.RECORDING_PROMPT}`
                );
              },
              MODAL_TYPES.ERROR,
              showModalPopup,
              dispatch
            );
            resetRecordingActionValues();
          } else {
            // show generic 'not set' toast notification for all other errors
            setRecordingActionError(setRecordingResponse);
          }
        } else {
          // No error in the response, refresh our recordings list
          fetchRecordings(null, true);
        }
      }
    }
  }, [setRecordingResponse, isMR, fetchRecordings, resetRecordingActionValues, dispatch]);

  /**
   * This useEffect is used after we have attempted to edit a recording
   */
  useEffect(() => {
    if (editRecordingResponse && isRecordingActionInProgress.current === false) {
      if (editRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(editRecordingResponse);
      } else {
        fetchRecordings(
          isMR && isRecordingPendingStateCheck(editRecordingResponse.content)
            ? editRecordingResponse.recordingParams
            : null,
          true
        );
      }
    }
  }, [editRecordingResponse, isMR, fetchRecordings]);

  /**
   * This useEffect is used after we have attempted to cancel a recording
   */
  useEffect(() => {
    if (deleteRecordingResponse && isRecordingActionInProgress.current === false) {
      if (deleteRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(deleteRecordingResponse);
      } else {
        // Nothing useful returned in the MR delete response, so we assume its pending
        fetchRecordings(isMR ? deleteRecordingResponse.recordingParams : null, true);
      }
    }
  }, [deleteRecordingResponse, fetchRecordings, isMR]);

  useEffect(() => {
    if (setShowRecordingNowBadge) {
      let result = false;
      if (primaryRecordingInfo) {
        if (isMR) {
          result = primaryRecordingInfo.recordingNowEventCheck ? true : false;
        } else {
          result = isCPVRRecordingInProgress(getCPVRRecordingStatus(primaryRecordingInfo));
        }
      }

      if (result !== prevRecordingOnNowResult.current) {
        setShowRecordingNowBadge(result);
      }

      prevRecordingOnNowResult.current = result;
    }
  }, [isMR, primaryRecordingInfo, setShowRecordingNowBadge]);

  useEffect(() => {
    return () => resetRecordingActionValues();
  }, [resetRecordingActionValues]);

  return {
    isMR,
    selectedAssetRecordingInfo,
    showRecordingModal,
    handleRecordingAction,
    showRecordingCTALoading,
    primaryRecordingCTA,
    closeRecordingModal,
    scheduleRecording,
    editRecordingHandler,
    openCancelRecordingModal,
  };
};
