export default Object.freeze({
  ERROR_TYPES: {
    ERROR: "error",
    SUCCESS: "success",
    INFO: "info",
  },
  ERROR_MESSAGES: {
    MULTI_GEO_ERROR: "user multigeo property does not match",
  },
  AVS_ERROR_CODES: {
    MAX_ATTEMPTS_INCORRECT_PURCHASE_PIN: "8116",
    MAX_ATTEMPTS_INCORRECT_PARENTAL_PIN: "8117",
    USER_NO_RIGHTS: "10146",
    CPVR_CONCURRENT_LIMIT_REACHED: "5009",
    INCORRECT_PIN_CODES: ["403-10145", "ACN_3124", "400-3124", "500-3124"],
    BAD_REQUEST: "400-10002",
    PROFILE_NAME_IN_USE: "ACN_10010",
    INVALID_PROFILE_NAME: "ACN_10013",
    AVATAR_IN_USE: "ACN_10014",
    MAX_PROFILES_REACHED: "ACN_10011",
    SERIES_METADATA_NOT_FOUND_ON_NPVR: "404-5027",
    PANIC_MODE: "503-10000",
    SERVICE_OUTAGE_CODES: ["500-10000", "500-10022", "500-10015"],
    FAVOURITE_LIMIT_REACHED: "500-1009",
    CPVR_AGL_PANIC_MODE: "400-5014",
    CPVR_SERIES_PANIC_MODE: "503-15014",
    TOKEN_NOT_VALID: "403-10100",
    TOKEN_NOT_VALID_2: "400-3179",
    USER_NOT_LOGIN: "401-10000",
    INVALID_STREAM_SESSION: "ACN_3503",
    INTERNAL_SERVER_ERROR: "500-10120",
    CASSANDRA_OUTAGE: "500-300",
    MS_OUTAGE: "503-901",
    OK: "200-10000",
  },
  API_STATUS_CODES: {
    BAD_REQUEST: 404,
    OK: 200,
  },
});
