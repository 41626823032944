import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

/**
 * Sets the page's title on the webpage
 * @component
 * @param {Object} props
 */
function SeoPageTags({ title = "", description = "", keywords = [] }) {
  const { t: translate } = useTranslation();
  const appTitle = translate("optik_tv");

  return (
    <Helmet>
      <title>{title ? `${appTitle} - ${title}` : appTitle}</title>
      <meta name="description" content={description ?? translate("browser_watch_telustvplus")} />
      <meta name="keywords" content={keywords.join()} />
      <link rel="icon" href={`${process.env.PUBLIC_URL}/favicon.ico`} />
    </Helmet>
  );
}

SeoPageTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

export default SeoPageTags;
