import React from "react";
import PropTypes from "prop-types";
import { ContextMenuTrigger } from "rctx-contextmenu";
import ContextMenuPanel from "./ContextMenuPanel";

import "./style.scss";

/**
 * Create Context Menu
 * @component
 * @param {Object} props
 */

function ContextualMenu({ id, menuAppendTo, contextMenuItems, children }) {
  return (
    <>
      <ContextMenuTrigger id={id}>{children}</ContextMenuTrigger>
      <ContextMenuPanel menuId={id} menuAppendTo={menuAppendTo} menuItems={contextMenuItems} />
    </>
  );
}

ContextualMenu.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  contextMenuItems: PropTypes.array.isRequired,
  menuAppendTo: PropTypes.string,
};
ContextualMenu.defaultProps = {
  id: "",
  contextMenuItems: [],
  menuAppendTo: "",
};

export default ContextualMenu;
