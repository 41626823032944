import React from "react";
import PropTypes from "prop-types";
import {
  FilterBoxContainer,
  ChannelSearchInputContainer,
  FilterInput,
  SearchButton,
  CrossIcon,
} from "./SearchBox.styles";
const SearchIconImage = `${process.env.PUBLIC_URL}/images/search-icon.png`;
const CrossIconImage = `${process.env.PUBLIC_URL}/images/cross-icon.svg`;

/**
 * FilterBox component
 * @component
 */
const SearchBox = ({ epgFilters, handleSearchChange, crossClickHandler }) => {
  //TODO: Need to update translation for Search channel name or number
  return (
    <FilterBoxContainer>
      <ChannelSearchInputContainer>
        <FilterInput
          type="text"
          placeholder="Search channel name or number"
          defaultValue={epgFilters.customFilter || ""}
          onChange={handleSearchChange}
        />
        <SearchButton src={SearchIconImage} alt="search" data-testid="searchIcon" />
        {epgFilters.customFilter && (
          <CrossIcon onClick={crossClickHandler} role="presentation" alt="crossIcon" src={CrossIconImage} />
        )}
      </ChannelSearchInputContainer>
    </FilterBoxContainer>
  );
};

SearchBox.propTypes = {
  epgFilters: PropTypes.shape({
    customFilter: PropTypes.string,
  }),
};

export default SearchBox;
