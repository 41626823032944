const epgConstants = {
  ALL_CHANNELS: "all_channels",
  PLAYABLE_ON_THIS_DEVICE: "playable_on_this_device",
  MY_SUBSCRIBED_CHANNELS: "my_subscribed_channels",
  MY_FAVOURITE_CHANNELS: "my_favourite_channels",
  AUTH_CHANNEL_FILTERS: () => {
    return [
      epgConstants.MY_SUBSCRIBED_CHANNELS,
      epgConstants.MY_FAVOURITE_CHANNELS,
      epgConstants.ALL_CHANNELS,
      epgConstants.PLAYABLE_ON_THIS_DEVICE,
    ];
  },
  UN_AUTH_CHANNEL_FILTERS: () => {
    return [epgConstants.ALL_CHANNELS];
  },
  AUDIO: "filter_audio",
  DIGITAL: "filter_digital",
  FULL_POWER_BROADCAST: "filter_full_power_broadcast",
  NBA_LEAGUE_PASS: "filter_nba_league_pass",
  NETWORK: "filter_network",
  FILTER_LABELS: {
    date: "date",
    time: "time",
    category: "category",
    channels: "channels",
    restart: "restart",
  },
  GUIDE_ONBOARDING_TUTORIAL: [
    {
      id: 1,
      title_key_name: "Feature_Onboarding_Lookback_Title",
      body_key_name: "Feature_Onboarding_Lookback_Body",
      image: "/images/Lookback_EN.gif",
    },
    {
      id: 2,
      title_key_name: "Feature_Onboarding_Contextual_Title",
      body_key_name: "Feature_Onboarding_Contextual_Body",
      image: "/images/Contextual_menu_EN.gif",
    },
    {
      id: 3,
      title_key_name: "Feature_Onboarding_Favourite_Title",
      body_key_name: "Feature_Onboarding_Favourite_Body",
      image: "/images/Favourite_EN.gif",
    },
  ],
  CATEGORY_FILTER_BUTTONS: {
    RESET: "reset",
    CANCEL: "cancel",
    APPLY: "apply",
  },
  EPG_DATA_CHUNK_SIZE: 3,
  TIMESLOT_MINUTES: 30,
  TIMESLOT_WIDTH: 250,
  TIMESLOT_HEIGHT: 50,
  CHANNEL_CELL_WIDTH: 201,
  PROGRAM_ROW_HEIGHT: 70,
  DEFAULT_RANGE_DAYS: 11,
  EPG_END_BUFFER_SLOTS: 1,
  CHANNEL_TYPES: {
    LIVE: "LIVE",
    PPV: "PPV VAR",
  },
  PPV_STATES: {
    BARKER: "barker",
    PURCHASABLE: "purchasable",
    PURCHASED: "purchased",
  },
  EPG_CALENDER: {
    AM: "AM",
    PM: "PM",
    FRENCH_TIME_FORMAT: "HH:mm",
    ENGLISH_TIME_FORMAT: "hh:mm",
    FRENCH_LANG_ABBR: "fr-ca",
    ENGLISH_LANG_ABBR: "en-us",
    DAYS: "days",
    DAY: "day",
    WEEK: "week",
    HOUR: "hour",
  },
};

export default epgConstants;
