// TODO: Remove this file after removing its usage from SubCategoryPage
import React, { useState, useEffect } from "react";
import Swimlane from "../../components/Swimlane";
import SwimlaneItem from "../../components/SwimlaneItem";
import SWIMLANE_CONSTANTS from "../constants/swimlane";
import middleware from "../middleware";
import Carousel from "../../components/Carousel";
import createSwimlaneModels from "../utils/dataFilters";
import {
  addQueryParamsToString,
  getQueryParamsByName,
  getProgramInAscendingOrder,
  isPanicMode,
  getPcLevelRestriction,
  updateQueryStringParameter,
  getKidExclusionRating,
} from "../utils";
import useAppLanguage from "../hooks/useAppLanguage";
import { useReducers } from "../hooks/useReducer";
import { isItemTypeRecording } from "../utils/content";
import paginationConstants from "../constants/pagination";
import constants from "../constants";

const { retrieveAvsItems } = middleware;
const { INITIAL_END_INDEX, INITIAL_START_INDEX } = paginationConstants;
const { REDUCER_TYPE } = constants;
const TRAY_SEARCH_VOD = "TRAY/SEARCH/VOD";

const { MAX_SWIMLANE_ITEMS } = SWIMLANE_CONSTANTS;
/**
 * Component Registry contains a list of all UI components that need to be created corresponding to the AVS UI Editor's components
 * @return {Object} Registry object `Key/value`: Key = AVS Component: Value: UI Component Config Component
 * The UI Component Config must return a UI Component
 */
const componentRegistry = {
  LARGE_CARDS: (
    appProvider,
    avsComponent,
    subscribedChannelIds,
    userProfile,
    updateAvailableSwimlanes,
    trackPageViewWithProductImpressions,
    favChannelIds,
    breadCrumbTitle
  ) => {
    let { DIMENSIONS, CONFIG } = SWIMLANE_CONSTANTS.ITEM_TYPES.TITLE_ITEM.PORTRAIT;

    let defaultFeed = { title: "", items: [] };
    let Comp = getAvsUiComponent(
      Swimlane,
      appProvider,
      avsComponent,
      createSwimlaneModels,
      { DIMENSIONS, CONFIG, userProfile },
      subscribedChannelIds,
      updateAvailableSwimlanes
    );

    return (
      <Comp
        key={avsComponent.id}
        feed={defaultFeed}
        dimensionConfig={DIMENSIONS}
        sliderSettings={CONFIG}
        ThumbnailComponent={SwimlaneItem}
        userProfile={userProfile}
        breadCrumbTitle={breadCrumbTitle}
      />
    );
  },
  SMALL_CARDS: (appProvider, avsComponent, subscribedChannelIds, userProfile, updateAvailableSwimlanes) => {
    let { DIMENSIONS, CONFIG } = SWIMLANE_CONSTANTS.ITEM_TYPES.TITLE_ITEM.LANDSCAPE;

    let defaultFeed = { title: "", items: [] };

    let Comp = getAvsUiComponent(
      Swimlane,
      appProvider,
      avsComponent,
      createSwimlaneModels,
      { DIMENSIONS, CONFIG, userProfile },
      subscribedChannelIds,
      updateAvailableSwimlanes
    );

    return (
      <Comp
        key={avsComponent.id}
        feed={defaultFeed}
        dimensionConfig={DIMENSIONS}
        sliderSettings={CONFIG}
        ThumbnailComponent={SwimlaneItem}
      />
    );
  },
  CAROUSEL: (
    appProvider,
    avsComponent,
    subscribedChannelIds,
    userProfile,
    updateAvailableSwimlanes,
    trackPageViewWithProductImpressions
  ) => {
    let CarouselHOC = getAvsUiComponent(
      Carousel,
      appProvider,
      avsComponent,
      null,
      { userProfile },
      subscribedChannelIds
    );

    return (
      <CarouselHOC
        key={avsComponent.id}
        appProvider={appProvider}
        userProfile={userProfile}
        trackPageViewWithProductImpressions={trackPageViewWithProductImpressions}
      />
    );
  },
  "169large": (
    appProvider,
    avsComponent,
    subscribedChannelIds,
    userProfile,
    updateAvailableSwimlanes,
    trackPageViewWithProductImpressions,
    favChannelIds
  ) => {
    const { DIMENSIONS, CONFIG } = SWIMLANE_CONSTANTS.ITEM_TYPES.TITLE_ITEM.LANDSCAPE;

    const defaultFeed = { title: "", items: [] };
    const Comp = getAvsUiComponent(
      Swimlane,
      appProvider,
      avsComponent,
      createSwimlaneModels,
      { DIMENSIONS, CONFIG, userProfile },
      subscribedChannelIds,
      updateAvailableSwimlanes,
      favChannelIds
    );

    return (
      <Comp
        key={avsComponent.id}
        feed={defaultFeed}
        dimensionConfig={DIMENSIONS}
        sliderSettings={CONFIG}
        ThumbnailComponent={SwimlaneItem}
        userProfile={userProfile}
      />
    );
  },
};

/**
 * A higher order component that returns a UI component that is capable of retrieving data from AVS
 * @param UIComponent An Optik UI Component
 * @param object appProvider
 * @param object avsComponent config
 * @param function Data retrieval function filter
 * @param object additional params to pass to filter function
 * @return Component Returns a HOC with the Optik UI component along with it's data.
 */
function getAvsUiComponent(
  Component,
  appProvider,
  avsComponent,
  dataFilter,
  addParams,
  subscribedChannelIds,
  updateAvailableSwimlanes = () => () => null,
  favChannelIds
) {
  return function (props) {
    const { currentPrograms } = useReducers(REDUCER_TYPE.EPG);
    const { featureToggles } = useReducers(REDUCER_TYPE.APP);
    const { isUserProfilesEnabled, isRecordingsEnabled, isParentalPINEnabled } = featureToggles;
    const [data, setData] = useState(null);
    const { isAppLanguageFrench } = useAppLanguage();

    useEffect(() => {
      const activeProfile = addParams?.userProfile?.user?.profile?.profileData;
      const isKidsProfile = isUserProfilesEnabled && activeProfile?.kidsProfile;

      let isMounted = true;
      let actionUri = avsComponent?.retrieveItems?.uri;
      if (actionUri && avsComponent.retrieveItems.type === "REMOTE") {
        if (actionUri.includes(TRAY_SEARCH_VOD)) {
          const reqParams = {
            maxResults: INITIAL_END_INDEX + 1,
            to: INITIAL_END_INDEX,
            from: INITIAL_START_INDEX,
          };
          actionUri = updateQueryStringParameter(actionUri, reqParams);
        }

        const filterAiringTime = getQueryParamsByName("filter_airingTime", actionUri);
        const isLiveItem = filterAiringTime === "now";

        if (actionUri.includes("/TRAY/SEARCH/PROGRAM")) {
          const filterChannelIds = getQueryParamsByName("filter_channelIds", actionUri)?.split(",");
          if (filterChannelIds?.length && subscribedChannelIds?.length) {
            const channelIds = filterChannelIds.filter((channelId) => subscribedChannelIds.includes(channelId));
            actionUri =
              channelIds && channelIds.length
                ? actionUri.replace(
                    `filter_channelIds=${filterChannelIds.join()}`,
                    `filter_channelIds=${channelIds.join()}`
                  )
                : actionUri.replace(
                    `filter_channelIds=${filterChannelIds.join()}`,
                    "filter_channelIds=noSubscribedChannelsAvailable" // passing incorrect channel id param for those live swimlanes, which do not have any subscribed channels
                  );
          } else if (isKidsProfile && subscribedChannelIds?.length) {
            actionUri = addQueryParamsToString(
              actionUri,
              {
                filter_channelIds: subscribedChannelIds.join(),
              },
              actionUri.includes("?")
            );
          }
        }

        if (appProvider?.channelMapID && actionUri.includes("/TRAY/SEARCH")) {
          actionUri = addQueryParamsToString(
            actionUri,
            {
              filter_regionId: appProvider.channelMapID,
            },
            actionUri.includes("?")
          );
        }

        const pcLevel = getPcLevelRestriction(addParams?.userProfile, isUserProfilesEnabled, isParentalPINEnabled);
        if (pcLevel) {
          const queryParam = { filter_pcLevel: pcLevel };
          getKidExclusionRating(addParams.userProfile, queryParam);
          actionUri = addQueryParamsToString(actionUri, queryParam, actionUri.includes("?"));
        }
        if (favChannelIds?.length > 0) {
          const uri = actionUri.replace(actionUri, "/TRAY/SEARCH/PROGRAM?filter_airingTime=now");
          actionUri = addQueryParamsToString(
            uri,
            {
              filter_regionId: appProvider.channelMapID,
              filter_channelIds: favChannelIds,
            },
            actionUri.includes("?")
          );
        }
        retrieveAvsItems(appProvider, actionUri, true)
          .then((response) => {
            if (response?.data?.resultCode === "OK" && response.data.resultObj) {
              let filteredItems = response.data.resultObj.containers?.filter((container) => {
                // Filter out items that are missing metadata
                const hasMetadata = Object.keys(container?.metadata)?.length > 0;
                // If the containers contain live items and user is not a guest (they have a subscribed channel ID list), we need to filter out the items airing on unsubscribed channels
                const isEntitledToItem =
                  !isLiveItem ||
                  !subscribedChannelIds?.length > 0 ||
                  subscribedChannelIds.includes(String(container.channel.channelId));

                // Manually filter out recording items from the bookmarks swimlane if the recording feature is disabled
                let keepBookmarkedItem = true;
                if (actionUri.includes("BOOKMARKS")) {
                  if (hasMetadata && isItemTypeRecording(container.metadata)) {
                    keepBookmarkedItem = isRecordingsEnabled;
                  }
                }

                return hasMetadata && isEntitledToItem && keepBookmarkedItem;
              });
              const totalItems = response.data.resultObj.total;
              const filteredItemsCount = filteredItems?.length;

              if (filteredItems) {
                if (isLiveItem) {
                  filteredItems = getProgramInAscendingOrder(filteredItems, appProvider);
                } else {
                  // Check the URI whitelist in the config to determine if data order should be reversed
                  // Commenting this block for now as on 28/09/23 we don't have anything for "data_order_reversal_uri_whitelist"
                  // for (let i = 0; i < appProvider.config.web.data_order_reversal_uri_whitelist.length; i++) {
                  //   if (actionUri.includes(appProvider.config.web.data_order_reversal_uri_whitelist[i])) {
                  //     filteredItems.reverse();
                  //     break;
                  //   }
                  // }
                  if (filteredItemsCount > MAX_SWIMLANE_ITEMS) {
                    filteredItems = filteredItems.slice(0, MAX_SWIMLANE_ITEMS);
                  }
                }
              }

              if (isMounted) {
                if (dataFilter !== null) {
                  setData(
                    dataFilter({
                      data: filteredItems ?? [],
                      avsComponent,
                      addParams,
                      itemsCount: totalItems ?? filteredItemsCount ?? 0,
                      isAppLanguageFrench,
                      userProfile: addParams.userProfile,
                      currentPrograms,
                    })
                  );
                } else {
                  setData(filteredItems ?? []);
                }
              }
            } else if (isPanicMode(response)) {
              // Currently in panic mode return nothing back
              if (isMounted) {
                setData(null);
              }
            }
          })
          .catch(() => {
            if (isMounted) {
              setData(null);
            }
          });
      }

      return () => (isMounted = false);
    }, [isAppLanguageFrench, currentPrograms, isUserProfilesEnabled, isParentalPINEnabled, isRecordingsEnabled]);
    return data ? <Component data={data} {...props} getSwimlaneRowNum={updateAvailableSwimlanes(data)} /> : null;
  };
}

export default componentRegistry;
