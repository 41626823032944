export default Object.freeze({
  PARENTAL_RATINGS: {
    UNRATED: {
      title_key: "parental_unrated",
      desc_data: [{ rating: "parental_unrated_ratings", desc: "" }],
      rating: "parental_unrated_ratings",
      value: 99,
    },
    ADULT_XXX: {
      title_key: "parental_adult_xxx",
      desc_data: [{ rating: "parental_adult_xxx_ratings", desc: "parental_adult_xxx_desc" }],
      rating: "parental_adult_xxx_ratings",
      value: 80,
    },
    RESTRICTED_ADULT: {
      title_key: "parental_restricted_adult",
      desc_data: [
        { rating: "parental_movie_r", desc: "parental_movie_r_desc" },
        { rating: "parental_tvshow_a18", desc: "parental_tvshow_a18_desc" },
      ],
      rating: "parental_restricted_adult_ratings",
      value: 60,
    },
    INTENDED_OLDER_AUDIENCE: {
      title_key: "parental_intended_for_older_audience",
      desc_data: [
        { rating: "parental_movie_a18", desc: "parental_movie_a18_desc" },
        { rating: "parental_rating_16+", desc: "parental_rating_16+_desc" },
      ],
      rating: "parental_intended_for_older_audience_ratings",
      value: 50,
    },
    NOT_RECOMMENDED_YOUNG_AUDIENCE: {
      title_key: "parental_not_recommended_for_younger_audience",
      desc_data: [
        { rating: "parental_tvshow_a14", desc: "parental_tvshow_a14_desc" },
        { rating: "parental_movie_a14", desc: "parental_movie_a14_desc" },
        { rating: "parental_rating_13+", desc: "parental_rating_13+_desc" },
      ],
      rating: "parental_not_recommended_for_younger_audience_ratings",
      value: 35,
    },
    NOT_SUITABLE_YOUNG_CHILDREN: {
      title_key: "parental_not_suitable_for_young_children",
      desc_data: [
        { rating: "parental_rating_8+", desc: "parental_rating_8+_desc" },
        { rating: "parental_tvshow_c8", desc: "parental_tvshow_c8_desc" },
        { rating: "parental_movie_pg", desc: "parental_movie_pg_desc" },
      ],
      rating: "parental_not_recommended_for_young_children_raitings",
      value: 20,
    },
    ALL_AGES: {
      title_key: "parental_all_ages",
      desc_data: [{ rating: "parental_tvshow_g", desc: "parental_tvshow_g_desc" }],
      rating: "parental_all_ages_ratings",
      value: 15,
    },
    CHILDREN: {
      title_key: "parental_rating_children",
      desc_data: [{ rating: "parental_tvshow_c", desc: "parental_tvshow_c_desc" }],
      rating: "parental_tvshow_c",
      value: 10,
    },
    EXEMPT: {
      title_key: "exempt",
      desc_data: [{ rating: "parental_tvshow_e", desc: "parental_tvshow_e_desc" }],
      rating: "parental_tvshow_e",
      value: 0,
    },
  },
  PLAYER_PIN_REFRESH_INTERVAL_MS: 14400000, // 4 hours
  KIDS_PARENTAL_CONTROL_LEVEL: 35,
});
