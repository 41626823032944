import React from "react";
import { useDispatch } from "react-redux";

import FilterDropdown from "./FilterDropdown";

const Index = (props) => {
  const dispatch = useDispatch();

  return <FilterDropdown dispatch={dispatch} {...props} />;
};

export default Index;
