import { setLocalStorage } from "./localStorage";
import { getSignInUrl } from "../middleware/login";
import { getQueryParamsByName } from "./";
import { trackWebAction } from "../analytics/dataLayer";
import constants from "../constants";
import { ANALYTICS_EVENT_TYPES } from "../constants/analytics";
import storageConstants from "../constants/storage";
import { trackConvivaCustomEvent } from "../analytics/media";
import { setSessionStorage } from "./sessionStorage";
const { DEVICE_ID, LOGIN_REDIRECT_URL } = storageConstants;
const { LOGIN_BRANDS } = constants;

/**
 * Sets the login redirect url in local storage
 * Redirects user to telus identity login
 */
export function handleLogin(appProvider, loginBrand = LOGIN_BRANDS.TELUS, KoodoEnabled = true) {
  const signInUrl = getSignInUrl(appProvider, loginBrand, KoodoEnabled);
  trackWebAction(ANALYTICS_EVENT_TYPES.LOGIN_START, signInUrl);

  trackConvivaCustomEvent(ANALYTICS_EVENT_TYPES.LOGIN_START, { loginStart: true });
  const deviceId = getQueryParamsByName("state", signInUrl);
  if (deviceId) {
    setLocalStorage(DEVICE_ID, deviceId);
  }
  if (window.location.hash && window.location.hash !== "#/")
    setSessionStorage(LOGIN_REDIRECT_URL, window.location.href);
  window.location.href = signInUrl;
}
