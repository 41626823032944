import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SeasonPickerDropDown from "../SeasonPickerDropdown";
import VodSidebarListItem from "../VodSideBarListItem";
import { useTheme } from "styled-components";
import ImageButton from "../ImageButton";
import Button from "../Button";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import constants from "../../shared/constants";
import { checkUnifiedAsset } from "../../shared/utils";
import { getEpisodeDetail } from "../../shared/utils/swimlane";
import { getSortedSeasons } from "../../shared/utils/feedHelper";
import { showModalPopup } from "../../App/state/actions";
import {
  PlayerSidebarWrapper,
  PlayerSidebarHideButtonWrapper,
  PlayerSidebarRoute,
  PlayerSidebarAnchor,
} from "../../pages/PlayerPage/PlayerPage.styles";
import {
  VodPlayerSidebarDetails,
  VodPlayerSidebarEpisodeName,
  VodPlayerSidebarEpisodeDetails,
  VodPlayerSidebarEpisodeDesc,
  VodPlayerSidebarList,
  VodPlayerSortFilters,
} from "./VodPlayerSideBar.styles";

const { ACTION_KEYS, MODAL_TYPES, PAGE_CONTENT_ITEM_TYPES } = constants;
const VIEW_ALL_ICON = process.env.PUBLIC_URL + "/images/white-chevron.svg";

// TODO: VodPlayerSideBar and MovieSideBar components are very similar in the way they are rendered
// Hence we will create a spike ticket in the future to use just one component for both(17/05/24).

const VodPlayerSideBar = ({
  onSeasonChange,
  onEpisodeItemClick,
  currentEpisodeId,
  currentProgram,
  handleHideButtonClick,
  currentSeason,
  vodProgress,
  recordingsToDisplay,
  isRecordingPlayer,
  dispatch,
  feedContent,
  episodeContent,
  seriesDetails,
}) => {
  const { t: translate } = useTranslation();
  const theme = useTheme();
  const screenWidth = window.screen.availWidth;
  const divElRef = useRef(null);
  const divFirstElRef = useRef(null);
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [isRunning, setIsRunning] = useState(true);
  const episodesToDisplay = isRecordingPlayer ? recordingsToDisplay : episodeContent;

  const getCurrentSeasonIndex = () => {
    let index = 0;
    if (feedContent?.length > 0 && currentSeason?.id) {
      const sortedFeedContent = getSortedSeasons(seriesDetails, feedContent);
      index = sortedFeedContent.findIndex((content) => content.metadata?.season === currentSeason.metadata?.season);
    }
    return index;
  };

  useEffect(() => {
    if (episodeContent) {
      const playingEpisode =
        episodeContent.length > 0 &&
        episodeContent.find((episode) => {
          return episode.id === currentProgram?.metadata?.extendedMetadata?.dlum?.uaId;
        });
      if (playingEpisode) {
        setCurrentEpisode(playingEpisode);
      } else setCurrentEpisode(currentProgram);
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgram]);

  useEffect(() => {
    setTimeout(() => {
      if (divElRef && divElRef.current) {
        divElRef.current.scrollIntoView(true);
      }
    });
  }, [currentEpisodeId]);

  function onSeasonSelected(item) {
    if (item) {
      if (divFirstElRef && divFirstElRef.current) {
        divFirstElRef.current.scrollIntoView(true);
        divElRef.current = null;
      }
      onSeasonChange(item);
    }
  }

  const getDetailPageRoute = () => {
    let actionUri;
    if (checkUnifiedAsset(currentEpisode?.metadata)) {
      actionUri = currentEpisode.actions?.find((action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK)?.uri;
    } else {
      if (episodesToDisplay) {
        const unifiedEpisode = episodeContent?.find(
          (episode) => episode.id === currentEpisode?.metadata?.extendedMetadata?.dlum?.uaId
        );
        if (unifiedEpisode) {
          actionUri = unifiedEpisode?.actions?.find(
            (action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK
          )?.uri;
        } else {
          const unifiedAssetId = currentEpisode?.metadata?.extendedMetadata?.dlum?.uaId;
          if (unifiedAssetId) {
            actionUri = `/PAGE/DETAILS/${PAGE_CONTENT_ITEM_TYPES.movie}/${unifiedAssetId}`;
          }
        }
      }
    }
    if (actionUri) {
      return `/detail/episode${actionUri}`;
    } else {
      return `/detail/episode/PAGE/DETAILS/${currentProgram?.metadata?.contentType}/${currentEpisodeId}`;
    }
  };
  const onHoverIn = (program) => {
    setCurrentEpisode(program);
    setIsRunning(false);
  };
  const onHoverOut = () => {
    setCurrentEpisode(currentProgram);
    setIsRunning(true);
  };
  const trackDetailsNavigation = () => {
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.VIEW_DETAILS};${LINK_INFO.EPISODE_SIDEBAR}`);
  };

  return (
    <PlayerSidebarWrapper theme={theme} id="sidebar">
      <PlayerSidebarHideButtonWrapper>
        <Button
          buttonStyles="list-button"
          label={translate("hide")}
          onClickHandler={handleHideButtonClick}
          tabIndex="-1"
        />
      </PlayerSidebarHideButtonWrapper>
      {currentEpisode && currentEpisode.metadata ? (
        <VodPlayerSidebarDetails width={screenWidth}>
          {currentEpisode.metadata.extendedMetadata && currentEpisode.metadata.extendedMetadata.dlum && (
            <VodPlayerSidebarEpisodeName theme={theme} width={screenWidth}>
              {currentEpisode.metadata.extendedMetadata.dlum.sortTitle}
            </VodPlayerSidebarEpisodeName>
          )}
          <VodPlayerSidebarEpisodeDetails theme={theme} width={screenWidth}>
            {(currentEpisode.metadata.season ||
              currentEpisode.metadata.episodeNumber ||
              currentEpisode.metadata.title) && <span>{getEpisodeDetail(currentEpisode.metadata)}</span>}
          </VodPlayerSidebarEpisodeDetails>
          {currentEpisode.metadata.longDescription && (
            <VodPlayerSidebarEpisodeDesc theme={theme} width={screenWidth}>
              {currentEpisode.metadata.longDescription}
            </VodPlayerSidebarEpisodeDesc>
          )}
          {isRunning &&
            (checkUnifiedAsset(currentEpisode.metadata.extendedMetadata?.dlum) ? (
              <PlayerSidebarRoute to={getDetailPageRoute()} tabIndex="-1" onClick={trackDetailsNavigation}>
                <span>{translate("details")}</span>
                <ImageButton src={VIEW_ALL_ICON} alt="viewAll" tabIndex="-1" />
              </PlayerSidebarRoute>
            ) : (
              <PlayerSidebarRoute>
                <PlayerSidebarAnchor
                  href="#"
                  tabIndex="-1"
                  onClick={(event) => {
                    event.preventDefault();
                    dispatch(showModalPopup(MODAL_TYPES.ERROR, { message: "error_non_unified", isCloseable: true })); // showing error for non-UA assets
                  }}
                >
                  <span>{translate("details")}</span>
                  <ImageButton src={VIEW_ALL_ICON} alt="viewAll" tabIndex="-1" />
                </PlayerSidebarAnchor>
              </PlayerSidebarRoute>
            ))}
        </VodPlayerSidebarDetails>
      ) : null}
      <VodPlayerSortFilters theme={theme} width={screenWidth}>
        {isRecordingPlayer || feedContent ? (
          <SeasonPickerDropDown
            onSeasonDropDownSelected={onSeasonSelected}
            currentSeasonIndex={isRecordingPlayer ? 0 : getCurrentSeasonIndex()}
            isRecordingPlayer={isRecordingPlayer}
          />
        ) : null}
      </VodPlayerSortFilters>
      <VodPlayerSidebarList>
        {episodesToDisplay &&
          episodesToDisplay.length > 0 &&
          episodesToDisplay.map((program, index) => {
            return (
              <VodSidebarListItem
                key={program?.id}
                divFirstElRef={divFirstElRef}
                program={program}
                onEpisodeItemClick={onEpisodeItemClick}
                index={index}
                divElRef={divElRef}
                currentEpisodeId={currentEpisodeId}
                onHoverIn={onHoverIn}
                onHoverOut={onHoverOut}
                vodProgress={vodProgress}
                isRecordingPlayer={isRecordingPlayer}
              />
            );
          })}
      </VodPlayerSidebarList>
    </PlayerSidebarWrapper>
  );
};

export default VodPlayerSideBar;
