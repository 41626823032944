import request from "../../service/request";
import constants from "../constants";
import { getResponseObject } from "../utils";
const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

/**
 * Returns the content specific user related information, such as entitlements. The assets the user has access to. The user's rights to the content
 * @param {Object} appProvider App Provider Information
 * @param {String} contentId Content ID of the given object
 * @param {String} contentType Content Type ( VOD|Live|Program )
 * @param {Object} cancelTokenSource CancelToken - deprecated in v0.22.0, will have to update to AbortController if we update Axios in the future
 * @param {String} programId Program id of the live program to fetch the start over stream manifest
 */
function getContentUserData(appProvider, contentId, contentType, cancelTokenSource = null, programId) {
  let url, withCredentials;
  if (appProvider && contentId) {
    withCredentials = appProvider.userCluster === "R";
    const AVS_API_LIST = appProvider.config?.avs_api_urls;

    if (AVS_API_LIST) {
      url = AVS_API_LIST.content_user_data;

      url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
      url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
      url = url.replace("$USER_CLUSTER", appProvider.userCluster);
      url = url.replace("$LANG", appProvider.AVS_LANG);
      url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
      url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
      url = url.replace("$ID", contentId);
      url = url.replace("$ITEM_TYPE", contentType);

      if (programId) {
        url = url + `?programId=${programId}`;
      }

      if (ENV === APP_ENVIRONMENTS.DEV) {
        url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
      }
    }

    return request(
      { url, method: "GET", withCredentials, returnResponseObject: true },
      cancelTokenSource,
      appProvider,
      false
    )
      .then((response) => ({ ...response.data.resultObj, errorDescription: response.data.errorDescription }))
      .catch((error) => {
        return Promise.reject(getResponseObject(error));
      });
  } else {
    return Promise.reject({});
  }
}

export default getContentUserData;
