import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Redirect, useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SeoPageTags from "../../components/SeoPageTags";
import {
  loadChannels,
  loadUserSubscribedChannels,
  toggleChannelSidePanelAction,
  toggleSpinningLoaderAction,
  showRecordingSettingsPanel,
  showModalPopup,
  resetAction,
  showToastNotification,
} from "../../App/state/actions";
import {
  getRecordingAction,
  SET_RECORDINGS,
  GET_RECORDINGS,
  EDIT_RECORDINGS,
  DELETE_RECORDINGS,
  MANIPULATE_RECORDING_ACTION_TRIGGERED,
  toggleSettingsPanelAction,
  setRecordingAction,
} from "../../pages/RecordingsPage/state/actions";
import { getPageComponents, PAGE_COMPONENTS } from "./state/actions";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import WelcomeModal from "../../components/WelcomePopup";
import { getLocalStorage } from "../../shared/utils/localStorage";
import { trackGenericAction, trackRecordingError } from "../../shared/analytics/dataLayer";
import { mapRecordingSeriesMediaContent } from "../../shared/analytics/helpers";
import { getRegionalChannelNumber } from "../../shared/utils/epg";
import { logNREvent } from "../../shared/analytics/newRelic";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import {
  getRecordingSystemType,
  isRecordingPendingStateCheck,
  checkCPVRConflicts,
  showCPVRConflictModal,
  navigateToPVRManager,
  getRecordingInfo,
  getCPVRRecordingStatus,
  isCPVRRecordingInProgress,
  setRecording,
  doesCPVREventRecordingExist,
} from "../../shared/utils/recordingHelper";
import {
  EXTRA_METADATA_TYPES,
  ANALYTICS_EVENT_TYPES,
  MAPPED_CONTENT_TYPES,
  LINK_INFO,
  ANALYTICS_STORAGE_KEYS,
} from "../../shared/constants/analytics";
import constants from "../../shared/constants/index";
import storageConstants from "../../shared/constants/storage";
import recordingConstants from "../../shared/constants/recordingConstants";
import errors from "../../shared/constants/error";
import { isGhostChannel } from "../../shared/utils/epg";
import ChannelSidePanel from "../../components/ChannelSidePanel";
import Feed, { canCreateFeed } from "../../components/Feed";
import { isFavouriteChannelsConfig } from "../../shared/utils/swimlane";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import RecordingModal from "../../components/RecordingModal";
import useGuidePrograms, { getSegmentTimestamp } from "../../shared/hooks/useGuidePrograms";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import "./style.scss";

const { HAS_SEEN_ONBOARDING_TUTORIAL } = storageConstants;
const { RECORDING_PARAMS, RECORDING_PACKAGES, MR_RECORDING_FILTER_OPTIONS } = recordingConstants;
const { MODAL_TYPES } = constants;
const { AVS_ERROR_CODES } = errors;

const DEFAULT_HUB_INDEX = 0;

/**
 * Feed page component
 *
 * @component
 * @param {Object} props
 */
function FeedPage(props) {
  const {
    hubs,
    appProvider,
    loadChannels,
    channelMapInfo,
    userProfile,
    getPageComponents,
    pageComponents,
    loadUserSubscribedChannels,
    subscribedChannels,
    toggleChannelSidePanelAction,
    displayChannelSidePanel,
    currentPrograms,
    featureToggles,
    toggleSpinningLoaderAction,
    showRecordingSettingsPanel,
    toggleSettingsPanelAction,
    setRecordingAction,
    showModalPopup,
    manipulatedRecordingParams,
    recordingsList,
    getRecordingAction,
    resetAction,
    setRecordingResponse,
    showToastNotification,
    getRecordingError,
    editRecordingResponse,
    deleteRecordingResponse,
  } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const { isUserProfilesEnabled, isFavouritesEnabled, isCloudPVREnabled } = featureToggles;

  const [activeHubIndex, setActiveHubIndex] = useState(-1);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [favouriteChannelIds, setFavouriteChannelIds] = useState(null);
  const [showOnboardingTutorial, setShowOnboardingTutorial] = useState(!getLocalStorage(HAS_SEEN_ONBOARDING_TUTORIAL));
  const [selectedAssetRecordingInfo, setSelectedAssetRecordingInfo] = useState(null);
  const [showRecordingModal, setShowRecordingModal] = useState(false);
  const [recordingActionError, setRecordingActionError] = useState(null);
  const availableSwimlanes = [];
  const isKidsProfile = isUserProfilesEnabled && userProfile?.user?.profile?.profileData?.kidsProfile;
  const refreshFeedRef = useRef({});
  const recordingEventCallbackFuncRef = useRef(null);
  const recordingEventTypeRef = useRef(null);
  const recordingsListRefreshed = useRef(false);
  const isRecordingActionInProgress = useRef(false);
  const prevFeedPageUri = useRef(null);
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const { loadGuidePrograms } = useGuidePrograms();
  const { trackPageView, resetIsPageViewTracked } = useTrackPageView();
  const [productImpressions, setProductImpressions] = useState(null);

  const { t: translate } = useTranslation();

  const subscribedChannelIds = useMemo(() => {
    if (subscribedChannels?.containers?.length) {
      return subscribedChannels.containers.filter((channel) => !isGhostChannel(channel)).map((channel) => channel.id);
    }
    return null;
  }, [subscribedChannels]);

  useEffect(() => {
    return () => resetAction(PAGE_COMPONENTS, "pageComponents");
  }, [resetAction]);

  useEffect(() => {
    const getHubIndexById = (hubId) => {
      if (!hubId) {
        return DEFAULT_HUB_INDEX;
      }
      return hubs.findIndex((hubItem) => hubItem.actions[0].uri.includes(hubId));
    };

    if (hubs?.length) {
      setActiveHubIndex(getHubIndexById(match?.params?.id));
    }
  }, [match, hubs]);

  useEffect(() => {
    if (appProvider && !channelMapInfo) {
      loadChannels(appProvider, cancelTokenSource);
    }
  }, [appProvider, channelMapInfo, loadChannels, cancelTokenSource]);

  useEffect(() => {
    if (appProvider && userProfile?.isLoggedIn && !subscribedChannels) {
      loadUserSubscribedChannels(appProvider, userProfile, cancelTokenSource, isUserProfilesEnabled);
    }
  }, [
    appProvider,
    loadUserSubscribedChannels,
    subscribedChannels,
    userProfile,
    cancelTokenSource,
    isUserProfilesEnabled,
  ]);

  useEffect(() => {
    // Preload guide programs
    if (!currentPrograms[getSegmentTimestamp()]) {
      loadGuidePrograms(getSegmentTimestamp());
    }
  }, [loadGuidePrograms, currentPrograms]);

  useEffect(() => {
    const activeHub = hubs?.[activeHubIndex];
    if (isKidsProfile && activeHub?.metadata) {
      const {
        dayBackground = null,
        dayBackgroundStartTimer = 7,
        nightBackground = null,
        nightBackgroundStartTimer = 18,
      } = activeHub.metadata;

      const currentHour = new Date().getHours();
      const isDaytime =
        dayBackgroundStartTimer < nightBackgroundStartTimer
          ? currentHour >= dayBackgroundStartTimer && currentHour < nightBackgroundStartTimer
          : currentHour >= dayBackgroundStartTimer || currentHour < nightBackgroundStartTimer;

      setBackgroundImage(isDaytime ? dayBackground : nightBackground);
    }
  }, [activeHubIndex, hubs, isKidsProfile]);

  // fetch page components for valid page id
  useEffect(() => {
    const feedPageUri =
      activeHubIndex !== -1
        ? hubs?.[activeHubIndex]?.actions?.[0]?.uri
        : match?.params?.id
        ? `/PAGE/${match.params.id}`
        : null;
    if (appProvider && feedPageUri && feedPageUri !== prevFeedPageUri.current) {
      prevFeedPageUri.current = feedPageUri;
      getPageComponents(appProvider, feedPageUri, cancelTokenSource);
    }
  }, [activeHubIndex, appProvider, getPageComponents, hubs, cancelTokenSource, match]);

  // show page not found modal for invalid page id
  useEffect(() => {
    if (pageComponents?.length === 0) {
      showModalPopup(MODAL_TYPES.ERROR, {
        title: translate("404_title"),
        message: translate("404_body"),
        isCloseable: false,
        buttons: [
          {
            label: translate("go_to_home"),
            onClickHandler: () => history.push("/"),
          },
        ],
        endButtonLabelOverride: "",
      });
    }
  }, [pageComponents, showModalPopup, history, translate]);

  useEffect(() => {
    // Reset page view tracking when a different hub is selected
    if (activeHubIndex > -1) {
      resetIsPageViewTracked();
      setProductImpressions(null);
    }
  }, [activeHubIndex, resetIsPageViewTracked]);

  useEffect(() => {
    if (productImpressions) {
      trackPageView({
        hubs,
        isInternalCampaign: true,
        extraMetadataList: [
          {
            type: EXTRA_METADATA_TYPES.PRODUCTS_IMPRESSION_VIEW,
            asset: productImpressions,
          },
        ],
      });
    }
  }, [hubs, trackPageView, productImpressions]);

  /**
   * Update available swimlanes only when data is valid
   * @param {Object} data - swimlane
   * @returns {Function} a callback function that returns the accurate row number of current swimlane on the screen,
   *                     this function will be triggered when a swimlane item has been clicked
   */
  const updateAvailableSwimlanes = (swimlane = {}) => {
    const getSwimlaneRowNum = (swimlaneTitle) => {
      const defaultSwimlaneTitles = pageComponents.map((pageComponent) => pageComponent?.title);
      const swimlanesInOrder = defaultSwimlaneTitles.filter((title) => availableSwimlanes.includes(title));

      return swimlanesInOrder.indexOf(swimlaneTitle);
    };

    if (swimlane?.title && swimlane?.items?.length && !availableSwimlanes.includes(swimlane?.title)) {
      availableSwimlanes.push(swimlane?.title);
    }
    return (swimlaneTitle) => getSwimlaneRowNum(swimlaneTitle);
  };

  const updatePopUp = () => {
    setShowOnboardingTutorial(false);
  };
  const closeRecordingModal = () => {
    setShowRecordingModal(false);
  };
  const recordingSystemType = useMemo(
    () => (userProfile?.isLoggedIn ? getRecordingSystemType(userProfile) : null),
    [userProfile]
  );

  /** isMR - type: boolean - check if a login user has Media Room recording package, this constant is crucial for recording functions*/
  const isMR = recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;

  /**
   * This useEffect is used to keep track of recordings list refreshes
   */
  useEffect(() => {
    recordingsListRefreshed.current = recordingsList && isRecordingActionInProgress.current;
  }, [recordingsList]);

  /**
   * Fetch the user's recordings and save the response in redux
   * @param {Object} recordingParamsMR - MR specific recording params used for set/edit/delete recording when initial attempt reports PENDING status
   * @param {Boolean} fetchingAfterAction - flag used to differentiate between initial fetch and post-action fetches
   */
  const fetchRecordings = useCallback(
    (recordingParamsMR = null, fetchingAfterAction = false) => {
      isRecordingActionInProgress.current = fetchingAfterAction;
      if (isMR) {
        getRecordingAction(
          appProvider,
          true,
          null,
          null,
          null,
          MR_RECORDING_FILTER_OPTIONS.SCHEDULED_PAGE_FILTERS,
          recordingParamsMR
        );
      } else {
        getRecordingAction(appProvider, false, true, null, [RECORDING_PARAMS.EVENT, RECORDING_PARAMS.SERIES]);
      }
    },
    [appProvider, getRecordingAction, isMR]
  );

  // Reset the recordings params associated with an outgoing recording manipulation action (set, edit, delete)
  const resetManipulatedRecordingParams = useCallback(() => {
    resetAction(MANIPULATE_RECORDING_ACTION_TRIGGERED, "recordingParams");
  }, [resetAction]);

  // Reset the response data from setting a recording
  const resetSetRecordingResponse = useCallback(() => {
    resetAction(SET_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response error from fetching recordings
  const resetGetRecordingError = useCallback(() => {
    resetAction(GET_RECORDINGS, "error");
  }, [resetAction]);

  // Reset the response data from editing a recording
  const resetEditRecordingResponse = useCallback(() => {
    resetAction(EDIT_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response data from deleting a recording
  const resetDeleteRecordingResponse = useCallback(() => {
    resetAction(DELETE_RECORDINGS, "content");
  }, [resetAction]);

  /**
   * Resets the values used to support recording CRUD action flows
   */
  const resetRecordingActionValues = useCallback(() => {
    isRecordingActionInProgress.current = false;
    resetSetRecordingResponse();
    resetEditRecordingResponse();
    resetDeleteRecordingResponse();
    resetManipulatedRecordingParams();
    resetGetRecordingError();
    setRecordingActionError(null);
  }, [
    resetDeleteRecordingResponse,
    resetEditRecordingResponse,
    resetGetRecordingError,
    resetManipulatedRecordingParams,
    resetSetRecordingResponse,
  ]);

  /**
   * Determines the appropriate recording toast message to display
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {String} actionType - type of recording action that occurred (set/edit/delete)
   * @returns {String} recordings-specific toast message
   */
  const getRecordingToastMessage = useCallback(
    (recordingInfo, actionType, recordingType) => {
      let toastMsg;
      if (recordingInfo.code) {
        if (actionType === "set") {
          toastMsg = translate(RECORDING_PARAMS.RECORDING_NOT_SET);
        } else if (actionType === "edit") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingInfo.code === AVS_ERROR_CODES.SERIES_METADATA_NOT_FOUND_ON_NPVR
                ? "SERIES_RECORDING_CANNOT_BE_UPDATED_FIRST_RUN"
                : "RECORDING_NOT_UPDATED"
            ]
          );
        } else if (actionType === "delete") {
          toastMsg = translate(RECORDING_PARAMS.COULD_NOT_CANCEL_RECORDING);
        }
      } else {
        if (actionType === "set") {
          toastMsg = translate(
            RECORDING_PARAMS[recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_SET" : "RECORDING_SET"]
          );
        } else if (actionType === "edit") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_UPDATED" : "RECORDING_UPDATED"
            ]
          );
        } else if (actionType === "delete") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_CANCELLED" : "RECORDING_CANCELLED"
            ]
          );
        }
      }

      return toastMsg;
    },
    [translate]
  );

  /**
   * Triggers a recording analytics event based on a toast message passed
   * @param {String} toastMsg
   */
  const triggerRecordingAnalyticsEvent = useCallback(
    (toastMsg) => {
      if (recordingEventTypeRef.current) {
        const isSeriesEvent = toastMsg === translate(RECORDING_PARAMS.SERIES_RECORDING_SET);
        if (isSeriesEvent || toastMsg === translate(RECORDING_PARAMS.RECORDING_SET)) {
          const selectedAssetSchedule = {
            ...selectedAssetRecordingInfo?.assetToRecord,
            channelNumber: getRegionalChannelNumber(
              selectedAssetRecordingInfo?.assetToRecord.channel,
              appProvider?.channelMapID
            ),
          };
          trackGenericAction(recordingEventTypeRef.current, {
            isSeriesEvent,
            isItemLive: true,
            ...(isSeriesEvent ? mapRecordingSeriesMediaContent(selectedAssetSchedule) : selectedAssetSchedule),
          });
          logNREvent(NR_PAGE_ACTIONS.RECORDING_START);
        } else if (
          toastMsg === translate(RECORDING_PARAMS.RECORDING_UPDATED) ||
          toastMsg === translate(RECORDING_PARAMS.SERIES_RECORDING_UPDATED)
        ) {
          recordingEventCallbackFuncRef.current();
          logNREvent(NR_PAGE_ACTIONS.RECORDING_EDIT);
        } else {
          trackRecordingError(recordingEventTypeRef.current, toastMsg, {
            errorCode: setRecordingResponse?.code,
            errorMessage: setRecordingResponse?.message,
          });
        }
      }
    },
    [setRecordingResponse, selectedAssetRecordingInfo, translate, appProvider]
  );

  /**
   * Displays the appropriate recording toast message (if any)
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {Object} manipulatedRecordingParams - refer to manipulateActionTriggered() in RecordingsPage/state/actions.js
   */
  const displayRecordingToast = useCallback(
    (recordingInfo, manipulatedRecordingParams) => {
      if (recordingInfo && manipulatedRecordingParams) {
        const toastMsg = getRecordingToastMessage(
          recordingInfo,
          manipulatedRecordingParams.actionType,
          manipulatedRecordingParams.recordingType
        );
        if (toastMsg) {
          showToastNotification(toastMsg);
          triggerRecordingAnalyticsEvent(toastMsg);
          recordingEventTypeRef.current = null;
        }
      }
    },
    [getRecordingToastMessage, showToastNotification, triggerRecordingAnalyticsEvent]
  );

  /**
   * This useEffect is used to deal with errors that occur during recording CRUD actions
   */
  useEffect(() => {
    if ((getRecordingError || recordingActionError) && manipulatedRecordingParams) {
      displayRecordingToast(getRecordingError || recordingActionError, manipulatedRecordingParams);
      resetRecordingActionValues();
    }
  }, [
    displayRecordingToast,
    getRecordingError,
    manipulatedRecordingParams,
    recordingActionError,
    resetRecordingActionValues,
  ]);

  /**
   * This useEffect is used after we have attempted to schedule a recording
   */
  useEffect(() => {
    if (setRecordingResponse && isRecordingActionInProgress.current === false) {
      if (isMR) {
        if (setRecordingResponse.code) {
          // Error occurred
          setRecordingActionError(setRecordingResponse);
        } else {
          fetchRecordings(
            isRecordingPendingStateCheck(setRecordingResponse.content) ? setRecordingResponse.recordingParams : null,
            true
          );
        }
      } else {
        if (setRecordingResponse.code) {
          // Error occurred
          if (checkCPVRConflicts(setRecordingResponse)) {
            // show popup modal for recording conflict error
            showCPVRConflictModal(
              () => {
                navigateToPVRManager(true); // Redirect user to PVR manager scheduled page to manage the conflict
                setSessionStorage(
                  ANALYTICS_STORAGE_KEYS.LINK,
                  `${LINK_INFO.MANAGE_RECORDINGS};${LINK_INFO.RECORDING_PROMPT}`
                );
              },
              MODAL_TYPES.ERROR,
              showModalPopup
            );
            resetRecordingActionValues();
          } else {
            // show generic 'not set' toast notification for all other errors
            setRecordingActionError(setRecordingResponse);
          }
        } else {
          // No error in the response, refresh our recordings list
          fetchRecordings(null, true);
        }
      }
    }
  }, [setRecordingResponse, isMR, fetchRecordings, showModalPopup, resetRecordingActionValues]);

  /**
   * This useEffect is used after we have attempted to edit a recording
   */
  useEffect(() => {
    if (editRecordingResponse && isRecordingActionInProgress.current === false) {
      if (editRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(editRecordingResponse);
      } else {
        fetchRecordings(
          isMR && isRecordingPendingStateCheck(editRecordingResponse.content)
            ? editRecordingResponse.recordingParams
            : null,
          true
        );
      }
    }
  }, [editRecordingResponse, isMR, fetchRecordings]);

  /**
   * This useEffect is used after we have attempted to cancel a recording
   */
  useEffect(() => {
    if (deleteRecordingResponse && isRecordingActionInProgress.current === false) {
      if (deleteRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(deleteRecordingResponse);
      } else {
        // Nothing useful returned in the MR delete response, so we assume its pending
        fetchRecordings(isMR ? deleteRecordingResponse.recordingParams : null, true);
      }
    }
  }, [deleteRecordingResponse, fetchRecordings, isMR]);

  /**
   * This useEffect is used after we have re-fetched recordings following a set/edit/cancel recording action.
   */
  useEffect(() => {
    if (
      selectedAssetRecordingInfo &&
      manipulatedRecordingParams &&
      isRecordingActionInProgress.current &&
      recordingsListRefreshed.current
    ) {
      // Can't use the selectedAssetRecordingInfo as-is, need to update it with the latest recording state checks/values
      const selectedRecordingInfo = getRecordingInfo(
        isMR,
        selectedAssetRecordingInfo.assetToRecord,
        recordingsList,
        appProvider?.channelMapID
      );
      setSelectedAssetRecordingInfo(selectedRecordingInfo);

      if (
        isMR &&
        (selectedRecordingInfo.recordingSeriesConflictCheck || selectedRecordingInfo.recordingEventConflictCheck)
      ) {
        setShowRecordingModal(true);
      } else {
        displayRecordingToast(selectedRecordingInfo, manipulatedRecordingParams);
      }

      recordingsListRefreshed.current = false;
      resetRecordingActionValues();
    }
  }, [
    selectedAssetRecordingInfo,
    isMR,
    displayRecordingToast,
    manipulatedRecordingParams,
    resetRecordingActionValues,
    recordingsList,
    appProvider,
  ]);

  /**
   * Schedule a recording and save the response in redux
   *
   * @param {String} typeOfRecording - the recording type (event vs series)
   */
  const scheduleRecording = useCallback(
    (typeOfRecording) => {
      if (selectedAssetRecordingInfo) {
        recordingEventTypeRef.current = ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START;
        setRecording(typeOfRecording, appProvider, selectedAssetRecordingInfo.assetToRecord, setRecordingAction, isMR);
      }
    },
    [appProvider, isMR, selectedAssetRecordingInfo, setRecordingAction]
  );
  useEffect(() => {
    return () => {
      toggleSpinningLoaderAction(false);
    };
  }, [toggleSpinningLoaderAction]);

  useEffect(() => {
    if (manipulatedRecordingParams) {
      toggleSpinningLoaderAction(true);
    } else {
      toggleSpinningLoaderAction(false);
    }
  }, [manipulatedRecordingParams, toggleSpinningLoaderAction]);

  /**
   * A callback function which will be triggered after a recording has been updated
   * @param {Object} recordingItem
   * @param {Boolean} isItemLive
   * @param {Boolean} isSeriesEvent
   * @param {String} mappedContentType live|svod|tvod|cpvr|lpvr
   * @param {String} analyticsEventType analytics event type
   * @param {String} toolSelections a toolSelection string with format: 'key:{selection},key:{selection}...'
   * @param {Object} error an error object
   * @param {Object} recordingInfo
   */
  const trackRecordingUpdateEventCallback = useCallback(
    ({
      recordingItem,
      isItemLive,
      isSeriesEvent,
      mappedContentType,
      analyticsEventType,
      toolSelections,
      error,
      recordingInfo,
    }) => {
      recordingEventCallbackFuncRef.current = () => {
        trackGenericAction(analyticsEventType, {
          isItemLive,
          isSeriesEvent,
          toolSelections,
          mappedContentType,
          ...(isSeriesEvent
            ? mapRecordingSeriesMediaContent(recordingItem || selectedAssetRecordingInfo.assetToRecord)
            : recordingInfo?.assetToRecord || recordingItem),
        });
      };
      if (error) {
        // Triggers a stop|delete recording error event.
        // The error of start recording event comes from setRecordingResponse data which is handled via useEffect.
        trackRecordingError(analyticsEventType, error?.message, {
          errorCode: error?.code,
        });
      } else {
        if (
          analyticsEventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE ||
          analyticsEventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_COMPLETE
        ) {
          // At this moment, the result of editing a recording is unknown, but a toastMessage will show up on the screen according to the result of editing a recording.
          // Thus set the recordingEventType here, and then trigger the event based on the toastMessage later.
          recordingEventTypeRef.current = analyticsEventType;
        } else {
          // The result of stop|delete a recording is ready at this moment
          recordingEventCallbackFuncRef.current();
        }
      }
    },
    [selectedAssetRecordingInfo]
  );

  const showRecordingSettingHandler = useCallback(
    (recordingInfo, typeOfRecording) => {
      const isSeriesEvent = typeOfRecording === RECORDING_PARAMS.SERIES;
      trackRecordingUpdateEventCallback({
        isSeriesEvent,
        isItemLive: true,
        recordingItem: recordingInfo?.assetToRecord,
        mappedContentType: MAPPED_CONTENT_TYPES.LIVE,
        analyticsEventType: isSeriesEvent
          ? ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_START
          : ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_START,
      });

      !isMR && toggleSpinningLoaderAction(true, "loader-wrapper setting-spinner-loader");

      showRecordingSettingsPanel(
        typeOfRecording,
        recordingInfo,
        null,
        false,
        (recordingData) => trackRecordingUpdateEventCallback({ recordingInfo, ...recordingData }),
        !isMR
      );
      toggleSettingsPanelAction(true);
    },
    [
      trackRecordingUpdateEventCallback,
      isMR,
      toggleSpinningLoaderAction,
      showRecordingSettingsPanel,
      toggleSettingsPanelAction,
    ]
  );

  /** Opens the modal to cancel in progress episode recordings. Currently used for CPVR only.
   *
   * @param {Object} recordingInfo Recording information
   * @param {Object} programDetails Program information
   */
  const openCancelRecordingModal = useCallback(
    (recordingInfo, programDetails) => {
      const modalContent = {
        recordingInfo,
        programDetails,
        isRecordingRecorded: false,
        recordingType: RECORDING_PARAMS.EVENT,
        isMR: false,
      };
      showModalPopup(MODAL_TYPES.RECORDING, modalContent);
    },
    [showModalPopup]
  );
  /**
   * Displays the appropriate overlaying component when selecting edit/cancel from the right click menu
   *
   * @param {Object} recordingInfo - Recording info object related to the selected program
   */
  const updateRecordingHandler = useCallback(
    (recordingInfo) => {
      if (recordingInfo) {
        setSelectedAssetRecordingInfo(recordingInfo);
        if (isMR) {
          const hasEventRecording =
            recordingInfo.recordingEventScheduledCheck && !recordingInfo.recordingSeriesScheduledCheck;
          if (hasEventRecording) {
            // If the recording has been set as a single EVENT recording, don't need to show the recording modal.
            showRecordingSettingHandler(recordingInfo, RECORDING_PARAMS.EVENT);
          } else {
            setShowRecordingModal(true);
          }
        } else {
          const cpvrRecordingStatus = getCPVRRecordingStatus(recordingInfo);
          if (doesCPVREventRecordingExist(cpvrRecordingStatus)) {
            if (isCPVRRecordingInProgress(recordingInfo.eventRecordingItem)) {
              // event recording is in progress, show the event cancel modal
              openCancelRecordingModal(recordingInfo, recordingInfo.assetToRecord, false);
            } else {
              // event recording is scheduled but not in progress, show the event settings panel
              showRecordingSettingHandler(recordingInfo, RECORDING_PARAMS.EVENT);
              toggleSettingsPanelAction(true);
            }
          } else {
            /*
              If a series recording exists for the show that this episode belongs to but there is no episode recording
              for this specific episode, we do not allow the user to edit the series recording.
            */
            setShowRecordingModal(true);
          }
        }
      }
    },
    [isMR, openCancelRecordingModal, showRecordingSettingHandler, toggleSettingsPanelAction]
  );

  return isKidsProfile && activeHubIndex > DEFAULT_HUB_INDEX ? (
    <Redirect to="/" />
  ) : (
    <div
      className={`home ${isKidsProfile ? "kids" : ""}`}
      style={backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : null}
    >
      <SeoPageTags title={hubs?.[activeHubIndex]?.metadata?.label} keywords={["optik", "telus"]} />
      {pageComponents?.length > 0 &&
        pageComponents.map(
          (config) =>
            canCreateFeed(config, appProvider, userProfile, isFavouritesEnabled, isCloudPVREnabled) && (
              <Feed
                key={config.id}
                subscribedChannelIds={subscribedChannelIds}
                config={config}
                setProductImpressions={setProductImpressions}
                updateAvailableSwimlanes={updateAvailableSwimlanes}
                loadFavouriteChannelsCallback={isFavouriteChannelsConfig(config) ? setFavouriteChannelIds : undefined}
                refreshFeedRef={refreshFeedRef}
                loadGuidePrograms={loadGuidePrograms}
                setSelectedAssetRecordingInfo={setSelectedAssetRecordingInfo}
                updateRecordingHandler={updateRecordingHandler}
              />
            )
        )}
      {showOnboardingTutorial && <WelcomeModal updatePopUp={updatePopUp} />}
      {displayChannelSidePanel && (
        <ChannelSidePanel
          toggleChannelSidePanelAction={toggleChannelSidePanelAction}
          favChannelIds={favouriteChannelIds}
        />
      )}
      {showRecordingModal && selectedAssetRecordingInfo && (
        <RecordingModal
          closeModal={closeRecordingModal}
          scheduleRecordingHandler={scheduleRecording}
          recordingInfo={selectedAssetRecordingInfo}
          editRecordingHandler={(recordingInfo, typeOfRecording) => {
            showRecordingSettingHandler(recordingInfo, typeOfRecording);
          }}
          openCancelRecordingModal={() => {
            if (!isMR) openCancelRecordingModal(selectedAssetRecordingInfo, selectedAssetRecordingInfo.assetToRecord);
          }}
        />
      )}
    </div>
  );
}

FeedPage.propTypes = {
  hubs: PropTypes.array,
  appProvider: PropTypes.object,
  userProfile: PropTypes.object,
  channelMapInfo: PropTypes.object,
  loadChannels: PropTypes.func.isRequired,
  pageComponents: PropTypes.array,
  getPageComponents: PropTypes.func,
  subscribedChannels: PropTypes.object,
  loadUserSubscribedChannels: PropTypes.func,
  displayChannelSidePanel: PropTypes.bool,
  toggleChannelSidePanelAction: PropTypes.func,
  currentPrograms: PropTypes.object,
  featureToggles: PropTypes.object,
  toggleSpinningLoaderAction: PropTypes.func,
};

function mapStateToProps({ feed, topNav, app, epg, recording }) {
  return {
    hubs: topNav.hubs,
    appProvider: app.provider,
    userProfile: app.userProfile,
    channelMapInfo: app.channelMapInfo,
    pageComponents: feed.pageComponents,
    subscribedChannels: app.subscribedChannels,
    displayChannelSidePanel: app.displayChannelSidePanel,
    currentPrograms: epg.currentPrograms,
    featureToggles: app.featureToggles,
    setRecordingResponse: recording.setRecordingResponse,
    editRecordingResponse: recording.editRecordingResponse,
    deleteRecordingResponse: recording.deleteRecordingResponse,
    getRecordingError: recording.getRecordingError,
    manipulatedRecordingParams: recording.manipulatedRecordingParams,
    recordingsList: recording.recordingsList,
  };
}

const mapDispatchToProps = {
  loadChannels,
  getPageComponents,
  loadUserSubscribedChannels,
  toggleChannelSidePanelAction,
  toggleSpinningLoaderAction,
  showRecordingSettingsPanel,
  toggleSettingsPanelAction,
  setRecordingAction,
  showModalPopup,
  getRecordingAction,
  resetAction,
  showToastNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedPage);
