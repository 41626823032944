import styled from "styled-components";

const Container = styled.label(
  () => `
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
);

const InputContainer = styled.input(
  () => `
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  `
);

const CheckMark = styled.span(
  ({ theme, checked }) => `
  position: relative;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid ${theme.colours.white};
  margin-right: 14px;
  background-color: ${checked && theme.colours.checkboxChecked};
  border-radius: ${checked && "4px"};
  border: ${`1px solid ${checked ? theme.colours.checkboxChecked : theme.colours.white}`};
  font-size: ${checked && theme.typography.mediumFontSize};
  font: ${checked && theme.typography.primaryFont};
  &::after {
    left: 5px;
    top: -1px;
    width: 5px;
    height: 13px;
    border: solid ${theme.colours.black};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: ${checked ? "block" : "none"};
    content: "";
    position: absolute;
  }
`
);

const Title = styled.span(
  () => `
  line-height: 21px;
  `
);

export { Container, InputContainer, CheckMark, Title };
