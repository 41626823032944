/**
 * load core modules
 */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
/* load core modules end */

/**
 * Load styled-components.
 */
import {
  SidebarItem,
  SidebarChannelInfo,
  SidebarChannelNumber,
  SidebarItemThumbnail,
  SidebarOnNowPlay,
  SidebarThumbnailImage,
  SidebarProgressBarContainer,
  SidebarProgressBar,
  SidebarItemDescription,
  SidebarRowContent,
  SidebarNewInfo,
  SidebarItemDateTime,
  SidebarRestartIconImage,
  SidebarItemActions,
  SidebarRecordingButtonWrapper,
  SidebarRecordingLoading,
  SidebarIconHomeNetwork,
  SidebarChannel4kIcon,
} from "./SideBarListItem.styles";

/**
 * load actions
 */
import { showToastNotification } from "../../App/state/actions";
/* load actions end */

/**
 * load util functions
 */
import { convertToPercentageString } from "../../shared/utils";
import { checkIsStartOverSupported, isGhostChannel } from "../../shared/utils/epg";
import { getRecordingInfo, getRecordingCTAs } from "../../shared/utils/recordingHelper";
import { handleImageError } from "../../shared/utils/image";
/* load util functions end */

/**
 * load custom hooks
 */
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import useCurrentTime from "../../shared/hooks/useCurrentTime";
/* load custom hooks end */

/**
 * load components
 */
import ImageButton from "../ImageButton";
/* load components end */

/**
 * load assets
 */
import "./style.scss";
const CHANNEL_4K_ICON = process.env.PUBLIC_URL + "/images/4K_icon.svg";
const defaultProgramIcon = process.env.PUBLIC_URL + "/images/swimlane-landscape-324px.jpg";
const defaultChannelLogoIcon = process.env.PUBLIC_URL + "/images/default-channel-logo.png";
const restartIcon = process.env.PUBLIC_URL + "/images/Restart.svg";
/* load assets end */

/**
 * List item component of mini-epg
 *
 * @component
 * @param {*} props - Player props
 */
const SidebarListItem = ({
  channel,
  program,
  currentProgramId,
  onLiveItemClick,
  onHoverIn,
  onHoverOut,
  hubIndex,
  onProgressComplete,
  recordingButtonClickHandler,
  isMR,
  itemRowNumber,
  isPlayerControlsDisable,
  dispatch,
  appProvider,
  isInHome,
  recordingsList,
  manipulatedRecordingParams,
  isRestartLiveFeatureEnabled,
  isRecordingEnabled,
}) => {
  const theme = useTheme();
  const screenWidth = window.screen.availWidth;
  const currentTime = useCurrentTime(6000, true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isStartOverSupported, setIsStartOverSupported] = useState(false);
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  /* Memoized variable to check if the restart live feature is enabled for the loaded config. */

  useEffect(() => {
    if (program) setRemainingTime(moment(program.metadata.airingEndTime).diff(currentTime, "minutes") + 1);
  }, [currentTime, program]);

  useEffect(() => {
    if (isRestartLiveFeatureEnabled && program && channel) {
      setIsStartOverSupported(checkIsStartOverSupported(program.metadata, channel, isInHome));
    }
  }, [isRestartLiveFeatureEnabled, channel, program, isInHome]);

  useEffect(() => {
    if (remainingTime <= 0) {
      onProgressComplete(currentTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  const calculateProgressPercentage = (program) => {
    const startTime = moment(program.metadata.airingStartTime).valueOf();
    const endTime = moment(program.metadata.airingEndTime).valueOf();
    const progressPercentage = ((currentTime - startTime) / (endTime - startTime)) * 100;
    return progressPercentage;
  };

  const newBadgeIcon = isAppLanguageFrench
    ? process.env.PUBLIC_URL + "/images/new-badge-fr.svg"
    : process.env.PUBLIC_URL + "/images/new-badge.svg";

  const homeNetwork = channel?.metadata?.isNotAvailableOutOfHome;
  const isRecordable = channel?.metadata?.isRecordable;

  const recordingInfo = useMemo(() => {
    if (isRecordingEnabled && isRecordable && recordingsList && program && channel) {
      const asset = {
        channel: channel,
        metadata: program.metadata,
      };
      return getRecordingInfo(isMR, asset, recordingsList, appProvider?.channelMapId);
    }

    return null;
  }, [appProvider, isRecordingEnabled, isMR, program, channel, recordingsList, isRecordable]);

  // Used to display the loading spinner on the player recording button, as appropriate
  const showRecordingCTALoading =
    manipulatedRecordingParams &&
    recordingInfo &&
    manipulatedRecordingParams.targetAssetInfo.metadata.contentId === recordingInfo.assetToRecord.metadata.contentId;

  const recordingCTA = useMemo(() => {
    if (recordingInfo) {
      let isRecordingScheduled, isRecordingConflicted, isSeriesRecordingScheduled, isSeriesRecordingConflicted;
      if (isMR) {
        isRecordingScheduled = recordingInfo.recordingEventScheduledCheck;
        isSeriesRecordingScheduled = recordingInfo.recordingSeriesScheduledCheck;
        isRecordingConflicted = recordingInfo.recordingEventConflictCheck;
        isSeriesRecordingConflicted = recordingInfo.recordingSeriesConflictCheck;
      }

      return getRecordingCTAs(
        isMR,
        recordingInfo,
        program,
        isRecordingConflicted,
        isRecordingScheduled,
        isSeriesRecordingConflicted,
        isSeriesRecordingScheduled,
        false,
        true
      );
    }

    return null;
  }, [isMR, program, recordingInfo]);

  const getRecordingButton = useCallback(() => {
    if (recordingCTA) {
      return (
        <ImageButton
          src={recordingCTA.recordingIcon}
          className="recording-sidebar-icon"
          onClickHandler={(event) => {
            event.stopPropagation();
            recordingButtonClickHandler(recordingInfo);
          }}
          testID="recordingIcon"
          buttonContainerStyles="button-container"
          alt={recordingCTA.altHead}
          tooltipDirection="top"
          tooltipHorizontalDirection="right"
        />
      );
    }
    return null;
  }, [recordingCTA, recordingButtonClickHandler, recordingInfo]);

  return program && channel ? (
    <SidebarItem
      width={screenWidth}
      $playing={parseInt(program.id) === parseInt(currentProgramId) ? theme.colours.sharkGrayBackground : null}
      id={`live-channel-${channel.id}`}
      onClick={(event) => {
        if (isGhostChannel(channel)) {
          event.preventDefault();
          dispatch(showToastNotification(translate("recordings_restriction_telustvplus"), CHANNEL_4K_ICON));
        } else {
          onLiveItemClick(channel.id, !isInHome && homeNetwork, itemRowNumber, program.id);
        }
      }}
      onMouseEnter={() => onHoverIn(program)}
      onMouseLeave={onHoverOut}
      theme={theme}
    >
      <SidebarChannelInfo data={"channel-info"} width={screenWidth}>
        {channel.assets?.[0]?.logoSmall ? (
          <img
            alt=""
            src={channel.assets[0].logoSmall + "?w=50"}
            onError={(e) => handleImageError(e, defaultChannelLogoIcon)}
          />
        ) : (
          <img src={defaultChannelLogoIcon} alt="" />
        )}
        <SidebarChannelNumber theme={theme} width={screenWidth}>
          {channel.number}
        </SidebarChannelNumber>
      </SidebarChannelInfo>

      <SidebarItemThumbnail theme={theme} width={screenWidth}>
        <SidebarThumbnailImage
          $screen={screenWidth}
          data={"item-thumbnail"}
          src={program.metadata.pictureUrl + "?w=125"}
          alt=""
          data-testid="thumbnail"
          width="100%"
          height="100%"
          onError={(e) => handleImageError(e, defaultProgramIcon)}
        />
        {hubIndex === 0 && (
          <>
            {parseInt(program.id) !== parseInt(currentProgramId) &&
              !(!isInHome && homeNetwork) &&
              !isGhostChannel(channel) && (
                <SidebarOnNowPlay
                  $screen={screenWidth}
                  src={process.env.PUBLIC_URL + "/images/CTA-PlayCircle.svg"}
                  alt=""
                />
              )}
            <SidebarProgressBarContainer theme={theme}>
              <SidebarProgressBar
                theme={theme}
                width={screenWidth}
                style={{
                  width: convertToPercentageString(calculateProgressPercentage(program)),
                }}
              ></SidebarProgressBar>
            </SidebarProgressBarContainer>
          </>
        )}
      </SidebarItemThumbnail>
      <SidebarItemDescription data={"item-description text-overflow"} theme={theme} width={screenWidth}>
        {program.metadata.title}
        <SidebarRowContent>
          {program.metadata.IsNew && <SidebarNewInfo src={newBadgeIcon} alt="newBadgeIcon" />}
          {program.metadata &&
            (hubIndex === 1 ? (
              <SidebarItemDateTime theme={theme} width={screenWidth}>
                {moment(program.metadata.airingStartTime).format("LT") +
                  " - " +
                  moment(program.metadata.airingEndTime).format("LT")}
              </SidebarItemDateTime>
            ) : (
              remainingTime > 0 && (
                <>
                  <SidebarItemDateTime theme={theme}>{`${remainingTime} ${translate(
                    "remaining_mins"
                  )}`}</SidebarItemDateTime>
                  {isStartOverSupported && <SidebarRestartIconImage src={restartIcon} alt="restartIcon" />}
                </>
              )
            ))}
        </SidebarRowContent>
      </SidebarItemDescription>
      <SidebarItemActions data={"item-actions"}>
        {isGhostChannel(channel) ? (
          <SidebarChannel4kIcon src={CHANNEL_4K_ICON} alt="4K icon" />
        ) : (
          homeNetwork && (
            <SidebarIconHomeNetwork
              src={
                isInHome
                  ? process.env.PUBLIC_URL + "/images/In_Home.svg"
                  : process.env.PUBLIC_URL + "/images/Out_of_home.svg"
              }
              alt="homeIcon"
            />
          )
        )}
        <SidebarRecordingButtonWrapper data={"mini-epg-recording-button-wrapper"}>
          {!isPlayerControlsDisable && getRecordingButton()}
          {showRecordingCTALoading && (
            <SidebarRecordingLoading src={process.env.PUBLIC_URL + "/images/Rec_Progress_Small.svg"} alt="" />
          )}
        </SidebarRecordingButtonWrapper>
      </SidebarItemActions>
    </SidebarItem>
  ) : (
    <></>
  );
};

export default SidebarListItem;
