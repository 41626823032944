import { useTranslation } from "react-i18next";
const EN_LANG_CODE = "en";
const FR_LANG_CODE = "fr";

/**
 * Hook for consistently determining app language across the app
 */
export default function useAppLanguage() {
  const { i18n } = useTranslation();
  const resolvedLanguage = i18n.language?.startsWith(FR_LANG_CODE) ? FR_LANG_CODE : EN_LANG_CODE;

  return {
    isAppLanguageFrench: resolvedLanguage === FR_LANG_CODE,
    appLanguage: resolvedLanguage,
  };
}
