import constants from "../constants";
import errorConstants from "../../shared/constants/error";
import storageConstants from "../../shared/constants/storage";
import { getRegionalChannelNumber, checkIsLookbackSupported } from "./epg";
import { removeSessionStorage, setSessionStorage } from "./sessionStorage.js";
import moment from "moment";
import i18n from "../../i18n.js";
import styled from "styled-components";

const {
  APP_ENVIRONMENTS,
  LOOKBACK_AVAILABILITY_HOURS,
  TRANSACTION_TYPES,
  DEFAULT_RENTAL_DURATION,
  USER_PROPERTY_NAMES,
  USER_PROPERTY_TYPES,
  USER_VISIBILITY_CONTROL,
} = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;
const {
  LAST_SCROLL_POSITION,
  LAST_SELECTED_SWIMLANE_ID,
  LAST_VIEW_ALL_SCROLL_POSITION,
  LAST_VIEW_ALL_SCROLL_SWIMLANE_ID,
  CANARY,
} = storageConstants;
const { AVS_ERROR_CODES } = errorConstants;

/**
 * Converts integer to pixel string and return the same
 *
 * @param {Number} value - integer value
 * @returns {String} - Returns pixel string
 */
export const convertToPixelString = (value) => {
  return value + "px";
};

/**
 * Converts pixel string to integer
 *
 * @param {String} value - pixel string value
 * @returns {Number} - Returns integer
 */
export const convertFromPixelString = (value) => {
  return parseInt(value.slice(0, -2));
};

/**
 * Converts integer to percentage string and return the same
 *
 * @param {Number} value - integer value
 * @returns {String} - Returns percentage string
 */

export const convertToPercentageString = (value) => {
  return value + "%";
};

/**
 * Detect browser type safari
 */
export function IsSafari() {
  let isSafariBrowser;
  const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
  isSafariBrowser =
    userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i)[1] === "Safari" ? true : false;

  return isSafariBrowser;
}

/**
 * Method used to determine if browser is block from playback
 * @returns {Boolean} true/false if allow to play
 */
export function isPlatformAllowToPlay(appProvider) {
  let browserInfo = window.navigator.userAgent;
  let allow = true;

  // This check is used specifically for SIT only to allow devs to freely use FF or Mac Edge
  let videoCheckRequired = appProvider.config.general?.videoPlatformCheckRequired ?? true;

  if (browserInfo && videoCheckRequired === true) {
    if (browserInfo.indexOf("Edg") !== -1 && browserInfo.indexOf("Mac") !== -1) {
      allow = false;
    } else if (browserInfo.indexOf("Firefox") !== -1) {
      allow = false;
    }
  }

  return allow;
}

export function trapFocus(element) {
  if (element) {
    element.addEventListener("keydown", function (e) {
      const focusableEls = element.querySelectorAll(
        "a[href]:not([disabled]), button, textarea:not([disabled]), input[type='text']:not([disabled]), input[type='radio']:not([disabled]), input[type='checkbox']:not([disabled]), select:not([disabled]), *[tabindex]"
      );
      const filteredFocusableEls = Array.from(focusableEls).filter((el) => {
        // eslint-disable-next-line eqeqeq
        return el.getAttribute("tabindex") != -1;
      });
      const firstFocusableEl = filteredFocusableEls[0];
      const lastFocusableEl = filteredFocusableEls[filteredFocusableEls.length - 1];
      const KEYCODE_TAB = 9;
      var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;
      if (!isTabPressed) {
        return;
      }
      if (e.shiftKey) {
        /* shift + tab */ if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });
  }
}

/**
 * Returns the AVS Channel property value specific to each browser
 * @param Object AVS Config
 * @param String Name of the browser
 * @return String Channel Property Value
 */
export function getAVSAppChannel(avsConfig) {
  const browser = getBrowserName();
  if (avsConfig.appChannelTypes[browser]) {
    return avsConfig.appChannelTypes[browser];
  } else {
    return avsConfig.appChannelTypes.default;
  }
}

/**
 * Returns the name of the browser.
 * TODO: This method should fetch the browser name using the duck type methodology.
 * @return String Browser Name
 */
function getBrowserName() {
  const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;

  const browser = userAgent.match(/(opera|chrome|safari|firefox|msie|jsdom)\/?\s*(\.?\d+(\.\d+)*)/i)[1];

  if (browser === "Chrome" && userAgent.indexOf("Edg") !== -1) return "edge";

  return browser.toLowerCase();
}

/**
 * Returns the query params of a hashed url in key value pairs
 * @return Object Query Params
 */
export function getQueryParamsByName(name, url = window.location.href) {
  name = name.replace(/[\\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * update the query params of a url if not exist then add it
 * @return updated URL
 */
export function updateQueryStringParameter(uri, paramsObj) {
  const entriesArray = Object.entries(paramsObj);
  entriesArray.forEach((it) => {
    const [key, value] = it;
    const regex = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    const separator = uri.indexOf("?") !== -1 ? "&" : "?";
    if (uri.match(regex)) {
      uri = uri.replace(regex, "$1" + key + "=" + value + "$2");
    } else {
      uri = uri + separator + key + "=" + value;
    }
  });
  return uri;
}

/**
 * Check for text-overflow Ellipsis in an HTML Element
 * @return boolean
 */
export function isEllipsisActive(elementId) {
  let el = document.getElementById(elementId);
  if (el) {
    return el.scrollHeight - el.clientHeight > 1 || el.style.display === "inline-block";
  }
}

/**
 * Returns the programs in ascending order
 * @param object Program object
 * @return {Object} Sorted program object in Ascending Order
 */
export function getProgramInAscendingOrder(programs, appProvider) {
  return programs?.sort(function (channelA, channelB) {
    if (channelA?.channel?.regions) {
      let channelAIndex =
        channelA.channel.regions.findIndex((channel) => channel?.regionId === appProvider?.channelMapID) ?? 0;
      let channelBIndex =
        channelB?.channel?.regions?.findIndex((channel) => channel?.regionId === appProvider?.channelMapID) ?? 0;
      return (
        channelA.channel.regions[channelAIndex]?.regionalChannelNumber -
        channelB?.channel?.regions?.[channelBIndex]?.regionalChannelNumber
      );
    } else {
      return channelA;
    }
  });
}

/**
 * Returns the programs that are not PC limited
 * @param {Object} programs
 * @param {Number} parcon level
 * @return {Object} Filter program object by pcLevel
 */
export function getProgramWithinPCLevel(programs, pcLevel) {
  return pcLevel && pcLevel >= 0 ? programs.filter((channel) => channel?.metadata?.pcLevel < pcLevel) : programs;
}

/**
 * Retrieves feature properties using the provided feature name
 *
 * @param {Object} appProvider App provider object
 * @param {String} featureName Name of the feature to check
 * @returns {object} feature properties
 **/
export function getFeatureProperties(appProvider, featureName) {
  if (appProvider?.config?.feature_toggles) {
    const featureNameLC = featureName.toLowerCase();
    for (const feature of appProvider.config.feature_toggles) {
      if (feature.name.toLowerCase() === featureNameLC) {
        if (feature.properties && Object.keys(feature.properties).length > 0) {
          return feature.properties;
        }
      }
    }
  }
  return null;
}

/**
 * Updates the URL with the provided query param. If it already exists in the URL, the value will be updated.
 * If not, then the query param will be added. If removeParam is set to true, the query param value is ignored and the key will be used
 * to search the URL and remove the entire query param.
 * @param {String} queryParam Query param string with format "key=value"
 * @param {Boolean} removeParam Flag to remove query param from URL if set to true
 */
export function updateURLQueryParam(queryParam, removeParam = false) {
  if (!!!queryParam) return;

  const currentHash = window.location.hash;
  let updatedHash = currentHash;
  const queryParamKV = queryParam.split("=");

  if (!currentHash.includes(queryParamKV[0])) {
    if (!removeParam) {
      updatedHash = currentHash + (currentHash.includes("?") ? "&" : "?") + queryParam;
    }
  } else {
    const temp = currentHash.substring(currentHash.indexOf(queryParamKV[0] + "="));
    let currentQueryParam;
    if (temp.includes("&")) {
      currentQueryParam = temp.substring(temp.indexOf(queryParamKV[0] + "="), temp.indexOf("&"));
    } else {
      currentQueryParam = temp.substring(temp.indexOf(queryParamKV[0] + "="));
    }

    if (removeParam) {
      // We want to remove the prefix too
      const currentQueryParamPrefix = currentHash.substr(currentHash.indexOf(queryParamKV[0] + "=") - 1, 1);
      updatedHash = currentHash.replace(currentQueryParamPrefix + currentQueryParam, "");
    } else {
      updatedHash = currentHash.replace(currentQueryParam, queryParam);
    }
  }

  window.history.replaceState({}, currentHash, updatedHash);
}

/**
 * Returns the purchase transaction type based on the name of the package
 * @param {String} packageName
 * @returns {String}
 */
export const getPurchaseTransactionType = (packageName) => {
  if (!packageName) {
    return null;
  }
  return packageName.includes(`-${DEFAULT_RENTAL_DURATION}h-`) ? TRANSACTION_TYPES.RENT : TRANSACTION_TYPES.BUY;
};

/**
 * Takes an object with query param data and appends the query params to the input string.
 *
 * @param {String} urlString The base string to append query params to.
 * @param {Object} queryParams The object with key-value pairs that should be appended to the input string.
 * @param {Boolean} hasPreexistingParams Flag indicating if the string already has any query params
 * @returns {String} urlString with or without query params appended
 */
export const addQueryParamsToString = (urlString, queryParams = {}, hasPreexistingParams = false) => {
  const queryParamKeys = Object.keys(queryParams);
  if (queryParamKeys.length) {
    let hasAppendedQueryParam = hasPreexistingParams;
    for (let i = 0; i < queryParamKeys.length; i++) {
      const key = queryParamKeys[i];
      const value = queryParams[key];

      if (value !== undefined && value !== null) {
        urlString += hasAppendedQueryParam ? "&" : "?";
        urlString += key + "=" + queryParams[key];
        hasAppendedQueryParam = true;
      }
    }
  }

  return urlString;
};

/**
 * Capitalize first letter of text
 * @param {String} str
 * @returns {String}
 */
export function capitalize(str) {
  if (!str) {
    return null;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Returns a key that's used in strings_en/strings_fr to return short form of month name
 */
export function getMonthKeyByNum(monthNum) {
  let monthName;
  switch (monthNum) {
    case 1:
      monthName = "month_name_jan";
      break;
    case 2:
      monthName = "month_name_feb";
      break;
    case 3:
      monthName = "month_name_mar";
      break;
    case 4:
      monthName = "month_name_apr";
      break;
    case 5:
      monthName = "month_name_may";
      break;
    case 6:
      monthName = "month_name_jun";
      break;
    case 7:
      monthName = "month_name_jul";
      break;
    case 8:
      monthName = "month_name_aug";
      break;
    case 9:
      monthName = "month_name_sep";
      break;
    case 10:
      monthName = "month_name_oct";
      break;
    case 11:
      monthName = "month_name_nov";
      break;
    default:
      monthName = "month_name_dec";
      break;
  }
  return monthName;
}

/**
 * Method is to force the app the reload itself
 * @param {Object} appProvider appProvider object
 */
export const reloadApp = (appProvider) => {
  let redirectUri = appProvider.config.general.AVS_config.redirectUri;

  if (ENV === APP_ENVIRONMENTS.DEV) {
    redirectUri = "http://localhost:3000";
  }

  if (window.location.href !== redirectUri + "/#/") {
    window.location.href = "/#/";
  }
  window.location.reload();
};

/**
 * Method is to evaluate if we're in a panic state based on api response
 * @param {Object} apiResponse JSON object from API response
 * @returns returns bool value if we're getting a 503-10000
 */
export const isPanicMode = (apiResponse) => {
  return apiResponse?.data?.resultCode === "KO" && apiResponse.data.errorDescription === AVS_ERROR_CODES.PANIC_MODE;
};

/**
 * Returns the given profile's parental control level if
 * 1. the profile is a kids profile or
 * 2. if the parental PIN is enabled and the profile is a standard profile
 * The returned value is used to hide swimlane and view all content that would require parental PIN to access.
 * For master profiles, we return null because master profiles can see content above their PC level.
 * @param {Object} userProfile
 * @param {Boolean} isUserProfilesEnabled
 * @param {Boolean} isParentalPINEnabled
 * @returns {String}
 */
export const getPcLevelRestriction = (userProfile, isUserProfilesEnabled, isParentalPINEnabled) => {
  if (!isUserProfilesEnabled) {
    return null;
  }

  const profileData = userProfile?.user?.profile?.profileData;
  const isPcPinEnabled = isParentalPINEnabled && profileData?.parentalControlPinEnabled === "Y";

  if (profileData?.kidsProfile || (isPcPinEnabled && profileData.isMasterAccount === "N")) {
    return profileData.userPcLevelVod;
  }

  return null;
};

/**
 * Returns a param object for excluding E rated content for kids profile that's
 * used as part of the API params.
 * @param {Object} userProfile
 * @param {Object} paramObj
 * @returns {Object}
 */
export const getKidExclusionRating = (userProfile, paramObj = null) => {
  const profileData = userProfile?.user?.profile?.profileData;

  if (profileData?.kidsProfile) {
    if (paramObj) {
      paramObj.filter_excludePcLevels = 0;
    }
  }
};

/**
 * Induces a delay of function execution by input value of milliseconds
 * @param {Number} delay Value in milliseconds to induce a delay of execution in code
 */
export const sleep = async (delay) => {
  await new Promise((r) => setTimeout(r, delay));
};

export const checkUnifiedAsset = (metadata) =>
  metadata && Object.keys(metadata)?.some((key) => key === "uaId" || key === "uaType" || key === "seriesUaId");

export const seasonCompareFunction = (previousSeason, nextSeason) => {
  return nextSeason?.metadata?.season - previousSeason?.metadata?.season;
};

/**
 * Compare function used to sort numbers that are in string format in numerical order
 *
 * @param {String} a Number in string format
 * @param {String} b Number in string format
 * @returns {Number}
 */
export const numberStringCompareFunction = (a, b) => {
  return parseInt(a, 10) - parseInt(b, 10);
};

/**
 * Function used to sort Showtimes where required.
 * Showtimes are sorted by earliest airing time or lowest channel number if airing at same time.
 *
 * @param {Array} schedules Array of program objects structured to match the TRAY/SEARCH/PROGRAM response
 * @param {Number} regionId User's region ID for determining channel numbers
 */
export const sortShowtimes = (schedules, regionId) => {
  schedules.sort((scheduleA, scheduleB) => {
    const startTimeA = scheduleA.metadata?.airingStartTime;
    const startTimeB = scheduleB.metadata?.airingStartTime;
    if (startTimeA !== startTimeB) {
      return startTimeA - startTimeB;
    }

    const channelNumberA = getRegionalChannelNumber(scheduleA.channel, regionId);
    const channelNumberB = getRegionalChannelNumber(scheduleB.channel, regionId);
    return channelNumberA - channelNumberB;
  });
};

/**
 * Creates an object where keys represent filter/sort category groupings and values are the label of the filter/sort item for that group
 * @param {Array} itemsArray Array of filter/sort item objects
 * @param {Boolean} isAppLanguageFrench
 * @returns {Object} or null
 */
export const createDropdownItemLabelObj = (itemsArray, isAppLanguageFrench) => {
  let itemObj = {};
  for (let i = 0; i < itemsArray.length; i++) {
    itemObj[i] = isAppLanguageFrench && itemsArray[i].label_FRA ? itemsArray[i].label_FRA : itemsArray[i].label;
  }

  return Object.keys(itemObj).length > 0 ? itemObj : null;
};

/**
 * @returns {Array} Array of default filter items for each filter category
 */
export const getDefaultFilterItems = (filterData) => {
  let defaultFilterItems = [];
  if (filterData) {
    for (let filterCategory of filterData) {
      defaultFilterItems.push(filterCategory.values[0]);
    }
  }

  return defaultFilterItems;
};

/**
 * Determines if a schedule should be filtered out of a list of schedules
 *
 * @param {Object} schedule
 * @param {Boolean} isLookbackEnabled
 * @param {Boolean} isInHome
 * @returns {Boolean} Flag indicating if schedule should be filtered
 */
export const shouldFilterSchedule = (schedule, isLookbackEnabled, isInHome) => {
  if (schedule?.metadata) {
    if (isLookbackEnabled) {
      const currentTime = moment();
      const threshold = moment().subtract(LOOKBACK_AVAILABILITY_HOURS, "hours");
      const airingStartTime = moment(schedule.metadata.airingStartTime);
      const airingEndTime = moment(schedule.metadata.airingEndTime);

      return !(
        airingEndTime.isAfter(currentTime) ||
        (airingStartTime.isSameOrAfter(threshold) &&
          checkIsLookbackSupported(schedule.metadata, schedule.channel, isInHome) &&
          schedule.isChannelSubscribed === true)
      );
    } else {
      return false;
    }
  }
  return true;
};

/**
 * @param {Object} schedule
 * @returns {Number} Return hours left that schedule will not within lookback window
 */
export const calculateLookbackHoursLeft = (schedule) => {
  const airingStartTime = moment(schedule?.metadata?.airingStartTime);
  const timeDifference = moment().diff(airingStartTime, "hours");

  return LOOKBACK_AVAILABILITY_HOURS - timeDifference;
};

/**
 * Function to get newest lookback program (in case more than one channel have the newest program we go with the lowest channel number)
 * @param {Array} schedules array of schedules available for an episode or movie
 * @param {Object} subscribedChannels object having container of channels user is subscribed to
 * @param {Boolean} isInHome flag to indicate if user is in home and can play out-of-home restricted content
 * @returns {Object}
 */
export const getNewestLookbackProgram = (schedules, subscribedChannels, isInHome, regionId) => {
  if (schedules && subscribedChannels?.containers) {
    return schedules
      .map((schedule) => {
        if (schedule?.channel) {
          const channel = subscribedChannels.containers.find(
            (channel) => channel.metadata.channelId === schedule.channel.channelId
          );

          let channelMetadata;
          if (channel?.metadata) {
            channelMetadata = {
              ...channel.metadata,
              isChannelSubscribed: true,
              isContentOOHBlocked: channel.metadata.isNotAvailableOutOfHome,
            };
          } else {
            channelMetadata = {
              isChannelSubscribed: false,
              isContentOOHBlocked: false,
            };
          }

          return {
            ...schedule,
            channel: {
              ...channel,
              metadata: channelMetadata,
            },
          };
        }

        return schedule;
      })
      .filter((schedule) => {
        if (schedule?.metadata) {
          const currentTime = moment();
          const threshold = moment().subtract(LOOKBACK_AVAILABILITY_HOURS, "hours");
          const airingStartTime = moment(schedule.metadata.airingStartTime);
          const airingEndTime = moment(schedule.metadata.airingEndTime);

          return (
            airingEndTime.isBefore(currentTime) &&
            airingStartTime.isSameOrAfter(threshold) &&
            checkIsLookbackSupported(schedule.metadata, schedule.channel, isInHome) &&
            schedule.channel.metadata.isChannelSubscribed === true
          );
        }
        return false;
      })
      .reduce((prevSchedule, currentSchedule) => {
        const prevScheduleStartTime = prevSchedule?.metadata?.airingStartTime;
        const currentScheduleStartTime = currentSchedule?.metadata?.airingStartTime;
        if (prevScheduleStartTime !== currentScheduleStartTime) {
          return prevScheduleStartTime > currentScheduleStartTime ? prevSchedule : currentSchedule;
        }

        const prevScheduleChannelNumber = getRegionalChannelNumber(prevSchedule?.channel, regionId);
        const currentScheduleChannelNumber = getRegionalChannelNumber(currentSchedule?.channel, regionId);
        return prevScheduleChannelNumber < currentScheduleChannelNumber ? prevSchedule : currentSchedule;
      }, null);
  }
};

/**
 * Function to get the response from the API.[23/06/23] Currently we are using this for PIN KO response.
 * @param {Object} response
 * @returns Object.
 */
export const getResponseObject = (response) => {
  if (response && response.config && response.data) {
    return {
      url: response.config.url,
      message: response.data.message,
      type: "Server",
      code: response.data.errorDescription,
      params: response.data.resultObj?.resultObj ?? response.data.resultObj,
    };
  } else {
    return response;
  }
};

/**
 * Function to check if the program has aired in past
 * @param {Number} programEndTime timestamp of program's end time
 * @returns {Boolean}
 */
export const isPastProgram = (programEndTime) => {
  const currentTime = moment().valueOf();
  return currentTime >= programEndTime;
};

/**
 * Function to return the container as per the provided layout
 * @param {Array} containers array of page containers
 * @param {String} layout layout type of the container
 * @returns container as per the provided layout
 */
export const findContainerByLayout = (containers, layout) =>
  containers?.find((container) => container?.layout?.toLowerCase() === layout);

/**
 * Resets session storage values for scroll position and selected swimlane.
 */
export const resetScrollPosition = () => {
  removeSessionStorage(LAST_SCROLL_POSITION);
  removeSessionStorage(LAST_SELECTED_SWIMLANE_ID);
  removeSessionStorage(LAST_VIEW_ALL_SCROLL_POSITION);
  removeSessionStorage(LAST_VIEW_ALL_SCROLL_SWIMLANE_ID);
  window.scrollTo(0, 0);
};

/**
 * Method to check if any of the schedule is from a subscribed channel or not
 * @param {Array} schedules list of schedules
 * @param {Array} subscribedChannels list of subscribed channels
 * @returns {Boolean}
 */
export const checkIfAnyScheduleIsSubscribed = (schedules, subscribedChannels) => {
  const subscribedChannelIds = new Set(subscribedChannels?.containers?.map((channel) => channel?.metadata?.channelId));
  return schedules.some((schedule) => subscribedChannelIds.has(schedule?.channel?.channelId));
};

/**
 * Method to evaluate values from harness to usable web versions
 * @param {Object} flag Harness feature flag
 * @param {String} flag Harness feature flag
 * @param {Object} app provider object
 * @param {Boolean} panic boolean
 * @param {Boolean} feature enabled in panic
 * @returns {Boolean}
 */
const harnessStringEval = (
  flag,
  webFlag,
  provider,
  panicMode = false,
  panicType = "legacy",
  isHarnessEnabled = false
) => {
  let configProperties;
  if (webFlag !== "") {
    const configProperties = provider.config["feature_toggles"].filter((flag) => flag.name === webFlag)[0];
  }
  if (panicMode && panicType === "legacy") {
    return isHarnessEnabled;
  }
  if (typeof flag === "string") {
    if (flag === "enabled" && configProperties?.properties?.user_visibility) {
      return isFeatureEnabledForUserType(provider, configProperties);
    } else {
      return flag === "enabled";
    }
  }
  return flag;
};

/**
 * Method to evaluate values from harness to usable web versions
 * @param {Array} Array of disabled features
 * @param {String} Feature to compare to
 * @returns {Boolean}
 */
const isFeaturePanicEnabled = (featureFlags, feature) => {
  if (featureFlags?.find((element) => element === feature)) {
    return false;
  }
  return true;
};

/**
 * Method to map values from harness to usable web versions
 * @param {Object} Object of harness flags
 * @returns {Object}
 */
export const mapHarnessFeatureFlags = (featureFlags, provider, panicMode) => {
  let webFeatureFlags = {};
  const panic = (featureFlags?.panic && JSON.parse(featureFlags?.panic)) || { state: "none" };
  const panicType = panic?.state;
  const disableFlags = panic?.disable_flags;

  // Basic app feature functionality
  webFeatureFlags.isUserProfilesEnabled =
    harnessStringEval(
      featureFlags?.user_profile,
      "UserProfiles",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "user_profile")
    ) || false;
  webFeatureFlags.isCloudPVREnabled =
    harnessStringEval(
      featureFlags?.cpvr,
      "CloudPVR",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "cpvr")
    ) || false;
  webFeatureFlags.isRemoteRecordingsEnabled =
    harnessStringEval(
      featureFlags?.lpvr,
      "RemoteRecordings",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "lpvr")
    ) || false;
  webFeatureFlags.isParentalPINEnabled =
    harnessStringEval(
      featureFlags?.parental_pin,
      "ParentalPIN",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "parental_pin")
    ) || false;
  webFeatureFlags.isPurchasePINEnabled =
    harnessStringEval(
      featureFlags?.purchase_pin,
      "PurchasePIN",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "purchase_pin")
    ) || false;
  webFeatureFlags.isPauseLiveTVEnabled =
    harnessStringEval(
      featureFlags?.pause_live,
      "PauseLiveTV",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "pause_live")
    ) || false;
  webFeatureFlags.isRecommendationMLTEnabled = panicMode ? false : true; // This check can be removed at a later date
  webFeatureFlags.isRestartLiveTVEnabled =
    harnessStringEval(
      featureFlags?.restart,
      "RestartLiveTV",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "restart")
    ) || false;
  webFeatureFlags.isFavouritesEnabled =
    harnessStringEval(
      featureFlags?.favorite,
      "Favourites",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "favorite")
    ) || false;
  webFeatureFlags.isPPVEnabled =
    harnessStringEval(featureFlags?.ppv, "PPV", provider, isFeaturePanicEnabled(disableFlags, "ppv")) || false;
  webFeatureFlags.isLookbackEnabled =
    harnessStringEval(
      featureFlags?.catchup,
      "Lookback",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "catchup")
    ) || false;
  webFeatureFlags.isChromecastEnabled =
    harnessStringEval(
      featureFlags?.chromecast,
      "Chromecast",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "chromecast")
    ) || false;
  webFeatureFlags.isGuideContextMenuEnabled =
    harnessStringEval(
      featureFlags?.web_epg_context_menu,
      "GuideContextMenu",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "web_epg_context_menu")
    ) || false;
  webFeatureFlags.isConvivaAppTrackerEnabled =
    harnessStringEval(
      featureFlags?.web_conviva_eco,
      "ConvivaAppTracker",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "web_conviva_eco")
    ) || false;
  webFeatureFlags.isOAuthEnabled = harnessStringEval(featureFlags?.legacy_login_service, "", provider);
  webFeatureFlags.isSearchCollectionEnabled =
    harnessStringEval(
      featureFlags?.web_search_collection,
      "SearchCollection",
      provider,
      panicMode,
      panicType,
      isFeaturePanicEnabled(disableFlags, "web_search_collection")
    ) || false;
  webFeatureFlags.panic = panic;

  // Playback specific features
  webFeatureFlags.isVODDisabled = harnessStringEval(featureFlags?.panic_vod_playback, "", provider) || false;
  webFeatureFlags.isLIVEDisabled = harnessStringEval(featureFlags?.panic_live_playback, "", provider) || false;
  webFeatureFlags.isFASTDisabled = harnessStringEval(featureFlags?.panic_fast_playback, "", provider) || false;
  webFeatureFlags.isRestartDisabled = harnessStringEval(featureFlags?.panic_restart_playback, "", provider) || false;
  webFeatureFlags.isCatchUpDisabled = harnessStringEval(featureFlags?.panic_catchup_playback, "", provider) || false;
  webFeatureFlags.isCPVRDisabled = harnessStringEval(featureFlags?.panic_cpvr_playback, "", provider) || false;
  webFeatureFlags.isPlaybackRetry = featureFlags?.playback_retry
    ? JSON.parse(featureFlags?.playback_retry)
    : { Enabled: false };

  setSessionStorage(CANARY, harnessStringEval(featureFlags?.canary, "", provider));

  // Koodo harness login
  webFeatureFlags.isKoodoLoginEnabled = harnessStringEval(featureFlags?.koodo_login, "", provider) || false;

  return webFeatureFlags;
};

/**
 * Checks if a feature is enabled based on the user property type
 *
 * @param {Object} appProvider App provider object
 * @param {Object} feature Feature toggle config
 * @returns {Boolean}
 */
const isFeatureEnabledForUserType = (appProvider, feature) => {
  // Pik is one of the possible values for userPropertyType but it is not populated for existing users, so we rely on the AVS property
  const isPik =
    (!appProvider.isOptik && !appProvider.userPropertyName) ||
    appProvider.userPropertyName === USER_PROPERTY_NAMES.PIK ||
    appProvider.userPropertyType === USER_PROPERTY_TYPES.PIK; // TODO: the first condition is a temporary fix to support environments that do not have the SPLUS enabled. Remove once SPLUS is enabled in all environments.
  const isOptikBudgetPremium = appProvider.userPropertyType === USER_PROPERTY_TYPES.OPTIK_AVS;
  const isTTP = isPik || appProvider.userPropertyName === USER_PROPERTY_NAMES.OPTIK;
  const isSplus = appProvider.userPropertyName === USER_PROPERTY_NAMES.SPLUS;

  switch (feature.properties.user_visibility) {
    case USER_VISIBILITY_CONTROL.PIK:
      return isPik;
    case USER_VISIBILITY_CONTROL.AVS:
      return (isOptikBudgetPremium || isPik) && !isSplus;
    case USER_VISIBILITY_CONTROL.TTP:
      return isTTP;
    case USER_VISIBILITY_CONTROL.SPLUS:
      return isTTP || isSplus;
    default:
      return false;
  }
};

/*
 * Used to check the autogenerated fields and return an object.
 * @param {Object} content
 * @returns Object
 */
export const getAutoGeneratedObject = (content) => {
  const isEpisodeAutoGenerated =
    content?.episode?.autoGenerated ?? content?.extendedMetadata?.dlum?.episode?.autoGenerated;
  const isSeasonAutoGenerated =
    content?.episode?.seasonAutoGenerated ?? content?.extendedMetadata?.dlum?.episode?.seasonAutoGenerated;
  return { isEpisodeAutoGenerated, isSeasonAutoGenerated };
};

/**
 * @param {Object} uaEpisodeObject season and episode autogenerated fields.
 * @param {Object} contentMetadata.
 * @param {Boolean} displayTitle.
 * @returns Formatted episode info string
 */
export const getAutogeneratedEpisodeString = (uaEpisodeObject, contentMetadata, displayTitle = true) => {
  if (!contentMetadata) return ""; // Add a check for null or undefined contentMetadata
  const { season, seasonNumber, episodeNumber, episodeId, episodeTitle, title, programTitle, seriesTitle } =
    contentMetadata;
  const seasonNumberStr =
    !uaEpisodeObject.isSeasonAutoGenerated && (season || seasonNumber) ? `S${season || seasonNumber}` : "";
  const episodeNumberStr =
    !uaEpisodeObject.isEpisodeAutoGenerated && (episodeNumber || episodeId) ? `E${episodeNumber || episodeId}` : "";
  const punctuationColon = displayTitle && (seasonNumberStr || episodeNumberStr) ? i18n.t("punctuation:colon") : "";

  let episodeTitleStr = "";
  if (displayTitle) {
    episodeTitleStr = episodeTitle || title || programTitle || seriesTitle || "";
  }

  const episodeString = `${seasonNumberStr} ${episodeNumberStr}${punctuationColon} ${episodeTitleStr}`;
  return episodeString;
};

/**
 * @param {String} errorDescription avs error code
 * @returns Boolean
 */
export const shouldRetryRefresh = (errorDescription) => {
  if (
    errorDescription === AVS_ERROR_CODES.USER_NOT_LOGIN ||
    errorDescription === AVS_ERROR_CODES.TOKEN_NOT_VALID ||
    errorDescription === AVS_ERROR_CODES.TOKEN_NOT_VALID_2
  ) {
    return true;
  }
  return false;
};

/**
 * @param {Object} error JSON response
 * @returns Boolean
 */
export const isPinMaxLimitReach = (error) =>
  error?.params?.remainingAttempts === 0 ||
  error?.code?.includes(AVS_ERROR_CODES.MAX_ATTEMPTS_INCORRECT_PARENTAL_PIN) ||
  error?.code?.includes(AVS_ERROR_CODES.MAX_ATTEMPTS_INCORRECT_PURCHASE_PIN);

/**
 * Storybook styled screen
 */
export const Screen = styled.div(
  ({ theme }) => `
  background: ${theme.colours.focusedRecordingButtonBackground};
  color: ${theme.colours.white};
`
);

export const getRandomInt = (min, max) => {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
};

export const isBookmarkComplete = (bookmark) => {
  return bookmark?.isComplete ? true : false;
};
