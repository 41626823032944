import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getFormattedPrice, getPackagePrice } from "../../shared/utils/feedHelper";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import OptikButton from "../OptikButton";

/**
 * Order CTA for purchases and rentals
 * @component
 * @param {Object} props
 */
const OrderCTA = ({ purchasePackages = [], onClickHandler = () => {} }) => {
  const { t: translate } = useTranslation();
  const { appLanguage } = useAppLanguage();

  // CTA text changes based on how many purchase packages are available
  const displayPrice = getFormattedPrice(getMinPurchasePrice(purchasePackages), appLanguage);
  const orderText =
    purchasePackages?.length > 1
      ? `${translate("order_from")} ${displayPrice}`
      : translate("order_for_stb").replace("%s", displayPrice);

  return purchasePackages?.length > 0 ? (
    <div className="detail-page-cta">
      <OptikButton
        label={orderText}
        icon={`${process.env.PUBLIC_URL}/images/order.svg`}
        onClickHandler={onClickHandler}
      />
    </div>
  ) : null;
};

/**
 * Returns the minimum purchase price from a list of packages
 * @param {Array} purchasePackages
 * @returns {Number}
 */
const getMinPurchasePrice = (purchasePackages) => {
  const packagePrices = purchasePackages
    ?.map((purchasePackage) => getPackagePrice(purchasePackage))
    ?.filter((price) => price);

  if (packagePrices?.length > 0) {
    return Math.min(...packagePrices);
  }
  return null;
};

OrderCTA.propTypes = {
  purchasePackages: PropTypes.array,
  onClickHandler: PropTypes.func,
};

export default OrderCTA;
