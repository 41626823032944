import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ContentActivities from "../ContentActivities";
import Logo from "../Logo";
import SettingsButton from "../ImageButton";
import Search from "../Search";
import routeConstants from "../../shared/constants/routes";
import constants from "../../shared/constants";
import storageConstants from "../../shared/constants/storage";
import { loadHubs } from "./state/actions";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { handleLogin } from "../../shared/utils/login";
import { getRouteToPVRManager } from "../../shared/utils/recordingHelper";
import { getLocalStorage } from "../../shared/utils/localStorage";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import ProfileMenu from "../ProfileMenu";
import "./style.scss";

const { SETTINGS, BASICS } = routeConstants;
const { TOP_NAV_ACTIVITY_TYPES } = constants;

/**
 * Component that renders a Top Navigation
 * @component
 * @param {object} props
 */
function TopNav(props) {
  const [isSettingsActive, setSettingsActive] = useState(false);
  const { appProvider, userProfile, loadHubs, hubs: hubsFromMenu, displayStatus, featureToggles } = props;
  const { isUserProfilesEnabled, isRecordingEnabled } = featureToggles;
  const location = useLocation();
  const history = useHistory();
  const topNavStyle = displayStatus ? { display: "none" } : { display: "" };
  const { t: translate } = useTranslation();
  const [hubs, setHubs] = useState([]);

  const isKidsProfile = isUserProfilesEnabled && userProfile?.user?.profile?.profileData?.kidsProfile;

  // Loading hubs on component load
  useEffect(() => {
    loadHubs(appProvider, userProfile);
  }, [appProvider, loadHubs, userProfile]);

  /**
   * Create and returns a activity item object for content activity
   *
   * @param {Object} hub - hub item
   * @param {String} type - type of hub to be used inside activity
   * @returns {Object}
   */
  const createActivityItem = (hub, type = TOP_NAV_ACTIVITY_TYPES.hub) => {
    const activityItem = {};
    const hubName = hub?.metadata?.label;
    const { actions } = hub;
    if (hub) {
      activityItem.type = type;
      if (hubName) activityItem.name = hubName;
      if (hub.Id === "hub-recording") activityItem.route = getRouteToPVRManager();
      else if (actions && actions.length) activityItem.route = actions[0].uri;
    }
    return activityItem;
  };

  useEffect(() => {
    if (hubsFromMenu?.length > 0) {
      const getAdditionalHubs = () => {
        const additionalHubs = [];

        if (isKidsProfile) {
          return additionalHubs;
        }

        if (appProvider?.config?.web?.additional_hubs) {
          appProvider.config.web.additional_hubs.forEach((hub) => {
            if (hub.Id === "hub-recording") {
              if (appProvider.isOptik && isRecordingEnabled) {
                additionalHubs.push(hub);
              }
            } else {
              additionalHubs.push(hub);
            }
          });
        }

        return additionalHubs;
      };

      setHubs(hubsFromMenu.concat(getAdditionalHubs()));
    }
  }, [appProvider, hubsFromMenu, isKidsProfile, isRecordingEnabled]);

  const settingsClickHandler = () => {
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.SETTINGS};${LINK_INFO.MEGA_NAV}`);
    history.push("/settings");
  };

  useEffect(() => {
    setSettingsActive(location.pathname === SETTINGS.route || location.pathname === BASICS.route);
  }, [location]);

  return (
    <div className="site-header" style={topNavStyle}>
      <div className={`top-nav ${isKidsProfile ? "kids" : ""}`}>
        <div className="display-flex align-center">
          <Logo isKidsProfile={isKidsProfile} />
          {!isKidsProfile && hubs?.length > 0 && (
            <ContentActivities hubs={hubs.map((hub) => createActivityItem(hub, hub.type))} />
          )}
        </div>
        <div className="position-relative nav-right">
          <div className="display-flex align-center search-bar-container">
            <Search />
            {!userProfile?.isLoggedIn && (
              <Button
                buttonStyles="login-button"
                label={translate(LINK_INFO.LOGIN)}
                onClickHandler={() => {
                  setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.LOGIN};${LINK_INFO.MEGA_NAV}`);
                  handleLogin(appProvider, constants.LOGIN_BRANDS.TELUS, featureToggles.isKoodoLoginEnabled);
                }}
              />
            )}
            {isUserProfilesEnabled && userProfile?.isLoggedIn ? (
              <ProfileMenu />
            ) : (
              <div className={isSettingsActive ? "settings-button-container active" : "settings-button-container"}>
                <span className="settings-icon-container">
                  <SettingsButton
                    src={
                      process.env.PUBLIC_URL +
                      (appProvider.panicMode ? "/images/gear-error.svg" : "/images/gear-icon.svg")
                    }
                    alt="settings"
                    onClickHandler={settingsClickHandler}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ app, topNav }) {
  return {
    appProvider: app.provider,
    userProfile: app.userProfile,
    hubs: topNav.hubs,
    displayStatus: app.hideTopNav,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  loadHubs,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
