import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import packageJson from "../../../package.json";
import SeoPageTags from "../../components/SeoPageTags";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import "./style.scss";

/**
 * Component that render settings detail
 * @component
 * @param {Object} props
 */
function SettingsDetailPage(props) {
  const { appProvider } = props;
  const { t: translate } = useTranslation();
  const { trackPageView } = useTrackPageView();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  const isUrl = (s) => {
    // eslint-disable-next-line
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  };

  const appInfo = [
    { name: translate("app_version"), value: appProvider.config.version },
    { name: translate("build_version"), value: packageJson.version },
    { name: translate("date"), value: packageJson.buildReleaseDate },
    {
      name: translate("environment"),
      value: appProvider.config.general.environment,
    },
  ];

  return (
    <React.Fragment>
      <SeoPageTags title={translate("Settings Info")} keywords={["optik", "telus"]} />
      <div className="SettingDetail">
        <div className="settingDetail-heading">
          <span className="settingDetail-label">{translate("app_version_info")}</span>
        </div>
        <div className="settingDetailList">
          {appInfo.map((item, index) => (
            <p key={index}>
              {item.name}
              {isUrl(item.value) ? (
                <a target="_blank" rel="noopener noreferrer" href={item.value}>
                  {item.value}
                </a>
              ) : (
                <span>{item.value}</span>
              )}
            </p>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

function mapStateToProps({ app }) {
  return {
    appProvider: app.provider,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDetailPage);
