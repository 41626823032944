import request from "../../service/request";
import constants from "../constants";
import { addQueryParamsToString } from "../utils";
const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

/**
 * Retrieve AVS Page or Tray Items.
 * @param {*} appProvider - App provider to provision calls based on session
 * @param {*} actionURI - Part URL to fetch page or tray items from AVS
 */
function retrieveAvsItems(appProvider, actionURI, returnResponseObject = false, cancelTokenSource = null) {
  let url = appProvider.config.avs_api_urls.avs_retrieve;

  const withCredentials = appProvider.userCluster === "R";
  const queryParams = {};

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("/$RETRIEVE_URL", actionURI);

  if (actionURI?.includes("content_filters=subscribed")) {
    url = url.replace("content_filters=subscribed", `filter_technicalPackages=${appProvider.svodPackageIds}`);
  } else if (appProvider.panicMode && withCredentials && actionURI?.includes("/SEARCH")) {
    queryParams.filter_pcLevel = appProvider.pcLevel;
    if (!actionURI.includes("/SEARCH/PROGRAM")) {
      queryParams.filter_technicalPackages = appProvider.svodPackageIds;
    }
  }

  url = addQueryParamsToString(url, queryParams, actionURI.includes("?"));

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request({ url: url, method: "GET", withCredentials, returnResponseObject }, cancelTokenSource, appProvider);
}

export default retrieveAvsItems;
