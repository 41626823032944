import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import parentalControlConstants from "../../shared/constants/parentalControls";
import "./style.scss";

const { PARENTAL_RATINGS } = parentalControlConstants;
const [allowAllRatingKey, ...ratingKeys] = Object.keys(PARENTAL_RATINGS);

const lockIcon = `${process.env.PUBLIC_URL}/images/lock-icon.png`;
const circleIcon = `${process.env.PUBLIC_URL}/images/ellipse.png`;
const filledCircleIcon = `${process.env.PUBLIC_URL}/images/filled-circle-green.svg`;

/**
 * List of selectable parental control ratings
 * @component
 * @param {Object} props
 */
function ParentalRatingList({ selectedRating = 0, isPcPinEnabled = false, onRatingSelect = () => {} }) {
  const { t: translate } = useTranslation();
  const [focusedRating, setFocusedRating] = useState(null);

  const isAllowAllHighlighted = focusedRating === allowAllRatingKey;
  const isAllowAllSelected = selectedRating === PARENTAL_RATINGS[allowAllRatingKey].value;

  return (
    <div className="rating-list-container">
      <button
        className={`rating-row allow-all-row ${isAllowAllHighlighted ? "highlighted" : ""}`}
        onClick={() => onRatingSelect(allowAllRatingKey)}
        disabled={!isPcPinEnabled}
        onMouseEnter={() => setFocusedRating(allowAllRatingKey)}
        onMouseLeave={() => setFocusedRating(null)}
        onFocus={() => setFocusedRating(allowAllRatingKey)}
        onBlur={() => setFocusedRating(null)}
      >
        <div className="rating-text">{translate("allow_all")}</div>
        <img
          className="rating-icon"
          src={
            !isPcPinEnabled || isAllowAllHighlighted || (!focusedRating && isAllowAllSelected)
              ? filledCircleIcon
              : circleIcon
          }
          alt=""
        />
      </button>

      {ratingKeys.map((key) => {
        const ratingOption = PARENTAL_RATINGS[key];
        const isRowHighlighted = focusedRating && ratingOption.value >= PARENTAL_RATINGS[focusedRating].value;
        const isRowRestricted = isPcPinEnabled && ratingOption.value >= selectedRating;

        return (
          <button
            key={key}
            className={`rating-row ${isRowHighlighted ? "highlighted" : ""}`}
            onClick={() => onRatingSelect(key)}
            disabled={!isPcPinEnabled}
            onMouseEnter={() => setFocusedRating(key)}
            onMouseLeave={() => setFocusedRating(null)}
            onFocus={() => setFocusedRating(key)}
            onBlur={() => setFocusedRating(null)}
          >
            <div className="rating-text">
              <span>{translate(ratingOption.title_key)}</span>
              <span>{translate(ratingOption.rating)}</span>
            </div>
            <img
              className="rating-icon"
              src={
                !isAllowAllHighlighted && (isRowHighlighted || (!focusedRating && isRowRestricted))
                  ? lockIcon
                  : circleIcon
              }
              alt=""
            />
          </button>
        );
      })}
    </div>
  );
}

ParentalRatingList.propTypes = {
  selectedRating: PropTypes.number.isRequired,
  isPcPinEnabled: PropTypes.bool,
  onRatingSelect: PropTypes.func,
};

export default ParentalRatingList;
