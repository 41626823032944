import request from "../../service/request";
import constants from "../constants";
const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

function getHubs(appProvider) {
  let url = appProvider.config.avs_api_urls.menu_url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);

  const withCredentials = appProvider.userCluster === "R";

  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request({ url, method: "GET", withCredentials }, null, appProvider);
}

export default getHubs;
