import React from "react";
import PropTypes from "prop-types";
import AvatarButton from "../AvatarButton";
import "./style.scss";

/**
 * Displays available avatars in a responsive grid
 * @component
 * @param {Object} props
 */
function AvatarGrid({ avatarList, onAvatarSelect = () => {} }) {
  return avatarList?.length > 0 ? (
    <div className="avatar-grid">
      {avatarList.map((avatarInfo, index) => {
        return (
          <AvatarButton key={avatarInfo.id} avatarInfo={avatarInfo} onClick={() => onAvatarSelect(avatarInfo, index)} />
        );
      })}
    </div>
  ) : null;
}

AvatarGrid.propTypes = {
  avatarList: PropTypes.array,
  onAvatarSelect: PropTypes.func,
};

export default AvatarGrid;
