import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PopUpOutline from "../PopUpOutline";
import ImageButton from "../ImageButton";
import constants from "../../shared/constants";
import routeConstants from "../../shared/constants/routes";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";

import "./style.scss";
import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";

const { ON_DEMAND_PLAYER } = routeConstants;
const { PAGE_CONTENT_ITEM_TYPES } = constants;

/**
 * Ways to watch prompt component
 * @component
 * @param {Object} props
 */
const WaysToWatchPrompt = ({ watchOptions, closePrompt, vodPlaybackType }) => {
  const { t: translate } = useTranslation();
  const { performPlaybackChecks } = usePlaybackChecks();

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [watchOptions]);

  const handleWatchOptionClick = (watchOption) => {
    performPlaybackChecks(
      watchOption.userData || watchOption,
      vodPlaybackType,
      watchOption?.contentId || watchOption?.metadata?.contentId,
      PAGE_CONTENT_ITEM_TYPES.vod,
      ON_DEMAND_PLAYER.route
    );
    setSessionStorage(
      ANALYTICS_STORAGE_KEYS.LINK,
      `${LINK_INFO.ON_DEMAND_PLAY};${LINK_INFO.SHOW_WAYS_TO_WATCH_PROMPT}`
    );
  };

  const waysToWatchOptionDetails = (watchOption) => {
    const channelInfo = watchOption?.channel || watchOption?.metadata?.extendedMetadata?.source;
    return (
      <div className="details-wrapper">
        {channelInfo?.image43 && (
          <div className="network-logo">
            <img src={`${channelInfo?.image43}?w=60`} alt={channelInfo?.name} />
          </div>
        )}
        <div className="watch-option-action">{`${translate("play_from")} ${channelInfo?.name}`}</div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="ways-to-watch-backdrop"></div>
      <div className="ways-to-watch-prompt-container">
        <div className="ways-to-watch-container">
          <PopUpOutline className="ways-to-watch">
            <div className="ways-to-watch-label-container">
              <div className="ways-to-watch-label">{translate("ways_to_watch")}</div>
              <ImageButton
                src={process.env.PUBLIC_URL + "/images/playback-close.svg"}
                alt={translate("close")}
                testID="closeIcon"
                onClickHandler={closePrompt}
              />
            </div>
            {watchOptions?.map((watchOption) => {
              return (
                <div
                  key={watchOption?.assetId || watchOption?.id}
                  className="watch-option-wrap"
                  onClick={() => handleWatchOptionClick(watchOption)}
                >
                  {waysToWatchOptionDetails(watchOption)}
                  <div className="action-wrapper">
                    <ImageButton
                      src={process.env.PUBLIC_URL + "/images/play-icon.svg"}
                      alt={translate("play")}
                      testID="playIcon"
                    />
                  </div>
                </div>
              );
            })}
          </PopUpOutline>
        </div>
      </div>
    </React.Fragment>
  );
};

WaysToWatchPrompt.propTypes = {
  watchOptions: PropTypes.array.isRequired,
  closePrompt: PropTypes.func.isRequired,
};

export default WaysToWatchPrompt;
