import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import OptikButton from "../../components/OptikButton";
import ProfileScreen from "../../components/ProfileScreen";
import ProfileList from "../../components/ProfileList";
import { getSessionStorage, removeSessionStorage, setSessionStorage } from "../../shared/utils/sessionStorage";
import routeConstants from "../../shared/constants/routes";
import storageConstants from "../../shared/constants/storage";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO, PROFILE_PAGE_NAMES } from "../../shared/constants/analytics";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import "./style.scss";

const { PROFILE_SELECT } = routeConstants;
const { EDIT_PROFILES_PREVIOUS_PAGE } = storageConstants;

/**
 * Page component linking to each profile's edit page
 * @component
 * @param {Object} props
 */
function EditAllProfilesPage({ profiles, appProvider, userProfile }) {
  const [newProfileId, setNewProfileId] = useState(null);
  const [deletedProfile, setDeletedProfile] = useState(null);
  const history = useHistory();
  const { t: translate } = useTranslation();
  const location = useLocation();
  const { trackPageView } = useTrackPageView();

  const isPageLoadAllowed = profiles && userProfile?.user?.profile?.profileData?.isMasterAccount === "Y";

  useEffect(() => {
    // If a profile has just been created or deleted, we handle it
    // here and then pass the profile's userId to ProfileList
    if (location.state?.newProfileId) {
      setNewProfileId(location.state.newProfileId);
    }
    if (location.state?.deletedProfile) {
      setDeletedProfile(location.state.deletedProfile);
    }

    if (location.state?.previousPage) {
      setSessionStorage(EDIT_PROFILES_PREVIOUS_PAGE, location.state.previousPage);
    }

    return () => {
      setNewProfileId(null);
      setDeletedProfile(null);
    };
  }, [appProvider, location.state]);

  useEffect(() => {
    if (isPageLoadAllowed) {
      trackPageView({
        pageName: PROFILE_PAGE_NAMES.EDIT_PROFILES,
      });
    }
  }, [isPageLoadAllowed, trackPageView]);

  return !isPageLoadAllowed ? (
    <Redirect to="/" />
  ) : (
    <ProfileScreen>
      <h1>{translate("profiles_edit")}</h1>
      <ProfileList isEditMode={true} newProfileId={newProfileId} deletedProfile={deletedProfile} />
      <OptikButton
        className="ghost-button done-button"
        label={translate("done")}
        onClickHandler={() => {
          const previousPage = getSessionStorage(EDIT_PROFILES_PREVIOUS_PAGE);
          removeSessionStorage(EDIT_PROFILES_PREVIOUS_PAGE);
          setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.DONE};${LINK_INFO.PROFILE}`);
          history.push(previousPage ?? PROFILE_SELECT.route);
        }}
      />
    </ProfileScreen>
  );
}

EditAllProfilesPage.propTypes = {
  profiles: PropTypes.array,
  appProvider: PropTypes.object,
  userProfile: PropTypes.object,
};

const mapStateToProps = ({ app }) => ({
  profiles: app.profileList,
  appProvider: app.provider,
  userProfile: app.userProfile,
});

export default connect(mapStateToProps)(EditAllProfilesPage);
