import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";
import PopUpOutline from "../PopUpOutline";
import Billboard from "../Billboard";
import OptikButton from "../OptikButton";
import { handleLogin } from "../../shared/utils/login";
import ImageButton from "../ImageButton";
import ScrollingChevron from "./assets/Scrolling_Chevron.svg";
import DeviceFrame from "./assets/device_frame.png";
import StarIcon from "./assets/star.svg";
import routes from "../../shared/constants/routes";
import constants from "../../shared/constants";
import { trapFocus } from "../../shared/utils/index";
import { getLocalStorage, setLocalStorage } from "../../shared/utils/localStorage";
import storageConstants from "../../shared/constants/storage";
import { useReducers } from "../../shared/hooks/useReducer";

const { BASICS } = routes;
const { REDUCER_TYPE, LOGIN_BRANDS } = constants;
const { HAS_LOGGED_IN_BEFORE, HAS_SEEN_ONBOARDING_TUTORIAL } = storageConstants;
const LeftChevron = `${process.env.PUBLIC_URL}/images/Left_Chevron.svg`;
const RightChevron = `${process.env.PUBLIC_URL}/images/Right_Chevron.svg`;
const ClearButton = `${process.env.PUBLIC_URL}/images/cross-icon.svg`;

const WelcomeModal = ({ updatePopUp }) => {
  const { provider: appProvider, userProfile, featureToggles } = useReducers(REDUCER_TYPE.APP);
  const isLoggedIn = userProfile?.isLoggedIn;
  const { t: translate } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(null);
  const [wallImgSrc, setWallImgSrc] = useState(null);
  const [slideCount, setSlideCount] = useState(0);
  const [backdropClassname, setBackdropClassname] = useState("");
  const onboardingData = appProvider.config.Onboarding_Tutorial_TTVPLUS;

  const history = useHistory();
  const modalFocus = useRef(null);
  const [modalElement, setModalElement] = useState([]);
  const onBillboardChange = (index) => {
    setCurrentSlide(onboardingData[index]);
    setSlideCount(index);
  };
  const currentHash = new URL(window.location.href);
  const domain = currentHash.hostname.includes("www.")
    ? currentHash.hostname.replace("www.", "")
    : currentHash.hostname;
  const hasLoggedInBefore = getLocalStorage(HAS_LOGGED_IN_BEFORE);
  const isLastSlide = currentSlide?.id === onboardingData.length;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    if (onboardingData && onboardingData.length) {
      setCurrentSlide(onboardingData[0]);
    }
    setLocalStorage(HAS_SEEN_ONBOARDING_TUTORIAL, true);

    return () => {
      document.body.style.overflow = "visible";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setModalElement(document.querySelector("#onboardingModal"));
    if (modalElement && modalFocus.current) {
      trapFocus(modalElement);
      modalFocus.current.focus();
    }
  }, [modalElement]);
  useEffect(() => {
    if (currentSlide) {
      let imgUrl = currentSlide.image;
      setWallImgSrc(imgUrl);

      // If the cookie message banner should be visible to the user, change the modal
      // backdrop styling to reveal the banner on the slide with the login button
      if (isLastSlide && !hasLoggedInBefore && !isLoggedIn) {
        setBackdropClassname("reveal-banner");
      }
    }
    return () => setWallImgSrc(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);
  const nextSlide = () => {
    setWallImgSrc(null);
    if (slideCount < onboardingData.length) {
      setSlideCount(slideCount + 1);
      setCurrentSlide(onboardingData[slideCount + 1]);
    }
  };
  const prevSlide = () => {
    setWallImgSrc(null);
    if (slideCount >= 1) {
      setSlideCount(slideCount - 1);
      setCurrentSlide(onboardingData[slideCount - 1]);
    }
  };
  const goToBasicPage = () => {
    history.push(BASICS.route);
  };
  return currentSlide ? (
    <React.Fragment>
      <div className={`welcome-modal-backdrop ${backdropClassname}`}></div>
      <div className="welcome-modal-container" id="onboardingModal">
        <div className="welcome-modal-popup-container">
          <PopUpOutline className="welcome-modal-popup">
            <div className="welcome-slide-container">
              <span className="close-modal">
                <ImageButton src={ClearButton} alt="" onClickHandler={() => updatePopUp()} ref={modalFocus} />
              </span>
              {currentSlide.image && (
                <div className="left-chevron">
                  {currentSlide.id > 1 && (
                    <ImageButton src={LeftChevron} alt="LeftChevron" onClickHandler={() => prevSlide(slideCount)} />
                  )}
                </div>
              )}
              {currentSlide.image && (
                <div className="welcome-item">
                  <div className="Welcome-item-image">
                    <div className="carousel-image">
                      {(currentSlide.id === 2 || currentSlide.id === 3) && (
                        <img
                          src={DeviceFrame}
                          alt=""
                          data-testid="thumbnail"
                          className="thumbnail-border"
                          width="100%"
                          height="100%"
                        />
                      )}
                      {wallImgSrc && (
                        <img
                          src={process.env.PUBLIC_URL + wallImgSrc}
                          alt=""
                          data-testid="thumbnail"
                          className="thumbnail"
                          width="100%"
                          height="100%"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {currentSlide.image && slideCount < onboardingData.length && (
                <div className="right-chevron">
                  <ImageButton src={RightChevron} alt="RightChevron" onClickHandler={() => nextSlide(slideCount)} />
                </div>
              )}
            </div>
            <div className="welcome-slide-title-container">
              <div>
                <div>
                  <div className={isLastSlide ? "welcome-popup-title large-title" : "welcome-popup-title"}>
                    {translate(currentSlide.title)}
                  </div>
                  {!currentSlide.image && (
                    <div className="welcome_bookmark_wrapper">
                      <span>{domain}</span>
                      <span>
                        <img src={StarIcon} alt="bookmark" />
                      </span>
                    </div>
                  )}
                  <div className={isLastSlide ? "welcome-popup-subtitle large-subtitle" : "welcome-popup-subtitle"}>
                    {translate(currentSlide.subTitle_1)} <br /> {translate(currentSlide.subTitle_2)}
                  </div>
                </div>
                <div>
                  {currentSlide.image && (
                    <Billboard
                      noOfItems={onboardingData.length}
                      onChange={onBillboardChange}
                      onBoardingPopup={true}
                      selectedDot={slideCount}
                    />
                  )}
                </div>
                {!currentSlide.image && (
                  <div className="carousel-login-button">
                    <OptikButton
                      buttonStyles="login-button"
                      label={!isLoggedIn ? translate("login") : translate("got_it")}
                      onClickHandler={() => {
                        updatePopUp();
                        if (!isLoggedIn)
                          handleLogin(appProvider, LOGIN_BRANDS.TELUS, featureToggles.isKoodoLoginEnabled);
                      }}
                      className="carousel-wall-button"
                    />
                    {!isLoggedIn && (
                      <h3 onClick={() => updatePopUp()} className="navigation-text guest-mode">
                        {translate("view_in_guest_mode")}
                        <ImageButton className="next-carousel" src={ScrollingChevron} alt="ScrollingChevron" />
                      </h3>
                    )}
                  </div>
                )}
                {!currentSlide.image && !isLoggedIn && (
                  <div className="carousel-text">
                    <span
                      className="navigation-text"
                      onClick={() => {
                        updatePopUp();
                        goToBasicPage();
                      }}
                    >
                      {translate("Welcome_Popup_Settings")} {translate("settings_basics")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </PopUpOutline>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};
export default WelcomeModal;
