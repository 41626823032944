import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./style.scss";
import RecordingDropdown from "../RecordingDropdown";
import OptikButton from "../OptikButton";
import { showRecordingSettingsPanel, showModalPopup, toggleSpinningLoaderAction } from "../../App/state/actions";
import { connect } from "react-redux";
import moment from "moment";
import ImageButton from "../ImageButton";
import {
  editRecordingAction,
  deleteRecordingAction,
  setRecordingAction,
  toggleSettingsPanelAction,
} from "../../pages/RecordingsPage/state/actions";
import { getProgramSchedules } from "../../shared/middleware/schedules";
import {
  editMRRecording,
  editCPVRRecording,
  getRecordingSystemType,
  getRecordablePrograms,
} from "../../shared/utils/recordingHelper";
import { getRegionalChannelNumber } from "../../shared/utils/epg";
import { sortShowtimes } from "../../shared/utils";
import constants from "../../shared/constants";
import recordingConstants from "../../shared/constants/recordingConstants";
import { ANALYTICS_EVENT_TYPES, MAPPED_CONTENT_TYPES } from "../../shared/constants/analytics";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import classNames from "classnames";

const { MODAL_TYPES } = constants;
const { RECORDING_PARAMS, RECORDING_SETTINGS, MR_RECORDING_STATUS, RECORDING_PACKAGES } = recordingConstants;

const editRecordingIcon = process.env.PUBLIC_URL + "/images/Pen_Icon.svg";
const cancelRecordingIcon = process.env.PUBLIC_URL + "/images/Recording_Cancel_Episode.svg";
const cancelSeriesRecordingIcon = process.env.PUBLIC_URL + "/images/Cancel_Series.svg";
const deleteRecordingIcon = process.env.PUBLIC_URL + "/images/Delete_Icon.svg";

const channelKey = RECORDING_SETTINGS.CHANNEL.KEY; // View ONLY for MR recordings, editable for CPVR recordings
const showTimeKey = RECORDING_SETTINGS.SHOW_TIME.KEY; // Editable for MR series recordings, view ONLY for CPVR series recordings
const showTypeKey = RECORDING_SETTINGS.SHOW_TYPE.KEY; // Editable for MR series recordings, editable for CPVR series recordings
const keepUntilKey = RECORDING_SETTINGS.KEEP_UNTIL.KEY; // Editable for MR recordings, not used for CPVR
const stopRecordingKey = RECORDING_SETTINGS.STOP_RECORDING.KEY; // Editable for MR recordings, not used for CPVR

/**
 * Remove all whitespace characters and non-visible characters
 * @param {String} str
 */
function removeWhitespaceAndNonVisibleChars(str) {
  // Check if str is a string
  if (typeof str !== "string") {
    return str; // Return str as is if it's not a string
  }
  return str.replace(/\s/g, "").replace(/\p{C}/gu, "");
}

/**
 * Display Recording setting panel
 * @param {Object} props
 */
const RecordingSettingsPanel = (props) => {
  let {
    appProvider,
    userProfile,
    showModalPopup,
    showRecordingSettingsPanel,
    recordingSettingsPanelData,
    editRecordingAction,
    deleteRecordingAction,
    setRecordingAction,
    displaySettingPanel,
    toggleSettingsPanelAction,
    toggleSpinningLoaderAction,
    subscribedChannels,
    spinnerToggle,
  } = props;

  const { t: translate } = useTranslation();
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const history = useHistory();
  const newRecordingSettings = useRef({});
  const [isSeriesEdit, setIsSeriesEdit] = useState(false);
  const [recordableSchedules, setRecordableSchedules] = useState(null);

  const recordingSystemType = useMemo(
    () => (userProfile?.isLoggedIn ? getRecordingSystemType(userProfile) : null),
    [userProfile]
  );
  const isMR = recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;
  const regionId = appProvider?.channelMapID;
  const recordingInfo = recordingSettingsPanelData?.recordingInfo ?? null;
  const recordingType = isSeriesEdit ? RECORDING_PARAMS.SERIES : recordingSettingsPanelData?.recordingType ?? null;

  const isRecordingScheduled = useMemo(() => {
    if (recordingInfo && recordingType) {
      if (isMR) {
        const isSeriesScheduled =
          recordingType === RECORDING_PARAMS.SERIES && recordingInfo.recordingSeriesScheduledCheck;
        const isEventScheduled =
          recordingType === RECORDING_PARAMS.EVENT && recordingInfo.recordingEventScheduledCheck ? true : false;
        const isStatusNotRecorded =
          !recordingInfo.isRecordingRecorded &&
          recordingInfo.status !== MR_RECORDING_STATUS.RECORDED &&
          !(moment().valueOf() > recordingInfo.programStartTime + recordingInfo.duration * 1000);

        return isSeriesScheduled || isEventScheduled || isStatusNotRecorded;
      } else {
        return true;
      }
    }
    return false;
  }, [isMR, recordingInfo, recordingType]);

  const currentRecordingItem = useMemo(() => {
    if (recordingInfo) {
      let currentRecordingItemData,
        isProgramOfSeries = false,
        recordGUID = null,
        recordingChannelId = null;
      if (isMR) {
        const currentTime = moment().valueOf();
        const seriesRecordingMR = recordingInfo.recordingSeriesCheck;
        const eventRecordingMR = recordingInfo.recordingEventScheduledCheck;

        /* Adding recordingChannelId from the recordingDefinition as the channelId of the recordingDefinition and that of the recordings
         inside recordingDefinitions might not always be same. So to avoid any inconsistency/discrepancy using the recordingDefinition's channelId
         */
        const isAssetRecordingNow = (assetRecordingInfo) => {
          return (
            assetRecordingInfo.programStartTime <= currentTime &&
            currentTime <= assetRecordingInfo.programStartTime + assetRecordingInfo.duration * 1000
          );
        };
        let assetRecordingInfo;
        if (seriesRecordingMR) {
          assetRecordingInfo =
            !eventRecordingMR &&
            seriesRecordingMR.recordings?.length > 0 &&
            seriesRecordingMR.recordings.find(
              (recording) => recording?.programStartTime === recordingInfo.airingStartTime
            );
          recordingChannelId = seriesRecordingMR.channelId;
          if (recordingType === RECORDING_PARAMS.SERIES) {
            recordGUID = seriesRecordingMR.recordingDefinitionId;
          } else if ((!eventRecordingMR && recordingType === RECORDING_PARAMS.EVENT) || recordingInfo.recordGUID) {
            recordGUID = assetRecordingInfo?.recordGUID;
            isProgramOfSeries = true;
          }
        } else if (eventRecordingMR) {
          assetRecordingInfo = eventRecordingMR.recordings[0];
          recordingChannelId = eventRecordingMR.channelId;
          recordGUID = eventRecordingMR.recordingDefinitionId;
        } else {
          isProgramOfSeries = recordingInfo.isProgramOfSeries;
          assetRecordingInfo = recordingInfo;
          recordGUID = isProgramOfSeries ? recordingInfo.recordGUID : recordingInfo.recordingDefinitionId;
          recordingChannelId = !isProgramOfSeries && recordingInfo.channelId;
        }
        currentRecordingItemData = {
          ...assetRecordingInfo,
          recordingChannelId,
          recordGUID,
          isProgramOfSeries,
          isRecordingNow: assetRecordingInfo?.isRecordingNow ?? isAssetRecordingNow(assetRecordingInfo),
        };
      } else {
        // CPVR logic
        if (recordingType === RECORDING_PARAMS.SERIES) {
          currentRecordingItemData = recordingInfo.seriesRecordingItem;
        } else {
          currentRecordingItemData = recordingInfo.eventRecordingItem;
          isProgramOfSeries = currentRecordingItemData?.metadata?.recordingSeriesId ? true : false;
        }
      }
      return currentRecordingItemData;
    }
  }, [isMR, recordingInfo, recordingType]);

  // Fetch schedules for the recording for editing channel and time.
  useEffect(() => {
    if (recordingSettingsPanelData?.isScheduleCallNeeded) {
      if (recordingInfo && recordingType) {
        let assetId;
        if (recordingType === RECORDING_PARAMS.EVENT) {
          assetId =
            recordingInfo.eventRecordingItem?.metadata?.extendedMetadata?.dlum?.uaId ??
            recordingInfo.assetToRecord?.metadata?.extendedMetadata?.dlum?.uaId;
        } else {
          assetId =
            recordingInfo.seriesRecordingItem?.metadata?.uaSeriesId ??
            recordingInfo.assetToRecord?.metadata?.extendedMetadata?.dlum?.series?.uaId;
        }
        if (assetId !== undefined) {
          let recordablePrograms = null;
          getProgramSchedules(appProvider, null, assetId, recordingType === RECORDING_PARAMS.SERIES, cancelTokenSource)
            .then((response) => {
              const currentTime = moment().valueOf();
              // We only want options where the program is airing now or in the future and the channel is subscribed & recordable
              recordablePrograms = getRecordablePrograms(response.containers, subscribedChannels);
              if (recordingType === RECORDING_PARAMS.EVENT) {
                recordablePrograms = recordablePrograms?.filter((schedule) => {
                  if (schedule.metadata) {
                    return currentTime < schedule.metadata.airingEndTime;
                  }

                  return false;
                });
              }
              if (!recordablePrograms?.length) {
                console.error("No recordable live schedules available for UA ID = ", assetId);
              }
            })
            .catch(() => {
              console.error("Failed to fetch live schedules for UA ID = ", assetId);
            })
            .finally(() => {
              setRecordableSchedules(recordablePrograms);
              toggleSpinningLoaderAction(false, null);
            });
        } else {
          console.error("No UA ID found for recording, could not fetch live schedules");
          toggleSpinningLoaderAction(false, null);
          setRecordableSchedules(null);
        }
      }
    } else {
      setRecordableSchedules(null);
    }
  }, [
    appProvider,
    cancelTokenSource,
    recordingInfo,
    recordingSettingsPanelData,
    recordingType,
    subscribedChannels,
    toggleSpinningLoaderAction,
  ]);

  const programSchedules = useMemo(() => {
    let schedules = recordingSettingsPanelData?.allSchedules || recordableSchedules || [];
    if (recordingType === RECORDING_PARAMS.SERIES && schedules.length > 0) {
      // Only want schedules with unique channels for series channel editing
      const uniqueChannelMap = new Map();
      schedules = schedules.filter((schedule) => {
        if (schedule.channel?.channelId && !uniqueChannelMap.has(schedule.channel.channelId)) {
          uniqueChannelMap.set(schedule.channel.channelId, true);
          return true;
        }
        return false;
      });
    }
    return schedules;
  }, [recordingSettingsPanelData, recordingType, recordableSchedules]);

  // Refer to getChannelOptions() in the same file.
  const channelOptions = useMemo(() => {
    if (recordingInfo && programSchedules) {
      return getChannelOptions(
        recordingInfo,
        recordingType,
        isMR,
        programSchedules,
        regionId,
        currentRecordingItem?.isProgramOfSeries
      );
    }
    return [];
  }, [isMR, programSchedules, recordingInfo, recordingType, regionId, currentRecordingItem]);

  const closeSettingsPanel = useCallback(() => {
    showRecordingSettingsPanel();
    toggleSettingsPanelAction(false);
    newRecordingSettings.current = {};
    toggleSpinningLoaderAction(false, null);
    setIsSeriesEdit(false);
  }, [showRecordingSettingsPanel, toggleSettingsPanelAction, toggleSpinningLoaderAction]);

  const cancelCTAText = useCallback(() => {
    if (recordingInfo) {
      // Only need to check for MR recorded recordings.
      // Recorded CPVR recordings (Series or Event) don't have any editable settings so this component should not be rendered
      // On-now recordings will have cancel CTA text instead of the delete CTA text.

      let isMRRecordingRecorded = false;
      if (isMR && currentRecordingItem) {
        const currentTime = moment().valueOf();
        const recordingEndTime = currentRecordingItem.programStartTime + currentRecordingItem.duration * 1000;
        isMRRecordingRecorded =
          currentRecordingItem.status === MR_RECORDING_STATUS.RECORDED ||
          currentRecordingItem.isRecordingRecorded ||
          (currentRecordingItem.openRecordedEditPanel &&
            !(
              currentRecordingItem.isRecordingNow ||
              (currentRecordingItem.programStartTime <= currentTime && currentTime <= recordingEndTime)
            ));
      }

      if (recordingType === RECORDING_PARAMS.SERIES) {
        return isMRRecordingRecorded ? translate("recordings_delete_series") : translate("cancel_series_rec");
      } else {
        if (isMRRecordingRecorded) {
          return translate(RECORDING_PARAMS.DELETE_RECORDING);
        } else {
          const isMREpisode =
            isMR && ((recordingInfo.episode && recordingInfo.season) || recordingInfo.recordingSeriesCheck);
          const isCPVREpisode = !isMR && recordingInfo.eventRecordingItem?.metadata?.recordingSeriesId;
          if (isMREpisode || isCPVREpisode) {
            return translate("cancel_episode_rec");
          } else {
            return translate(RECORDING_PARAMS.CANCEL_RECORDING);
          }
        }
      }
    } else {
      return "";
    }
  }, [currentRecordingItem, isMR, recordingInfo, recordingType, translate]);

  const cancelRecordingClickHandler = useCallback(() => {
    let programDetails;
    if (isMR) {
      if (recordingType === RECORDING_PARAMS.EVENT) {
        programDetails = currentRecordingItem;
      }
    } else {
      if (recordingType === RECORDING_PARAMS.EVENT) {
        if (recordingSettingsPanelData?.isPVRManager) {
          programDetails = currentRecordingItem;
        } else {
          programDetails = recordingInfo?.assetToRecord;
        }
      }
    }

    let isRecordingNow = false;
    if (recordingInfo) {
      const currentTime = moment().valueOf();
      const recordingEndTime = recordingInfo.programStartTime + recordingInfo.duration * 1000;
      isRecordingNow =
        recordingInfo.isRecordingNow ||
        (recordingInfo.status !== MR_RECORDING_STATUS.RECORDED &&
          recordingInfo.programStartTime <= currentTime &&
          currentTime <= recordingEndTime);
    }

    const isRecordingRecorded = !isRecordingNow && (!isRecordingScheduled || recordingInfo.openRecordedEditPanel);
    const modalContent = {
      recordGUID: currentRecordingItem?.recordGUID,
      recordingInfo: recordingInfo,
      programDetails: programDetails,
      recordingType: recordingType,
      isProgramOfSeries: currentRecordingItem?.isProgramOfSeries,
      isRecordingRecorded,
      isRecordingNow,
      isMR: isMR,
      closeRecordingSettings: closeSettingsPanel,
    };

    showModalPopup(MODAL_TYPES.RECORDING, modalContent);
  }, [
    closeSettingsPanel,
    currentRecordingItem,
    isMR,
    isRecordingScheduled,
    recordingInfo,
    recordingSettingsPanelData,
    recordingType,
    showModalPopup,
  ]);

  // Method to show and handle edit and cancel/delete options.
  const getRecordingActions = useCallback(() => {
    let shouldShowCancelCTA = false,
      shouldShowEditSeriesRecordingCTA = false;
    if (recordingInfo) {
      if (recordingType === RECORDING_PARAMS.SERIES) {
        if (isMR) {
          // Hide cancel CTA if any of the episodes of the series is "Recording now"
          shouldShowCancelCTA =
            recordingInfo.recordingSeriesCheck || recordingInfo.recordingDefinitionStatus ? true : false;
        } else {
          shouldShowCancelCTA = true;
        }
      } else {
        if (isMR) {
          const scheduledEventRecordingDataExists = recordingInfo.recordingEventScheduledCheck ? true : false;
          const scheduledSeriesRecordingDataExists = recordingInfo.recordingSeriesScheduledCheck ? true : false;
          shouldShowCancelCTA =
            scheduledEventRecordingDataExists ||
            scheduledSeriesRecordingDataExists ||
            currentRecordingItem?.isProgramOfSeries ||
            recordingInfo.recordingDefinitionStatus ||
            recordingInfo.recordGUID;

          const seriesIdExists = recordingInfo.mediaRoom?.seriesId ? true : false;
          const seriesRecordingDataExists = recordingInfo.recordingSeriesCheck ? true : false;
          shouldShowEditSeriesRecordingCTA =
            seriesIdExists && seriesRecordingDataExists && !scheduledEventRecordingDataExists;
        } else {
          shouldShowCancelCTA = true;
          shouldShowEditSeriesRecordingCTA =
            !recordingSettingsPanelData?.isPVRManager &&
            recordingInfo.eventRecordingItem?.recordingSeriesId &&
            recordingInfo.seriesRecordingItem
              ? true
              : false;
        }
      }
    }
    const cancelText = cancelCTAText();
    return (
      (shouldShowCancelCTA || shouldShowEditSeriesRecordingCTA) && (
        <div className="recording-setting-button-wrapper">
          {shouldShowCancelCTA ? (
            <ImageButton
              alt={cancelText}
              src={getCancelCTAImage(currentRecordingItem, isMR, recordingType)}
              buttonName={cancelText}
              className="recording-image-button"
              onClickHandler={cancelRecordingClickHandler}
            />
          ) : (
            <></>
          )}
          {shouldShowEditSeriesRecordingCTA ? (
            <ImageButton
              alt={translate("edit_series_rec")}
              src={editRecordingIcon}
              buttonName={translate("edit_series_rec")}
              className="recording-image-button"
              onClickHandler={() => setIsSeriesEdit(true)}
            />
          ) : (
            <></>
          )}
        </div>
      )
    );
  }, [
    cancelCTAText,
    cancelRecordingClickHandler,
    currentRecordingItem,
    isMR,
    recordingInfo,
    recordingSettingsPanelData,
    recordingType,
    translate,
  ]);

  const getRecordingDropdownData = useCallback(() => {
    let dropdownData;
    // Only need to check for MR recorded recordings.
    // Recorded CPVR recordings (Series or Event) don't have any editable settings so this component should not be rendered
    // `Recorded` and `recording-now` recordings will be having the recorded edit panel
    let showMRRecordedSettings = false;
    if (isMR && currentRecordingItem) {
      const currentTime = moment().valueOf();
      showMRRecordedSettings =
        currentRecordingItem.isRecordingRecorded ||
        currentRecordingItem.status === MR_RECORDING_STATUS.RECORDED ||
        currentRecordingItem.isRecordingNow ||
        currentTime >= currentRecordingItem.programStartTime ||
        currentRecordingItem.openRecordedEditPanel;
    }

    if (showMRRecordedSettings) {
      // This condition satisfies on the PVR manager if we try to edit a recorded recording.
      dropdownData = [
        {
          key: keepUntilKey,
          title: RECORDING_SETTINGS.KEEP_UNTIL.TITLE_KEY,
          data: RECORDING_SETTINGS.KEEP_UNTIL.OPTIONS,
        },
      ];
      if (currentRecordingItem.isRecordingNow) {
        dropdownData = dropdownData.concat([
          {
            key: stopRecordingKey,
            title: RECORDING_SETTINGS.STOP_RECORDING.TITLE_KEY,
            data: RECORDING_SETTINGS.STOP_RECORDING.OPTIONS,
          },
        ]);
      }
    } else if (recordingType === RECORDING_PARAMS.SERIES) {
      dropdownData = [
        { key: channelKey, title: RECORDING_SETTINGS.CHANNEL.TITLE_KEY_SERIES, data: channelOptions },
        {
          key: showTimeKey,
          title: RECORDING_SETTINGS.SHOW_TIME.TITLE_KEY,
          data: isMR ? RECORDING_SETTINGS.SHOW_TIME.OPTIONS : [RECORDING_SETTINGS.SHOW_TIME.OPTIONS[0]],
        },
        {
          key: showTypeKey,
          title: RECORDING_SETTINGS.SHOW_TYPE.TITLE_KEY,
          data: isMR ? RECORDING_SETTINGS.SHOW_TYPE.MR_OPTIONS : RECORDING_SETTINGS.SHOW_TYPE.CPVR_OPTIONS,
        },
      ];

      if (isMR) {
        dropdownData = dropdownData.concat([
          {
            key: keepUntilKey,
            title: RECORDING_SETTINGS.KEEP_UNTIL.TITLE_KEY,
            data: RECORDING_SETTINGS.KEEP_UNTIL.OPTIONS,
          },
          {
            key: stopRecordingKey,
            title: RECORDING_SETTINGS.STOP_RECORDING.TITLE_KEY,
            data: RECORDING_SETTINGS.STOP_RECORDING.OPTIONS,
          },
        ]);
      }
    } else {
      dropdownData = [{ key: channelKey, title: RECORDING_SETTINGS.CHANNEL.TITLE_KEY, data: channelOptions }];

      if (isMR) {
        dropdownData = dropdownData.concat([
          {
            key: keepUntilKey,
            title: RECORDING_SETTINGS.KEEP_UNTIL.TITLE_KEY,
            data: RECORDING_SETTINGS.KEEP_UNTIL.OPTIONS,
          },
          {
            key: stopRecordingKey,
            title: RECORDING_SETTINGS.STOP_RECORDING.TITLE_KEY,
            data: RECORDING_SETTINGS.STOP_RECORDING.OPTIONS,
          },
        ]);
      }
    }

    return dropdownData;
  }, [channelOptions, currentRecordingItem, isMR, recordingType]);

  const recordingDropdownData = useMemo(() => {
    if (channelOptions) {
      return getRecordingDropdownData();
    }
  }, [channelOptions, getRecordingDropdownData]);

  /*Closing Recording edit side panel when navigated from one page to another*/
  useEffect(() => {
    const unListen = history.listen(() => {
      closeSettingsPanel();
    });
    return unListen;
  }, [history, closeSettingsPanel]);

  const linkSelectHandler = (selectedItem, key) => {
    let selectedData = newRecordingSettings.current;
    if (!newRecordingSettings.current[key]) {
      newRecordingSettings.current = { ...selectedData, [key]: selectedItem };
    } else {
      selectedData[key] = selectedItem;
      newRecordingSettings.current = { ...selectedData };
    }
  };

  const getDefaultSelectedValue = (dropdownData, key) => {
    let defaultValue;
    if (currentRecordingItem && recordingInfo) {
      if (key === keepUntilKey) {
        defaultValue = currentRecordingItem.isAutoDeletionEnabled.toString();
      } else if (key === stopRecordingKey) {
        // For postPadding sometimes the value is not the value that we have in the 'stopRecordingKey' data
        // Hence in that case we are using the default stopRecordingKey data value : 0
        const postPaddingCheck = dropdownData.some((res) => res.value === currentRecordingItem.postPadding);
        defaultValue = postPaddingCheck ? currentRecordingItem.postPadding : null;
      } else if (key === channelKey) {
        if (isMR && currentRecordingItem.isProgramOfSeries) {
          const channelNumber = recordingInfo.channelNumber;
          const channelName = recordingInfo.metadata?.channelName;
          const programTime =
            recordingInfo.airingStartTime || recordingInfo.utcStartTime || recordingInfo.programStartTime;
          let airingTime;

          if (programTime) {
            airingTime = moment(programTime).format("[schedule_date_format], LT");
          }

          if (channelNumber) {
            defaultValue = `${channelNumber}`;

            if (channelName) {
              defaultValue = defaultValue + ` - ${channelName}`;
            }

            if (recordingType === RECORDING_PARAMS.EVENT) {
              // We need the airing time for event recordings, so defaulting to null if its missing
              defaultValue = airingTime ? airingTime + ` \n${defaultValue}` : null;
            }
          }
        } else {
          // Try to find the schedule that matches our scheduled recording
          const matchingSchedule = programSchedules?.find((schedule) => {
            if (
              schedule.metadata &&
              schedule.channel &&
              ((isMR && currentRecordingItem.recordingChannelId) || (!isMR && currentRecordingItem.metadata))
            ) {
              if (recordingType === RECORDING_PARAMS.SERIES) {
                return isMR
                  ? schedule.metadata.extendedMetadata?.epg?.mediaroom?.channelId ===
                      currentRecordingItem.recordingChannelId
                  : schedule.channel.channelId === currentRecordingItem.metadata.channelId &&
                      schedule.metadata.extendedMetadata?.dlum?.uaGroupId === currentRecordingItem.metadata.uaSeriesId;
              } else {
                if (currentRecordingItem.channel) {
                  return (
                    schedule.metadata.programId === currentRecordingItem.metadata.programId &&
                    schedule.channel.channelId === currentRecordingItem.channel.channelId &&
                    schedule.metadata.airingStartTime === currentRecordingItem.metadata.programStartTime
                  );
                } else if (isMR) {
                  return (
                    schedule.metadata.extendedMetadata?.epg?.mediaroom?.channelId ===
                      currentRecordingItem.recordingChannelId &&
                    schedule.metadata.airingStartTime ===
                      (currentRecordingItem.recordings?.[0]?.programStartTime ?? currentRecordingItem.programStartTime)
                  );
                }
              }
            }
            return false;
          });

          const channelOptionString = createChannelListString(matchingSchedule, regionId, recordingType);
          defaultValue = channelOptionString !== "" ? channelOptionString : null;
        }
      } else if (key === showTimeKey) {
        defaultValue = isMR ? currentRecordingItem.schedulingTime : RECORDING_SETTINGS.SHOW_TIME.OPTIONS[0].value;
      } else if (key === showTypeKey) {
        defaultValue = isMR ? currentRecordingItem.episodeScope : currentRecordingItem.metadata?.episodeScope;
      }
    }
    return defaultValue !== undefined && defaultValue !== null
      ? dropdownData.findIndex((x) => {
          const xValue = removeWhitespaceAndNonVisibleChars(x.value);
          const defaultValueCleaned = removeWhitespaceAndNonVisibleChars(defaultValue);
          return xValue === defaultValueCleaned;
        })
      : 0;
  };

  const createRecordingDropdown = (dropdownData, key, linkSelectHandler, hasNewline) => {
    let recordingInfoAiringTime;
    if (isMR && recordingInfo) {
      const programTime = recordingInfo.airingStartTime || recordingInfo.utcStartTime || recordingInfo.programStartTime;
      if (programTime) {
        recordingInfoAiringTime = moment(programTime).format("LT");
      }
    }

    return (
      <RecordingDropdown
        isMR={isMR}
        recordingDropdownData={dropdownData}
        linkSelectHandler={linkSelectHandler}
        dropdownKey={key}
        defaultSelectedIndex={getDefaultSelectedValue(dropdownData, key)}
        recordingInfoAiringTime={recordingInfoAiringTime}
        hasNewline={hasNewline}
      />
    );
  };

  /**
   * Gets the toolSelection based on values from dropdown list
   * @return {String} a toolSelection string with format: 'key:{selection},key:{selection}...'
   */
  const getToolSelections = () => {
    const toolSelections = [];
    recordingDropdownData.forEach((item) => {
      if (item) {
        const { key, title, data } = item;
        if (newRecordingSettings.current[key]) {
          toolSelections.push(`${title}:${newRecordingSettings.current[key].label}`);
        } else {
          const selectedData = data[getDefaultSelectedValue(data, key)];
          toolSelections.push(
            `${title}:${selectedData.isTranslated ? selectedData.label : translate(selectedData.label)}`
          );
        }
      }
    });

    return toolSelections.join(",");
  };

  const submitRecordingHandler = () => {
    const updatedRecordingData = {};
    if (newRecordingSettings.current && currentRecordingItem) {
      if (isMR) {
        const recordingPVRData =
          (currentRecordingItem.recordings?.length > 0 &&
            currentRecordingItem.recordings.find((recording) => recording.duration)) ||
          (recordingInfo?.recordGUID ? recordingInfo : null);
        if (recordingInfo) {
          updatedRecordingData.recordingInfo = recordingInfo;
          updatedRecordingData.airingStartTime =
            newRecordingSettings.current[channelKey] && recordingType === RECORDING_PARAMS.EVENT
              ? newRecordingSettings.current[channelKey].item?.metadata?.airingStartTime
              : recordingInfo.airingStartTime
              ? recordingInfo.airingStartTime
              : recordingInfo.utcStartTime ?? recordingInfo.programStartTime;
          updatedRecordingData.title = recordingInfo.title;
          updatedRecordingData.channelNumber = newRecordingSettings.current[channelKey]
            ? getRegionalChannelNumber(newRecordingSettings.current[channelKey].item?.channel, regionId)
            : currentRecordingItem.channelNumber ?? recordingInfo.channelNumber;
          if (recordingInfo.mediaRoom) {
            updatedRecordingData.channelId = recordingInfo.mediaRoom.channelId;
            updatedRecordingData.seriesId = recordingInfo.mediaRoom.seriesId;
          } else if (!currentRecordingItem.isProgramOfSeries) {
            updatedRecordingData.channelId = recordingInfo.channelId;
            if (recordingPVRData) {
              updatedRecordingData.seriesId = recordingPVRData.seriesId;
            }
          }
        }

        if (newRecordingSettings.current[channelKey]) {
          updatedRecordingData.channelId =
            newRecordingSettings.current[channelKey].item?.metadata?.extendedMetadata?.epg?.mediaroom?.channelId;
        }

        updatedRecordingData.duration = currentRecordingItem.duration
          ? currentRecordingItem.duration
          : recordingPVRData
          ? recordingPVRData.duration
          : recordingInfo.duration;

        updatedRecordingData.episodeScope = newRecordingSettings.current[showTypeKey]
          ? newRecordingSettings.current[showTypeKey].value
          : currentRecordingItem.episodeScope ?? "ALL";

        // For MR, we need a flag specifically to know when trying to switch series recording episode scope from ALL to FirstRun to support TCDWC-1519
        updatedRecordingData.updatedToFirstRun =
          newRecordingSettings.current[showTypeKey]?.value === RECORDING_SETTINGS.SHOW_TYPE.MR_OPTIONS[1].value &&
          newRecordingSettings.current[showTypeKey].value !== currentRecordingItem.episodeScope;

        updatedRecordingData.schedulingTime = newRecordingSettings.current[showTimeKey]
          ? newRecordingSettings.current[showTimeKey].value
          : currentRecordingItem.schedulingTime ?? 0;

        updatedRecordingData.isAutoDeletionEnabled =
          newRecordingSettings.current[keepUntilKey]?.value === "false"
            ? false
            : newRecordingSettings.current[keepUntilKey]?.value === "true"
            ? true
            : currentRecordingItem.isAutoDeletionEnabled ?? true;

        updatedRecordingData.postPadding = newRecordingSettings.current[stopRecordingKey]
          ? newRecordingSettings.current[stopRecordingKey].value
          : currentRecordingItem.postPadding ?? 0;

        updatedRecordingData.recordGUID = currentRecordingItem.recordGUID;

        updatedRecordingData.isProgramOfSeries = currentRecordingItem.isProgramOfSeries;

        if (Object.keys(newRecordingSettings.current).length > 0) {
          editMRRecording(
            currentRecordingItem.isProgramOfSeries ? RECORDING_PARAMS.SERIES : recordingType,
            appProvider,
            updatedRecordingData,
            editRecordingAction
          );
        }
        closeSettingsPanel();
      } else {
        const actionData = {};
        // Channel & time change is the only editable setting for scheduled single event CPVR recordings.
        // We should not allow any editing for recorded CPVR recordings (this component shouldn't be rendered at all).
        if (recordingType === RECORDING_PARAMS.EVENT) {
          const newChannelProgramItem = newRecordingSettings.current[channelKey]?.item;
          // AVS system currently requires us to delete and create a new recording when "editing" the channel & time.
          if (
            currentRecordingItem.metadata &&
            newChannelProgramItem &&
            newChannelProgramItem.channel &&
            newChannelProgramItem.metadata
          ) {
            const newChannelId = newChannelProgramItem.channel.channelId;
            const newProgramId = newChannelProgramItem.metadata.programId;
            const newProgramAiringTime = newChannelProgramItem.metadata.airingStartTime;

            if (newChannelId && newProgramId && newProgramAiringTime) {
              const isChannelUpdated =
                currentRecordingItem.channel?.channelId && currentRecordingItem.channel.channelId !== newChannelId;
              const isTimeUpdated =
                currentRecordingItem.metadata.programStartTime &&
                currentRecordingItem.metadata.programStartTime !== newProgramAiringTime;
              if (isChannelUpdated || isTimeUpdated) {
                // These two fields are used to delete the existing recording. We only set them within this if block to prevent us from deleting
                // the previous recording when we don't have the data necessary to create a new one
                updatedRecordingData.currentRecordId = currentRecordingItem.metadata.contentId;
                updatedRecordingData.currentRecordStartDeltaTime = currentRecordingItem.metadata.startDeltaTime;

                // assetToRecord is used to create a new recording
                updatedRecordingData.assetToRecord = {
                  id: recordingInfo.assetToRecord?.id ?? recordingInfo.assetToRecord?.metadata?.contentId,
                  channel: {
                    channelId: newChannelId,
                  },
                  metadata: {
                    programId: newProgramId,
                    airingStartTime: newProgramAiringTime,
                    contentId: recordingInfo.assetToRecord?.metadata?.contentId,
                  },
                };

                actionData.deleteRecordingAction = deleteRecordingAction;
                actionData.setRecordingAction = setRecordingAction;
              }
            }
          }
        } else {
          // Channel & show type (first run & reruns vs first run) only
          let newChannelId, newShowTypeValue;
          if (currentRecordingItem.metadata) {
            newChannelId = newRecordingSettings.current[channelKey]?.item?.channel?.channelId;
            newShowTypeValue = newRecordingSettings.current[showTypeKey]?.value;

            const isChannelUpdated =
              newChannelId &&
              currentRecordingItem.metadata.channelId &&
              currentRecordingItem.metadata.channelId !== newChannelId;
            const isShowTypeUpdated =
              newShowTypeValue &&
              currentRecordingItem.metadata.episodeScope &&
              currentRecordingItem.metadata.episodeScope !== newShowTypeValue;
            if (isChannelUpdated || isShowTypeUpdated) {
              updatedRecordingData.currentRecordId = currentRecordingItem.metadata.recordingSeriesId;
              updatedRecordingData.assetToRecord = {
                id: recordingInfo.assetToRecord?.id ?? recordingInfo.assetToRecord?.metadata?.contentId,
                channel: {
                  channelId: newChannelId ?? currentRecordingItem.metadata.channelId,
                },
                metadata: {
                  seriesId: currentRecordingItem.metadata.programSeriesId,
                  contentId: recordingInfo.assetToRecord?.metadata?.contentId,
                },
                episodeScope: newShowTypeValue ?? currentRecordingItem.metadata.episodeScope,
                onlyEpisodeScopeUpdated: !isChannelUpdated && isShowTypeUpdated,
              };

              actionData.deleteRecordingAction = deleteRecordingAction;
              actionData.setRecordingAction = setRecordingAction;
            }
          }
        }

        if (Object.keys(updatedRecordingData).length > 0) {
          editCPVRRecording(appProvider, recordingType, updatedRecordingData, actionData, isRecordingScheduled);
        }

        closeSettingsPanel();
      }
      const isSeriesEvent = recordingType === RECORDING_PARAMS.SERIES;
      recordingSettingsPanelData?.trackRecordingUpdateEvent &&
        recordingSettingsPanelData.trackRecordingUpdateEvent({
          isSeriesEvent,
          isItemLive: isRecordingScheduled,
          recordingItem: currentRecordingItem,
          toolSelections: getToolSelections(),
          mappedContentType: isRecordingScheduled
            ? MAPPED_CONTENT_TYPES.LIVE
            : isMR
            ? MAPPED_CONTENT_TYPES.LPVR
            : MAPPED_CONTENT_TYPES.CPVR,
          analyticsEventType: isSeriesEvent
            ? ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE
            : ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_COMPLETE,
        });
    }
  };

  return displaySettingPanel && recordingDropdownData ? (
    <div className="recording-setting-backdrop-overlay">
      <div className="recording-setting-panel">
        <div className="recording-setting-panel-title">
          {translate("record_settings")}
          {!spinnerToggle &&
            (recordingType === RECORDING_PARAMS.SERIES ? <span>{translate("series_text")}</span> : null)}
        </div>
        {recordingInfo && !spinnerToggle && (
          <>
            {recordingDropdownData.map((item) => {
              let key, title, data;
              if (item) {
                key = item.key;
                title = item.title;
                data = item.data;
              }
              // Check if any data item in the current dropdown has a newline character
              const hasNewline = data.some((itemData) => itemData.hasNewline);
              return (
                <div className="recording-dropdown-container" key={key}>
                  <div className="recording-dropdown-title">{translate(title)}</div>
                  <div className={classNames("recording-dropdown", { "has-newline": hasNewline })}>
                    {createRecordingDropdown(data, key, linkSelectHandler, hasNewline)}
                  </div>
                </div>
              );
            })}
            {getRecordingActions()}
            <div className="recording-confirmation-button-wrapper">
              <OptikButton onClickHandler={closeSettingsPanel} label={translate("cancel")} className={"cancel"} />
              <OptikButton label={translate("apply")} className={"submit"} onClickHandler={submitRecordingHandler} />
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

function mapStateToProps({ app, recording: { displaySettingPanel } }) {
  return {
    recordingSettingsPanelData: app.recordingSettingsPanelData,
    appProvider: app.provider,
    userProfile: app.userProfile,
    subscribedChannels: app.subscribedChannels,
    displaySettingPanel,
    spinnerToggle: app.spinningLoaderParams?.toggle,
  };
}

const mapDispatchToProps = {
  showRecordingSettingsPanel,
  showModalPopup,
  editRecordingAction,
  deleteRecordingAction,
  setRecordingAction,
  toggleSettingsPanelAction,
  toggleSpinningLoaderAction,
};

RecordingSettingsPanel.propTypes = {
  recordingSettingsPanelData: PropTypes.object,
  appProvider: PropTypes.object.isRequired,
  showModalPopup: PropTypes.func.isRequired,
  editRecordingAction: PropTypes.func.isRequired,
  deleteRecordingAction: PropTypes.func.isRequired,
  setRecordingAction: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordingSettingsPanel);

/**
 * Builds out the channel options list
 *
 * @param {Object} recordingInfo Recording info object. Contents depend on the recording system type
 * @param {String} recordingType The type of recording. Can be "event" or "series"
 * @param {Boolean} isMR Flag indicating if the recording system is MediaRoom, otherwise CPVR
 * @param {Object[]} programSchedules Array of program schedule objects. These objects contain program and channel metadata.
 * @param {Integer} regionId User's region ID
 * @param {Boolean} isProgramOfSeries True if concerned recording is an episode inside a series recording
 * @returns {Object[]} Array of objects used to create list items in the RecordingDropdown component, empty array otherwise
 */
function getChannelOptions(recordingInfo, recordingType, isMR, programSchedules, regionId, isProgramOfSeries) {
  let channelOptionsList = [];
  if (recordingInfo && recordingType) {
    if (isMR && (isProgramOfSeries || !(programSchedules?.length > 0))) {
      const channelNumber = recordingInfo.channelNumber;
      const channelName = recordingInfo.channelName || recordingInfo.metadata?.channelName;
      const programTime = recordingInfo.airingStartTime || recordingInfo.utcStartTime || recordingInfo.programStartTime;
      let airingTime;

      if (programTime) {
        airingTime = moment(programTime).format("[schedule_date_format], LT");
      }

      let channelOptionString = "";
      if (channelNumber) {
        channelOptionString = `${channelNumber}`;

        if (channelName) {
          channelOptionString = channelOptionString + ` - ${channelName}`;
        }

        if (recordingType === RECORDING_PARAMS.EVENT) {
          // We need the airing time for event recordings, so defaulting to null if its missing
          channelOptionString = airingTime ? `${airingTime}  \n${channelOptionString}` : "";
        }
      }
      const hasNewline = channelOptionString.includes("\n");
      if (channelOptionString !== "") {
        channelOptionsList.push({
          label: channelOptionString,
          value: channelOptionString,
          item: channelOptionString,
          isTranslated: true,
          hasNewline,
        });
      }
    } else {
      // Sort programSchedules using sortShowtimes (recent -> future)
      sortShowtimes(programSchedules, regionId);
      // Build the channel list options
      channelOptionsList = programSchedules
        .map((schedule) => {
          const channelOptionString = createChannelListString(schedule, regionId, recordingType);
          const hasNewline = channelOptionString.includes("\n");
          if (channelOptionString !== "") {
            return {
              label: channelOptionString,
              value: channelOptionString,
              item: schedule,
              isTranslated: true,
              hasNewline: hasNewline,
            };
          }

          return null;
        })
        .filter((channelOption) => channelOption !== null);
    }
  }

  return channelOptionsList;
}

/**
 * Creates a channel & program specific string for the channel option list
 *
 * @param {Object} schedule Program metadata used to determine channel info and airing time
 * @param {Integer} regionId User's region ID, used to determine the appropriate regional channel number
 * @param {String} recordingType The type of recording. Can be "event" or "series"
 * @returns {String}
 */
function createChannelListString(schedule, regionId, recordingType) {
  let channelOptionString = "";
  if (schedule) {
    let channelNumber, channelNames, formattedAiringTime;
    if (schedule.channel) {
      channelNumber = getRegionalChannelNumber(schedule.channel, regionId);
      channelNames = schedule.channel.channelName;
    }

    if (schedule.metadata?.airingStartTime) {
      formattedAiringTime = moment(schedule.metadata.airingStartTime).format("[schedule_date_format], LT");
    }

    if (channelNumber) {
      channelOptionString = `${channelNumber}`;

      if (channelNames) {
        channelOptionString = channelOptionString + ` - ${channelNames}`;
      }

      if (recordingType === RECORDING_PARAMS.EVENT) {
        // We need the airing time for event recordings, so defaulting to empty string if its missing
        channelOptionString = formattedAiringTime ? `${formattedAiringTime}  \n${channelOptionString}` : "";
      }
    }
  }

  return channelOptionString;
}

/**
 * Determines the appropriate cancel CTA image to use
 * @param {Object} recordingInfo Current recording object in question. It can be event/episode part of series/series info.
 * @param {Boolean} isMR Flag indicating if the recording system is MediaRoom, otherwise CPVR
 * @param {String} recordingType The type of recording. Can be "event" or "series"
 * @returns {String} The image source to use for the cancel CTA image button
 */
function getCancelCTAImage(recordingInfo, isMR, recordingType) {
  let imageSrc = "";
  if (recordingInfo) {
    // Only need to check for MR recorded recordings.
    // Recorded CPVR recordings (Series or Event) don't have any editable settings so this component should not be rendered
    // On-now recordings will have cancel CTA and not the delete CTA
    let isMRRecordingRecorded = false;
    if (isMR) {
      const currentTime = moment().valueOf();
      const recordingEndTime = recordingInfo.programStartTime + recordingInfo.duration * 1000;
      isMRRecordingRecorded =
        recordingInfo.status === MR_RECORDING_STATUS.RECORDED ||
        recordingInfo.status === MR_RECORDING_STATUS.CANCELLED ||
        recordingInfo.isRecordingRecorded ||
        (recordingInfo.openRecordedEditPanel &&
          !(
            recordingInfo.isRecordingNow ||
            (recordingInfo.programStartTime <= currentTime && currentTime <= recordingEndTime)
          ));
    }

    if (isMRRecordingRecorded) {
      imageSrc = deleteRecordingIcon;
    } else if (recordingType === RECORDING_PARAMS.SERIES) {
      imageSrc = cancelSeriesRecordingIcon;
    } else {
      imageSrc = cancelRecordingIcon;
    }
  }

  return imageSrc;
}
