import { LOAD_HUBS, UPDATE_ACTIVE_INDEX } from "./actions";

const initialState = {
  hubs: [],
  selectedHubIndex: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_HUBS:
      return { ...state, hubs: action.hubs };
    case UPDATE_ACTIVE_INDEX:
      return { ...state, selectedHubIndex: action.activeIndex };
    default:
      return state;
  }
};
