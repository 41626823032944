export const MIDDLEWARE_PLATFORM = "opus";

export const ADOBE_SDK = "aep_web_sdk";

export const ANALYTICS_STORAGE_KEYS = Object.freeze({
  PREVIOUS_PAGE_NAME: "previousPageName",
  FINDING_METHOD: "findingMethod",
  SESSION_ID: "sessionId",
  LINK: "link", // link format: "linkName;linkPosition;channelName|aaVideoPlayType"
  SEARCH_TERM: "",
});

export const ANALYTICS_EVENT_TYPES = Object.freeze({
  REWIND: "rewind",
  FAST_FORWARD: "fastForward",
  NAVIGATION: "navigation",
  SEARCH: "search",
  GUIDE_SEARCH: "guideSearch",
  SEARCH_REFINEMENT: "searchRefinement",
  SWIMLANE_SEARCH: "swimlane_refinement",
  PAGE_VIEW: "pageLoaded",
  SETTINGS_INTERACT: "settingInteract",
  ERROR: "errorEvent",
  LOGIN_START: "loginStart",
  LOGIN_COMPLETE: "loginComplete",
  LOGOUT: "logout",
  EXIT_CLICK: "exitClicks",
  PIN_SET_UP_START: "pinSetupStart",
  PIN_SET_UP_COMPLETE: "pinSetupComplete",
  PIN_RESET_START: "pinResetStart",
  PIN_RESET_COMPLETE: "pinResetComplete",
  PIN_CONTENT_LOCK: "pinContentLock",
  PIN_CONTENT_UNLOCK: "pinContentUnlock",
  PARENTAL_PIN_ENABLE_START: "parentalPinEnableStart",
  PARENTAL_PIN_DISABLE_START: "parentalPinDisableStart",
  PARENTAL_PIN_ENABLE_COMPLETE: "parentalPinEnableComplete",
  PARENTAL_PIN_DISABLE_COMPLETE: "parentalPinDisableComplete",
  PARENTAL_PIN_ENABLE_ERROR: "parentalPinEnableError",
  PARENTAL_PIN_DISABLE_ERROR: "parentalPinDisableError",
  PARENTAL_FILTER_START: "parentalFilterStart",
  PARENTAL_FILTER_COMPLETE: "parentalFilterComplete",
  PARENTAL_FILTER_ERROR: "parentalFilterError",
  PARENTAL_PIN_RESET_START: "parentalPinResetStart",
  PARENTAL_PIN_RESET_COMPLETE: "parentalPinResetComplete",
  PARENTAL_PIN_RESET_ERROR: "parentalPinResetError",
  PARENTAL_PIN_CONTENT_LOCK: "parentalPinContentLock",
  PARENTAL_PIN_CONTENT_UNLOCK: "parentalPinContentUnlock",
  PARENTAL_PIN_CONTENT_UNLOCK_ERROR: "parentalPinContentUnlockError",
  PURCHASE_PIN_ENABLE_START: "purchasePinEnableStart",
  PURCHASE_PIN_DISABLE_START: "purchasePinDisableStart",
  PURCHASE_PIN_ENABLE_COMPLETE: "purchasePinEnableComplete",
  PURCHASE_PIN_DISABLE_COMPLETE: "purchasePinDisableComplete",
  PURCHASE_PIN_ENABLE_ERROR: "purchasePinEnableError",
  PURCHASE_PIN_DISABLE_ERROR: "purchasePinDisableError",
  PURCHASE_PIN_RESET_START: "purchasePinResetStart",
  PURCHASE_PIN_RESET_COMPLETE: "purchasePinResetComplete",
  PURCHASE_PIN_RESET_ERROR: "purchasePinResetError",
  PURCHASE_PIN_LOCK: "purchasePinLock",
  PURCHASE_PIN_UNLOCK: "purchasePinUnlock",
  PURCHASE_PIN_UNLOCK_ERROR: "purchasePinUnlockError",
  PRODUCT_IMPRESSION: "productImpression",
  PRODUCT_DETAIL_VIEW: "productView",
  PROMO_CLICK: "promoClick",
  ADD_TO_CART: "addToCart",
  PURCHASE: "purchase",
  STILL_WATCHING: "stillWatching",
  BOOKMARK: "bookmark",
  FAVOURITE: "favourite",
  UNFAVOURITE: "unfavourite",
  ON_NOW_START: "onNowStart",
  UP_NEXT_START: "upNextStart",
  GEO_BLOCKED_ERROR: "geoBlockedError",
  PLAYBACK_ERROR: "playbackError",
  VIDEO_RECORD_STOP: "videoRecordStop",
  VIDEO_RECORD_START: "videoRecordStart",
  VIDEO_RECORD_DELETE: "videoRecordDelete",
  VIDEO_RECORD_STOP_ERROR: "videoRecordStopError",
  VIDEO_RECORD_START_ERROR: "videoRecordStartError",
  VIDEO_RECORD_DELETE_ERROR: "videoRecordDeleteError",
  VIDEO_RECORD_EDIT_START: "videoRecordingEditStart",
  VIDEO_RECORD_EDIT_COMPLETE: "videoRecordingEditComplete",
  VIDEO_RECORD_SERIES_EDIT_START: "videoRecordingSeriesEditStart",
  VIDEO_RECORD_EPISODE_EDIT_START: "videoRecordingEpisodeEditStart",
  VIDEO_RECORD_SERIES_EDIT_COMPLETE: "videoRecordingSeriesEditComplete",
  VIDEO_RECORD_EPISODE_EDIT_COMPLETE: "videoRecordingEpisodeEditComplete",
  VIDEO_RECORD_SERIES_EDIT_ERROR: "videoRecordingSeriesEditError",
  VIDEO_RECORD_EPISODE_EDIT_ERROR: "videoRecordingEpisodeEditError",
  TOOL_START: "toolStart",
  TOOL_COMPLETE: "toolComplete",
  NOTIFICATION_IMPRESSIONS: "notificationImpressions",
  NOTIFICATION_DISMISSALS: "notificationDismissals",
  RECOMMENDATION_IMPRESSIONS: "recommendationImpressions",
  PROFILE_EDIT_ERROR: "profileEditError",
  PROFILE_CREATE_ERROR: "profileCreateError",
  RESTART: "restart",
  LOOKBACK: "lookback",
});

export const ANALYTICS_EVENT_INFO_TYPES = Object.freeze({
  PAGE_LOAD: "Page load event",
  SETTINGS_CLICK: "Settings click event",
  SEARCH: "Search event",
  VIDEO_FORWARD: "Video forward event",
  VIDEO_REWIND: "Video rewind event",
  ERROR: "Error event",
  LOGIN_START: "User login start event",
  LOGIN_COMPLETE: "User login complete event",
  LOGOUT: "User logout event",
  EXIT_LINK_NAME: "Exit Link Name Event",
  EXIT: "Exit Link Click Event",
  PARENTAL_PIN_ENABLE_START: "Parental PIN enable start event",
  PARENTAL_PIN_DISABLE_START: "Parental PIN disable start event",
  PARENTAL_PIN_ENABLE_COMPLETE: "Parental PIN enable complete event",
  PARENTAL_PIN_DISABLE_COMPLETE: "Parental PIN disable complete event",
  PARENTAL_FILTER_START: "Parental filter start event",
  PARENTAL_FILTER_COMPLETE: "Parental filter complete event",
  PARENTAL_PIN_RESET_START: "Parental PIN reset start event",
  PARENTAL_PIN_RESET_COMPLETE: "Parental PIN reset complete event",
  PARENTAL_PIN_CONTENT_LOCK: "Parental PIN content lock event",
  PARENTAL_PIN_CONTENT_UNLOCK: "Parental PIN content unlock event",
  PURCHASE_PIN_ENABLE_START: "Purchase PIN enable start event",
  PURCHASE_PIN_DISABLE_START: "Purchase PIN disable start event",
  PURCHASE_PIN_ENABLE_COMPLETE: "Purchase PIN enable complete event",
  PURCHASE_PIN_DISABLE_COMPLETE: "Purchase PIN disable complete event",
  PURCHASE_PIN_RESET_START: "Purchase PIN reset start event",
  PURCHASE_PIN_RESET_COMPLETE: "Purchase PIN reset complete event",
  PURCHASE_PIN_LOCK: "Purchase PIN lock event",
  PURCHASE_PIN_UNLOCK: "Purchase PIN unlock event",
  PRODUCT_IMPRESSION: "Product banner promo view event",
  PRODUCT_DETAIL_VIEW: "Product detail view event",
  PROMO_CLICK: "Internal campaign click event",
  ADD_TO_CART: "Add to cart event",
  PURCHASE: "Final purchase event",
  STILL_WATCHING: "Still watching event",
  BOOKMARK: "Bookmark event",
  FAVOURITE: "Add to favourite event",
  ON_NOW_START: "On now start event",
  UP_NEXT_START: "Up next start event",
  VIDEO_RECORD_STOP: "Video record stop event",
  VIDEO_RECORD_START: "Video record start event",
  VIDEO_RECORD_DELETE: "Video record delete event",
  VIDEO_RECORD_SERIES_EDIT_START: "Series recording edit start event",
  VIDEO_RECORD_EPISODE_EDIT_START: "Episode recording edit start event",
  VIDEO_RECORD_SERIES_EDIT_COMPLETE: "Series recording edit complete event",
  VIDEO_RECORD_EPISODE_EDIT_COMPLETE: "Episode recording edit complete event",
  PROFILE_CREATE_COMPLETE: "Profile create complete event",
  PROFILE_EDIT_COMPLETE: "Profile edit complete event",
  NOTIFICATION_IMPRESSIONS: "Notification module impression event",
  NOTIFICATION_DISMISSALS: "Notification module dismissal event",
  RECOMMENDATION_IMPRESSIONS: "Recommendation impressions event",
  RESTART: "Live playback restart content event",
  LOOKBACK: "Live playback restart content event",
});

export const WEB_ACTION_EVENT_TYPES = Object.freeze({
  OTHER: "other",
  DOWNLOAD: "download",
  EXIT: "exit",
});

export const WEB_ACTION_EVENT_NAMES = Object.freeze({
  LOGIN_START: "login_start",
  LOGIN_COMPLETE: "login_complete",
  LOGOUT: "logout",
  PARENTAL_PIN_ENABLE_START: "parental_pin_enable_start",
  PARENTAL_PIN_DISABLE_START: "parental_pin_disable_start",
  PARENTAL_PIN_ENABLE_COMPLETE: "parental_pin_enable_complete",
  PARENTAL_PIN_DISABLE_COMPLETE: "parental_pin_disable_complete",
  PARENTAL_PIN_ENABLE_ERROR: "parental_pin_enable_error",
  PARENTAL_PIN_DISABLE_ERROR: "parental_pin_disable_error",
  PARENTAL_PIN_RESET_START: "parental_pin_reset_start",
  PARENTAL_PIN_RESET_COMPLETE: "parental_pin_reset_complete",
  PARENTAL_PIN_RESET_ERROR: "parental_pin_reset_error",
  PARENTAL_FILTER_ERROR: "parental_filter_error",
  PARENTAL_PIN_CONTENT_LOCK: "parental_pin_content_lock",
  PARENTAL_PIN_CONTENT_UNLOCK: "parental_pin_content_unlock",
  PARENTAL_PIN_CONTENT_UNLOCK_ERROR: "parental_pin_content_unlock_error",
  PURCHASE_PIN_ENABLE_START: "purchase_pin_enable_start",
  PURCHASE_PIN_DISABLE_START: "purchase_pin_disable_start",
  PURCHASE_PIN_ENABLE_COMPLETE: "purchase_pin_enable_complete",
  PURCHASE_PIN_DISABLE_COMPLETE: "purchase_pin_disable_complete",
  PURCHASE_PIN_ENABLE_ERROR: "purchase_pin_enable_error",
  PURCHASE_PIN_DISABLE_ERROR: "purchase_pin_disable_error",
  PURCHASE_PIN_RESET_ERROR: "purchase_pin_reset_error",
  PURCHASE_PIN_RESET_START: "purchase_pin_reset_start",
  PURCHASE_PIN_RESET_COMPLETE: "purchase_pin_reset_complete",
  PURCHASE_PIN_LOCK: "purchase_pin_lock",
  PURCHASE_PIN_UNLOCK: "purchase_pin_unlock",
  PURCHASE_PIN_UNLOCK_ERROR: "purchase_pin_unlock_error",
  GUIDE_SEARCH: "guide_search",
  SWIMLANE_SEARCH: "swimlane_refinement",
  SEARCH: "search",
  PROMO_CLICK: "promo_click",
  STILL_WATCHING: "still_watching",
  ON_NOW: "on_now",
  UP_NEXT: "up_next",
  PLAYBACK_ERROR: "playback_error",
  GEO_BLOCKED_ERROR: "geo_blocked_error",
  VIDEO_RECORD_STOP: "video_record_stop",
  VIDEO_RECORD_START: "video_record_start",
  VIDEO_RECORD_DELETE: "video_record_delete",
  VIDEO_RECORD_STOP_ERROR: "video_record_stop_error",
  VIDEO_RECORD_START_ERROR: "video_record_start_error",
  VIDEO_RECORD_DELETE_ERROR: "video_record_delete_error",
  VIDEO_RECORD_SERIES_EDIT_START: "series_recording_edit_start",
  VIDEO_RECORD_EPISODE_EDIT_START: "episode_recording_edit_start",
  VIDEO_RECORD_SERIES_EDIT_COMPLETE: "series_recording_edit_complete",
  VIDEO_RECORD_EPISODE_EDIT_COMPLETE: "episode_recording_edit_complete",
  VIDEO_RECORD_SERIES_EDIT_ERROR: "series_recording_edit_error",
  VIDEO_RECORD_EPISODE_EDIT_ERROR: "episode_recording_edit_error",
  PROFILE_EDIT_COMPLETE: "profile_edit_complete",
  PROFILE_CREATE_COMPLETE: "profile_create_complete",
  RECOMMENDATION_IMPRESSIONS: "recommendation_impressions",
  RESTART: "restart",
  LOOKBACK: "lookback",
});

export const ACTION_VALUES = Object.freeze({
  SETTINGS_TOGGLE_CC_ON: "closed_caption_toggle_on",
  SETTINGS_TOGGLE_CC_OFF: "closed_caption_toggle_off",
  SETTINGS_TOGGLE_DV_ON: "described_video_toggle_on",
  SETTINGS_TOGGLE_DV_OFF: "described_video_toggle_off",
  SETTINGS_TOGGLE_LANG_EN: "language_toggle_english",
  SETTINGS_TOGGLE_LANG_FR: "language_toggle_french",
  PARENTAL_PIN_SETUP: "parental_pin_setup",
  PARENTAL_PIN_ENABLE: "parental_pin_enable",
  PARENTAL_PIN_DISABLE: "parental_pin_disable",
  PARENTAL_FILTER: "parental_filter",
  PARENTAL_FILTER_ENABLE: "parental_filter_enable",
  PARENTAL_FILTER_DISABLE: "parental_filter_disable",
  PARENTAL_PIN_RESET: "parental_pin_reset",
  PARENTAL_PIN_CONTENT_LOCK: "parental_pin_content_lock",
  PARENTAL_PIN_CONTENT_UNLOCK: "parental_pin_content_unlock",
  PURCHASE_PIN_ENABLE: "purchase_pin_enable",
  PURCHASE_PIN_DISABLE: "purchase_pin_disable",
  PURCHASE_PIN_RESET: "purchase_pin_reset",
  PURCHASE_PIN_LOCK: "purchase_pin_lock",
  PURCHASE_PIN_UNLOCK: "purchase_pin_unlock",
  SEARCH_REFINEMENT_TYPE_FILTER: "filter_by",
  SEARCH_REFINEMENT_TYPE_SORT: "sort_by",
  SEARCH_TYPE_ORIGINAL: "original_search",
  VIDEO_RECORD_STOP: "video_record_stop",
  VIDEO_RECORD_START: "video_record_start",
  VIDEO_RECORD_DELETE: "video_record_delete",
  VIDEO_RECORD_SERIES_EDIT: "recording_series_edit",
  VIDEO_RECORD_EPISODE_EDIT: "recording_episode_edit",
  EDIT_PROFILE: "edit_profile",
  ADD_PROFILE: "add_profile",
});

export const LINK_INFO = Object.freeze({
  PLAYER: "player",
  MEGA_NAV: "mega_nav",
  TOP_NAV: "top_nav",
  SUB_NAV: "sub_nav",
  BANNER: "banner",
  CAROUSEL: "carousel",
  LOGO: "logo",
  GUIDE: "guide",
  SEARCH: "search",
  LOGIN: "login",
  SETTINGS: "settings",
  BASICS: "basics",
  DETAILS: "details",
  SCHEDULES: "schedules",
  APP_INFO: "app_info",
  PROFILE_MENU: "profile_menu",
  ACCOUNT_INFO: "account_info",
  PARENTAL_PIN: "parental_pin",
  PURCHASE_PIN: "purchase_pin",
  MOVIE_SIDEBAR: "movie_sidebar",
  EPISODE_SIDEBAR: "episode_sidebar",
  RECORDING_SIDEBAR: "recording_sidebar",
  RECORDING_ITEM_PLAY: "recording_item_play",
  RECORDING_ITEM_TITLE: "recording_item_title",
  SWIMLANE: "swimlane",
  SWIMLANE_HEADER: "swimlane_header",
  SWIMLANE_ITEM: "swimlane_item",
  SERIES_LINK: "series_link",
  TELUS_TV: "telus_tv",
  VIEW_DETAILS: "view_details",
  VIEW_ALL: "view_all",
  LIVE_PLAY: "live_play",
  TRAILER_PLAY: "trailer_play",
  ON_DEMAND_PLAY: "on_demand_play",
  PURCHASE_PLAY: "purchase_play",
  PURCHASE_PROMPT: "purchase_prompt",
  RECORDING_PROMPT: "recording_prompt",
  SHOW_WAYS_TO_WATCH_PROMPT: "show_ways_to_watch_prompt",
  SEASON_PICKER_DROPDOWN: "season_picker_dropdown",
  SKIP: "skip",
  SAVE: "save",
  DONE: "done",
  CREATE: "create",
  CLOSE: "close",
  EDIT: "edit",
  CANCEL: "cancel",
  DELETE: "delete",
  MINI_GUIDE: "mini_guide",
  ON_NOW: "on_now",
  PLAY: "play",
  NEXT_EPISODE: "next_episode",
  MANAGE_RECORDINGS: "manage_recordings",
  PROFILE: "profiles",
  ADD_PROFILE: "add_profile",
  EDIT_PROFILES: "edit_profiles",
  EDIT_PROFILE: "edit_profile",
  SWITCH_PROFILES: "switch_profiles",
  PROFILE_AVATAR: "profile_avatar",
  CHOOSE_AN_IMAGE: "choose_an_image",
});

export const ANALYTICS_ERROR_NAMES = Object.freeze({
  USER: "user",
  USER_ERROR: "user_error",
  SERVER_ERROR: "server_error",
});

export const ANALYTICS_ERROR_INFO = Object.freeze({
  LOGIN_FAILURE: "login_failure",
  LOGOUT_FAILURE: "logout_failure",
  WRONG_ACCOUNT_FAILURE: "wrong_account_failure",
  DATA_FETCHING_FAILURE: "data_fetching_failure",
  VIDEO_RECORD_START_FAILURE: "video_record_start_failure",
  VIDEO_RECORD_STOP_FAILURE: "video_record_stop_failure",
  VIDEO_RECORD_DELETE_FAILURE: "video_record_delete_failure",
  VIDEO_RECORD_SERIES_EDIT_FAILURE: "video_record_series_edit_failure",
  VIDEO_RECORD_EPISODE_EDIT_FAILURE: "video_record_episode_edit_failure",
  PROFILE_EDIT_FAILURE: "profile_edit_failure",
  PROFILE_CREATE_FAILURE: "profile_create_failure",
});

export const EXTRA_METADATA_TYPES = Object.freeze({
  MEDIA_CONTENT: "media_content",
  COMMERCE: "commerce",
  PRODUCT_IMPRESSION: "product_impression",
  PRODUCTS_IMPRESSION_VIEW: "products_impression_view",
  SEARCH_CLICK: "search_click",
  RECOMMENDATION_CLICK: "recommendation_click",
  RECOMMENDATION_IMPRESSIONS: "recommendation_impressions",
});

export const MEDIA_VIDEO_PLAY_TYPES = Object.freeze({
  RECOMMENDATION: "recommendation",
  STANDARD_LISTING: "standard_listing",
});

export const SITE_NAMES = {
  PIK_TV: "piktv",
  OPTIK_TV: "optiktv",
  TELUS_TV: "telustv",
};
export const DEFAULT_CUSTOMER_TYPE = "customer";
export const UNKNOWN_VALUE = "unknown";

export const MAPPED_CONTENT_TYPES = {
  LIVE: "live",
  SVOD: "svod",
  TVOD: "tvod",
  CPVR: "cpvr",
  LPVR: "lpvr",
  RESTART: "live-restart",
  LOOKBACK: "live-lookback",
  FAST: "live-fast",
};

export const PROFILE_TYPES = {
  KID: "kid",
  MAIN: "main",
  STANDARD: "standard",
};

export const LOGIN_STATE = {
  LOGGED_IN: "logged-in",
  LOGGED_OUT: "logged-out",
};

export const NOTIFICATION_TYPES = Object.freeze({
  NOTIFICATIONS: "notifications",
  WARNINGS: "warnings",
  ALERTS: "alerts",
  SUBSCRIPTION: "subscription",
});

export const RECOMMENDATION_TYPES = Object.freeze({
  MORE_LIKE_THIS_EN: "more_like_this",
  MORE_LIKE_THIS_FR: "même_genre",
  YOU_MIGHT_LIKE: "you_might_like",
});

export const PROFILE_PAGE_NAMES = Object.freeze({
  ADD_PROFILE: "add_profile",
  CHOOSE_IMAGE: "choose_an_image",
  EDIT_PROFILE: "edit_profile",
  EDIT_PROFILES: "edit_profiles",
});

export const SHOW_TYPES = Object.freeze({
  TRAILER: "trailer",
  MOVIES: "movies",
  TV_SHOWS: "tv_shows",
});

export const SPLUS_CUSTOMER_TYPES = Object.freeze({
  TELUS: "splustelus",
  KOODO: "spluskoodo",
});
