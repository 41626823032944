import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import {
  addQueryParamsToString,
  getPcLevelRestriction,
  getProgramInAscendingOrder,
  getQueryParamsByName,
  updateQueryStringParameter,
  getKidExclusionRating,
  getProgramWithinPCLevel,
} from "../../shared/utils";
import paginationConstants from "../../shared/constants/pagination";
import retrieveAvsItems from "../../shared/middleware/retrieveAvsItems";
import { isItemTypeRecording, isItemTypeLive } from "../../shared/utils/content";
import {
  isFavouriteChannelsConfig,
  isSwimlaneFeatureEnabled,
  isFavouriteAssetsConfig,
} from "../../shared/utils/swimlane";
import Carousel from "../../components/Carousel";
import FeedPageSwimlane from "./FeedSwimlane";
import { useReducers } from "../../shared/hooks/useReducer";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import { createChannelItem, getCurrentAiringShow } from "../../shared/utils/swimlane";
import { getSegmentTimestamp, getNextSegmentTimestamp } from "../../shared/hooks/useGuidePrograms";
import SWIMLANE_CONSTANTS from "../../shared/constants/swimlane";
import constants from "../../shared/constants";

const { INITIAL_END_INDEX, INITIAL_START_INDEX } = paginationConstants;
const { MAX_SWIMLANE_ITEMS } = SWIMLANE_CONSTANTS;
const { REDUCER_TYPE } = constants;

/**
 * A feed component that appears on the feed page. This can be different UI components like
 * the Carousel or Swimlane, but the purpose of this component is fetching the feed items,
 * filtering/sorting the results, and passing them through to the appropriate component
 * for rendering based on the feed config.
 *
 * @component
 */
function Feed({
  config,
  subscribedChannelIds,
  setProductImpressions,
  updateAvailableSwimlanes,
  loadFavouriteChannelsCallback,
  refreshFeedRef,
  loadGuidePrograms,
  setSelectedAssetRecordingInfo,
  updateRecordingHandler,
}) {
  const {
    provider: appProvider,
    userProfile,
    subscribedChannels,
    favouriteChannels,
    favouriteAssets,
    channelMapInfo: channelContainers,
    featureToggles,
  } = useReducers(REDUCER_TYPE.APP);
  const { isEpgFetched, currentPrograms } = useReducers(REDUCER_TYPE.EPG);
  const { isRecordingEnabled, isParentalPINEnabled, isUserProfilesEnabled } = featureToggles;
  const channelMapInfo = useMemo(() => channelContainers?.containers, [channelContainers]);
  const [rawFeedData, setRawFeedData] = useState(null);
  const [channelInfoPayload, setChannelInfoPayload] = useState(null);
  const [filteredFeedData, setFilteredFeedData] = useState(null);
  const [feedEndpoint, setFeedEndpoint] = useState(null);
  const FeedComponent = feedComponentMap[config?.layout];

  const isFavouriteChannelsFeed = isFavouriteChannelsConfig(config);
  const isFavouriteAssetsFeed = isFavouriteAssetsConfig(config);
  const isLiveFeed =
    isFavouriteChannelsFeed ||
    (!!feedEndpoint
      ? getQueryParamsByName("filter_airingTime", feedEndpoint) === "now"
      : getQueryParamsByName("filter_airingTime", channelInfoPayload?.feedEndpoint) === "now");
  const liveFeedRefreshTimer = useRef(null);
  const epgCacheData = useRef(currentPrograms);
  const performLoadCacheData = useRef(false);
  const currentEPGTS = useRef();
  const allowOneFetch = useRef(false);

  const channelIdsFilter = isFavouriteChannelsFeed ? null : subscribedChannelIds;
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const isCacheReady = useRef(false);
  const pcLevel = getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled);

  // Helps organizing the swimlane items before passing into the swimlane
  const processFilteredItems = useCallback(
    (filteredItems, feedEndpoint) => {
      const filteredItemsCount = filteredItems?.length;
      if (filteredItemsCount) {
        filteredItems = getProgramWithinPCLevel(filteredItems, pcLevel);
        if (isLiveFeed) {
          getProgramInAscendingOrder(filteredItems, appProvider);
        } else {
          // Check the URI whitelist in the config to determine if data order should be reversed
          // Commenting this block for now as on 28/09/23 we don't have anything for "data_order_reversal_uri_whitelist"
          // if (appProvider.config.web.data_order_reversal_uri_whitelist?.find((uri) => feedEndpoint?.includes(uri))) {
          //   filteredItems.reverse();
          // }

          if (filteredItemsCount > MAX_SWIMLANE_ITEMS) {
            filteredItems.splice(MAX_SWIMLANE_ITEMS);
          }
        }

        setFilteredFeedData({
          items: filteredItems,
          totalItems: filteredItemsCount,
        });
      } else {
        setFilteredFeedData([]);
      }
    },
    [appProvider, isLiveFeed, pcLevel]
  );

  // Used to fetch swimlane data from the EPG cache data
  const loadCacheData = useCallback(() => {
    if (channelInfoPayload?.channelIds && epgCacheData.current && channelMapInfo) {
      let filteredItems = [];
      const channelIds = channelInfoPayload.channelIds;
      const currentSegmentKey = getSegmentTimestamp();
      for (let i = 0; i < channelIds.length; i++) {
        const channelShows = epgCacheData.current[currentSegmentKey][channelIds[i]];
        if (channelShows) {
          const channel = channelMapInfo.find((channel) => {
            return parseInt(channel.id) === parseInt(channelIds[i]);
          });
          const channelItem = createChannelItem(getCurrentAiringShow(channelShows), channel);
          if (channelItem) {
            filteredItems.push(channelItem);
          }
        }
      }

      processFilteredItems(filteredItems, channelInfoPayload.feedEndpoint);
    }
  }, [channelMapInfo, processFilteredItems, channelInfoPayload]);

  // Compute the endpoint to use to fetch the feed data
  const computeFeedEndpoint = useCallback(async () => {
    // If localUri is defined we must use epg cache data to build the component
    const localUri = config?.metadata?.localUri;
    let actionUri = config?.retrieveItems?.uri;
    if (!localUri) {
      // Fetch favourite channel IDs and construct endpoint to get current programs
      if (isFavouriteChannelsFeed && isEpgFetched && subscribedChannelIds) {
        try {
          let favouriteChannelsResult = "";
          // We prefetch FAVORITES on app launch and updates, data should come from redux instead of making additional calls
          if (!actionUri.include("FAVORITES")) {
            favouriteChannelsResult = await retrieveAvsItems(appProvider, actionUri, false, cancelTokenSource);
          }
          if (!favouriteChannelsResult?.containers?.length) {
            loadFavouriteChannelsCallback([]);
            setRawFeedData(null);
            return;
          }

          const favouriteChannelIds = [];
          if (favouriteChannelsResult?.containers?.length > 0 && subscribedChannels?.containers?.length > 0) {
            const subscribedChannelIds = new Set(subscribedChannels.containers.map((channel) => parseInt(channel.id)));
            favouriteChannelIds.push(
              ...favouriteChannelsResult.containers
                .filter((favChannel) => subscribedChannelIds.has(parseInt(favChannel.id)))
                .map((favChannel) => parseInt(favChannel.id))
            );
          }
          if (loadFavouriteChannelsCallback) {
            loadFavouriteChannelsCallback(favouriteChannelIds);
          }
          const segmentTS = getSegmentTimestamp();
          const hasData = currentPrograms[segmentTS] && Object.keys(currentPrograms[segmentTS]).length !== 0;

          if (!hasData) {
            actionUri = addQueryParamsToString("/TRAY/SEARCH/PROGRAM", {
              filter_airingTime: "now",
              filter_channelIds: favouriteChannelIds.join(","),
            });
            setFeedEndpoint(
              getEnhancedFeedEndpoint(
                actionUri,
                appProvider,
                userProfile,
                channelIdsFilter,
                pcLevel,
                isUserProfilesEnabled
              )
            );
          } else {
            // else fetch the data from cache //
            setChannelInfoPayload({
              channelIds: favouriteChannelIds,
            });
          }
        } catch {
          loadFavouriteChannelsCallback([]);
          setRawFeedData(null);
          return;
        }
      }
      if (isFavouriteAssetsFeed) {
        actionUri = addQueryParamsToString(
          actionUri,
          {
            sortOrder: "DESC",
          },
          actionUri.includes("?")
        );
      }
      if (!isFavouriteChannelsFeed) {
        // adding this check to avoid duplicate feed end points for favourite channels
        setFeedEndpoint(
          getEnhancedFeedEndpoint(actionUri, appProvider, userProfile, channelIdsFilter, pcLevel, isUserProfilesEnabled)
        );
      }
    } else {
      let channelInfoPayload = [];
      if (localUri.includes("filter_channelIds")) {
        channelInfoPayload = getQueryParamsByName("filter_channelIds", localUri).split(",");
      } else {
        // TODO: Update the On Now swimlane identification check to be correct and consistent (TCDWC-2051)
        if (config?.title === "On Now") {
          channelInfoPayload = subscribedChannelIds;
        }
      }
      setChannelInfoPayload({
        feedEndpoint: getEnhancedFeedEndpoint(
          actionUri,
          appProvider,
          userProfile,
          channelIdsFilter,
          null,
          isUserProfilesEnabled
        ),
        channelIds: channelInfoPayload,
      });
    }
  }, [
    isFavouriteChannelsFeed,
    config,
    appProvider,
    userProfile,
    channelIdsFilter,
    cancelTokenSource,
    loadFavouriteChannelsCallback,
    subscribedChannelIds,
    isEpgFetched,
    currentPrograms,
    pcLevel,
    subscribedChannels,
    isUserProfilesEnabled,
    isFavouriteAssetsFeed,
  ]);

  useEffect(() => {
    computeFeedEndpoint();
  }, [computeFeedEndpoint]);

  useEffect(() => {
    const segmentTS = getSegmentTimestamp();
    const hasData = currentPrograms[segmentTS] && Object.keys(currentPrograms[segmentTS]).length !== 0;

    epgCacheData.current = currentPrograms;

    // Used to setup and determine if the cache data is ready and available
    if (currentPrograms[segmentTS] && hasData && !isCacheReady.current) {
      isCacheReady.current = true;
      currentEPGTS.current = segmentTS;
    }

    // Used to load in cache data for epg segments that are new
    if (performLoadCacheData.current && currentEPGTS.current !== segmentTS && hasData) {
      currentEPGTS.current = segmentTS;
      performLoadCacheData.current = false;
      loadCacheData();
    }
  }, [currentPrograms, loadCacheData]);

  const fetchFeedData = useCallback(async () => {
    if (!feedEndpoint && !channelInfoPayload) {
      return;
    }
    if (feedEndpoint) {
      try {
        // We prefetch FAVORITES on app launch and updates, data should come from redux instead of making additional calls
        if (
          (feedEndpoint.includes("/TRAY/USER/FAVORITES?filter_contentType=MOVIE,TVSHOW") && !allowOneFetch.current) ||
          !feedEndpoint.includes("/TRAY/USER/FAVORITES?filter_contentType=MOVIE,TVSHOW")
        ) {
          allowOneFetch.current = true;
          const results = await retrieveAvsItems(appProvider, feedEndpoint, false, cancelTokenSource);
          if (results) {
            setRawFeedData(results);
          }
        }
      } catch {
        // Silently fail
      }
    } else {
      if (!!epgCacheData.current[getSegmentTimestamp()]) {
        loadCacheData();
      } else {
        performLoadCacheData.current = true;
        loadGuidePrograms(getNextSegmentTimestamp());
      }
    }
  }, [feedEndpoint, appProvider, cancelTokenSource, channelInfoPayload, loadCacheData, loadGuidePrograms]);

  useEffect(() => {
    if (
      feedEndpoint?.includes("/TRAY/USER/FAVORITES?filter_contentType=MOVIE,TVSHOW") &&
      allowOneFetch.current &&
      favouriteAssets
    ) {
      setRawFeedData({ containers: favouriteAssets, total: favouriteAssets.length });
    }
  }, [favouriteAssets, feedEndpoint]);

  useEffect(() => {
    if (favouriteChannels && loadFavouriteChannelsCallback) {
      const favouriteChannelIds = favouriteChannels.map((channel) => parseInt(channel?.id));
      loadFavouriteChannelsCallback(favouriteChannelIds);
      setChannelInfoPayload({
        channelIds: favouriteChannelIds,
      });
    }
  }, [favouriteChannels, loadFavouriteChannelsCallback]);

  // Refresh live swimlanes every 15 minutes
  useEffect(() => {
    if (isLiveFeed) {
      liveFeedRefreshTimer.current = setInterval(() => {
        fetchFeedData();
      }, 15 * 60 * 1000);
    }

    return () => {
      if (liveFeedRefreshTimer.current) {
        clearInterval(liveFeedRefreshTimer.current);
      }
    };
  }, [fetchFeedData, isLiveFeed]);

  // Fetch data whenever the endpoint changes
  useEffect(() => {
    if (feedEndpoint) {
      fetchFeedData();
    }
  }, [feedEndpoint, fetchFeedData]);

  useEffect(() => {
    // Used when we set the channel payload when localUri is defined from UI builder for using cache data
    if (!!channelInfoPayload) {
      loadCacheData();
    }
  }, [channelInfoPayload, loadCacheData]);

  // Filter, sort, and/or trim results before displaying on the page
  useEffect(() => {
    if (rawFeedData) {
      const filteredItems = rawFeedData.containers?.filter((container) => {
        // Filter out items that are missing metadata
        const hasMetadata = Object.keys(container?.metadata)?.length > 0;
        // If the containers contain live items and user is not a guest (they have a subscribed channel ID list), we need to filter out the items airing on unsubscribed channels
        const isEntitledToItem =
          !isLiveFeed ||
          !channelIdsFilter?.length > 0 ||
          channelIdsFilter.includes(String(container.channel.channelId));

        // Manually filter out recording items from the bookmarks swimlane if the recording feature is disabled
        let keepBookmarkedItem = true;
        if (feedEndpoint.includes("BOOKMARKS")) {
          if (hasMetadata && isItemTypeRecording(container.metadata)) {
            keepBookmarkedItem = isRecordingEnabled;
          }
        }

        /**
         * Manually filter out live events to filter out ppv events from purchases
         * TODO: Instead of filtering live items,
         * filter items with content type as PPV once BE is ready
         * */
        let keepPurchasedItem = true;
        if (feedEndpoint.includes("PURCHASES")) {
          if (hasMetadata) {
            keepPurchasedItem = !isItemTypeLive(container.metadata);
          }
        }

        return hasMetadata && isEntitledToItem && keepBookmarkedItem && keepPurchasedItem;
      });

      processFilteredItems(filteredItems, feedEndpoint);
    }
  }, [
    appProvider,
    feedEndpoint,
    rawFeedData,
    isLiveFeed,
    channelIdsFilter,
    userProfile,
    processFilteredItems,
    isRecordingEnabled,
  ]);

  // Give the parent component access to the function used to fetch data for this swimlane
  useEffect(() => {
    refreshFeedRef.current[config.id] = fetchFeedData;
  }, [config.id, fetchFeedData, refreshFeedRef]);

  return (!isFavouriteChannelsFeed || filteredFeedData) && filteredFeedData?.items?.length ? (
    <FeedComponent
      data={filteredFeedData?.items}
      config={config}
      totalItems={rawFeedData?.total ?? filteredFeedData?.totalItems}
      setProductImpressions={setProductImpressions}
      updateAvailableSwimlanes={updateAvailableSwimlanes}
      setSelectedAssetRecordingInfo={setSelectedAssetRecordingInfo}
      updateRecordingHandler={updateRecordingHandler}
      refreshFeedRef={refreshFeedRef.current}
    />
  ) : null;
}

/**
 * Returns enhanced feed endpoint after adding or updating filter parameters like region ID and
 * PC level, or pagination params for account-specific results
 * @param {String} actionUri
 * @param {Object} appProvider
 * @param {Object} userProfile
 * @param {Array} channelIdsFilter Optional array used to filter out channel IDs sent in the request
 * @param {Number} pcLevel
 * @param {Boolean} isUserProfilesEnabled
 * @returns {String}
 */
const getEnhancedFeedEndpoint = (
  actionUri,
  appProvider,
  userProfile,
  channelIdsFilter,
  pcLevel,
  isUserProfilesEnabled
) => {
  const activeProfile = userProfile?.user?.profile?.profileData;
  const isKidsProfile = isUserProfilesEnabled && activeProfile?.kidsProfile;

  const queryParams = {};

  if (actionUri.includes("/TRAY/SEARCH/VOD")) {
    Object.assign(queryParams, {
      maxResults: INITIAL_END_INDEX + 1,
      to: INITIAL_END_INDEX,
      from: INITIAL_START_INDEX,
    });
  }

  if (actionUri.includes("/TRAY/SEARCH/PROGRAM")) {
    const channelIds = getQueryParamsByName("filter_channelIds", actionUri)?.split(",");
    if (channelIds?.length && channelIdsFilter?.length) {
      const filteredChannelIds = channelIds.filter((channelId) => channelIdsFilter.includes(channelId));
      if (channelIds.length !== filteredChannelIds.length) {
        // passing incorrect channel id param for live swimlanes that do not have any subscribed channels
        queryParams.filter_channelIds = filteredChannelIds.length
          ? filteredChannelIds.join()
          : "noSubscribedChannelsAvailable";
      }
    } else if (isKidsProfile && channelIdsFilter?.length) {
      queryParams.filter_channelIds = channelIdsFilter.join();
    }
  }

  if (appProvider?.channelMapID && actionUri.includes("/TRAY/SEARCH")) {
    queryParams.filter_regionId = appProvider.channelMapID;
  }

  if (pcLevel) {
    queryParams.filter_pcLevel = pcLevel;
    getKidExclusionRating(userProfile, queryParams);
  }

  return updateQueryStringParameter(actionUri, queryParams);
};

/**
 * Returns true if the given feed configuration is supported
 * @param {Object} appProvider
 * @param {Object} config Feed config object
 * @param {Object} userProfile
 * @param {Boolean} isFavouritesEnabled
 * @param {Boolean} isCloudPVREnabled
 * @returns {Boolean}
 */
export const canCreateFeed = (config, appProvider, userProfile, isFavouritesEnabled, isCloudPVREnabled) => {
  return (
    feedComponentMap[config?.layout] &&
    config.metadata &&
    isSwimlaneFeatureEnabled(config, userProfile, isFavouritesEnabled, isCloudPVREnabled) &&
    (appProvider.userCluster === "R" || !config.metadata.requiresAuth)
  );
};

/**
 * Maps a feed layout to a component
 */
const feedComponentMap = {
  LARGE_CARDS: FeedPageSwimlane,
  SMALL_CARDS: FeedPageSwimlane,
  CAROUSEL: Carousel,
  "169large": FeedPageSwimlane,
};

export default Feed;
