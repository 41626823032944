/**
 * Identify the document reference according to platforms and expose the same
 */

let Document;

if (typeof document === "undefined") {
  Document = window.document;
} else {
  Document = document;
}

export default Document;
