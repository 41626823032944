import { LOAD_CAST_DETAIL } from "./actions";

const initialState = {
  content: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAST_DETAIL:
      return { ...state, content: action.content };
    default:
      return state;
  }
};
