import styled from "styled-components";

const SidebarItem = styled.div(
  ({ theme, $playing, width }) => `
  display: flex;
  align-items: center;
  padding: ${width <= 1366 ? "15px 30px" : "24px 30px"};
  height: ${width <= 1366 ? "85px" : "123px"};
  box-sizing: border-box;
  border-bottom: solid 1px ${theme.colours.recordingButtonBackground};
  cursor: pointer;
  background-color: ${$playing};
  &:hover {
    background-color: ${theme.colours.sharkGrayBackground};
  }
  @media only screen and (max-width: 768px) {
    padding-left: 25px;
  }
`
);

const SidebarChannelInfo = styled.div(
  ({ width }) => `
  margin-right: ${width <= 1366 ? "15px" : "25px"};
  width: 43px;
  min-width: 43px;
  text-align: center;
  img {
    width: ${width <= 768 ? "30px" : width <= 1366 ? "35px" : "43px"};
    height: ${width <= 768 ? "25px" : width <= 1366 ? "32px" : "43px"};
    object-fit: contain;
  }
`
);

const SidebarChannelNumber = styled.div(
  ({ theme, width }) => `
  font: ${theme.typography.regularFont};
  font-size: ${
    width <= 768
      ? theme.typography.smallFontSizeHd
      : width <= 1366
      ? theme.typography.smallFontSize
      : theme.typography.smallFontSwimlane
  };
  color: ${theme.colours.white};
  height: ${width <= 768 ? "12px" : width <= 1366 ? "14px" : ""};
  line-height: ${width <= 768 ? "12px" : width <= 1366 ? "14px" : ""}
`
);

const SidebarItemThumbnail = styled.div(
  ({ theme, width }) => `
  max-width: ${width <= 1366 ? "90px" : "122px"};
  margin-right: 15px;
  background: ${theme.colours.sharkGrayBackground};
  position: relative;
`
);

const SidebarThumbnailImage = styled.img(
  ({ $screen }) => `
  height: ${$screen <= 768 ? "40px" : $screen <= 1366 ? "50px" : "70px"};
  width: ${$screen <= 768 ? "75px" : $screen <= 1366 ? "90px" : "122px"};
  display: block;
  `
);

const SidebarOnNowPlay = styled.img(
  ({ $screen }) => `
  position: absolute;
  width: ${$screen <= 1366 ? "20px" : "30px"};
  height: ${$screen <= 1366 ? "20px" : "30px"};
  right: 6px;
  bottom: ${$screen <= 1366 ? "5px" : "10px"};
`
);

const SidebarProgressBarContainer = styled.div(
  ({ theme }) => `
  background-color: ${theme.colours.translucentCloudyGray};
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0px;
`
);

const SidebarProgressBar = styled.div(
  ({ theme, width }) => `
  height: 100%;
  background-color: ${theme.colours.subscribeLink};
  max-width: ${width <= 768 ? "75px" : width <= 1366 ? "90px" : "100%"};
`
);

const SidebarItemDescription = styled.div(
  ({ theme, width }) => `
  font: ${theme.typography.primaryFont};
  font-size: ${
    width <= 768
      ? theme.typography.smallFontSizeHd
      : width <= 1366
      ? theme.typography.smallFontSwimlane
      : theme.typography.mediumSwimlaneFontSize
  };
  height: ${width <= 1366 ? "50px" : "70px"};
  flex-grow: 1;
  line-height: ${width <= 768 ? "1.4" : "1.2"};
  text-overflow: ellipsis;
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 2;
`
);

const SidebarRowContent = styled.div(
  () => `
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  div {
    min-width: 0;
  }
`
);

const SidebarNewInfo = styled.img(
  () => `
  text-align: center;
  margin: 2px 10px 0 0;
`
);

const SidebarItemDateTime = styled.p(
  ({ theme, width }) => `
  font: ${
    width <= 768
      ? theme.typography.smallFontSizeHd
      : width <= 1366
      ? theme.typography.smallFontSwimlane
      : theme.typography.primaryFont
  };
  font-size: ${theme.typography.mediumSwimlaneFontSize};
  padding: 0px;
  margin: 0px;
  color: ${theme.colours.secondaryFontColor};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
);

const SidebarRestartIconImage = styled.img(
  () => `
  height: 18px;
  margin: 5px;
`
);

const SidebarItemActions = styled.div(
  () => `
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
`
);

const SidebarRecordingButtonWrapper = styled.div(
  () => `
  position: relative;
  align-self: flex-end;
  margin-top: auto;
`
);

const SidebarRecordingLoading = styled.img(
  () => `
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  animation: rotation 2s linear infinite;
  @media only screen and (max-width: 1366px) {
    width: 25px;
    height: 25px;
  }
`
);

const SidebarIconHomeNetwork = styled.img(
  () => `
  width: 18px;
  margin-top: 3px;
`
);

const SidebarChannel4kIcon = styled.img(
  () => `
  width: 29.3px;
  height: 17.33px;
`
);

export {
  SidebarItem,
  SidebarChannelInfo,
  SidebarChannelNumber,
  SidebarItemThumbnail,
  SidebarThumbnailImage,
  SidebarOnNowPlay,
  SidebarProgressBarContainer,
  SidebarProgressBar,
  SidebarItemDescription,
  SidebarRowContent,
  SidebarNewInfo,
  SidebarItemDateTime,
  SidebarRestartIconImage,
  SidebarItemActions,
  SidebarRecordingButtonWrapper,
  SidebarRecordingLoading,
  SidebarIconHomeNetwork,
  SidebarChannel4kIcon,
};
