import middleware from "../../../shared/middleware";
import { getSeriesDetail } from "../../../shared/middleware/series";
import { getProgramSchedules } from "../../../shared/middleware/schedules";
import constants from "../../../shared/constants";
import paginationConstants from "../../../shared/constants/pagination";

const { getSimilarItems, getContentUserData, retrieveAvsItems } = middleware;
const { CONTAINER_TYPES } = constants;
const { INITIAL_START_INDEX, INITIAL_END_INDEX } = paginationConstants;

// Action types
export const LOAD_SIMILAR_SERIES = "LOAD_SIMILAR_SERIES";
export const LOAD_PROGRAM_DETAILS = "LOAD_PROGRAM_DETAILS";
export const LOAD_PLAY_OPTIONS = "LOAD_PLAY_OPTIONS";
export const LOAD_SERIES_CONTENT = "LOAD_SERIES_CONTENT";
export const LOAD_EPISODE_SCHEDULES = "LOAD_EPISODE_SCHEDULES";
export const LOAD_EPISODE_PAGE_CONTAINERS = "LOAD_EPISODE_PAGE_CONTAINERS";
export const LOAD_ON_DEMAND_OPTIONS = "LOAD_ON_DEMAND_OPTIONS";
export const LOAD_LIST_OF_TEAMS = "LOAD_LIST_OF_TEAMS";

function loadPageContainersSuccess(content) {
  return { type: LOAD_EPISODE_PAGE_CONTAINERS, content };
}
function loadPageContainersFailure() {
  return { type: LOAD_EPISODE_PAGE_CONTAINERS, content: [] };
}
function loadSimilarItemsSuccess(content) {
  return { type: LOAD_SIMILAR_SERIES, content: content.containers, total: content.total };
}
function loadSimilarItemsFailure() {
  return { type: LOAD_SIMILAR_SERIES, content: [] };
}
function loadPlayOptionsSuccess(content) {
  return { type: LOAD_PLAY_OPTIONS, content };
}
function loadPlayOptionsFailure() {
  return { type: LOAD_PLAY_OPTIONS, content: {} };
}
function loadSchedulesSuccess(content, regionId) {
  return { type: LOAD_EPISODE_SCHEDULES, content, regionId };
}
function loadSchedulesFailure() {
  return { type: LOAD_EPISODE_SCHEDULES, content: [] };
}
function loadOnDemandOptionsSuccess(content) {
  return { type: LOAD_ON_DEMAND_OPTIONS, content };
}
function loadOnDemandOptionsFailure() {
  return { type: LOAD_ON_DEMAND_OPTIONS, content: [] };
}

function loadListOfTeamsSuccess(content) {
  return { type: LOAD_LIST_OF_TEAMS, content: content };
}
function loadListOfTeamsFailure(error) {
  return { type: LOAD_LIST_OF_TEAMS, content: error };
}

export const loadSimilarItems = (
  appProvider,
  itemType,
  itemGenre,
  itemSubType = null,
  userProfile = null,
  pcLevel = null,
  cancelTokenSource = null
) => {
  return async (dispatch) => {
    try {
      const feedContent = await getSimilarItems(
        appProvider,
        itemType,
        itemGenre,
        itemSubType,
        userProfile,
        pcLevel,
        cancelTokenSource,
        INITIAL_START_INDEX,
        INITIAL_END_INDEX
      );
      if (feedContent) {
        dispatch(loadSimilarItemsSuccess(feedContent));
      }
    } catch (error) {
      dispatch(loadSimilarItemsFailure());
    }
  };
};

function loadProgramDetailsSuccess(content) {
  return { type: LOAD_PROGRAM_DETAILS, content };
}
function loadProgramDetailsFailure() {
  return { type: LOAD_PROGRAM_DETAILS, content: [] };
}

export const loadPlayOptions = (appProvider, itemId, itemType) => {
  return async (dispatch) => {
    try {
      const feedContent = await getContentUserData(appProvider, itemId, itemType);
      if (feedContent) {
        dispatch(loadPlayOptionsSuccess(feedContent.containers[0]));
      }
    } catch (error) {
      dispatch(loadPlayOptionsFailure());
    }
  };
};

function loadSeriesContentSuccess(content) {
  return { type: LOAD_SERIES_CONTENT, content };
}
function loadSeriesContentFailure() {
  return { type: LOAD_SERIES_CONTENT, content: [] };
}

export const loadSeriesContent = (appProvider, itemId, isLoggedIn) => {
  return async (dispatch) => {
    try {
      let feedContent = await getSeriesDetail(appProvider, itemId);
      dispatch(loadSeriesContentSuccess(feedContent));
    } catch (error) {
      dispatch(loadSeriesContentFailure());
    }
  };
};

export const loadSchedules = (appProvider, title, cancelTokenSource) => {
  return async (dispatch) => {
    try {
      const feedContent = await getProgramSchedules(appProvider, title, null, false, cancelTokenSource);
      if (feedContent) {
        dispatch(loadSchedulesSuccess(feedContent.containers, appProvider.channelMapID));
      } else {
        throw new Error("EpisodeDetailPage - program schedules response object is invalid");
      }
    } catch (error) {
      dispatch(loadSchedulesFailure());
    }
  };
};

export const loadEpisodeDetailItems = (appProvider, actionUri, episodeDetailItem) => {
  let successAction;
  let failureAction;
  switch (episodeDetailItem) {
    case CONTAINER_TYPES.CONTAINERS:
      successAction = loadPageContainersSuccess;
      failureAction = loadPageContainersFailure;
      break;
    case CONTAINER_TYPES.CONTENT:
      successAction = loadProgramDetailsSuccess;
      failureAction = loadProgramDetailsFailure;
      break;
    case CONTAINER_TYPES.SCHEDULES:
      successAction = (containers) => loadSchedulesSuccess(containers, appProvider.channelMapID);
      failureAction = loadSchedulesFailure;
      break;
    case CONTAINER_TYPES.ON_DEMAND:
      successAction = loadOnDemandOptionsSuccess;
      failureAction = loadOnDemandOptionsFailure;
      break;
    case CONTAINER_TYPES.MORE_LIKE_THIS:
      successAction = loadSimilarItemsSuccess;
      failureAction = loadSimilarItemsFailure;
      break;
    case CONTAINER_TYPES.LIST_OF_TEAMS:
      successAction = loadListOfTeamsSuccess;
      failureAction = loadListOfTeamsFailure;
      break;
    default:
      break;
  }
  return async (dispatch) => {
    try {
      const feedContent = await retrieveAvsItems(appProvider, actionUri);
      if (feedContent) {
        if (episodeDetailItem === CONTAINER_TYPES.MORE_LIKE_THIS) {
          dispatch(successAction(feedContent));
        } else if (feedContent.containers) {
          dispatch(successAction(feedContent.containers));
        }
      }
    } catch (error) {
      dispatch(failureAction());
    }
  };
};
