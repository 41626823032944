/**
 * Sets the value of a cookie with given cookie name, value and expiry days
 * @param {String} name
 * @param {String} value
 * @param {Number} expiryDays
 */
export function setCookie(name, value, expiryDays) {
  const d = new Date();
  d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

/**
 * Expires the cookie with given cookie name
 * @param {String} name
 */
export function expireCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
