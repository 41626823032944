import request from "../../service/request";
import constants from "../constants";
import { addQueryParamsToString, getKidExclusionRating } from "../utils";
import paginationConstants from "../constants/pagination";

const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

const { CONTENT_ITEM_TYPES } = constants;
const { INITIAL_START_INDEX, INITIAL_END_INDEX_VIEW_ALL } = paginationConstants;

export const getSimilarItems = (
  appProvider,
  itemType,
  itemGenre = null,
  itemSubType = null,
  userProfile = null,
  pcLevel = null,
  cancelTokenSource = null,
  startIndex = INITIAL_START_INDEX,
  endIndex = INITIAL_END_INDEX_VIEW_ALL
) => {
  const withCredentials = appProvider.userCluster === "R";
  const queryParams = {
    from: startIndex,
    to: endIndex,
    maxResults: endIndex + 1,
  };

  let url =
    itemType === CONTENT_ITEM_TYPES.program
      ? appProvider.config.avs_api_urls.similar_program
      : appProvider.config.avs_api_urls.similar_vod;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$genres", itemGenre ?? "");
  url = url.replace("$SubType", itemSubType ?? "");

  if (appProvider.channelMapID) {
    queryParams.filter_regionId = appProvider.channelMapID;
  }

  if (appProvider.panicMode && withCredentials) {
    if (itemType !== CONTENT_ITEM_TYPES.program) {
      queryParams.filter_technicalPackages = appProvider.svodPackageIds;
    }

    queryParams.filter_pcLevel = appProvider.pcLevel;
  } else if (pcLevel) {
    queryParams.filter_pcLevel = pcLevel;
  }

  if (queryParams.filter_pcLevel) {
    getKidExclusionRating(userProfile, queryParams);
  }

  url = addQueryParamsToString(url, queryParams, true);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request({ url, method: "GET", withCredentials }, cancelTokenSource, appProvider);
};
