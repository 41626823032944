import middleware from "../../../shared/middleware";

const { getCastDetail } = middleware;
// Action types
export const LOAD_CAST_DETAIL = "LOAD_CAST_DETAIL";

// Action methods
function loadCastSuccess(content) {
  return { type: LOAD_CAST_DETAIL, content };
}
function loadCastFailure(error) {
  return { type: LOAD_CAST_DETAIL, content: error };
}
export const loadCast = (appProvider, title, userProfile, pcLevel, cancelTokenSource = null) => {
  return async (dispatch) => {
    try {
      const castContent = await getCastDetail(
        appProvider,
        title,
        userProfile,
        pcLevel,
        undefined,
        undefined,
        cancelTokenSource
      );
      if (castContent) {
        dispatch(loadCastSuccess(castContent));
      }
    } catch (error) {
      dispatch(loadCastFailure(error));
    }
  };
};
