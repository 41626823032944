export function setSessionStorage(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSessionStorage(key) {
  var item = sessionStorage.getItem(key);

  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch (e) {
    return item;
  }
}

export function removeSessionStorage(key) {
  // remove
  sessionStorage.removeItem(key);
}
