/* eslint-disable no-unused-expressions */
/**
 * TODO: Our current version of eslint does not fully support optional chaining
 * so statements like window.adobeMediaTracker?.trackPlay(), where the result
 * of the expression is not used or assigned, trigger the no-unused-expressions
 * lint error. When we upgrade eslint (>= v7.5) and/or react-scripts (>= v4),
 * we can remove the comment above that disables the lint rule for this file.
 * More details: https://github.com/facebook/create-react-app/issues/8107
 */
import moment from "moment";
import PlayerConstants from "../constants/player";
import { getSessionStorage } from "../../shared/utils/sessionStorage";
import {
  formatTrackingValue,
  getStreamFormat,
  getRatingDetail,
  getMappedContentType,
  getMappedShowType,
  getMappedUserProfileType,
} from "../utils/analytics";
import {
  ANALYTICS_STORAGE_KEYS,
  MEDIA_VIDEO_PLAY_TYPES,
  SITE_NAMES,
  ADOBE_SDK,
  LOGIN_STATE,
  MIDDLEWARE_PLATFORM,
  MAPPED_CONTENT_TYPES,
} from "../../shared/constants/analytics";
import constants from "../../shared/constants";
import { getLink, getMediaContentDuration, getHomeStatus } from "./helpers";
import { logNREvent } from "./newRelic";
import { NR_CUSTOM_ATTRIBUTES, NR_PAGE_ACTIONS } from "../constants/newRelic";
import { PLAY_BACK_MODE } from "../constants/conviva";
import { DEFAULT_CUSTOMER_TYPE } from "../constants/analytics";
import { setCustomTags } from "@convivainc/conviva-js-appanalytics";
import { trackCustomEvent } from "@convivainc/conviva-js-appanalytics";

const { PLAYER_TYPE } = PlayerConstants;

window.adobeMediaTracker = window.adobeMediaTracker ?? null;
let isSessionStarted = false;
let isSessionRunning = false;
let totalPlayedTimeInSeconds = 0;

/**
 * Gets the totalPlayedTimeInSeconds (Live)
 */
export const getTotalPlayedTimeInSeconds = () => totalPlayedTimeInSeconds;

export const getCustomContextMetadata = (metadata = {}, userProfile, appProvider, isInHome) => {
  const isVod = PLAYER_TYPE.VOD;
  const { USER_PROPERTY_NAMES, CONTENT_TYPE } = constants;

  const { linkExtra: aaVideoPlayType } = getLink();
  const umId = metadata?.extendedMetadata?.dlum?.umId ?? null;
  const channelNumber = metadata?.channel?.number;
  const channelId = metadata?.channel?.id ?? null;
  const channelName = formatTrackingValue(metadata.channel?.channelName || metadata.channel?.metadata?.channelName);
  const isSPLUS = userProfile?.user?.profile?.profileData?.properties?.[0]?.propertyName === USER_PROPERTY_NAMES.SPLUS;
  const isFast = metadata.channel?.metadata?.extendedMetadata?.isFast === "Y" || false;
  const customData = {
    "aa.video.language": formatTrackingValue(
      isVod
        ? metadata.extendedMetadata?.dlum?.defaultLanguage?.toLowerCase()
        : metadata.channel?.metadata?.channelLanguage?.toLowerCase()
    ),
    "aa.video.playType":
      aaVideoPlayType === MEDIA_VIDEO_PLAY_TYPES.RECOMMENDATION
        ? MEDIA_VIDEO_PLAY_TYPES.RECOMMENDATION
        : MEDIA_VIDEO_PLAY_TYPES.STANDARD_LISTING,
    // "aa.playbackLocation": null, // NA
    "aa.video.rating.detail": getRatingDetail(
      metadata.extendedMetadata?.dlum?.rt?.criticScore,
      metadata.extendedMetadata?.dlum?.rt?.fanScore
    ),
    "aa.contentType": getMappedContentType(!isVod, metadata.playbackType, metadata.mappedContentType, false, isFast),
    "aa.avsid": getSessionStorage(ANALYTICS_STORAGE_KEYS.SESSION_ID),
    "aa.providerId": channelName,
    "aa.lookback": metadata.mappedContentType === MAPPED_CONTENT_TYPES.LOOKBACK,
    "aa.tamsId": isVod ? formatTrackingValue(metadata.externalId) : null,
    "aa.programId": metadata?.extendedMetadata?.epg?.mediaroom?.programId ?? null,
    "aa.channelId": channelId,
    "aa.tmsId": metadata.extendedMetadata?.dlum?.gracenote?.tmsId ?? null,
    "aa.seriesUmId": metadata?.extendedMetadata?.dlum?.series?.umId ?? null,
    "aa.seasonUaId": metadata?.extendedMetadata?.dlum?.series?.uaId ?? null,
    "aa.seasonId": metadata.season ?? null,
    "aa.uaid": metadata?.extendedMetadata?.dlum?.uaId ?? null,
    "s.products": umId,
    "aa.dlumContentId": formatTrackingValue(String(metadata?.contentId || "")),
    "media.channel": channelNumber,
    "aa.profile": userProfile?.user?.profile?.profileData?.userId ?? null,
    "aa.profileType": getMappedUserProfileType(userProfile?.user?.profile?.profileData),
    "aa.user.pid": (userProfile?.masterAccountHouseholdID || userProfile?.user?.profile?.profileData?.username) ?? null,
    "aa.uuid": userProfile?.user?.profile?.profileData?.uuidExternal,
    "aa.customerType": formatTrackingValue(
      isSPLUS
        ? userProfile?.user?.profile?.profileData?.properties?.[0]?.propertyName.toLowerCase() +
            userProfile?.user?.profile?.profileData?.customAttributes?.[0]?.attributeValue?.toLowerCase()
        : appProvider?.customerType !== DEFAULT_CUSTOMER_TYPE
        ? appProvider?.customerType
        : userProfile?.user?.profile?.profileData?.userPropertyType || ""
    ),
    "aa.customerRegion": userProfile?.user?.profile?.profileData?.geoProvinceCode,
    "aa.panicMode": appProvider?.panicMode,
    "aa.page.contentId": formatTrackingValue(metadata?.contentId || ""),
    "aa.previous.page": getSessionStorage(ANALYTICS_STORAGE_KEYS.PREVIOUS_PAGE_NAME),
    "aa.siteName": userProfile?.isLoggedIn
      ? isSPLUS
        ? USER_PROPERTY_NAMES.SPLUS.toLowerCase()
        : userProfile.user?.profile?.profileData?.isOptik
        ? SITE_NAMES.OPTIK_TV
        : SITE_NAMES.PIK_TV
      : null,
    "aa.sdkversion": ADOBE_SDK,
    "aa.media.format": getMappedContentType(!isVod, metadata.playbackType, metadata.mappedContentType),
    "aa.login.status": userProfile?.isLoggedIn ? LOGIN_STATE.LOGGED_IN : LOGIN_STATE.LOGGED_OUT,
    "aa.homeStatus": getHomeStatus(isInHome),
    "aa.geoRange": userProfile?.user?.profile?.profileData?.geoCityName
      ? `geo-${formatTrackingValue(userProfile?.user?.profile?.profileData?.geoCityName)}`
      : null,
    "aa.middlewarePlatform": MIDDLEWARE_PLATFORM,
    "aa.deviceLanguage": appProvider?.lang.toLowerCase(),
    "aa.timezoneOffset": `${moment().format("HHmm")}|${moment().utcOffset() / 60}`,
    "aa.linkPosition": getSessionStorage(ANALYTICS_STORAGE_KEYS.LINK),
    "aa.findingMethod.navigation": formatTrackingValue(getSessionStorage(ANALYTICS_STORAGE_KEYS.FINDING_METHOD)),
    "aa.playbackMode": PLAY_BACK_MODE.LOCAL,
  };
  return customData;
};

/**
 * Tracks the start of a media session
 * @param {Object} metadata
 * @param {String} type Player type ( vod|live )
 * @param {Object} userProfile
 * @param {Boolean} isResumed
 */
export function trackMediaStart(metadata = {}, type, userProfile, appProvider, isInHome, isResumed = false) {
  if (!window.adobeMediaTracker || !window.ADB?.Media) {
    return;
  }
  const MediaApi = window.ADB.Media;
  const isVod = type === PLAYER_TYPE.VOD || type === PLAYER_TYPE.RECORDING;

  if (isSessionStarted) {
    trackMediaEnd();
  }

  const umId = metadata?.extendedMetadata?.dlum?.umId ?? null;
  const name = formatTrackingValue(metadata?.episodeTitle || metadata.title);
  const channelNumber = metadata?.channel?.number;
  const duration = getMediaContentDuration(isVod || metadata?.programDuration, {
    duration: metadata?.duration || metadata?.programDuration,
    airingStartTime: metadata?.airingStartTime,
    airingEndTime: metadata?.airingEndTime,
  });

  window.adobeMediaTracker =
    isVod || !channelNumber
      ? window.ADB.Media.getInstance()
      : window.ADB.Media.getInstance({ "media.channel": channelNumber.toString() });

  let mediaObject = MediaApi.createMediaObject(
    name,
    umId,
    duration ?? 0,
    isVod ? (type === PLAYER_TYPE.RECORDING ? "pvr" : MediaApi.StreamType.VOD) : MediaApi.StreamType.Linear,
    MediaApi.MediaType.Video
  );

  if (!mediaObject) {
    return;
  }

  if (isResumed) {
    mediaObject[MediaApi.MediaObjectKey.MediaResumed] = true;
  }

  const genres = metadata.genres ?? metadata.extendedMetadata?.dlum?.displayGenres;
  const channelName = formatTrackingValue(metadata.channel?.channelName || metadata.channel?.metadata?.channelName);
  const firstAirDate =
    metadata.extendedMetadata?.dlum?.originalAirDate ||
    (metadata.airingStartTime ? moment(metadata.airingStartTime).format("YYYY-MM-DD") : null) ||
    null;
  const airingTime = metadata.airingStartTime
    ? moment(metadata.airingStartTime / 1000).toISOString()
    : moment().toISOString();
  const contextMetadata = getCustomContextMetadata(metadata, userProfile, appProvider, isInHome);

  const contextData = {
    // Standard metadata
    [MediaApi.VideoMetadataKeys.Show]: metadata?.episodeTitle ? formatTrackingValue(metadata.title) : null,
    [MediaApi.VideoMetadataKeys.StreamFormat]: formatTrackingValue(
      getStreamFormat(
        metadata?.isUHD || metadata.extendedMetadata?.isUHD,
        metadata?.isHDR || metadata.extendedMetadata?.isHDR
      )
    ),
    [MediaApi.VideoMetadataKeys.Season]: formatTrackingValue(metadata.season),
    [MediaApi.VideoMetadataKeys.Episode]: formatTrackingValue(metadata.episodeId || metadata.episodeNumber),
    [MediaApi.VideoMetadataKeys.AssetId]: umId,
    [MediaApi.VideoMetadataKeys.Genre]: formatTrackingValue(genres?.join(",")),
    [MediaApi.VideoMetadataKeys.FirstAirDate]: firstAirDate ?? null,
    [MediaApi.VideoMetadataKeys.Rating]: formatTrackingValue(metadata.extendedMetadata?.dlum?.rating),
    [MediaApi.VideoMetadataKeys.Network]: channelName,
    [MediaApi.VideoMetadataKeys.Authorized]: userProfile.isLoggedIn,
    [MediaApi.VideoMetadataKeys.DayPart]: airingTime.substring(0, airingTime.indexOf(".")),
    [MediaApi.VideoMetadataKeys.ShowType]: getMappedShowType(
      metadata.extendedMetadata?.dlum?.uaGroupType,
      metadata.playbackType
    ),
    // Data list below is NA for now
    // [MediaApi.VideoMetadataKeys.FirstDigitalDate]: null,
    // [MediaApi.VideoMetadataKeys.Originator]: null,
    // [MediaApi.VideoMetadataKeys.MVPD]: null,
    // [MediaApi.VideoMetadataKeys.Feed]: null,
    // Custom metadata
    ...contextMetadata,
  };

  setCustomTags(contextMetadata);
  window.adobeMediaTracker.trackSessionStart(mediaObject, contextData);
  isSessionStarted = true;
}

/**
 * Tracks media play or resume
 */
export function trackMediaPlay(nrAttribuites = {}) {
  isSessionRunning = true;
  window.adobeMediaTracker?.trackPlay();
  logNREvent(NR_PAGE_ACTIONS.PLAY_START, nrAttribuites);
}

/**
 * Tracks media pause
 */
export function trackMediaPause() {
  isSessionRunning = false;
  window.adobeMediaTracker?.trackPause();
}

/**
 * Tracks media complete
 */
export function trackMediaComplete(trackBookmarkCallback) {
  isSessionRunning && trackBookmarkCallback && trackBookmarkCallback();
  window.adobeMediaTracker?.trackComplete();
  isSessionRunning = false;
  totalPlayedTimeInSeconds = 0;
}

/**
 * Tracks end of the previously started media session
 */
export function trackMediaEnd(trackBookmarkCallback) {
  if (isSessionStarted) {
    trackBookmarkCallback && trackBookmarkCallback && trackBookmarkCallback();
    window.adobeMediaTracker?.trackSessionEnd();
    isSessionStarted = false;
    isSessionRunning = false;
    totalPlayedTimeInSeconds = 0;
    logNREvent(NR_PAGE_ACTIONS.PLAY_END);
  }
}

/**
 * Tracks media seek
 */
export function trackMediaSeek() {
  window.adobeMediaTracker?.trackEvent(window.ADB?.Media.Event.SeekStart);
}

/**
 * Tracks media seek complete
 */
export function trackMediaSeeked() {
  window.adobeMediaTracker?.trackEvent(window.ADB?.Media.Event.SeekComplete);
}

/**
 * Tracks media buffer start
 */
export function trackMediaBufferStart() {
  window.adobeMediaTracker?.trackEvent(window.ADB?.Media.Event.BufferStart);
}

/**
 * Tracks media buffer complete
 */
export function trackMediaBufferComplete() {
  window.adobeMediaTracker?.trackEvent(window.ADB?.Media.Event.BufferComplete);
}

/**
 * Tracks media bitrate change
 * @param {Object} qoeData Quality of experience data, including bitrate, framerate, dropped frames, etc.
 */
export function trackMediaBitrateChange(qoeData) {
  const { bitrate, startupTime, fps, droppedFrames } = qoeData;
  window.adobeMediaTracker?.updateQoEObject(
    window.ADB?.Media.createQoEObject(bitrate ?? 0, startupTime ?? 0, fps ?? 0, droppedFrames ?? 0)
  );
  window.adobeMediaTracker?.trackEvent(window.ADB?.Media.Event.BitrateChange);
}

/**
 * Tracks media or playback error
 * @param {String|Number} errorId Error identifier
 * @param {String|Number} errorName Error name
 */
export function trackMediaError(errorId, errorName) {
  window.adobeMediaTracker?.trackError(formatTrackingValue(`${errorId}`));
  logNREvent(NR_PAGE_ACTIONS.PLAY_ERROR, { message: errorName });
}

/**
 * Tracks player state start and end events
 * @param {window.ADB.Media.PlayerState} playerState
 * @param {Boolean} isStart `true` for start, `false` for end
 */
function trackPlayerStateChange(playerState, isStart) {
  const stateObject = window.ADB?.Media.createStateObject(playerState);
  window.adobeMediaTracker?.trackEvent(window.ADB?.Media.Event[isStart ? "StateStart" : "StateEnd"], stateObject);
}

/**
 * Tracks player mute and unmute states
 * @param {Boolean} isMute `true` for mute, `false` for unmute
 */
export function trackPlayerMute(isMute) {
  trackPlayerStateChange(window.ADB?.Media.PlayerState.Mute, isMute);
}

/**
 * Tracks player fullscreen state
 * @param {Boolean} isEnterFullscreen `true` for enter fullscreen, `false` for exit fullscreen
 */
export function trackPlayerFullscreen(isEnterFullscreen) {
  trackPlayerStateChange(window.ADB?.Media.PlayerState.FullScreen, isEnterFullscreen);
}

/**
 * Tracks toggling closed captions
 * @param {Boolean} isEnabled `true` for enabling captions, `false` for disabling captions
 */
export function trackPlayerClosedCaption(isEnabled) {
  trackPlayerStateChange(window.ADB?.Media.PlayerState.ClosedCaption, isEnabled);
  logNREvent(NR_PAGE_ACTIONS.TOGGLE_CC, { [NR_CUSTOM_ATTRIBUTES.TOGGLE_ON]: isEnabled });
}

/**
 * Tracks playhead change
 * @param {Boolean} isPlayheadAvailable `true` if playhead is available
 * @param {Function} getPlayheadCallback a callback function that returns the current playhead
 */
export function trackPlayheadChange(isPlayheadAvailable, getPlayheadCallback) {
  if (!isSessionStarted || !isSessionRunning) return;
  if (isPlayheadAvailable) {
    window.adobeMediaTracker?.updatePlayhead(getPlayheadCallback());
  } else {
    window.adobeMediaTracker?.updatePlayhead(++totalPlayedTimeInSeconds);
  }
}

/**
 * Track custom Event check for Conviva
 *
 * @param {String} name ANALYTICS_EVENT_TYPES
 * @param {Object} value data object
 **/
export function trackConvivaCustomEvent(name, value) {
  const data = JSON.stringify(value);
  trackCustomEvent({
    name,
    data,
  });
}
