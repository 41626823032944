import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { loadSimilarItems, LOAD_ALL_SIMILAR_ITEMS } from "./state/actions";
import { resetAction } from "../../App/state/actions";
import ViewAll from "../../components/ViewAll";
import { convertTitleItemsListToSwimLaneModel } from "../../shared/utils/swimlane";
import swimlaneConstants from "../../shared/constants/swimlane";
import constants from "../../shared/constants";
import { trackGenericAction } from "../../shared/analytics/dataLayer";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import middleware from "../../shared/middleware";
import { getPcLevelRestriction } from "../../shared/utils";
import { ANALYTICS_EVENT_TYPES, EXTRA_METADATA_TYPES } from "../../shared/constants/analytics";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import useTrackPageView from "../../shared/hooks/useTrackPageView";

const { ITEM_TYPES, SWIMLANE_TITLES } = swimlaneConstants;
const { CONTENT_ITEM_TYPES } = constants;
const dimension = ITEM_TYPES.TITLE_ITEM.PORTRAIT.DIMENSIONS.values;
const { getSimilarItems } = middleware;

/**
 * Similar Item page component
 *
 * @component
 * @param {Object} props
 */

const SimilarItemsPage = (props) => {
  const { appProvider, loadSimilarItems, content, resetAction, totalContent, userProfile, featureToggles } = props;

  const { isUserProfilesEnabled, isParentalPINEnabled } = featureToggles;
  const [showViewAll, setShowViewAll] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const itemType = query.get("itemType");
  const contentType = itemType === "vod" ? "VOD" : "PROGRAM";
  const itemSubType = query.get("itemSubType");
  const genres = query.get("genres");
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const { trackPageView } = useTrackPageView();

  const pcLevel = getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled);

  useEffect(() => {
    loadSimilarItems(appProvider, contentType, genres, itemSubType, userProfile, pcLevel, cancelTokenSource);
    return () => {
      resetAction(LOAD_ALL_SIMILAR_ITEMS, "content");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showViewAll && itemsList) {
      trackPageView({
        extraMetadataList: [
          {
            type: EXTRA_METADATA_TYPES.RECOMMENDATION_IMPRESSIONS,
            asset: { items: itemsList, title: SWIMLANE_TITLES.SIMILAR_ITEMS },
          },
        ],
      });
    }
  }, [appProvider, showViewAll, itemsList, trackPageView]);

  useEffect(() => {
    content !== null && setShowViewAll(true);
    if (content) {
      setItemsList(convertTitleItemsListToSwimLaneModel(content, dimension, isAppLanguageFrench, userProfile));
    }
  }, [content, isAppLanguageFrench, userProfile]);

  const loadNextSetOfItems = async (startIndex, endIndex) => {
    setIsLoading(true);
    try {
      const feedContent = await getSimilarItems(
        appProvider,
        contentType,
        genres,
        itemSubType,
        userProfile,
        pcLevel,
        cancelTokenSource,
        startIndex,
        endIndex
      );
      if (feedContent) {
        const newItemList = convertTitleItemsListToSwimLaneModel(
          feedContent.containers?.filter((item) => item?.layout && item?.metadata),
          dimension,
          isAppLanguageFrench,
          userProfile
        );
        setItemsList((itemsList) => [...itemsList, ...newItemList]);
        setIsLoading(false);
        trackGenericAction(ANALYTICS_EVENT_TYPES.RECOMMENDATION_IMPRESSIONS, {
          assets: newItemList,
          startingIndex: itemsList?.length || 0,
          title: SWIMLANE_TITLES.SIMILAR_ITEMS,
        });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to retrieve search items", error);
    }
  };

  return !itemType ||
    (itemType !== CONTENT_ITEM_TYPES.programs &&
      itemType !== CONTENT_ITEM_TYPES.vod &&
      itemType !== CONTENT_ITEM_TYPES.series &&
      itemType !== CONTENT_ITEM_TYPES.episode) ? (
    <Redirect to="/" />
  ) : (
    showViewAll && (
      <ViewAll
        title={translate(SWIMLANE_TITLES.SIMILAR_ITEMS)}
        history={history}
        items={itemsList}
        totalContent={totalContent}
        isLoading={isLoading}
        loadNextSetOfItems={loadNextSetOfItems}
      />
    )
  );
};

SimilarItemsPage.propTypes = {
  appProvider: PropTypes.object.isRequired,
  loadSimilarItems: PropTypes.func.isRequired,
  content: PropTypes.array,
  resetAction: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  featureToggles: PropTypes.object,
};

SimilarItemsPage.defaultProps = {
  content: [],
};

function mapStateToProps({ app, similarItems }) {
  return {
    appProvider: app.provider,
    content: similarItems.content,
    totalContent: similarItems.total,
    userProfile: app.userProfile,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  loadSimilarItems,
  resetAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimilarItemsPage);
