import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SeoPageTags from "../../components/SeoPageTags";
import SwimlaneItem from "../SwimlaneItem";
import SpinningLoader from "../../components/SpinningLoader";
import ImageButton from "../ImageButton";
import Breadcrumb from "../Breadcrumb";
import paginationConstants from "../../shared/constants/pagination";
import constants from "../../shared/constants";
import classNames from "classnames";
import { ANALYTICS_STORAGE_KEYS } from "../../shared/constants/analytics";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { buildLink } from "../../shared/analytics/helpers";
import { useConvivaContentSubType } from "../../shared/hooks/useConvivaContentSubType";
import { setConvivaContentSubType } from "../../App/state/actions";
import { useHistory } from "react-router-dom";

import "./style.scss";
import { useReducers } from "../../shared/hooks/useReducer";

const { ITEMS_PER_PAGE } = paginationConstants; // set to 100 for view all pages
const { REDUCER_TYPE } = constants;
/**
 * VIEW ALL page component
 * @param {Object} props - component properties
 */

function ViewAll(props) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    subTitle,
    title,
    items,
    isFromCastDetail,
    searchTerm,
    noResultFound,
    loadNextSetOfItems,
    filterParams,
    sortParams,
    totalContent,
    isLoading,
  } = props;
  const { provider: appProvider } = useReducers(REDUCER_TYPE.APP);
  const [targetElement, setTargetElement] = useState(null); // element whose intersection with root element(viewport) would be observed
  const [thresholdIndex, setThresholdIndex] = useState(null);
  const convivaContSubType = useConvivaContentSubType((subType) => dispatch(setConvivaContentSubType(subType)));
  const breadCrumbSegments = title && title.includes("/") ? title.split("/").reverse() : [];
  const isTeamsPage = title === translate("teams");

  // Using refs to share updated data with observer and avoid component's stale closure
  const totalLoadedItems = useRef(null); // number of items loaded
  const currentPage = useRef(1); // page number that user is currently on, each page contains at most 100 items
  const queryParams = useRef(null); // filter and sort values (if any)
  const totalItems = useRef(null); // total number of items that can be returned from the api
  const isLoadingItems = useRef(false); // boolean to ensure we load only immediate next set of data

  useEffect(() => {
    if (items.length > 0) {
      setThresholdIndex(items.length - 1); // setting last element of the loaded items as target to trigger next load
      totalLoadedItems.current = items.length;
    }
  }, [items]);

  useEffect(() => {
    const updatedQueryParams = { filterParams, sortParams };
    queryParams.current = updatedQueryParams;
    currentPage.current = 1; // re-setting current page number to first on filter or sort update
  }, [filterParams, sortParams]);

  useEffect(() => {
    totalItems.current = totalContent;
  }, [totalContent]);

  const observerOptions = {
    root: null, // using root as null, as it allows observer to use viewport as reference, also apparently using any other value for this property is not supported for Safari
    rootMargin: "0px",
    threshold: 0.5, // observer callback to be called if 50% of target is visible within root element(viewport)
  };

  const handleObserver = (entities) => {
    const first = entities[0];
    if (!isLoadingItems.current && first.isIntersecting) {
      // loading next page if previous set is loaded and user scrolls to next page
      const nextEndIndex = (currentPage.current + 1) * ITEMS_PER_PAGE;
      currentPage.current = currentPage.current + 1;

      if (totalLoadedItems.current < nextEndIndex) {
        isLoadingItems.current = true;
        loadNextSetOfItems(
          nextEndIndex - ITEMS_PER_PAGE,
          nextEndIndex - 1,
          queryParams.current.filterParams,
          queryParams.current.sortParams
        );
      }
    }
  };

  // Using intersection observer api to avoid using browser's main thread for calculating scroll positions
  const observer = useRef(new IntersectionObserver(handleObserver, observerOptions));

  useEffect(() => {
    const currentElement = targetElement;
    const currentObserver = observer.current;
    if (currentElement) {
      currentObserver.observe(currentElement);
      isLoadingItems.current = false;
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [targetElement]);

  const translateSubtitle = (subtitle) => {
    if (isFromCastDetail) {
      if (subtitle.includes(",")) {
        return subtitle
          .split(",")
          .map((item) => item)
          .join();
      } else {
        return subtitle;
      }
    } else {
      if (subtitle.includes(",")) {
        return subtitle
          .split(",")
          .map((item) => translate(item.toLowerCase()))
          .join();
      } else {
        return translate(subtitle.toLowerCase());
      }
    }
  };

  const getSubtitleString = (items) => {
    let subtitleString;
    if (typeof searchTerm === "string") {
      subtitleString = "results";
    } else {
      if (items.length > 1) {
        subtitleString = "titles";
      } else {
        subtitleString = "title";
      }
    }
    return subtitleString;
  };

  /**
   *
   * @param {Object} item Swimlane item object
   * @param {Number} itemIndex Grid item's index
   * @returns analytics callback function to trigger when the specified grid item is clicked
   */
  const getItemClickAnalyticsCallback = useCallback(
    (item, itemIndex) => {
      return (userData) => {
        setSessionStorage(ANALYTICS_STORAGE_KEYS.FINDING_METHOD, title);
        setSessionStorage(
          ANALYTICS_STORAGE_KEYS.LINK,
          buildLink(title, null, itemIndex, item.analyticsSrc?.metadata?.title)
        );

        if (title === translate("resume")) {
          convivaContSubType.updateContSubTypeFromUserData(
            appProvider,
            { item: item.analyticsSrc?.metadata },
            userData
          );
        } else if (searchTerm) {
          setSessionStorage(ANALYTICS_STORAGE_KEYS.SEARCH_TERM, searchTerm);
        }
      };
    },
    [appProvider, convivaContSubType, searchTerm, title, translate]
  );

  const getGridItems = useCallback(
    (items, thresholdIndex) => {
      return items.map((item, index) => (
        <div className="column" key={`${index}-${item.id}`} ref={index === items.length - 1 ? setTargetElement : null}>
          <SwimlaneItem
            key={item.id}
            {...item}
            itemClickAnalyticsCallback={getItemClickAnalyticsCallback(item, index)}
            swimlaneTitle={title}
          />
        </div>
      ));
    },
    [getItemClickAnalyticsCallback, title]
  );

  const memoizedGridItems = useMemo(() => getGridItems(items, thresholdIndex), [items, thresholdIndex, getGridItems]);

  return (
    items && (
      <>
        <SeoPageTags title={title} keywords={["optik", "telus"]} />
        <div className="grid">
          <div className="gridHeader">
            <ImageButton
              src={process.env.PUBLIC_URL + "/images/back.svg"}
              onClickHandler={history.goBack}
              alt={"viewAllBack"}
              className="viewAllBack"
            />
            {searchTerm ? (
              <h1>
                {title}
                {translate("punctuation:colon")}
                <span className="searchTerm">
                  {translate("punctuation:open_quote")}
                  {searchTerm}
                  {translate("punctuation:close_quote")}
                </span>
              </h1>
            ) : breadCrumbSegments.length === 0 ? (
              <h1>{title}</h1>
            ) : (
              <Breadcrumb breadCrumbSegments={breadCrumbSegments} />
            )}
            <div className="subtitle-container">
              {subTitle && <h2 className={items.length ? "subtitle" : ""}>{translateSubtitle(subTitle)}</h2>}
              {items.length > 0 && !noResultFound ? (
                <h2>
                  {(totalContent || items.length) + " "}
                  {translate(getSubtitleString(items)).toLowerCase()}
                </h2>
              ) : undefined}
            </div>
          </div>
          {noResultFound ? (
            <div className="no-result-found">{translate("no_results_found")}</div>
          ) : (
            <div
              className={classNames("row", {
                landscape: title === translate("resume") || isTeamsPage,
                teams: isTeamsPage,
              })}
            >
              {memoizedGridItems}
            </div>
          )}
        </div>
        {isLoading && (
          <div className="view-all-loading-wrapper">
            <SpinningLoader />
          </div>
        )}
      </>
    )
  );
}

export default ViewAll;

ViewAll.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  subTitle: PropTypes.string,
  imgSrc: PropTypes.string,
  loadNextSetOfItems: PropTypes.func,
  filterParams: PropTypes.string,
  sortParams: PropTypes.string,
  totalContent: PropTypes.number,
};

ViewAll.defaultProps = {
  subTitle: null,
  imgSrc: null,
  title: null,
  loadNextSetOfItems: () => {},
  filterParams: "",
  sortParams: "",
  totalContent: 0,
};
