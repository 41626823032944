import middleware from "../../../shared/middleware";
import constants from "../../../shared/constants";
import paginationConstants from "../../../shared/constants/pagination";
import { addQueryParamsToString, getKidExclusionRating } from "../../../shared/utils";

const { getSvodItems, retrieveAvsItems } = middleware;
const { INITIAL_START_INDEX, INITIAL_END_INDEX_VIEW_ALL } = paginationConstants;

// Action types
export const LOAD_SVOD_PKG = "LOAD_SVOD_PKG";
export const LOAD_SVOD_PAGE_CONTAINERS = "LOAD_SVOD_PAGE_CONTAINERS";
export const LOAD_SVOD_PAGE_DETAILS = "LOAD_SVOD_PAGE_DETAILS";

const { CONTAINER_TYPES, CONTAINER_ACTION_TYPE, CONTAINER_LAYOUTS } = constants;

// Action methods
function loadSvodItemsSuccess(content) {
  return { type: LOAD_SVOD_PKG, content: content.containers, total: content.total };
}
function loadSvodItemsFailure() {
  return { type: LOAD_SVOD_PKG, content: [] };
}
function loadPageContainersSuccess(content) {
  return { type: LOAD_SVOD_PAGE_CONTAINERS, content };
}
function loadPageContainersFailure() {
  return { type: LOAD_SVOD_PAGE_CONTAINERS, content: [] };
}
function loadSvodPageDetailsSuccess(content) {
  return { type: LOAD_SVOD_PAGE_DETAILS, content };
}
function loadSvodPageDetailsFailure() {
  return { type: LOAD_SVOD_PAGE_DETAILS, content: [] };
}

export const loadSVODItems = (
  appProvider,
  provider,
  subType,
  userProfile = null,
  pcLevel = null,
  cancelTokenSource = null,
  startIndex = INITIAL_START_INDEX,
  endIndex = INITIAL_END_INDEX_VIEW_ALL
) => {
  return async (dispatch) => {
    try {
      const feedContent = await getSvodItems(
        appProvider,
        provider,
        subType,
        pcLevel,
        cancelTokenSource,
        userProfile,
        startIndex,
        endIndex
      );
      if (feedContent?.containers) {
        dispatch(loadSvodItemsSuccess(feedContent));
      }
    } catch (error) {
      dispatch(loadSvodItemsFailure());
    }
  };
};

export const loadSVODContainerItems = (
  appProvider,
  actionUri,
  sVODContainerItems,
  userProfile = null,
  pcLevel = null,
  startIndex = INITIAL_START_INDEX,
  endIndex = INITIAL_END_INDEX_VIEW_ALL
) => {
  let successAction;
  let failureAction;
  switch (sVODContainerItems) {
    case CONTAINER_TYPES.CONTAINERS:
      successAction = loadPageContainersSuccess;
      failureAction = loadPageContainersFailure;
      break;
    case CONTAINER_ACTION_TYPE.WALL_PAGE:
      successAction = loadSvodItemsSuccess;
      failureAction = loadSvodItemsFailure;

      actionUri = addQueryParamsToString(
        actionUri,
        {
          from: startIndex,
          to: endIndex,
          maxResults: endIndex + 1,
        },
        true
      );

      if (pcLevel) {
        const queryParam = { filter_pcLevel: pcLevel };
        getKidExclusionRating(userProfile, queryParam);
        actionUri = addQueryParamsToString(actionUri, queryParam, true);
      }
      break;
    case CONTAINER_LAYOUTS.CONTENT_DETAIL:
      successAction = loadSvodPageDetailsSuccess;
      failureAction = loadSvodPageDetailsFailure;
      break;
    default:
      break;
  }
  return async (dispatch) => {
    try {
      const feedContent = await retrieveAvsItems(appProvider, actionUri);
      if (feedContent) {
        if (feedContent.containers) {
          if (sVODContainerItems === CONTAINER_ACTION_TYPE.WALL_PAGE) {
            dispatch(successAction(feedContent));
          } else {
            dispatch(successAction(feedContent.containers));
          }
        }
      }
    } catch (error) {
      dispatch(failureAction());
    }
  };
};
