import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { getRecordingAction } from "../pages/RecordingsPage/state/actions";
import { getRecordingProfile, retrieveFavourite, retrieveSearchCollection } from "../App/state/actions";
import { getRecordingSystemType } from "../shared/utils/recordingHelper";
import constants from "../shared/constants";
import playerConstants from "../shared/constants/player";
import recordingConstants from "../shared/constants/recordingConstants";
import routes from "../shared/constants/routes";
import { getFeatureProperties } from "../shared/utils";
import { convivaAppTracker, trackPageView, setUserId } from "@convivainc/conviva-js-appanalytics";
import { PerformanceTimingPlugin } from "@convivainc/conviva-js-appanalytics-performance-timing";
import { ErrorTrackingPlugin, enableErrorTracking } from "@convivainc/conviva-js-appanalytics-error-tracking";
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
  enableButtonClickTracking,
} from "@convivainc/conviva-js-appanalytics-click-tracking";
import { ANALYTICS_EVENT_TYPES } from "../shared/constants/analytics";
import { trackConvivaCustomEvent } from "../shared/analytics/media";
import { getRecordingsBookmarks } from "../shared/middleware/recordings";

const { PAGE_CONTENT_ITEM_TYPES } = constants;
const { CAST_FRAMEWORK_URL } = playerConstants;
const { RECORDING_PACKAGES, RECORDING_PARAMS, MR_RECORDING_FILTER_OPTIONS } = recordingConstants;
const { RECORDINGS } = routes;

/**
 * The AppLaunchManager component handles any data fetching that should be initialized on app launch,
 * regardless of the page the user starts on.
 *
 * @param {Object} props - React component props
 * @returns null
 */
const AppLaunchManager = (props) => {
  const {
    appProvider,
    userProfile,
    getRecordingAction,
    retrieveFavourite,
    subscribedChannels,
    featureToggles,
    getRecordingProfile,
    retrieveSearchCollection,
  } = props;
  const {
    isChromecastEnabled: isChromecastAvailable,
    isFavouritesEnabled,
    isRecordingEnabled,
    isConvivaAppTrackerEnabled,
    isUserProfilesEnabled,
    isSearchCollectionEnabled,
  } = featureToggles;

  const fetchRecordings = useCallback(() => {
    // Only fetch the recordings if the feature is enabled and we aren't on the PVR manager page (we trigger the recording fetch on PVR manager)
    if (appProvider && isRecordingEnabled && !window.location.href.includes(RECORDINGS.route)) {
      getRecordingAction(
        appProvider,
        getRecordingSystemType(userProfile) === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP,
        true,
        null,
        [RECORDING_PARAMS.EVENT, RECORDING_PARAMS.SERIES],
        MR_RECORDING_FILTER_OPTIONS.SCHEDULED_PAGE_FILTERS
      );
    }
  }, [appProvider, getRecordingAction, isRecordingEnabled, userProfile]);

  const fetchFavouriteChannels = useCallback(() => {
    // Make the favourite channel call on app load to update our cache.
    if (appProvider && userProfile?.isLoggedIn && isFavouritesEnabled && subscribedChannels) {
      retrieveFavourite(appProvider, PAGE_CONTENT_ITEM_TYPES.live, subscribedChannels);
    }
  }, [appProvider, userProfile, isFavouritesEnabled, subscribedChannels, retrieveFavourite]);

  const fetchFavouriteAssets = useCallback(() => {
    // Make the favourite assets call on app load to update our cache.
    if (appProvider && userProfile?.isLoggedIn && isFavouritesEnabled) {
      retrieveFavourite(appProvider, `${PAGE_CONTENT_ITEM_TYPES.movie},${PAGE_CONTENT_ITEM_TYPES.tvShow}`);
    }
  }, [appProvider, userProfile, retrieveFavourite, isFavouritesEnabled]);

  const fetchSearchCollection = useCallback(() => {
    // Make the search collection call on app load to update our cache.
    const isKidsProfile = isUserProfilesEnabled && userProfile?.user?.profile?.profileData?.kidsProfile;
    if (appProvider && !isKidsProfile && isSearchCollectionEnabled) {
      retrieveSearchCollection(appProvider);
    }
  }, [appProvider, userProfile, isUserProfilesEnabled, retrieveSearchCollection, isSearchCollectionEnabled]);

  const fetchRecordingProfile = useCallback(() => {
    const recordingSystemType = getRecordingSystemType(userProfile);
    if (isRecordingEnabled && recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.CPVR_TP) {
      getRecordingProfile(appProvider);
    }
  }, [appProvider, isRecordingEnabled, userProfile, getRecordingProfile]);

  const fetchBookmarks = useCallback(() => {
    if (appProvider && isRecordingEnabled) {
      getRecordingsBookmarks(appProvider);
    }
  }, [appProvider, isRecordingEnabled]);

  useEffect(() => {
    fetchRecordings();
  }, [fetchRecordings]);

  useEffect(() => {
    fetchFavouriteChannels();
  }, [fetchFavouriteChannels]);

  useEffect(() => {
    fetchFavouriteAssets();
  }, [fetchFavouriteAssets]);

  useEffect(() => {
    fetchRecordingProfile();
  }, [fetchRecordingProfile]);

  useEffect(() => {
    fetchSearchCollection();
  }, [fetchSearchCollection]);

  useEffect(() => {
    fetchBookmarks();
  }, [fetchBookmarks]);

  useEffect(() => {
    // Load Adobe library
    const adobeScript = document.createElement("script");
    adobeScript.src = appProvider.config.adobe_lib_url;
    adobeScript.async = false;
    adobeScript.onload = () => {
      window.adobeMediaTracker = window.ADB?.Media?.getInstance();
    };
    document.body.appendChild(adobeScript);
  }, [appProvider]);

  useEffect(() => {
    if (appProvider && isConvivaAppTrackerEnabled) {
      convivaAppTracker({
        appId: appProvider.config.general.environment,
        convivaCustomerKey: appProvider.config.conviva.customer_key,
        contexts: {
          performanceTiming: true,
        },
        plugins: [PerformanceTimingPlugin(), ErrorTrackingPlugin(), LinkClickTrackingPlugin()],
      });
      setUserId(appProvider.accountID);
      trackPageView();
      enableLinkClickTracking(); // Tracks all link clicks on the page
      enableButtonClickTracking();
      enableErrorTracking();
      if (userProfile.isLoggedIn) {
        trackConvivaCustomEvent(ANALYTICS_EVENT_TYPES.LOGIN_COMPLETE, { loginComplete: true });
      }
    }
  }, [appProvider, userProfile, isConvivaAppTrackerEnabled]);

  useEffect(() => {
    // Load Chromecast sender framework to prevent tab casting
    if (isChromecastAvailable && getFeatureProperties(appProvider, "Chromecast")?.disableTabCasting) {
      window["__onGCastApiAvailable"] = function (isAvailable) {
        if (isAvailable && window.cast && window.chrome) {
          window.cast.framework.CastContext.getInstance().setOptions({
            receiverApplicationId: process.env.REACT_APP_CAST_RECEIVER_ID,
            autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
          });
        }
      };
      const castFrameworkScript = document.createElement("script");
      castFrameworkScript.src = CAST_FRAMEWORK_URL;
      document.body.appendChild(castFrameworkScript);
    }
  }, [appProvider, isChromecastAvailable]);

  return null;
};

function mapStateToProps({ app }) {
  return {
    appProvider: app.provider,
    userProfile: app.userProfile,
    subscribedChannels: app.subscribedChannels,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  getRecordingAction,
  retrieveFavourite,
  getRecordingProfile,
  retrieveSearchCollection,
  getRecordingsBookmarks,
};

AppLaunchManager.propTypes = {
  appProvider: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  getRecordingAction: PropTypes.func.isRequired,
  retrieveFavourite: PropTypes.func,
  subscribedChannels: PropTypes.object,
  getRecordingProfile: PropTypes.func,
  retrieveSearchCollection: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLaunchManager);
