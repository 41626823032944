import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  convertToPercentageString,
  checkUnifiedAsset,
  getAutoGeneratedObject,
  getAutogeneratedEpisodeString,
} from "../../shared/utils";
import { getAVSKeyArtImage, handleImageError } from "../../shared/utils/image";
import constants from "../../shared/constants";
import { getEpisodeInfo } from "../../shared/utils/feedHelper";
import { getItemDuration } from "../../shared/utils/swimlane";
import middleware from "../../shared/middleware";
import playerConstants from "../../shared/constants/player";
import {
  VodSidebarItem,
  VodSidebarItemThumbnail,
  VodSidebarThumbnail,
  VodSidebarOnNowPlay,
  VodSidebarProgressBarContainer,
  VodSidebarProgressBar,
  VodSidebarItemDescription,
  VodSidebarItemDescriptionTitle,
  VodSidebarItemDateTime,
  VodSidebarItemDuration,
  VodSidebarEpisodeWatched,
} from "./VodSideBarListItem.styles";
import { useTheme } from "styled-components";

const { ASSET_TYPES } = playerConstants;
const { WATCH_OPTIONS, PAGE_CONTENT_ITEM_TYPES } = constants;
const { getContentUserData } = middleware;
const icon = process.env.PUBLIC_URL + "/images/swimlane-landscape-324px.jpg";

const VodSidebarListItem = ({
  program,
  index,
  onEpisodeItemClick,
  currentEpisodeId,
  divElRef,
  divFirstElRef,
  onHoverIn,
  onHoverOut,
  vodProgress,
  isRecordingPlayer,
  appProvider,
  channelMapInfo,
  seasonEntitlements,
}) => {
  const theme = useTheme();
  const screenWidth = window.screen.availWidth;
  const { t: translate } = useTranslation();
  const catalogInfo = program?.metadata ?? null;
  const [episodeInfo, setEpisodeInfo] = useState(null);
  const [isUnifiedAsset, setIsUnifiedAsset] = useState(true);
  const [itemProgress, setItemProgress] = useState(0);

  const progressRef = useRef(null); // ref to capture the latest progress of an episode if already played in the current video player session

  useEffect(() => {
    setIsUnifiedAsset(
      checkUnifiedAsset(isRecordingPlayer ? program?.metadata?.extendedMetadata?.dlum : program?.metadata)
    );
  }, [program, isRecordingPlayer]);

  useEffect(() => {
    if (episodeInfo?.metadata?.contentId === parseInt(currentEpisodeId)) {
      setItemProgress(vodProgress);
      progressRef.current = vodProgress;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vodProgress]);

  useEffect(() => {
    const fetchUserData = async (episodeId) => {
      const data = episodeId ? await getContentUserData(appProvider, episodeId, PAGE_CONTENT_ITEM_TYPES.vod) : {};
      if (data.containers && data.containers.length > 0) setEpisodeInfo(data.containers[0]);
    };
    if (program) {
      if (!isUnifiedAsset) {
        fetchUserData(program?.id);
      } else {
        setEpisodeInfo(
          isRecordingPlayer
            ? program
            : seasonEntitlements?.length > 0 &&
                seasonEntitlements.find(
                  (entitlement) => entitlement?.uaId === program?.metadata?.extendedMetadata?.dlum?.uaId
                )
        );
      }
    }
    return () => setEpisodeInfo(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appProvider, program, isUnifiedAsset, currentEpisodeId, seasonEntitlements]);

  useEffect(() => {
    if (episodeInfo && episodeInfo?.metadata?.contentId !== parseInt(currentEpisodeId)) {
      const progressTime =
        (episodeInfo?.metadata?.bookmarks?.[0]?.startDeltaTime / program?.metadata?.duration) * 100 ?? 0;
      setItemProgress(progressTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeInfo, currentEpisodeId]);

  if (episodeInfo?.metadata?.contentId !== parseInt(currentEpisodeId)) {
    divElRef = null;
  }
  if (index !== 0) {
    divFirstElRef = null;
  }

  const isUpcoming = episodeInfo?.metadata?.airingStartTime > moment().valueOf(); // check if the episode is upcoming

  const canPlay = () => {
    if (isUnifiedAsset) {
      const isUserEntitled = isRecordingPlayer
        ? isRecordingPlayer
        : seasonEntitlements?.length > 0 &&
          seasonEntitlements.find(
            (entitlement) => entitlement?.uaId === program?.metadata?.extendedMetadata?.dlum?.uaId
          )?.entitlement?.hasTechPackage;
      if (isUpcoming) return false; // upcoming episodes are not playable
      return isUserEntitled;
    } else {
      const assets = episodeInfo?.entitlement?.assets ?? null;
      if (assets && assets.length) {
        return (
          !isUpcoming &&
          assets.some(
            (asset) =>
              asset.assetType.toLowerCase() === ASSET_TYPES.MASTER && asset.rights.toLowerCase() === WATCH_OPTIONS.watch
          )
        ); // episode is playable if it is not upcoming and has at least one master asset with watch rights
      } else return false;
    }
  };

  const showWatchIcon = () => {
    return (
      ((progressRef.current || itemProgress) > 0 && Math.ceil(progressRef.current || itemProgress) > 98) ||
      (!progressRef.current && episodeInfo?.metadata?.bookmarks?.[0]?.isComplete)
    );
  };

  const getEpisodeString = () => {
    if (catalogInfo) {
      const uaEpisodeObject = getAutoGeneratedObject(catalogInfo);
      let episodeInfoString = getAutogeneratedEpisodeString(uaEpisodeObject, catalogInfo, true);
      if (!isRecordingPlayer) {
        // Remove first instance of season and episode number prefixes
        episodeInfoString = episodeInfoString.replace(/S\d+\s?/, ""); // Remove season number
        episodeInfoString = episodeInfoString.replace(/E\s?/, ""); // Remove episode prefix

        // Replace first instance of colon with period
        episodeInfoString = episodeInfoString.replace(/:/, ".");
      }
      return episodeInfoString;
    }
  };

  return program ? (
    <VodSidebarItem
      theme={theme}
      width={screenWidth}
      ref={divElRef}
      $status={
        episodeInfo?.metadata?.contentId === parseInt(currentEpisodeId) ? "playing" : !canPlay() ? "nonPlayable" : ""
      }
      onMouseEnter={() => onHoverIn(program)}
      onMouseLeave={() => onHoverOut()}
      onClick={() => onEpisodeItemClick(episodeInfo, canPlay(), false, index, null, true)}
    >
      <VodSidebarItemThumbnail theme={theme} width={screenWidth} ref={divFirstElRef}>
        <VodSidebarThumbnail
          $screen={screenWidth}
          src={getAVSKeyArtImage(program.metadata, "16x9")}
          alt=""
          data-testid="thumbnail"
          width="100%"
          height="100%"
          onError={(e) => handleImageError(e, icon)}
        />
        <>
          {episodeInfo?.metadata?.contentId !== parseInt(currentEpisodeId) &&
          (episodeInfo?.entitlement?.hasTechPackage || isRecordingPlayer) &&
          !isUpcoming ? (
            <VodSidebarOnNowPlay
              $screen={screenWidth}
              src={process.env.PUBLIC_URL + "/images/CTA-PlayCircle.svg"}
              alt=""
            />
          ) : null}
          {(progressRef.current || itemProgress) > 0 &&
          Math.ceil(progressRef.current || itemProgress) < 98 &&
          !showWatchIcon() ? (
            <VodSidebarProgressBarContainer theme={theme}>
              <VodSidebarProgressBar
                style={{
                  width: convertToPercentageString(progressRef.current || itemProgress), // give preference to latest progress of episode if already played in the current video player session
                }}
              ></VodSidebarProgressBar>
            </VodSidebarProgressBarContainer>
          ) : null}
        </>
      </VodSidebarItemThumbnail>

      <VodSidebarItemDescription theme={theme} width={screenWidth}>
        <VodSidebarItemDescriptionTitle>{getEpisodeString()}</VodSidebarItemDescriptionTitle>
        <VodSidebarItemDateTime theme={theme} width={screenWidth}>
          {getEpisodeInfo(program, channelMapInfo)}
        </VodSidebarItemDateTime>
        <VodSidebarItemDuration>
          {showWatchIcon() ? (
            <VodSidebarEpisodeWatched>
              <img src={process.env.PUBLIC_URL + "/images/tick-icon.svg"} alt="watchedIcon" />
            </VodSidebarEpisodeWatched>
          ) : null}
          <VodSidebarItemDateTime theme={theme} width={screenWidth}>
            {!isUpcoming ? getItemDuration(catalogInfo.duration) : translate("upcoming")}
          </VodSidebarItemDateTime>
        </VodSidebarItemDuration>
      </VodSidebarItemDescription>
    </VodSidebarItem>
  ) : (
    <></>
  );
};

export default VodSidebarListItem;
