import React, { useState } from "react";
import PropTypes from "prop-types";

import { handleImageError } from "../../shared/utils/image";

function CastSwimlaneItem(props) {
  const { id, itemClickAnalyticsCallback = () => null, route, subtitle, thumbnail, title } = props;
  const [isHovering, setIsHovering] = useState(false);

  const getMetadataComponents = () => {
    const textChildren = [];
    if (title) {
      textChildren.push(
        <p key={"title-" + id} className="title">
          {title}
        </p>
      );
    }

    if (subtitle.default) {
      let subtitleText = subtitle.default;
      // support alternative subtitle on hover
      if (subtitle.hover && isHovering) {
        subtitleText = subtitle.hover;
      }

      textChildren.push(
        <p key={"subtitle-" + id} className="subtitle" style={subtitle.styleOverride}>
          {subtitleText}
        </p>
      );
    }

    if (textChildren.length) {
      return (
        <div
          key={"textContainer-" + id}
          className="text-container"
          style={{
            maxWidth: thumbnail.dimensions.width,
          }}
        >
          {textChildren}
        </div>
      );
    }

    return null;
  };

  /**
   * Handles thumbnail fallback when the thumbnail fails to load
   * @param {Error} e Error from img failing to load
   * @param {Object} thumbnailInfo The item's thumbnail properties
   */
  const handleThumbnailError = (e, thumbnailInfo) => {
    handleImageError(e, thumbnailInfo.fallbackSrc);
  };

  const onHoverIn = () => {
    setIsHovering(true);
  };

  const onHoverOut = () => {
    setIsHovering(false);
  };

  /**
   * Swimlane item click handler used to prevent navigation when necessary or to trigger other navigational side effects
   * such as analytics
   * @param {SyntheticEvent} event onClick event
   */
  const onItemClick = (event) => {
    itemClickAnalyticsCallback();
  };

  return (
    <div
      id={id}
      className="item-container"
      style={{
        width: thumbnail.dimensions.width,
        marginRight: thumbnail.dimensions.itemSpacing,
      }}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
    >
      <a href={`#${route}`} onClick={onItemClick}>
        <div
          className="thumbnail-container-cast"
          style={{
            borderRadius: thumbnail.dimensions.borderRadius,
            height: thumbnail.dimensions.height,
            width: thumbnail.dimensions.width,
          }}
        >
          <img
            className="thumbnail"
            src={thumbnail.src ? `${thumbnail.src}?w=${thumbnail.dimensions.width}` : ""}
            alt={thumbnail.alt ?? ""}
            onError={(e) => handleThumbnailError(e, thumbnail)}
          />
        </div>
        <div className="metadata-container">{getMetadataComponents()}</div>
      </a>
    </div>
  );
}

CastSwimlaneItem.propTypes = {
  id: PropTypes.string,
  itemClickAnalyticsCallback: PropTypes.func,
  route: PropTypes.string,
  subtitle: PropTypes.shape({
    default: PropTypes.string,
    hover: PropTypes.string,
    styleOverride: PropTypes.object,
  }),
  thumbnail: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    dimensions: PropTypes.object,
    fallbackSrc: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default CastSwimlaneItem;
