import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";

import { loadCast, LOAD_CAST_DETAIL } from "./state/actions";
import { resetAction } from "../../App/state/actions";
import ViewAll from "../../components/ViewAll";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { convertTitleItemsListToSwimLaneModel } from "../../shared/utils/swimlane";
import swimlaneConstants from "../../shared/constants/swimlane";
import errors from "../../shared/constants/error";
import middleware from "../../shared/middleware";
import { getPcLevelRestriction } from "../../shared/utils";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import useTrackPageView from "../../shared/hooks/useTrackPageView";

const { ITEM_TYPES, SWIMLANE_TITLES } = swimlaneConstants;
const dimension = ITEM_TYPES.TITLE_ITEM.PORTRAIT.DIMENSIONS.values;
const { getCastDetail } = middleware;
const { API_STATUS_CODES } = errors;

/**
 * Cast Detail page component
 *
 * @component
 * @param {Object} props
 */

const CastDetailPage = (props) => {
  const { appProvider, loadCast, content, resetAction, userProfile, featureToggles } = props;
  const { isUserProfilesEnabled, isParentalPINEnabled } = featureToggles;
  const [showViewAll, setShowViewAll] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { isAppLanguageFrench } = useAppLanguage();
  const query = new URLSearchParams(useLocation().search);
  const title = query.get("title");
  const subTitle = query.get("subtitle");
  const image = query.get("image");

  const cancelTokenSource = useCancelTokenSource();
  const { trackPageView } = useTrackPageView();

  const pcLevel = getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled);

  useEffect(() => {
    loadCast(appProvider, title, userProfile, pcLevel, cancelTokenSource);

    return () => {
      resetAction(LOAD_CAST_DETAIL, "content");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackPageView({ pageName: title });
  }, [trackPageView, title]);

  useEffect(() => {
    content !== null && setShowViewAll(true);
    if (content && content?.containers && content.status !== API_STATUS_CODES.BAD_REQUEST) {
      setItemsList(
        convertTitleItemsListToSwimLaneModel(
          content.containers,
          dimension,
          isAppLanguageFrench,
          userProfile,
          undefined,
          { title: SWIMLANE_TITLES.CAST_AND_CREW }
        )
      );
    }
  }, [content, isAppLanguageFrench, userProfile]);

  const loadNextSetOfItems = async (startIndex, endIndex) => {
    setIsLoading(true);
    try {
      const feedContent = await getCastDetail(
        appProvider,
        title,
        userProfile,
        pcLevel,
        startIndex,
        endIndex,
        cancelTokenSource
      );
      if (feedContent) {
        setItemsList((itemsList) => [
          ...itemsList,
          ...convertTitleItemsListToSwimLaneModel(
            feedContent.containers?.filter((item) => item?.layout && item?.metadata),
            dimension,
            isAppLanguageFrench,
            userProfile
          ),
        ]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to retrieve search items", error);
    }
  };

  const imgSrc = image || null;

  return content && content.status === API_STATUS_CODES.BAD_REQUEST ? (
    <Redirect to="/" />
  ) : (
    showViewAll && (
      <ViewAll
        title={title}
        subTitle={subTitle}
        items={itemsList}
        imgSrc={imgSrc}
        isFromCastDetail={true}
        isLoading={isLoading}
        loadNextSetOfItems={loadNextSetOfItems}
        totalContent={content.total}
      />
    )
  );
};

CastDetailPage.propTypes = {
  appProvider: PropTypes.object.isRequired,
  loadCast: PropTypes.func.isRequired,
  content: PropTypes.object,
  resetAction: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
  featureToggles: PropTypes.object,
};

CastDetailPage.defaultProps = {
  content: null,
};

function mapStateToProps({ app, castDetails }) {
  return {
    appProvider: app.provider,
    content: castDetails.content,
    userProfile: app.userProfile,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  loadCast,
  resetAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CastDetailPage);
