import styled from "styled-components";

const Scrollable = styled.div`
  height: 100%;
  max-height: 360px;
  min-width: 190px;
  overflow-y: auto;
  width: 100%;

  @media only screen and (max-width: 1366px) {
    width: 292px;
  }

  @media only screen and (max-width: 768px) {
    width: 236px;
  }

  /* IE */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    scrollbar-face-color: ${(props) => props.theme.colours.abbeyGray};
    scrollbar-track-color: ${(props) => props.theme.colours.mineShaftGray};
    scrollbar-arrow-color: ${(props) => props.theme.colours.mineShaftGray};
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &:after {
      content: "";
      height: 32px;
      display: block;
    }
  }

  /* Edge */
  @supports (-ms-ime-align: auto) {
    margin-right: -10px;
    padding-top: 16px;
    margin-top: -16px;
    margin-bottom: -16px;
    padding-bottom: 16px;

    &:after {
      content: "";
      height: 16px;
      display: block;
    }
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colours.abbeyGray} ${(props) => props.theme.colours.mineShaftGray};

  /* Chrome */
  &::-webkit-scrollbar-track {
    width: 4px;
    margin: 8px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colours.abbeyGray};
    border: 1px solid transparent;
    background-clip: content-box;
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }
`;

export { Scrollable };
