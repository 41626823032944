import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import OptikButton from "../../components/OptikButton";
import { trapFocus } from "../../shared/utils";
import { loadUserProfile, loadUserProfiles } from "../../App/state/actions";
import constants from "../../shared/constants";
import routes from "../../shared/constants/routes";
import HeaderImage from "./assets/profiles-intro.png";
import middleware from "../../shared/middleware";
import { sortAndFilterAvatars } from "../../shared/utils/userProfile";
import "./style.scss";

const { DEFAULT_AVATAR_CATEGORY } = constants;
const { PROFILE_EDIT } = routes;
const { updateProfile, getAvatarList } = middleware;

/**
 * Modal component for introducing the user profiles feature. The user can
 * click on one of the buttons to edit profiles or dismiss the modal. Choosing
 * either option sets the default profile name to the account username.
 * Clicking outside the modal does not close the modal.
 * @component
 * @param {Object} props
 */
function ProfileSetupModal({ appProvider, userProfile, loadUserProfile, loadUserProfiles, featureToggles }) {
  const history = useHistory();
  const { isUserProfilesEnabled } = featureToggles;
  const modalElement = useRef(null);
  const modalFocus = useRef(null);
  const [profileName, setProfileName] = useState(userProfile?.user?.profile?.profileData?.profileName);
  const [avatar, setAvatar] = useState(userProfile?.user?.profile?.profileData?.avatar);
  const { t: translate } = useTranslation();

  const shouldRenderModal =
    isUserProfilesEnabled &&
    userProfile?.isLoggedIn &&
    userProfile?.user?.profile?.profileData?.isMasterAccount === "Y" &&
    !profileName;

  useEffect(() => {
    if (modalElement.current && modalFocus.current) {
      trapFocus(modalElement.current);
      modalFocus.current.focus();
    }
  }, []);

  useEffect(() => {
    if (shouldRenderModal && !avatar) {
      getAvatarList(appProvider).then((result) => {
        if (result?.length > 0) {
          const sortedActiveAvatars = sortAndFilterAvatars(result);
          if (sortedActiveAvatars?.length > 0) {
            const defaultCategory = DEFAULT_AVATAR_CATEGORY.toLowerCase();
            const defaultAvatar =
              sortedActiveAvatars.find((avatarInfo) => {
                return avatarInfo?.category?.toLowerCase()?.includes(defaultCategory);
              }) ?? sortedActiveAvatars[0];

            if (defaultAvatar) {
              setAvatar(defaultAvatar);
            }
          }
        }
      });
    }
  }, [appProvider, avatar, shouldRenderModal]);

  const setProfileDefaults = () => {
    // Set a default profile name to close the modal and perform update
    // and reload user profile
    const defaultProfileName = translate("default_profile_name");
    setProfileName(defaultProfileName);

    const profileParams = { profileName: defaultProfileName };
    if (avatar?.id !== userProfile?.user?.profile?.profileData?.avatar) {
      profileParams.avatarId = avatar.id;
    }

    return updateProfile(appProvider, profileParams).then(() => {
      return Promise.allSettled([loadUserProfile(appProvider), loadUserProfiles(appProvider)]);
    });
  };

  return shouldRenderModal ? (
    <>
      <div className="profile-setup-modal-backdrop" />
      <div className="profile-setup-modal" ref={modalElement}>
        <img src={HeaderImage} alt="Profiles" />
        <div className="profile-setup-text">
          <h1>{translate("profiles_personalized_feed")}</h1>
          <p>{translate("profiles_created")}</p>
        </div>
        <div className="cta-container">
          <OptikButton
            ref={modalFocus}
            label={translate("profiles_edit")}
            onClickHandler={() => {
              setProfileDefaults().then(() => {
                history.push(PROFILE_EDIT.route);
              });
            }}
          />
          <OptikButton className="ghost-button" label={translate("dismiss")} onClickHandler={setProfileDefaults} />
        </div>
      </div>
    </>
  ) : null;
}

ProfileSetupModal.propTypes = {
  appProvider: PropTypes.object,
  userProfile: PropTypes.object,
  loadUserProfile: PropTypes.func,
  loadUserProfiles: PropTypes.func,
};

const mapStateToProps = ({ app }) => ({
  appProvider: app.provider,
  userProfile: app.userProfile,
  featureToggles: app.featureToggles,
});

const mapDispatchToProps = {
  loadUserProfile,
  loadUserProfiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSetupModal);
