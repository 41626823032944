import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadSubCategoryPageItems, LOAD_SUB_CATEGORY } from "./state/actions";
import { useLocation, useRouteMatch, useHistory, Link } from "react-router-dom";
import { resetAction } from "../../App/state/actions";
import PropTypes from "prop-types";
import createComponent from "../../shared/factories/componentFactory";
import "./style.scss";
import SeoPageTags from "../../components/SeoPageTags";
import ImageButton from "../../components/ImageButton";
import Breadcrumb from "../../components/Breadcrumb";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";

/**
 * Sub Category page component
 * @param {Object} props - component properties
 */

function SubCategoryPage(props) {
  const { appProvider, loadSubCategoryPageItems, content, resetAction, featureToggles } = props;
  const { isFavouritesEnabled, isCloudPVREnabled } = featureToggles;
  const [pageItems, setPageItems] = useState([]);
  const match = useRouteMatch();
  let {
    params: { feedId },
  } = match;
  let availableSwimlanes = [];
  feedId = decodeURIComponent(feedId);
  const query = new URLSearchParams(useLocation().search);
  const title = query.get("title");
  const breadCrumbSegments = title && title.includes("/") ? title.split("/").reverse() : [];
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const { trackPageView } = useTrackPageView();
  const history = useHistory();

  useEffect(() => {
    if (content) {
      if (appProvider) {
        // TODO: Refactor to use Feed component
        let pItems = content.map((componentConfig) =>
          createComponent(
            componentConfig,
            appProvider,
            null,
            null,
            updateAvailableSwimlanes,
            null,
            null,
            isFavouritesEnabled,
            isCloudPVREnabled,
            title
          )
        );
        setPageItems(pItems);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    loadSubCategoryPageItems(appProvider, feedId, cancelTokenSource);
    return () => {
      resetAction(LOAD_SUB_CATEGORY, "content");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedId]);

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  /**
   * Update available swimlanes only when data is valid
   * @param {Object} data - swimlane
   * @returns {Function} a callback function that returns the accurate row number of current swimlane on the screen,
   *                     this function will be triggered when a user clicks on a swimlane item
   */
  const updateAvailableSwimlanes = (swimlane = {}) => {
    const getSwimlaneRowNum = (swimlaneTitle) => {
      const defaultSwimlaneTitles = content.map((pageComponent) => pageComponent?.title);
      const swimlanesInOrder = defaultSwimlaneTitles.filter((title) => availableSwimlanes.includes(title));

      return swimlanesInOrder.indexOf(swimlaneTitle);
    };

    if (swimlane?.title && !availableSwimlanes.includes(swimlane?.title)) {
      availableSwimlanes.push(swimlane?.title);
    }
    return (swimlaneTitle) => getSwimlaneRowNum(swimlaneTitle);
  };

  return (
    <div className="subcategoryPage">
      <SeoPageTags title={title} keywords={["optik", "telus"]} />
      <div className="categoryPageHeader">
        <ImageButton
          src={process.env.PUBLIC_URL + "/images/back.svg"}
          onClickHandler={history.goBack}
          alt={"viewAllBack"}
          className="viewAllBack"
        />
        {breadCrumbSegments.length === 0 ? <h1>{title}</h1> : <Breadcrumb breadCrumbSegments={breadCrumbSegments} />}
      </div>
      <div className="categoryPageItems">{pageItems}</div>
    </div>
  );
}

SubCategoryPage.propTypes = {
  appProvider: PropTypes.object.isRequired,
  loadSubCategoryPageItems: PropTypes.func.isRequired,
  content: PropTypes.array,
  history: PropTypes.object.isRequired,
  resetAction: PropTypes.func.isRequired,
  featureToggles: PropTypes.object,
};

SubCategoryPage.defaultProps = {
  content: null,
};

function mapStateToProps({ app, subCategory }) {
  return {
    appProvider: app.provider,
    content: subCategory.content,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  loadSubCategoryPageItems,
  resetAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryPage);
