import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import { Container, InputContainer, CheckMark, Title } from "./CheckBox.styles";

/**
 * Checkbox component
 * @component
 */
const CheckBox = (props) => {
  const { title, checked, onChangeHandler, className } = props;
  const theme = useTheme();
  let containerClass = "checkbox-container";
  containerClass += className ? " " + className : "";

  return (
    <Container data={containerClass} className={containerClass}>
      <InputContainer data={"checkbox"} type="checkbox" name="checkbox" checked={checked} onChange={onChangeHandler} />
      <CheckMark data={"checkmark"} theme={theme} checked={checked}></CheckMark>
      <Title data={"label"}>{title}</Title>
    </Container>
  );
};

CheckBox.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
};

CheckBox.defaultProps = {
  className: null,
  checked: false,
};

export default CheckBox;
