import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import GreenSplatIcon from "./assets/RottenTomato.svg";
import SpilledPopIcon from "./assets/SpilledPopcorn.svg";
import FreshPopIcon from "./assets/FreshPopcorn.svg";
import FreshTomatoIcon from "./assets/FreshTomato.svg";
import CertifiedFreshIcon from "./assets/CertifiedFreshTomato.svg";
import constants from "../../shared/constants/rating";

const COLOURED_STAR = process.env.PUBLIC_URL + "/images/Star_On.svg";

const Rating = ({ ratingObj }) => {
  const { t: translate } = useTranslation();
  const { score, imageType, type, isCinocheRatings, isRatingFromDetailsHeader } = ratingObj;
  const { critics, fan, image_type } = constants;

  let imgSrc = "";
  const numericalScore = parseFloat(score);
  const isNotANumber = Number.isNaN(numericalScore);
  if (isCinocheRatings) {
    imgSrc = COLOURED_STAR;
  } else {
    if (type === critics) {
      if (imageType?.toLowerCase() === image_type) imgSrc = CertifiedFreshIcon;
      else if (numericalScore >= 60) {
        imgSrc = FreshTomatoIcon;
      } else if (0 <= numericalScore && numericalScore < 60) imgSrc = GreenSplatIcon;
    } else if (type === fan) {
      if (numericalScore >= 60) imgSrc = FreshPopIcon;
      else if (0 <= numericalScore && numericalScore < 60) imgSrc = SpilledPopIcon;
    }
  }

  return (
    !isNotANumber && (
      <>
        {isCinocheRatings ? (
          <>
            <span className="rating-wrapper">
              {isRatingFromDetailsHeader &&
                (type === critics ? (
                  <img src={process.env.PUBLIC_URL + "/images/Cinoche_Critics.svg"} alt="" />
                ) : (
                  <span>{translate("details_members_cinoche")}</span>
                ))}
              <span className="cinoche-text">{numericalScore}</span>
              <img
                className={`cinoche-star${isRatingFromDetailsHeader ? " header" : ""}`}
                height={18}
                src={imgSrc}
                alt="Some dummy"
              />
            </span>
            {!isRatingFromDetailsHeader && <span className="rating-separator"></span>}
          </>
        ) : (
          <span className="rating-wrapper rt">
            <img height={30} src={imgSrc} alt="Some dummy" />
            <span className="rt-text">{`${numericalScore}%`}</span>
          </span>
        )}
      </>
    )
  );
};
Rating.propTypes = {
  ratingObj: PropTypes.object.isRequired,
};

Rating.defaultProps = {
  ratingObj: {},
};
export default Rating;
