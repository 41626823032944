import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { hideTopNav } from "../../App/state/actions";
import routeConstants from "../../shared/constants/routes";
import Logo from "../Logo";
import "./style.scss";

const { PROFILE_SELECT } = routeConstants;

/**
 * Common background component for all profile pages
 * @component
 * @param {Object} props
 */
function ProfileScreen({
  className = "",
  userProfile,
  hideTopNav,
  isKidsBackground = false,
  children,
  featureToggles,
}) {
  const { isUserProfilesEnabled } = featureToggles;
  const location = useLocation();
  const { profileName } = userProfile?.user?.profile?.profileData || {};

  // The profile pages use a logo-only nav so we hide the main nav on mount and restore it on unmount
  useEffect(() => {
    hideTopNav(true);

    return () => {
      hideTopNav(false);
    };
  }, [hideTopNav]);

  // Redirect to the homepage if feature is disabled or user tries to visit
  // any of the profile pages before the initial setup
  return !isUserProfilesEnabled || !profileName ? (
    <Redirect to="/" />
  ) : (
    <div className={`profile-screen ${isKidsBackground ? "kids" : ""} ${className}`}>
      {isKidsBackground && <div className="gradient-overlay" />}
      <div className="profile-screen-content">
        <Logo isClickable={location.pathname !== PROFILE_SELECT.route} />
        {children}
      </div>
    </div>
  );
}

ProfileScreen.propTypes = {
  className: PropTypes.string,
  userProfile: PropTypes.object,
  hideTopNav: PropTypes.func,
  isKidsBackground: PropTypes.bool,
  children: PropTypes.node,
  featureToggles: PropTypes.object,
};

const mapStateToProps = ({ app }) => ({
  userProfile: app.userProfile,
  featureToggles: app.featureToggles,
});

const mapDispatchToProps = {
  hideTopNav,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
