import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { loadViewAllItems, LOAD_VIEW_ALL } from "./state/actions";
import swimlaneConstants from "../../shared/constants/swimlane";
import { resetAction } from "../../App/state/actions";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ViewAll from "../../components/ViewAll";
import { convertTitleItemsListToSwimLaneModel } from "../../shared/utils/swimlane";
import {
  getPcLevelRestriction,
  updateQueryStringParameter,
  updateURLQueryParam,
  createDropdownItemLabelObj,
  getDefaultFilterItems,
  getKidExclusionRating,
} from "../../shared/utils";
import "./style.scss";
import ViewAllFilterDropdown from "../../components/ViewAllFilterDropdown";
import SortDropdown from "../../components/SortDropdown";
import { getLocalStorage } from "../../shared/utils/localStorage";
import { trackWebAction } from "../../shared/analytics/dataLayer";
import { ANALYTICS_EVENT_TYPES, ACTION_VALUES } from "../../shared/constants/analytics";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import storageConstants from "../../shared/constants/storage";
import constants from "../../shared/constants";
import { useReducers } from "../../shared/hooks/useReducer";

const { ITEM_TYPES, SWIMLANE_TITLES } = swimlaneConstants;
const { FILTERS_SUFFIX, SORT_OPTIONS_SUFFIX } = storageConstants;
const { CONTENT_ITEM_TYPES, REDUCER_TYPE } = constants;

/**
 * VIEW ALL page component
 * @param {Object} props - component properties
 */

function ViewAllPage(props) {
  const { t: translate } = useTranslation();
  const { favouriteAssets } = useReducers(REDUCER_TYPE.APP);
  const { appProvider, loadViewAllItems, content, resetAction, userProfile, featureToggles } = props;

  const { isUserProfilesEnabled, isParentalPINEnabled } = featureToggles;
  const location = useLocation();
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const currentUrlQueryParams = Object.fromEntries(new URLSearchParams(location.search).entries());
  const currentUrlQueryParamKeys = Object.keys(currentUrlQueryParams);
  let actionURI = location.pathname.split("viewall")[1];
  const searchRefinementValue = useRef({ isSearchTrigged: false, refinementType: null });
  const cachedContent = useRef(null);
  const { trackPageView } = useTrackPageView();

  const pcLevel = getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled);

  const extraQueryParams = {};
  // Add any server-provided query params back to the actionURI, but leave out any query params that we add via code
  for (let i = 0; i < currentUrlQueryParamKeys.length; i++) {
    const queryParamKey = currentUrlQueryParamKeys[i];
    if (
      queryParamKey !== CONTENT_ITEM_TYPES.title &&
      queryParamKey !== "sortOrder" &&
      isNotViewAllQueryParam(queryParamKey)
    ) {
      extraQueryParams[queryParamKey] = currentUrlQueryParams[queryParamKey];
    }
  }

  if (appProvider?.channelMapID && actionURI.includes("/TRAY/SEARCH")) {
    extraQueryParams.filter_regionId = appProvider.channelMapID;
  }
  if (pcLevel) {
    getKidExclusionRating(userProfile, extraQueryParams);
    extraQueryParams.filter_pcLevel = pcLevel;
  }
  actionURI = updateQueryStringParameter(actionURI, extraQueryParams);

  const { isAppLanguageFrench } = useAppLanguage();
  const [noResultFound, setNoResultFound] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const title = location.pathname.includes("TEAMS") ? translate(SWIMLANE_TITLES.TEAMS) : currentUrlQueryParams.title;

  const filterData = JSON.parse(getLocalStorage(title + FILTERS_SUFFIX));
  if (filterData) {
    // Remove subscribed content from filters if user is not logged in
    if (!userProfile.isLoggedIn) {
      const contentArray = (element) => element?.key === "content_filters";
      const subscribedElement = (element) => element?.value === "subscribed";
      filterData[filterData.findIndex(contentArray)].values.splice(
        filterData[filterData.findIndex(contentArray)].values.findIndex(subscribedElement),
        1
      );
    }
  }

  const initialFilterParams = isFilterApplied() ? getURLFilterParams() : getDefaultFilterParams();
  const [filterParams, setFilterParams] = useState(initialFilterParams ?? "");
  const prevFilterParams = useRef("");
  const initialFilterDropdownState = useRef(
    createDropdownItemLabelObj(getURLFilterItems() ?? getDefaultFilterItems(filterData), isAppLanguageFrench)
  );

  const sortData = JSON.parse(getLocalStorage(title + SORT_OPTIONS_SUFFIX));
  const initialSortItem = getURLSortItem() ?? getDefaultSortItem();
  const prevSortParams = useRef("");

  let initialSortParams = "";
  if (initialSortItem) {
    initialSortParams = initialSortItem.value;
    if (initialSortParams) {
      // Remove any prefix that might have been provided so we can control it ourselves later
      if (initialSortParams.startsWith("&") || initialSortParams.startsWith("?")) {
        initialSortParams = initialSortParams.substring(1);
      }

      // Update the URL with sort query params on initial page load since there is always some sort method in use
      if (prevSortParams.current === "") {
        const initialSortQueryParams = initialSortParams.split("&");
        updateURLQueryParam(initialSortQueryParams[0]);
        updateURLQueryParam(initialSortQueryParams[1]);
      }
    }
  }
  const [sortParams, setSortParams] = useState(initialSortParams);
  const initialSortDropdownState = useRef(initialSortItem ? { 0: initialSortItem.label } : null);
  const [totalContent, setTotalContent] = useState(null); // total number of items api can return
  const [isLoading, setIsLoading] = useState(false); // boolean to show spinning loader

  const findLayoutType = (title) => {
    if (title === translate("resume") || title === translate(SWIMLANE_TITLES.TEAMS)) {
      return ITEM_TYPES.TITLE_ITEM.LANDSCAPE;
    } else {
      return ITEM_TYPES.TITLE_ITEM.PORTRAIT;
    }
  };

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  /**
   * Triggers the loading of the view all page content whenever the filter or sort params
   * are updated. This includes the initial fetching of content.
   */
  useEffect(() => {
    if (actionURI) {
      if (
        sortParams !== prevSortParams.current ||
        filterParams !== prevFilterParams.current ||
        (sortParams === "" && filterParams === "")
      ) {
        if (actionURI === "/TRAY/USER/FAVORITES?filter_contentType=MOVIE,TVSHOW" && favouriteAssets?.length > 0) {
          // using cache for favourites assets gallery
          cachedContent.current = favouriteAssets;
        }
        itemsList.length > 0 && setItemsList([]); // re-setting items in case of filter or sort update
        loadViewAllItems(
          appProvider,
          actionURI,
          filterParams,
          sortParams,
          cancelTokenSource,
          undefined,
          undefined,
          cachedContent.current
        );

        const getRefinementType = () => {
          if (sortParams !== prevSortParams.current && prevSortParams.current !== "") {
            return ACTION_VALUES.SEARCH_REFINEMENT_TYPE_SORT;
          } else if (filterParams !== prevFilterParams.current) {
            return ACTION_VALUES.SEARCH_REFINEMENT_TYPE_FILTER;
          } else return null;
        };
        searchRefinementValue.current = { isSearchTrigged: true, refinementType: getRefinementType() };

        prevSortParams.current = sortParams;
        prevFilterParams.current = filterParams;
      }
    }

    return () => {
      resetAction(LOAD_VIEW_ALL, "content");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, sortParams]);

  /**
   * Handles the view all items data from the server
   */
  useEffect(() => {
    if (content?.total) {
      setTotalContent(content.total);
    }
    if (content?.containers) {
      itemsList.length === 0 && setNoResultFound(content.containers.length === 0);
      if (content.containers.length) {
        let feedContent = content.containers;
        feedContent = feedContent.filter((container) => Object.keys(container?.metadata)?.length > 0); // filtering out bad data
        const layoutType = findLayoutType(title);
        setItemsList((itemsList) => [
          ...itemsList,
          ...convertTitleItemsListToSwimLaneModel(
            feedContent,
            title === translate(SWIMLANE_TITLES.TEAMS)
              ? layoutType.DIMENSIONS.teamViewAllValues
              : layoutType.DIMENSIONS.values,
            isAppLanguageFrench,
            userProfile,
            undefined,
            { title }
          ),
        ]);
        setIsLoading(false);
      } else if (isLoading) {
        setIsLoading(false);
      }

      if (searchRefinementValue.current.isSearchTrigged && searchRefinementValue.current.refinementType) {
        trackSearchRefinementEvent(searchRefinementValue.current.refinementType, content.containers?.length > 0);
      }
    } else if (isLoading) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  /**
   * Gets the default sort item
   * @returns First sort option as default or null if none exist
   */
  function getDefaultSortItem() {
    return sortData?.[0].values?.[0] ?? null;
  }

  /**
   * Gets the sort item that matches the query param in the URL
   * @returns {Object} if a match is found, returns the sort item, otherwise returns null
   */
  function getURLSortItem() {
    const currentHash = window.location.hash;
    if (sortData && sortData.length && currentHash.includes("?")) {
      const urlQueryParams = Object.fromEntries(new URLSearchParams(currentHash).entries());
      for (let sortItem of sortData[0].values) {
        if (
          sortItem.value.includes(urlQueryParams["orderBy"]) &&
          sortItem.value.includes(urlQueryParams["sortOrder"])
        ) {
          return sortItem;
        }
      }
    }
    return null;
  }

  /**
   * @returns {String} String of combined query params for default filters
   */
  function getDefaultFilterParams() {
    let defaultFilterParamString = "";
    if (filterData) {
      for (let filterCategory of filterData) {
        if (filterCategory.key === "filter_genres") {
          const filterValue = filterCategory.values[0]?.value;
          if (filterValue && filterValue.includes("&")) {
            defaultFilterParamString += "&" + filterValue.split("&")[1];
          }
        } else if (filterCategory.key === "language_filters") {
          defaultFilterParamString += filterCategory.values[0]?.value ?? "";
        }
      }
    }

    return defaultFilterParamString;
  }

  /**
   * @returns {boolean} true if current hash includes a genre or language filter
   */
  function isFilterApplied() {
    const currentHash = window.location.hash;
    return (
      currentHash.includes("filter_genres") ||
      currentHash.includes("filter_language") ||
      currentHash.includes("content_filters")
    );
  }

  /**
   * @returns {String} String of combined filter query params found in the current URL
   */
  function getURLFilterParams() {
    const currentHash = window.location.hash;
    let filterParamString = "";

    if (currentHash.includes("?")) {
      // We have some query params!
      const queryParams = currentHash.split("?")[1].split("&");
      for (let param of queryParams) {
        if (param.includes("filter_genres") || param.includes("filter_language") || param.includes("content_filters")) {
          if (filterParamString === "") {
            filterParamString += param;
          } else {
            filterParamString += "&" + param;
          }
        }
      }
    }

    return filterParamString;
  }

  /**
   * Gets the filter items that match the filter query params in the URL. If no query param in the URL, the
   * @returns {Array} Array of filter item objects, one for each filter category
   */
  function getURLFilterItems() {
    const currentHash = window.location.hash;
    let filterItems = [];
    if (filterData && currentHash.includes("?")) {
      const urlQueryParams = Object.fromEntries(new URLSearchParams(currentHash).entries());
      for (let filterCategory of filterData) {
        if (filterCategory.key === "filter_genres") {
          if (urlQueryParams["filter_genres"]) {
            for (let filterItem of filterCategory.values) {
              if (filterItem.value.includes(urlQueryParams["filter_genres"])) {
                filterItems.push(filterItem);
                break;
              }
            }
          } else {
            filterItems.push(filterCategory.values[0]);
          }
        } else if (filterCategory.key === "language_filters") {
          if (urlQueryParams["filter_language"]) {
            for (let filterItem of filterCategory.values) {
              if (filterItem.value.includes(urlQueryParams["filter_language"])) {
                filterItems.push(filterItem);
                break;
              }
            }
          } else {
            filterItems.push(filterCategory.values[0]);
          }
        } else if (filterCategory.key === "content_filters") {
          if (urlQueryParams["content_filters"]) {
            for (let filterItem of filterCategory.values) {
              if (filterItem.value.includes(urlQueryParams["content_filters"])) {
                filterItems.push(filterItem);
                break;
              }
            }
          } else {
            filterItems.push(filterCategory.values[0]);
          }
        }
      }
    }
    return filterItems;
  }

  /**
   * Checks if a query param is one that we add via code.
   * @param {String} queryParamKey
   * @returns {Boolean} true if the query param is not one that we add via code
   */
  function isNotViewAllQueryParam(queryParamKey) {
    return (
      queryParamKey !== "filter_genres" &&
      queryParamKey !== "filter_language" &&
      queryParamKey !== "orderBy" &&
      queryParamKey !== "content_filters"
    );
  }

  /**
   * Tracks a filter or sort change event
   * @param {String} refinementType One of "filter_by" or "sort_by"
   */
  function trackSearchRefinementEvent(refinementType, isSearchSuccessful) {
    const categories = (refinementType === ACTION_VALUES.SEARCH_REFINEMENT_TYPE_SORT ? sortParams : filterParams)
      .split("&")
      .map((param) => param.split("=")[1])
      .join(",");

    trackWebAction(ANALYTICS_EVENT_TYPES.SWIMLANE_SEARCH, {
      content: title,
      categories,
      language: appProvider.lang,
      type: refinementType,
      isSearchSuccessful,
    });
  }

  const loadNextSetOfItems = (startIndex, endIndex, filterParams, sortParams) => {
    setIsLoading(true);
    loadViewAllItems(
      appProvider,
      actionURI,
      filterParams,
      sortParams,
      cancelTokenSource,
      startIndex,
      endIndex,
      cachedContent.current
    );
  };

  return (
    <div className="view-all-wrapper">
      <div className="sort-filters">
        {filterData && filterData.length > 0 ? (
          <ViewAllFilterDropdown
            filterList={filterCategories(filterData, isAppLanguageFrench)}
            applyButtonHandler={setFilterParams}
            resetButtonHandler={setFilterParams}
            defaultFilterParams={createDropdownItemLabelObj(getDefaultFilterItems(filterData), isAppLanguageFrench)}
            initialParamsState={initialFilterDropdownState.current}
          />
        ) : null}
        {sortData && sortData.length > 0 ? (
          <SortDropdown
            sortOptionList={sortData}
            applyButtonHandler={setSortParams}
            resetButtonHandler={setSortParams}
            defaultSortParams={{ 0: getDefaultSortItem()?.label }}
            initialParamsState={initialSortDropdownState.current}
          />
        ) : null}
      </div>
      <ViewAll
        title={title}
        items={itemsList}
        noResultFound={noResultFound}
        loadNextSetOfItems={loadNextSetOfItems}
        filterParams={filterParams}
        sortParams={sortParams}
        totalContent={totalContent}
        isLoading={isLoading}
      />
    </div>
  );
}

ViewAllPage.propTypes = {
  appProvider: PropTypes.object.isRequired,
  loadViewAllItems: PropTypes.func.isRequired,
  content: PropTypes.object,
  resetAction: PropTypes.func.isRequired,
  featureToggles: PropTypes.object,
};

ViewAllPage.defaultProps = {
  content: {},
};

function mapStateToProps({ app, viewAll }) {
  return {
    appProvider: app.provider,
    content: viewAll.content,
    userProfile: app.userProfile,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  loadViewAllItems,
  resetAction,
};

const filterCategories = (filterList, isAppLanguageFrench) => {
  let result = filterList.filter(
    (word) =>
      word.label.toLowerCase() === "genre" ||
      word.label.toLowerCase() === "language" ||
      word.label.toLowerCase() === "content"
  );

  addAdditionalFilterParam(result, isAppLanguageFrench);
  return result;
};

const addAdditionalFilterParam = (filterList, isAppLanguageFrench) => {
  filterList.forEach((element) => {
    if (element && element.Pivots) {
      let all = isAppLanguageFrench ? { Id: "Tous", Name: "Tous" } : { Id: "All", Name: "All" };
      if ((element.Pivots[0].Id !== "All" || element.Pivots[0].Id !== "Tous") && element.Id !== "SortBy") {
        element.Pivots.unshift(all);
      }
    }
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllPage);
