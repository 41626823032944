import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useSetInterval from "../../shared/hooks/useSetInterval";
import "./styles.scss";

function Billboard({ noOfItems, onChange, stallBillboardChange, onBoardingPopup, selectedDot }) {
  const [currentItem, setCurrentItem] = useState(0);
  const [dots, setDots] = useState([]);
  const [intervalDuration] = useState(10000);
  const intervalTicker = useSetInterval(intervalDuration);

  useEffect(() => {
    for (let i = 0; i < noOfItems; i++) {
      setDots((dots) => {
        return [...dots, i];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfItems]);
  useEffect(() => {
    if (onBoardingPopup) {
      setCurrentItem(selectedDot);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDot]);

  useEffect(() => {
    if (!onBoardingPopup && intervalTicker > 0 && !stallBillboardChange) {
      if (currentItem === noOfItems - 1) setCurrentItem(0);
      else setCurrentItem(currentItem + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalTicker]);

  useEffect(() => {
    onChange(currentItem);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  return (
    <div>
      {/* Render the wall component here with displayItem info */}
      <div className={onBoardingPopup ? "dotsWelcomeContainer" : "dotsContainer"}>
        {dots &&
          dots.length > 1 &&
          dots.map((dot) => (
            <div
              key={dot}
              onClick={() => setCurrentItem(dot)}
              className={dot === currentItem ? "dot selected" : "dot"}
            ></div>
          ))}
      </div>
    </div>
  );
}

Billboard.propTypes = {
  noOfItems: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

Billboard.defaultProps = {
  noOfItems: 0,
};

export default Billboard;
