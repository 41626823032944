import request from "../../service/request";
import constants from "../constants";
const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

/**
 * AVS POST content/UserData/bookmark Service API call to create bookmark and stop stream content beacon
 * @param {Object} appProvider App Provider Information
 * @param {String} contentId Content ID of the given object
 * @param {String} contentType Content Type ( VOD|Live|Program )
 * @param {Object} requestBody request body
 */
export function createBookmark(appProvider, contentType, contentId, requestBody) {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = AVS_API_LIST.set_bookmark_url;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$CONTENT_TYPE", contentType?.toUpperCase());
  url = url.replace("$CONTENT_ID", contentId);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: withCredentials,
  };

  if (requestBody) {
    options["data"] = requestBody;
  }

  return request(options, null, appProvider);
}

/**
 * AVS PUT content/UserData/bookmark Service API call to update bookmark if already exists
 * @param {Object} appProvider App Provider Information
 * @param {String} contentId Content ID of the given object
 * @param {String} contentType Content Type ( VOD|Live|Program )
 * @param {Object} requestBody request body
 */
export function updateBookmark(appProvider, contentType, contentId, bookmarkSetId, requestBody) {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = AVS_API_LIST.update_bookmark_url;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$CONTENT_TYPE", contentType?.toUpperCase());
  url = url.replace("$CONTENT_ID", contentId);
  url = url.replace("$BOOKMARK_SET_ID", bookmarkSetId);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url: url,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: withCredentials,
  };

  if (requestBody) {
    options["data"] = requestBody;
  }

  return request(options, null, appProvider);
}

/**
 * Returns the content specific user related information, such as entitlements. The assets the user has access to. The user's rights to the content
 * @param {Object} appProvider App Provider Information
 * @param {String} contentId Content ID of the given object
 * @param {String} contentType Content Type ( VOD|Live|Program )
 * @param {Object} requestBody request body
 */
export function bookmarkAVSVideo(appProvider, contentId, contentType, requestBody) {
  return postUserData(appProvider, contentId, contentType, requestBody);
}

/**
 * AVS POST content/UserData Service API call for bookmarking and stop stream content beacon
 * @param {Object} appProvider App Provider Information
 * @param {String} contentId Content ID of the given object
 * @param {String} contentType Content Type ( VOD|Live|Program )
 * @param {Object} requestBody request body - Optional param
 */
function postUserData(appProvider, contentId, contentType, requestBody = null) {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = AVS_API_LIST.content_user_data;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);
  url = url.replace("$ITEM_TYPE", contentType.toUpperCase());
  url = url.replace("$ID", contentId);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const options = {
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: withCredentials,
  };

  if (requestBody) {
    options["data"] = requestBody;
  }

  return request(options, null, appProvider);
}
