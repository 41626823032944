import React, { useEffect, useState } from "react";
import "./style.scss";
import Dropdown from "../Dropdown";
import { useTranslation } from "react-i18next";
import recordingConstants from "../../shared/constants/recordingConstants";
import classNames from "classnames";
const { RECORDING_SETTINGS } = recordingConstants;
/**
 * Recording settings Dropdown component
 * @param {Object} props
 */
function RecordingDropdown(props) {
  let {
    isMR,
    recordingDropdownData,
    linkSelectHandler,
    dropdownKey,
    defaultSelectedIndex,
    recordingInfoAiringTime,
    hasNewline,
  } = props;

  const { t: translate } = useTranslation();
  const [showDropdownList, setShowDropdownList] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(defaultSelectedIndex);
  const [dropdownList, setDropdownList] = useState({});
  const [recordingDropdownDataTranslated, setRecordingDropdownDataTranslated] = useState(null);

  useEffect(() => {
    if (recordingDropdownData) {
      const recordingDropdownDataTranslated = recordingDropdownData.map((data) => ({
        ...data,
        label: data.isTranslated ? data.label : translate(data.label),
        desc: translate(data.desc),
      }));
      if (recordingDropdownDataTranslated && isMR && dropdownKey === RECORDING_SETTINGS.SHOW_TIME.KEY) {
        const timeListIndex = recordingDropdownDataTranslated.findIndex((timeList) => timeList.value === 0);
        const updatedShowtimeValue =
          recordingDropdownDataTranslated[timeListIndex].label + " " + recordingInfoAiringTime;
        recordingDropdownDataTranslated[timeListIndex].label = updatedShowtimeValue;
      }
      setRecordingDropdownDataTranslated(recordingDropdownDataTranslated);
      const list = {
        items: recordingDropdownDataTranslated,
      };
      setDropdownList(list);
    }
  }, [dropdownKey, isMR, recordingDropdownData, recordingInfoAiringTime, translate]);

  useEffect(() => {
    setSelectedItemIndex(defaultSelectedIndex);
  }, [defaultSelectedIndex]);

  /**
   * Shows the dropdown pop up when clicked
   */
  const buttonClickHandler = () => {
    setShowDropdownList(recordingDropdownData.length > 1);
  };
  /**
   * Set the selected option from the dropdown list
   */
  const linkClickHandler = (item) => {
    const index = recordingDropdownData.findIndex((x) => x.value === item.value);
    setSelectedItemIndex(index);
    setShowDropdownList(false);
    linkSelectHandler(item, dropdownKey);
  };
  /**
   * Hides the dropdown pop up when clicked outside of the dropdown
   */
  const handleOutsideComponentClick = () => {
    setShowDropdownList(false);
  };

  return recordingDropdownDataTranslated && dropdownList ? (
    <Dropdown
      showDropdownList={showDropdownList}
      button={{
        label: recordingDropdownDataTranslated[selectedItemIndex]?.label,
        contentSize: recordingDropdownDataTranslated.length,
        buttonClickHandler,
      }}
      lists={[
        {
          items: dropdownList,
          selectedItem: recordingDropdownDataTranslated[selectedItemIndex]?.value,
          listStyles: "recording-dropdown-list",
          linkClickHandler,
        },
      ]}
      popUp={{
        popUpStyles: classNames("recording-list-container", { "has-newline": hasNewline }),
        handleOutsideComponentClick,
      }}
    ></Dropdown>
  ) : (
    <></>
  );
}

export default RecordingDropdown;
