import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./style.scss";
import constants from "../../shared/constants";
import recordingConstants from "../../shared/constants/recordingConstants";
import OptikButton from "../OptikButton";
import classNames from "classnames";
import { useReducers } from "../../shared/hooks/useReducer";
import { useTheme } from "styled-components";

const { RECORDING_PARAMS } = recordingConstants;
const { REDUCER_TYPE } = constants;
const RECORDING_ICON = process.env.PUBLIC_URL + "/images/Recordings.svg";
const SCHEDULED_ICON = process.env.PUBLIC_URL + "/images/Calendar.svg";
const INFO_ICON = process.env.PUBLIC_URL + "/images/Info-stroke.svg";
/**
 * Display side bar for recordings page
 * @param {Object} props
 * @returns RecordingSidePanel component
 */
function RecordingSidePanel(props) {
  const {
    tabClickHandler,
    isScheduleClicked,
    seriesRecordingItem,
    selectedSeriesId,
    recordingListArray,
    infoClickHandler,
    recordingAvailabilityDays,
    isMR,
    isRequestLoading,
  } = props;
  const theme = useTheme();
  const { recordingsList: recordingGetResponse } = useReducers(REDUCER_TYPE.RECORDING);
  const { t: translate } = useTranslation();

  let playbackAvailabilityMsg = "";
  const episodeCount = recordingListArray?.length ?? 0;
  if (isMR) {
    playbackAvailabilityMsg = translate(RECORDING_PARAMS.RESTRICTION_OPTIK);
  } else {
    playbackAvailabilityMsg =
      !isRequestLoading && translate("cloud_pvr_availability").replace("%s", recordingAvailabilityDays);
  }
  if (episodeCount) {
    const icon = isScheduleClicked ? (
      <img src="/images/Calendar.svg" alt="Scheduled Icon" />
    ) : (
      <img src="/images/Recordings.svg" alt="Recorded Icon" />
    );
  }
  return (
    <div className="recording-panel" id="recording-panel">
      {!selectedSeriesId || !recordingGetResponse || seriesRecordingItem ? (
        <div className="recording-panel-details">
          <div className="recording-type">
            <OptikButton
              backgroundColor={
                !isScheduleClicked
                  ? theme.colours.recordingButtonBackground
                  : theme.colours.focusedRecordingButtonBackground
              }
              label={translate(RECORDING_PARAMS.RECORDED)}
              icon={RECORDING_ICON}
              className={classNames("PVR-wall-button", {
                selected: !isScheduleClicked,
              })}
              onClickHandler={() => tabClickHandler(RECORDING_PARAMS.RECORDED)}
            />
            <OptikButton
              backgroundColor={
                isScheduleClicked
                  ? theme.colours.recordingButtonBackground
                  : theme.colours.focusedRecordingButtonBackground
              }
              label={translate(RECORDING_PARAMS.SCHEDULED)}
              icon={SCHEDULED_ICON}
              className={classNames("PVR-wall-button", {
                scheduled: isScheduleClicked,
              })}
              onClickHandler={() => tabClickHandler(RECORDING_PARAMS.SCHEDULED)}
            />
          </div>
        </div>
      ) : null}
      <div className="PVR-manager">
        <div className="info-popup">
          <img src={INFO_ICON} alt="Info Icon" onClick={() => infoClickHandler()} />
        </div>
        <div className="recording-space">
          <div className="recording-play-info">{playbackAvailabilityMsg}</div>
        </div>
      </div>
    </div>
  );
}
RecordingSidePanel.propTypes = {
  tabClickHandler: PropTypes.func,
  isScheduleClicked: PropTypes.bool,
  seriesRecordingItem: PropTypes.object,
  selectedSeriesId: PropTypes.string,
  recordingListArray: PropTypes.array,
  infoClickHandler: PropTypes.func,
  recordingAvailabilityDays: PropTypes.string,
  isMR: PropTypes.bool,
};
RecordingSidePanel.defaultProps = {
  tabClickHandler: () => {},
  isScheduleClicked: false,
  recordingListArray: [],
  infoClickHandler: () => {},
};
export default RecordingSidePanel;
