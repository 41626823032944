import { useSelector } from "react-redux";

/**
 * Custom hook to read redux store's data and return it.
 * @param {String} reducerKey
 * @returns Object/Boolean
 */
export const useReducers = (reducerKey) => {
  return useSelector((state) => state[reducerKey]);
};
