import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { showPlaybackError } from "../../App/state/actions";
import { getPlaybackErrorMessageInfo } from "../../shared/utils/playbackHelpers";

/**
 * Create Playback Error Message
 * @component
 * @param {Object} props
 */
function PlaybackError(props) {
  const { playbackError, showPlaybackError, isSideNavOpen } = props;
  const { t: translate } = useTranslation();
  const [displayPlaybackError, setDisplayPlaybackError] = useState(false);
  let playbackErrorTitle = "";
  let playbackErrorMessage = "";

  useEffect(() => {
    return () => showPlaybackError(null, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (playbackError && Object.values(playbackError).some((value) => value !== null)) {
      setDisplayPlaybackError(true);
    } else {
      setDisplayPlaybackError(false);
    }
  }, [playbackError]);

  if (playbackError) {
    const { errorTitle, errorMessage } = getPlaybackErrorMessageInfo(playbackError);

    playbackErrorTitle = errorTitle ? translate(errorTitle) : "";
    playbackErrorMessage = errorMessage ? translate(errorMessage) : "";
  }

  return displayPlaybackError ? (
    <div className={`playback-error-bg ${isSideNavOpen ? "shrink" : ""}`}>
      <div className="playback-error-container">
        <div className="playback-error-header">
          <img
            src={process.env.PUBLIC_URL + "/images/warning-icon.svg"}
            alt=" "
            height="40px"
            width="50px"
            className="playback-error-icon"
          />
          <div className="playback-error-title">{playbackErrorTitle}</div>
        </div>
        <div className="playback-error-body">{playbackErrorMessage}</div>
      </div>
    </div>
  ) : (
    <></>
  );
}
function mapStateToProps({ app }) {
  return {
    playbackError: app.playbackError,
  };
}

const mapDispatchToProps = {
  showPlaybackError,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaybackError);
