import React from "react";
import PropTypes from "prop-types";
import PopUpOutline from "../PopUpOutline";
import ImageButton from "../ImageButton";
import { useTranslation } from "react-i18next";
import parentalControlConstants from "../../shared/constants/parentalControls";
import "./style.scss";

const closeButton = process.env.PUBLIC_URL + "/images/cross-icon.svg";
const { PARENTAL_RATINGS, KIDS_PARENTAL_CONTROL_LEVEL } = parentalControlConstants;

/**
 * Component that render rating dialog description
 * @component
 * @param {Object} props
 */
const ParentalRatingModal = ({ handleClose, isKidsProfile = false }) => {
  const { t: translate } = useTranslation();

  const ratingKeys = Object.keys(PARENTAL_RATINGS);
  const filteredRatingKeys = isKidsProfile
    ? ratingKeys.filter(
        (key) => PARENTAL_RATINGS[key].value < KIDS_PARENTAL_CONTROL_LEVEL && PARENTAL_RATINGS[key].value > 0
      )
    : ratingKeys.slice(1);

  return (
    <>
      <div className="ratings-definitions-backdrop" />
      <div className="ratings-definitions-modal-wrapper">
        <PopUpOutline className="ratings-definitions-modal">
          <div className="heading-row">
            <h2>{translate(isKidsProfile ? "pin_parental_control" : "rating_definition")}</h2>
            <ImageButton
              className="close-button"
              src={closeButton}
              alt={translate("close")}
              onClickHandler={handleClose}
            />
          </div>
          {isKidsProfile && <p className="kids-ratings-description">{translate("profiles_kids_display_ratings")}</p>}
          <div className="scroll-container">
            {filteredRatingKeys.map((key) => {
              const ratingOption = PARENTAL_RATINGS[key];
              return (
                <div key={key} className="rating-group">
                  <div className="rating-heading">{translate(ratingOption.title_key)}</div>
                  {ratingOption.desc_data.map((ratingData) => {
                    return (
                      <div key={ratingData.rating} className="rating-definition">
                        <div className="rating-value">{translate(ratingData.rating)}</div>
                        <div className="rating-description">{translate(ratingData.desc)}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </PopUpOutline>
      </div>
    </>
  );
};

ParentalRatingModal.propTypes = {
  handleClose: PropTypes.func,
  isKidsProfile: PropTypes.bool,
};

export default ParentalRatingModal;
