import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { loadUserProfiles, showToastNotification } from "../../App/state/actions";
import constants from "../../shared/constants";
import errorConstants from "../../shared/constants/error";
import parentalControlConstants from "../../shared/constants/parentalControls";
import routeConstants from "../../shared/constants/routes";
import AvatarGrid from "../../components/AvatarGrid";
import AvatarButton from "../../components/AvatarButton";
import TextInput from "../../components/TextInput";
import OptikButton from "../../components/OptikButton";
import ToggleSwitch from "../../components/ToggleSwitch";
import ProfileScreen from "../../components/ProfileScreen";
import middleware from "../../shared/middleware";
import { sortAndFilterAvatars } from "../../shared/utils/userProfile";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { trackGenericAction } from "../../shared/analytics/dataLayer";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import {
  ACTION_VALUES,
  ANALYTICS_ERROR_INFO,
  ANALYTICS_ERROR_NAMES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_STORAGE_KEYS,
  LINK_INFO,
  PROFILE_PAGE_NAMES,
  WEB_ACTION_EVENT_NAMES,
} from "../../shared/constants/analytics";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import "./style.scss";

const { MAX_ACCOUNT_PROFILES, DEFAULT_AVATAR_CATEGORY } = constants;
const { AVS_ERROR_CODES } = errorConstants;
const { PARENTAL_RATINGS } = parentalControlConstants;
const { PROFILE_EDIT } = routeConstants;
const { createProfile, getAvatarList } = middleware;

/**
 * Page component for creating a new profile
 * @param {Object} props
 */
function AddProfilePage({ appProvider, profiles, userProfile, loadUserProfiles, showToastNotification }) {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [avatarList, setAvatarList] = useState(null);
  const [showSelectAvatarScreen, setShowSelectAvatarScreen] = useState(true);
  const [avatarError, setAvatarError] = useState(null);
  const [isKidsProfile, setIsKidsProfile] = useState(false);
  // TODO: Add back in TCDWC-1208 (MVE4)
  // const [isProfileExitEnabled, setIsProfileExitEnabled] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [profileNameInputError, setProfileNameInputError] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const profileNameInput = useRef(null);
  const cancelTokenSource = useCancelTokenSource();
  const history = useHistory();
  const { t: translate } = useTranslation();
  const isCreatedRef = useRef(false);
  const isToolUsageStartedRef = useRef(false);
  const { trackPageView, resetIsPageViewTracked } = useTrackPageView();

  const isMasterAccountLoggedIn = userProfile?.user?.profile?.profileData?.isMasterAccount === "Y";
  const isPageLoadAllowed = isMasterAccountLoggedIn && profiles?.length < MAX_ACCOUNT_PROFILES;

  useEffect(() => {
    // Automatically focus profile name input field after avatar selection
    if (!showSelectAvatarScreen && profileNameInput.current && !profileName) {
      profileNameInput.current.focus();
    }
  }, [profileName, showSelectAvatarScreen]);

  useEffect(() => {
    if (isPageLoadAllowed && showSelectAvatarScreen && !avatarList) {
      getAvatarList(appProvider, cancelTokenSource).then((result) => {
        if (result?.length > 0) {
          // Exclude avatars used by other profiles
          const exclusions = profiles?.map((profile) => profile.avatar?.id);
          const sortedActiveAvatars = sortAndFilterAvatars(result, exclusions);
          setAvatarList(sortedActiveAvatars);
        }
      });
    }
  }, [appProvider, avatarList, cancelTokenSource, isPageLoadAllowed, profiles, showSelectAvatarScreen]);

  const onAvatarSelect = (avatarInfo, index = 0) => {
    if (avatarInfo) {
      setSelectedAvatar(avatarInfo);
      setSessionStorage(
        ANALYTICS_STORAGE_KEYS.LINK,
        `${LINK_INFO.PROFILE_AVATAR}_${index + 1};${LINK_INFO.CHOOSE_AN_IMAGE}`
      );
    } else if (avatarList?.length > 0) {
      // If user does not select an avatar, pick the first avatar in the default
      // category if available, or first avatar in the list
      const defaultCategory = DEFAULT_AVATAR_CATEGORY.toLowerCase();
      setSelectedAvatar(
        avatarList.find((avatarInfo) => {
          return avatarInfo?.category?.toLowerCase()?.includes(defaultCategory);
        }) ?? avatarList[0]
      );
    }

    resetIsPageViewTracked();
    setShowSelectAvatarScreen(false);
    setAvatarError(null);
  };

  useEffect(() => {
    if (isCreatedRef.current || !isPageLoadAllowed) return;
    if (showSelectAvatarScreen) {
      trackPageView({
        pageName: PROFILE_PAGE_NAMES.CHOOSE_IMAGE,
        profileEvent: isToolUsageStartedRef.current
          ? {}
          : {
              toolUsageStart: 1,
              toolName: ACTION_VALUES.ADD_PROFILE,
            },
      });
      isToolUsageStartedRef.current = true;
    } else {
      trackPageView({
        profileEvent: {},
        pageName: PROFILE_PAGE_NAMES.ADD_PROFILE,
      });
    }
  }, [isPageLoadAllowed, showSelectAvatarScreen, trackPageView]);

  const addProfile = (e) => {
    e.preventDefault();

    // Prevent form resubmission
    if (isFormSubmitted) {
      return;
    }

    const profileNameInputValue = profileNameInput.current?.value;
    if (profileNameInputValue?.trim().length === 0) {
      setProfileNameInputError(translate("please_enter_profile_name"));
      trackGenericAction(ANALYTICS_EVENT_TYPES.PROFILE_CREATE_ERROR, {
        toolName: ACTION_VALUES.ADD_PROFILE,
        name: ANALYTICS_ERROR_NAMES.USER_ERROR,
        errorDetails: ANALYTICS_ERROR_INFO.PROFILE_CREATE_FAILURE,
        errorMessage: "please_enter_profile_name",
        errorCode: null,
      });
      return;
    }
    setProfileNameInputError(null);
    setIsFormSubmitted(true);

    const profileParams = {
      profileName: profileNameInput.current?.value,
      avatarId: selectedAvatar?.id,
      kidsProfile: isKidsProfile,
      // TODO: Add back in TCDWC-1208 (MVE4)
      // exitProofEnabled: isProfileExitEnabled,
      isAdmin: isKidsProfile ? "N" : "Y",
      purchaseEnabled: "Y",
    };
    if (!isKidsProfile) {
      const defaultPcLevel = PARENTAL_RATINGS.UNRATED.value.toString();
      profileParams.pcLevelVod = defaultPcLevel;
      profileParams.pcLevelEpg = defaultPcLevel;
    }

    createProfile(appProvider, profileParams)
      .then(async (result) => {
        isCreatedRef.current = true;
        await loadUserProfiles(appProvider, cancelTokenSource);

        trackGenericAction(WEB_ACTION_EVENT_NAMES.PROFILE_CREATE_COMPLETE);
        setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.CREATE};${LINK_INFO.ADD_PROFILE}`);

        history.push({
          pathname: PROFILE_EDIT.route,
          state: {
            newProfileId: result.profile?.profileData?.userId,
          },
        });
      })
      .catch(async (err) => {
        let errMessage = "";
        let errorName = ANALYTICS_ERROR_NAMES.USER_ERROR;
        let navigateBack = false;
        if (err?.code?.includes(AVS_ERROR_CODES.AVATAR_IN_USE)) {
          if (selectedAvatar) {
            await loadUserProfiles(appProvider, cancelTokenSource);
            setAvatarList(null);
          }
          errMessage = "profile_avatar_used";
          setAvatarError(translate("profile_avatar_used"));
        } else if (err?.code?.includes(AVS_ERROR_CODES.PROFILE_NAME_IN_USE)) {
          errMessage = "profile_name_already_used";
          setProfileNameInputError(translate("profile_name_already_used"));
        } else if (err?.code?.includes(AVS_ERROR_CODES.INVALID_PROFILE_NAME)) {
          errMessage = "please_use_letters_and_numbers";
          setProfileNameInputError(translate("please_use_letters_and_numbers"));
        } else if (err?.code?.includes(AVS_ERROR_CODES.MAX_PROFILES_REACHED)) {
          await loadUserProfiles(appProvider);
          errMessage = "profiles_error_reached_maximum";
          showToastNotification(translate("profiles_error_reached_maximum"));
          navigateBack = true;
        } else {
          errMessage = "profiles_add_failed";
          errorName = ANALYTICS_ERROR_NAMES.SERVER_ERROR;
          showToastNotification(translate("profiles_add_failed"));
        }

        trackGenericAction(ANALYTICS_EVENT_TYPES.PROFILE_CREATE_ERROR, {
          toolName: ACTION_VALUES.ADD_PROFILE,
          name: errorName,
          errorDetails: ANALYTICS_ERROR_INFO.PROFILE_CREATE_FAILURE,
          errorMessage: errMessage,
          errorCode: err?.code,
        });
        setIsFormSubmitted(false);
        if (navigateBack) {
          if (history.length > 1) {
            history.goBack();
          } else {
            history.push(PROFILE_EDIT.route);
          }
        }
      });
  };

  return !isPageLoadAllowed ? (
    <Redirect to="/" />
  ) : (
    <ProfileScreen isKidsBackground={isKidsProfile && !showSelectAvatarScreen}>
      {showSelectAvatarScreen ? (
        <div className="select-avatar-container">
          <h1>{translate("profiles_choose_image")}</h1>
          <OptikButton
            className="skip-button"
            label={translate("skip")}
            onClickHandler={() => {
              onAvatarSelect();
              setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.SKIP};${LINK_INFO.CHOOSE_AN_IMAGE}`);
            }}
          />
          <AvatarGrid avatarList={avatarList} onAvatarSelect={onAvatarSelect} />
        </div>
      ) : (
        <form className="add-profile-form" onSubmit={addProfile}>
          <h1>{translate("add_profile")}</h1>
          <div className="form-row">
            <div className="avatar-container">
              <AvatarButton
                avatarInfo={selectedAvatar}
                onClick={() => {
                  resetIsPageViewTracked();
                  setShowSelectAvatarScreen(true);
                  setSessionStorage(
                    ANALYTICS_STORAGE_KEYS.LINK,
                    `${LINK_INFO.PROFILE_AVATAR};${LINK_INFO.ADD_PROFILE}`
                  );
                }}
                isKids={isKidsProfile}
                isEditMode={true}
              />
              {avatarError && <span className="avatar-error">{avatarError}</span>}
            </div>
            <div className="input-container">
              <TextInput
                ref={profileNameInput}
                value={profileName}
                placeholder={translate("profile_name")}
                onChange={(event) => setProfileName(event.target.value)}
                errorMessage={profileNameInputError}
              />
              <div className="toggle-setting-container">
                <div>
                  <span>{translate("kids_profile")}</span>
                  <p>{translate("kids_profile_description")}</p>
                </div>
                <ToggleSwitch
                  isChecked={isKidsProfile}
                  onToggleButtonChange={(isToggleOn) => {
                    setIsKidsProfile(isToggleOn);

                    // TODO: Add back in TCDWC-1208 (MVE4)
                    // if (!isToggleOn) {
                    //   setIsProfileExitEnabled(false);
                    // }
                  }}
                />
              </div>

              {/* TODO: Add back in TCDWC-1208 (MVE4) */}
              {/* <div className={`toggle-setting-container ${isKidsProfile ? "" : "disabled-setting"}`}>
                <div>
                  <span>{translate("kid_proof_exit")}</span>
                  <p>{translate("kid_proof_exit_description")}</p>
                </div>
                <ToggleSwitch
                  disabled={!isKidsProfile}
                  isChecked={isProfileExitEnabled}
                  onToggleButtonChange={setIsProfileExitEnabled}
                />
              </div> */}
            </div>
          </div>
          <div className="form-row cta-container">
            <OptikButton
              className="ghost-button"
              label={translate("cancel")}
              onClickHandler={() => {
                setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.CANCEL};${LINK_INFO.ADD_PROFILE}`);
                if (history.length > 1) {
                  history.goBack();
                } else {
                  history.push(PROFILE_EDIT.route);
                }
              }}
            />
            <OptikButton
              label={translate("create")}
              onClickHandler={addProfile}
              disabled={isFormSubmitted}
              type="submit"
            />
          </div>
        </form>
      )}
    </ProfileScreen>
  );
}

AddProfilePage.propTypes = {
  appProvider: PropTypes.object,
  profiles: PropTypes.array,
  userProfile: PropTypes.object,
  loadUserProfiles: PropTypes.func,
  showToastNotification: PropTypes.func,
};

const mapStateToProps = ({ app }) => ({
  appProvider: app.provider,
  profiles: app.profileList,
  userProfile: app.userProfile,
});

const mapDispatchToProps = {
  loadUserProfiles,
  showToastNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProfilePage);
