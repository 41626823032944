// index.js
import React from "react";
import PropTypes from "prop-types";
import { StyledButton, StyledIcon, StyledSpan } from "./OptikButton.styles";

/**
 * Render button component with Image and Text
 * @component
 * @param {Object} props
 */
const OptikButton = React.forwardRef((props, ref) => {
  const { icon, label, onClickHandler, testID, className, disabled, type, backgroundColor } = props;

  const screenWidth = window.screen.availWidth;
  return (
    <StyledButton
      type={type ?? "button"}
      className={`button ${className}`}
      onClick={onClickHandler}
      disabled={disabled}
      data-testid={testID}
      ref={ref}
      $backgroundColor={backgroundColor}
      width={screenWidth}
    >
      {icon && <StyledIcon src={icon} alt="" className={`icon ${className}`} width={screenWidth} />}
      <StyledSpan width={screenWidth}>{label}</StyledSpan>
    </StyledButton>
  );
});

OptikButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  testID: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  backgroundColor: PropTypes.string,
};

OptikButton.defaultProps = {
  icon: "",
};

export default OptikButton;
