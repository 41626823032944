import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { convertToPercentageString, isEllipsisActive } from "../../shared/utils";
import "./style.scss";
/**
 * Display information about a specific
 * title( Live, Movie, Series or Episode )
 * along with a catchy artwork to the user
 * @component
 * @param {Object} props
 */

function WallContent({
  recordingBadge,
  title,
  description,
  line1,
  line2,
  children,
  progressPercentage,
  line3,
  isShowMoreButton,
}) {
  const { t: translate } = useTranslation();
  const [descriptionClass, setDescriptionClass] = useState("");

  // Check for line count in an HTML Element and set class
  const measuredDescriptionRef = useCallback((node) => {
    if (node !== null) {
      const style = window.getComputedStyle(node, null).getPropertyValue("font-size");
      const fontSize = parseFloat(style);
      const lineHeight = Math.floor(fontSize * 1.5);
      const height = node.clientHeight;
      const lineCount = Math.ceil(height / lineHeight);

      setDescriptionClass(lineCount <= 4 ? "desc-fits" : "desc-not-fits");
    }
  }, []);

  const toggleText = (e) => {
    let descElement = document.getElementById("desc");
    if (descElement.style.display === "") {
      descElement.style.display = "inline-block";
      e.target.innerText = translate("show_less");
    } else {
      descElement.style.display = "";
      e.target.innerText = translate("details_read_more");
    }
  };
  return (
    <div>
      <div className="content">
        <div className="description-wrapper">
          <div>{recordingBadge}</div>
          <h1 data-testid="wallTitle">{title}</h1>
          <div className="wall-progressBar-wrap">
            {progressPercentage && (
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{
                    width: convertToPercentageString(progressPercentage),
                  }}
                ></div>
              </div>
            )}
          </div>
          {line1 && (
            <h2 data-testid="wallContentCategory" className="details-badge-wrap">
              {line1}
            </h2>
          )}
          {line2 && (
            <h2 data-testid="wallContentDetail" className="details-badge-wrap">
              {line2}
            </h2>
          )}
          {description !== null && (
            <p id="desc" className={descriptionClass} ref={measuredDescriptionRef} data-testid="wallDescription">
              {description}
            </p>
          )}
          {description && isShowMoreButton && isEllipsisActive("desc") && (
            <h3 className="show-more" onClick={(e) => toggleText(e)} id="textButton">
              {translate("details_read_more")}
            </h3>
          )}
          {line3 && <h3 data-testid="wallContentCategory">{line3}</h3>}
        </div>
      </div>
      <div className="wall-button-wrapper">{children != null && children}</div>
    </div>
  );
}

WallContent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  line1: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
  line2: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
  children: PropTypes.node,
  progressPercentage: PropTypes.number,
};

WallContent.defaultProps = {
  description: null,
  line1: null,
  line2: null,
  children: null,
  progressPercentage: null,
};

export default WallContent;
