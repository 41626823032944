/**
 * load core modules
 */
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
/* load core modules end */

/**
 * load child components
 */
import ImageButton from "../../components/ImageButton";
/* load child components end */

/**
 * load custom hooks
 */
import { useReducers } from "../../shared/hooks/useReducer";
/* load custom hooks end */

/**
 * load action methods
 */
import { addFavourites, deleteFavourites } from "../../App/state/actions";
/* load action methods end */

/**
 * load utilities
 */
import { showFavouriteToast } from "../../shared/utils";
/* load utilities end */

/**
 * load analytics helper functions
 */
import { trackGenericAction } from "../../shared/analytics/dataLayer";
import { getCustomContextMetadata, trackConvivaCustomEvent } from "../../shared/analytics/media";
/* load analytics helper functions end */

/**
 * load constants
 */
import constants from "../../shared/constants";
import { ANALYTICS_EVENT_TYPES, MAPPED_CONTENT_TYPES } from "../../shared/constants/analytics";
/* load constants end */

/** declare/destructure constants */
const { REDUCER_TYPE } = constants;
const favourite_icon_active = process.env.PUBLIC_URL + "images/favourite_active.svg";
const favourite_icon = process.env.PUBLIC_URL + "images/favourite_white.svg";

/**
 * Component to render favourites indicator on detail pages
 * @component
 * @param {Object} props
 */
const Favourite = React.memo(({ contentMetadata, contentType, productDetails, isPlayableOnDemand }) => {
  const {
    featureToggles,
    isAddedToFavourites,
    provider: appProvider,
    userProfile,
    isInHome,
  } = useReducers(REDUCER_TYPE.APP);
  const { isFavouritesEnabled, isConvivaAppTrackerEnabled } = featureToggles;
  const { contentUserData } = useReducers(REDUCER_TYPE.MOVIE_DETAILS);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const isAPIcallFired = useRef(false);

  let isItemFavourite = useMemo(() => contentUserData?.metadata?.favorites?.[0], [contentUserData]);
  isAPIcallFired.current = false;
  if (isAddedToFavourites !== null) {
    isItemFavourite = isAddedToFavourites;
  }

  const addFavourite = () => {
    if (!isAPIcallFired.current) {
      isAPIcallFired.current = true;
      dispatch(addFavourites(appProvider, contentMetadata, contentType));
      trackGenericAction(ANALYTICS_EVENT_TYPES.FAVOURITE, {
        ...productDetails,
        mappedContentType: isPlayableOnDemand ? MAPPED_CONTENT_TYPES.SVOD : MAPPED_CONTENT_TYPES.TVOD,
      });
      if (isConvivaAppTrackerEnabled) {
        trackConvivaCustomEvent(
          ANALYTICS_EVENT_TYPES.FAVOURITE,
          getCustomContextMetadata(productDetails, userProfile, appProvider, isInHome)
        );
      }
    }
  };

  const removeFavourite = () => {
    if (!isAPIcallFired.current) {
      isAPIcallFired.current = true;
      dispatch(deleteFavourites(appProvider, contentMetadata, contentType));
      trackGenericAction(ANALYTICS_EVENT_TYPES.UNFAVOURITE, {
        ...productDetails,
        mappedContentType: isPlayableOnDemand ? MAPPED_CONTENT_TYPES.SVOD : MAPPED_CONTENT_TYPES.TVOD,
      });
      if (isConvivaAppTrackerEnabled) {
        trackConvivaCustomEvent(
          ANALYTICS_EVENT_TYPES.UNFAVOURITE,
          getCustomContextMetadata(productDetails, userProfile, appProvider, isInHome)
        );
      }
    }
  };

  return isFavouritesEnabled && contentUserData?.metadata ? (
    <ImageButton
      src={isItemFavourite ? favourite_icon_active : favourite_icon}
      className="wall-content-icon"
      onClickHandler={isItemFavourite ? removeFavourite : addFavourite}
      testid="wallFavouriteIcon"
      buttonContainerStyles="button-container"
      alt={isItemFavourite ? translate("action_unfavourite") : translate("action_favourite")}
      tooltipDirection="top"
    />
  ) : null;
});

Favourite.propTypes = {
  contentMetadata: PropTypes.object.isRequired,
  contentType: PropTypes.string.isRequired,
  productDetails: PropTypes.object,
  isPlayableOnDemand: PropTypes.bool,
};

export default Favourite;
