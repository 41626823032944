import styled from "styled-components";

const ButtonContainer = styled.div`
  min-width: ${({ width }) => (width <= 768 ? "43px" : width <= 1366 ? "76px" : "125px")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font: ${({ theme, width }) => (width <= 768 ? theme.typography.regularFontHd : theme.typography.dropdownRegularFont)};
  line-height: 20px;
  padding: 5px;
  border-radius: 6px;
  gap: 8px;

  &.guide-filter-button {
    width: max-content;
    background-color: #1e1e1e;
    &.filter {
      margin-right: 16px;
      display: flex;
      justify-content: space-between;
    }
  }

  &.filter {
    min-width: 100px;
    padding: 12px 16px;
  }

  &.hover-state {
    background-color: ${({ theme }) => theme.colours.sharkGrayBackground};
  }

  &.gray-background {
    background-color: rgba(255, 255, 255, 0.15);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .button-container {
    flex: 1;
  }
`;

const DropdownButtonContainer = styled.div`
  background-color: transparent;
  border-width: 0px;
  padding: 0px;
  cursor: pointer;
  border-radius: 5px;
  line-height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.mediumFontSize};
  font-weight: 600;
  line-height: ${({ width }) => (width <= 768 ? "21px" : width <= 1366 ? "23px" : "27px")};
  border-radius: 6px;
  gap: 8px;

  &.filter-active {
    color: ${({ theme }) => theme.colours.subscribeLink};
    .guide-filter-icon {
      img {
        filter: brightness(0) saturate(100%) invert(66%) sepia(18%) saturate(6782%) hue-rotate(50deg) brightness(98%)
          contrast(98%);
      }
    }
  }
`;

const DropdownIconContainer = styled.div`
  margin-left: 15px;
`;

const FilterIcon = styled.img`
  cursor: pointer;
  width: 30px;
  height: 20px;
  border-radius: 50px;
  img {
    width: 20px;
    height: 20px;
  }
`;

const FilterCount = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colours.strongGreen};
  text-align: center;
  color: ${({ theme }) => theme.colours.black};
  font: ${({ theme }) => theme.dropdownRegularFont};
  line-height: 25px;
  margin-left: 5px;
`;

const StyledDropdownIcon = styled.div`
  fill: ${({ $isfilterapplied }) => ($isfilterapplied ? "#66cc00" : "#ffffff")};
`;

const ArrowIconStyle = styled.img`
  &.up-arrow-icon {
    transform: rotate(360deg);
  }

  &.down-arrow-icon {
    transform: rotate(180deg);
  }

  cursor: pointer;
  width: 13px;
  height: 11px;
`;

export {
  ButtonContainer,
  DropdownButtonContainer,
  DropdownIconContainer,
  FilterIcon,
  FilterCount,
  StyledDropdownIcon,
  ArrowIconStyle,
};
