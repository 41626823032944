import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import routeConstants from "../../shared/constants/routes";

/**
 * BaseRoute allows routes to be associated with feature toggles if feature names are provided.
 * If no names are provided, it functions as a normal Route.
 *
 * @param {String[]} [props.featureNames] - Optional. Array of feature names corresponding to the feature toggle list in our configs
 * @param {Boolean} [props.requiresAllFeatures] - Optional. Flag indicating if all of the specified features must be enabled for the route to work
 * @param {Object} [props.userProfile]
 * @param {String} [props.path]
 * @param {Object} [props.featureToggles]
 * @returns Route if allowed, Redirect otherwise
 */
const BaseRoute = (props) => {
  const { featureNames = [], requiresAllFeatures = false, userProfile, path, featureToggles } = props;
  let isRouteEnabled = true;
  if (featureNames?.length > 0) {
    isRouteEnabled = requiresAllFeatures;
    for (let i = 0; i < featureNames.length; i++) {
      const toggleKey = `is${featureNames[i]}Enabled`; // Key format for `featureToggles`
      isRouteEnabled = featureToggles[toggleKey];
      if (requiresAllFeatures) {
        // if we need all specified features enabled, we can short circuit the loop at the first sign of a disabled feature
        if (!isRouteEnabled) break;
      } else {
        // if we only need one of the specified features enabled, we can short circuit the loop at the first sign of an enabled feature
        if (isRouteEnabled) break;
      }
    }
  }

  // Prevent kids profiles from accessing Guide, Recordings, etc.
  const isKidsProfile = featureToggles.isUserProfilesEnabled && userProfile?.user?.profile?.profileData?.kidsProfile;
  if (
    isRouteEnabled &&
    isKidsProfile &&
    Object.values(routeConstants).find((routeObj) => routeObj.route === path)?.isKidsProfileAccessRestricted
  ) {
    isRouteEnabled = false;
  }

  if (isRouteEnabled) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

BaseRoute.propTypes = {
  featureNames: PropTypes.arrayOf(PropTypes.string.isRequired),
  requiresAllFeatures: PropTypes.bool,
  userProfile: PropTypes.object,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  featureToggles: PropTypes.object,
};

const mapStateToProps = ({ app }) => ({
  userProfile: app.userProfile,
  featureToggles: app.featureToggles,
});

export default connect(mapStateToProps)(BaseRoute);
