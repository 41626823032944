import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import FilterDropdown from "../FilterDropdown";
import constants from "../../shared/constants";
import epgConstants from "../../shared/constants/epg";
import storage from "../../shared/constants/storage";
import "./style.scss";
import { useReducers } from "../../shared/hooks/useReducer";
import { useLocation } from "react-router-dom";
import routes from "../../shared/constants/routes";
import { setLocalStorage } from "../../shared/utils/localStorage";

const { REDUCER_TYPE } = constants;
const { GUIDE_RESTART } = storage;
const { AUTH_CHANNEL_FILTERS, UN_AUTH_CHANNEL_FILTERS, FILTER_LABELS, MY_SUBSCRIBED_CHANNELS, MY_FAVOURITE_CHANNELS } =
  epgConstants;

const RESTART_ICON_SRC = process.env.PUBLIC_URL + "/images/Restart.svg";
const { GUIDE } = routes;

/**
 * EPG category filter Dropdown component
 *
 * @component
 * @param {Object} props
 */
const CategoryDropdown = ({ filters, filterClickHandler, isGuideFilter }) => {
  const {
    provider: appProvider,
    userProfile,
    featureToggles: { isRestartLiveTVEnabled },
    favouriteChannels,
  } = useReducers(REDUCER_TYPE.APP);
  const [showDropdownList, setShowDropdownList] = useState(false);
  const [channelFilters, setChannelFilters] = useState(UN_AUTH_CHANNEL_FILTERS());
  const [selectedChannels, setSelectedChannels] = useState(filters.channels);
  const [restartableChannelToggleState, setRestartableChannelToggleState] = useState(filters.restart);
  const showFavouriteFilter = favouriteChannels?.length > 0;
  const location = useLocation();
  const isGuidePage = location.pathname?.includes(GUIDE.route);

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (userProfile && appProvider) {
      userProfile.isLoggedIn && !appProvider.panicMode
        ? setChannelFilters(AUTH_CHANNEL_FILTERS())
        : setChannelFilters(UN_AUTH_CHANNEL_FILTERS());
    }
  }, [appProvider, userProfile]);

  useEffect(() => {
    setSelectedChannels(filters.channels);
  }, [filters.channels]);

  /**
   * Returns a dropdown list template for channels with list label and list items
   * @return {Object}
   */
  const channelsList = useMemo(() => {
    const items = channelFilters.map((filter) => {
      return { value: filter, isDisabled: filter === MY_FAVOURITE_CHANNELS && !showFavouriteFilter };
    });
    const list = {
      label: translate(FILTER_LABELS.channels),
      items: items,
      isLocalized: true,
    };
    return list;
  }, [channelFilters, showFavouriteFilter, translate]);

  /**
   * Returns a dropdown list template for channels with list label and list items
   * @return {Object}
   */
  const restartDropDownList = () => {
    const items = [
      {
        value: "restart_available",
        image: {
          src: RESTART_ICON_SRC,
          alt: "restartIcon",
        },
      },
    ];
    const list = {
      label: translate(FILTER_LABELS.restart),
      items: items,
      isLocalized: true,
    };
    return list;
  };

  const toggleFilter = () => {
    setShowDropdownList(!showDropdownList);
  };

  /**
   * Shows filter dropdown pop up when clicked on the filter dropdown button
   */
  const buttonClickHandler = (event) => {
    event.stopPropagation();
    toggleFilter();
  };

  /**
   * Sets the channel filter to the selected channel and applies it.
   */
  const channelLinkClickHandler = useCallback(
    (index) => {
      // If selectedFilter?.isDisabled = true then the particular filter should be disabled.
      const selectedFilter = channelsList.items[index];
      if (selectedFilter?.isDisabled === false) {
        const selectedChannels = selectedFilter.value;
        setSelectedChannels(selectedChannels);
        filterClickHandler({ channels: selectedChannels });
      }
    },
    [filterClickHandler, channelsList]
  );

  const restartableChannelSelectHandler = (toggleValue) => {
    setLocalStorage(GUIDE_RESTART, toggleValue);
    setRestartableChannelToggleState(toggleValue);
    filterClickHandler({ restart: toggleValue });
  };

  /**
   * Hides the filter dropdown pop up when clicked outside of the filter dropdown
   */
  const handleOutsideComponentClick = () => {
    setShowDropdownList(false);
  };

  return (
    <FilterDropdown
      filterImage={process.env.PUBLIC_URL + "/images/Filter_Icon.svg"}
      showDropdownList={showDropdownList}
      button={{
        label: isGuideFilter ? translate(selectedChannels) : translate("filter"),
        buttonClickHandler,
        isFilterApplied: userProfile?.isLoggedIn && selectedChannels !== MY_SUBSCRIBED_CHANNELS ? true : false,
        buttonStyles: restartableChannelToggleState ? "filter-active" : "",
      }}
      lists={[
        {
          items: channelsList,
          linkClickHandler: channelLinkClickHandler,
          type: "radio",
          selectedItem: selectedChannels,
        },
        {
          items: restartDropDownList(),
          linkClickHandler: restartableChannelSelectHandler,
          isFeaturedEnabled: isRestartLiveTVEnabled && (userProfile?.isLoggedIn ?? false),
          type: "toggle",
          selectedItem: `${restartableChannelToggleState}`,
        },
      ]}
      popUp={{
        popUpStyles: "filter-list-container",
        handleOutsideComponentClick,
      }}
      isGuidePage={isGuidePage}
    />
  );
};

export default CategoryDropdown;

CategoryDropdown.propTypes = {
  filters: PropTypes.object,
  filterClickHandler: PropTypes.func.isRequired,
};
