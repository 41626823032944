import middleware from "../../../shared/middleware";

const { retrieveAvsItems } = middleware;

// Action Types
export const LOAD_FEED = "LOAD_FEED";
export const PAGE_COMPONENTS = "PAGE_COMPONENTS";
export const SWIMLANE_ITEMS = "SWIMLANE_ITEMS";

// Action Methods
const loadPageComponentSuccess = (components) => {
  return {
    type: PAGE_COMPONENTS,
    components,
  };
};

const loadPageComponentFailure = () => {
  return { type: PAGE_COMPONENTS, components: [] };
};

export const getPageComponents = (appProvider, pageUri, cancelTokenSource = null) => {
  return async (dispatch) => {
    try {
      const pageComponents = await retrieveAvsItems(appProvider, pageUri, false, cancelTokenSource);
      if (pageComponents) {
        dispatch(loadPageComponentSuccess(pageComponents.containers));
      }
    } catch (error) {
      dispatch(loadPageComponentFailure());
    }
  };
};
