import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import routes from "../../shared/constants/routes";
import "./style.scss";

const dropdownIcon = `${process.env.PUBLIC_URL}/images/white-chevron.svg`;

const { GUIDE } = routes;
/**
 * Create Dropdown button
 * @component
 * @param {Object} props
 */
function DropdownButton({ className, label, tag, onClickHandler, listSize, showDropdownList }) {
  const location = useLocation();
  const classStyleName = `dropdown-button ${className}`;
  const defaultCursorStyle = listSize && listSize === 1 ? { cursor: "default" } : null;

  return (
    <div
      className={classNames(
        "button-container",
        { "guide-filter-button": location.pathname?.includes(GUIDE.route) },
        { "hover-state": showDropdownList }
      )}
      onClick={onClickHandler}
      style={defaultCursorStyle}
    >
      <div className={classStyleName} style={defaultCursorStyle}>
        {tag ? <div className="labelTag">{tag}</div> : undefined}
        <div>{label}</div>
      </div>

      <div className="dropdown-icon-container">
        {listSize && listSize === 1 ? null : <img src={dropdownIcon} alt="dropdown" />}
      </div>
    </div>
  );
}

DropdownButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClickHandler: PropTypes.func,
  tag: PropTypes.string,
};

DropdownButton.defaultProps = {
  className: "",
  onClickHandler: (e) => e,
  tag: "",
};

export default DropdownButton;
