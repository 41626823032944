import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Button from "../Button";
import PlayerConstants from "../../shared/constants/player";

const { STILL_WATCHING_COUNTDOWN_TIMER } = PlayerConstants;

/**
 * A prompt displayed to the user after some time of inactivity to ask if the user is
 * still watching. If the user clicks "Continue watching," the inactivity timer restarts.
 * Otherwise, the player closes automatically after counting down.
 * @component
 * @param {Object} props
 */
const StillWatchingCard = forwardRef(
  ({ className = undefined, onButtonClick = undefined, onCountdownComplete = undefined }, ref) => {
    const { t: translate } = useTranslation();
    const [countdownSeconds, setCountdownSeconds] = useState(STILL_WATCHING_COUNTDOWN_TIMER);
    const countdownInterval = useRef(null);

    useEffect(() => {
      countdownInterval.current = setInterval(() => {
        setCountdownSeconds((seconds) => seconds - 1);
      }, 1000);

      return () => clearInterval(countdownInterval.current);
    }, []);

    useEffect(() => {
      if (countdownSeconds <= 0) {
        if (onCountdownComplete) {
          onCountdownComplete();
        }
        clearInterval(countdownInterval.current);
      }
    }, [countdownSeconds, onCountdownComplete]);

    return (
      <div className={className}>
        <div className="countdown">{translate("exit_player").replace("<x>", countdownSeconds)}</div>
        <div className="prompt" ref={ref}>
          <div className="label">{translate("still_watching")}</div>
          <Button onClickHandler={onButtonClick} label={translate("yes_continue")} />
        </div>
      </div>
    );
  }
);

StillWatchingCard.propTypes = {
  className: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  onCountdownComplete: PropTypes.func,
};

export default StillWatchingCard;
