import _ from "lodash";

import { getPurchaseTransactionType } from "../utils";
import { retrieveMasterAsset } from "../utils/feedHelper";
import constants from "../constants/index";
import getContentUserData from "../../shared/middleware/contentUserData";
import { MAPPED_CONTENT_TYPES } from "../constants/analytics";

const { WATCH_OPTIONS, TRANSACTION_TYPES } = constants;

export const useConvivaContentSubType = (setConvivaContentSubType, dispatch) => {
  let playbackId = 0;
  let watchAsset = {};
  let tvodAsset = {};
  let tvodRentAsset = {};

  const dispatchConvivaContentSubType = (contentSubType) => {
    if (dispatch) {
      dispatch(setConvivaContentSubType(contentSubType));
    } else {
      setConvivaContentSubType(contentSubType);
    }
  };

  const resetConvivaContentSubType = () => {
    if (tvodAsset?.endDate || tvodRentAsset?.endDate || (playbackId && watchAsset?.rentalEndDate)) {
      dispatchConvivaContentSubType(MAPPED_CONTENT_TYPES.TVOD);
    } else if (!_.isEmpty(tvodAsset) || !_.isEmpty(tvodAsset) || (playbackId && watchAsset)) {
      dispatchConvivaContentSubType(MAPPED_CONTENT_TYPES.SVOD);
    } else {
      dispatchConvivaContentSubType(null);
    }
  };

  const setPlaybackId = (id) => {
    playbackId = id;
    resetConvivaContentSubType();
  };

  const updateWatchAsset = (watchOptions) => {
    watchAsset = watchOptions?.length > 0 && retrieveMasterAsset(watchOptions, WATCH_OPTIONS.watch);

    resetConvivaContentSubType();
  };

  const updateContSubType = (purchasePackages) => {
    if (purchasePackages?.length) {
      tvodAsset = purchasePackages.find((purchasePackage) => {
        return getPurchaseTransactionType(purchasePackage.name) === TRANSACTION_TYPES.BUY;
      });
      tvodRentAsset = purchasePackages.find((purchasePackage) => {
        return getPurchaseTransactionType(purchasePackage.name) === TRANSACTION_TYPES.RENT;
      });
      resetConvivaContentSubType();
    }
  };

  const updateContSubTypeViaPriorityEpisode = (priorityEpisode) => {
    const { entitlement = {}, id } = priorityEpisode?.userData || {};
    const { assets } = entitlement;

    playbackId = id;
    updateWatchAsset(assets);
    resetConvivaContentSubType();
  };

  const updateContSubTypeFromUserData = async (appProvider, item, contentUserData) => {
    const { contentId, contentType } = item?.item || {};
    try {
      if (contentId && contentType) {
        const userData = contentUserData || (await getContentUserData(appProvider, contentId, contentType));
        const { entitlement } = userData?.containers?.[0] || {};
        const { assets } = entitlement;

        playbackId = contentId;
        updateWatchAsset(assets);
      } else dispatchConvivaContentSubType(null);
    } catch (_) {
      dispatchConvivaContentSubType(null);
    }
  };

  return {
    setPlaybackId,
    updateWatchAsset,
    updateContSubType,
    updateContSubTypeViaPriorityEpisode,
    updateContSubTypeFromUserData,
  };
};
