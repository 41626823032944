import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { resetScrollPosition } from "../../shared/utils";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import "./style.scss";

const LogoImage = `${process.env.PUBLIC_URL}/images/telus-tv-logo.svg`;
const KidsLogoEnglish = `${process.env.PUBLIC_URL}/images/kids-logo-en.svg`;
const KidsLogoFrench = `${process.env.PUBLIC_URL}/images/kids-logo-fr.svg`;

/**
 * Component that renders the TELUS TV+ Logo
 * @component
 */
const Logo = ({ isClickable = true, isKidsProfile = false }) => {
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();

  return (
    <ConditionalLogoLink
      className="logo-wrapper"
      data-testid="brandLogo"
      to={isClickable ? "/" : undefined}
      onClick={
        isClickable
          ? () => {
              setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.TELUS_TV};${LINK_INFO.MEGA_NAV}`);
              resetScrollPosition();
            }
          : undefined
      }
    >
      <img className="logo" src={LogoImage} alt={translate("optik_tv")} />
      {isKidsProfile && (
        <img
          className="kids-logo"
          src={isAppLanguageFrench ? KidsLogoFrench : KidsLogoEnglish}
          alt={translate("kids_profile")}
        />
      )}
    </ConditionalLogoLink>
  );
};

/**
 * Component that wraps its children in a `Link` component if the `to` prop
 * is provided, or a `span` otherwise
 * @component
 */
const ConditionalLogoLink = ({ children, to, ...rest }) => {
  return to ? (
    <Link to={to} {...rest}>
      {children}
    </Link>
  ) : (
    <span {...rest}>{children}</span>
  );
};

export default Logo;
