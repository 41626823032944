import request from "../../service/request";
import constants from "../constants";
import { addQueryParamsToString } from "../utils";
const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

/**
 * Returns a list of channels
 * @param {object} appProvider
 * @param {Object} cancelTokenSource CancelToken - deprecated in v0.22.0, will have to update to AbortController if we update Axios in the future
 * @return {Promise}
 */
export function getChannels(appProvider, cancelTokenSource = null) {
  const withCredentials = appProvider.userCluster === "R";

  let url = appProvider.config.avs_api_urls.live_channels.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (appProvider && appProvider.channelMapID) {
    url = `${url}&filter_regionId=${appProvider.channelMapID}`;
  }

  if (appProvider.panicMode && withCredentials) {
    url = `${url}&filter_technicalPackages= ${appProvider.svodPackageIds}`;
  }

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }
  return request({ url, method: "GET", withCredentials }, cancelTokenSource, appProvider);
}

/**
 * Returns user subscribed channels
 * @param {Object} appProvider
 * @param {Object} userProfile
 * @param {Object} cancelTokenSource CancelToken - deprecated in v0.22.0, will have to update to AbortController if we update Axios in the future
 * @param {Boolean} isUserProfilesEnabled Flag from client side to check if profiles is enabled
 * @return {Promise}
 */
export function getSubscribedChannels(appProvider, userProfile, cancelTokenSource, isUserProfilesEnabled) {
  const withCredentials = appProvider.userCluster === "R";
  const isKidsProfile = isUserProfilesEnabled && userProfile?.user?.profile?.profileData?.kidsProfile;

  let url = appProvider.config.avs_api_urls.live_channels.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (appProvider.channelMapID && appProvider.packageIds) {
    url = addQueryParamsToString(
      url,
      {
        filter_regionId: appProvider.channelMapID,
        filter_technicalPackages: appProvider.packageIds,
      },
      true
    );
  }
  if (appProvider.config.kids_channel_ids && isKidsProfile) {
    url = addQueryParamsToString(
      url,
      {
        filter_channelIds: appProvider.config.kids_channel_ids.join(),
      },
      true
    );
  }

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }
  return request({ url, method: "GET", withCredentials }, cancelTokenSource, appProvider);
}

/**
 * Returns a list of program schedules for the given set of channels for a set of 3 hrs scheduled programs
 * @param {Number} startTime
 * @param {Number} endTime
 * @param {Object} appProvider
 * @param {Object} cancelTokenSource CancelToken - deprecated in v0.22.0, will have to update to AbortController if we update Axios in the future
 * @return {Promise}
 */
export function getChannelPrograms(startTime, endTime, appProvider, cancelTokenSource = null) {
  const withCredentials = appProvider.userCluster === "R";
  let url = appProvider.config.avs_api_urls.live_programs.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  url = url.replace("$START_TIME", startTime);
  url = url.replace("$END_TIME", endTime);
  url += `&filter_regionId=${appProvider.channelMapID}`;

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const requestOptions = {
    url: url,
    method: "GET",
    withCredentials,
  };

  return request(requestOptions, cancelTokenSource, appProvider).then(sortProgramsByStationID);
}

/**
 * Returns all channelModels with programs
 * @param {array} list of channels
 * @param {array} list of schedules
 * @return {array} list of channel model
 */
export function getChannelDetails(channels, programs) {
  if (channels && channels.length <= 0 && programs && programs.length <= 0) return [];

  return channels.map((channel) => createChannelDetailsModel(channel, programs.sortedPrograms[channel.id]));
}

/**
 * Find the channel object by Id and returns the same.
 *
 * @param {Array} channels - list of channels
 * @param {String} channelId - channel Id to
 */
export function getChannelById(channels, channelId) {
  return channels.find((channel) => channel.id === channelId);
}

/*** Helpers ***/

/**
 * Returns a mapping between channel IDs and an array of programs airing on the channel
 *
 * @param {Object} resultObj
 * @returns {Object}
 */
function sortProgramsByStationID(resultObj) {
  const sortedPrograms = {};
  resultObj.containers.forEach((programItem) => {
    programItem.containers.forEach((program) => {
      const channelId = program.channel.channelId;
      if (!sortedPrograms[channelId]) {
        sortedPrograms[channelId] = [];
      }
      sortedPrograms[channelId].push(createEPGProgramObject(program));
    });
  });
  return sortedPrograms;
}

/**
 * Returns the current running program within the channel
 * @param dateTime scheduled date time. Moment JS time is expected
 * @return object current running program
 */
function getScheduledProgram(scheduleDateTime) {
  if (this.programs) {
    return this.programs.find((program) => {
      return scheduleDateTime.isBetween(program.airingStartTime, program.airingEndTime);
    });
  }
}

/**
 * Returns the current running program within the channel
 * @param dateTime scheduled date time. Moment JS time is expected
 * @return object current running program
 */
function getNextScheduledProgram(scheduleDateTime) {
  if (this.programs) {
    return this.programs.find((program) => {
      return scheduleDateTime.isBefore(program.airingStartTime);
    });
  }
}

function createChannelDetailsModel(channel, programs) {
  return {
    channel,
    programs,
    getScheduledProgram,
    getNextScheduledProgram,
  };
}

/**
 * Convert a program object from API to EPG grid program structure and returns the same.
 * The returned object has the following structure:
 * {
 *   "id": String,
 *   "layout": String,
 *   "actions": [
 *     {
 *       "key": String,
 *       "uri": String,
 *       "targetType": String
 *     }
 *   ],
 *   "metadata": Object
 * }
 *
 * @param {Object} program - One program object
 * @returns {Object}
 */
const createEPGProgramObject = (program) => {
  const { channel, assets, ...rest } = program;
  // Exclude channel and assets from program object
  const programObject = {
    ...rest,
  };

  return programObject;
};
