import React from "react";
import ContextMenuItemText from "./ContextMenuItemText";
import { ContextMenu, ContextMenuItem } from "rctx-contextmenu";

const ContextMenuPanel = ({ menuId, menuAppendTo, menuItems }) => {
  return (
    <ContextMenu id={menuId} appendTo={menuAppendTo}>
      {menuItems.map((menuItem) => {
        return (
          <ContextMenuItem onClick={menuItem.onClickHandler} key={menuItem.iconKey}>
            <ContextMenuItemText iconSrc={menuItem.iconSrc} iconKey={menuItem.iconKey} label={menuItem.label} />
          </ContextMenuItem>
        );
      })}
    </ContextMenu>
  );
};

export default ContextMenuPanel;
