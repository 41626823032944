import {
  LOAD_ON_DEMAND_PLAY_OPTIONS,
  SHOW_PURCHASE_ACKNOWLEDGEMENT,
  LOAD_CURRENT_PROGRAM_DETAILS,
  LOAD_CURRENT_SERIES_DETAILS,
  LOAD_PLAYBACK_SEASON_DATA,
  LOAD_CURRENT_SEASON_DETAILS,
  LOAD_CURRENT_SEASON_ENTITLEMENTS,
} from "./actions";

const initialState = {
  playOptions: null,
  isRented: null,
  programContent: null,
  seriesDetails: null,
  seasonDetails: null,
  seasonContent: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ON_DEMAND_PLAY_OPTIONS:
      return { ...state, playOptions: action.content };
    case SHOW_PURCHASE_ACKNOWLEDGEMENT:
      return { ...state, isRented: action.content };
    case LOAD_CURRENT_PROGRAM_DETAILS:
      return { ...state, programContent: action.content };
    case LOAD_CURRENT_SERIES_DETAILS:
      return { ...state, seriesDetails: action.content };
    case LOAD_PLAYBACK_SEASON_DATA:
      return { ...state, seasonDetails: action.content };
    case LOAD_CURRENT_SEASON_DETAILS:
      return { ...state, seasonContent: action.content };
    case LOAD_CURRENT_SEASON_ENTITLEMENTS:
      return { ...state, seasonEntitlements: action.content };
    default:
      return state;
  }
};
