import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducer from "./state/reducer";

export default function configureStore(initialState = {}) {
  // Add new middlewares to be used for the application inside the array
  const middlewares = [thunk];
  // We enable logger if REACT_APP_DISPLAY_LOGGER is set to true
  if (process.env.REACT_APP_DISPLAY_LOGGER === "true") {
    // Logger with default options
    const { logger } = require("redux-logger");
    middlewares.push(logger);
  }

  const store = createStore(reducer, initialState, compose(applyMiddleware(...middlewares)));

  return store;
}
