import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import BaseRoute from "../BaseRoute";

const AuthRoute = (props) => {
  const { userProfile } = props;
  if (!userProfile?.isLoggedIn) return <Redirect to="/" />;
  else return <BaseRoute {...props} />;
};

const mapStateToProps = ({ app }) => ({
  userProfile: app.userProfile,
});

export default connect(mapStateToProps)(AuthRoute);
