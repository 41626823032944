/**
 * load core modules
 */
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
/* load core modules end */

/**
 * load child components
 */
import OptikButton from "../../components/OptikButton";
/* load child components end */

/**
 * load utilities
 */
import { getLookbackAvailabilityString } from "../../shared/utils/feedHelper";
/* load utilities end */

/**
 * Component to render "Watch now on Live TV" or "Restart Live TV" CTA for live assets on detail pages
 * @component
 * @param {Object} props
 */
const WatchLiveCTA = React.memo(
  ({ lookbackProgram, firstLiveProgram, lookbackHoursLeft, getEntitlementType, handleLiveItemClick }) => {
    const { t: translate } = useTranslation();

    const liveButton = useMemo(() => {
      const entitlementObj = getEntitlementType(null, "live");

      return (
        <OptikButton
          label={translate(entitlementObj?.label)}
          icon={entitlementObj?.icon}
          className={entitlementObj?.className}
          onClickHandler={() => handleLiveItemClick(entitlementObj?.type)}
        />
      );
    }, [getEntitlementType, translate, handleLiveItemClick]);

    const lookbackAvailabilityElement = useMemo(() => {
      return lookbackHoursLeft ? (
        <div className="lookback-string">{getLookbackAvailabilityString(lookbackHoursLeft)}</div>
      ) : null;
    }, [lookbackHoursLeft]);

    return lookbackProgram || firstLiveProgram ? (
      <div>
        <div className="detail-page-cta live-btn">{liveButton}</div>
        {lookbackAvailabilityElement}
      </div>
    ) : null;
  }
);

WatchLiveCTA.propTypes = {
  lookbackProgram: PropTypes.object,
  firstLiveProgram: PropTypes.object,
  lookbackHoursLeft: PropTypes.number,
  getEntitlementType: PropTypes.func.isRequired,
  handleLiveItemClick: PropTypes.func.isRequired,
};

export default WatchLiveCTA;
