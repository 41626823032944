import request from "../../service/request";
import constants from "../constants";
const { APP_ENVIRONMENTS, API_BASE_URL, MVE2_AGL_VERSION } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;
const DEFAULT_PIN = "0000"; // AVS expects this as a default PIN
const PIN_STATES = {
  ENABLED: "Y",
  DISABLED: "N",
};

/**
 * Handles enabling or disabling the parental control PIN.
 * If shouldEnable = true, the function will enable the PC pin and update the USER/PROFILE pin from the default to the provided userPIN
 * If shouldEnable = false, the function will disable the PC pin and update the USER/PROFILE pin from the provided userPIN to the default
 *
 * @param {Object} appProvider App provider information
 * @param {Boolean} shouldEnable Flag to indicate if function should enable or disable PC pin
 * @param {String} userPIN PIN input from the user
 * @returns {Promise} request promise
 */
export function setParentalPINAvailability(appProvider, shouldEnable, userPIN) {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = AVS_API_LIST.user_profile;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const params = {
    newParentalControlPin: shouldEnable ? userPIN : DEFAULT_PIN,
    parentalControlPin: shouldEnable ? DEFAULT_PIN : userPIN,
    parentalControlPinEnabled: shouldEnable ? PIN_STATES.ENABLED : PIN_STATES.DISABLED,
    pcFlag: shouldEnable ? PIN_STATES.ENABLED : PIN_STATES.DISABLED,
  };

  return request(
    {
      url,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data:
        appProvider.AGL_Version === MVE2_AGL_VERSION
          ? {
              profileData: {
                ...params,
                pcFlag: shouldEnable ? "1" : "0",
              },
            }
          : params,
      withCredentials,
    },
    null,
    appProvider
  );
}

/**
 * Handles updating the parental control PIN.
 *
 * @param {Object} appProvider App provider information
 * @param {String} currentPIN User's current PC PIN
 * @param {String} newPIN User's new PC PIN
 * @returns {Promise} request promise
 */
export function updateParentalPIN(appProvider, currentPIN, newPIN) {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = AVS_API_LIST.user_profile;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  const params = {
    newParentalControlPin: newPIN,
    parentalControlPin: currentPIN,
  };

  return request(
    {
      url,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: appProvider.AGL_Version === MVE2_AGL_VERSION ? { profileData: params } : params,
      withCredentials,
    },
    null,
    appProvider
  );
}

/**
 * Validate the provided parental PIN by trying to update the PIN to itself.
 *
 * @param {Object} appProvider App provider information
 * @param {String} currentPIN User's current PC PIN
 * @returns {Promise} request promise
 */
export function validateParentalPIN(appProvider, currentPIN) {
  return updateParentalPIN(appProvider, currentPIN, currentPIN);
}

/**
 * Updates the user's parental control rating levels for VOD and LIVE content if the provided PIN is correct.
 *
 * @param {Object} appProvider App provider information
 * @param {String} currentPIN User's current PC PIN
 * @param {String} newRatingLevel String representation of the new desired PC rating level
 * @returns {Promise} request promise
 */
export function updateParentalControlRatingLevel(appProvider, currentPIN, newRatingLevel) {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = AVS_API_LIST.parental_control;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", appProvider.userCluster);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request(
    {
      url,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userPin: currentPIN,
        pcLevelVod: newRatingLevel,
        pcLevelEpg: newRatingLevel,
      },
      withCredentials,
    },
    null,
    appProvider
  );
}
