import { getActionProperties } from "../utils/analytics";
import {
  ANALYTICS_EVENT_TYPES,
  ACTION_VALUES,
  ANALYTICS_ERROR_INFO,
  ANALYTICS_ERROR_NAMES,
  WEB_ACTION_EVENT_NAMES,
  NOTIFICATION_TYPES,
} from "../../shared/constants/analytics";
import recordingConstants from "../constants/recordingConstants";
import { logNREvent } from "./newRelic";
import { NR_PAGE_ACTIONS } from "../constants/newRelic";
import i18n from "../../i18n.js";

const { RECORDING_PARAMS } = recordingConstants;

/**
 * Tracks an Adobe Analytics event by pushing it to the data layer
 * @param {String} event Event key
 * @param {string} eventInfo Description of event
 * @param {Object} xdm Event details (XDM stands for Experience Data Model)
 */
export function trackEvent(event, eventInfo, xdm) {
  window.adobeDataLayer.push({
    event,
    eventInfo,
    xdm,
  });

  // Log corresponding New Relic event if applicable
  const eventTypeKey = Object.keys(ANALYTICS_EVENT_TYPES).find((key) => ANALYTICS_EVENT_TYPES[key] === event);
  if (NR_PAGE_ACTIONS[eventTypeKey]) {
    logNREvent(NR_PAGE_ACTIONS[eventTypeKey]);
  }
}

/**
 * Tracks an action event
 * @param {String} eventType Name of the type of action event
 * @param {String|Object} actionValue Value of the action
 */
export const trackWebAction = (eventType, actionValue) => {
  const {
    eventType: convertedEventType,
    eventInfo,
    searchInfo = {},
    ...properties
  } = getActionProperties(eventType, actionValue);
  eventType = convertedEventType ?? eventType;

  const actionDetails = {
    web: {
      webInteraction: {
        linkClicks: {
          value: 1,
        },
        ...properties,
      },
    },
    ...searchInfo,
  };

  trackEvent(eventType, eventInfo, actionDetails);
};

/**
 * Tracks a generic analytics event
 * @param {String} eventType Name of the type of action event
 * @param {Array|Object} actionValue Value of the action
 */
export const trackGenericAction = (eventType, actionValue) => {
  const { eventType: convertedEventType, eventInfo, eventDetails } = getActionProperties(eventType, actionValue);
  eventType = convertedEventType ?? eventType;
  trackEvent(eventType, eventInfo, eventDetails);
};

/**
 * Tracks notification event
 * @param {Object} modalContent
 */
export const trackNotification = (modalContent) => {
  const { message, analyticsInfo = {} } = modalContent;

  if (
    message === i18n.t("message_in_home") ||
    message === i18n.t("error_outside_region") ||
    message === i18n.t("android_error_outside_region_2") ||
    message === i18n.t("error_program_not_available_region")
  ) {
    trackGenericAction(ANALYTICS_EVENT_TYPES.GEO_BLOCKED_ERROR, { message, code: analyticsInfo.errorCode });
  } else if (message === i18n.t("message_subscribe")) {
    trackGenericAction(ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS, { message, media: analyticsInfo.media });
  } else if (
    message === i18n.t("error_program_restricted") ||
    message === i18n.t("error_program_not_available_device")
  ) {
    trackGenericAction(ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS, {
      message,
      media: analyticsInfo.media,
      notificationType: NOTIFICATION_TYPES.ALERTS,
    });
  } else if (message === "profiles_delete_permanent") {
    trackGenericAction(ANALYTICS_EVENT_TYPES.NOTIFICATION_IMPRESSIONS, { message: "profiles_delete_permanent" });
  }
};

/**
 * Tracks notification dismissals event
 * @param {Object} modalContent
 */
export const trackNotificationDismissals = (modalContent) => {
  const { message, analyticsInfo } = modalContent;

  if (message === i18n.t("message_subscribe")) {
    trackGenericAction(ANALYTICS_EVENT_TYPES.NOTIFICATION_DISMISSALS, { message, media: analyticsInfo.media });
  } else if (
    message === i18n.t("error_program_restricted") ||
    message === i18n.t("error_program_not_available_device")
  ) {
    trackGenericAction(ANALYTICS_EVENT_TYPES.NOTIFICATION_DISMISSALS, {
      message,
      media: analyticsInfo.media,
      notificationType: NOTIFICATION_TYPES.ALERTS,
    });
  }
};

/**
 * Tracks a recording error event
 * @param {String} recordingEventType recording event type
 * @param {String} toastErrorMsg translated error message
 * @param {Object} errorObj
 */
export const trackRecordingError = (recordingEventType, toastErrorMsg, { errorCode, errorMessage }) => {
  const isRecordingNotSet = toastErrorMsg === i18n.t(RECORDING_PARAMS.RECORDING_NOT_SET);
  const isRecordingConflict = toastErrorMsg === i18n.t(RECORDING_PARAMS.RECORDING_CONFLICT);
  const isRecordingNotUpdated = toastErrorMsg === i18n.t(RECORDING_PARAMS.RECORDING_NOT_UPDATED);
  const isCouldRecordingNotDeleted = toastErrorMsg === i18n.t(RECORDING_PARAMS.COULD_NOT_DELETE_RECORDING);
  const isCouldRecordingNotCanceled = toastErrorMsg === i18n.t(RECORDING_PARAMS.COULD_NOT_CANCEL_RECORDING);

  if (
    recordingEventType &&
    (isRecordingNotSet ||
      isRecordingConflict ||
      isRecordingNotUpdated ||
      isCouldRecordingNotDeleted ||
      isCouldRecordingNotCanceled)
  ) {
    const errMap = {
      [RECORDING_PARAMS.RECORDING_NOT_SET]: isRecordingNotSet,
      [RECORDING_PARAMS.RECORDING_CONFLICT]: isRecordingConflict,
      [RECORDING_PARAMS.RECORDING_NOT_UPDATED]: isRecordingNotUpdated,
      [RECORDING_PARAMS.COULD_NOT_DELETE_RECORDING]: isCouldRecordingNotDeleted,
      [RECORDING_PARAMS.COULD_NOT_CANCEL_RECORDING]: isCouldRecordingNotCanceled,
    };

    errorMessage = errorMessage || Object.keys(errMap).find((key) => errMap[key]);

    switch (recordingEventType) {
      case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START:
        trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START_ERROR, {
          toolName: ACTION_VALUES.VIDEO_RECORD_START,
          errorType: ANALYTICS_ERROR_NAMES.SERVER_ERROR,
          name: WEB_ACTION_EVENT_NAMES.VIDEO_RECORD_START_ERROR,
          errorCode,
          errorDetails: ANALYTICS_ERROR_INFO.VIDEO_RECORD_START_FAILURE,
          errorMessage,
        });
        break;
      case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_STOP:
        trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_STOP_ERROR, {
          toolName: ACTION_VALUES.VIDEO_RECORD_STOP,
          errorType: ANALYTICS_ERROR_NAMES.SERVER_ERROR,
          name: WEB_ACTION_EVENT_NAMES.VIDEO_RECORD_STOP_ERROR,
          errorCode,
          errorDetails: ANALYTICS_ERROR_INFO.VIDEO_RECORD_STOP_FAILURE,
          errorMessage,
        });
        break;
      case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_DELETE:
        trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_DELETE_ERROR, {
          toolName: ACTION_VALUES.VIDEO_RECORD_DELETE,
          errorType: ANALYTICS_ERROR_NAMES.SERVER_ERROR,
          name: WEB_ACTION_EVENT_NAMES.VIDEO_RECORD_DELETE_ERROR,
          errorCode,
          errorDetails: ANALYTICS_ERROR_INFO.VIDEO_RECORD_DELETE_FAILURE,
          errorMessage,
        });
        break;
      case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE:
        trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_ERROR, {
          toolName: ACTION_VALUES.VIDEO_RECORD_SERIES_EDIT,
          errorType: ANALYTICS_ERROR_NAMES.SERVER_ERROR,
          name: WEB_ACTION_EVENT_NAMES.VIDEO_RECORD_SERIES_EDIT_ERROR,
          errorCode,
          errorDetails: ANALYTICS_ERROR_INFO.VIDEO_RECORD_SERIES_EDIT_FAILURE,
          errorMessage,
        });
        break;
      case ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_COMPLETE:
        trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_ERROR, {
          toolName: ACTION_VALUES.VIDEO_RECORD_EPISODE_EDIT,
          errorType: ANALYTICS_ERROR_NAMES.SERVER_ERROR,
          name: WEB_ACTION_EVENT_NAMES.VIDEO_RECORD_EPISODE_EDIT_ERROR,
          errorCode,
          errorDetails: ANALYTICS_ERROR_INFO.VIDEO_RECORD_EPISODE_EDIT_FAILURE,
          errorMessage,
        });
        break;
      default:
        break;
    }
  }
};

/**
 * Tracks recommendation impressions event
 * @param {Object} swimlaneFeed
 */
export const trackRecommendationImpressions = (swimlaneFeed) => {
  trackGenericAction(ANALYTICS_EVENT_TYPES.RECOMMENDATION_IMPRESSIONS, {
    assets: swimlaneFeed.items,
    startingIndex: 0,
    title: swimlaneFeed.title,
  });
};
