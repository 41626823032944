import middleware from "../../../shared/middleware";

const { getSubCategoryPageItems } = middleware;
// Action types
export const LOAD_SUB_CATEGORY = "LOAD_SUB_CATEGORY";

// Action methods
function loadSubCategoryPageItemsSuccess(content) {
  return { type: LOAD_SUB_CATEGORY, content };
}
function loadSubCategoryPageItemsFailure() {
  return { type: LOAD_SUB_CATEGORY, content: [] };
}
export const loadSubCategoryPageItems = (appProvider, feedId, cancelTokenSource = null) => {
  return async (dispatch) => {
    try {
      const feedContent = await getSubCategoryPageItems(appProvider, feedId, cancelTokenSource);
      if (feedContent) {
        dispatch(loadSubCategoryPageItemsSuccess(feedContent.containers));
      }
    } catch (error) {
      dispatch(loadSubCategoryPageItemsFailure());
    }
  };
};
