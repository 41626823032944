import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import OptikButton from "../OptikButton";
import { connect } from "react-redux";
import errorConstants from "../../shared/constants/error";
import storageConstants from "../../shared/constants/storage";
import { getLocalStorage, removeLocalStorage } from "../../shared/utils/localStorage";
import { getSessionStorage, removeSessionStorage } from "../../shared/utils/sessionStorage";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { isPinMaxLimitReach, trapFocus } from "../../shared/utils/index";
import { showModalPopup, showToastNotification } from "../../App/state/actions";
import { handleLogin } from "../../shared/utils/login";
import PinModal, { EXTERNAL_PIN_ERRORS, PIN_MODAL_MODES, PIN_MODAL_TYPES } from "../../components/PinModal";
import constants from "../../shared/constants";
import { trackNotification, trackNotificationDismissals, trackGenericAction } from "../../shared/analytics/dataLayer";
import { getGenericErrorEventHandler } from "../../shared/analytics/helpers";
import { ACTION_VALUES, ANALYTICS_EVENT_TYPES, WEB_ACTION_EVENT_NAMES } from "../../shared/constants/analytics";
import CancelPopup from "../CancelPopup";
import { useHistory, useLocation } from "react-router-dom";
import PurchaseModal from "../../components/PurchaseModal";
import middleware from "../../shared/middleware";
import { showPurchaseAcknowledgement } from "../../pages/PlayerPage/state/actions";
import { getCustomContextMetadata, trackConvivaCustomEvent } from "../../shared/analytics/media";
import { useReducers } from "../../shared/hooks/useReducer";

const { MODAL_TYPES, MVE2_AGL_VERSION, REDUCER_TYPE, LOGIN_BRANDS } = constants;
const { AVS_ERROR_CODES } = errorConstants;
const { IS_FRESH_LOGIN, PLAYER_LOADED } = storageConstants;

const { logout, makePurchase } = middleware;
/**
 * Create Modal
 * @component
 * @param {Object} props
 */
function ErrorModal(props) {
  const {
    modalData,
    className,
    showModalPopup,
    appProvider,
    userProfile,
    showPurchaseAcknowledgement,
    isInHome,
    showToastNotification,
  } = props;
  const { featureToggles } = useReducers(REDUCER_TYPE.APP);
  const { t: translate } = useTranslation();
  const [popUpDialog, setPopUpDialog] = useState(false);
  let modalTitle = "";
  let modalFocus = useRef(null);
  let modal = useFocusTrap(popUpDialog);
  const isFreshLogin = getLocalStorage(IS_FRESH_LOGIN);
  const playerLoaded = getSessionStorage(PLAYER_LOADED);
  const modalType = modalData?.modalType;
  const modalContent = useMemo(() => modalData?.modalContent || {}, [modalData]);
  const classStyleName = `modal-wrap sm ${modalContent?.className ?? className}`;
  const [pinError, setPinError] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { isConvivaAppTrackerEnabled, isKoodoLoginEnabled } = featureToggles;

  useEffect(() => {
    if (modal && popUpDialog) {
      trapFocus(modal);
      if (modalFocus.current) modalFocus.current.focus();
    }
  }, [modal, popUpDialog]);

  useEffect(() => {
    if (modalData?.modalContent && Object.values(modalData.modalContent).some((value) => value !== null)) {
      setPopUpDialog(true);
      trackNotification(modalData.modalContent);
    } else {
      setPopUpDialog(false);
    }
  }, [modalData]);

  useEffect(() => {
    const unListen = history.listen(() => {
      hideModal();
    });
    return unListen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const hideModal = useCallback(
    (navigateBack = true) => {
      playerLoaded && removeSessionStorage(PLAYER_LOADED);
      showModalPopup(MODAL_TYPES.ERROR, {});
      trackNotificationDismissals(modalContent);
      setPopUpDialog(false);
      setPinError(null);
      if (modalContent.callbackFunction) {
        modalContent.callbackFunction();
      }

      if (modalContent.history && location.pathname.includes("player") && navigateBack) {
        if (isFreshLogin) {
          modalContent.history.push("/");
          removeLocalStorage(IS_FRESH_LOGIN);
        } else {
          if (1 < modalContent.history.length) {
            modalContent.history.goBack();
          } else {
            modalContent.history.push("/");
          }
        }
      }
    },
    [isFreshLogin, location, modalContent, playerLoaded, showModalPopup]
  );

  const retryLogin = () => {
    handleLogin(appProvider, LOGIN_BRANDS.TELUS, isKoodoLoginEnabled);
  };
  const logoutUser = () => {
    logout(appProvider);
  };

  const pinModalConfirmHandler = (pin) => {
    if (modalContent.pinConfirmHandler) {
      modalContent
        .pinConfirmHandler(pin)
        .then(() => {
          if (modalContent.closeOnConfirm !== false) {
            hideModal(false);
          }
        })
        .catch((e) => {
          if (modalContent.pinAnalyticsErrorEventHandler) {
            modalContent.pinAnalyticsErrorEventHandler({ errorMessage: e.message, errorCode: e.code });
          }
          if (
            appProvider.AGL_Version === MVE2_AGL_VERSION
              ? e?.code?.includes(AVS_ERROR_CODES.USER_NO_RIGHTS)
              : AVS_ERROR_CODES.INCORRECT_PIN_CODES.includes(e?.code)
          ) {
            setPinError({ params: e.params });
          } else if (isPinMaxLimitReach(e)) {
            setPinError({ code: e.code });
          } else {
            //TODO: How to handle other errors?
            const errorString = modalContent.pinErrorMessage ?? "PIN related error";
            console.error(errorString + ", error = ", e);
            hideModal();
          }
        });
    }
  };

  const pinModalCancelHandler = () => {
    if (modalContent.pinCancelHandler) {
      modalContent.pinCancelHandler();
    }
    hideModal();
  };

  /**
   * Makes the purchase API call using the provided purchase package details (and PIN if provided).
   * Broadly applicable functionalities should be implemented here, while scenario-specific functionalities
   * should be passed in to this ErrorModal component using modalContent.purchaseCallback.
   *
   * @param {Object} purchasePackage Data about the package the user is purchasing
   * @param {String} pin User's purchase PIN, if enabled
   * @returns {Promise} Purchase API promise for any additional callback control
   */
  const purchaseItem = useCallback(
    (purchasePackage, pin) => {
      const promise = makePurchase(appProvider, purchasePackage, pin)
        .then(({ purchaseDetails }) => {
          trackGenericAction(ANALYTICS_EVENT_TYPES.PURCHASE, {
            appProvider,
            asset: modalContent.mediaNodeMetadata,
            purchaseDetails,
            purchasePackage,
          });
          if (isConvivaAppTrackerEnabled) {
            trackConvivaCustomEvent(
              ANALYTICS_EVENT_TYPES.PURCHASE,
              getCustomContextMetadata(modalContent.mediaNodeMetadata, userProfile, appProvider, isInHome)
            );
          }
          hideModal(false);
          showPurchaseAcknowledgement();
          if (pin) {
            trackGenericAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK, modalContent.mediaNodeMetadata);
            if (isConvivaAppTrackerEnabled) {
              trackConvivaCustomEvent(
                ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK,
                getCustomContextMetadata(modalContent.mediaNodeMetadata, userProfile, appProvider, isInHome)
              );
            }
          }

          if (modalContent.purchaseCallback) {
            modalContent.purchaseCallback();
          }
        })
        .catch((err) => {
          console.error("Failed to make purchase ", err);
          showToastNotification(translate("message_purchase_failed"));
        });

      return promise;
    },
    [
      appProvider,
      hideModal,
      modalContent,
      showPurchaseAcknowledgement,
      isInHome,
      userProfile,
      isConvivaAppTrackerEnabled,
      translate,
      showToastNotification,
    ]
  );

  /**
   * Checks if purchase PIN functionality is enabled & required before attempting to make a purchase.
   * @param {Object} purchasePackage Data about the package the user is purchasing
   */
  const purchaseClickHandler = useCallback(
    (purchasePackage) => {
      if (userProfile?.user?.profile?.profileData?.purchasePinEnabled === "Y") {
        const modalConfig = {
          pinModalMode: PIN_MODAL_MODES.ACCESS,
          pinModalType: PIN_MODAL_TYPES.PURCHASE,
          title: translate("pin_purchase_locked"),
          message: translate("pin_purchase_locked_instructions"),
          pinConfirmHandler: (pin) => {
            return purchaseItem(purchasePackage, pin);
          },
          pinAnalyticsErrorEventHandler: getGenericErrorEventHandler(
            ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK_ERROR,
            ACTION_VALUES.PURCHASE_PIN_UNLOCK,
            WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_UNLOCK_ERROR
          ),
        };

        showModalPopup(MODAL_TYPES.PIN, modalConfig);
        trackGenericAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_LOCK, modalContent.mediaNodeMetadata);
        if (isConvivaAppTrackerEnabled) {
          trackConvivaCustomEvent(
            ANALYTICS_EVENT_TYPES.PURCHASE_PIN_LOCK,
            getCustomContextMetadata(modalContent.mediaNodeMetadata, userProfile, appProvider, isInHome)
          );
        }
      } else {
        purchaseItem(purchasePackage);
      }
    },
    [
      modalContent,
      purchaseItem,
      showModalPopup,
      translate,
      userProfile,
      appProvider,
      isInHome,
      isConvivaAppTrackerEnabled,
    ]
  );

  let modalElement = null;
  if (Object.keys(modalContent).length > 0) {
    if (modalType === MODAL_TYPES.PIN) {
      modalElement = (
        <PinModal
          initialPinModalMode={modalContent.pinModalMode}
          pinModalType={modalContent.pinModalType}
          closeModal={pinModalCancelHandler}
          heading={modalContent.title}
          subheading={modalContent.message}
          pinConfirmHandler={pinModalConfirmHandler}
          externalError={pinError}
          setExternalError={setPinError}
          pinAnalyticsErrorEventHandler={modalContent.pinAnalyticsErrorEventHandler}
        />
      );
    } else if (modalType === MODAL_TYPES.RECORDING) {
      modalElement = (
        <CancelPopup
          closeModal={hideModal}
          recordingInfo={modalContent.recordingInfo}
          recordGUID={modalContent.recordGUID}
          programDetails={modalContent.programDetails}
          isRecordingRecorded={modalContent.isRecordingRecorded}
          recordingType={modalContent.recordingType?.toLowerCase()}
          closeRecordingSettings={modalContent.closeRecordingSettings}
          isProgramOfSeries={modalContent.isProgramOfSeries}
          isMR={modalContent.isMR}
          isRecordingNow={modalContent.isRecordingNow}
          isInHome={isInHome}
          userProfile={userProfile}
        />
      );
    } else if (modalType === MODAL_TYPES.PURCHASE) {
      modalElement = (
        <PurchaseModal
          channel={modalContent.channel}
          closeModal={hideModal}
          itemMetadata={modalContent.itemMetadata}
          itemImage={modalContent.itemImage}
          purchaseItem={purchaseClickHandler}
          purchasePackage={modalContent.purchasePackage}
        />
      );
    } else {
      if (modalContent.title?.length > 0) {
        modalTitle = typeof modalContent.title === "string" ? translate(modalContent.title) : modalContent.title;
      }

      let endButtonLabel, endButtonClickHandler;
      if (modalContent.isCloseable) {
        endButtonLabel = translate("ok");
        endButtonClickHandler = hideModal;
      } else if (modalContent.disableRetry) {
        endButtonLabel = translate("ok");
        endButtonClickHandler = logoutUser;
      } else {
        endButtonLabel = translate("retry");
        endButtonClickHandler = retryLogin;
      }

      let buttons = [];
      if (modalContent.buttons?.length > 0) {
        buttons = buttons.concat(modalContent.buttons);
      }

      // Overriding the end button label with an empty string is allowed and will cause the end button to be excluded from the modal
      if (modalContent.endButtonLabelOverride !== null && modalContent.endButtonLabelOverride !== undefined) {
        endButtonLabel = modalContent.endButtonLabelOverride;
      }

      // Overriding the end button click handler with any truthy value is allowed, but the button will only get created if a function is used
      if (modalContent.endButtonClickHandlerOverride) {
        endButtonClickHandler = modalContent.endButtonClickHandlerOverride;
      }

      // By default we have a button that will be the last button child in a list of buttons
      buttons.push({
        label: endButtonLabel,
        onClickHandler: endButtonClickHandler,
        classOverride:
          typeof modalContent.endButtonClassOverride === "string" ? modalContent.endButtonClassOverride : null,
      });

      let buttonElements = [];
      for (let i = 0; i < buttons.length; i++) {
        const buttonData = buttons[i];
        if (buttonData.label && typeof buttonData.onClickHandler === "function") {
          buttonElements.push(
            <OptikButton
              key={i}
              className={buttonData.classOverride ? buttonData.classOverride : "error-modal-btn-green"}
              label={buttonData.label}
              onClickHandler={() => {
                buttonData.onClickHandler();
                if (
                  buttonData.onClickHandler !== hideModal &&
                  modalContent.isCloseable &&
                  !modalContent.endButtonClickHandlerOverride
                ) {
                  hideModal();
                }
              }}
              ref={modalFocus}
            />
          );
        }
      }

      modalElement = (
        <div className="modal" id="modal">
          <div className="backdrop"></div>
          <div className="modal-container">
            <div className={classStyleName}>
              <div className="modal-header">
                <h2>{modalTitle}</h2>
              </div>
              <div className="modal-body">{translate(modalContent.message)}</div>
              <div className="error-modal-btn-container">{buttonElements}</div>
              {modalContent.externalLink ? (
                <a
                  href="https://www.google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="error-modal-ext-link"
                >
                  {translate("learn_more")}
                </a>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }

  return popUpDialog ? modalElement : null;
}
function mapStateToProps({ app }) {
  return {
    modalData: app.modalPopup,
    appProvider: app.provider,
    userProfile: app.userProfile,
    isInHome: app.isInHome,
  };
}

const mapDispatchToProps = {
  showModalPopup,
  showPurchaseAcknowledgement,
  showToastNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);

// HELPERS

function useFocusTrap(popUpDialog) {
  const [modal, setModal] = useState([]);
  useEffect(() => {
    setModal(document.querySelector("#modal"));
  }, [popUpDialog]);
  return modal;
}
