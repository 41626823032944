/**
 * Sort function that orders avatars alphabetically by category, then by displayName
 * @param {Object} avatarA
 * @param {Object} avatarB
 * @returns {Number}
 */
const avatarCompareFunction = (avatarA, avatarB) => {
  const categoryA = avatarA.category?.toLowerCase();
  const categoryB = avatarB.category?.toLowerCase();
  if (categoryA !== categoryB) {
    return categoryA < categoryB ? -1 : 1;
  }

  const displayNameA = avatarA.displayName?.toLowerCase();
  const displayNameB = avatarB.displayName?.toLowerCase();
  if (displayNameA === displayNameB) {
    return 0;
  }
  return displayNameA < displayNameB ? -1 : 1;
};

/**
 * Returns the list of avatars sorted by category and displayName, with inactive
 * avatars and exclusions filtered out
 * @param {Array} avatars Array of avatar objects
 * @param {Array} exclusions Optional array of avatarIds to be excluded from results
 * @returns {Array}
 */
export const sortAndFilterAvatars = (avatars = [], exclusions) => {
  if (avatars?.length > 0) {
    const filteredAvatars = avatars.filter((avatarInfo) => {
      return avatarInfo.status === "ACTIVE" && !exclusions?.includes(avatarInfo.id);
    });
    filteredAvatars.sort(avatarCompareFunction);
    return filteredAvatars;
  }

  return avatars;
};
