import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ForgotPinLink from "../ForgotPinLink";
import OptikButton from "../OptikButton";
import { PIN_MODAL_TYPES } from "../PinModal";
import "./style.scss";

/**
 * An overlay component for the video player when content is parental control blocked
 * @component
 * @param {Object} props
 */
function ContentLockedOverlay({ isSideNavOpen = false, isPinLocked = false, unlockContentHandler = () => {} }) {
  const { t: translate } = useTranslation();

  return (
    <div className={`content-locked-overlay ${isSideNavOpen ? "shrink" : ""}`}>
      <div className="content-locked-container">
        <img src={`${process.env.PUBLIC_URL}/images/lock-icon.svg`} alt="" className="lock-icon" />
        <div className="content-locked-text">
          <span className="content-locked-heading">{translate("pin_content_locked")}</span>
          <div className="content-locked-message">
            {isPinLocked ? (
              translate("parental_locked_many_attempts")
                .split("<link>")
                .map((token, index) => {
                  if (index === 0) {
                    return token;
                  }
                  return (
                    <>
                      <ForgotPinLink pinType={PIN_MODAL_TYPES.PARENTAL} />
                      {token}
                    </>
                  );
                })
            ) : (
              <>
                {translate("parental_locked_unlock")}
                <OptikButton
                  label={translate("parental_unlock_content")}
                  onClickHandler={unlockContentHandler}
                  className="gray-button"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentLockedOverlay;

ContentLockedOverlay.propTypes = {
  isSideNavOpen: PropTypes.bool,
  isPinLocked: PropTypes.bool,
  unlockContentHandler: PropTypes.func,
};
