import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { loadUserProfiles } from "../../App/state/actions";
import ProfileScreen from "../../components/ProfileScreen";
import ProfileList from "../../components/ProfileList";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import "./style.scss";

/**
 * Page component prompting the user to select a profile to use
 * @component
 * @param {Object} props
 */
function SelectProfilePage({ appProvider, profiles, loadUserProfiles }) {
  const [newProfileId, setNewProfileId] = useState(null);
  const [deletedProfile, setDeletedProfile] = useState(null);
  const location = useLocation();
  const { t: translate } = useTranslation();
  const { trackPageView } = useTrackPageView();

  useEffect(() => {
    // If a profile has just been created or deleted, we handle it
    // here and then pass the profile's userId to ProfileList
    if (location.state?.newProfileId) {
      setNewProfileId(location.state.newProfileId);
    }
    if (location.state?.deletedProfile) {
      setDeletedProfile(location.state.deletedProfile);
    }

    return () => {
      setNewProfileId(null);
      setDeletedProfile(null);
    };
  }, [appProvider, loadUserProfiles, location.state]);

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return profiles?.length <= 1 && !location.state?.deletedProfile ? (
    <Redirect to="/" />
  ) : (
    <ProfileScreen>
      <h1>{translate("whos_watching")}</h1>
      <ProfileList newProfileId={newProfileId} deletedProfile={deletedProfile} />
      <div className="admin-profile-info">{translate("profiles_can_be_add_edited_only_by_admin")}</div>
    </ProfileScreen>
  );
}

SelectProfilePage.propTypes = {
  appProvider: PropTypes.object,
  profiles: PropTypes.array,
  loadUserProfiles: PropTypes.func,
};

const mapStateToProps = ({ app }) => ({
  appProvider: app.provider,
  profiles: app.profileList,
});

const mapDispatchToProps = {
  loadUserProfiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectProfilePage);
