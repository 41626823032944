import { useEffect, useState, useRef } from "react";

/**
 * Trigger state changes on every interval updates.
 * @param {Int} - interval duration in milliseconds
 * @returns {Int} - total time elapsed after each duration
 */
export default function useSetInterval(intervalDuration) {
  const [timeElapsed, setTimeElapsed] = useState(0);
  const setIntervalTimeArray = useRef([]);

  useEffect(() => {
    let intervalTimer = setInterval(() => {
      setTimeElapsed((timeElapsed) => timeElapsed + intervalDuration);
    }, intervalDuration);
    setIntervalTimeArray.current.push(intervalTimer);
    const setIntervalTimeArrayCopy = [...setIntervalTimeArray.current];
    return () => {
      setIntervalTimeArrayCopy.forEach((it) => clearInterval(it));
    };
  }, [intervalDuration]);

  return timeElapsed;
}
