import React from "react";
import "./style.scss";

/**
 * Search toggle button component
 *
 * @component
 * @param {Object} props
 */

function ToggleSwitch({ isChecked, onToggleButtonChange, disabled }) {
  const onToggle = () => {
    onToggleButtonChange(!isChecked);
  };
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={isChecked} onChange={onToggle} disabled={disabled} />
      <span className="switch" />
    </label>
  );
}
export default ToggleSwitch;
